import { FeatureSetDownloadStep } from "@/@types/project/mlPipeline/Kubeflow/pipeline-featureset-download";
import { FeatureSetUploadStep } from "@/@types/project/mlPipeline/Kubeflow/pipeline-featureset-upload";
import { ProcessingStep } from "@/@types/project/mlPipeline/Kubeflow/pipeline-processing";
import { RegisterModelStep } from "@/@types/project/mlPipeline/Kubeflow/pipeline-register-model";
import { TrainingStep } from "@/@types/project/mlPipeline/Kubeflow/pipeline-training";

const { config } = JSON.parse(localStorage.getItem("redux-common") || "{}");
const {
  pipeline: {
    step_config: {
      FeatureSetDownload = {},
      FeatureSetUpload = {},
      RegisterModel = {},
    } = {},
  } = {},
} = JSON.parse(config || "{}");

export const constructProcessingStep = (
  id: number,
  position: number[],
  stepType: string
) => {
  return {
    id: id.toString(),
    type: "Processing",
    name: stepType,
    nodeX: position[0],
    nodeY: position[1],
    tags: [],
    properties: [],
    stepType: stepType,
    // step config
    codePath: "",
    codeJobArgumentsList: [],
    description: "",
    // processor
    processor: {
      image: "",
      instanceType: "",
      count: "",
    },
    inputs: [],
    outputs: [],
  } as ProcessingStep;
};
export const constructFeatureSetUploadStep = (
  id: number,
  position: number[],
  stepType: string
) => {
  return {
    id: id.toString(),
    type: "FeatureSetUpload",
    name: stepType,
    nodeX: position[0],
    nodeY: position[1],
    tags: [],
    properties: [],
    stepType: stepType,

    codePath: FeatureSetUpload?.code_path,
    description: "",
    arguments: {
      useParam: false,
      fsId: "",
      fsExternalId: "",
      name: "",
      featureSetType: "",
      deliverTo: "",
      resourcePath: "",
      resourceType: "",
      resourceConnection: "",
      resourceConnectionCredential: "",
    },
    processor: {
      image: FeatureSetUpload?.image,
      instanceType: "",
      count: "",
    },
    inputs: [],
  } as FeatureSetUploadStep;
};
export const constructRegisterModelStep = (
  id: number,
  position: number[],
  stepType: string
) => {
  return {
    id: id.toString(),
    type: "RegisterModel",
    name: stepType,
    nodeX: position[0],
    nodeY: position[1],
    tags: [],
    properties: [],
    stepType: stepType,

    codePath: RegisterModel?.code_path,
    description: "",
    arguments: {
      useParam: false,
      mpId: "",
      mpExternalId: "",
      name: "",
      featureSetType: "",
      deliverTo: "",
      resourcePath: "",
      resourceType: "",
      resourceConnection: "",
      resourceConnectionCredential: "",
    },
    processor: {
      image: RegisterModel?.image,
      instanceType: "",
      count: "",
    },
    inputs: [],
  } as RegisterModelStep;
};

export const constructFeatureSetDownloadStep = (
  id: number,
  position: number[],
  stepType: string
) => {
  return {
    id: id.toString(),
    type: "FeatureSetDownload",
    name: stepType,
    nodeX: position[0],
    nodeY: position[1],
    tags: [],
    properties: [],
    stepType: stepType,

    codePath: FeatureSetDownload?.code_path,
    description: "",
    arguments: {
      fsId: "",
      fsVersionId: "",
      fsVersionExternalId: "",
    },
    processor: {
      image: FeatureSetDownload?.image,
      instanceType: "",
      count: "",
    },
    outputs: [],
  } as FeatureSetDownloadStep;
};

export const constructTrainingStep = (
  id: number,
  position: number[],
  stepType: string
) => {
  return {
    id: id.toString(),
    type: "Training",
    name: stepType,
    nodeX: position[0],
    nodeY: position[1],
    tags: [],
    properties: [],
    stepType: stepType,

    // step config
    codePath: "",
    codeJobArgumentsList: [],
    description: "",
    // processor
    processor: {
      image: "",
      instanceType: "",
      count: "",
    },
    inputs: [],
    // two fixed outputs
    outputs: [
      {
        name: "model_file_path",
        dataType: "String",
        description: "",
        paramType: 2,
      },
      {
        name: "evaluation_metrics_file_path",
        dataType: "String",
        description: "",
        paramType: 2,
      },
    ],
  } as TrainingStep;
};

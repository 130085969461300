import { useRef } from "react";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
// redux
import { useDispatch } from "@/redux/store";
// material
import { alpha } from "@mui/material/styles";
import {
  Box,
  List,
  BoxProps,
  ListItemText,
  ListItemButton,
  ListItem,
} from "@mui/material";
import { importLocalPipelineDef } from "@/redux/project/automation/pipeline";
import { isEmpty } from "lodash";
import { clearSchema } from "../../../../graph/slices/slice";
// import { getGraph } from "../../../../graph/schema/components/GraphViewer";

interface PipelineNavSectionProps extends BoxProps {
  setCurSelectedTemplate: Function;
  curSelectedTemplate: Record<string, any>;
  navConfig: any[];
  pipelineType: "Kubeflow" | "AWS SageMaker";
}

export default function PipelineNavSection({
  navConfig,
  setCurSelectedTemplate,
  curSelectedTemplate,
  pipelineType,
  ...other
}: PipelineNavSectionProps) {
  const dispatch = useDispatch();
  const timeRef = useRef(Date.now());
  const { enqueueSnackbar } = useSnackbar();

  const callback = (content: string, variant: undefined) => {
    if (content !== "" && variant === "error")
      enqueueSnackbar(content, { variant: variant });
  };

  const loadPipeline = (name: string) => {
    import(`../assets/${name}.json`).then((def) => {
      dispatch(
        importLocalPipelineDef(
          `Pipeline-${timeRef.current}`,
          JSON.stringify(def),
          callback
        )
      );
    });
  };

  const loadPipelineTemplate = (name: string) => {
    if (name.toLowerCase().includes("re-identification")) {
      loadPipeline("Template-MOT-pipeline");
    } else if (name.toLowerCase().includes("trend prediction")) {
      loadPipeline("Template-abalone-age-prediction-automl-pipeline");
    } else if (name.toLowerCase().includes("bird detection")) {
      loadPipeline("Template-bird-detection-pipeline");
    }
  };

  useEffect(() => {
    if (!isEmpty(curSelectedTemplate)) {
      if (pipelineType === "AWS SageMaker") {
        loadPipelineTemplate(curSelectedTemplate.pipelineGraphName);
      } else {
        // on last Template.tsx page
      }
    }
  }, [curSelectedTemplate, pipelineType]);

  return (
    <Box {...other}>
      <List>
        {navConfig
          .filter((item) => item.mlpipeline_type === pipelineType)
          .map((item) => (
            <ListItem
              key={item.id}
              disablePadding
              onClick={() => {
                setCurSelectedTemplate(item);
              }}
              sx={{
                ...(curSelectedTemplate.id === item.id
                  ? {
                      color: "primary.main",
                      fontWeight: "fontWeightMedium",
                      bgcolor: (theme) =>
                        alpha(
                          theme.palette.primary.main,
                          theme.palette.action.selectedOpacity
                        ),
                    }
                  : {}),
              }}
            >
              <ListItemButton>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
      {/* {navConfig.map((list) => {
        return (
          <PipelineItem
            key={list.title}
            item={list}
            isShow={isShow}
            updateSelectedItem={updateSelectedItem}
            selectedItem={selectedItem}
          />
        );
      })} */}
    </Box>
  );
}

import { ReactNode } from "react";
import { isString } from "lodash";
// material
import { Box, Typography, Link } from "@mui/material";
//
import { MBreadcrumbs } from "../@material-extend";
import { MBreadcrumbsProps } from "../@material-extend/MBreadcrumbs";

// ----------------------------------------------------------------------

interface HeaderBreadcrumbsProps extends MBreadcrumbsProps {
  action?: ReactNode;
  heading: string | ReactNode;
  moreLink?: string | string[];
  noTitle?: boolean;
  noBreadcrumb?: boolean;
}

export default function HeaderBreadcrumbs({
  links,
  action,
  heading,
  noTitle = false,
  noBreadcrumb = false,
  moreLink = "" || [],
  sx,
  ...other
}: HeaderBreadcrumbsProps) {
  return (
    <Box sx={{ mb: 3, ...sx }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1 }}>
          {!noTitle &&
            (typeof heading === "string" ? (
              <Typography variant="h4" gutterBottom>
                {heading}{" "}
              </Typography>
            ) : (
              heading
            ))}
          {!noBreadcrumb && <MBreadcrumbs links={links} {...other} />}
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <Link href={moreLink} target="_blank" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              sx={{ display: "table" }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}

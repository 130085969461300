import Label from "@/components/project/Label";
import { styled } from "@mui/material/styles";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

const TableHeaderStyle = styled(TableCell)(({ theme }) => ({
  minWidth: 120,
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(0.5),
  fontWeight: "bold",
  color: theme.palette.text.secondary,
  textAlign: "left",
}));

const TableRowStyle = styled(TableCell)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1.5),
  textAlign: "left",
}));

enum StatusEnum {
  Succeeded = "success",
  Failed = "error",
}

const columns = [
  { id: "status", label: "Status" },
  { id: "duration", label: "Duration Time" },
  { id: "created_at", label: "Created At" },
  { id: "finished_at", label: "Finished At" },
  { id: "pipeline_name", label: "Pipeline Name" },
];

export default function DetailTable() {
  const {
    pipelineDetail: { data: detailData = {} },
    pipelineExecutionDetail: { data },
  } = useSelector((state: RootState) => state.automation);

  const { status, created_at, finished_at, duration } = data;

  return (
    <Card sx={{ bgcolor: "background.neutral", mx: 2 }}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              {columns.map((item) => (
                <TableHeaderStyle>{item.label}</TableHeaderStyle>
              ))}
            </TableRow>
            <TableRow>
              <TableRowStyle>
                {StatusEnum[status] ? (
                  <Label color={StatusEnum[status]}>{status}</Label>
                ) : (
                  "-"
                )}
              </TableRowStyle>
              <TableRowStyle>{duration}</TableRowStyle>
              <TableRowStyle>{created_at}</TableRowStyle>
              <TableRowStyle>{finished_at}</TableRowStyle>
              <TableRowStyle
                sx={{
                  maxWidth: 250,
                  overflow: "hidden",
                  wordBreak: "break-all",
                }}
              >
                {detailData.name}
              </TableRowStyle>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

import { Divider, Stack, Typography } from "@mui/material";
import PipelineGraphPanel from "../pipelineList/PipelineCreateUseTemplateDialog/components/PipelineGraphPanel";
import { useEffect, useState } from "react";
import { RootState, dispatch } from "@/redux/store";
import { clearSchema, setSchemaData } from "../../graph/slices/slice";
import PipelineNavSection from "../pipelineList/PipelineCreateUseTemplateDialog/components/PipelineNavSection";
import sidebarConfig from "../pipelineList/PipelineCreateUseTemplateDialog/components/PipelineSidebarConfig";
import { FormikProps } from "formik";
import { IPipelineInitialValuesTypes } from "../../PipelineCreateOrEdit";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useSnackbar } from "notistack";
import {
  getPipelineStorageData,
  getPipelineTemplateVersionData,
} from "@/redux/project/template/thunks";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/material";

interface IProps {
  formik: FormikProps<IPipelineInitialValuesTypes>;
}

const templateSkeleton = (width?: number, height?: number) => {
  return (
    <Box sx={{ px: 2, py: 3, width, height }}>
      <Skeleton variant="rectangular" sx={{ width: "100%", height: "100%" }} />
    </Box>
  );
};

const PipelineTemplate = ({ formik }: IProps) => {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    pipelineTemplateTableList: { data: templateList },
  } = useSelector((state: RootState) => state.template);

  const { values, setFieldValue } = formik;

  const handleSelectTemplate = async (data) => {
    // set form value
    setFieldValue("template", data);
    try {
      if (values.type === "Kubeflow") {
        if (data.name === "blank") {
          dispatch(clearSchema(true));
        } else {
          setLoading(true);
          // get the latest template version to get commit _id
          const { items } = await dispatch(
            getPipelineTemplateVersionData({
              params: {
                mlpipeline_template_id: data.id,
                page_num: 1,
                page_size: 9999,
              },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap();

          // get the lastest pipeline template version sorted by updated_by
          const mostRecent = items.reduce((prev, cur) => {
            return new Date(cur.updated_at) > new Date(prev.updated_at)
              ? cur
              : prev;
          });

          const res = await dispatch(
            getPipelineStorageData({
              params: {
                provider: "gitlab",
                location: `pipeline-template-test`,
                // file_path: data.name,
                file_path: `${data.name}/pipeline_template.json`,
                id: mostRecent.git_commit_id,
              },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap();

          const { edges, steps } = res.content;
          dispatch(
            setSchemaData({
              schema: {
                name: "",
                tags: [],
                relationships: [],
                pipelineRoleArn: "",
                edgeEntities: edges,
                pipelineSteps: steps,
              },
            })
          );

          setLoading(false);
        }
      }
    } catch (e) {
      dispatch(clearSchema(true));
      setLoading(false);
      console.log("e", e);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearSchema(true));
    };
  }, []);

  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      flexGrow={1}
    >
      {loading ? (
        templateSkeleton(180, 450)
      ) : (
        <PipelineNavSection
          pipelineType={values.type}
          navConfig={
            values.type === "AWS SageMaker"
              ? sidebarConfig
              : templateList?.items || []
          }
          curSelectedTemplate={values.template}
          setCurSelectedTemplate={(data) => {
            handleSelectTemplate(data);
          }}
          sx={{ mt: 1, maxWidth: "15%" }}
        />
      )}

      {/* right */}
      <Stack
        flexGrow={1}
        display="flex"
        flexDirection="column"
        divider={<Divider orientation="horizontal" flexItem />}
      >
        {loading ? (
          templateSkeleton(1, 150)
        ) : (
          <Stack sx={{ px: 5 }} className="new-pipeline-template-detail">
            <Typography fontSize={22} variant="subtitle1" sx={{ mb: 2 }}>
              {values?.template?.name || "-"}
            </Typography>
            <Stack>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                Scenario
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {values?.template?.scenario || "-"}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                Description
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {values?.template?.description || "-"}
              </Typography>
            </Stack>
          </Stack>
        )}

        {loading ? templateSkeleton(1, 300) : <PipelineGraphPanel />}
      </Stack>
    </Stack>
  );
};

export default PipelineTemplate;

import request from "@/utils/project/request";

// 1. notebook
export const getNotebookTableList = (params, isAll) => {
  return request.get(`/experiment/notebook`, {
    ...params,
    ...(isAll ? {} : { is_active: 1 }),
  });
};

export const getNotebookDetail = (id) => {
  return request.get(`/experiment/notebook/${id}`);
};

export const addNotebookService = (params) => {
  return request.post(`/experiment/notebook`, { payload: params });
};

export const updateNotebookService = (id, params) => {
  return request.put(`/experiment/notebook/${id}`, { payload: params });
};

export const delNotebookService = (id) => {
  return request.put(`/experiment/notebook/${id}`, {
    payload: { is_active: 0 },
  });
};

export const addNotebookTagsService = (params) => {
  return request.post(`/experiment/rel_notebook_tag`, { payload: params });
};

export const delNotebookTagsService = (id) => {
  return request.delete(`/experiment/rel_notebook_tag/${id}`);
};

export const getNotebookTagsService = (params) => {
  return request.get(`/experiment/rel_notebook_tag`, params);
};

// 2. notebook volume
export const getNotebookVolumeTableList = (params, isAll) => {
  return request.get(`/experiment/notebook_volume`, {
    ...params,
    ...(isAll ? {} : { is_active: 1 }),
  });
};

export const getNotebookVolumeDetail = (id) => {
  return request.get(`/experiment/notebook_volume/${id}`);
};

export const addNotebookVolumeService = (params) => {
  return request.post(`/experiment/notebook_volume`, { payload: params });
};

export const editNotebookVolumeService = (id, params) => {
  return request.put(`/experiment/notebook_volume/${id}`, { payload: params });
};

export const delNotebookVolumeService = (id) => {
  return request.put(`/experiment/notebook_volume/${id}`, {
    payload: { is_active: 0 },
  });
};

// rel_notebook_notebook_volume
export const getRelNotebookVolumeService = (params) => {
  return request.get(`/experiment/rel_notebook_notebook_volume`, params);
};

export const addRelNotebookVolumeService = (params) => {
  return request.post(`/experiment/rel_notebook_notebook_volume`, {
    payload: params,
  });
};

export const delRelNotebookVolumeService = (id) => {
  return request.delete(`/experiment/rel_notebook_notebook_volume/${id}`);
};

// rel_notebook_volume_tag
export const getNotebookVolumeTagsService = (params) => {
  return request.get(`/experiment/rel_notebook_volume_tag`, params);
};

export const addNotebookVolumeTagsService = (params) => {
  return request.post(`/experiment/rel_notebook_volume_tag`, {
    payload: params,
  });
};

export const delNotebookVolumeTagsService = (id) => {
  return request.delete(`/experiment/rel_notebook_volume_tag/${id}`);
};

// 3. notebook version
export const getNotebookVersionTableList = (params) => {
  return request.get(`/experiment/notebook_version`, {
    ...params,
    is_active: 1,
  });
};

export const getNotebookVersionDetailService = (id) => {
  return request.get(`/experiment/notebook_version/${id}`);
};

export const addNotebookVersionService = (params) => {
  return request.post(`/experiment/notebook_version`, { payload: params });
};

export const editNotebookVersionService = (id, params) => {
  return request.put(`/experiment/notebook_version/${id}`, params);
};

export const delNotebookVersionService = (id) => {
  return request.put(`/experiment/notebook_version/${id}`, {
    payload: { is_active: 0 },
  });
};

// 4. hp tuner
export const getHptunerTableList = (params, isAll) => {
  return request.get(`/experiment/hptuner`, {
    ...params,
    ...(isAll ? {} : { is_active: 1 }),
  });
};

export const getHptunerDetailService = (id) => {
  return request.get(`/experiment/hptuner/${id}`);
};

export const getHptunerResultService = (params) => {
  return request.get(`/experiment/hptuner_result`, params);
};

export const addHptunerService = (params) => {
  return request.post(`/experiment/hptuner`, { payload: params });
};

/* export const updateHptunerService = (id, params) => {
  return request.get(`/experiment/hptuner/${id}`, { payload: { params } })
}; */

export const deleteHptunerService = (id, params) => {
  return request.put(`/experiment/hptuner/${id}`, {
    payload: { ...params, is_active: 0 },
  });
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik, FormikProvider, Form, Field } from "formik";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  Stack,
  Typography,
  FormHelperText,
} from "@mui/material";
import * as Yup from "yup";
import { capitalCase } from "change-case";
import { useEffect, useState } from "react";
import { RootState, dispatch } from "@/redux/store";
import {
  getProjectRoleDetail,
  getProjectRoleTableData,
} from "@/redux/project/template/thunks";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import {
  handleAddProjectRole,
  handleUpdateProjectRole,
} from "@/redux/project/administration/thunks";
import { useLocation } from "react-router";
import { LoadingButton } from "@mui/lab";
import { getProjectRolesByProjectId } from "@/services/administration";

interface IProjectRoleAddDialogProps {
  mode: "add" | "edit";
  handleRefresh: (a?: boolean) => void;
  roleDetailData: Record<string, any>;
  isOpen: boolean;
  onClose: VoidFunction;
  curProjectId: string;
}

interface FormValues {
  role_id: string;
  ad_group: string;
}

const ProjectRoleAddDialog = ({
  mode,
  handleRefresh,
  roleDetailData,
  isOpen,
  onClose,
  curProjectId,
}: IProjectRoleAddDialogProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const { pathname } = useLocation();

  const [description, setDescription] = useState<string>("");

  const {
    projectDetailRolesTableData: { data: projectRoles },
  } = useSelector((state: RootState) => state.administration);

  const {
    projectRoleTableList: { data },
  } = useSelector((state: RootState) => state.template);

  const initialValues: FormValues = {
    role_id: "",
    ad_group: "",
  };

  const validationSchema = Yup.object({
    role_id: Yup.string().required("Project Role is required"),
    ad_group: Yup.string().required("Security Group is required"),
  });

  const handleAddSubmit = async (values) => {
    // whether the data exist or not (is_active === 0)
    // exist , update
    const res = await getProjectRolesByProjectId(
      {
        // role_id: values.role_id,
        project_id: curProjectId,
        page_num: 1,
        page_size: 9999,
      },
      true
    );
    const { data: { result = {} } = {} } = res;
    const idx = result?.items?.findIndex(
      (item) => item.role_id === values.role_id
    );

    if (idx !== -1) {
      await dispatch(
        handleUpdateProjectRole({
          id: result?.items?.[idx].id,
          params: {
            project_id: curProjectId,
            ...values,
            is_active: 1,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
    } else {
      // not exist, insert
      await dispatch(
        handleAddProjectRole({
          params: {
            project_id: curProjectId,
            ...values,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
    }
  };

  const handleUpdateSubmit = (values) => {
    return dispatch(
      handleUpdateProjectRole({
        id: roleDetailData.id,
        params: {
          project_id: curProjectId,
          ...values,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  };

  const getFilteredProjectRoles = () => {
    if (mode === "edit") {
      return data?.items;
    }

    return data?.items?.filter((item) => {
      if (projectRoles?.items?.findIndex((i) => i.name === item.name) > -1) {
        return false;
      }
      return true;
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      try {
        if (mode === "edit") {
          await handleUpdateSubmit(values);
          enqueueSnackbar("Edit Success", { variant: "success" });
        } else {
          await handleAddSubmit(values);
          enqueueSnackbar("Add Success", { variant: "success" });
        }
        actions.setSubmitting(false);

        onClose();
        setDescription("");
        resetForm();
        handleRefresh(true);
      } catch (err) {
        console.log("error-in-submit", err);
        actions.setSubmitting(false);
      }
    },
  });

  const {
    // values,
    errors,
    touched,
    isSubmitting,
    setValues,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;
  // console.log('values', values);

  useEffect(() => {
    if (isOpen) {
      if (mode === "edit") {
        setValues({
          role_id: roleDetailData.role_id,
          ad_group: roleDetailData.ad_group,
        });

        setDescription(roleDetailData.description);
      }

      dispatch(
        getProjectRoleTableData({
          params: {
            page_num: 1,
            page_size: 10,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      );
    }
  }, [mode, isOpen]);

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown
      onClose={() => {
        onClose();
        resetForm();
        setDescription("");
      }}
      PaperProps={{
        sx: {
          width: "960px",
          maxWidth: "none",
          padding: "20px 40px",
        },
      }}
    >
      <FormikProvider value={formik}>
        <Form>
          <Typography variant="h6" sx={{ mb: 2 }}>{`${capitalCase(
            mode
          )} Project Role`}</Typography>

          <FormControl
            fullWidth
            margin="dense"
            size="small"
            error={touched.role_id && Boolean(errors.role_id)}
          >
            <InputLabel>Project Role</InputLabel>
            <Field
              as={Select}
              name="role_id"
              label="Project Role"
              disabled={mode === "edit"}
              onChange={async (event) => {
                await setFieldValue("role_id", event.target.value);
                setFieldTouched("role_id", true);

                dispatch(
                  getProjectRoleDetail({
                    id: event.target.value,
                    alertCallback: enqueueSnackbar,
                    pathname,
                  })
                )
                  .unwrap()
                  .then((res) => {
                    setDescription(res?.description || "-");
                  });
              }}
            >
              {getFilteredProjectRoles()?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Field>
            <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
              {touched.role_id && errors.role_id ? errors.role_id : " "}
            </FormHelperText>
          </FormControl>

          <Stack sx={{ mt: 1, mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Description
            </Typography>
            <Typography variant="body2" sx={{ pl: 2 }}>
              {description || "-"}
            </Typography>
          </Stack>

          <Field
            as={TextField}
            name="ad_group"
            label="Security Group"
            size="small"
            fullWidth
            margin="dense"
            helperText={(touched.ad_group && errors.ad_group) || " "}
            error={touched.ad_group && Boolean(errors.ad_group)}
            FormHelperTextProps={{
              sx: { minHeight: "18px", marginTop: "2px" },
            }}
          />
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ my: 4 }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              color="primary"
              sx={{ width: "200px", color: "background.paper" }}
            >
              Save
            </LoadingButton>
            <Button
              type="button"
              color="inherit"
              variant="outlined"
              onClick={() => {
                resetForm();
                onClose();
                setDescription("");
              }}
              sx={{ width: "200px", color: "text.secondary" }}
            >
              Cancel
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default ProjectRoleAddDialog;

import { useEffect } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import { useDispatch } from "react-redux";
// material
import { Paper, Stack, IconButton, MenuItem } from "@mui/material";

import { MlPipelineTextfield } from "../../../../../components/PipelineComponents";
import { handleKubeflowStepArray } from "@/pages/project/automation/MlPipeline/graph/slices/slice";
import { FeatureSetUploadInputItem as typeFeatureSetUploadInputItem } from "@/@types/project/mlPipeline/Kubeflow/pipeline-featureset-upload";
import MlPipelineParameterEntry from "../../../../../components/MlPipelineParameterEntry";
// import MlPipelineParameterEntry from "../../../../../components/MlPipelineParameterEntry";

const typeOptions = [
  { code: "String", label: "String" },
  { code: "Integer", label: "Integer" },
  { code: "Float", label: "Float" },
  { code: "CSV", label: "CSV" },
  { code: "ZIP", label: "ZIP" },
];

export default function FeatureSetUploadInputItem({
  id,
  data,
  isEdit,
  length,
}: {
  id: number;
  data: typeFeatureSetUploadInputItem;
  isEdit: boolean;
  length: number;
}) {
  const dispatch = useDispatch();
  const ParamSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    value: Yup.string().required("Value is required"),
  });

  const formik = useFormik({
    initialValues: data,
    validationSchema: ParamSchema,
    onSubmit: () => {},
  });

  const submitFunc = (fieldName, value) => {
    try {
      dispatch(
        handleKubeflowStepArray({
          handleType: "edit",
          id,
          data: {
            [fieldName]: value,
          },
          key: "inputs",
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const { setValues } = formik;

  useEffect(() => {
    setValues(data);
  }, [length]);

  return (
    <>
      <Paper sx={{ p: 1.5, borderRadius: 1.5, bgcolor: "background.neutral" }}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off">
            <Stack spacing={2}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <MlPipelineTextfield
                  label={"Name"}
                  field={"name"}
                  disabled={isEdit}
                  formik={formik}
                  submitThisValuefunc={(value) => submitFunc("name", value)}
                />

                <IconButton
                  size="small"
                  disabled={isEdit}
                  onClick={() => {
                    dispatch(
                      handleKubeflowStepArray({
                        id,
                        handleType: "delete",
                        key: "inputs",
                      })
                    );
                  }}
                >
                  <Icon icon={trash2Fill} width={18} height={18} />
                </IconButton>
              </Stack>

              <MlPipelineParameterEntry
                label={"Value"}
                field={"value"}
                disabled={isEdit}
                fieldUseParam={"useParam"}
                formik={formik}
                submitThisValuefunc={(fieldName, value) =>
                  submitFunc(fieldName, value)
                }
              />

              <MlPipelineTextfield
                select
                label={"Type"}
                field={"dataType"}
                disabled={isEdit}
                formik={formik}
                submitThisValuefunc={(value) => submitFunc("dataType", value)}
              >
                {typeOptions.map((item) => (
                  <MenuItem key={item.label} value={item.label}>
                    {item.label}
                  </MenuItem>
                ))}
              </MlPipelineTextfield>

              <MlPipelineTextfield
                label={"Description (Optional)"}
                field={"description"}
                disabled={isEdit}
                formik={formik}
                submitThisValuefunc={(value) =>
                  submitFunc("description", value)
                }
              />
            </Stack>
          </Form>
        </FormikProvider>
      </Paper>
    </>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { addThunkReducers } from "../../utils";
import { IModelRegistryState } from "@/@types/project/model/modelRegistry";
import {
  getModelPackageList,
  getModelPackageVersionList,
  getModelPackageDescribe,
  getModelPackageVersionDescribeList,
  getConnectionById,
  getModelPackageTagList,
  getConnectionList,
  getProjectList,
  getSynchronizationList,
  getSynchronizationJobList,
  getStatisticList,
  getCredentialList,
  getTagList,
} from "./thunks";

const initialState: IModelRegistryState = {
  modelPackageDatas: {
    loading: false,
    data: {},
    error: null,
  },
  modelPackageVersionDatas: {
    loading: false,
    data: {},
    error: null,
  },
  modelPackageDescribeDatas: {
    loading: false,
    data: {},
    error: null,
  },
  modelPackageVersionDescribeDatas: {
    loading: false,
    data: {},
    error: null,
  },
  connectionDatas: {
    loading: false,
    data: {},
    error: null,
  },
  tagDatas: {
    loading: false,
    data: {},
    error: null,
  },
  modelPackageTagDatas: {
    loading: false,
    data: {},
    error: null,
  },
  connectionListDatas: {
    loading: false,
    data: {},
    error: null,
  },
  projectDatas: {
    loading: false,
    data: {},
    error: null,
  },
  synchronizationDatas: {
    loading: false,
    data: {},
    error: null,
  },
  synchronizationJobDatas: {
    loading: false,
    data: {},
    error: null,
  },
  modelPackageEditData: {
    loading: false,
    data: {},
    error: null,
  },
  statisticDatas: {
    loading: false,
    data: {},
    error: null,
  },
  credentialDatas: {
    loading: false,
    data: {},
    error: null,
  },
};

const slice = createSlice({
  name: "modelRegistry",
  initialState,
  reducers: {
    setModelPackageData(state, action) {
      Object.assign(state, action.payload);
    },
    setModelPackageVersionData(state, action) {
      Object.assign(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    addThunkReducers(builder, [
      { action: getModelPackageList, name: "modelPackageDatas" },
      { action: getModelPackageVersionList, name: "modelPackageVersionDatas" },
      { action: getModelPackageDescribe, name: "modelPackageDescribeDatas" },
      {
        action: getModelPackageVersionDescribeList,
        name: "modelPackageVersionDescribeDatas",
      },
      { action: getConnectionById, name: "connectionDatas" },
      { action: getTagList, name: "tagDatas" },
      { action: getModelPackageTagList, name: "modelPackageTagDatas" },
      { action: getConnectionList, name: "connectionListDatas" },
      { action: getProjectList, name: "projectDatas" },
      { action: getSynchronizationList, name: "synchronizationDatas" },
      { action: getSynchronizationJobList, name: "synchronizationJobDatas" },
      { action: getStatisticList, name: "statisticDatas" },
      { action: getCredentialList, name: "credentialDatas" },
    ]);
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setModelPackageData, setModelPackageVersionData } =
  slice.actions;

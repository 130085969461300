/* eslint-disable react-hooks/exhaustive-deps */
import Page from "@/components/project/Page";
import { getNotebookDetailData } from "@/redux/project/experiment/thunks";
import { RootState, dispatch } from "@/redux/store";
import { PATH_DASHBOARD } from "@/routes/paths";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import qs from "qs";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { resetNotebookData } from "@/redux/project/experiment/slice";
import MlIframe from "@/components/project/mlComponents/MlIframe";

const NoteBookIframe = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname, search } = useLocation();

  const { notebookId } = useParams();

  const queryParams = qs.parse(search, { ignoreQueryPrefix: true });

  const {
    notebookDetail: { data },
  } = useSelector((state: RootState) => state.experiment);

  const getLinks = () => {
    return [
      {
        name: "Experiment",
      },
      {
        name: "Notebook",
        href: PATH_DASHBOARD.experiment.notebook,
      },
      {
        name: data?.name || "",
      },
    ];
  };

  useEffect(() => {
    dispatch(
      getNotebookDetailData({
        id: notebookId!,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );

    return () => {
      dispatch(resetNotebookData("notebookDetail"));
    };
  }, []);

  return (
    <Page
      noTitle
      title={`${data?.name} | OmniML`}
      heading={data?.name || ""}
      links={getLinks()}
      height="100%"
    >
      <MlIframe src={queryParams.url as string} />
    </Page>
  );
};

export default NoteBookIframe;

import { createSlice } from "@reduxjs/toolkit";
import { addThunkReducers } from "../../utils";
import { IMlRuntimeState } from "@/@types/project/model/mlRuntime";
import {
  getMlRuntimeList,
  getServiceDeploymentList,
  getModelPackageList,
  getModelPackageById,
  getServiceDeploymentById,
  getServiceInferenceList,
  getModelPackageVersionList,
  getMetricsTemplateList,
  getMlRuntimeById,
  getMetricsList,
  getStatisticTotalList,
  getStatisticDailyList,
  getTagList,
  getRelTagServiceList,
  getRelMlServiceMetricsTemplateList,
} from "./thunks";

const initialState: IMlRuntimeState = {
  mlRuntimeTableList: {
    loading: false,
    data: {},
    error: null,
  },
  mlRuntimeDetail: {
    loading: false,
    data: {},
    error: null,
  },
  mlServiceDeploymentList: {
    loading: false,
    data: {},
    error: null,
  },
  modelPackageList: {
    loading: false,
    data: {},
    error: null,
  },
  modelPackageDatas: {
    loading: false,
    data: {},
    error: null,
  },
  modelPackageVersionList: {
    loading: false,
    data: {},
    error: null,
  },
  mlServiceVersionDescribe: {
    loading: false,
    data: {},
    error: null,
  },
  serviceInferenceResultList: {
    loading: false,
    data: {},
    error: null,
  },
  metricsTemplateList: {
    loading: false,
    data: {},
    error: null,
  },
  metricsList: {
    loading: false,
    data: {},
    error: null,
  },
  statisticTotalView: {
    loading: false,
    data: {},
    error: null,
  },
  statisticDailyView: {
    loading: false,
    data: {},
    error: null,
  },
  tagDatas: {
    loading: false,
    data: {},
    error: null,
  },
  serviceTagDatas: {
    loading: false,
    data: {},
    error: null,
  },
  relMetricsTemplateList: {
    loading: false,
    data: {},
    error: null,
  },
};

const slice = createSlice({
  name: "mlRuntime",
  initialState,
  reducers: {
    setMlRuntimeData(state, action) {
      Object.assign(state, action.payload);
    },
    setServiceDeploymentData(state, action) {
      Object.assign(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    addThunkReducers(builder, [
      { action: getMlRuntimeList, name: "mlRuntimeTableList" },
      { action: getMlRuntimeById, name: "mlRuntimeDetail" },
      { action: getServiceDeploymentList, name: "mlServiceDeploymentList" },
      { action: getModelPackageList, name: "modelPackageList" },
      { action: getModelPackageById, name: "modelPackageDatas" },
      { action: getTagList, name: "tagDatas" },
      { action: getRelTagServiceList, name: "serviceTagDatas" },
      { action: getModelPackageVersionList, name: "modelPackageVersionList" },
      { action: getServiceDeploymentById, name: "mlServiceVersionDescribe" },
      { action: getServiceInferenceList, name: "serviceInferenceResultList" },
      { action: getMetricsTemplateList, name: "metricsTemplateList" },
      { action: getMetricsList, name: "metricsList" },
      { action: getStatisticTotalList, name: "statisticTotalView" },
      { action: getStatisticDailyList, name: "statisticDailyView" },
      {
        action: getRelMlServiceMetricsTemplateList,
        name: "relMetricsTemplateList",
      },
    ]);
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setMlRuntimeData, setServiceDeploymentData } = slice.actions;

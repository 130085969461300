import { capitalize } from "lodash";
import {
  JobArgument,
  EnvVariable,
  StepCodeStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline";
import {
  ProcessingStep,
  ProcessingArguments,
  PropertyFileStruct,
  ProcessingInputStruct,
  ProcessingOutputStruct,
  ProcessingProcessorStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-processing";
import {
  parseUri,
  checkUseParam,
  // parseFrameworkVersion,
} from "./helper-functions";

// ----------------------------------------------------------------------

export default function parseProcessingStep(
  index: number,
  pipelineStep: any,
  stepPairs: [string, string, string][]
) {
  const args = pipelineStep.Arguments as ProcessingArguments;
  // console.log(args);
  const step = {
    id: String(index),
    type: "Processing",
    name: pipelineStep.Name,
    processor: parseProcessorProps(
      args.ProcessingResources,
      args.AppSpecification,
      args.Environment
    ),
    processingInputs: parseProcessingInputs(
      args.ProcessingInputs,
      pipelineStep.Name,
      stepPairs
    ),
    processingOutputs: parseProcessingOutputs(args.ProcessingOutputConfig),
    propertyFiles: parsePropertyFiles(pipelineStep.PropertyFiles),
    code: parseProcessorCode(
      args.ProcessingInputs,
      args.AppSpecification,
      pipelineStep.Name,
      stepPairs
    ),
    networkConfig: parseNetworkConfig(args.NetworkConfig),
    nodeX: 220 * index,
    nodeY: 100,
    tags: [],
    properties: [],
    stepType: "Processing",
  } as ProcessingStep;

  return step;
}

const parseProcessorProps = (resources: any, appSpec: any, env: any) => {
  const processorProperties: ProcessingProcessorStruct = {
    processorType: parseProcessor(appSpec.ImageUri),
    processorTypeUseParam: checkUseParam(appSpec.ImageUri),
    // frameworkVersion: parseFrameworkVersion(appSpec.ImageUri),
    frameworkVersion: "",
    instanceType: parseUri(resources.ClusterConfig.InstanceType),
    instanceTypeUseParam: checkUseParam(resources.ClusterConfig.InstanceType),
    instanceCount: parseUri(resources.ClusterConfig.InstanceCount),
    instanceCountUseParam: checkUseParam(resources.ClusterConfig.InstanceCount),
    volumeSizeInGb: parseUri(resources.ClusterConfig.VolumeSizeInGB),
    volumeSizeInGbUseParam: checkUseParam(
      resources.ClusterConfig.VolumeSizeInGB
    ),
    environmentVars: [],
  };

  // if (typeof resources.ClusterConfig.InstanceType === "object") {
  //   processorProperties.instanceType =
  //     resources.ClusterConfig.InstanceType.Get.split(".")[1];
  //   processorProperties.instanceTypeUseParam = true;
  // } else if (typeof resources.ClusterConfig.InstanceType === "string") {
  //   processorProperties.instanceType = resources.ClusterConfig.InstanceType;
  // }

  // if (typeof resources.ClusterConfig.InstanceCount === "object") {
  //   processorProperties.instanceCount =
  //     resources.ClusterConfig.InstanceCount.Get.split(".")[1];
  //   processorProperties.instanceCountUseParam = true;
  // } else if (typeof resources.ClusterConfig.InstanceCount === "number") {
  //   processorProperties.instanceCount = resources.ClusterConfig.InstanceCount;
  // }

  // if (typeof resources.ClusterConfig.VolumeSizeInGB === "object") {
  //   processorProperties.volumeSizeInGb =
  //     resources.ClusterConfig.VolumeSizeInGB.Get.split(".")[1];
  //   processorProperties.volumeSizeInGbUseParam = true;
  // } else if (typeof resources.ClusterConfig.VolumeSizeInGB === "number") {
  //   processorProperties.volumeSizeInGb = resources.ClusterConfig.VolumeSizeInGB;
  // }

  if (env !== undefined) {
    const environmentVars: Array<EnvVariable> = [];
    for (const [_key, _value] of Object.entries(env)) {
      environmentVars.push({ name: _key, value: String(_value) });
    }
    processorProperties.environmentVars = environmentVars;
  }
  return processorProperties;
};

const parseProcessingInputs = (
  inputs: any,
  stepName: string,
  stepPairs: [string, string, string][]
) => {
  const processingInputs: Array<ProcessingInputStruct> = [];
  for (let input of inputs) {
    // console.log(input);
    if (input.InputName !== "code") {
      // let s3Src = input.S3Input.S3Uri;
      // if (typeof s3Src === "object") {
      //   const s3UriItems = s3Src.Get.split(".");
      //   if (s3UriItems[0] === "Steps") {
      //     // Dependent steps
      //     if (s3UriItems[2] === "ProcessingOutputConfig") {
      //       // Processing
      //       s3Src = `${s3UriItems[1]}.${s3UriItems[2].replace("Config", "")}s.${
      //         s3UriItems[3].split("'")[1]
      //       }`;
      //     } else if (s3UriItems[2] === "ModelArtifacts") {
      //       // Training
      //       s3Src = `${s3UriItems[1]}.ModelArtifacts.S3ModelArtifacts`;
      //     }
      //     addStepPairs(
      //       stepPairs,
      //       s3UriItems[1],
      //       stepName,
      //       s3Src.replace(`${s3UriItems[1]}.`, "") +
      //         "&&" +
      //         `ProcessingInputs.${input.InputName}`
      //     );
      //   } else {
      //     // Pipeline parameters
      //     s3Src = s3UriItems[1];
      //   }
      // }
      processingInputs.push({
        inputName: input.InputName,
        source: parseUri(
          input.S3Input.S3Uri,
          stepName,
          stepPairs,
          `ProcessingInputs.${input.InputName}`
        ),
        sourceUseParam: checkUseParam(input.S3Input.S3Uri),
        destination: input.S3Input.LocalPath,
        destinationUseParam: checkUseParam(input.S3Input.LocalPath),
        s3DataDistributionType: input.S3Input.S3DataDistributionType,
        s3CompressionType: input.S3Input.S3CompressionType,
      });
    }
  }
  return processingInputs;
};

const parseProcessingOutputs = (OutputConfig: any) => {
  if (OutputConfig === undefined || OutputConfig.Outputs === undefined)
    return [];

  const processingOutputs: Array<ProcessingOutputStruct> = [];
  for (let output of OutputConfig.Outputs) {
    processingOutputs.push({
      outputName: output.OutputName,
      source: output.S3Output.LocalPath,
      sourceUseParam: checkUseParam(output.S3Output.LocalPath),
      destination: parseUri(output.S3Output.S3Uri),
      destinationUseParam: checkUseParam(output.S3Output.S3Uri),
      s3UploadMode: output.S3Output.S3UploadMode,
    });
  }
  return processingOutputs;
};

const parsePropertyFiles = (propertyFileConfig: any) => {
  if (propertyFileConfig === undefined) return [];

  const propertyFiles: Array<PropertyFileStruct> = [];
  for (let propertyFile of propertyFileConfig) {
    propertyFiles.push({
      name: propertyFile.PropertyFileName,
      outputName: propertyFile.OutputName,
      path: propertyFile.FilePath,
    });
  }
  return propertyFiles;
};

const parseNetworkConfig = (networkConfig: any) => {
  if (networkConfig === undefined)
    return {
      enableNetworkIsolation: "",
      enableNetworkIsolationUseParam: false,
      securityGroupIds: "",
      securityGroupIdsUseParam: false,
      subnets: "",
      subnetsUseParam: false,
      encryptInterContainerTraffic: "",
      encryptInterContainerTrafficUseParam: false,
    };

  // NetworkConfigStruct;
  const tmp1 = parseUri(networkConfig.EnableNetworkIsolation);
  const tmp2 = parseUri(networkConfig.EncryptInterContainerTraffic);
  return {
    enableNetworkIsolation: [false, true].includes(tmp1)
      ? capitalize(tmp1)
      : tmp1,
    enableNetworkIsolationUseParam: checkUseParam(
      networkConfig.EnableNetworkIsolation
    ),
    securityGroupIds: networkConfig.VpcConfig
      ? parseSecurityGroupIds(networkConfig.VpcConfig.SecurityGroupIds)
      : "",
    securityGroupIdsUseParam: networkConfig.VpcConfig
      ? checkSecurityGroupIdsUseParam(networkConfig.VpcConfig.SecurityGroupIds)
      : false,
    subnets: networkConfig.VpcConfig
      ? parseSecurityGroupIds(networkConfig.VpcConfig.Subnets)
      : "",
    subnetsUseParam: networkConfig.VpcConfig
      ? checkSecurityGroupIdsUseParam(networkConfig.VpcConfig.Subnets)
      : false,
    encryptInterContainerTraffic: [false, true].includes(tmp2)
      ? capitalize(tmp2)
      : tmp2,
    encryptInterContainerTrafficUseParam: checkUseParam(
      networkConfig.EncryptInterContainerTraffic
    ),
  };
};

const parseSecurityGroupIds = (input: any[] | undefined) => {
  if (input === undefined || input.length === 0) {
    return "";
  } else if (input.length === 1) {
    return parseUri(input[0]);
  } else {
    return input.join(",");
  }
};

const checkSecurityGroupIdsUseParam = (input: any[] | undefined) => {
  if (input === undefined || input.length === 0) {
    return false;
  } else if (input.length === 1) {
    return checkUseParam(input[0]);
  } else {
    return false;
  }
};

const parseProcessorCode = (
  inputs: any,
  appSpec: any,
  stepName: string,
  stepPairs: [string, string, string][]
) => {
  const jobArgs: Array<JobArgument> = [];
  if (appSpec.ContainerArguments !== undefined) {
    if (appSpec.ContainerArguments.length % 2 === 1) {
      for (let i = 0; i < appSpec.ContainerArguments.length / 2; i++) {
        // console.log(appSpec.ContainerArguments[2 * i + 1]);
        // console.log(typeof appSpec.ContainerArguments[2 * i + 1]);
        if (
          typeof appSpec.ContainerArguments[2 * i + 1] === "string" &&
          appSpec.ContainerArguments[2 * i + 1].startsWith("--")
        ) {
          appSpec.ContainerArguments.splice(2 * i + 1, 0, "");
        }
      }
    }
    // console.log(appSpec.ContainerArguments);
    for (let i = 0; i < appSpec.ContainerArguments.length / 2; i++) {
      jobArgs.push({
        name: appSpec.ContainerArguments[2 * i],
        value: parseUri(
          appSpec.ContainerArguments[2 * i + 1],
          stepName,
          stepPairs,
          `${appSpec.ContainerArguments[2 * i + 1]}`
        ),
        useParam: checkUseParam(appSpec.ContainerArguments[2 * i + 1]),
      });
    }
  }
  const codeProperties: StepCodeStruct = {
    source: "amazon_s3",
    path: "",
    includeDir: false,
    jobArgs: jobArgs,
  };

  for (let input of inputs) {
    if (input.InputName === "code") {
      codeProperties.path = input.S3Input.S3Uri;
      break;
    }
  }

  return codeProperties;
};

// const parseProcessor = (imageName: string) => {
//   if (imageName.includes("spark")) return "PySparkProcessor";
//   else if (imageName.includes("xgboost")) return "XGBoostProcessor";
//   else if (imageName.includes("tensorflow")) return "TensorFlow";
//   return "SKLearnProcessor";
// };

const parseProcessor = (imageName: string) => {
  const uri = parseUri(imageName);
  return uri;
  // const items = uri.split("/");
  // if (items.length > 0) return items[1];
  // else return items[0];
};

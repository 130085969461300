import { Grid, IconButton, ListItemText, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { DeleteIcon } from "@/assets/project/icons";
import { ListItemIcon } from "@mui/material";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { dispatch } from "@/redux/store";
import { handleDeleteProjectRole } from "@/redux/project/administration/thunks";

interface IProps {
  row: {
    [key: string]: any;
  };
  handleRefresh: (a?: boolean) => void;
}

const ProjectRolesTableActionCol = ({ row, handleRefresh }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // delete confirm
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);

  // dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelRole = async () => {
    setDelLoading(true);
    try {
      // del rel_project_role with hash_id
      await dispatch(
        handleDeleteProjectRole({
          id: row.id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      setDelLoading(false);
      setOpenConfirm(false);
      enqueueSnackbar("Delete Success", { variant: "success" });

      handleRefresh(true);
    } catch (e) {
      setDelLoading(false);
    }
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={6} lg={6}>
          <IconButton onClick={() => navigate(`${row.role_id}`)}>
            <KeyboardArrowRightOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6} lg={6}>
          <IconButton onClick={handleClick}>
            <MoreVertOutlinedIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              disabled={row.name === "Project Leader"}
              onClick={() => {
                setOpenConfirm(true);
                // must pop a confirm dialog
                handleClose();
              }}
              sx={{ color: "error.main" }}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <SvgIconStyle
                  src={DeleteIcon}
                  sx={{
                    width: 22,
                    height: 22,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Delete"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          </Menu>

          <ConfirmDialog
            open={openConfirm}
            onClose={() => setOpenConfirm(false)}
            title="Delete"
            content={
              <>
                Are you sure to delete role <strong> {row.name} </strong> item?
              </>
            }
            action={
              <LoadingButton
                color="error"
                sx={{ width: "80px" }}
                onClick={() => {
                  handleDelRole();
                }}
                loading={delLoading}
                // loadingPosition="start" // must use with startIcon
                variant="contained"
              >
                Delete
              </LoadingButton>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectRolesTableActionCol;

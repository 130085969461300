/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@iconify/react";
import { CollapseButtonStyle } from "../../../../SettingSidebar";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useEffect, useState } from "react";
import { useDispatch } from "@/redux/store";
import { Collapse, Stack } from "@mui/material";
import { MlPipelineTextfield } from "../../../../components/PipelineComponents";
import { updateStepAttrs } from "@/pages/project/automation/MlPipeline/graph/slices/slice";
import { TrainingStep } from "@/@types/project/mlPipeline/Kubeflow/pipeline-training";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

type Props = {
  currentStep: TrainingStep | null;
  isEdit: boolean;
};

const StepConfig = ({ currentStep, isEdit }: Props) => {
  const dispatch = useDispatch();
  const [showStepConfig, setShowStepConfig] = useState(true);

  const initialValues = {
    name: currentStep?.name || "",
    codePath: currentStep?.codePath || "",
    description: currentStep?.description || "",
  };

  const ParamSchema = Yup.object().shape({
    name: Yup.string().required("Step Name is required"),
    codePath: Yup.string().required("Code Path is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ParamSchema,
    onSubmit: () => {},
  });

  // same node type，but different node data
  useEffect(() => {
    formik.resetForm();
    formik.setValues(initialValues);
  }, [currentStep?.id]);

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setShowStepConfig((prev) => !prev)}
        endIcon={
          <Icon
            icon={showStepConfig ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
      >
        STEP CONFIG
      </CollapseButtonStyle>

      <Collapse in={showStepConfig}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off">
            <Stack spacing={2} sx={{ mx: 2, my: 1, mb: 1.5 }}>
              <MlPipelineTextfield
                label={"Step Name"}
                field={"name"}
                disabled={isEdit}
                formik={formik}
                submitThisValuefunc={(value) =>
                  dispatch(updateStepAttrs({ name: value }))
                }
              />

              <MlPipelineTextfield
                label={"Code Path"}
                field={"codePath"}
                disabled={isEdit}
                formik={formik}
                submitThisValuefunc={(value) =>
                  dispatch(updateStepAttrs({ codePath: value }))
                }
              />

              <MlPipelineTextfield
                label={"Description (Optional)"}
                field={"description"}
                disabled={isEdit}
                multiline
                rows={3}
                formik={formik}
                submitThisValuefunc={(value) =>
                  dispatch(updateStepAttrs({ description: value }))
                }
              />
            </Stack>
          </Form>
        </FormikProvider>
      </Collapse>
    </>
  );
};

export default StepConfig;

import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import MlCard from "@/components/project/mlComponents/MlCard";
import Scrollbar from "@/components/project/Scrollbar";
import { extractKeys } from "@/utils/project/utils";
import { useLocation, useNavigate, useParams } from "react-router";
import { RootState, dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import MlTableBodyContainer from "@/components/project/mlComponents/MlTableBodyContainer";
import Label from "@/components/project/Label";
import PipelineVersionTableActionCol from "./PipelineVersionTableActionCol";
import { getPipelineVersionTableData } from "@/redux/project/automation/thunks";
import { resetCurrentKubeflowPipeline } from "@/redux/project/automation/pipeline";

interface Column {
  id: string;
  label?: string;
  width?: number;
  minWidth?: number;
}

enum StatusEnum {
  started = "success",
  closed = "default",
  updated = "secondary",
  failed = "error",
}

const columns: Column[] = [
  { id: "name", label: "Version", minWidth: 150 },
  { id: "created_at", label: "Created By" },
  { id: "updated_at", label: "Last Modified" },
  { id: "status", label: "Status" },
  { id: "", width: 150 },
];

const PipelineVersionTable = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { mlPipelineId } = useParams();

  const [page_num, setPageNum] = useState<number>(1);
  const [page_size, setPageSize] = useState<number>(10);

  const {
    pipelineVersionTableList: { data, loading },
  } = useSelector((state: RootState) => state.automation);

  const fetchData = (params: { page_num: number; page_size: number }) => {
    return dispatch(
      getPipelineVersionTableData({
        params: {
          mlpipeline_id: mlPipelineId!,
          ...params,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  };

  const handleRefresh = (isUpdate?: boolean) => {
    setPageNum(1);
    setPageSize(10);

    fetchData({ page_num: 1, page_size: 10 }).then(() => {
      if (!isUpdate) {
        enqueueSnackbar("Refresh Success", { variant: "success" });
      }
    });
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNum(newPage + 1);
    fetchData({ page_num: newPage + 1, page_size });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNum(1);

    fetchData({ page_num: 1, page_size: parseInt(event.target.value, 10) });
  };

  const renderCell = (row, column) => {
    switch (column.id) {
      case "name":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Stack direction="row" spacing={2}>
              <Typography variant="subtitle2">{row[column.id]}</Typography>
              {row?.active_version === 1 && (
                <Label color="success" variant="ghost">
                  Activated
                </Label>
              )}
            </Stack>
          </TableCell>
        );
      case "status":
        const status = row[column.id];
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width", "maxWidth") }}
          >
            {status ? <Label color={StatusEnum[status]}>{status}</Label> : "-"}
          </TableCell>
        );
      case "":
        return (
          <TableCell
            align="right"
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <PipelineVersionTableActionCol
              row={row}
              handleRefresh={handleRefresh}
            />
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {row[column.id] || "-"}
            </Typography>
          </TableCell>
        );
    }
  };

  const handleAddVersion = () => {
    navigate("createVersion");
  };

  useEffect(() => {
    dispatch(
      getPipelineVersionTableData({
        params: {
          mlpipeline_id: mlPipelineId!,
          page_num,
          page_size,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  }, []);

  return (
    <MlCard
      title="Version"
      extraJsx={
        <Stack direction="row" alignItems="center" spacing={1}>
          <Button
            variant="contained"
            sx={{ width: 120 }}
            onClick={() => {
              dispatch(resetCurrentKubeflowPipeline());

              handleAddVersion();
            }}
          >
            Add Version
          </Button>

          <IconButton onClick={() => handleRefresh()}>
            <RefreshOutlinedIcon />
          </IconButton>
        </Stack>
      }
      hasPaddingX={false}
    >
      <Scrollbar>
        <TableContainer sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <MlTableBodyContainer loading={loading} data={data?.items}>
                {data?.items?.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((column) => renderCell(row, column))}
                  </TableRow>
                ))}
              </MlTableBodyContainer>
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25]}
        component="div"
        count={data?.total || 0}
        rowsPerPage={page_size}
        page={page_num - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </MlCard>
  );
};

export default PipelineVersionTable;

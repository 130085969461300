import { checkCode, handleErrorMessage } from "@/utils/project/request";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAppGalleryDatas,
  getAppGalleryById,
  createAppGallery,
  updateAppGallery,
  createAppVersion,
  updateAppVersion,
  getAppVersion,
  getTagDatas,
  createTag,
  createRelTagApp,
  getRelTagApp,
  deleteRelTagApp,
  uploadFile,
  getFile,
  deleteFile,
} from "@/services/appGallery";
import { MlParams } from "@/@types/project/global";

// ml app
interface IGetAppGalleryParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
    name?: string;
    is_active?: number;
    scenario?: string;
  };
}
export const getAppGalleryList = createAsyncThunk(
  "appGallery/getAppGalleryList",
  async (
    { params, alertCallback, pathname }: IGetAppGalleryParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getAppGalleryDatas(params);
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching App Gallery failed");
    }
  }
);

interface IGetAppGalleryByIdParams extends MlParams {
  id: string | undefined;
}
export const getAppGalleryDetail = createAsyncThunk(
  "appGallery/getAppGalleryDetail",
  async (
    { id, alertCallback, pathname }: IGetAppGalleryByIdParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getAppGalleryById(id);
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching App Gallery Detail failed");
    }
  }
);

interface ICreateAppGalleryParams extends MlParams {
  params: Record<string, any>;
}
export const addAppGallery = createAsyncThunk(
  "appGallery/addAppGallery",
  async (
    { params, alertCallback, pathname }: ICreateAppGalleryParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createAppGallery({ payload: params });
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Create App Gallery failed");
    }
  }
);
interface IUpdateAppGalleryParams extends MlParams {
  id: string | undefined;
  params: Record<string, any>;
}
export const editAppGallery = createAsyncThunk(
  "appGallery/editAppGallery",
  async (
    { id, params, alertCallback, pathname }: IUpdateAppGalleryParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await updateAppGallery(id, { payload: params });
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Update App Gallery failed");
    }
  }
);

// ml app version
interface IGetAppVersionParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
    mlapp_id?: string;
    is_active: number;
  };
}
export const getAppVersionList = createAsyncThunk(
  "appGallery/getAppVersionList",
  async (
    { params, alertCallback, pathname }: IGetAppVersionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getAppVersion(params);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching App Gallery Version failed");
    }
  }
);

interface ICreateAppVersionParams extends MlParams {
  params: Record<string, any>;
}
export const addAppVersion = createAsyncThunk(
  "appGallery/addAppVersion",
  async (
    { params, alertCallback, pathname }: ICreateAppVersionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createAppVersion({ payload: params });
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Create App Gallery Version failed");
    }
  }
);

interface IUpdateAppVersionParams extends MlParams {
  id: string | undefined;
  params: Record<string, any>;
}
export const editAppVersion = createAsyncThunk(
  "appGallery/editAppVersion",
  async (
    { id, params, alertCallback, pathname }: IUpdateAppVersionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await updateAppVersion(id, { payload: params });
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Update App Version failed");
    }
  }
);

// tags
interface IGetTagListParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
    is_active: number;
  };
}
export const getTagList = createAsyncThunk(
  "appGallery/getTagList",
  async (
    { params, alertCallback, pathname }: IGetTagListParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getTagDatas(params);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Tags failed");
    }
  }
);

interface ICreateTagParams extends MlParams {
  params: Record<string, any>;
}
export const addTag = createAsyncThunk(
  "appGallery/addTag",
  async (
    { params, alertCallback, pathname }: ICreateTagParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createTag({ payload: params });
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Create Tag failed");
    }
  }
);

interface ICreateRelTagAppParams extends MlParams {
  params: {
    tag_id: string;
    mlapp_id: string | undefined;
    is_active: number;
  };
}
export const addRelTagApp = createAsyncThunk(
  "appGallery/addRelTagApp",
  async (
    { params, alertCallback, pathname }: ICreateRelTagAppParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createRelTagApp({ payload: params });
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Create Tag and Mlapp relation failed");
    }
  }
);

interface IGetRelTagAppListParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
    is_active: number;
    mlapp_id: string | undefined;
  };
}
export const getRelTagAppList = createAsyncThunk(
  "appGallery/getRelTagAppList",
  async (
    { params, alertCallback, pathname }: IGetRelTagAppListParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getRelTagApp(params);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Rel_Tag_App failed");
    }
  }
);

interface IDelRelTagAppParams extends MlParams {
  id: string;
  params: {
    is_active: number;
  };
}

export const delRelTagApp = createAsyncThunk(
  "appGallery/delRelTagApp",
  async (
    { id, params, alertCallback, pathname }: IDelRelTagAppParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await deleteRelTagApp(id, { payload: params });
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete app tag failed");
    }
  }
);

// files
interface IUploadFileParams extends MlParams {
  formData: FormData;
}
export const uploadImage = createAsyncThunk(
  "appGallery/uploadImage",
  async (
    { formData, alertCallback, pathname }: IUploadFileParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await uploadFile(formData);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Upload image failed");
    }
  }
);

interface IGetFileParams extends MlParams {
  params: {
    s3_key: string;
  };
}
export const getImage = createAsyncThunk(
  "appGallery/getImage",
  async (
    { params, alertCallback, pathname }: IGetFileParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getFile({ payload: params });
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching image failed");
    }
  }
);
interface IDeleteFileParams extends MlParams {
  s3_key: string;
}
export const delImage = createAsyncThunk(
  "appGallery/delImage",
  async (
    { s3_key, alertCallback, pathname }: IDeleteFileParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await deleteFile(s3_key);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete image failed");
    }
  }
);

import * as Yup from "yup";
import { Icon } from "@iconify/react";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Stack, Collapse } from "@mui/material";
//
import { updateClarifyChecDataConfig } from "../../../graph/slices/slice";
import {
  ClarifyCheckStep,
  ClarifyCheckDataConfigStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-clarify-check";
import { contentOptions } from "../helper-functions";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { PipelineSelect } from "../../components/PipelineComponents";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";

// ----------------------------------------------------------------------

type Props = {
  currentStep: ClarifyCheckStep | null;
};

export default function DataConfig({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [showProcessor, setShowProcessorr] = useState(false);

  const ParamSchema = Yup.object().shape({
    s3InputDataUrl: Yup.string().required("Dataset is required"),
  });

  const formik = useFormik<ClarifyCheckDataConfigStruct>({
    initialValues: {
      s3InputDataUrl: currentStep?.dataConfig.s3InputDataUrl || "",
      s3InputDataUrlUseParam:
        currentStep?.dataConfig.s3InputDataUrlUseParam || false,
      s3OutputUrl: currentStep?.dataConfig.s3OutputUrl || "",
      s3OutputUrlUseParam: currentStep?.dataConfig.s3OutputUrlUseParam || false,
      label: currentStep?.dataConfig.label || "",
      labelUseParam: currentStep?.dataConfig.labelUseParam || false,
      contentType: currentStep?.dataConfig.contentType || "text/csv",
    },
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values) => {
      try {
        dispatch(updateClarifyChecDataConfig(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { handleSubmit, getFieldProps } = formik;

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setShowProcessorr((prev) => !prev)}
        endIcon={
          <Icon
            icon={showProcessor ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
      >
        Data config
      </CollapseButtonStyle>

      <Collapse in={showProcessor} sx={{ mx: 2 }}>
        <Stack spacing={1} sx={{ my: 1.2, mb: 1.5 }}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onChange={handleSubmit}>
              <Stack spacing={2} sx={{ mb: 0.5 }}>
                {/* <PipelineSelect
                  label={"Processor type"}
                  field={"processorType"}
                  placeholder={"Select a processor"}
                  options={processorTypes}
                  getFieldProps={getFieldProps}
                />

                <PipelineSelect
                  label={"Framework version"}
                  field={"frameworkVersion"}
                  placeholder={"Select a framework version"}
                  options={processorVerions}
                  getFieldProps={getFieldProps}
                /> */}

                <PipelineParameterEntry
                  label={"S3 data input path"}
                  field={"s3InputDataUrl"}
                  fieldUseParam={"s3InputDataUrlUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"S3 output path"}
                  field={"s3OutputUrl"}
                  fieldUseParam={"s3OutputUrlUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Label"}
                  field={"label"}
                  fieldUseParam={"labelUseParam"}
                  formik={formik}
                />

                <PipelineSelect
                  label={"Dataset type"}
                  field={"contentType"}
                  placeholder={"Select a framework version"}
                  options={contentOptions}
                  getFieldProps={getFieldProps}
                />
              </Stack>
            </Form>
          </FormikProvider>
        </Stack>
      </Collapse>
    </>
  );
}

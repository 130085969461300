import { useState } from "react";
import { Icon } from "@iconify/react";
import ReactPlayer from "react-player/lazy";
import playCircleFilled from "@iconify/icons-ant-design/play-circle-filled";
// material
import { useTheme } from "@mui/material/styles";
import { Paper, Typography, Stack, Box } from "@mui/material";
// utils
import Scrollbar from "@/components/project/Scrollbar";
import ReactJson from "react-json-view";
// hooks
import useLocales from "@/hooks/project/useLocales";
// // @types
// import { TesterResponseStruct } from "../../../../../@types/service";
// components
import LightboxModal from "@/components/project/LightboxModal";
import TesterResponseBodyMultiImg from "./TesterResponseBodyMultiImg";

// ----------------------------------------------------------------------

type TesterResponseStruct = {
  prediction: {
    pred: string;
    time: string;
    size: string;
    statusCode: number;
  };
  body: string;
};

type Props = {
  responses: TesterResponseStruct[];
  dataType?: string;
  taskType?: string;
  selectedImage: number;
  setSelectedImage: React.Dispatch<React.SetStateAction<number>>;
};

export default function TesterResponseBody({
  responses,
  dataType,
  taskType,
  selectedImage,
  setSelectedImage,
}: Props) {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const [openLightbox, setOpenLightbox] = useState(false);
  const { translate } = useLocales();

  const imagesLightbox = responses.map((response) => response.body);

  return (
    <Stack spacing={2} sx={{ p: 1 }}>
      <Typography
        variant="overline"
        sx={{ color: "text.secondary", lineHeight: 1 }}
      >
        {translate("body")}
      </Typography>

      <Paper
        sx={{
          p: !dataType || dataType.toLowerCase() === "csv" ? 1.5 : 0,
          bgcolor:
            !dataType || dataType.toLowerCase() === "csv"
              ? "background.neutral"
              : "transparent",
          height: { xs: "auto", md: "calc(64vh - 224px)" },
          // minHeight: { xs: '30px', md: 'calc(55vh - 170px)' },
        }}
      >
        <Scrollbar>
          {!dataType ||
            (dataType.toLowerCase() === "csv" && (
              <ReactJson
                src={JSON.parse(responses[selectedImage].body)}
                theme={isLight ? "rjv-default" : "eighties"}
                iconStyle="square"
                enableClipboard={false}
                displayDataTypes={false}
                style={{ backgroundColor: "transparent", fontSize: "14px" }}
              />
            ))}
          {dataType && dataType.toLowerCase() === "video" && (
            <Box sx={{ p: 0.5, pt: 1 }}>
              <ReactPlayer
                url={JSON.parse(imagesLightbox[0])}
                className="react-player"
                width="100%"
                height="100%"
                playIcon={
                  <Icon icon={playCircleFilled} width={36} height={36} />
                }
                controls={true}
              />
            </Box>
          )}
          {dataType && dataType.toLowerCase() === "image" && (
            <TesterResponseBodyMultiImg images={imagesLightbox} />
          )}
          {dataType && dataType.toLowerCase() === "json" && (
            <ReactJson
              src={responses[0] ? JSON.parse(responses[0].body) : {}}
              theme={isLight ? "rjv-default" : "eighties"}
              iconStyle="square"
              enableClipboard={false}
              displayDataTypes={false}
              style={{ backgroundColor: "transparent", fontSize: "14px" }}
            />
          )}
        </Scrollbar>
      </Paper>

      <LightboxModal
        images={imagesLightbox}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onClose={() => setOpenLightbox(false)}
      />
    </Stack>
  );
}

export const estimatorTypes = [
  { code: "A", label: "PyTorch" },
  { code: "B", label: "MXNet" },
  { code: "C", label: "TensorFlow" },
  { code: "D", label: "XGBoost" },
  { code: "E", label: "SKLearn" },
  { code: "F", label: "HuggingFace" },
];

export const estimatorVerions = [
  { code: "A", label: "1.8.1" },
  { code: "B", label: "1.7.1" },
  { code: "C", label: "1.6.1" },
  { code: "D", label: "1.0.1" },
];

export const processorTypes = [
  { code: "A", label: "SKLearnProcessor" },
  { code: "B", label: "ScriptProcessor" },
  { code: "C", label: "FrameworkProcessor" },
  { code: "D", label: "PySparkProcessor" },
  { code: "E", label: "SparkJarProcessor" },
  { code: "F", label: "XGBoostProcessor" },
];

export const processorVerions = [
  { code: "A", label: "0.23.2" },
  { code: "B", label: "0.23.1" },
  { code: "C", label: "0.20.0" },
  { code: "D", label: "1.0.1" },
];

export const tuningTypes = [
  { code: "A", label: "Maximize" },
  { code: "B", label: "Minimize" },
];

export const clarifyCheckTypes = [
  { code: "A", label: "MODEL_BIAS" },
  { code: "B", label: "MODEL_EXPLAINABILITY" },
];

export const boolOptions = [
  { code: "A", label: "True" },
  { code: "B", label: "False" },
];

// export const boolOptions = [
//   { code: "A", label: "True" },
//   { code: "B", label: "False" },
// ];

export const contentOptions = [
  { code: "A", label: "text/csv" },
  { code: "B", label: "application/jsonlines" },
  { code: "C", label: "application/x-parquet" },
];

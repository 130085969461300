import { replace } from "lodash";
import numeral from "numeral";

// ----------------------------------------------------------------------

export function fCurrency(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? "$0,0" : "$0,0.00");
}

export function fCurrencyRMB(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? "¥0,0" : "¥0,0.00");
}

export function fPercent(number: number) {
  return numeral(number / 100).format("0.0%");
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fPercentByDivision(number1: number, number2: number) {
  return numeral(number1 / number2).format("0.0%");
}

export function fNumberWithOneDecimal(number: string | number) {
  return numeral(number).format("0.0");
}

export function fBytesToKB(number1: string) {
  return numeral(parseInt(number1) / 1024).format("0.00");
}

export function fNumberWithDecimal(number: string | number, decimals: number) {
  if (decimals === 1) return numeral(number).format("0.0");
  else if (decimals === 2) return numeral(number).format("0.00");
  else if (decimals === 3) return numeral(number).format("0.000");
  else if (decimals === 4) return numeral(number).format("0.0000");
  else if (decimals === 5) return numeral(number).format("0.00000");
  else return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
  return replace(numeral(number).format("0.00a"), ".00", "");
}

export function fData(number: string | number) {
  return numeral(number).format("0.0 b");
}

export function fInteger(number: string | number) {
  return numeral(number).format("0");
}

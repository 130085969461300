import { isEmpty } from "lodash";
import yaml from "js-yaml";

export const parseAdvancedSettings = (data) => {
  const tempObj = {
    billing: "elastic",
    provider: "aws",
    region: "beijing",
    node_type: "cpu",
    node_linux_arch: "x86",
  };

  return tempObj;
};

export const parseTrailThresholds = (data) => {
  return {
    parallelTrial: data?.parallelTrialCount,
    resumePolicy: data?.resumePolicy,
    maxTrails: data?.maxTrialCount,
    maxFailedTrails: data?.maxFailedTrialCount,
  };
};

export const parseObjective = (data) => {
  return {
    type: data?.type,
    metric: data?.objectiveMetricName,
    goal: data?.goal,
    additionalMetrics:
      data?.additionalMetricNames?.map((i) => ({ metricName: i })) || [],
    isSetMetricStrategies: !isEmpty(data.metricStrategies),
    metricStrategies:
      data?.metricStrategies?.map((i) => ({
        metricName: i.name,
        strategy: i.value,
      })) || [],
  };
};

export const parseAlgorithm = (data) => {
  let settings = {};
  if (data?.algorithmName === "random") {
    settings = {
      randomState: !isEmpty(data?.algorithmSettings)
        ? data?.algorithmSettings.find((i) => i.name === "random_state")
            ?.value || ""
        : "",
    };
  }

  if (data?.algorithmName === "bayesianoptimization") {
    settings = {
      baseEstimator: !isEmpty(data?.algorithmSettings)
        ? data.algorithmSettings.find((i) => i.name === "base_estimator")
            ?.value || ""
        : "",
      riInitialPoints: !isEmpty(data?.algorithmSettings)
        ? data.algorithmSettings.find((i) => i.name === "n_initial_points")
            ?.value || ""
        : "",
      acqFunc: !isEmpty(data?.algorithmSettings)
        ? data.algorithmSettings.find((i) => i.name === "acq_func")?.value || ""
        : "",
      acqOptimizer: !isEmpty(data?.algorithmSettings)
        ? data.algorithmSettings.find((i) => i.name === "acq_optimizer")
            ?.value || ""
        : "",
      random_state: !isEmpty(data?.algorithmSettings)
        ? data.algorithmSettings.find((i) => i.name === "random_state")
            ?.value || ""
        : "",
    };
  }

  // grid
  return {
    name: data?.algorithmName || "",
    settings,
  };
};

export const parseEarlyStopping = (data) => {
  let name = "";
  let settings = {};
  if (isEmpty(data)) {
    name = "none";
  } else {
    name = data.algorithmName;
    settings = {
      minTrialsRequired: !isEmpty(data?.algorithmSettings)
        ? data.algorithmSettings.find((i) => i.name === "min_trials_required")
            ?.value || ""
        : "",
      startStep: !isEmpty(data?.algorithmSettings)
        ? data.algorithmSettings.find((i) => i.name === "start_step")?.value ||
          ""
        : "",
    };
  }

  return {
    name,
    settings,
  };
};

export const parseParameters = (data) => {
  return data.map((item) => {
    if (["integer", "double"].includes(item.parameterType)) {
      return {
        ...item,
        feasibleSpace: {
          ...item.feasibleSpace,
          step: item.feasibleSpace?.step || "",
        },
      };
    }

    return {
      ...item,
      feasibleSpace: item.feasibleSpace.list.map((i) => ({
        value: i,
      })),
    };
  });
};

export const parseMetricsCollector = (data) => {
  if (isEmpty(data)) {
    return {
      kind: "None",
      metricsFormat: "",
      settings: {},
    };
  }

  if (data?.kind === "Stdout") {
    return {
      kind: data?.kind,
      metricsFormat: data?.source?.filter?.metricsFormat[0],
      settings: {},
    };
  }

  // File
  return {
    kind: "File",
    metricsFormat: data?.source?.filter?.metricsFormat?.[0],
    settings: {
      metricFile: data?.source?.fileSystemPath?.path || "",
    },
  };
};

export const parseTrialTemplate = (data) => {
  return {
    primaryContainerName: data?.primaryContainerName,
    trialParameters: data?.trialParameters || [],
    settings: {
      sourceType: "YAML",
      content: yaml.dump(data?.trialSpec),
    },
  };
};

import {
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useLocation, useNavigate, useParams } from "react-router";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import { Activate, DeleteIcon } from "@/assets/project/icons";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import { dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import bxsDuplicate from "@iconify/icons-bx/bxs-duplicate";
import { Icon } from "@iconify/react";
import {
  delPipelineVersion,
  editPipelineTrigger,
  editPipelineVersion,
  getPipelineTriggerTableData,
} from "@/redux/project/automation/thunks";
import { resetCurrentKubeflowPipeline } from "@/redux/project/automation/pipeline";
import { isEmpty } from "lodash";
import { getPipelineVersionTableList } from "@/services/automation";

interface IProps {
  row: {
    id: string;
    [key: string]: any;
  };
  handleRefresh: (a?: boolean) => void;
}

const PipelineVersionTableActionCol = ({ row, handleRefresh }: IProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { mlPipelineId } = useParams();

  // delete confirm
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);

  // activate confirm
  const [openActivateConfirm, setOpenActivateConfirm] =
    useState<boolean>(false);
  const [activateLoading, setActivateLoading] = useState<boolean>(false);

  // dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOriginalVersion = async () => {
    // set the original active_version field to 0, and set the original active pipeline trigger to disable
    const versionRes = await getPipelineVersionTableList({
      mlpipeline_id: mlPipelineId!,
      active_version: 1,
      page_num: 1,
      page_size: 9999,
    });
    const { data: { result: res = {} } = {} } = versionRes;

    // if active_version = 1 exist, only one
    if (!isEmpty(res?.items)) {
      // set triggers disable
      const result = await dispatch(
        getPipelineTriggerTableData({
          params: {
            mlpipeline_version_id: res?.items[0]?.id,
            page_num: 1,
            page_size: 9999,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      await Promise.all(
        result.items.map((item) =>
          dispatch(
            editPipelineTrigger({
              id: item.id,
              params: {
                status: "wait_close",
              },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap()
        )
      );

      await dispatch(
        editPipelineVersion({
          id: res?.items[0]?.id,
          params: {
            active_version: 0,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
    }
  };

  const handleActivatePipelineVersion = async () => {
    setActivateLoading(true);

    await handleOriginalVersion();

    // set the current version field to 1
    await dispatch(
      editPipelineVersion({
        id: row.id,
        params: {
          active_version: 1,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    setOpenConfirm(false);
    setActivateLoading(false);
    handleRefresh(true);
    enqueueSnackbar(`Activate version ${row.name} success`, {
      variant: "success",
    });
  };

  const handleDuplicatePipelineVersion = async () => {
    // create + edit in PipelinPage.tsx
    navigate(`${row.id}/duplicateVersion`);
  };

  const handleDelPipelinVersion = async () => {
    setDelLoading(true);
    try {
      await dispatch(
        delPipelineVersion({
          id: row.id,
          params: {
            status: "wait_close",
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      setDelLoading(false);
      setOpenConfirm(false);
      handleRefresh(true);
      enqueueSnackbar(`Delete ${row.name} Success`, { variant: "success" });
    } catch (e) {
      setDelLoading(false);
    }
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={6} lg={6}>
          <IconButton onClick={() => navigate(`${row.id}`)}>
            <KeyboardArrowRightOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6} lg={6}>
          <IconButton onClick={handleClick}>
            <MoreVertOutlinedIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              key="duplicate"
              onClick={() => {
                dispatch(resetCurrentKubeflowPipeline());

                handleDuplicatePipelineVersion();
                handleClose();
              }}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <Icon icon={bxsDuplicate} width={22} height={22} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                Duplicate
              </ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem
              key="activate"
              disabled={row.status !== "started"}
              onClick={() => {
                setOpenActivateConfirm(true);
                handleClose();
              }}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <SvgIconStyle
                  src={Activate}
                  sx={{
                    width: 22,
                    height: 22,
                  }}
                />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                Activate
              </ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenConfirm(true);
              }}
              sx={{ color: "error.main" }}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <SvgIconStyle
                  src={DeleteIcon}
                  sx={{
                    width: 22,
                    height: 22,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Delete"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete version <strong> {row.name} </strong> item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelPipelinVersion();
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />

      <ConfirmDialog
        open={openActivateConfirm}
        onClose={() => setOpenActivateConfirm(false)}
        title="Activate"
        content={
          <>
            Are you sure to activate version <strong> {row.name} </strong> ?
          </>
        }
        action={
          <LoadingButton
            color="primary"
            sx={{ width: "80px" }}
            onClick={() => {
              handleActivatePipelineVersion();
            }}
            loading={activateLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Confirm
          </LoadingButton>
        }
      />
    </>
  );
};

export default PipelineVersionTableActionCol;

import { Icon } from "@iconify/react";
import editFill from "@iconify/icons-eva/edit-fill";
import { useState } from "react";
// material
import { Box, Stack, IconButton } from "@mui/material";
//
import { updateTrainingCode } from "../../../graph/slices/slice";
import { TrainingCodeStruct } from "@/@types/project/mlPipeline/SageMaker/pipeline";
import CodePopover from "./CodePopover";
import { StyledTextField } from "../../components/PipelineComponents";

// ----------------------------------------------------------------------

export default function TrainingCode({ data }: { data: TrainingCodeStruct }) {
  const [openCodeSetter, setOpenCodeSetter] = useState(false);
  const { entryPoint } = data;

  const handleOpenCodeSetter = () => {
    setOpenCodeSetter(true);
  };

  return (
    <Stack spacing={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <StyledTextField
          multiline
          disabled
          maxRows={3}
          label="Training code"
          placeholder="N/A"
          inputProps={{ style: { fontSize: "14px", wordBreak: "break-all" } }}
          InputLabelProps={{
            style: { fontSize: "15px" },
            shrink: entryPoint !== "",
          }}
          value={entryPoint}
        />

        <IconButton
          size="small"
          onClick={handleOpenCodeSetter}
          sx={{ ml: 0.5 }}
        >
          <Icon icon={editFill} width={16} height={16} />
        </IconButton>

        <CodePopover
          isOpenCompose={openCodeSetter}
          onCloseCompose={() => setOpenCodeSetter(false)}
          codeProps={data}
          update={updateTrainingCode}
        />
      </Box>
    </Stack>
  );
}

import { useState } from "react";
import Menu from "@mui/material/Menu";
import { MIconButton } from "@/components/project/@material-extend";
import { HelpButton } from "@/assets/project/icons/index";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import { OMNIML_URL_PREFIX } from "@/config";
import HelpBtnMenuItem from "./partComponents/HelpBtnMenuItem";

const menuItemList = [
  {
    title: "User Manual",
    isExternal: true,
  },
  {
    title: `Developer's Guide`,
    isExternal: false,
    children: [
      {
        title: "Govern",
        link: `${OMNIML_URL_PREFIX}swagger?type=govern`,
      },
      {
        title: "Experiment",
        link: `${OMNIML_URL_PREFIX}swagger?type=experiment`,
      },
      {
        title: "Feature Store",
        link: `${OMNIML_URL_PREFIX}swagger?type=featurestore`,
      },
      {
        title: "Model Registry",
        link: `${OMNIML_URL_PREFIX}swagger?type=modelregistry`,
      },
      {
        title: "Pipeline",
        link: `${OMNIML_URL_PREFIX}swagger?type=pipeline`,
      },
      {
        title: "Model Runtime",
        link: `${OMNIML_URL_PREFIX}swagger?type=modelruntime`,
      },
      {
        title: "App Gallery",
        link: `${OMNIML_URL_PREFIX}swagger?type=appgallery`,
      },
    ],
  },
  {
    title: "Support",
    isExternal: true,
  },
];

const HelpBtn = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MIconButton
        onClick={handleClick}
        title="Help"
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          // color: "primary.main",
          color: "default",
        }}
      >
        <SvgIconStyle
          src={HelpButton}
          sx={{
            width: 22,
            height: 22,
          }}
        />
      </MIconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
      >
        {menuItemList.map((item) => (
          <HelpBtnMenuItem item={item} handleClose={handleClose} />
        ))}
      </Menu>
    </>
  );
};

export default HelpBtn;

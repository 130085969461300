import * as Yup from "yup";
import { Icon } from "@iconify/react";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Stack, Collapse } from "@mui/material";
// types
import {
  ModelStep,
  ModelDataStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-model";
//
import { CollapseButtonStyle } from "../../SettingSidebar";
import { updateModelData } from "../../../graph/slices/slice";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";

// ----------------------------------------------------------------------

type Props = {
  currentStep: ModelStep;
};

export default function ModelData({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [isCollapse, setCollapse] = useState(false);

  const ParamSchema = Yup.object().shape({
    // modelDataUrl: Yup.string().required("Model data url is required"),
  });

  const formik = useFormik<ModelDataStruct>({
    initialValues: currentStep.modelData,
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values) => {
      try {
        dispatch(updateModelData(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { handleSubmit } = formik;

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setCollapse((prev) => !prev)}
        endIcon={
          <Icon
            icon={isCollapse ? arrowIosForwardFill : arrowIosDownwardFill}
            width={16}
            height={16}
          />
        }
      >
        Model data
      </CollapseButtonStyle>

      <Collapse in={!isCollapse} sx={{ mx: 2, mb: !isCollapse ? 0.5 : 0 }}>
        <Stack spacing={1} sx={{ my: 1 }}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onChange={handleSubmit}>
              <Stack spacing={2}>
                {/* <PipelineTextfield
                  multiline
                  label={"Model data url"}
                  field={"modelDataUrl"}
                  placeholder={"s3://"}
                  formik={formik}
                /> */}

                <PipelineParameterEntry
                  label={"Model data url"}
                  field={"modelDataUrl"}
                  placeholder={"s3://"}
                  fieldUseParam={"modelDataUrlUseParam"}
                  formik={formik}
                />
              </Stack>
            </Form>
          </FormikProvider>
        </Stack>
      </Collapse>
    </>
  );
}

import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import editFill from "@iconify/icons-eva/edit-fill";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Box, Stack, Typography, IconButton } from "@mui/material";
//
import { updateProcessingJobArgs } from "../../../graph/slices/slice";
import { StepCodeStruct } from "@/@types/project/mlPipeline/SageMaker/pipeline";
import ProcessingCodePopover from "./ProcessingCodePopover";
import ProcessingCodeArgs from "./ProcessingCodeArgs";
import { StyledTextField } from "../../components/PipelineComponents";

// ----------------------------------------------------------------------

export default function ProcessingCode({ data }: { data: StepCodeStruct }) {
  const [openCodeSetter, setOpenCodeSetter] = useState(false);
  const dispatch = useDispatch();
  const { path, jobArgs } = data;

  const handleOpenCodeSetter = () => {
    setOpenCodeSetter(true);
  };

  const jobArgNum = jobArgs !== undefined ? jobArgs.length : 0;

  const handleAddJobArgs = () => {
    dispatch(
      updateProcessingJobArgs({
        data: {
          name: "",
          value: "",
        },
      })
    );
  };

  return (
    <Stack spacing={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <StyledTextField
          multiline
          disabled
          maxRows={3}
          label="Processing Code"
          inputProps={{ style: { fontSize: "14px", wordBreak: "break-all" } }}
          InputLabelProps={{ style: { fontSize: "15px" }, shrink: path !== "" }}
          value={path}
        />

        <IconButton
          size="small"
          onClick={handleOpenCodeSetter}
          sx={{ ml: 0.5 }}
        >
          <Icon icon={editFill} width={16} height={16} />
        </IconButton>

        <ProcessingCodePopover
          isOpenCompose={openCodeSetter}
          onCloseCompose={() => setOpenCodeSetter(false)}
          codeProps={data}
        />
      </Box>

      <Stack spacing={1.5} sx={{ mx: 2, my: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", fontWeight: 600 }}
          >
            Job arguments {jobArgNum > 0 && `(${jobArgNum})`}
          </Typography>
          <IconButton size="small" onClick={handleAddJobArgs}>
            <Icon icon={plusFill} width={16} height={16} />
          </IconButton>
        </Stack>

        {jobArgs !== undefined &&
          jobArgs.map((arg, index) => (
            <ProcessingCodeArgs key={index} id={index} data={arg} />
          ))}
      </Stack>
    </Stack>
  );
}

import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import MlCard from "@/components/project/mlComponents/MlCard";
import Scrollbar from "@/components/project/Scrollbar";
import { extractKeys } from "@/utils/project/utils";
import { useLocation } from "react-router";
import { RootState, dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import MlTableBodyContainer from "@/components/project/mlComponents/MlTableBodyContainer";
import Label from "@/components/project/Label";
import {
  addPipelineExecution,
  getPipelineExecutionTableData,
} from "@/redux/project/automation/thunks";
import { isEmpty } from "lodash";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import ExecutionDetailDialog from "./ExecutionDetailDialog";

interface Column {
  id: string;
  label?: string;
  width?: number;
  minWidth?: number;
}

enum StatusEnum {
  Succeeded = "success",
  Failed = "error",
}

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 150 },
  { id: "duration", label: "Duration Time" },
  { id: "created_at", label: "Created At" },
  { id: "finished_at", label: "Finished At" },
  { id: "status", label: "Status" },
  { id: "", label: "View", width: 150 },
];

const KubeflowExecutionTable = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const [row, setRow] = useState<Record<any, any>>({});
  const [openViewExecution, setOpenViewExecution] = useState(false);

  const [curPageTableData, setCurPageTableData] = useState<
    Array<Record<any, any>>
  >([]);

  const [page_num, setPageNum] = useState<number>(1);
  const [page_size, setPageSize] = useState<number>(10);

  const isCreate = pathname.includes("/createVersion");
  const isDuplicate = pathname.includes("/duplicateVersion");
  const isEdit = !isCreate && !isDuplicate;

  // add confirm
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [addLoading, setAddLoading] = useState<boolean>(false);

  const {
    pipelineExecutionTableList: { data, loading },
    pipelineVersionDetail: { data: versionDetail = {} },
  } = useSelector((state: RootState) => state.automation);

  const fetchData = () => {
    const { id } = versionDetail || {};
    return dispatch(
      getPipelineExecutionTableData({
        params: {
          mlpipeline_version_id: id,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  };

  const handleRefresh = (isUpdate?: boolean) => {
    setPageNum(1);
    setPageSize(10);

    fetchData().then((res) => {
      const startIndex = 0;
      const endIndex = page_size;
      const currentPageData = res?.items.slice(startIndex, endIndex);

      setCurPageTableData(currentPageData);

      if (!isUpdate) {
        enqueueSnackbar("Refresh Success", { variant: "success" });
      }
    });
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNum(newPage + 1);
    const startIndex = newPage * page_size;
    const endIndex = startIndex + page_size;
    const currentPageData = data?.items.slice(startIndex, endIndex);

    setCurPageTableData(currentPageData);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNum(1);
    const startIndex = 0;
    const endIndex = page_size;
    const currentPageData = data?.items.slice(startIndex, endIndex);

    setCurPageTableData(currentPageData);
  };

  const renderCell = (row, column) => {
    switch (column.id) {
      case "name":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="subtitle2">{row[column.id]}</Typography>
          </TableCell>
        );
      case "status":
        const status = row[column.id];
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width", "maxWidth") }}
          >
            {status ? <Label color={StatusEnum[status]}>{status}</Label> : "-"}
          </TableCell>
        );
      case "":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            {row.status === "Succeeded" ? (
              <IconButton
                sx={{ color: "primary.main" }}
                onClick={() => {
                  setRow(row);
                  setOpenViewExecution(true);
                }}
              >
                <OpenInNewOutlinedIcon sx={{ width: 16, height: 16 }} />
              </IconButton>
            ) : (
              <Typography sx={{ pl: 1.4 }}>-</Typography>
            )}
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {row[column.id] || "-"}
            </Typography>
          </TableCell>
        );
    }
  };

  const handleAddPipelineExecution = async () => {
    setAddLoading(true);
    try {
      const { id } = versionDetail || {};

      await dispatch(
        addPipelineExecution({
          params: {
            mlpipeline_version_id: id,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      setAddLoading(false);
      setOpenConfirm(false);
      handleRefresh(true);
      enqueueSnackbar("Add Success", { variant: "success" });
    } catch (e) {
      setAddLoading(false);
    }
  };

  useEffect(() => {
    // isEdit need request the data
    if (
      isEdit &&
      !isEmpty(versionDetail) &&
      versionDetail.status === "started"
    ) {
      fetchData().then((res) => {
        const startIndex = 0;
        const endIndex = page_size;
        const currentPageData = res?.items.slice(startIndex, endIndex);

        setCurPageTableData(currentPageData);
      });
    }
  }, [versionDetail]);

  return (
    <MlCard
      title="Execution"
      extraJsx={
        <Stack direction="row" alignItems="center" spacing={1}>
          <Button
            variant="contained"
            sx={{ width: 120 }}
            disabled={isEmpty(versionDetail)}
            onClick={() => {
              setOpenConfirm(true);
            }}
          >
            Add Execution
          </Button>

          <IconButton onClick={() => handleRefresh()}>
            <RefreshOutlinedIcon />
          </IconButton>
        </Stack>
      }
      hasPaddingX={false}
    >
      <Scrollbar>
        <TableContainer sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <MlTableBodyContainer loading={loading} data={data?.items}>
                {curPageTableData?.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((column) => renderCell(row, column))}
                  </TableRow>
                ))}
              </MlTableBodyContainer>
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25]}
        component="div"
        count={data?.total || 0}
        rowsPerPage={page_size}
        page={page_num - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Add"
        content={
          <>Are you sure to add an execution from this pipeline version?</>
        }
        action={
          <LoadingButton
            color="primary"
            sx={{ width: "80px" }}
            onClick={() => {
              handleAddPipelineExecution();
            }}
            loading={addLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Confirm
          </LoadingButton>
        }
      />

      <ExecutionDetailDialog
        isOpenDialog={openViewExecution}
        onCloseDialog={() => setOpenViewExecution(false)}
        row={row}
      />
    </MlCard>
  );
};

export default KubeflowExecutionTable;

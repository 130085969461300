// material
import { Stack, Switch, Tooltip, TextFieldProps } from "@mui/material";
//
import PipelineParameterSelector from "./PipelineParameterSelector";
import { StyledTextField } from "./PipelineComponents";

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  label: string;
  field: string;
  fieldUseParam: string;
  formik: any;
  placeholder?: string;
};

export default function PipelineParameterEntry({
  label,
  field,
  fieldUseParam,
  formik,
  placeholder,
  disabled,
  ...other
}: Props) {
  const { errors, setFieldValue, touched, values, getFieldProps } = formik;

  return (
    <Stack
      spacing={0.1}
      direction="row"
      alignItems="center"
      sx={{ width: "103%" }}
    >
      {values[fieldUseParam] ? (
        <PipelineParameterSelector
          label={label}
          placeholder={placeholder ? placeholder : "Select a parameter"}
          {...getFieldProps(field)}
          formik={formik}
          disabled={disabled}
          {...other}
        />
      ) : (
        <StyledTextField
          label={label}
          {...getFieldProps(field)}
          placeholder={placeholder ? placeholder : ""}
          error={Boolean(touched[field] && errors[field])}
          helperText={touched[field] && errors[field]}
          disabled={disabled}
          {...other}
        />
      )}
      <Tooltip
        title="Open pipeline params"
        enterDelay={1000}
        enterTouchDelay={1000}
        arrow
        sx={{ zIndex: 3000 }}
      >
        <Switch
          size="small"
          {...getFieldProps(fieldUseParam)}
          checked={values[fieldUseParam]}
          disabled={disabled}
          onChange={(e) => {
            setFieldValue(field, "");
            setFieldValue(fieldUseParam, e.target.checked);
            if (!e.target.checked) {
              setFieldValue("paramType", 0);
            }
          }}
        />
      </Tooltip>
    </Stack>
  );
}

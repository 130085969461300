import { Field, FieldArray, FormikProps } from "formik";
import { IKubeflowHpTunerFormValues } from "..";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import { InputLabel } from "@mui/material";
import { Stack } from "@mui/material";
import { IconButton } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { useTheme } from "@mui/material";
import ReactCodeMirror from "@uiw/react-codemirror";
import { yaml as yamlLang } from "@codemirror/lang-yaml";
import { oneDark } from "@codemirror/theme-one-dark";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { getInitResourceQuota } from "@/utils/project/resourceRequest";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

interface IProps {
  formik: FormikProps<IKubeflowHpTunerFormValues>;
}

const TrialTemplate = ({ formik }: IProps) => {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";

  // resourceQuota
  const [resourceQuota, setResourceQuota] = useState<Record<string, any>>({});

  const {
    userDetail: { project_id },
  } = useSelector((state: RootState) => state.common);

  const resourceHelper = () => {
    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="body2">
          {`CPU: ${resourceQuota.cpu?.used || 0}/${
            resourceQuota.cpu?.all || 0
          } used/hard`}
        </Typography>
        <Typography variant="body2">
          {`MEM: ${resourceQuota.memory?.used || 0}/${
            resourceQuota.memory?.all || 0
          } used/hard`}
        </Typography>
        <Typography variant="body2">
          {`GPU: ${resourceQuota.gpu?.used || 0}/${
            resourceQuota.gpu?.all || 0
          } used/hard`}
        </Typography>
        <Tooltip title="Refresh to query instant resource">
          <IconButton
            size="small"
            sx={{ height: "18px", width: "18px" }}
            onClick={() => getInitResourceQuota(project_id, setResourceQuota)}
          >
            <RefreshOutlinedIcon
              sx={{ color: (theme) => theme.palette.grey[500] }}
            />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  const { values, errors, touched, setFieldValue } = formik;
  const trialParametersTouched = touched as Array<{
    name: boolean;
    reference: boolean;
    description: boolean;
  }>;
  const trialParametersErrors = errors as Array<{
    name: string;
    reference: string;
    description: string;
  }>;

  useEffect(() => {
    getInitResourceQuota(project_id, setResourceQuota);
  }, []);

  return (
    <>
      <Typography variant="subtitle1">Trial Template</Typography>

      <Box sx={{ pl: 2 }}>
        <Field
          as={TextField}
          name="trialTemplate.primaryContainerName"
          label="Primary Container Name"
          size="small"
          fullWidth
          margin="dense"
          helperText={
            (touched.trialTemplate?.primaryContainerName &&
              errors.trialTemplate?.primaryContainerName) ||
            " "
          }
          error={
            touched.trialTemplate?.primaryContainerName &&
            Boolean(errors.trialTemplate?.primaryContainerName)
          }
          FormHelperTextProps={{ sx: { minHeight: "18px", marginTop: "2px" } }}
        />

        <FormControl
          fullWidth
          margin="dense"
          size="small"
          error={
            touched.trialTemplate?.settings?.sourceType &&
            Boolean(errors.trialTemplate?.settings?.sourceType)
          }
        >
          <InputLabel>Source Type</InputLabel>
          <Field
            as={Select}
            name="trialTemplate.settings.sourceType"
            label="Source Type"
            error={
              touched.trialTemplate?.settings?.sourceType &&
              Boolean(errors.trialTemplate?.settings?.sourceType)
            }
          >
            <MenuItem value="YAML">YAML</MenuItem>
          </Field>
          <FormHelperText
            margin="dense"
            sx={{ minHeight: "18px", marginTop: "2px" }}
          >
            {touched.trialTemplate?.settings?.sourceType &&
            errors.trialTemplate?.settings?.sourceType
              ? errors.trialTemplate?.settings?.sourceType
              : "You can either use a predefined YAML from a ConfigMap or write your own."}
          </FormHelperText>
        </FormControl>

        <ReactCodeMirror
          value={values.trialTemplate.settings.content}
          height="auto"
          minHeight="300px"
          onChange={(value) => {
            // setFieldValue('trialTemplate.settings.content', yaml.load(value));
            setFieldValue("trialTemplate.settings.content", value);
          }}
          extensions={[yamlLang()]}
          theme={isLight ? "light" : oneDark}
          style={{
            border: `1px solid ${theme.palette.grey[500_32]}`,
          }}
        />
        <FormHelperText
          error={Boolean(
            touched?.trialTemplate?.settings?.content &&
              errors?.trialTemplate?.settings?.content
          )}
          margin="dense"
          sx={{ minHeight: "18px", marginTop: "2px", pl: 1, mb: "12px" }}
        >
          {(touched?.trialTemplate?.settings?.content &&
            errors?.trialTemplate?.settings?.content) ||
            resourceHelper()}
        </FormHelperText>

        <FieldArray
          name="trialTemplate.trialParameters"
          render={({ push, remove }) => (
            <>
              {/* <Typography variant='body2' sx={{ mt: 1.5, mb: 1 }}>Trial Parameters</Typography> */}
              {values.trialTemplate.trialParameters.map((_, index) => (
                <Stack key={index} direction="row">
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12} lg={4}>
                      <Field
                        as={TextField}
                        name={`trialTemplate.trialParameters.${index}.name`}
                        label="Name"
                        size="small"
                        fullWidth
                        margin="dense"
                        helperText={
                          (trialParametersTouched?.[index]?.name &&
                            trialParametersErrors?.[index]?.name) ||
                          " "
                        }
                        error={
                          trialParametersTouched?.[index]?.name &&
                          Boolean(trialParametersErrors?.[index]?.name)
                        }
                        FormHelperTextProps={{
                          sx: { minHeight: "18px", marginTop: "2px" },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Field
                        as={TextField}
                        name={`trialTemplate.trialParameters.${index}.reference`}
                        label="Reference"
                        size="small"
                        fullWidth
                        margin="dense"
                        helperText={
                          (trialParametersTouched?.[index]?.reference &&
                            trialParametersErrors?.[index]?.reference) ||
                          " "
                        }
                        error={
                          trialParametersTouched?.[index]?.reference &&
                          Boolean(trialParametersErrors?.[index]?.reference)
                        }
                        FormHelperTextProps={{
                          sx: { minHeight: "18px", marginTop: "2px" },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Field
                        as={TextField}
                        name={`trialTemplate.trialParameters.${index}.description`}
                        label="Description"
                        size="small"
                        fullWidth
                        margin="dense"
                        helperText={
                          (trialParametersTouched?.[index]?.description &&
                            trialParametersErrors?.[index]?.description) ||
                          " "
                        }
                        error={
                          trialParametersTouched?.[index]?.description &&
                          Boolean(trialParametersErrors?.[index]?.description)
                        }
                        FormHelperTextProps={{
                          sx: { minHeight: "18px", marginTop: "2px" },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <>
                    <Box sx={{ ml: 1.5, mt: 1 }}>
                      <IconButton
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <RemoveOutlinedIcon />
                      </IconButton>
                    </Box>
                  </>
                </Stack>
              ))}
              <Button
                variant="text"
                color="secondary"
                startIcon={<AddOutlinedIcon />}
                onClick={() => {
                  push({
                    name: "",
                    reference: "",
                    description: "",
                  });
                }}
              >
                Add Template Parameter
              </Button>
            </>
          )}
        />
        <FormHelperText
          error
          margin="dense"
          sx={{ minHeight: "18px", marginTop: "2px", pl: 1 }}
        >
          {touched.trialTemplate?.trialParameters &&
          errors.trialTemplate?.trialParameters
            ? errors.trialTemplate?.trialParameters
            : ""}
        </FormHelperText>
      </Box>
    </>
  );
};

export default TrialTemplate;

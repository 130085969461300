/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { PATH_DASHBOARD } from "@/routes/paths";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  Stack,
  IconButton,
  Typography,
} from "@mui/material";
import Page from "@/components/project/Page";
import MlCardList from "@/components/project/mlComponents/MlCardList";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { LoadingButton } from "@mui/lab";
import Logger from "./components/mlRuntimeDetail/logger/Logger";
import Deployment from "./components/mlRuntimeDetail/deployment/Deployment";
import Metrics from "./components/mlRuntimeDetail/metrics/Metrics";
import Tester from "./components/mlRuntimeDetail/tester/Tester";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { dispatch, useSelector, RootState } from "@/redux/store";
import {
  getMlRuntimeById,
  getRelTagServiceList,
  getTagList,
  getModelPackageList,
  getServiceDeploymentList,
  editMlService,
} from "@/redux/project/mlService/mlRuntime/thunks";
import Scrollbar from "@/components/project/Scrollbar";
import MlRuntimeScenarioLabel from "./components/MlRuntimeScenarioLabel";
import MlCard from "@/components/project/mlComponents/MlCard";
import ConfirmDialog from "@/components/project/ConfirmDialog";

const MlRuntimeDetail: React.FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { runtimeId = "" } = useParams();
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState("deployment");
  const {
    modelPackageList: { data },
    mlRuntimeDetail: { data: serviceDetail },
    serviceTagDatas: {
      data: { items: serviceTagList = [] },
    },
    tagDatas: {
      data: { items: tagList = [] },
    },
    mlServiceDeploymentList: {
      data: { items: deploymentList = [] },
    },
  } = useSelector((state: RootState) => state.mlRuntime);
  const [curDeployment, setCurDeployment] = useState<Record<string, any>>({});

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLinks = () => {
    return [
      {
        name: "ML Service",
      },
      {
        name: "ML Runtime",
        href: PATH_DASHBOARD.mlService.mlRuntime,
      },
      {
        name: serviceDetail?.name || "",
      },
    ];
  };

  useEffect(() => {
    // get service
    dispatch(
      getMlRuntimeById({
        id: runtimeId,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    // get tag
    dispatch(
      getRelTagServiceList({
        params: {
          page_num: 1,
          page_size: 9999,
          is_active: 1,
          mlservice_id: runtimeId,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    dispatch(
      getTagList({
        params: { page_num: 1, page_size: 9999, is_active: 1 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    // get model package
    dispatch(
      getModelPackageList({
        params: { page_num: 1, page_size: 9999 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    // get deployment
    dispatch(
      getServiceDeploymentList({
        params: {
          page_num: 1,
          page_size: 9999,
          is_active: 1,
          mlservice_id: runtimeId,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  }, []);

  useEffect(() => {
    setCurDeployment(deploymentList[0]);
  }, [deploymentList]);

  const getTagsContent = useCallback(() => {
    const tags: string[] = [];
    // eslint-disable-next-line array-callback-return
    serviceTagList.map((rel) => {
      const tagName = tagList.find((tag) => tag.id === rel.tag_id)?.name;
      if (tagName) tags.push(tagName);
    });
    return tags.length > 0 ? (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {tags.map((tag) => {
          return <Chip label={tag} size="small" key={tag} />;
        })}
      </Box>
    ) : (
      "-"
    );
  }, [serviceTagList]);

  const getRuntimeDetails = useCallback(() => {
    return [
      { subTitle: "Service Name", label: serviceDetail?.name || "-" },
      { subTitle: "Type", label: serviceDetail?.service_type || "-" },
      {
        subTitle: "Model Package Name",
        label: (
          <Stack direction="row" spacing={0.5} alignItems="center">
            {data.items?.find(
              (item) => item.id === serviceDetail.mp_external_id
            ) ? (
              <>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", wordBreak: "break-word" }}
                >
                  {
                    data.items.find(
                      (item) => item.id === serviceDetail.mp_external_id
                    ).name
                  }
                </Typography>
                <IconButton
                  color="secondary"
                  onClick={() => {
                    const curmp = data.items?.find(
                      (item) => item.id === serviceDetail.mp_external_id
                    );
                    navigate(`/ui/dataAsset/modelRegistry/${curmp.id}`);
                  }}
                >
                  <OpenInNewOutlinedIcon
                    sx={{ width: 16, height: 16, borderRadius: 4 }}
                  />
                </IconButton>
              </>
            ) : (
              "-"
            )}
          </Stack>
        ),
      },
      {
        subTitle: "Scenario",
        label: !isEmpty(serviceDetail?.scenario) ? (
          <MlRuntimeScenarioLabel>
            {serviceDetail?.scenario}
          </MlRuntimeScenarioLabel>
        ) : (
          "-"
        ),
      },
      { subTitle: "Description", label: serviceDetail?.description || "-" },
      { subTitle: "Tags", label: getTagsContent() },
      { subTitle: "Created At", label: serviceDetail?.created_at || "-" },
      { subTitle: "Last Modified", label: serviceDetail?.updated_at || "-" },
      { subTitle: "Modified By", label: serviceDetail?.updated_by || "-" },
    ];
  }, [serviceTagList, data, serviceDetail]);

  useEffect(() => {
    getRuntimeDetails();
  }, [getRuntimeDetails]);

  const handleDelete = () => {
    try {
      setDelLoading(true);
      const params = {
        is_active: 0,
      };
      dispatch(
        editMlService({
          id: serviceDetail?.id,
          params,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then(() => {
          setDelLoading(false);
          setOpenConfirm(false);
          enqueueSnackbar("Delete Success", { variant: "success" });
        });
    } catch (err) {
      throw err;
    }
  };

  const getExtraBtns = () => {
    return (
      <Box sx={{ pt: 1.5, pr: 5 }}>
        <Button
          onClick={handleClick}
          variant="outlined"
          endIcon={
            <ExpandMoreOutlinedIcon
              sx={{
                transform: open ? "rotate(-180deg)" : "rotate(0deg)",
                transition: "transform 0.5s",
              }}
            />
          }
          color="secondary"
          sx={{ width: "150px" }}
        >
          Actions
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "150px",
            },
          }}
        >
          <MenuItem onClick={() => navigate(`mlRuntimeEdit`)}>Edit</MenuItem>
          <MenuItem
            onClick={() => {
              setOpenConfirm(true);
              handleClose();
            }}
            disabled={deploymentList.length > 0}
          >
            Delete
          </MenuItem>
        </Menu>
      </Box>
    );
  };

  const ML_SERVICE_TABS = [
    { value: "deployment", label: "Deployment", component: <Deployment /> },
    {
      value: "tester",
      label: "Tester",
      component: (
        <Tester
          curDeployment={curDeployment}
          setCurDeployment={setCurDeployment}
        />
      ),
    },
    {
      value: "metrics",
      label: "Metrics",
      component: (
        <Metrics
          curDeployment={curDeployment}
          setCurDeployment={setCurDeployment}
        />
      ),
    },
    {
      value: "logger",
      label: "Logger",
      component: (
        <Logger
          curDeployment={curDeployment}
          setCurDeployment={setCurDeployment}
        />
      ),
    },
  ];

  return (
    <Page
      title={`${serviceDetail?.name}| OmniML`}
      heading={serviceDetail?.name || ""}
      links={getLinks()}
    >
      <Scrollbar sx={{ minHeight: "600px" }}>
        <MlCard title="Basic Information" isCanFold extraJsx={getExtraBtns()}>
          <MlCardList list={getRuntimeDetails()} />
        </MlCard>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ bgcolor: "background.neutral" }}
        >
          <Tabs
            // centered
            value={currentTab}
            // allowScrollButtonsMobile
            onChange={(_, value) => setCurrentTab(value)}
            sx={{ px: 2 }}
          >
            {ML_SERVICE_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                value={tab.value}
                label={tab.label}
                sx={{
                  "&:not(:last-of-type)": {
                    marginRight: (theme) => theme.spacing(3),
                  },
                }}
              />
            ))}
          </Tabs>
        </Stack>

        {ML_SERVICE_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Scrollbar>
      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete runtime{" "}
            <strong> {serviceDetail.name} </strong> item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelete();
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </Page>
  );
};

export default MlRuntimeDetail;

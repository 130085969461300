import { addTags, getTags } from "@/redux/project/administration/thunks";
import { RootState, dispatch, useSelector } from "@/redux/store";
import { Autocomplete } from "@mui/material";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Field, FormikProps } from "formik";
import { useSnackbar } from "notistack";
import { useLocation, useParams } from "react-router";
import PipelineScenarioLabel from "../PipelineScenarioLabel";
import { IPipelineInitialValuesTypes } from "../../PipelineCreateOrEdit";
import { useEffect } from "react";
import { getRelPipelineVolumeData } from "@/redux/project/automation/thunks";
import { isEmpty } from "lodash";

interface IProps {
  formik: FormikProps<IPipelineInitialValuesTypes>;
}

const PipelineSettings = ({ formik }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const isEdit = pathname.includes("edit");
  const { mlPipelineId } = useParams();

  const {
    tags: { items: tagList },
  } = useSelector((state: RootState) => state.administration);

  const { config: { common = {} } = {} } = useSelector(
    (state: RootState) => state.common
  );
  const { scenario: scenarioList = [] } = common;

  const {
    pipelineDetail: { data },
  } = useSelector((state: RootState) => state.automation);

  const {
    pipelineVolumeTableList: { data: volumeList },
  } = useSelector((state: RootState) => state.automation);
  const startedVolumeList = volumeList?.items?.filter(
    (item) => item.status === "started"
  );

  const { values, errors, touched, setFieldValue } = formik;

  useEffect(() => {
    if (!isEdit) {
      setFieldValue("volume", startedVolumeList?.[0]?.id || "");
    } else {
      // get rel_mlpipeline_volume
      dispatch(
        getRelPipelineVolumeData({
          params: {
            mlpipeline_id: mlPipelineId!,
            page_num: 1,
            page_size: 9999,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((res) => {
          setFieldValue("volume", res?.items?.[0]?.mlpipeline_volume_id || "");
        });
    }
  }, []);

  useEffect(() => {
    if (isEdit && !isEmpty(data)) {
      setFieldValue("name", data?.name || "");
      setFieldValue("scenario", data?.scenario || "");
      setFieldValue("description", data?.description || "");

      // todo
      // get template version select to change
    }
  }, [data]);

  return (
    <Box sx={{ width: "800px" }}>
      <Field
        as={TextField}
        name="name"
        label="Name"
        disabled={isEdit}
        size="small"
        fullWidth
        margin="dense"
        helperText={(touched.name && errors.name) || " "}
        error={touched.name && Boolean(errors.name)}
        FormHelperTextProps={{ sx: { minHeight: "18px", marginTop: "2px" } }}
      />
      {values.type === "Kubeflow" && (
        <FormControl fullWidth margin="dense" size="small" sx={{ mb: 2 }}>
          <InputLabel>Pipeline Volume</InputLabel>
          <Field
            as={Select}
            disabled={isEdit}
            name="volume"
            label="Pipeline Volume"
          >
            {startedVolumeList?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
      )}

      <FormControl fullWidth margin="dense" size="small" sx={{ mb: 2 }}>
        <InputLabel>Scenario (Optional)</InputLabel>
        <Field as={Select} name="scenario" label="Scenario (Optional)">
          {scenarioList?.map((item) => (
            <MenuItem value={item} key={item}>
              <PipelineScenarioLabel>{item}</PipelineScenarioLabel>
            </MenuItem>
          ))}
        </Field>
      </FormControl>

      <Autocomplete
        size="small"
        multiple
        disableCloseOnSelect
        disablePortal
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        limitTags={8}
        getOptionLabel={(option) =>
          typeof option === "object" ? option.name : option
        }
        isOptionEqualToValue={(option, value) => {
          if (typeof option === "object" && typeof value === "object") {
            return option.id === value.id;
          } else if (typeof option === "string" || typeof value === "string") {
            return option === value;
          }
          return false;
        }}
        options={tagList}
        value={values.tags.map(
          (tagId) => tagList.find((tag) => tag.id === tagId) || tagId
        )}
        onChange={(_, newValues) => {
          const filteredValues = newValues.filter(
            (item) => typeof item === "string"
          );

          if (tagList.find((i) => i.name === filteredValues[0])) {
            enqueueSnackbar(`Tag ${filteredValues[0]} existed`, {
              variant: "warning",
            });
            return;
          }

          const updatedTagsPromises = newValues.map(async (item) => {
            if (typeof item === "string") {
              // new text ,add a new tag
              const res = await dispatch(
                addTags({
                  params: {
                    is_active: 1,
                    name: item,
                  },
                  alertCallback: enqueueSnackbar,
                  pathname,
                })
              ).unwrap();

              enqueueSnackbar(`Add Tag ${item} Success`, {
                variant: "success",
              });

              await dispatch(
                getTags({
                  params: {
                    is_active: 1,
                    page_num: 1,
                    page_size: 9999,
                  },
                  alertCallback: enqueueSnackbar,
                  pathname,
                })
              );

              return res.id;
            }
            return item.id;
          });

          Promise.all(updatedTagsPromises).then((r) => {
            setFieldValue("tags", r);
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Tags (Optional)"
            helperText=" "
            FormHelperTextProps={{
              style: {
                minHeight: "18px",
                marginTop: "2px",
              },
            }}
          />
        )}
        sx={{ mt: 1, mb: 0.5 }}
      />

      <Field
        as={TextField}
        multiline
        rows={4}
        name="description"
        label="Description (Optional)"
        size="small"
        fullWidth
        margin="dense"
      />
    </Box>
  );
};

export default PipelineSettings;

/* eslint-disable react-hooks/exhaustive-deps */
import Page from "@/components/project/Page";
import MlCard from "@/components/project/mlComponents/MlCard";
import { PATH_DASHBOARD } from "@/routes/paths";
import { Box, Button, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import ProjectRolesTable from "./components/projectDetail/ProjectRolesTable";
// import { Link as RouterLink } from 'react-router-dom';
import MlCardList from "@/components/project/mlComponents/MlCardList";
import { RootState, dispatch } from "@/redux/store";
import {
  delProject,
  getOrganizationDetailData,
  getProjectDetailData,
  getProjectRolesTableDataInProject,
  getProjectTags,
  getTagDetail,
} from "@/redux/project/administration/thunks";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { resetAdministrationData } from "@/redux/project/administration/slice";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import Label from "@/components/project/Label";
import { isEmpty } from "lodash";

enum StatusEnum {
  started = "success",
  closed = "default",
  updated = "secondary",
  failed = "error",
}

const ProjectDetail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const { orgId, projectId } = useParams();
  const isFromPgManageSiderbar = pathname.includes("/projectGovernance/manage");

  const [leaderGroup, setLeaderGroup] = useState({
    value: "",
    loading: false,
  });

  const [tags, setTags] = useState<{
    value: string[];
    loading: boolean;
  }>({
    value: [],
    loading: false,
  });

  const {
    isSuperAdmin,
    userDetail: { organization_id },
  } = useSelector((state: RootState) => state.common);

  const isJustSuperAdmin = () => {
    return isSuperAdmin && !organization_id;
  };

  const {
    organizationDetailData: { data: orgData, loading: orgLoading },
    projectDetailData: { data, loading },
  } = useSelector((state: RootState) => state.administration);

  const { resource_config: { aws: orgAws = {}, k8s = {} } = {} } = orgData;

  const {
    config: {
      govern,
      common: { taichi_git_url_prefix, taichi_docker_url_prefix },
    },
    userDetail: {
      project_id: manageProjectId,
      organization_id: curOrgIdOfAdmin,
      project_list = [],
    },
  } = useSelector((state: RootState) => state.common);

  // const org_name = project_list?.[manageProjectId]?.[1];
  const org_name = project_list?.find(
    (item) => item.id === manageProjectId
  )?.organization_name;

  const curProjectIdInDetail =
    isFromPgManageSiderbar && manageProjectId ? manageProjectId : projectId;

  const curOrgIdInDetail =
    isFromPgManageSiderbar && manageProjectId ? curOrgIdOfAdmin : orgId;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // delete confirm
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLinks = () => {
    if (manageProjectId && isFromPgManageSiderbar) {
      return [
        {
          name: "Project Governance",
        },
        {
          name: "Manage",
        },
        {
          name: data?.name || "",
        },
      ];
    }

    return [
      {
        name: "Administration",
      },
      {
        name: "Organization",
        href: PATH_DASHBOARD.administration.organization,
      },
      {
        name: orgData?.name || "",
        href: `${PATH_DASHBOARD.administration.organization}/${orgId}`,
      },
      {
        name: data?.name || "",
      },
    ];
  };

  const getExtraBtn = () => {
    return (
      <Box sx={{ width: "120px" }}>
        <Button
          onClick={handleClick}
          variant="outlined"
          endIcon={
            <ExpandMoreOutlinedIcon
              sx={{
                transform: open ? "rotate(0)" : "rotate(90deg)",
                transition: "transform 0.5s",
              }}
            />
          }
          color="secondary"
          fullWidth
        >
          Actions
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "120px",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              if (isFromPgManageSiderbar) {
                navigate(`${manageProjectId}/projectEdit`);
              } else {
                navigate("projectEdit");
              }
            }}
          >
            Edit
          </MenuItem>
          {!isFromPgManageSiderbar && (
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenConfirm(true);
              }}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
      </Box>
    );
  };

  const projectBaseInfoList = () => {
    return [
      { subTitle: "Project Name", label: data?.name || "-", loading },
      {
        subTitle: "Project Type",
        label: govern?.project_type?.[data?.project_type] || "-",
        loading,
      },
      {
        subTitle: "Organization",
        label: orgData?.name || org_name || "-",
        loading: orgLoading,
      },
      {
        subTitle: "Leader Group",
        label: leaderGroup.value || "-",
        loading: leaderGroup.loading,
      },
      {
        subTitle: "Status",
        label: data?.status ? (
          <Label color={StatusEnum[data?.status]} sx={{ width: "80px" }}>
            {data?.status}
          </Label>
        ) : (
          "-"
        ),
        loading,
      },
      // { subTitle: 'AD Group', label: data?.ad_group || '-', loading, },
      { subTitle: "Last Modified", label: data?.updated_at || "-", loading },
      {
        subTitle: "Tags",
        label: !isEmpty(tags.value) ? (
          <Stack direction="row" spacing={1}>
            {tags.value.map((i) => (
              <Label color="default" sx={{ width: "80px" }}>
                {i}
              </Label>
            ))}
          </Stack>
        ) : (
          "-"
        ),
        loading: tags.loading,
      },
      {
        subTitle: "Description",
        label: data?.description ? (
          <Tooltip title={data?.description}>
            <Typography
              variant="body2"
              textOverflow="ellipsis"
              noWrap
              sx={{ color: "text.secondary" }}
            >
              {data?.description}
            </Typography>
          </Tooltip>
        ) : (
          "-"
        ),
        loading,
      },
    ];
  };

  const getResourceList = () => {
    return [
      {
        subTitle: "Kubernetes Namespace",
        label: `omniml-prj-${curProjectIdInDetail}`,
        loading,
      },
      ...(!isFromPgManageSiderbar
        ? [
            {
              subTitle: "Kubernetes Cluster",
              label: k8s?.cluster_names?.join(",") || "-",
              loading,
            },
          ]
        : []),
      {
        subTitle: "Artifactory Address",
        label: `${taichi_docker_url_prefix}${curOrgIdInDetail}/${curProjectIdInDetail}`,
        loading,
      },
      ...(!isFromPgManageSiderbar
        ? [
            {
              subTitle: "AWS Region",
              label: orgAws?.region || "-",
              loading: orgLoading,
            },
          ]
        : []),
      {
        subTitle: "AWS S3 Object Prefix",
        label: `omniml-prj-${curProjectIdInDetail}`,
        loading,
      },
      {
        subTitle: "Git Folder",
        label: `${taichi_git_url_prefix}${curOrgIdInDetail}/${curProjectIdInDetail}`,
        loading,
      },
    ];
  };

  const getResourceQuotaList = () => {
    const { namespace: { resource_quota = {} } = {} } =
      data?.resource_config || {};
    const { cpu, ephemeral_storage, memory, requests = {} } = resource_quota;

    return [
      { subTitle: "CPU", label: cpu || "-", loading },
      { subTitle: "Memory", label: memory || "-", loading },
      { subTitle: "Storage", label: requests?.storage || "-", loading },
      {
        subTitle: "Ephemeral Storage",
        label: ephemeral_storage || "-",
        loading,
      },
      {
        subTitle: "Requests.nvidia.com/gpu",
        label: requests?.["nvidia.com/gpu"] || "-",
        loading,
      },
    ];
  };

  const handleDelProject = async () => {
    setDelLoading(true);
    try {
      // projectGovernance manage don't allow delete
      await dispatch(
        delProject({
          id: projectId!,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      setDelLoading(false);
      setOpenConfirm(false);
      enqueueSnackbar("Delete Success", { variant: "success" });

      navigate(`${PATH_DASHBOARD.administration.organization}/${orgId}`);
    } catch (e) {
      setDelLoading(false);
    }
  };

  useEffect(() => {
    if (!isFromPgManageSiderbar) {
      dispatch(
        getOrganizationDetailData({
          id: curOrgIdInDetail,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      );
    }

    dispatch(
      getProjectDetailData({
        id: curProjectIdInDetail,
        organization_id: curOrgIdInDetail,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );

    // get leader group (binding with project leader role)
    setLeaderGroup({
      ...leaderGroup,
      loading: true,
    });
    dispatch(
      getProjectRolesTableDataInProject({
        params: {
          project_id: curProjectIdInDetail,
          page_num: 1,
          page_size: 999,
        },
        pathname,
        hasDescription: true,
        alertCallback: enqueueSnackbar,
      })
    )
      .unwrap()
      .then((res) => {
        const targetItem = res.items.find(
          (item) => item.name === "Project Leader"
        );
        setLeaderGroup({
          value: targetItem?.ad_group || "",
          loading: false,
        });
      })
      .catch(() => {
        setLeaderGroup({
          value: "",
          loading: false,
        });
      });

    // get rel_project_tag tags
    setTags({
      value: [],
      loading: true,
    });
    dispatch(
      getProjectTags({
        params: {
          project_id: curProjectIdInDetail,
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    )
      .unwrap()
      .then((res) => {
        Promise.all(
          res.items.map((item) =>
            dispatch(
              getTagDetail({
                id: item.tag_id,
                alertCallback: enqueueSnackbar,
                pathname,
              })
            ).unwrap()
          )
        )
          .then((r) => {
            setTags({
              value: r?.map((i) => i.name) || [],
              loading: false,
            });
          })
          .catch(() => {
            setTags({
              value: [],
              loading: false,
            });
          });
      });
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetAdministrationData("projectDetailData"));
    };
  }, []);

  return (
    <Page
      title={`${data?.name} | OmniML`}
      heading={data?.name || ""}
      links={getLinks()}
    >
      <MlCard
        isCanFold
        title="Basic Information"
        extraJsx={
          !(isJustSuperAdmin() || isFromPgManageSiderbar) && getExtraBtn()
        }
      >
        <MlCardList list={projectBaseInfoList()} />
      </MlCard>
      <MlCard isCanFold title="Resources">
        <MlCardList list={getResourceList()} />
      </MlCard>
      <MlCard isCanFold title="Kubernetes Namespace Resource Quota">
        <MlCardList list={getResourceQuotaList()} />
      </MlCard>

      {isFromPgManageSiderbar && (
        <ProjectRolesTable curProjectId={curProjectIdInDetail} />
      )}

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete project <strong> {data.name} </strong> item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelProject();
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </Page>
  );
};

export default ProjectDetail;

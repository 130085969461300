import { useState } from "react";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
// material
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  Portal,
  Divider,
  Backdrop,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
// @types
import { RootState, useSelector } from "@/redux/store";
//
import PipelineSteps from "./PipelineSteps";
import PipelineParameters from "./PipelineParameters";
import PipelineStepConfig from "./PipelineStepConfig";
import PipelineEdgeConfig from "./PipelineEdgeConfig";
import PipelineConfigurations from "./PipelineConfigurations";
import { SchemaNodeEntity, SchemaEdgeEntity } from "../graph/slices/slice";
//
import Scrollbar from "@/components/project/Scrollbar";
import AdvancedSettings from "./AdvancedSettings";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  right: 0,
  top: 0,
  zIndex: 1999,
  minHeight: 440,
  // height: 'auto',
  outline: "none",
  display: "flex",
  position: "fixed",
  overflow: "hidden",
  flexDirection: "column",
  margin: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  boxShadow: theme.customShadows.z20,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
}));

// const InputStyle = styled(Input)(({ theme }) => ({
//   padding: theme.spacing(0.5, 3),
//   borderBottom: `solid 1px ${theme.palette.divider}`
// }));

// ----------------------------------------------------------------------

type SettingSidebarExpandProps = {
  name?: string;
  isEdit: boolean;
  isExpand: boolean;
  onCloseExpand: VoidFunction;
};

export default function SettingSidebarExpand({
  name,
  isEdit,
  isExpand,
  onCloseExpand,
}: SettingSidebarExpandProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { schema } = useSelector((state: RootState) => state.schema);
  const { currentPipeline, currentKubeflowPipeline } = useSelector(
    (state: RootState) => state.pipeline
  );
  const { pipelineType } = useSelector((state: RootState) => state.schema);
  const isKubeflow = pipelineType === "Kubeflow";
  const commonPipelineData = isKubeflow
    ? currentKubeflowPipeline
    : currentPipeline;

  const currentEntity = useSelector((state: RootState) => {
    const currentEntityId = state.schema.currentEntityId;
    let entity: SchemaNodeEntity | SchemaEdgeEntity | undefined =
      state.schema.schema.pipelineSteps.find((entity) => {
        return entity.id === currentEntityId;
      });
    if (!entity) {
      entity = state.schema.schema.edgeEntities.find(
        (entity) => entity.id === currentEntityId
      );
    }
    return entity ? entity : null;
  });

  // const [openSidebar, setOpenSidebar] = useState(true);
  // const [fullScreen, setFullScreen] = useState(false);
  const [showPipelineConfig, setShowPipelineConfig] = useState(true);
  const [showPipelineSteps, setShowPipelineSteps] = useState(false);
  const [showPipelineParams, setShowPipelineParams] = useState(false);
  const [showPipelineAdvancedSettings, setShowPipelineAdvancedSettings] =
    useState(false);

  const isEdgeEntity =
    (currentEntity as SchemaEdgeEntity)?.relationship != null;

  const handleClose = () => {
    onCloseExpand();
  };

  if (!isExpand) {
    return null;
  }

  return (
    <Portal>
      <Backdrop open={isMobile} sx={{ zIndex: 1998 }} />
      <RootStyle
        sx={{
          ...{
            width: {
              xs: `calc(100% - 32px)`,
              md: "324px",
            },
            height: {
              xs: `calc(100% - 32px)`,
              md: `calc(100% - 32px)`,
            },
          },
        }}
      >
        <Box
          sx={{
            pl: 2,
            pr: 0.5,
            height: 50,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" color={theme.palette.text.secondary}>
            {currentEntity ? "STEP SETTINGS" : "PIPELINE SETTINGS"}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          <IconButton onClick={handleClose}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Box>

        <Divider />

        <Scrollbar>
          {!currentEntity && (
            <Box>
              <PipelineConfigurations
                name={name}
                isEdit={isEdit}
                isCollapse={showPipelineConfig}
                onCollapse={() => setShowPipelineConfig((prev) => !prev)}
              />

              <Divider />

              <PipelineSteps
                allSteps={schema.pipelineSteps}
                isCollapse={showPipelineSteps}
                onCollapse={() => setShowPipelineSteps((prev) => !prev)}
              />

              <Divider />

              <PipelineParameters
                parameters={commonPipelineData.pipelineParamList}
                isCollapse={showPipelineParams}
                onCollapse={() => setShowPipelineParams((prev) => !prev)}
                openCollapse={() => {
                  setShowPipelineParams(true);
                }}
                isEdit={isEdit}
              />

              <Divider />

              <AdvancedSettings
                data={commonPipelineData.advancedSettings}
                isCollapse={showPipelineAdvancedSettings}
                onCollapse={() =>
                  setShowPipelineAdvancedSettings((prev) => !prev)
                }
                isEdit={isEdit}
              />
            </Box>
          )}
          {currentEntity && isEdgeEntity ? (
            <PipelineEdgeConfig entity={currentEntity as SchemaEdgeEntity} />
          ) : (
            <PipelineStepConfig
              isEdit={isEdit}
              entity={currentEntity as SchemaNodeEntity}
            />
          )}
        </Scrollbar>
      </RootStyle>
    </Portal>
  );
}

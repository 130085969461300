import { useState } from "react";
// material
import { Card } from "@mui/material";
// redux
// components
import {
  TriggerSidebar,
  PipelineCanvas,
  SettingSidebar,
  SettingSidebarExpand,
} from "../../pipeline-construction";

// ----------------------------------------------------------------------

export default function PipelineConstructionPanel({
  isEdit,
  name,
}: {
  isEdit: boolean;
  name?: string;
}) {
  const [expandSideBar, setExpandSideBar] = useState(false);

  return (
    <Card sx={{ height: "74vh", display: "flex", position: "relative" }}>
      <TriggerSidebar />
      {/* isEdit for create or edit api when click upload */}
      <PipelineCanvas isEdit={isEdit} />
      {/* isEdit for disable some form components and show the Pipeline Arn (Sagemaker when editing) */}
      {expandSideBar ? (
        <SettingSidebarExpand
          name={name}
          isEdit={isEdit}
          isExpand={expandSideBar}
          onCloseExpand={() => setExpandSideBar(false)}
        />
      ) : (
        <SettingSidebar
          name={name}
          isEdit={isEdit}
          onExpand={() => setExpandSideBar(true)}
        />
      )}
    </Card>
  );
}

import { Box, Stack } from "@mui/material";
import Page from "@/components/project/Page";
import DashbordStatistic from "./components/mlRuntimeDashboard/DashbordStatistic";
import MlRuntimeTable from "./components/mlRuntimeDashboard/MlRuntimeTable";

export default function MlRuntime() {
  const getLinks = () => {
    return [
      {
        name: "ML Service",
      },
      {
        name: "ML Runtime",
      },
    ];
  };

  return (
    <Page heading="ML Runtime" links={getLinks()}>
      <Box sx={{ mb: 2 }}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <DashbordStatistic />
        </Stack>
      </Box>

      <MlRuntimeTable />
    </Page>
  );
}

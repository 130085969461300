// material
import { styled } from "@mui/material/styles";
// @types
import { TriggerManager } from "@/@types/pipeline";
import { TriggerOption } from "@/@types/project/mlPipeline/SageMaker/pipeline-trigger";

export const OptionStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 2.5),
  justifyContent: "space-between",
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("all"),
  border: `solid 1px ${theme.palette.grey[500_32]}`,
}));

export const RootStyle = styled("div")(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 1999,
  minHeight: 440,
  outline: "none",
  display: "flex",
  position: "fixed",
  overflow: "hidden",
  flexDirection: "row",
  margin: theme.spacing(3),
  boxShadow: theme.customShadows.z20,
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.background.paper,
}));

const prefix = process.env.NODE_ENV === "development" ? "" : "/ui";

export const TRIGGER_OPTIONS: TriggerOption[] = [
  {
    value: "scheduled",
    title: "Schedule trigger",
    description: "Run the pipeline according to a schedule.",
    icon: `${prefix}/static/icons/pipeline/ic_schedule.svg`,
  },
  /*   {
    value: "amazon_s3",
    title: "Amazon S3 trigger",
    description: "Run the pipeline when files are added/updated to S3.",
    icon: "/static/icons/pipeline/ic_amazons3.svg",
  }, */
  /* {
    value: "artifactory",
    title: "Artifactory Trigger",
    description: "Run the pipeline when new images are pushed.",
    icon: "/static/icons/pipeline/IoMdGitBranch.svg",
  }, */
  // {
  //   value: "git",
  //   title: "Git trigger",
  //   description: "Run the pipline when new commits  are pushed.",
  //   icon: "/static/icons/pipeline/ic_codecommit.svg",
  // },
];

export const formTriggerProps = (
  currentTimestamp: number,
  _triggerProps: TriggerManager | null
) => {
  const triggerProps = {
    type: "scheduled",
    triggerName: `Trigger-${currentTimestamp}`,
    triggerDescription: "Run the pipeline on a schedule.",
    enableTrigger: true,
    schedule: "regular",
    regularScheduleValue: "12",
    regularScheduleUnit: "hours",
    cronMinutes: "0",
    cronHours: "12",
    cronDom: "*",
    cronMonth: "*",
    cronDow: "?",
    cronYear: "*",
    s3BucketName: "",
    s3Prefix: "",
    s3Suffix: "",
  };

  if (_triggerProps) {
    triggerProps.type = _triggerProps.Type;
    triggerProps.triggerName = _triggerProps.Name;
    triggerProps.triggerDescription = _triggerProps.Description;
    triggerProps.enableTrigger = _triggerProps.State === "ENABLED";
    if (triggerProps.type === "scheduled") {
      if (_triggerProps.ScheduleExpression.startsWith("cron")) {
        triggerProps.schedule = "cron";
        const cronComps = _triggerProps.ScheduleExpression.replace(")", "")
          .split("(")[1]
          .split(" ");
        triggerProps.cronMinutes = cronComps[0];
        triggerProps.cronHours = cronComps[1];
        triggerProps.cronDom = cronComps[2];
        triggerProps.cronMonth = cronComps[3];
        triggerProps.cronDow = cronComps[4];
        triggerProps.cronYear = cronComps[5];
      } else {
        const regularComps = _triggerProps.ScheduleExpression.replace(")", "")
          .split("(")[1]
          .split(" ");
        triggerProps.regularScheduleValue = regularComps[0];
        triggerProps.regularScheduleUnit = regularComps[1];
      }
    } else if (triggerProps.type === "amazon_s3") {
      if (_triggerProps.EventPattern) {
        const pattern = JSON.parse(_triggerProps.EventPattern);
        triggerProps.s3BucketName = pattern.detail.bucket.name.join(",");
        triggerProps.s3Prefix = pattern.detail.object.key.join(",");
      }
    }
  }

  return triggerProps;
};

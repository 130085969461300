import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  FormHelperText,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Scrollbar from "@/components/project/Scrollbar";
import { extractKeys } from "@/utils/project/utils";
import HyperParamsTableActionCol from "./HyperParamsTableActionCol";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import MlTableBodyContainer from "@/components/project/mlComponents/MlTableBodyContainer";
import { IKubeflowHpTunerFormValues } from "../..";
import { FormikProps } from "formik";
import { useLocation } from "react-router";
import { isArray } from "lodash";
import HyperParamsDialog from "./HyperParamsDialog";

interface Column {
  id: string;
  label?: string;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
}

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 150 },
  { id: "parameterType", label: "Type" },
  { id: "domain", label: "Domain", maxWidth: 75 },
  { id: "", width: 150 },
];

interface IHyperParamsTableProps {
  formik: FormikProps<IKubeflowHpTunerFormValues>;
}

const HyperParamsTable: React.FC<IHyperParamsTableProps> = ({ formik }) => {
  const { pathname } = useLocation();
  const isDuplicate = pathname.includes("hpTunerDuplicate");

  const [tableData, setTableData] = useState<any>([]);

  // dialog
  const [mode, setMode] = useState<"create" | "edit">("create");

  const [hyperParamsDialogOpen, setHyperParamsDialogOpen] =
    useState<boolean>(false);

  const [rowData, setRowData] = useState({});

  const {
    hptunerDetail: { loading },
  } = useSelector((state: RootState) => state.experiment);

  const renderCell = (row, column) => {
    switch (column.id) {
      case "name":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="subtitle2">{row[column.id]}</Typography>
          </TableCell>
        );
      case "":
        return (
          <TableCell
            align="right"
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <HyperParamsTableActionCol
              row={row}
              setDialogModeAndSetData={() => {
                setHyperParamsDialogOpen(true);
                setMode("edit");
                setRowData(row);
              }}
              formik={formik}
            />
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {row[column.id]}
            </Typography>
          </TableCell>
        );
    }
  };

  const generateRowDomain = (item) => {
    const { name, parameterType: type, feasibleSpace = {} } = item;

    if (type === "double" || type === "integer") {
      return `${feasibleSpace.min} <= ${name} <= ${feasibleSpace.max}${
        feasibleSpace.step ? `, ${feasibleSpace.step}` : ""
      }`;
    }

    if (isArray(feasibleSpace)) {
      return feasibleSpace?.map((item) => item.value).join(",");
    }

    return "";
  };

  const { values, errors, touched } = formik;

  useEffect(() => {
    setTableData(
      values.hyperParameters?.map((item) => ({
        ...item,
        name: item.name,
        parameterType: item.parameterType,
        domain: generateRowDomain(item),
      }))
    );
  }, [values.hyperParameters]);

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <MlTableBodyContainer
                data={tableData}
                loading={(isDuplicate && loading) || false}
              >
                {tableData?.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((column) => renderCell(row, column))}
                  </TableRow>
                ))}
              </MlTableBodyContainer>
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <FormHelperText
        error
        margin="dense"
        sx={{ minHeight: "18px", marginTop: "2px", pl: 1 }}
      >
        {touched?.hyperParameters && errors?.hyperParameters}
      </FormHelperText>
      <Button
        variant="text"
        color="secondary"
        sx={{ mt: 2 }}
        startIcon={<AddOutlinedIcon />}
        onClick={() => {
          setHyperParamsDialogOpen(true);
          setMode("create");
        }}
      >
        Add Parameter
      </Button>

      <HyperParamsDialog
        mode={mode}
        isOpen={hyperParamsDialogOpen}
        onClose={() => {
          setHyperParamsDialogOpen(false);
        }}
        row={rowData}
        clearRowData={() => {
          setRowData({});
        }}
        totalFormik={formik}
      />
    </>
  );
};

export default HyperParamsTable;

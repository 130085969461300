import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import { useDispatch } from "react-redux";
// material
import { Paper, Stack, IconButton } from "@mui/material";
// type
import { ParameterRangeStruct } from "@/@types/project/mlPipeline/SageMaker/pipeline-tuning";
//
import {
  deleteTuningParameterRange,
  updateTuningParameterRanges,
} from "../../../graph/slices/slice";
import {
  PipelineTextfield,
  PipelineSelect,
} from "../../components/PipelineComponents";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";
import PipelineParameterWithSelector from "../../components/PipelineParameterWithSelector";

// ----------------------------------------------------------------------

const parameterTypes = [
  { code: "A", label: "Continuous" },
  { code: "B", label: "Categorical" },
  { code: "C", label: "Integer" },
];

const scalingTypes = [
  { code: "A", label: "Auto" },
  { code: "B", label: "Linear" },
  { code: "C", label: "Logarithmic" },
  { code: "D", label: "ReverseLogarithmic" },
];

export default function ParameterRange({
  id,
  data,
}: {
  id: number;
  data: ParameterRangeStruct;
}) {
  const dispatch = useDispatch();
  const [isInitialzied, setInitialized] = useState(false);
  const [inputValues, setInputValues] = useState(data);

  useEffect(() => {
    if (isInitialzied) {
      const timer = setTimeout(() => {
        dispatch(
          updateTuningParameterRanges({
            id: id,
            data: inputValues,
          })
        );
        setInitialized(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [dispatch, id, inputValues, isInitialzied]);

  const ParamSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const formik = useFormik<ParameterRangeStruct>({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values) => {
      try {
        setInitialized(true);
        setInputValues(values);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Paper sx={{ p: 1.5, borderRadius: 1.5, bgcolor: "background.neutral" }}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onChange={handleSubmit}>
            <Stack spacing={2}>
              <PipelineTextfield
                label={"Name"}
                field={"name"}
                placeholder={"Name"}
                formik={formik}
              />

              <PipelineSelect
                label={"Type"}
                field={"type"}
                placeholder={"Select parameter type"}
                options={parameterTypes}
                getFieldProps={getFieldProps}
              />

              <PipelineParameterEntry
                label={"MinValue"}
                field={"minValue"}
                fieldUseParam={"minValueUseParam"}
                formik={formik}
              />

              <PipelineParameterEntry
                label={"MaxValue"}
                field={"maxValue"}
                fieldUseParam={"maxValueUseParam"}
                formik={formik}
              />

              <Stack direction="row" spacing={-0.2} alignItems="center">
                <PipelineParameterWithSelector
                  label={"ScalingType"}
                  field={"scalingType"}
                  placeholder={"Select scaling type"}
                  fieldUseParam={"scalingTypeUseParam"}
                  options={scalingTypes}
                  formik={formik}
                />

                <IconButton
                  size="small"
                  onClick={() => {
                    dispatch(deleteTuningParameterRange(id));
                  }}
                >
                  <Icon icon={trash2Fill} width={18} height={18} />
                </IconButton>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </Paper>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik, FieldArray, FormikProvider, Form, Field } from "formik";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  Stack,
  Grid,
  Box,
  Typography,
  IconButton,
  FormHelperText,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import * as yup from "yup";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import {
  addConnection,
  getConnectionDetail,
  updateConnection,
} from "@/redux/project/connection/thunks";
import { RootState, dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { resetConnectionData } from "@/redux/project/connection/slice";
import { LoadingButton } from "@mui/lab";
import { uuidv7 } from "uuidv7";

interface IConnectionCreateOrEditDialog {
  mode: "create" | "edit";
  isOpen: boolean;
  onClose: VoidFunction;
  row?: Record<string, any>;
  handleRefresh: (a: boolean) => void;
}

interface FormValues {
  connectionName: string;
  connectionType: string;
  scheme: string;
  endpoint: string;
  port: string;
  description: string;
  lines: Array<{ key: string; value: string }>;
}

const initialValues: FormValues = {
  connectionName: "",
  connectionType: "",
  scheme: "",
  endpoint: "",
  port: "",
  description: "",
  lines: [],
};

const validationSchema = yup.object({
  connectionName: yup
    .string()
    .required("Connection Name is required")
    .max(64, "Connection Name cannot be longer than 64 characters"),
  connectionType: yup.string().required("Connection Type is required"),
  scheme: yup.string().required("Scheme is required"),
  endpoint: yup
    .string()
    .required("Endpoint is required")
    .max(64, "Endpoint cannot be longer than 64 characters"),
  port: yup
    .number()
    .typeError("Port must be number type")
    .integer("Port must be integer")
    .required("Port is required")
    .min(1, "Port number must be between 1 and 65535")
    .max(65535, "Port number must be between 1 and 65535"),
  description: yup
    .string()
    .max(400, "Description cannot be longer than 400 characters"),
  lines: yup.array().of(
    yup.object({
      key: yup.string().nullable().required("Key is required"),
      value: yup.string().required("Value is required"),
    })
  ),
});

const ConnectionCreateOrEditDialog = ({
  mode,
  isOpen,
  onClose,
  row = {},
  handleRefresh,
}: IConnectionCreateOrEditDialog) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const { config: { govern = {} } = {} } = useSelector(
    (state: RootState) => state.common
  );
  const {
    connection: {
      connection_type: typeList = [],
      connection_scheme: schemeList = [],
    } = {},
  } = govern;

  const {
    connectionDetail: { data },
  } = useSelector((state: RootState) => state.connection);

  const getSchemeListByType = (type) => {
    if (type === "AmazonS3") {
      return ["S3", "HTTPS"];
    }

    if (type === "AmazonRedshift") {
      return ["JDBC"];
    }

    if (type === "CIFS") {
      return ["None"];
    }

    return schemeList;
  };

  const handleCreateSubmit = (values) => {
    const propertiesObj = {};
    values.lines.forEach(({ key, value }) => {
      propertiesObj[key] = value;
    });

    return dispatch(
      addConnection({
        params: {
          external_id: uuidv7(),
          name: values.connectionName,
          connection_type: values.connectionType,
          connection_state: "RELEASED",
          connection_family: "PLATFORM",
          connection_scheme: values.scheme,
          connection_endpoint: values.endpoint,
          connection_port: values.port,
          connection_properties: propertiesObj,
          description: values.description,
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();
  };

  const handleUpdateSubmit = (values) => {
    const propertiesObj = {};
    values.lines.forEach(({ key, value }) => {
      propertiesObj[key] = value;
    });

    return dispatch(
      updateConnection({
        id: row.id,
        params: {
          external_id: row.external_id || uuidv7(),
          name: values.connectionName,
          connection_type: values.connectionType,
          connection_state: row.connection_state || "RELEASED",
          connection_scheme: values.scheme,
          connection_endpoint: values.endpoint,
          connection_port: values.port,
          connection_properties: propertiesObj,
          description: values.description,
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      try {
        if (mode === "edit") {
          await handleUpdateSubmit(values);
          enqueueSnackbar("Edit Success", { variant: "success" });
        } else {
          await handleCreateSubmit(values);
          enqueueSnackbar("Create Success", { variant: "success" });
        }
        actions.setSubmitting(false);

        handleRefresh(true);
        handleClose();
      } catch (e) {
        console.log("error-in-submit", e);
        actions.setSubmitting(false);
      }
    },
  });

  const {
    errors,
    values,
    touched,
    setFieldValue,
    setFieldTouched,
    setValues,
    resetForm,
    isSubmitting,
  } = formik;
  const lineErrors = errors.lines as Array<{ key?: string; value?: string }>;
  const lineTouched = touched.lines as Array<{
    key?: boolean;
    value?: boolean;
  }>;
  console.log("values", values);

  const handleClose = () => {
    onClose();
    resetForm();
    dispatch(resetConnectionData("connectionDetail"));
  };

  useEffect(() => {
    if (isOpen && mode === "create") {
      setFieldValue("connectionName", `omniml-conn-${new Date().getTime()}`);
      setFieldValue("connectionType", typeList[0]);
      setFieldValue("scheme", getSchemeListByType(typeList[0])[0]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !isEmpty(data) && mode === "edit") {
      const lines = Object.entries(data.connection_properties).map(
        ([key, value]) => ({
          key,
          value,
        })
      ) as Array<{ key: string; value: string }>;

      setValues({
        connectionName: data?.name || "",
        connectionType: data?.connection_type || undefined,
        scheme: data?.connection_scheme || undefined,
        endpoint: data?.connection_endpoint || "",
        port: String(data?.connection_port) || "",
        description: data?.description || "",
        lines,
      });
    }
  }, [isOpen, data]);

  const handleInit = async () => {
    try {
      await dispatch(
        getConnectionDetail({
          id: row.id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
    } catch (e) {}
  };

  useEffect(() => {
    if (isOpen && mode === "edit") {
      handleInit();
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown
      onClose={handleClose}
      PaperProps={{
        style: { width: "960px", maxWidth: "none", padding: "20px 40px" },
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        {mode === "create" ? "New" : "Edit"} Connection
      </Typography>
      <FormikProvider value={formik}>
        <Form>
          <Box sx={{ px: 2.5 }}>
            <Field
              as={TextField}
              name="connectionName"
              disabled={mode === "edit"}
              size="small"
              label="Connection Name"
              fullWidth
              margin="dense"
              helperText={
                touched.connectionName && errors.connectionName
                  ? errors.connectionName
                  : " "
              }
              error={touched.connectionName && Boolean(errors.connectionName)}
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
            <Field
              as={TextField}
              name="description"
              size="small"
              label="Description (Optional)"
              fullWidth
              multiline
              rows={4}
              margin="dense"
              helperText={
                touched.description && errors.description
                  ? errors.description
                  : " "
              }
              error={touched.description && Boolean(errors.description)}
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
            <FormControl
              fullWidth
              margin="dense"
              size="small"
              error={touched.connectionType && Boolean(errors.connectionType)}
            >
              <InputLabel>Connection Type</InputLabel>
              <Field
                as={Select}
                disabled={mode === "edit"}
                name="connectionType"
                label="Connection Type"
                onChange={async (event) => {
                  await setFieldValue("connectionType", event.target.value);
                  setFieldTouched("connectionType", true);

                  setFieldValue(
                    "scheme",
                    getSchemeListByType(event.target.value)[0] || schemeList[0]
                  );
                }}
              >
                {typeList.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Field>
              <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                {touched.connectionType && errors.connectionType
                  ? errors.connectionType
                  : ""}
              </FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              margin="dense"
              size="small"
              error={touched.scheme && Boolean(errors.scheme)}
            >
              <InputLabel>Scheme</InputLabel>
              <Field as={Select} name="scheme" label="Scheme">
                {getSchemeListByType(values.connectionType).map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Field>
              <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                {touched.scheme && errors.scheme ? errors.scheme : ""}
              </FormHelperText>
            </FormControl>
            <Field
              as={TextField}
              name="endpoint"
              size="small"
              label="Endpoint"
              fullWidth
              multiline
              margin="dense"
              helperText={
                touched.endpoint && errors.endpoint ? errors.endpoint : " "
              }
              error={touched.endpoint && Boolean(errors.endpoint)}
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
            <Field
              as={TextField}
              name="port"
              size="small"
              label="Port"
              fullWidth
              multiline
              margin="dense"
              helperText={touched.port && errors.port ? errors.port : " "}
              error={touched.port && Boolean(errors.port)}
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
          </Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Custom Properties
          </Typography>
          <FieldArray
            name="lines"
            render={({ push, remove }) => (
              <>
                {values.lines.map((line, index) => (
                  <Stack key={index} direction="row" sx={{ mb: 1, px: 2.5 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <Field
                          as={TextField}
                          name={`lines.${index}.key`}
                          size="small"
                          label="Key"
                          fullWidth
                          margin="dense"
                          helperText={
                            (lineTouched?.[index]?.key &&
                              lineErrors?.[index]?.key) ||
                            ""
                          }
                          error={
                            lineTouched?.[index]?.key &&
                            Boolean(lineErrors?.[index]?.key)
                          }
                          FormHelperTextProps={{
                            sx: { minHeight: "18px", marginTop: "2px" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Field
                          as={TextField}
                          name={`lines.${index}.value`}
                          size="small"
                          label="Value"
                          fullWidth
                          margin="dense"
                          helperText={
                            (lineTouched?.[index]?.value &&
                              lineErrors?.[index]?.value) ||
                            ""
                          }
                          error={
                            lineTouched?.[index]?.value &&
                            Boolean(lineErrors?.[index]?.value)
                          }
                          FormHelperTextProps={{
                            sx: { minHeight: "18px", marginTop: "2px" },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Box sx={{ ml: 1.5, mt: 1 }}>
                      <IconButton onClick={() => remove(index)}>
                        <RemoveOutlinedIcon />
                      </IconButton>
                    </Box>
                  </Stack>
                ))}
                <Box sx={{ px: 2.5 }}>
                  <Button
                    variant="text"
                    color="secondary"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => {
                      push({ key: "", value: "" });
                    }}
                  >
                    Add Property
                  </Button>
                </Box>
              </>
            )}
          />
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ my: 4 }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              color="primary"
              sx={{ width: "200px", color: "background.paper" }}
            >
              Save
            </LoadingButton>
            <Button
              type="button"
              color="inherit"
              variant="outlined"
              onClick={handleClose}
              sx={{ width: "200px", color: "text.secondary" }}
            >
              Cancel
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default ConnectionCreateOrEditDialog;

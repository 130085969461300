import { Icon } from "@iconify/react";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useState } from "react";
// material
import { Box, Stack, Divider, Collapse } from "@mui/material";
// @types
import { RootState, useDispatch, useSelector } from "@/redux/store";
//
import LambdaInputs from "./LambdaInputs";
import LambdaOutputs from "./LambdaOutputs";
import LambdaStepFunction from "./LambdaStepFunction";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { StyledTextField } from "../../components/PipelineComponents";
import { LambdaStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-lambda";
import {
  SchemaNodeEntity,
  updateEntityName,
} from "../../../graph/slices/slice";

// ----------------------------------------------------------------------

type ProcessingStepProps = {
  entity: SchemaNodeEntity;
};

export default function LambdaStepEntry({ entity }: ProcessingStepProps) {
  const dispatch = useDispatch();
  const [showStepConfig, setShowStepConfig] = useState(true);

  const currentStep = useSelector((state: RootState) => {
    let step: LambdaStep = state.schema.schema.pipelineSteps.find((step) => {
      return step.id === entity.id;
    }) as LambdaStep;
    return step;
  });

  return (
    <>
      {entity && currentStep && (
        <Box>
          <CollapseButtonStyle
            fullWidth
            color="inherit"
            onClick={() => setShowStepConfig((prev) => !prev)}
            endIcon={
              <Icon
                icon={
                  showStepConfig ? arrowIosDownwardFill : arrowIosForwardFill
                }
                width={16}
                height={16}
              />
            }
          >
            Configuration
          </CollapseButtonStyle>

          <Collapse in={showStepConfig}>
            <Stack spacing={2} sx={{ mx: 2, my: 1, mb: 1.5 }}>
              <StyledTextField
                label="Step name"
                defaultValue={entity.name}
                onChange={(event: any) => {
                  const newValue = event.target.value;
                  dispatch(updateEntityName({ name: newValue }));
                }}
              />
            </Stack>
          </Collapse>

          <Divider />

          <LambdaStepFunction currentStep={currentStep} />

          <Divider />

          <LambdaInputs currentStep={currentStep} />

          <Divider />

          <LambdaOutputs currentStep={currentStep} />

          <Divider sx={{ mb: 1 }} />
        </Box>
      )}
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteIcon } from "@/assets/project/icons";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Field, FormikProps } from "formik";
import { useEffect, useState } from "react";
import { IKubeflowNotebookCreateFormValues } from "../KubeflowNotebook";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "@/redux/store";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router";
import { getFeatureSetVersionList } from "@/redux/project/dataAsset/featureStore/thunks";
import { getModelPackageVersionList } from "@/redux/project/dataAsset/modelRegistry/thunks";
import { Box } from "@mui/material";

interface IVolumeErrorsType {
  volumeType: string;
  type: string;
  name: string;
  giSize?: string;
  storageClass?: string;
  accessMode?: string;
  isReadOnly?: string;
  mountPath: string;
  fs_id: string;
  fs_version_id: string;
  mp_id: string;
  mp_version_id: string;
}

interface IVolumeTouchedType {
  volumeType: boolean;
  type: boolean;
  name: boolean;
  giSize?: boolean;
  storageClass?: boolean;
  accessMode?: boolean;
  isReadOnly?: boolean;
  mountPath: boolean;
  fs_id: boolean;
  fs_version_id: boolean;
  mp_id: boolean;
  mp_version_id: boolean;
}

interface IProps {
  index: number;
  removeFunc: VoidFunction;
  formik: FormikProps<IKubeflowNotebookCreateFormValues>;
}

const KubeflowExistingVolumeForm = ({ index, removeFunc, formik }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const [expanded, setExpanded] = useState(true);

  const [filterList, setFilterList] = useState<Array<Record<any, any>>>([]);

  const {
    notebookVolumeTableList: {
      data: { items: volumeList },
    },
  } = useSelector((state: RootState) => state.experiment);
  const startedVolumeList = volumeList.filter(
    (item) => item.status === "started"
  );

  const {
    featureSetDatas: { data: { items: fsList = [] } = {} },
  } = useSelector((state: RootState) => state.featureStore);

  const [fsVersionList, setFsVersionList] = useState<
    Array<{ id: string; name: string; external_id: string }>
  >([]);

  const {
    modelPackageDatas: { data: { items: mpList = [] } = {} },
  } = useSelector((state: RootState) => state.modelRegistry);

  const [mpVersionList, setMpVersionList] = useState<
    Array<{ id: string; name: string; external_id: string }>
  >([]);

  const getExpandedText = (volumeObj) => {
    const arr: string[] = [];

    if (volumeObj?.name) {
      arr.push(volumeObj?.name);
    }
    if (volumeObj?.type) {
      arr.push(volumeObj?.type);
    }

    return arr.join(",");
  };

  const { values, errors, touched, setFieldValue } = formik;

  const volumeErrors = errors.volumes as Array<IVolumeErrorsType>;
  const volumeTouched = touched.volumes as Array<IVolumeTouchedType>;

  useEffect(() => {
    // options exclude added volumes, reserve self
    const existedVolumes = values.volumes.filter(
      (i) => i.volumeType === "existing"
    );

    const filterArr = startedVolumeList.filter((i) => {
      if (
        existedVolumes.findIndex((v) => v.name === i.id) > -1 &&
        values.volumes[index].name !== i.id
      ) {
        return false;
      }
      return true;
    });

    setFilterList(filterArr);
  }, [values.volumes]);

  return (
    <Accordion
      key={index}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Typography>Existing Volume</Typography>
          {!expanded && (
            <Typography sx={{ color: "text.secondary" }}>
              {getExpandedText(values.volumes[index])}
            </Typography>
          )}
          <IconButton onClick={() => removeFunc()}>
            <SvgIconStyle
              src={DeleteIcon}
              sx={{
                width: 22,
                height: 22,
              }}
            />
          </IconButton>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl
          margin="dense"
          size="small"
          fullWidth
          variant="outlined"
          error={
            volumeTouched?.[index]?.type && Boolean(volumeErrors?.[index]?.type)
          }
        >
          <InputLabel>Type</InputLabel>
          <Field as={Select} name={`volumes.${index}.type`} label="Type">
            <MenuItem key="Kubernetes Volume" value="Kubernetes Volume">
              Kubernetes Volume
            </MenuItem>
          </Field>
          <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
            {(volumeTouched?.[index]?.type && volumeErrors?.[index]?.type) ||
              ""}
          </FormHelperText>
        </FormControl>
        <FormControl
          margin="dense"
          size="small"
          fullWidth
          variant="outlined"
          error={
            volumeTouched?.[index]?.name && Boolean(volumeErrors?.[index]?.name)
          }
        >
          <InputLabel>Name</InputLabel>
          <Field as={Select} name={`volumes.${index}.name`} label="Name">
            {filterList?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Field>
          <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
            {(volumeTouched?.[index]?.name && volumeErrors?.[index]?.name) ||
              ""}
          </FormHelperText>
        </FormControl>
        <FormControlLabel
          control={
            // checked to value
            <Field name={`volumes.${index}.isReadOnly`}>
              {({ field }) => (
                <Switch
                  {...field}
                  checked={field.value || false} // init undefined to false
                  onChange={(_, checked) => {
                    setFieldValue(field.name, checked);
                  }}
                  size="small"
                />
              )}
            </Field>
          }
          label="ReadOnly"
          sx={{ pl: 1, pb: 1.5 }}
        />
        <Field
          as={TextField}
          name={`volumes.${index}.mountPath`}
          label="Mount Path"
          size="small"
          fullWidth
          margin="dense"
          helperText={
            (volumeTouched?.[index]?.mountPath &&
              volumeErrors?.[index]?.mountPath) ||
            " "
          }
          error={
            volumeTouched?.[index]?.mountPath &&
            Boolean(volumeErrors?.[index]?.mountPath)
          }
          FormHelperTextProps={{ sx: { minHeight: "18px", marginTop: "2px" } }}
        />

        {/* Feature Set */}
        <FormControlLabel
          control={
            // checked to value
            <Field name={`volumes.${index}.isSyncFs`}>
              {({ field }) => (
                <Switch
                  {...field}
                  checked={field.value || false} // init undefined to false
                  onChange={(_, checked) => {
                    setFieldValue(field.name, checked);
                    if (!checked) {
                      setFieldValue(`volumes.${index}.fs_id`, "");
                      setFieldValue(`volumes.${index}.fs_version_id`, "");
                      setFieldValue(
                        `volumes.${index}.fs_version_external_id`,
                        ""
                      );
                    }
                  }}
                  size="small"
                />
              )}
            </Field>
          }
          label="Sync Feature Set"
          sx={{ pl: 1, pb: 1.5 }}
        />

        {values.volumes[index].isSyncFs && (
          <Grid container columnSpacing={2}>
            <Grid item xs={12} lg={6}>
              <FormControl
                margin="dense"
                size="small"
                fullWidth
                variant="outlined"
                error={
                  volumeTouched?.[index]?.fs_id &&
                  Boolean(volumeErrors?.[index]?.fs_id)
                }
              >
                <InputLabel>Feature Set</InputLabel>
                <Field
                  as={Select}
                  name={`volumes.${index}.fs_id`}
                  label="Feature Set"
                  onChange={(event) => {
                    const curFsId = event.target.value;
                    setFieldValue(`volumes.${index}.fs_id`, curFsId);

                    setFieldValue(`volumes.${index}.fs_version_id`, "");
                    setFieldValue(
                      `volumes.${index}.fs_version_external_id`,
                      ""
                    );

                    dispatch(
                      getFeatureSetVersionList({
                        params: {
                          featureset_id: curFsId,
                          page_num: 1,
                          page_size: 9999,
                        },
                        isList: true,
                        alertCallback: enqueueSnackbar,
                        pathname,
                      })
                    )
                      .unwrap()
                      .then((r) => {
                        setFsVersionList(r?.items || []);
                      });
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 180,
                      },
                    },
                  }}
                >
                  {fsList.map((item) => (
                    <MenuItem key={item.id} value={item.id} title={item.name}>
                      <Typography noWrap textOverflow="ellipsis">
                        {item.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {(volumeTouched?.[index]?.fs_id &&
                    volumeErrors?.[index]?.fs_id) ||
                    ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl
                margin="dense"
                size="small"
                fullWidth
                variant="outlined"
                error={
                  volumeTouched?.[index]?.fs_version_id &&
                  Boolean(volumeErrors?.[index]?.fs_version_id)
                }
              >
                <InputLabel>Feature Set Version</InputLabel>
                <Field
                  as={Select}
                  name={`volumes.${index}.fs_version_id`}
                  disabled={!values.volumes[index].fs_id}
                  label="Feature Set Version"
                  onChange={(event) => {
                    const curFsVersionId = event.target.value;

                    setFieldValue(
                      `volumes.${index}.fs_version_id`,
                      curFsVersionId
                    );
                    setFieldValue(
                      `volumes.${index}.fs_version_external_id`,
                      fsVersionList.find((i) => i.id === curFsVersionId)
                        ?.external_id || ""
                    );
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 180,
                      },
                    },
                  }}
                >
                  {fsVersionList.map((item) => (
                    <MenuItem key={item.id} value={item.id} title={item.name}>
                      <Typography noWrap textOverflow="ellipsis">
                        {item.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {(volumeTouched?.[index]?.fs_version_id &&
                    volumeErrors?.[index]?.fs_version_id) ||
                    ""}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}

        <Box component="div" sx={{ my: 1.5 }}></Box>

        {/* Model Package */}
        <FormControlLabel
          control={
            // checked to value
            <Field name={`volumes.${index}.isSyncMp`}>
              {({ field }) => (
                <Switch
                  {...field}
                  checked={field.value || false} // init undefined to false
                  onChange={(_, checked) => {
                    setFieldValue(field.name, checked);
                    if (!checked) {
                      setFieldValue(`volumes.${index}.mp_id`, "");
                      setFieldValue(`volumes.${index}.mp_version_id`, "");
                      setFieldValue(
                        `volumes.${index}.mp_version_external_id`,
                        ""
                      );
                    }
                  }}
                  size="small"
                />
              )}
            </Field>
          }
          label="Sync Model Package"
          sx={{ pl: 1, pb: 1.5 }}
        />

        {values.volumes[index].isSyncMp && (
          <Grid container columnSpacing={2}>
            <Grid item xs={12} lg={6}>
              <FormControl
                margin="dense"
                size="small"
                fullWidth
                variant="outlined"
                error={
                  volumeTouched?.[index]?.mp_id &&
                  Boolean(volumeErrors?.[index]?.mp_id)
                }
              >
                <InputLabel>Model Package</InputLabel>
                <Field
                  as={Select}
                  name={`volumes.${index}.mp_id`}
                  label="Model Package"
                  onChange={(event) => {
                    const curMpId = event.target.value;
                    setFieldValue(`volumes.${index}.mp_id`, curMpId);

                    setFieldValue(`volumes.${index}.mp_version_id`, "");
                    setFieldValue(
                      `volumes.${index}.mp_version_external_id`,
                      ""
                    );

                    dispatch(
                      getModelPackageVersionList({
                        params: {
                          modelpackage_id: curMpId,
                          page_num: 1,
                          page_size: 9999,
                        },
                        isList: true,
                        alertCallback: enqueueSnackbar,
                        pathname,
                      })
                    )
                      .unwrap()
                      .then((r) => {
                        setMpVersionList(r?.items || []);
                      });
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 180,
                      },
                    },
                  }}
                >
                  {mpList.map((item) => (
                    <MenuItem key={item.id} value={item.id} title={item.name}>
                      <Typography noWrap textOverflow="ellipsis">
                        {item.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {(volumeTouched?.[index]?.mp_id &&
                    volumeErrors?.[index]?.mp_id) ||
                    ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl
                margin="dense"
                size="small"
                fullWidth
                variant="outlined"
                error={
                  volumeTouched?.[index]?.mp_version_id &&
                  Boolean(volumeErrors?.[index]?.mp_version_id)
                }
              >
                <InputLabel>Model Package Version</InputLabel>
                <Field
                  as={Select}
                  name={`volumes.${index}.mp_version_id`}
                  disabled={!values.volumes[index].mp_id}
                  label="Model Package Version"
                  onChange={(event) => {
                    const curMpVersionId = event.target.value;

                    setFieldValue(
                      `volumes.${index}.mp_version_id`,
                      curMpVersionId
                    );
                    setFieldValue(
                      `volumes.${index}.mp_version_external_id`,
                      mpVersionList.find((i) => i.id === curMpVersionId)
                        ?.external_id || ""
                    );
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 180,
                      },
                    },
                  }}
                >
                  {mpVersionList.map((item) => (
                    <MenuItem key={item.id} value={item.id} title={item.name}>
                      <Typography noWrap textOverflow="ellipsis">
                        {item.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {(volumeTouched?.[index]?.mp_version_id &&
                    volumeErrors?.[index]?.mp_version_id) ||
                    ""}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default KubeflowExistingVolumeForm;

import { useState } from "react";
import { Icon } from "@iconify/react";
import { useFormik, Form, FormikProvider } from "formik";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { Box, Stack, Divider, Collapse } from "@mui/material";
// @types
import { RootState, useDispatch, useSelector } from "@/redux/store";
import { ClarifyCheckStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-clarify-check";
// utils
import {
  updateEntityName,
  SchemaNodeEntity,
  updateClarifyCheckInput,
} from "../../../graph/slices/slice";
import { boolOptions, clarifyCheckTypes } from "../helper-functions";
// types
import { ClarifyCheckStepInput } from "@/@types/project/mlPipeline/SageMaker/pipeline-clarify-check";
// components
import { CollapseButtonStyle } from "../../SettingSidebar";
import {
  PipelineSelect,
  StyledTextField,
  PipelineTextfield,
} from "../../components/PipelineComponents";
import BiasConfig from "./BiasConfig";
import ShapConfig from "./ShapConfig";
import DataConfig from "./DataConfig";
import ModelConfig from "./ModelConfig";
import CheckJobConfig from "./CheckJobConfig";
import PredictedLabelConfig from "./PredictedLabelConfig";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";
import PipelineParameterWithSelector from "../../components/PipelineParameterWithSelector";

// ----------------------------------------------------------------------

type Props = {
  entity: SchemaNodeEntity;
};

export default function ClarifyCheckStepEntry({ entity }: Props) {
  const dispatch = useDispatch();
  const [showStepConfig, setShowStepConfig] = useState(true);

  const currentStep = useSelector((state: RootState) => {
    let step: ClarifyCheckStep = state.schema.schema.pipelineSteps.find(
      (step) => {
        return step.id === entity.id;
      }
    ) as ClarifyCheckStep;
    return step;
  });

  // console.log(currentStep);

  const formik = useFormik<ClarifyCheckStepInput>({
    initialValues: {
      checkType: currentStep?.checkStepInput.checkType || "",
      methods: currentStep?.checkStepInput.methods || "all",
      skipCheck: currentStep?.checkStepInput.skipCheck || "False",
      skipCheckUseParam: currentStep?.checkStepInput.skipCheckUseParam || false,
      registerNewBaseline:
        currentStep?.checkStepInput.registerNewBaseline || "False",
      registerNewBaselineUseParam:
        currentStep?.checkStepInput.registerNewBaselineUseParam || false,
      suppliedBaselineConstraints:
        currentStep?.checkStepInput.suppliedBaselineConstraints || "",
      suppliedBaselineConstraintsUseParam:
        currentStep?.checkStepInput.suppliedBaselineConstraintsUseParam ||
        false,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      try {
        dispatch(updateClarifyCheckInput(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { values, handleSubmit, getFieldProps } = formik;

  return (
    <>
      {entity && currentStep && (
        <Box>
          <CollapseButtonStyle
            fullWidth
            color="inherit"
            onClick={() => setShowStepConfig((prev) => !prev)}
            endIcon={
              <Icon
                icon={
                  showStepConfig ? arrowIosDownwardFill : arrowIosForwardFill
                }
                width={16}
                height={16}
              />
            }
          >
            Configuration
          </CollapseButtonStyle>

          <Collapse in={showStepConfig}>
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onChange={handleSubmit}>
                <Stack spacing={2} sx={{ mx: 2, mt: 1, mb: 2 }}>
                  <StyledTextField
                    label="Step name"
                    defaultValue={entity.name}
                    onChange={(event: any) => {
                      const newValue = event.target.value;
                      dispatch(updateEntityName({ name: newValue }));
                    }}
                  />

                  <PipelineSelect
                    label={"Check type"}
                    field={"checkType"}
                    placeholder={"Select check type"}
                    options={clarifyCheckTypes}
                    getFieldProps={getFieldProps}
                  />

                  <PipelineTextfield
                    label={"Methods"}
                    field={"methods"}
                    placeholder={"Potential metrics"}
                    formik={formik}
                  />

                  <PipelineParameterWithSelector
                    label={"Skip check"}
                    field={"skipCheck"}
                    placeholder={"True or False"}
                    fieldUseParam={"skipCheckUseParam"}
                    options={boolOptions}
                    formik={formik}
                  />

                  <PipelineParameterWithSelector
                    label={"Register new baseline"}
                    field={"registerNewBaseline"}
                    placeholder={"True or False"}
                    fieldUseParam={"registerNewBaselineUseParam"}
                    options={boolOptions}
                    formik={formik}
                  />

                  <PipelineParameterEntry
                    label={"Supplied baseline constraints"}
                    field={"suppliedBaselineConstraints"}
                    // placeholder={"Supplied basel..."}
                    fieldUseParam={"suppliedBaselineConstraintsUseParam"}
                    formik={formik}
                  />
                </Stack>
              </Form>
            </FormikProvider>
          </Collapse>

          <Divider />

          <CheckJobConfig currentStep={currentStep} />

          <Divider />

          <ModelConfig currentStep={currentStep} />

          <Divider />

          <DataConfig currentStep={currentStep} />

          {values.checkType === "MODEL_BIAS" && (
            <>
              <Divider />

              <BiasConfig currentStep={currentStep} />

              <Divider />

              <PredictedLabelConfig currentStep={currentStep} />
            </>
          )}

          {values.checkType === "MODEL_EXPLAINABILITY" && (
            <>
              <Divider />

              <ShapConfig currentStep={currentStep} />
            </>
          )}

          <Divider sx={{ mb: 1 }} />
        </Box>
      )}
    </>
  );
}

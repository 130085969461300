import { Icon } from "@iconify/react";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// @types
import { useDispatch, RootState, useSelector } from "@/redux/store";
// material
import { Stack, Collapse } from "@mui/material";
//
import Scrollbar from "@/components/project/Scrollbar";
import { CollapseButtonStyle } from "./SettingSidebar";
import {
  setPipelineArn,
  setPipelineRoleArn,
  setCurrentPipelineName,
  setPipelineDescription,
  setCurrentKubeflowPipeline,
} from "@/redux/project/automation/pipeline";
import {
  MlPipelineTextfield,
  StyledTextField,
} from "./components/PipelineComponents";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { MenuItem } from "@mui/material";

type PipelineConfigurationsProps = {
  name?: string;
  isEdit: boolean;
  isCollapse: boolean;
  onCollapse: VoidFunction;
};

export default function PipelineConfigurations({
  isEdit,
  isCollapse,
  onCollapse,
}: PipelineConfigurationsProps) {
  const dispatch = useDispatch();
  const { pipelineType } = useSelector((state: RootState) => state.schema);
  const isKubeflow = pipelineType === "Kubeflow";

  const { currentPipeline, currentKubeflowPipeline } = useSelector(
    (state: RootState) => state.pipeline
  );
  const commonPipelineData = isKubeflow
    ? currentKubeflowPipeline
    : currentPipeline;

  const {
    pipelineVolumeTableList: { data: volumeList },
  } = useSelector((state: RootState) => state.automation);
  const startedVolumeList = volumeList?.items?.filter(
    (item) => item.status === "started"
  );

  const initialValues = isKubeflow
    ? {
        PipelineName: "",
        pipelineVolume: "",
        description: "",
      }
    : {
        PipelineName: currentPipeline?.PipelineName || "",
        RoleArn: currentPipeline?.RoleArn || "",
        scenario: currentPipeline?.scenario || "",
      };

  const ParamSchema = isKubeflow
    ? Yup.object().shape({
        PipelineName: Yup.string().required("Pipeline Name is required"),
        PipelineVolume: Yup.string().required("Pipeline Volume is required"),
      })
    : Yup.object().shape({
        PipelineName: Yup.string().required("Pipeline Name is required"),
        RoleArn: Yup.string().required("Role Arn is required"),
      });

  const formik = useFormik({
    initialValues,
    validationSchema: ParamSchema,
    onSubmit: () => {},
  });

  const { setValues } = formik;

  useEffect(() => {
    if (isKubeflow) {
      setValues({
        PipelineName: currentKubeflowPipeline?.PipelineName || "",
        pipelineVolume: currentKubeflowPipeline?.pipelineVolume || "",
        description: currentKubeflowPipeline?.description || "",
      });
    }
  }, [currentKubeflowPipeline]);

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={onCollapse}
        endIcon={
          <Icon
            icon={isCollapse ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
      >
        Pipeline Config
      </CollapseButtonStyle>

      <Scrollbar>
        <Collapse in={isCollapse}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off">
              <Stack spacing={2} sx={{ mx: 2, mb: 2, mt: 1 }}>
                <MlPipelineTextfield
                  label={"Pipeline name"}
                  // disabled={isEdit}
                  disabled
                  field={"PipelineName"}
                  formik={formik}
                  submitThisValuefunc={(value) => {
                    if (isKubeflow) {
                      dispatch(
                        setCurrentKubeflowPipeline({ PipelineName: value })
                      );
                    } else {
                      dispatch(setCurrentPipelineName(value));
                    }
                  }}
                />

                <MlPipelineTextfield
                  select
                  disabled
                  label={"Pipeline Volume"}
                  field={"pipelineVolume"}
                  formik={formik}
                  submitThisValuefunc={(value) =>
                    dispatch(
                      setCurrentKubeflowPipeline({ pipelineVolume: value })
                    )
                  }
                >
                  {startedVolumeList?.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                  <MenuItem key="volume2" value="volume2">
                    volume2
                  </MenuItem>
                  <MenuItem key="volume3" value="volume3">
                    volume3
                  </MenuItem>
                </MlPipelineTextfield>

                {!isKubeflow && (
                  <MlPipelineTextfield
                    label={"Role Arn"}
                    field={"RoleArn"}
                    formik={formik}
                    submitThisValuefunc={(value) =>
                      dispatch(setPipelineRoleArn(value))
                    }
                  />
                )}

                <MlPipelineTextfield
                  multiline
                  rows={2}
                  disabled
                  label="Description (Optional)"
                  field="description"
                  formik={formik}
                  value={commonPipelineData.description}
                  submitThisValuefunc={(value) => {
                    if (isKubeflow) {
                      dispatch(
                        setCurrentKubeflowPipeline({ description: value })
                      );
                    } else {
                      dispatch(setPipelineDescription(value));
                    }
                  }}
                />

                {isEdit && !isKubeflow && (
                  <StyledTextField
                    // multiline
                    // maxRows={1}
                    label="Pipeline Arn"
                    disabled={isEdit}
                    value={currentPipeline.PipelineArn}
                    inputProps={{
                      style: { fontSize: "14px", wordBreak: "break-all" },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "15px",
                        // transform: values !== '' ? 'translate(15px, -9px) scale(0.75)' : ''
                      },
                      shrink: currentPipeline.PipelineArn !== "",
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const newValue = event.target.value;
                      dispatch(setPipelineArn(newValue));
                    }}
                  />
                )}
              </Stack>
            </Form>
          </FormikProvider>
        </Collapse>
      </Scrollbar>
    </>
  );
}

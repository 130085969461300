import {
  ClarifyCheckStep,
  ClarifyCheckArguments,
  ClarifyCheckStepInput,
  ClarifyCheckJobConfigStruct,
  ClarifyCheckBiasConfigStruct,
  ClarifyCheckShapConfigStruct,
  ClarifyCheckDataConfigStruct,
  ClarifyCheckModelConfigStruct,
  ClarifyCheckPredLabelConfigStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-clarify-check";
import {
  parseUri,
  checkUseParam,
  parseS3BucketAndPrefix,
} from "./helper-functions";
import axios from "@/utils/project/axiosForPython";

// ----------------------------------------------------------------------

export default async function parseClarifyCheckStep(
  index: number,
  pipelineStep: any,
  stepPairs: [string, string, string][]
) {
  const args = pipelineStep.Arguments as ClarifyCheckArguments;

  const analysisConfig = await parseAnalysisConfig(args.ProcessingInputs);

  const step = {
    id: String(index),
    type: "ClarifyCheck",
    name: pipelineStep.Name,
    checkStepInput: parseCheckInputs(pipelineStep, analysisConfig),
    checkJobConfig: parseProcessorProps(
      args.ProcessingInputs,
      args.ProcessingResources,
      args.AppSpecification
    ),
    modelConfig: parseModelConfigProps(
      analysisConfig,
      pipelineStep.ModelName,
      pipelineStep.ModelPackageGroupName,
      pipelineStep.Name,
      stepPairs
    ),
    dataConfig: parseDataConfigProps(
      analysisConfig,
      args.ProcessingInputs,
      args.ProcessingOutputConfig,
      pipelineStep.Name,
      stepPairs
    ),
    predLabelConfig: parsePredLabelConfigProps(analysisConfig),
    biasConfig: parseBiasConfigProps(pipelineStep.CheckType, analysisConfig),
    shapConfig: parseShapConfigProps(pipelineStep.CheckType, analysisConfig),
    nodeX: 0,
    nodeY: 0,
    tags: [],
    properties: [],
    stepType: "ClarifyCheck",
  } as ClarifyCheckStep;

  return step;
}

const parseAnalysisConfig = async (inputs: any) => {
  const configFilePath =
    inputs !== null &&
    inputs.length > 0 &&
    inputs[0].InputName === "analysis_config"
      ? inputs[0].S3Input.S3Uri
      : "";

  if (configFilePath === "") return undefined;

  const [bucket, prefix] = parseS3BucketAndPrefix(configFilePath);

  const response = await axios.post("/omniml/helper/s3/read/json", {
    s3_bucket: bucket,
    file_path: prefix,
  });

  const analysisConfig =
    response.status && response.data.success
      ? JSON.parse(response.data.message)
      : undefined;

  return analysisConfig;
};

const parseCheckInputs = (pipelineStep: any, analysisConfig: any) => {
  const checkInputs: ClarifyCheckStepInput = {
    checkType: pipelineStep.CheckType,
    methods: parseCheckMethods(analysisConfig),
    skipCheck: parseUri(pipelineStep.SkipCheck),
    skipCheckUseParam: checkUseParam(pipelineStep.SkipCheck),
    registerNewBaseline: parseUri(pipelineStep.RegisterNewBaseline),
    registerNewBaselineUseParam: checkUseParam(
      pipelineStep.RegisterNewBaseline
    ),
    suppliedBaselineConstraints: parseUri(
      pipelineStep.SuppliedBaselineConstraints
    ),
    suppliedBaselineConstraintsUseParam: checkUseParam(
      pipelineStep.SuppliedBaselineConstraints
    ),
  };
  return checkInputs;
};

const parseCheckMethods = (analysisConfig: any) => {
  if (!analysisConfig) return "all";
  const checkMethods = analysisConfig.methods;
  if (Object.keys(checkMethods)[0] === "post_training_bias") {
    return checkMethods["post_training_bias"]["methods"].join(",");
  } else if (Object.keys(checkMethods)[0] === "shap") {
    return "shap";
  } else {
    return "all";
  }
};

const parseProcessorProps = (inputs: any, resources: any, appSpec: any) => {
  const configFilePath =
    inputs && inputs.length > 0 && inputs[0].InputName === "analysis_config"
      ? inputs[0].S3Input.S3Uri
      : "";

  const processorProperties: ClarifyCheckJobConfigStruct = {
    processorType: parseProcessor(appSpec.ImageUri),
    processorTypeUseParam: checkUseParam(appSpec.ImageUri),
    configFilePath: configFilePath,
    configFilePathUseParam: checkUseParam(configFilePath),
    // frameworkVersion: parseFrameworkVersion(appSpec.ImageUri),
    frameworkVersion: "",
    instanceType: parseUri(resources.ClusterConfig.InstanceType),
    instanceTypeUseParam: checkUseParam(resources.ClusterConfig.InstanceType),
    instanceCount: parseUri(resources.ClusterConfig.InstanceCount),
    instanceCountUseParam: checkUseParam(resources.ClusterConfig.InstanceCount),
    volumeSizeInGb: parseUri(resources.ClusterConfig.VolumeSizeInGB),
    volumeSizeInGbUseParam: checkUseParam(
      resources.ClusterConfig.VolumeSizeInGB
    ),
  };
  return processorProperties;
};

const parseModelConfigProps = (
  analysisConfig: any,
  modelName: any,
  modelPackGName: any,
  stepName: string,
  stepPairs: [string, string, string][]
) => {
  const predictor = analysisConfig ? analysisConfig.predictor : undefined;
  const modelConfigProperties: ClarifyCheckModelConfigStruct = {
    modelDataUrl: parseUri(modelName, stepName, stepPairs, "ModelDataUrl"),
    modelDataUrlUseParam: checkUseParam(modelName),
    instanceType: predictor ? parseUri(predictor.instance_type) : "",
    instanceTypeUseParam: predictor
      ? checkUseParam(predictor.instance_type)
      : false,
    instanceCount: predictor ? parseUri(predictor.initial_instance_count) : "",
    instanceCountUseParam: predictor
      ? checkUseParam(predictor.initial_instance_count)
      : false,
    modelPackageGroupName: modelPackGName ? parseUri(modelPackGName) : "",
    modelPackageGroupNameUseParam: modelPackGName
      ? checkUseParam(modelPackGName)
      : false,
  };
  return modelConfigProperties;
};

const parseProcessor = (imageName: string) => {
  const uri = parseUri(imageName);
  return uri;
  // const items = uri.split("/");
  // if (items.length > 0) return items[1];
  // else return items[0];
};

const parseDataConfigProps = (
  analysisConfig: any,
  inputs: any,
  outputConfig: any,
  stepName: string,
  stepPairs: [string, string, string][]
) => {
  const dataset =
    inputs && inputs.length > 1 && inputs[1].InputName === "dataset"
      ? inputs[1]
      : undefined;

  const output =
    outputConfig && outputConfig.Outputs && outputConfig.Outputs.length > 0
      ? outputConfig.Outputs[0]
      : undefined;

  const dataConfigProperties: ClarifyCheckDataConfigStruct = {
    s3InputDataUrl: dataset
      ? parseUri(dataset.S3Input.S3Uri, stepName, stepPairs, dataset.InputName)
      : "",
    s3InputDataUrlUseParam: dataset
      ? checkUseParam(dataset.S3Input.S3Uri)
      : false,
    s3OutputUrl: output ? parseUri(output.S3Output.S3Uri) : "",
    s3OutputUrlUseParam: output ? checkUseParam(output.S3Output.S3Uri) : false,
    label: analysisConfig ? analysisConfig.label : "",
    labelUseParam: false,
    contentType: "text/csv",
  };
  return dataConfigProperties;
};

const parsePredLabelConfigProps = (analysisConfig: any) => {
  const predLabelConfigProperties: ClarifyCheckPredLabelConfigStruct = {
    label: "",
    probability: "",
    probThreshold: analysisConfig ? analysisConfig.probability_threshold : "",
    labelHeaders: "",
  };
  return predLabelConfigProperties;
};

const parseBiasConfigProps = (checkType: string, analysisConfig: any) => {
  const initialState = {
    labelValuesOrThreshold: "",
    facetName: "",
    facetValuesOrThreshold: "",
    groupName: "",
  } as ClarifyCheckBiasConfigStruct;

  if (checkType === "MODEL_BIAS") {
    try {
      const labelValuesOrThreshold =
        analysisConfig && analysisConfig.label_values_or_threshold
          ? analysisConfig.label_values_or_threshold
          : undefined;

      const facet =
        analysisConfig && analysisConfig.facet
          ? analysisConfig.facet
          : undefined;

      return {
        labelValuesOrThreshold: labelValuesOrThreshold
          ? labelValuesOrThreshold.join(",")
          : "",
        facetName: facet ? facet[0].name_or_index : "",
        facetValuesOrThreshold: facet
          ? facet[0].value_or_threshold.join(",")
          : "",
        groupName: "",
      } as ClarifyCheckBiasConfigStruct;
    } catch (error) {
      return initialState;
    }
  } else {
    return initialState;
  }
};

const parseShapConfigProps = (checkType: string, analysisConfig: any) => {
  const initialState = {
    baseline: "",
    num_samples: "",
    agg_method: "mean_abs",
    use_logit: "false",
    save_local_shap_values: "true",
    seed: "",
    num_clusters: "",
    text_config: "",
    image_config: "",
  } as ClarifyCheckShapConfigStruct;

  if (checkType === "MODEL_EXPLAINABILITY") {
    try {
      const shapConfig = analysisConfig.methods.shap;
      return {
        baseline: shapConfig.baseline ? shapConfig.baseline : "",
        num_samples: shapConfig.num_samples ? shapConfig.num_samples : "",
        agg_method: shapConfig.agg_method ? shapConfig.agg_method : "mean_abs",
        use_logit: shapConfig.use_logit ? shapConfig.use_logit : "false",
        save_local_shap_values: shapConfig.save_local_shap_values
          ? shapConfig.save_local_shap_values
          : "true",
        seed: shapConfig.seed ? shapConfig.seed : "",
        num_clusters: shapConfig.num_clusters ? shapConfig.num_clusters : "",
        text_config: shapConfig.text_config ? shapConfig.text_config : "",
        image_config: shapConfig.image_config ? shapConfig.image_config : "",
      } as ClarifyCheckShapConfigStruct;
    } catch (error) {
      return initialState;
    }
  } else {
    return initialState;
  }
};

/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Field, useFormik, FormikProvider } from "formik";
import { TextField, Button, Stack, Box, Autocomplete } from "@mui/material";
import * as Yup from "yup";
import MlCard from "@/components/project/mlComponents/MlCard";
import { useLocation, useNavigate } from "react-router";
import Scrollbar from "@/components/project/Scrollbar";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import {
  addProjectTag,
  addTags,
  createProject,
  delProjectTag,
  getProjectRolesTableDataInProject,
  getProjectTags,
  getTags,
  handleAddProjectRole,
  handleUpdateProjectRole,
  updateProject,
} from "@/redux/project/administration/thunks";
import { PATH_DASHBOARD } from "@/routes/paths";
import { LoadingButton } from "@mui/lab";
import { useEffect, useRef } from "react";
import { isEmpty } from "lodash";
import { getProjectRoleTableData } from "@/redux/project/template/thunks";
import ResourceQuota from "./ResourceQuota";

interface IProps {
  curOrgIdOfProject: string;
  curProjectId: string;
}

interface IFormValues {
  projectName: string;
  description: string;
  tags: string[];
  leaderGroup: string;
  resource: {
    cpu: string;
    memory: string;
    storage: string;
    ephemeral_storage: string;
    gpu_type: string;
    gpu_number: string;
  };
}

/* interface IFormValuesWithS3 extends IFormValues {
  s3BucketName: string;
}; */

interface IS3Touched {
  projectName: boolean;
  leaderGroup: boolean;
  description: boolean;
  // s3BucketName?: boolean,
}

interface IS3Errors {
  projectName: string;
  leaderGroup: string;
  description: string;
  // s3BucketName?: string,
}

const ProjectCreateOrEditForm = ({
  curOrgIdOfProject,
  curProjectId,
}: IProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  // record the initial rel project tags
  const allExistTags = useRef<any[]>([]);

  const isFromPgManageSiderbar = pathname.includes("/projectGovernance/manage"); // only can edit don't need organization_id

  const isEdit = pathname.includes("projectEdit");

  const {
    tags: { items: tagList = [] },
  } = useSelector((state: RootState) => state.administration);

  // const { config: { govern } } = useSelector((state: RootState) => state.common);

  const {
    projectDetailData: { data },
  } = useSelector((state: RootState) => state.administration);

  // const { users: { items: userList = [] } } = useSelector((state: RootState) => state.administration);

  const initialValues: IFormValues = {
    projectName: `omniml-prj-${new Date().getTime()}`,
    leaderGroup: "",
    tags: [],
    description: "",
    // ...(isEdit ? {} : { s3BucketName: '' }),

    resource: {
      cpu: "",
      memory: "",
      storage: "",
      ephemeral_storage: "",
      gpu_type: "requests.nvidia.com/gpu",
      gpu_number: "",
    },
  };

  const validationSchema = Yup.object({
    projectName: Yup.string()
      .required("Project Name is required")
      .max(64, "Project Name cannot be longer than 64 characters"),
    description: Yup.string().max(
      400,
      "Description cannot be longer than 400 characters"
    ),
    leaderGroup: Yup.string().required("Leader Group is required"),
    /* leaderGroup: Yup.lazy((value, schemaContext) => {
      if (schemaContext.parent.projectType === '0') {
        return Yup.array()
          .of(Yup.object({ user_id: Yup.string().required('User is required') }))
          .min(1, 'At least one leader is required');
      } else {
        return Yup.string().required('Leader is required');
      }
    }), */
    /* ...(!isEdit ? { 
      s3BucketName: Yup.string()
      .required('S3 Bucket Name is required')
      .max(64, 'S3 Bucket Name cannot be longer than 64 characters')
     } : {}) */
    resource: Yup.object({
      cpu: Yup.string().required("Cpu is required"),
      memory: Yup.string().required("Memory is required"),
      storage: Yup.string().required("Storage is required"),
      ephemeral_storage: Yup.string().required("Ephemeral Storage is required"),
      gpu_type: Yup.string().required("GPU Type is required"),
      gpu_number: Yup.string().required("GPU Number is required"),
    }),
  });

  const handleCreateSubmit = async (values) => {
    const { id } = await dispatch(
      createProject({
        params: {
          project_type: "0", // sandbox
          name: values.projectName,
          organization_id: curOrgIdOfProject,
          description: values.description,
          resource_config: {},
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();

    // if tag exist, add rel_project_tag
    if (!isEmpty(values.tags)) {
      await Promise.all(
        values.tags.map((item) =>
          dispatch(
            addProjectTag({
              params: {
                project_id: id,
                tag_id: item,
                organization_id: curOrgIdOfProject,
              },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap()
        )
      );
    }

    // update resource_config, change the status to wait_start
    // update the resource_config
    const { cpu, memory, storage, ephemeral_storage, gpu_number } =
      values.resource;

    const config = {
      aws: {
        iam_roles: {
          job_role: `taichi-prj-${id}-job-role`,
          sagemaker_role: `taichi-prj-${id}-sagemaker-role`,
        },
      },
      namespace: {
        resource_quota: {
          requests: {
            storage: Number(storage) || 10,
            "nvidia.com/gpu": Number(gpu_number) || 1,
          },
          cpu: Number(cpu) || 10,
          memory: Number(memory) || 10,
          ephemeral_storage: Number(ephemeral_storage) || 10,
        },
      },
    };

    await dispatch(
      updateProject({
        id,
        params: {
          project_type: "0", // sandbox
          name: values.projectName,
          organization_id: curOrgIdOfProject,
          description: values.description,
          resource_config: config,
          status: "wait_start",
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();

    // create rel_project_role, project leader, bind ad_group to the project leader
    // get Project Leader role_id
    const { items } = await dispatch(
      getProjectRoleTableData({
        params: {
          name: "Project Leader",
          page_num: 1,
          page_size: 9999,
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();

    await dispatch(
      handleAddProjectRole({
        params: {
          role_id: items[0].id,
          project_id: id,
          ad_group: values.leaderGroup,
          organization_id: curOrgIdOfProject,
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();

    // the other project role must be added by project manager (project leader) in Manage Menu
  };

  const updatePrjectTags = async (tags) => {
    const deleteIds: string[] = [];
    const addIds: string[] = [];

    tags.forEach((item) => {
      if (allExistTags.current.findIndex((i) => i.tag_id === item) === -1) {
        addIds.push(item);
      }
    });
    allExistTags.current.forEach((item) => {
      if (!tags.includes(item.tag_id)) {
        deleteIds.push(item.tag_id);
      }
    });

    // delete
    await Promise.all(
      deleteIds.map((item) =>
        dispatch(
          delProjectTag({
            id: allExistTags.current.find((i) => i.tag_id === item).id,
            alertCallback: enqueueSnackbar,
            pathname,
          })
        ).unwrap()
      )
    );

    // add
    await Promise.all(
      addIds.map((item) =>
        dispatch(
          addProjectTag({
            params: {
              project_id: curProjectId!,
              tag_id: item,
              organization_id: curOrgIdOfProject,
            },
            alertCallback: enqueueSnackbar,
            pathname,
          })
        ).unwrap()
      )
    );
  };

  const handleUpdateSubmit = async (values) => {
    // update the rel_project_tag
    await updatePrjectTags(values.tags);

    const { cpu, memory, storage, ephemeral_storage, gpu_number } =
      values.resource;

    const config = {
      aws: {
        iam_roles: {
          job_role: `taichi-prj-${curProjectId}-job-role`,
          sagemaker_role: `taichi-prj-${curProjectId}-sagemaker-role`,
        },
      },
      namespace: {
        resource_quota: {
          requests: {
            storage: Number(storage) || 10,
            "nvidia.com/gpu": Number(gpu_number) || 1,
          },
          cpu: Number(cpu) || 10,
          memory: Number(memory) || 10,
          ephemeral_storage: Number(ephemeral_storage) || 10,
        },
      },
    };

    await dispatch(
      updateProject({
        id: curProjectId!,
        params: {
          project_type: "0", // sandbox
          name: values.projectName,
          organization_id: curOrgIdOfProject,
          description: values.description,
          resource_config: config,
          status: "wait_start",
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();

    // update project role
    const { items } = await dispatch(
      getProjectRolesTableDataInProject({
        params: {
          project_id: curProjectId,
          name: "Project Leader",
          page_num: 1,
          page_size: 9999,
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();
    await dispatch(
      handleUpdateProjectRole({
        id: items[0].id,
        params: {
          role_id: items[0].role_id,
          project_id: curProjectId,
          ad_group: values.leaderGroup,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  };

  const handleSubmitLinkTo = () => {
    if (isFromPgManageSiderbar) {
      navigate(PATH_DASHBOARD.projectGovernance.manage);
    } else {
      if (isEdit) {
        navigate(
          `${PATH_DASHBOARD.administration.organization}/${curOrgIdOfProject}/${curProjectId}`
        );
      } else {
        navigate(
          `${PATH_DASHBOARD.administration.organization}/${curOrgIdOfProject}`
        );
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async (values, actions) => {
      try {
        if (isEdit) {
          await handleUpdateSubmit(values);
          enqueueSnackbar("Edit Success", { variant: "success" });
        } else {
          await handleCreateSubmit(values);
          enqueueSnackbar("Create Success", { variant: "success" });
        }
        actions.setSubmitting(false);

        handleSubmitLinkTo();
      } catch (e) {
        console.log("error-in-submit", e);
        actions.setSubmitting(false);
      }
    },
  });

  const { values, errors, setFieldValue, isSubmitting, touched } = formik;

  const s3Touched = touched as IS3Touched;
  const s3Errors = errors as IS3Errors;

  useEffect(() => {
    if (isEdit && !isEmpty(data)) {
      setFieldValue("projectName", data.name);
      setFieldValue("description", data.description);

      const { namespace: { resource_quota = {} } = {} } =
        data.resource_config || {};
      const { requests = {}, cpu, memory } = resource_quota;

      const r_config = {
        cpu: cpu ? String(cpu) : "10",
        memory: memory ? String(memory) : "10",
        storage: requests.storage ? String(requests.storage) : "10",
        ephemeral_storage: resource_quota["ephemeral_storage"]
          ? String(resource_quota["ephemeral_storage"])
          : "10",
        gpu_type: "requests.nvidia.com/gpu",
        gpu_number: requests["nvidia.com/gpu"]
          ? String(requests["nvidia.com/gpu"])
          : "1",
      };

      setFieldValue("resource", r_config);
    }
  }, [data]);

  useEffect(() => {
    if (!isEdit) {
      const r_config = {
        cpu: "10",
        memory: "10",
        storage: "10",
        ephemeral_storage: "10",
        gpu_type: "requests.nvidia.com/gpu",
        gpu_number: "1",
      };

      setFieldValue("resource", r_config);
    }
  }, []);

  /*   useEffect(() => {
      dispatch(getUsers({
        params: {
          privileged: 0,
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      }));
    }, []); */

  useEffect(() => {
    // all tags
    dispatch(
      getTags({
        params: {
          is_active: 1,
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  }, []);

  useEffect(() => {
    if (isEdit) {
      dispatch(
        getProjectTags({
          params: {
            project_id: curProjectId,
            page_num: 1,
            page_size: 9999,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((res) => {
          setFieldValue(
            "tags",
            res.items.map((item) => item.tag_id)
          );

          allExistTags.current = res?.items;
        });

      // get the leader group
      dispatch(
        getProjectRolesTableDataInProject({
          params: {
            project_id: curProjectId,
            page_num: 1,
            page_size: 999,
          },
          pathname,
          hasDescription: true,
          alertCallback: enqueueSnackbar,
        })
      )
        .unwrap()
        .then((res) => {
          const targetItem = res.items.find(
            (item) => item.name === "Project Leader"
          );

          setFieldValue("leaderGroup", targetItem?.ad_group);
        });
    }
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form>
        <MlCard title="Basic Infomation">
          <Scrollbar>
            <Box sx={{ pb: 2, width: "800px" }}>
              <Field
                as={TextField}
                disabled={isEdit}
                name="projectName"
                label="Project Name"
                size="small"
                fullWidth
                margin="dense"
                helperText={
                  (s3Touched.projectName && s3Errors.projectName) || " "
                }
                error={s3Touched.projectName && Boolean(s3Errors.projectName)}
                FormHelperTextProps={{
                  sx: { minHeight: "18px", marginTop: "2px" },
                }}
              />
              {/* <Autocomplete
                size="small"
                multiple
                fullWidth
                options={userList}
                getOptionLabel={(user: { user_id: string }) => user.user_id}
                isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
                value={userList.filter(user => values.leaderGroup.includes(user.user_id))}
                onChange={(_, newValue) => {
                  setFieldValue('leaderGroup', newValue.map(item => item.user_id) || []);
                }}
                // onBlur={() => handleBlur(`lines.${index}.userIds`)}
                onBlur={handleBlur('leaderGroup')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="User"
                    variant="outlined"
                    fullWidth
                    margin='dense'
                    onBlur={handleBlur('leaderGroup')}
                    error={s3Touched.leaderGroup && Boolean(s3Errors.leaderGroup)}
                    helperText={(s3Touched.leaderGroup && s3Errors.leaderGroup) || ''}
                    FormHelperTextProps={{
                      style: {
                        minHeight: '18px', marginTop: '2px'
                      }
                    }}
                  />
                )}
              /> */}
              <Field
                as={TextField}
                name="leaderGroup"
                label="Leader Group"
                size="small"
                fullWidth
                margin="dense"
                helperText={
                  (s3Touched.leaderGroup && s3Errors.leaderGroup) ||
                  "Leader Group must be created in identity and access management system"
                }
                error={s3Touched.leaderGroup && Boolean(s3Errors.leaderGroup)}
                FormHelperTextProps={{
                  sx: { minHeight: "18px", marginTop: "2px" },
                }}
              />
              <Autocomplete
                size="small"
                multiple
                disableCloseOnSelect
                disablePortal
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                limitTags={8}
                getOptionLabel={(option) =>
                  typeof option === "object" ? option.name : option
                }
                isOptionEqualToValue={(option, value) => {
                  if (typeof option === "object" && typeof value === "object") {
                    return option.id === value.id;
                  } else if (
                    typeof option === "string" ||
                    typeof value === "string"
                  ) {
                    return option === value;
                  }
                  return false;
                }}
                options={tagList}
                value={values.tags.map(
                  (tagId) => tagList.find((tag) => tag.id === tagId) || tagId
                )}
                onChange={(_, newValues) => {
                  const filteredValues = newValues.filter(
                    (item) => typeof item === "string"
                  );

                  if (tagList.find((i) => i.name === filteredValues[0])) {
                    enqueueSnackbar(`Tag ${filteredValues[0]} existed`, {
                      variant: "warning",
                    });
                    return;
                  }

                  const updatedTagsPromises = newValues.map(async (item) => {
                    if (typeof item === "string") {
                      // new text ,add a new tag
                      const res = await dispatch(
                        addTags({
                          params: {
                            is_active: 1,
                            name: item,
                          },
                          alertCallback: enqueueSnackbar,
                          pathname,
                        })
                      ).unwrap();

                      enqueueSnackbar(`Add Tag ${item} Success`, {
                        variant: "success",
                      });

                      await dispatch(
                        getTags({
                          params: {
                            is_active: 1,
                            page_num: 1,
                            page_size: 9999,
                          },
                          alertCallback: enqueueSnackbar,
                          pathname,
                        })
                      );

                      return res.id;
                    }
                    return item.id;
                  });

                  Promise.all(updatedTagsPromises).then((r) => {
                    setFieldValue("tags", r);
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tags (Optional)"
                    helperText=" "
                    FormHelperTextProps={{
                      style: {
                        minHeight: "18px",
                        marginTop: "2px",
                      },
                    }}
                  />
                )}
                sx={{ mt: 1, mb: 0.5 }}
              />
              <Field
                as={TextField}
                multiline
                rows={4}
                name="description"
                label="Description (Optional)"
                size="small"
                fullWidth
                margin="dense"
                helperText={s3Touched.description && s3Errors.description}
                error={s3Touched.description && Boolean(s3Errors.description)}
                FormHelperTextProps={{
                  sx: { minHeight: "18px", marginTop: "2px" },
                }}
              />
            </Box>
          </Scrollbar>
        </MlCard>
        <MlCard
          title="Kubernetes Namespace Resource Quota"
          isCanFold
          defaultExpand={false}
        >
          <Box sx={{ pb: 2, width: "800px" }}>
            <Scrollbar>
              <ResourceQuota formik={formik} />
            </Scrollbar>
          </Box>
        </MlCard>

        <Stack direction="row" justifyContent="center" spacing={2}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            color="primary"
            sx={{ width: "200px", color: "background.paper" }}
          >
            Save
          </LoadingButton>
          <Button
            variant="outlined"
            color="inherit"
            sx={{ width: "200px", color: "text.secondary" }}
            onClick={() => {
              let cancelRedirecTo = `${PATH_DASHBOARD.administration.organization}/${curOrgIdOfProject}`;
              if (isFromPgManageSiderbar) {
                cancelRedirecTo = PATH_DASHBOARD.projectGovernance.manage;
              }

              navigate(cancelRedirecTo);
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default ProjectCreateOrEditForm;

/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Skeleton } from "@mui/material";
import TemplateCard from "./TemplateCard";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import EmptyTable from "@/components/project/EmptyTable";
import useCollapseDrawer from "@/hooks/project/useCollapseDrawer";
import { useEffect, useState } from "react";

interface IPipelineTemplateProps {
  handleRefresh: (a?: boolean) => void;
  onSetModel: (a?: string) => void;
  onShowDialog: (a?: boolean) => void;
  onShowDetail: (a?: boolean) => void;
  setTemplate: (a: Record<string, any>) => void;
}

const TemplateList = ({
  handleRefresh,
  onSetModel,
  onShowDialog,
  onShowDetail,
  setTemplate,
}: IPipelineTemplateProps) => {
  const { isCollapse } = useCollapseDrawer();
  const {
    pipelineTemplateTableList: {
      data: { items: templateList = [] },
      loading,
    },
  } = useSelector((state: RootState) => state.template);
  const [rowCount, setRowCount] = useState<number>();

  const handleResize = () => {
    const listWidth = isCollapse
      ? window.innerWidth - 72 - 24
      : window.innerWidth - 72 - 24 - 240;
    const count = Math.floor(listWidth / (240 + 20));
    setRowCount(count);
  };

  useEffect(() => {
    handleResize();
  }, [isCollapse]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {loading && (
        <Box
          sx={{
            display: "grid",
            p: 2,
            gap: 2.5,
            gridTemplateColumns: {
              xs: `repeat(${rowCount}, 1fr)`,
              sm: `repeat(${rowCount}, 1fr)`,
              md: `repeat(${rowCount}, 1fr)`,
              lg: `repeat(${rowCount}, 1fr)`,
              xl: `repeat(${rowCount}, 1fr)`,
            },
          }}
        >
          {templateList.map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width={240}
              height={320}
              sx={{ borderRadius: 2, mt: 1, mb: 1 }}
            />
          ))}
        </Box>
      )}

      {!loading && templateList.length > 0 ? (
        <Box
          sx={{
            display: "grid",
            p: 2,
            gap: 2.5,
            gridTemplateColumns: {
              xs: `repeat(${rowCount}, 1fr)`,
              sm: `repeat(${rowCount}, 1fr)`,
              md: `repeat(${rowCount}, 1fr)`,
              lg: `repeat(${rowCount}, 1fr)`,
              xl: `repeat(${rowCount}, 1fr)`,
            },
          }}
        >
          {templateList.map((template) => (
            <TemplateCard
              key={template.id}
              template={template}
              handleRefresh={handleRefresh}
              onSetModel={onSetModel}
              onShowDialog={onShowDialog}
              onShowDetail={onShowDetail}
              setTemplate={setTemplate}
            />
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            mt: 0,
            height: "100%",
          }}
        >
          <EmptyTable
            mainTitle="No Data"
            content="There is no data here, please create some items first"
            sx={{ p: 8 }}
          />
        </Box>
      )}
    </>
  );
};

export default TemplateList;

import Page from "@/components/project/Page";
import { Card } from "@mui/material";
import NotebookTable from "./components/notebookList/NotebookTable";

const Notebook = () => {
  return (
    <Page>
      <Card>
        <NotebookTable />
      </Card>
    </Page>
  );
};

export default Notebook;

import { MAvatarProps } from "../@material-extend/MAvatar";
import { IUserDetail } from "@/@types/project/model/common";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { legal_surname = "", legal_given_name = "" } = useSelector(
    (state: RootState) => state.common.userDetail as IUserDetail
  );

  const getName = () => {
    return `${legal_given_name ? legal_given_name?.[0]?.toUpperCase() : ""}${
      legal_surname ? legal_surname?.[0]?.toUpperCase() : ""
    }`;
  };

  return (
    <Avatar sx={{ bgcolor: (theme) => theme.palette.grey[5008] }}>
      {getName()}
    </Avatar>
  );
}

import { useState } from "react";
import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
import { useLocation } from "react-router";
// import { styled } from '@mui/material/styles';
// material
import { Box, Card, CardHeader, Menu, MenuItem, Button } from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { BaseOptionChart } from "@/components/project/charts";
import {
  delRelMlServiceMetrics,
  getMetricsTemplateList,
  getRelMlServiceMetricsTemplateList,
} from "@/redux/project/mlService/mlRuntime/thunks";
import { RootState, dispatch, useSelector } from "@/redux/store";
import { useSnackbar } from "notistack";
import moment from "moment";

// const RootStyle = styled(Card)(({ theme }) => ({
//   backgroundColor: 'transparent',
//   boxShadow: 'none',
//   borderRadius: 0,
// }));

export default function MetricPlot({
  title,
  metricData,
  seriesData,
  chartColors,
  xaxis,
}) {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorOpEl, setAnchorOpEl] = useState<null | HTMLElement>(null);
  const openOp = Boolean(anchorOpEl);
  const {
    mlRuntimeDetail: { data: serviceDetail },
    metricsTemplateList: { data: templateData },
  } = useSelector((state: RootState) => state.mlRuntime);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorOpEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorOpEl(null);
  };

  const handleDelete = async () => {
    try {
      // get template id,only one
      await dispatch(
        getMetricsTemplateList({
          params: { page_num: 1, page_size: 9999, name: title },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      // get rel_metrics_template id (only one) and then delete
      if (templateData?.items?.length !== 0) {
        dispatch(
          getRelMlServiceMetricsTemplateList({
            params: {
              page_num: 1,
              page_size: 9999,
              mlservice_id: serviceDetail?.id,
              metrics_template_id: templateData?.items[0].id,
            },
            alertCallback: enqueueSnackbar,
            pathname,
          })
        )
          .unwrap()
          .then((resp) => {
            if (resp?.items?.length !== 0) {
              dispatch(
                delRelMlServiceMetrics({
                  id: resp?.items[0].id,
                  alertCallback: enqueueSnackbar,
                  pathname,
                })
              );
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
    handleClose();
  };

  const options = merge(BaseOptionChart(), {
    colors: chartColors,
    stroke: {
      show: true,
      width: 2.5,
    },
    // fill: { type: ['gradient'] },
    xaxis: {
      type: xaxis?.length > 1 ? "datetime" : "category",
      categories: xaxis,
      tickPlacement: "on",
      labels: {
        hideOverlappingLabels: true,
        showDuplicates: false,
        datetimeUTC: false,
        formatter: (val: number) => moment(val).format("yyyy/MM/DD HH:mm:ss"),
      },
      axisTicks: {
        show: true,
      },
    },
    yaxis: {
      labels: {
        align: "right",
        minWidth: 30,
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: "top",
      top: 12,
      horizontalAlign: "center",
    },
    grid: {
      padding: {
        right: 120,
        left: 20,
      },
    },
  });

  return (
    <Card>
      <CardHeader
        title={title}
        sx={{ p: 1, pt: 2, ml: 1.5 }}
        action={
          <>
            <Button
              onClick={handleClick}
              variant="outlined"
              endIcon={
                <ExpandMoreOutlinedIcon
                  sx={{
                    transform: openOp ? "rotate(-180deg)" : "rotate(0deg)",
                    transition: "transform 0.5s",
                  }}
                />
              }
              color="secondary"
              sx={{ width: "120px", mr: 2 }}
            >
              Actions
            </Button>
          </>
        }
      />

      <Box sx={{ mt: 0, mx: 0 }} dir="ltr">
        <ReactApexChart
          type="area"
          series={seriesData}
          options={options}
          height={260}
        />
      </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorOpEl}
        open={openOp}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "120px",
          },
        }}
      >
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </Card>
  );
}

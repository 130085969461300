import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Box, Stack, Collapse, IconButton } from "@mui/material";
//
import EmptyTable from "@/components/project/EmptyTable";
import { CollapseButtonStyle } from "../../../../SettingSidebar";
import FeatureSetUploadInputItem from "./partComponents/FeatureSetUploadInputItem";
import { handleKubeflowStepArray } from "@/pages/project/automation/MlPipeline/graph/slices/slice";
import { FeatureSetUploadStep } from "@/@types/project/mlPipeline/Kubeflow/pipeline-featureset-upload";

// ----------------------------------------------------------------------

type Props = {
  currentStep: FeatureSetUploadStep | null;
  isEdit: boolean;
};

export default function FeatureSetUploadInputs({ currentStep, isEdit }: Props) {
  const dispatch = useDispatch();

  const [showFeatureSetUploadInput, setShowFeatureSetUploadInput] =
    useState(false);

  const featureSetUploadInputs = currentStep?.inputs || [];

  const handleAddFeatureSetUploadInput = () => {
    if (!showFeatureSetUploadInput) {
      setShowFeatureSetUploadInput(true);
    }

    dispatch(
      handleKubeflowStepArray({
        handleType: "create",
        data: {
          name: `FeatureSetUploadInput-${featureSetUploadInputs.length + 1}`,
          value: "",
          dataType: "String",
          description: "",
          useParam: false,
          paramType: 0,
        },
        key: "inputs",
      })
    );
  };

  return (
    <>
      <Box sx={{ mb: 0, position: "relative" }}>
        <CollapseButtonStyle
          fullWidth
          color="inherit"
          onClick={() => setShowFeatureSetUploadInput((prev) => !prev)}
          endIcon={
            <Icon
              icon={
                showFeatureSetUploadInput
                  ? arrowIosDownwardFill
                  : arrowIosForwardFill
              }
              width={16}
              height={16}
            />
          }
        >
          INPUTS{" "}
          {featureSetUploadInputs.length > 0 &&
            `(${featureSetUploadInputs.length})`}
        </CollapseButtonStyle>

        <IconButton
          sx={{ zIndex: 1999, position: "absolute", top: 7, right: 30 }}
          disabled={isEdit}
          size="small"
          onClick={handleAddFeatureSetUploadInput}
        >
          <Icon icon={plusFill} width={16} height={16} />
        </IconButton>
      </Box>

      <Collapse in={showFeatureSetUploadInput}>
        <Stack
          spacing={1.5}
          sx={{ mx: 2, mb: 1.5, mt: showFeatureSetUploadInput ? 0.5 : 0 }}
        >
          {featureSetUploadInputs.length > 0 &&
            featureSetUploadInputs.map((featureSetUploadInput, index) => (
              <FeatureSetUploadInputItem
                length={featureSetUploadInputs.length}
                isEdit={isEdit}
                key={index}
                id={index}
                data={featureSetUploadInput}
              />
            ))}
        </Stack>
      </Collapse>

      {showFeatureSetUploadInput && featureSetUploadInputs.length === 0 && (
        <EmptyTable
          emptyImage={false}
          mainTitle={"No featureSetUpload inputs"}
          content={'Create one by clicking "+" button'}
          color={"text.disabled"}
          sx={{ backgroundColor: "transparent", mx: 2, mt: 5, mb: 7 }}
        />
      )}
    </>
  );
}

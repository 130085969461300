// routes
import { PATH_DASHBOARD } from "@/routes/paths";
// components
import {
  CiDeliveryTruck,
  AiOutlineExperiment,
  MdManageAccounts,
  Notebook,
  NotebookVolume,
  MlPipeline,
  PipeLineVolume,
  ModleRRegistry,
  FeatureStore,
  MlRuntime,
  MlAppGallery,
  Organization,
  ProjectRole,
  Connection,
  Credential,
  ProjectMonitor,
  NotebookTemplate,
  PipelineTemplate,
  PlatformMonitor,
  Project,
  Manage,
} from "@/assets/project/icons/index";
import originalFirstMeunItem from "./originalIcon.svg";
import SvgIconStyle from "@/components/project/SvgIconStyle";

const getFirstMenuItemIcon = (src: string) => (
  <SvgIconStyle
    src={src}
    sx={{
      width: 30,
      height: 30,
      fontWeight: 500,
    }}
  />
);

const getSecondIcon = (src: string) => (
  <SvgIconStyle src={src} sx={{ width: "100%", height: "100%" }} />
);

const FIRSTICONS = {
  FirstMenuItem1: getFirstMenuItemIcon(AiOutlineExperiment),
  FirstMenuItem2: getFirstMenuItemIcon(CiDeliveryTruck),
  FirstMenuItem3: getFirstMenuItemIcon(MdManageAccounts),

  originalFirstMeunItem: getFirstMenuItemIcon(originalFirstMeunItem),
};

const SECONDICONS = {
  // First
  Notebook: getSecondIcon(Notebook),
  Notebook_Volume: getSecondIcon(NotebookVolume),
  MlPipeline: getSecondIcon(MlPipeline),
  PipeLineVolume: getSecondIcon(PipeLineVolume),
  Modle_Registry: getSecondIcon(ModleRRegistry),
  Feature_Store: getSecondIcon(FeatureStore),
  // second
  MlRuntime: getSecondIcon(MlRuntime),
  MlAppGallery: getSecondIcon(MlAppGallery),
  Manage: getSecondIcon(Manage),

  // third
  Organization: getSecondIcon(Organization),
  Project: getSecondIcon(Project),
  ProjectRole: getSecondIcon(ProjectRole),
  Notebook_Template: getSecondIcon(NotebookTemplate),
  Pipeline_Template: getSecondIcon(PipelineTemplate),
  Connection: getSecondIcon(Connection),
  Credential: getSecondIcon(Credential),
  ProjectMonitor: getSecondIcon(ProjectMonitor),
  PlatformMonitor: getSecondIcon(PlatformMonitor),
};

const sidebarConfig = [
  {
    firstMenuItem: "FirstMenuItem1",
    icon: FIRSTICONS.FirstMenuItem1,
    secondMenuData: [
      {
        subheader: "EXPERIMENT",
        items: [
          {
            title: "Notebook",
            path: PATH_DASHBOARD.experiment.notebook,
            icon: SECONDICONS.Notebook,
            auth: [20100, 20200],
          },
          {
            title: "Notebook Volume",
            path: PATH_DASHBOARD.experiment.notebookVolume,
            icon: SECONDICONS.Notebook_Volume,
            auth: [20100, 20200],
          },
        ],
      },
      {
        subheader: "AUTOMATION",
        items: [
          {
            title: "ML Pipeline",
            path: PATH_DASHBOARD.automation.mlPipeline,
            icon: SECONDICONS.MlPipeline,
            auth: [20300, 20400],
          },
          {
            title: "Pipeline Volume",
            path: PATH_DASHBOARD.automation.pipelineVolume,
            icon: SECONDICONS.PipeLineVolume,
            auth: [20300, 20400],
          },
        ],
      },
      {
        subheader: "DATA ASSET",
        items: [
          {
            title: "Feature Store",
            path: PATH_DASHBOARD.dataAsset.featureStore,
            icon: SECONDICONS.Feature_Store,
            auth: [20500, 20600],
          },
          {
            title: "Model Registry",
            path: PATH_DASHBOARD.dataAsset.modelRegistry,
            icon: SECONDICONS.Modle_Registry,
            auth: [20700, 20800],
          },
        ],
      },
    ],
  },
  {
    firstMenuItem: "FirstMenuItem2",
    icon: FIRSTICONS.FirstMenuItem2,
    secondMenuData: [
      {
        subheader: "ML SERVICE",
        items: [
          {
            title: "ML Runtime",
            path: PATH_DASHBOARD.mlService.mlRuntime,
            icon: SECONDICONS.MlRuntime,
            auth: [20900, 21000],
          },
          {
            title: "ML App Gallery",
            path: PATH_DASHBOARD.mlService.appGallery,
            icon: SECONDICONS.MlAppGallery,
            auth: [21100, 21200],
          },
        ],
      },
      {
        subheader: "PROJECT GOVERNANCE",
        items: [
          {
            title: "Manage",
            path: PATH_DASHBOARD.projectGovernance.manage,
            icon: SECONDICONS.Manage,
            auth: [10500, 10600],
          },
          {
            title: "Monitor",
            path: PATH_DASHBOARD.projectGovernance.monitor,
            icon: SECONDICONS.PlatformMonitor,
            auth: [10500, 10600],
          },
        ],
      },
    ],
  },
  {
    firstMenuItem: "FirstMenuItem3",
    icon: FIRSTICONS.FirstMenuItem3,
    secondMenuData: [
      {
        subheader: "ADMINISTRATION",
        items: [
          {
            title: "Organization",
            path: PATH_DASHBOARD.administration.organization,
            icon: SECONDICONS.Organization,
            auth: [10300, 10400],
          },
          /* {
            title: "Project",
            path: PATH_DASHBOARD.administration.project,
            icon: SECONDICONS.Project,
          }, */
        ],
      },
      {
        subheader: "TEMPLATE",
        items: [
          {
            title: "Notebook Template",
            path: PATH_DASHBOARD.template.notebookTemplate,
            icon: SECONDICONS.Notebook_Template,
            auth: [10100, 10200],
          },
          {
            title: "Pipeline Template",
            path: PATH_DASHBOARD.template.pipelineTemplate,
            icon: SECONDICONS.Pipeline_Template,
            auth: [10100, 10200],
          },
          {
            title: "Project Role",
            path: PATH_DASHBOARD.template.projectRole,
            icon: SECONDICONS.ProjectRole,
            auth: [10700, 10800],
          },
        ],
      },
      {
        subheader: "CONNECTION",
        items: [
          {
            title: "Connection",
            path: PATH_DASHBOARD.connection.connection,
            icon: SECONDICONS.Connection,
            auth: [10100, 10200],
          },
          /* {
            title: "Credential",
            path: PATH_DASHBOARD.connection.credential,
            icon: SECONDICONS.Credential,
          }, */
        ],
      },
      {
        subheader: "OBSERVABILITY",
        items: [
          /* {
            title: "Project Monitor",
            path: PATH_DASHBOARD.observability.projectMonitor,
            icon: SECONDICONS.ProjectMonitor,
          }, */
          {
            title: "Platform Metrics",
            path: PATH_DASHBOARD.observability.grafana,
            icon: SECONDICONS.PlatformMonitor,
            auth: [11100, 11200],
          },
          {
            title: "Platform Logging",
            path: PATH_DASHBOARD.observability.kibana,
            icon: SECONDICONS.ProjectMonitor,
            auth: [11100, 11200],
          },
        ],
      },
    ],
  },
];

export default sidebarConfig;

// material
import { useTheme, styled } from "@mui/material/styles";
import { Box, List, Stack, IconButton, Typography } from "@mui/material";
// types
// import { TriggerManager } from "@/@types/pipeline";
// components
import BadgeStatus from "@/components/project/BadgeStatus";
import SvgIconStyle from "@/components/project/SvgIconStyle";

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  fontSize: "13px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: 62,
}));

const TriggerWrapperStyle = styled(IconButton)(({ theme }) => ({
  position: "relative",
  width: 81,
  height: 80,
  border: `solid 0.1rem ${theme.palette.divider}`,
  borderRadius: "9px",
  margin: theme.spacing(1, 1.7),
}));

const prefix = process.env.NODE_ENV === "development" ? "" : "/ui";

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`${prefix}/static/icons/pipeline/${name}.svg`}
    sx={{ width: 32, height: 32, ml: "14px", mt: "-2px", mb: "6px" }}
  />
);

type Props = {
  triggerList: Array<Record<any, any>>;
  onOpenTriggerConfigPanel: (triggerId: string) => void;
};

export default function TriggerList({
  triggerList,
  onOpenTriggerConfigPanel,
}: Props) {
  const theme = useTheme();
  const isEmpty = triggerList.length === 0;
  // console.log(triggerList);

  const getListIcon = (type) => {
    if (type.includes("schedule")) {
      return getIcon("ic_schedule");
    }

    if (type.includes("amazon_s3")) {
      return getIcon("ic_amazons3");
    }

    if (type.includes("artifactory")) {
      return getIcon("IoMdGitBranch");
    }

    return getIcon("ic_codecommit");
  };

  const getListTitle = (type) => {
    if (type.includes("schedule")) {
      return "Schedule";
    }

    if (type.includes("amazon_s3")) {
      return "Amazon S3";
    }

    if (type.includes("artifactory")) {
      return getIcon("Artifactory");
    }

    return "CodeCommit";
  };

  const getListStatus = (status) => {
    if (status === "started") return "online";
    return "offline";
  };

  return (
    <>
      {!isEmpty && (
        <List>
          {triggerList.map((trigger, index) => (
            <TriggerWrapperStyle
              key={index}
              onClick={() => onOpenTriggerConfigPanel(trigger.id)}
            >
              <Stack sx={{ flexGrow: 1, mb: -1 }}>
                {getListIcon(trigger.trigger_type)}
                <LabelStyle
                  variant="body2"
                  color={theme.palette.text.secondary}
                >
                  {getListTitle(trigger.trigger_type)}
                </LabelStyle>
              </Stack>

              <BadgeStatus
                status={getListStatus(trigger.status)}
                sx={{ right: 2, top: 2, position: "absolute" }}
              />
            </TriggerWrapperStyle>
          ))}
        </List>
      )}
      {isEmpty && (
        <Stack>
          <Box
            component="img"
            src={`${prefix}/static/illustrations/illustration_empty_trigger_list.svg`}
            sx={{ width: 91, height: 300, color: "red", mx: "auto" }}
          />
          <Typography
            gutterBottom
            align="center"
            variant="subtitle2"
            sx={{ color: "text.disabled" }}
          >
            No triggers
          </Typography>
        </Stack>
      )}
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import MlCard from "@/components/project/mlComponents/MlCard";
import Page from "@/components/project/Page";
import { PATH_DASHBOARD } from "@/routes/paths";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import KubeflowForm from "./components/hpTunerCreateOrDuplicate/KubeflowForm";
import { useEffect } from "react";
import { getHptunerDetail } from "@/redux/project/experiment/thunks";
import { RootState, dispatch } from "@/redux/store";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { resetNotebookData } from "@/redux/project/experiment/slice";

const HpTunerCreateOrDuplicate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const { hpTunerId } = useParams();
  const [searchParams] = useSearchParams();

  const isDuplicate = pathname.includes("hpTunerDuplicate");

  const {
    hptunerDetail: { data },
  } = useSelector((state: RootState) => state.experiment);

  const getName = () => {
    if (isDuplicate) {
      return `Create ${data?.hptuner_type || ""} HP tunning job`;
    }
    return `Create ${searchParams.get("type")} HP tunning job`;
  };

  const getLinks = () => {
    return [
      {
        name: "Experiment",
      },
      {
        name: "Hp Tuner",
        href: PATH_DASHBOARD.experiment.hpTuner,
      },
      {
        name: getName(),
      },
    ];
  };

  useEffect(() => {
    if (isDuplicate) {
      dispatch(
        getHptunerDetail({
          id: hpTunerId!,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      );
    }

    return () => {
      dispatch(resetNotebookData("hptunerDetail"));
    };
  }, []);

  return (
    <Page
      heading={getName()}
      title={`HpTuner ${isDuplicate ? "Duplicate" : "Create"} | OmniML`}
      links={getLinks()}
    >
      <MlCard title="New HP Tuner" sx={{ pb: 2 }}>
        <KubeflowForm />
      </MlCard>
    </Page>
  );
};

export default HpTunerCreateOrDuplicate;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Card,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useNavigate, useLocation } from "react-router";
import Page from "@/components/project/Page";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import SearchIcon from "@mui/icons-material/Search";
import AppGalleryList from "./components/appList/AppGalleryList";
import { dispatch, useSelector, RootState } from "@/redux/store";
import { useSnackbar } from "notistack";
import { getAppGalleryList } from "@/redux/project/mlService/appGallery/thunks";
import { setAppGalleryData } from "@/redux/project/mlService/appGallery/slice";

export default function MlAppGallery() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [searchText, setSearchText] = useState<string>("");
  const [scenarioType, setScenarioType] = useState<string>("All");
  const {
    config: { common },
    userDetail: { permissions = [] },
  } = useSelector((state: RootState) => state.common);

  const getLinks = () => {
    return [
      {
        name: "ML Service",
      },
      {
        name: "App Gallery",
      },
    ];
  };

  // filter
  const handleSelectChange = (
    event: SelectChangeEvent<typeof scenarioType>
  ) => {
    const {
      target: { value },
    } = event;
    setScenarioType(value);
    if (value === "All") {
      fetchData({
        page_num: 1,
        page_size: 9999,
        is_active: 1,
        ...filter("name", searchText),
      })
        .unwrap()
        .then(() => {
          enqueueSnackbar("Search Success", { variant: "success" });
        });
    } else {
      fetchData({
        page_num: 1,
        page_size: 9999,
        is_active: 1,
        scenario: value,
        ...filter("name", searchText),
      })
        .unwrap()
        .then(() => {
          enqueueSnackbar("Search Success", { variant: "success" });
        });
    }
  };

  const filter = (name, value) => {
    if (value) return { [name]: value };
    return {};
  };

  const fetchData = (params: {
    page_num: number;
    page_size: number;
    name?: string;
    is_active: number;
    scenario?: string;
  }) => {
    return dispatch(
      getAppGalleryList({
        params,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (scenarioType === "All") {
        fetchData({
          page_num: 1,
          page_size: 9999,
          is_active: 1,
          ...filter("name", searchText),
        })
          .unwrap()
          .then(() => {
            enqueueSnackbar("Search Success", { variant: "success" });
          });
      } else {
        fetchData({
          page_num: 1,
          page_size: 9999,
          is_active: 1,
          ...filter("name", searchText),
          ...filter("scenario", scenarioType),
        })
          .unwrap()
          .then(() => {
            enqueueSnackbar("Search Success", { variant: "success" });
          });
      }
    }
  };

  const handleRefresh = () => {
    fetchData({ page_num: 1, page_size: 9999, is_active: 1 })
      .unwrap()
      .then(() => {
        enqueueSnackbar("Refresh Success", { variant: "success" });
      });
    setSearchText("");
    setScenarioType("All");
  };

  useEffect(() => {
    dispatch(setAppGalleryData("mlAppGalleryList"));
    fetchData({ page_num: 1, page_size: 9999, is_active: 1 });
  }, []);

  return (
    <Page heading="App Gallery" links={getLinks()}>
      <Card>
        <Box>
          <Stack direction="row" justifyContent="space-between" sx={{ p: 2.5 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <TextField
                id="search"
                sx={{ width: "360px" }}
                variant="outlined"
                size="small"
                label="Search by Name"
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
                onKeyDown={handleKeyDown}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() => {
                          if (scenarioType === "All") {
                            fetchData({
                              page_num: 1,
                              page_size: 9999,
                              is_active: 1,
                              ...filter("name", searchText),
                            })
                              .unwrap()
                              .then(() => {
                                enqueueSnackbar("Search Success", {
                                  variant: "success",
                                });
                              });
                          } else {
                            fetchData({
                              page_num: 1,
                              page_size: 9999,
                              is_active: 1,
                              ...filter("scenario", scenarioType),
                              ...filter("name", searchText),
                            })
                              .unwrap()
                              .then(() => {
                                enqueueSnackbar("Search Success", {
                                  variant: "success",
                                });
                              });
                          }
                        }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
              <FormControl
                fullWidth
                margin="normal"
                size="small"
                sx={{ width: "360px" }}
              >
                <InputLabel>Scenario</InputLabel>
                <Select
                  value={scenarioType}
                  onChange={handleSelectChange}
                  label="Scenario"
                  // input={<OutlinedInput id="scenario-multiple-chip" label="Chip" />}
                  // renderValue={(selected) =>
                  //   <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  //     {selected.map((option: string, index: number) => (
                  //       <Chip
                  //         size="small"
                  //         variant="soft"
                  //         label={option}
                  //         key={option}
                  //       />
                  //     ))}
                  //   </Box>
                  // }
                >
                  <MenuItem value="All" key="All">
                    All
                  </MenuItem>
                  {common?.scenario?.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              {permissions.includes("21100") && (
                <Button
                  variant="contained"
                  sx={{ width: "120px", height: "36px" }}
                  onClick={() =>
                    navigate("appGalleryCreate", { state: { mode: "create" } })
                  }
                >
                  Add App
                </Button>
              )}
              <IconButton onClick={handleRefresh}>
                <RefreshOutlinedIcon sx={{ width: "22px", height: "22px" }} />
              </IconButton>
            </Stack>
          </Stack>
        </Box>
        <AppGalleryList />
      </Card>
    </Page>
  );
}

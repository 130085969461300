// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Dialog,
  Stack,
  Divider,
  Button,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import { LoadingButton } from "@mui/lab";
// redux
import { useDispatch } from "@/redux/store";
import {
  CodeSourceOption,
  TrainingCodeStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline";
//
import CodeSelector from "./CodeSelector";
import Scrollbar from "@/components/project/Scrollbar";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 1999,
  minHeight: 440,
  outline: "none",
  display: "flex",
  position: "fixed",
  overflow: "hidden",
  flexDirection: "row",
  margin: theme.spacing(3),
  boxShadow: theme.customShadows.z20,
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.background.paper,
}));

const prefix = process.env.NODE_ENV === "development" ? "" : "/ui";

const CODE_OPTIONS: CodeSourceOption[] = [
  {
    value: "amazon_s3",
    title: "Amazon S3",
    description:
      "Input the S3 location of code package and the name of entry code file.",
    icons: [`${prefix}/static/icons/ic_s3.svg`],
  },
  {
    value: "amazon_codecommit",
    title: "Amazon CodeCommit",
    description: "Select the CodeCommit repo where your code is located.",
    icons: [`${prefix}/static/icons/ic_codecommit.svg`],
  },
  {
    value: "local",
    title: "Local",
    description: "Select a code file on your local machine.",
    icons: [`${prefix}/static/icons/ic_local.svg`],
  },
];

// const REPO_OPTIONS = [
//   { value: "InternWork_2021_YC_DD", label: "InternWork_2021_YC_DD" },
//   { value: "KGQA-NLP-Neptune", label: "KGQA-NLP-Neptune" },
//   { value: "AWS_ML_Lab_Workshop", label: "AWS_ML_Lab_Workshop" },
// ];

// ----------------------------------------------------------------------

type Props = {
  isOpenCompose: boolean;
  onCloseCompose: VoidFunction;
  codeProps: TrainingCodeStruct;
  update: Function;
};

export default function CodePopover({
  isOpenCompose,
  onCloseCompose,
  codeProps,
  update,
}: Props) {
  const dispatch = useDispatch();

  // const Schema = Yup.object().shape({
  //   path: Yup.mixed().required("Code file path is required"),
  // });

  const formik = useFormik({
    initialValues: codeProps,
    // validationSchema: Schema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        dispatch(update(values));
        onCloseCompose();
      } catch (error) {
        setSubmitting(false);
        // setErrors(error.message);
      }
    },
  });

  const { isSubmitting, handleSubmit } = formik;

  if (!isOpenCompose) {
    return null;
  }

  return (
    // <Portal>
    <Dialog open={isOpenCompose} onClose={onCloseCompose}>
      {/* <Backdrop open={fullScreen || isMobile} sx={{ zIndex: 1998 }} /> */}

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <RootStyle
            sx={{
              ...{
                top: 0,
                left: 0,
                display: "flex",
                margin: "auto",
                width: {
                  xs: "90%",
                  sm: "90%",
                  md: "70%",
                  lg: "60%",
                },
                height: {
                  xs: "85vh",
                  md: "80vh",
                },
              },
            }}
          >
            <Stack sx={{ flexGrow: 1 }}>
              <DialogTitle>{"Select code"}</DialogTitle>

              <Scrollbar sx={{ flexDirection: "row" }}>
                <Box sx={{ p: 0, height: "100%" }}>
                  <CodeSelector formik={formik} codeOptions={CODE_OPTIONS} />
                </Box>
              </Scrollbar>

              <Divider />

              <DialogActions sx={{ my: -1 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Confirm
                </LoadingButton>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={onCloseCompose}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Stack>
          </RootStyle>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}

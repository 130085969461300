import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import token from "@iconify/icons-material-symbols/token";
import { Link as RouterLink } from "react-router-dom";
import { alpha } from "@mui/material/styles";
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Stack,
  Tooltip,
} from "@mui/material";
import { useAuth } from "react-oidc-context";
import { MIconButton } from "@/components/project/@material-extend";
import MyAvatar from "@/components/project/MyAvatar";
import MenuPopover from "@/components/project/MenuPopover";
import { TOKEN_FLAG } from "@/config";
import useCollapseDrawer from "@/hooks/project/useCollapseDrawer";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { Credential } from "@/assets/project/icons";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import RoleLabel from "./partComponents/RoleLabel";

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const auth = useAuth();
  const {
    isAdmin,
    isNoneUser,
    userDetail: {
      sys_admin,
      sys_ops,
      organization_id,
      project_id,
      user_id,
      legal_surname = "",
      legal_given_name = "",
      project_list = [],
    },
  } = useSelector((state: RootState) => state.common);

  const { config: { common = {} } = {} } = useSelector(
    (state: RootState) => state.common
  );

  const { SSO_LOGOUT_URL = "" } = common || {};

  const { toggleIsCollapseByBol } = useCollapseDrawer();

  const getMenuOptions = () => {
    return [
      {
        label: "My Credentials",
        icon: Credential,
        iconType: "custom",
        linkTo: "/ui/myCredential",
        state: "fromMyCredential",
        type: "default",
      },
      {
        label: "My Token",
        icon: token,
        iconType: "default",
        linkTo: "",
        type: "custom",
      },
    ];
  };

  const handleLogout = async () => {
    try {
      localStorage.clear();
      auth.removeUser();
      // signoutRedirect parameter is redirect_url not post_logout_redirect_uri
      auth.signoutRedirect(
        {
          extraQueryParams: {
            logout_uri: `${window.location.origin}/ui/`
          }
        }
      );
      // window.location.href = SSO_LOGOUT_URL;
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout", { variant: "error" });
    }
  };

  const handleCopy = () => {
    const token = localStorage.getItem(TOKEN_FLAG);

    navigator.clipboard.writeText(token || "");

    enqueueSnackbar("Copy token successfully", { variant: "success" });
  };

  const getRoleInfo = () => {
    if (isNoneUser) return <></>;

    let rolesList: string[] = [];
    if (isAdmin) {
      if (sys_admin) {
        rolesList.push("System Admin");
      }
      if (sys_ops) {
        rolesList.push("System Operation");
      }
      if (organization_id) {
        rolesList.push("Group Admin");
      }
    } else {
      rolesList =
        project_list?.find((item) => item.id === project_id)?.roles || [];
    }

    return (
      <Stack direction="row" spacing={1} justifyContent="center">
        {rolesList.map((item) => (
          <RoleLabel>{item}</RoleLabel>
        ))}
      </Stack>
    );
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={() => {
          setOpen(true);
        }}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[700], 0.72),
            },
          }),
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%", my: 1.5 }}
          spacing={1}
        >
          <Typography variant="subtitle1" noWrap>
            {`${legal_given_name} ${legal_surname}`}
          </Typography>
          <Typography variant="body2" noWrap>
            {`(${user_id})`}
          </Typography>
        </Stack>

        <Box sx={{ mx: 2 }}>{getRoleInfo()}</Box>

        <Divider sx={{ my: 1 }} />

        {getMenuOptions().map((option) =>
          option.type === "custom" ? (
            <Tooltip title="Click to copy token">
              <MenuItem
                key={option.label}
                onClick={() => {
                  handleCopy();
                  setOpen(false);
                }}
                sx={{ typography: "body2", py: 1, px: 2.5 }}
              >
                <Box
                  component={Icon}
                  icon={option.icon}
                  sx={{
                    mr: 2,
                    width: 24,
                    height: 24,
                  }}
                />

                {option.label}
              </MenuItem>
            </Tooltip>
          ) : (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              {...(option.state ? { state: option.state } : {})}
              onClick={() => {
                setOpen(false);
                toggleIsCollapseByBol(true);
              }}
              sx={{ typography: "body2", py: 1, px: 2.5 }}
            >
              {option.iconType === "custom" ? (
                <SvgIconStyle
                  src={Credential}
                  sx={{
                    width: 20,
                    height: 20,
                    // fontWeight: 500
                    mr: 2,
                  }}
                />
              ) : (
                <Box
                  component={Icon}
                  icon={option.icon}
                  sx={{
                    mr: 2,
                    width: 24,
                    height: 24,
                  }}
                />
              )}

              {option.label}
            </MenuItem>
          )
        )}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

import { ReactNode, createContext, useState, useEffect } from "react";
// material
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export type CollapseDrawerContextProps = {
  isCollapse?: boolean;
  onToggleCollapse: VoidFunction;
  toggleIsCollapseByBol: (a: boolean) => void;
};

const initialState: CollapseDrawerContextProps = {
  isCollapse: false,
  onToggleCollapse: () => {},
  toggleIsCollapseByBol: () => {},
};

const CollapseDrawerContext = createContext(initialState);

type CollapseDrawerProviderProps = {
  children: ReactNode;
};

function CollapseDrawerProvider({ children }: CollapseDrawerProviderProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [collapse, setCollapse] = useState({
    click: false,
  });

  useEffect(() => {
    if (isMobile) {
      setCollapse({
        click: false,
      });
    }
  }, [isMobile]);

  useEffect(() => {
    const storageCollapse = localStorage.getItem("collapse");
    if (storageCollapse) {
      setCollapse(JSON.parse(storageCollapse));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("collapse", JSON.stringify(collapse));
  }, [collapse]);

  const handleToggleCollapse = () => {
    setCollapse({ ...collapse, click: !collapse.click });
  };

  const toggleIsCollapseByBol = (bol: boolean) => {
    setCollapse({ ...collapse, click: bol });
  };

  return (
    <CollapseDrawerContext.Provider
      value={{
        isCollapse: collapse.click,
        onToggleCollapse: handleToggleCollapse,
        toggleIsCollapseByBol: toggleIsCollapseByBol,
      }}
    >
      {children}
    </CollapseDrawerContext.Provider>
  );
}

export { CollapseDrawerProvider, CollapseDrawerContext };

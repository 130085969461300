import Page from "@/components/project/Page";
import { PATH_DASHBOARD } from "@/routes/paths";
import MlRuntimeCreateForm from "./components/mlRuntimeCreateOrEdit/MlRuntimeCreateForm";

const MlRuntimeCreateOrEdit = () => {
  const getLinks = () => {
    return [
      {
        name: "ML Service",
      },
      {
        name: "ML Runtime",
        href: PATH_DASHBOARD.mlService.mlRuntime,
      },
      {
        name: `Create ML Service` || "",
      },
    ];
  };

  return (
    <Page
      title={`Create ML Service | OmniML`}
      heading={`Create ML Service`}
      links={getLinks()}
    >
      <MlRuntimeCreateForm />
    </Page>
  );
};

export default MlRuntimeCreateOrEdit;

import { useEffect } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch } from "react-redux";
// material
import { MenuItem, Paper, Stack } from "@mui/material";

import { MlPipelineTextfield } from "../../../../../components/PipelineComponents";
import { TrainingOutputItem as KubeflowTrainingOutputItem } from "@/@types/project/mlPipeline/Kubeflow/pipeline-training";
import { handleKubeflowStepArray } from "@/pages/project/automation/MlPipeline/graph/slices/slice";
import { IconButton } from "@mui/material";
import { Icon } from "@iconify/react";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";

export default function TrainingOutputsItem({
  id,
  data,
  isEdit,
  length,
}: {
  id: number;
  data: KubeflowTrainingOutputItem;
  isEdit: boolean;
  length: number;
}) {
  const dispatch = useDispatch();
  const ParamSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const formik = useFormik({
    initialValues: data,
    validationSchema: ParamSchema,
    onSubmit: () => {},
  });

  const submitFunc = (fieldName, value) => {
    try {
      dispatch(
        handleKubeflowStepArray({
          handleType: "edit",
          id,
          data: {
            [fieldName]: value,
          },
          key: "outputs",
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const isDefaultOutput = (name) => {
    return (
      name === "model_file_path" || name === "evaluation_metrics_file_path"
    );
  };

  const { values, setValues } = formik;

  useEffect(() => {
    setValues(data);
  }, [length]);

  return (
    <>
      <Paper sx={{ p: 1.5, borderRadius: 1.5, bgcolor: "background.neutral" }}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off">
            <Stack spacing={2}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <MlPipelineTextfield
                  label={"Name"}
                  field={"name"}
                  disabled={isEdit || isDefaultOutput(values.name)}
                  formik={formik}
                  submitThisValuefunc={(value) => submitFunc("name", value)}
                />

                <IconButton
                  size="small"
                  onClick={() => {
                    dispatch(
                      handleKubeflowStepArray({
                        id,
                        handleType: "delete",
                        key: "outputs",
                      })
                    );
                  }}
                >
                  <Icon icon={trash2Fill} width={18} height={18} />
                </IconButton>
              </Stack>

              <MlPipelineTextfield
                select
                label={"Type"}
                field={"dataType"}
                disabled={isEdit}
                formik={formik}
                submitThisValuefunc={(value) => submitFunc("dataType", value)}
              >
                {[{ code: "String", label: "String" }].map((item) => (
                  <MenuItem key={item.label} value={item.label}>
                    {item.label}
                  </MenuItem>
                ))}
              </MlPipelineTextfield>

              <MlPipelineTextfield
                label={"Description (Optional)"}
                field={"description"}
                disabled={isEdit}
                formik={formik}
                submitThisValuefunc={(value) =>
                  submitFunc("description", value)
                }
              />
            </Stack>
          </Form>
        </FormikProvider>
      </Paper>
    </>
  );
}

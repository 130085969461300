/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Field, FieldArray, FormikProps } from "formik";
import { IKubeflowHpTunerFormValues } from "..";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { FormControlLabel } from "@mui/material";
import { Switch } from "@mui/material";
import { useEffect } from "react";
import MlNumberInputField from "@/components/project/mlComponents/MlNumberInputField";

interface IAdditionalMetricsErrorsType {
  metricName: string;
}

interface IAdditionalMetricsTouchedType {
  metricName: boolean;
}

interface IProps {
  formik: FormikProps<IKubeflowHpTunerFormValues>;
}

const Objective = ({ formik }: IProps) => {
  const handleStrategiesByMetrics = (objective) => {
    let tempArr: any = [];

    const arr = {
      maximize: "max",
      minimize: "min",
    };

    tempArr.push({
      metricName: objective.metric,
      strategy: arr[objective.type] || "",
    });
    tempArr.push(
      ...objective?.additionalMetrics?.map((i) => ({
        metricName: i.metricName,
        strategy: arr[objective.type] || "",
      }))
    );

    formik.setFieldValue("objective.metricStrategies", tempArr);
  };

  const { values, errors, touched, setFieldValue, setFieldTouched } = formik;
  console.log("values", values);

  const additionalMetricsErrors = errors.objective
    ?.additionalMetrics as Array<IAdditionalMetricsErrorsType>;
  const additionalMetricsTouched = touched.objective
    ?.additionalMetrics as Array<IAdditionalMetricsTouchedType>;

  useEffect(() => {
    if (values.objective.isSetMetricStrategies) {
      handleStrategiesByMetrics(values.objective);
    }
  }, [values.objective.metric, values.objective?.additionalMetrics?.length]);

  return (
    <>
      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        Objective
      </Typography>
      <Box sx={{ pl: 2 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", pl: 1, py: 1 }}
        >
          Katib will use the objective's metric to monitor the performance of
          the model. It will create Trials until either the goal is reached or
          the Controller has run the maximum defined numbar of Trials.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <FormControl
              fullWidth
              margin="dense"
              size="small"
              error={touched.objective?.type && Boolean(errors.objective?.type)}
            >
              <InputLabel>Type</InputLabel>
              <Field
                as={Select}
                name="objective.type"
                label="Type"
                error={
                  touched.objective?.type && Boolean(errors.objective?.type)
                }
                onChange={async (event) => {
                  await setFieldValue("objective.type", event.target.value);
                  setFieldTouched("objective.type", true);

                  handleStrategiesByMetrics({
                    ...values.objective,
                    type: event.target.value,
                  });
                }}
              >
                <MenuItem value="maximize">Maximize</MenuItem>
                <MenuItem value="minimize">Minimize</MenuItem>
              </Field>
              <FormHelperText
                margin="dense"
                sx={{ minHeight: "18px", marginTop: "2px" }}
              >
                {touched.objective?.type && errors.objective?.type
                  ? errors.objective?.type
                  : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              as={TextField}
              name="objective.metric"
              label="Metric"
              size="small"
              fullWidth
              margin="dense"
              helperText={touched.objective?.metric && errors.objective?.metric}
              error={
                touched.objective?.metric && Boolean(errors.objective?.metric)
              }
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
              onChange={async (event) => {
                await setFieldValue("objective.metric", event.target.value);
                setFieldTouched("objective.metric", true);

                if (values.objective.isSetMetricStrategies) {
                  const tempObjective = {
                    ...values.objective,
                    metric: event.target.value,
                  };
                  handleStrategiesByMetrics(tempObjective);
                }
              }}
            />
          </Grid>
        </Grid>
        <MlNumberInputField
          name="objective.goal"
          label="Goal"
          formik={formik}
          helperText={
            (touched.objective?.goal && errors.objective?.goal) ||
            "You can run the Experiment without specifying the goal. In that case, Katib runs the Experiment until the corresponding successful Trials reach maximum number of allowed Trials. This number is defined in the the Trial Thresholds section above."
          }
          isDouble
        />
        <FieldArray
          name="objective.additionalMetrics"
          render={({ push, remove }) => (
            <>
              <Typography variant="body2" sx={{ mt: 1.5, mb: 1 }}>
                Additional Metrics
              </Typography>
              {values.objective.additionalMetrics.map((_, index) => (
                <Stack direction="row">
                  <>
                    <Field
                      as={TextField}
                      name={`objective.additionalMetrics.${index}.metricName`}
                      label="Metric Name"
                      size="small"
                      fullWidth
                      margin="dense"
                      helperText={
                        (additionalMetricsTouched?.[index]?.metricName &&
                          additionalMetricsErrors?.[index]?.metricName) ||
                        " "
                      }
                      error={
                        additionalMetricsTouched?.[index]?.metricName &&
                        Boolean(additionalMetricsErrors?.[index]?.metricName)
                      }
                      FormHelperTextProps={{
                        sx: { minHeight: "18px", marginTop: "2px" },
                      }}
                      onChange={async (event) => {
                        await setFieldValue(
                          `objective.additionalMetrics.${index}.metricName`,
                          event.target.value
                        );
                        setFieldTouched(
                          `objective.additionalMetrics.${index}.metricName`,
                          true
                        );

                        if (values.objective.isSetMetricStrategies) {
                          const tempAdditionalMetrics =
                            values.objective.additionalMetrics.map(
                              (i, idx) => ({
                                metricName:
                                  idx === index
                                    ? event.target.value
                                    : i.metricName,
                              })
                            );

                          handleStrategiesByMetrics({
                            ...values.objective,
                            additionalMetrics: tempAdditionalMetrics,
                          });
                        }
                      }}
                    />
                    <Box sx={{ ml: 1.5, mt: 1 }}>
                      <IconButton
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <RemoveOutlinedIcon />
                      </IconButton>
                    </Box>
                  </>
                </Stack>
              ))}
              <Button
                variant="text"
                color="secondary"
                startIcon={<AddOutlinedIcon />}
                onClick={() => {
                  push({
                    metricName: "",
                  });
                }}
              >
                Addtional Metric
              </Button>
            </>
          )}
        />
        <Box sx={{ pl: 1, pb: 1.5, mt: 2 }}>
          <FormControlLabel
            control={
              // checked to value
              <Field name={`objective.isSetMetricStrategies`}>
                {({ field }) => (
                  <Switch
                    {...field}
                    checked={field.value || false} // init undefined to false
                    onChange={(_, checked) => {
                      setFieldValue(field.name, checked);
                      if (checked) {
                        handleStrategiesByMetrics(values.objective);
                      } else {
                        setFieldValue("objective.metricStrategies", []);
                      }
                    }}
                    size="small"
                  />
                )}
              </Field>
            }
            label="Set metric strategies"
          />
        </Box>
        {values.objective.isSetMetricStrategies &&
          values.objective.metricStrategies.map((item, index) => (
            <FormControl
              fullWidth
              margin="dense"
              size="small"
              error={
                touched.objective?.[index]?.strategy &&
                Boolean(errors.objective?.[index]?.strategy)
              }
            >
              <InputLabel>{`Strategy for metric ${item.metricName}`}</InputLabel>
              <Field
                as={Select}
                name={`objective.metricStrategies.${index}.strategy`}
                label={`Strategy for metric ${item.metricName}`}
                disabled={!item.metricName || values.objective.type}
                error={
                  touched.objective?.[index]?.strategy &&
                  Boolean(errors.objective?.[index]?.strategy)
                }
              >
                <MenuItem value="max">Max</MenuItem>
                <MenuItem value="min">Min</MenuItem>
              </Field>
              <FormHelperText
                margin="dense"
                sx={{ minHeight: "18px", marginTop: "2px" }}
              >
                {touched.objective?.[index]?.strategy &&
                errors.objective?.[index]?.strategy
                  ? errors.objective?.[index]?.strategy
                  : ""}
              </FormHelperText>
            </FormControl>
          ))}
      </Box>
    </>
  );
};

export default Objective;

import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import { useDispatch } from "react-redux";
// material
import { Paper, Stack, IconButton } from "@mui/material";
//
import {
  updateProcessingJobArgs,
  deleteProcessingJobArgs,
} from "../../../graph/slices/slice";
import { PipelineTextfield } from "../../components/PipelineComponents";
import { JobArgument } from "@/@types/project/mlPipeline/SageMaker/pipeline";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";

// ----------------------------------------------------------------------

export default function ProcessingCodeArgs({
  id,
  data,
}: {
  id: number;
  data: JobArgument;
}) {
  const dispatch = useDispatch();
  const ParamSchema = Yup.object().shape({
    // name: Yup.string().required('Name is required'),
    // value: Yup.string().required('Value is required')
  });

  const formik = useFormik<JobArgument>({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values) => {
      try {
        dispatch(
          updateProcessingJobArgs({
            id: id,
            data: values,
          })
        );
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { handleSubmit } = formik;

  return (
    <>
      <Paper
        sx={{
          p: 1.5,
          mx: 2,
          mb: 2,
          mt: 1,
          borderRadius: 1.5,
          bgcolor: "background.neutral",
        }}
      >
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onChange={handleSubmit}>
            <Stack spacing={2}>
              <PipelineTextfield
                label={"Argument name"}
                field={"name"}
                placeholder={"Argument name"}
                formik={formik}
              />

              <Stack direction="row" spacing={-0.2} alignItems="center">
                {/* <PipelineTextfield
                  label={"Value"}
                  field={"value"}
                  placeholder={"Argument value"}
                  formik={formik}
                /> */}

                <PipelineParameterEntry
                  label={"Value"}
                  field={"value"}
                  fieldUseParam={"useParam"}
                  formik={formik}
                />

                <IconButton
                  size="small"
                  onClick={() => {
                    dispatch(deleteProcessingJobArgs(id));
                  }}
                >
                  <Icon icon={trash2Fill} width={18} height={18} />
                </IconButton>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </Paper>
    </>
  );
}

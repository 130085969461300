/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  InputAdornment,
  TablePagination,
  Card,
  Stack,
  Button,
  Typography,
  Box,
} from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Scrollbar from "@/components/project/Scrollbar";
import { extractKeys } from "@/utils/project/utils";
import Label from "@/components/project/Label";
import NotebookVolumeTableActionCol from "./NotebookVolumeTableActionCol";
import MlTableBodyContainer from "@/components/project/mlComponents/MlTableBodyContainer";
import { RootState, dispatch } from "@/redux/store";
import { getNotebookVolumeTableData } from "@/redux/project/experiment/thunks";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import NotebookVolumeUpdateTagsDrawer from "./NotebookVolumeUpdateTagsDrawer";
import NotebookVolumeCreateOrEditDialog from "./NotebookVolumeCreateOrEditDialog";

interface Column {
  id: string;
  label?: string;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
}

enum StatusEnum {
  started = "success",
  closed = "default",
  updated = "secondary",
  failed = "error",
}

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 150 },
  { id: "cluster", label: "Cluster Name" },
  { id: "size", label: "Size" },
  // { id: 'accessMode', label: 'Access Mode' },
  // { id: 'storage_class', label: 'Storage Class' },
  // { id: 'userdBy', label: 'Userd By' },
  { id: "created_at", label: "Create At" },
  { id: "status", label: "Status", maxWidth: 75 },
  { id: "", width: 150 },
];

const NotebookVolumeTable = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const [searchText, setSearchText] = useState<string>("");
  const [page_num, setPageNum] = useState<number>(1);
  const [page_size, setPageSize] = useState<number>(10);

  const {
    notebookVolumeTableList: { data, loading },
  } = useSelector((state: RootState) => state.experiment);

  const [rowData, setRowData] = useState({});

  // drawer
  const [openNotebookVolumeDetailDrawer, setOpenNotebookVolumeDetailDrawer] =
    useState<boolean>(false);

  // dialog
  const [
    openNotebookVolumeCreateOrEditDialog,
    setOpenNotebookVolumeCreateOrEditDialog,
  ] = useState<boolean>(false);

  const [dialogMode, setDialogMode] = useState<"create" | "edit">("create");

  const nameExist = (key, value) => {
    if (value) {
      return {
        [key]: value,
      };
    }
    return {};
  };

  const fetchData = (params: {
    name?: string;
    notebook_type?: string;
    page_num: number;
    page_size: number;
  }) => {
    return dispatch(
      getNotebookVolumeTableData({
        params,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  };

  const handleRefresh = (isUpdate?: boolean) => {
    setSearchText("");
    setPageNum(1);
    setPageSize(10);

    fetchData({ page_num: 1, page_size: 10 }).then(() => {
      if (!isUpdate) {
        enqueueSnackbar("Refresh Success", { variant: "success" });
      }
    });
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNum(newPage + 1);
    fetchData({
      ...nameExist("name", searchText),
      page_num: newPage + 1,
      page_size,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNum(1);
    fetchData({
      ...nameExist("name", searchText),
      page_num: 1,
      page_size: parseInt(event.target.value, 10),
    });
  };

  const renderCell = (row, column) => {
    switch (column.id) {
      case "name":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography
              variant="subtitle2"
              onClick={() => {
                setOpenNotebookVolumeDetailDrawer(true);
                setRowData(row);
              }}
              color="secondary"
              sx={{ cursor: "pointer" }}
            >
              {row[column.id]}
            </Typography>
          </TableCell>
        );
      case "cluster":
        const {
          resource_config: { env = {} },
        } = row;
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2">
              {env?.cluster_names.join(",") || "-"}
            </Typography>
          </TableCell>
        );
      case "status":
        const status = row[column.id];
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width", "maxWidth") }}
          >
            {status ? (
              <Label color={StatusEnum[status] || "default"}>{status}</Label>
            ) : (
              "-"
            )}
          </TableCell>
        );
      case "size":
        const {
          resource_config: { notebook_volume = {} },
        } = row;
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width", "maxWidth") }}
          >
            {notebook_volume[column.id] || "-"}
          </TableCell>
        );
      case "":
        return (
          <TableCell
            align="right"
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <NotebookVolumeTableActionCol
              row={row}
              setDialogMode={() => {
                setOpenNotebookVolumeCreateOrEditDialog(true);
                setDialogMode("edit");
                setRowData(row);
              }}
              handleRefresh={handleRefresh}
            />
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {row[column.id] || "-"}
            </Typography>
          </TableCell>
        );
    }
  };

  useEffect(() => {
    dispatch(
      getNotebookVolumeTableData({
        params: {
          page_num,
          page_size,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  }, []);

  return (
    <Card>
      <Stack direction="row" justifyContent="space-between" sx={{ p: 2.5 }}>
        <Box sx={{ width: "360px" }}>
          <TextField
            id="search"
            fullWidth
            variant="outlined"
            size="small"
            label="Search volume name"
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() =>
                      fetchData({
                        ...nameExist("name", searchText),
                        page_num: 1,
                        page_size: 10,
                      }).then(() => {
                        enqueueSnackbar("Search Success", {
                          variant: "success",
                        });
                      })
                    }
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Button
            variant="contained"
            sx={{ width: "120px", height: "36px" }}
            onClick={() => {
              setOpenNotebookVolumeCreateOrEditDialog(true);
              setDialogMode("create");
            }}
          >
            Add Volume
          </Button>
          <IconButton onClick={() => handleRefresh()}>
            <RefreshOutlinedIcon sx={{ width: "22px", height: "22px" }} />
          </IconButton>
        </Stack>
      </Stack>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <MlTableBodyContainer loading={loading} data={data?.items}>
                {data?.items?.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((column) => renderCell(row, column))}
                  </TableRow>
                ))}
              </MlTableBodyContainer>
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25]}
        component="div"
        count={data?.total || 0}
        rowsPerPage={page_size}
        page={page_num - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <NotebookVolumeUpdateTagsDrawer
        isOpen={openNotebookVolumeDetailDrawer}
        onClose={() => {
          setOpenNotebookVolumeDetailDrawer(false);
        }}
        row={rowData}
      />

      <NotebookVolumeCreateOrEditDialog
        row={rowData}
        isOpen={openNotebookVolumeCreateOrEditDialog}
        mode={dialogMode}
        onClose={() => {
          setOpenNotebookVolumeCreateOrEditDialog(false);
        }}
        handleRefresh={handleRefresh}
      />
    </Card>
  );
};

export default NotebookVolumeTable;

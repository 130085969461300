import { useState } from "react";
import { Icon } from "@iconify/react";
import { useFormik, Form, FormikProvider } from "formik";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { Stack, Collapse } from "@mui/material";
// redux
import { useDispatch } from "react-redux";
// types
import {
  RegisterModelStep,
  DriftCheckBaselinesStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-register-model";
import { updateDriftCheckBaslines } from "../../../graph/slices/slice";
//
import ModelMetricCard from "./ModelMetricCard";
import { CollapseButtonStyle } from "../../SettingSidebar";

// ----------------------------------------------------------------------

const baselineList = [
  ["Bias config file", "biasConfigFile"],
  ["Bias pre-training constraints", "biasPreTrainingConstraints"],
  ["Bias post-training constraints", "biasPostTrainingConstraints"],
  ["Explainability config file", "explainabilityConfigFile"],
  ["Explainability constraints", "explainabilityConstraints"],
  ["Model statistics", "modelStatistics"],
  ["Model constraints", "modelConstraints"],
  ["Model data statistics", "modelDataStatistics"],
  ["Model data constraints", "modelDataConstraints"],
];

type Props = {
  currentStep: RegisterModelStep | null;
};

export default function DriftCheckBaselines({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [isCollapse, setCollapse] = useState(true);

  const formik = useFormik<DriftCheckBaselinesStruct>({
    initialValues: {
      //model_statistics
      modelStatistics: currentStep?.driftCheckBaselines?.modelStatistics || "",
      modelStatisticsUseParam:
        currentStep?.driftCheckBaselines?.modelStatisticsUseParam || false,
      modelStatisticsContentType:
        currentStep?.driftCheckBaselines?.modelStatisticsContentType || "None",
      //model_constraints
      modelConstraints:
        currentStep?.driftCheckBaselines?.modelConstraints || "",
      modelConstraintsUseParam:
        currentStep?.driftCheckBaselines?.modelConstraintsUseParam || false,
      modelConstraintsContentType:
        currentStep?.driftCheckBaselines?.modelConstraintsContentType || "None",
      //model_data_statistics
      modelDataStatistics:
        currentStep?.driftCheckBaselines?.modelDataStatistics || "",
      modelDataStatisticsUseParam:
        currentStep?.driftCheckBaselines?.modelDataStatisticsUseParam || false,
      modelDataStatisticsContentType:
        currentStep?.driftCheckBaselines?.modelDataStatisticsContentType ||
        "None",
      //model_data_constraints
      modelDataConstraints:
        currentStep?.driftCheckBaselines?.modelDataConstraints || "",
      modelDataConstraintsUseParam:
        currentStep?.driftCheckBaselines?.modelDataConstraintsUseParam || false,
      modelDataConstraintsContentType:
        currentStep?.driftCheckBaselines?.modelDataConstraintsContentType ||
        "None",
      //bias_config_file
      biasConfigFile: currentStep?.driftCheckBaselines?.biasConfigFile || "",
      biasConfigFileUseParam:
        currentStep?.driftCheckBaselines?.biasConfigFileUseParam || false,
      biasConfigFileContentType:
        currentStep?.driftCheckBaselines?.biasConfigFileContentType || "None",
      //explainability_config_file
      explainabilityConfigFile:
        currentStep?.driftCheckBaselines?.explainabilityConfigFile || "",
      explainabilityConfigFileUseParam:
        currentStep?.driftCheckBaselines?.explainabilityConfigFileUseParam ||
        false,
      explainabilityConfigFileContentType:
        currentStep?.driftCheckBaselines?.explainabilityConfigFileContentType ||
        "None",
      //explainability_constraints
      explainabilityConstraints:
        currentStep?.driftCheckBaselines?.explainabilityConstraints || "",
      explainabilityConstraintsUseParam:
        currentStep?.driftCheckBaselines?.explainabilityConstraintsUseParam ||
        false,
      explainabilityConstraintsContentType:
        currentStep?.driftCheckBaselines
          ?.explainabilityConstraintsContentType || "None",
      //bias_pre_training
      biasPreTrainingConstraints:
        currentStep?.driftCheckBaselines?.biasPreTrainingConstraints || "",
      biasPreTrainingConstraintsUseParam:
        currentStep?.driftCheckBaselines?.biasPreTrainingConstraintsUseParam ||
        false,
      biasPreTrainingConstraintsContentType:
        currentStep?.driftCheckBaselines
          ?.biasPreTrainingConstraintsContentType || "None",
      //bias_post_training
      biasPostTrainingConstraints:
        currentStep?.driftCheckBaselines?.biasPostTrainingConstraints || "",
      biasPostTrainingConstraintsUseParam:
        currentStep?.driftCheckBaselines?.biasPostTrainingConstraintsUseParam ||
        false,
      biasPostTrainingConstraintsContentType:
        currentStep?.driftCheckBaselines
          ?.biasPostTrainingConstraintsContentType || "None",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      try {
        dispatch(updateDriftCheckBaslines(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { handleSubmit } = formik;

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setCollapse((prev) => !prev)}
        endIcon={
          <Icon
            icon={isCollapse ? arrowIosForwardFill : arrowIosDownwardFill}
            width={16}
            height={16}
          />
        }
      >
        Drift check baselines
      </CollapseButtonStyle>

      <Collapse in={!isCollapse} sx={{ mx: 2, mb: !isCollapse ? 0.5 : 0 }}>
        <Stack spacing={1} sx={{ my: 1 }}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onChange={handleSubmit}>
              <Stack spacing={0} sx={{ mt: 0.5 }}>
                {baselineList.map(([label, field]) => (
                  <ModelMetricCard
                    key={field}
                    label={label}
                    field={field}
                    fieldUseParam={`${field}UseParam`}
                    formik={formik}
                    contentType={`${field}ContentType`}
                  />
                ))}
              </Stack>
            </Form>
          </FormikProvider>
        </Stack>
      </Collapse>

      {/* {isCollapse && <Divider />} */}
    </>
  );
}

import { Divider, ListItemText } from "@mui/material";
import { Grid, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useState } from "react";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import { DeleteIcon, EditIcon } from "@/assets/project/icons";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router";
import { deleteConnection } from "@/redux/project/connection/thunks";
import { dispatch } from "@/redux/store";

interface IProps {
  row: {
    [key: string]: any;
  };
  setDialogModeAndSetData: (a: Record<string, any>) => void;
  handleRefresh: (a: boolean) => void;
}

const ConnectionTableActionCol = ({
  row,
  setDialogModeAndSetData,
  handleRefresh,
}: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  // delete confirm
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);

  // dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelConn = async () => {
    setDelLoading(true);
    try {
      await dispatch(
        deleteConnection({
          id: row.id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      setDelLoading(false);
      setOpenConfirm(false);
      enqueueSnackbar("Delete Success", { variant: "success" });

      handleRefresh(true);
    } catch (e) {
      setDelLoading(false);
    }
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={1}>
        {/* <Grid item  xs={6} lg={6}>
          <IconButton onClick={() => setDrawerOpenAndSetData(row)}>
            <KeyboardArrowRightOutlinedIcon />
          </IconButton>
        </Grid> */}
        <Grid item xs={12} lg={12}>
          <IconButton onClick={handleClick}>
            <MoreVertOutlinedIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setDialogModeAndSetData(row);
              }}
              sx={{ color: "text.secondary" }}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <SvgIconStyle
                  src={EditIcon}
                  sx={{
                    width: 22,
                    height: 22,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Edit"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setOpenConfirm(true);
                handleClose();
              }}
              key="delete"
              sx={{ color: "error.main" }}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <SvgIconStyle
                  src={DeleteIcon}
                  sx={{
                    width: 22,
                    height: 22,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Delete"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete connection <strong> {row.name} </strong>{" "}
            item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelConn();
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
};

export default ConnectionTableActionCol;

import { EnvVariable } from "@/@types/project/mlPipeline/SageMaker/pipeline";
import {
  ModelStep,
  ModelArguments,
  ModelContainerStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-model";
import {
  parseUri,
  checkUseParam,
  parseFramework,
  parseFrameworkVersion,
} from "./helper-functions";

// ----------------------------------------------------------------------

export default function parseModelStep(
  index: number,
  pipelineStep: any,
  stepPairs: [string, string, string][]
) {
  const args = pipelineStep.Arguments as ModelArguments;
  // console.log(args);
  const step = {
    id: String(index),
    type: "Model",
    name: pipelineStep.Name,
    container: parseContainerProps(args.PrimaryContainer),
    modelData: {
      modelDataUrl: parseUri(
        args.PrimaryContainer.ModelDataUrl,
        pipelineStep.Name,
        stepPairs,
        "ModelDataUrl"
      ),
      modelDataUrlUseParam: checkUseParam(args.PrimaryContainer.ModelDataUrl),
    },
    nodeX: 220 * index,
    nodeY: 0,
    tags: [],
    properties: [],
    stepType: "Model",
  } as ModelStep;
  return step;
}

const parseContainerProps = (containerConfig: any) => {
  const containerDefProps: ModelContainerStruct = {
    containerType: parseFramework(containerConfig.Image),
    containerTypeUseParam: checkUseParam(containerConfig.Image),
    frameworkVersion: parseFrameworkVersion(containerConfig.Image),
    // instanceType: parseUri(containerConfig.ResourceConfig.InstanceType),
    // instanceTypeUseParam: checkUseParam(
    //   containerConfig.ResourceConfig.InstanceType
    // ),
    environmentVars: parseEnvVar(containerConfig.Environment),
  };
  return containerDefProps;
};

const parseEnvVar = (env: any) => {
  if (env !== undefined && Object.entries(env).length > 0) {
    const environmentVars: Array<EnvVariable> = [];
    for (const [_key, _value] of Object.entries(env)) {
      environmentVars.push({ name: _key, value: String(_value) });
    }
    return environmentVars;
  } else {
    return [];
  }
};

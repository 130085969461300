import { ModelStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-model";
import { StepInfo } from "@/@types/project/mlPipeline/SageMaker/pipeline";
import { generateUri, formEnvVars, generateImageUri } from "./helper-functions";

// ----------------------------------------------------------------------

export default function generateModelStep(step: ModelStep, roleArn: string) {
  // console.log(step);
  return {
    Name: step.name,
    Type: step.type,
    Arguments: {
      ExecutionRoleArn: roleArn,
      PrimaryContainer: {
        Image: generateImageUri(
          step.container.containerType,
          step.container.containerTypeUseParam
        ),
        Environment: formEnvVars(step.container.environmentVars),
        ModelDataUrl: generateUri(
          step.modelData.modelDataUrl,
          step.modelData.modelDataUrlUseParam
        ),
      },
    },
  } as StepInfo;
}

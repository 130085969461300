import SvgIconStyle from "@/components/project/SvgIconStyle";
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { DeleteIcon } from "@/assets/project/icons";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import { dispatch } from "@/redux/store";
import { deleteHptuner } from "@/redux/project/experiment/thunks";
import bxsDuplicate from "@iconify/icons-bx/bxs-duplicate";
import { Icon } from "@iconify/react";

interface IProps {
  row: {
    [key: string]: any;
  };
  handleRefresh: (a?: boolean) => void;
}

const HpTunerTableActionCol = ({ row, handleRefresh }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // delete confirm
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);

  // dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelHptuner = async () => {
    setDelLoading(true);
    try {
      await dispatch(
        deleteHptuner({
          id: row.id,
          params: {
            status: "wait_close",
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      setDelLoading(false);
      setOpenConfirm(false);
      enqueueSnackbar("Delete Success", { variant: "success" });

      handleRefresh(true);
    } catch (e) {
      setDelLoading(false);
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertOutlinedIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          key="duplicate"
          onClick={() => {
            navigate(`${row.id}/hpTunerDuplicate`);
            handleClose();
          }}
        >
          <ListItemIcon sx={{ mr: 1 }}>
            <Icon icon={bxsDuplicate} width={22} height={22} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: "body2" }}>
            Duplicate
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={!(row.status === "failed" || row.status === "closed")}
          onClick={() => {
            setOpenConfirm(true);
            handleClose();
          }}
          key="delete"
          sx={{ color: "error.main" }}
        >
          <ListItemIcon sx={{ mr: 1 }}>
            <SvgIconStyle
              src={DeleteIcon}
              sx={{
                width: 22,
                height: 22,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete hptuner <strong> {row.name} </strong> item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelHptuner();
            }}
            loading={delLoading}
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
};

export default HpTunerTableActionCol;

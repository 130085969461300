import { useState } from "react";
import { Icon } from "@iconify/react";
import { useParams } from "react-router";
import apiFilled from "@iconify/icons-ant-design/api-filled";
// material
import {
  Box,
  Card,
  Tab,
  Tabs,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import useLocales from "@/hooks/project/useLocales";
import TesterRequestApiCall from "./TesterRequestApiCall";
// import { createImageURL } from "./Tester";
import { RootState, useSelector } from "@/redux/store";
import { useSnackbar } from "notistack";
import { fBytesToKB } from "@/utils/project/formatNumber";

type TesterResponseStruct = {
  prediction: {
    pred: string;
    time: string;
    size: string;
    statusCode: number;
  };
  body: string;
};

type Props = {
  dataType?: string;
  curDeployment: Record<string, any>;
  setCurDeployment: Function;
  loadingResponse: boolean;
  onSetLoadingResponse: React.Dispatch<React.SetStateAction<boolean>>;
  onSetResponses: React.Dispatch<React.SetStateAction<TesterResponseStruct[]>>;
};

export default function TesterRequest({
  dataType,
  curDeployment,
  setCurDeployment,
  loadingResponse,
  onSetLoadingResponse,
  onSetResponses,
}: Props) {
  const { translate } = useLocales();
  const { runtimeId = "" } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const {
    mlServiceDeploymentList: {
      data: { items: deploymentList = [] },
    },
  } = useSelector((state: RootState) => state.mlRuntime);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const deployment =
      deploymentList.find((item) => item.id === event.target.value) || {};
    setCurDeployment(deployment);
  };

  const handleReponse = (response: any, startTime: string) => {
    if (response && response.status === 200) {
      if (
        response.data
        // && (response.data.body ||
        //   response.data.detail ||
        //   response.data.result ||
        //   response.data.prediction)
      ) {
        const endTime = new Date();
        const duration = (endTime as any) - Date.parse(startTime);
        onSetResponses((prevState) => [
          {
            body:
              dataType && dataType.toLowerCase() === "json"
                ? JSON.stringify(response.data)
                : "",
            // dataType && dataType.toLowerCase() === "image"
            // ? createImageURL(response.data.result, "base64", "image/jpeg")
            // : dataType && dataType.toLowerCase() === "video"
            //   ? JSON.stringify(response.data.result)
            //   : dataType && dataType.toLowerCase() === "json"
            //     ? JSON.stringify(response.data.result)
            //     : JSON.stringify(response.data.body),
            prediction: {
              pred: JSON.stringify(
                response.data.detail
                  ? response.data.detail
                  : response.data.prediction
              ),
              size: `${fBytesToKB(response.headers["content-length"])} KB`,
              statusCode: 200,
              time: `${duration} ms`,
            },
          },
          ...prevState,
        ]);
      } else {
        if (response.data)
          enqueueSnackbar(JSON.stringify(response.data), {
            variant: "error",
          });
        // else
        //   enqueueSnackbar("Error occurred", {
        //     variant: "error",
        //   });
      }
      onSetLoadingResponse(false);
    } else {
      enqueueSnackbar("Error occurred", {
        variant: "error",
      });
    }
  };

  return (
    <Card
      sx={{
        alignItems: "top",
        pt: 1,
        height: "calc(100% - 40px)",
      }}
    >
      <Stack direction="row" justifyContent="space-between" spacing={3}>
        <Tabs
          value="api_call"
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          sx={{ px: 2.5, width: "50%" }}
        >
          <Tab
            disableRipple
            key="api_call"
            label={translate("api_call")}
            icon={<Icon icon={apiFilled} width={20} height={20} />}
            value="api_call"
            style={{ marginRight: 4 }}
          />
          <IconButton
            sx={{ width: 36, height: 36, mt: 0.7 }}
            onClick={handleClick}
          >
            <HelpOutlineOutlinedIcon sx={{ width: 20, height: 20 }} />
          </IconButton>
        </Tabs>
        <Popover
          id="help"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 2 }}>
            Please copy host url, user token and use below format to invoke the
            model service
            <br />
            {`curl -X POST <host_url> -H 'Content-Type: application/json' -d '<model payload>'`}
          </Typography>
        </Popover>
        <Box sx={{ px: 2.5, pt: 1 }}>
          <FormControl size="small">
            <InputLabel>Deployment</InputLabel>
            <Select
              id="deployment"
              value={curDeployment?.id}
              label="Deployment"
              onChange={handleSelectChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 280,
                    maxWidth: 280,
                  },
                },
              }}
              sx={{ width: 280 }}
            >
              {deploymentList.map((item, index) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>

      <Box sx={{ mb: 2.5 }} />

      <Box key="API_Call" sx={{ height: "100%" }}>
        <TesterRequestApiCall
          id={runtimeId}
          curDeployment={curDeployment}
          loadingResponse={loadingResponse}
          onSetResponses={onSetResponses}
          onHandleReponse={handleReponse}
          onSetLoadingResponse={onSetLoadingResponse}
        />
      </Box>
    </Card>
  );
}

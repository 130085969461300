import { Icon } from "@iconify/react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { Box, Card, Stack, Typography } from "@mui/material";
import { alpha, useTheme, styled } from "@mui/material/styles";
import trendingUpFill from "@iconify/icons-eva/trending-up-fill";
import trendingDownFill from "@iconify/icons-eva/trending-down-fill";
import { isNil } from "lodash";
import { fNumber } from "@/utils/project/formatNumber";

const IconWrapperStyle = styled("div")(({ theme }) => ({
  width: 24,
  height: 24,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16),
}));

interface IStatisticPanelProps {
  panelInfo: Record<string, any>;
}

const StatisticPanel = ({ panelInfo }: IStatisticPanelProps) => {
  const theme = useTheme();

  const chartOptions: ApexOptions = {
    colors: [theme.palette.primary.main],
    chart: { sparkline: { enabled: true } },
    plotOptions: { bar: { columnWidth: "68%", borderRadius: 2 } },
    tooltip: {
      x: { show: false },
      y: {
        title: {
          formatter: (seriesName: number | string) => "",
        },
      },
      marker: { show: false },
    },
    labels: panelInfo.labels,
  };

  return (
    <Card
      sx={{ alignItems: "center", width: "33%", px: 3, py: 2, height: "134px" }}
    >
      <Typography variant="subtitle1">{panelInfo.title}</Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" sx={{ mt: 2 }}>
          {fNumber(panelInfo.total) || 0}
        </Typography>
        {!isNil(panelInfo.total) && (
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box sx={{ mt: 0 }}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ mt: 0.5, mb: 1 }}
              >
                <IconWrapperStyle
                  sx={{
                    color:
                      panelInfo.trend === "up" ? "success.main" : "error.main",
                    bgcolor:
                      panelInfo.trend === "up"
                        ? alpha(theme.palette.success.main, 0.16)
                        : alpha(theme.palette.error.main, 0.16),
                  }}
                >
                  <Icon
                    width={16}
                    height={16}
                    icon={
                      panelInfo.trend === "up"
                        ? trendingUpFill
                        : trendingDownFill
                    }
                  />
                </IconWrapperStyle>
                <Typography component="span" variant="subtitle2">
                  {`${panelInfo.percent?.toFixed(2)}%`}
                </Typography>
              </Stack>
            </Box>

            {panelInfo.chartData && (
              <ReactApexChart
                type="bar"
                series={[{ data: panelInfo.chartData }]}
                options={chartOptions}
                width={60}
                height={36}
              />
            )}
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

export default StatisticPanel;

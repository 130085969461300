/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import Page from "@/components/project/Page";
import { PATH_DASHBOARD } from "@/routes/paths";
import { useLocation, useParams } from "react-router";
import FeatureSetCreateOrEditForm from "./components/featureSetCreateOrEdit/FeatureSetCreateOrEditForm";
import { useSelector, RootState, dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import {
  getFeatureSetDescribe,
  getTagList,
  getFeatureSetList,
} from "@/redux/project/dataAsset/featureStore/thunks";

const FeatureSetCreateOrEdit = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { featureId } = useParams();
  const {
    featureSetDescribeDatas: { data: featuresetDescribe },
  } = useSelector((state: RootState) => state.featureStore);
  const {
    userDetail: { id, user_id },
  } = useSelector((state: RootState) => state.common);

  const isEdit = pathname.includes("featureSetEdit");

  const getLinks = () => {
    return [
      {
        name: "Data Asset",
      },
      {
        name: "Feature Set",
        href: PATH_DASHBOARD.dataAsset.featureStore,
      },
      ...(isEdit
        ? [
            {
              name: featuresetDescribe?.name || "",
              href: `${PATH_DASHBOARD.dataAsset.featureStore}/${featureId}`,
            },
          ]
        : []),
      {
        name: `${isEdit ? "Edit" : "Create"} Feature Set` || "",
      },
    ];
  };

  useEffect(() => {
    // get feature set、tags
    if (isEdit) {
      dispatch(
        getFeatureSetDescribe({
          id: featureId,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
      dispatch(
        getTagList({
          params: { page_num: 1, page_size: 9999, is_active: 1 },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      );
    }
    // get all feature set list
    dispatch(
      getFeatureSetList({
        jwt_id: id,
        jwt_user_id: user_id,
        params: { page_num: 1, page_size: 9999 },
        isList: true,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  }, []);

  return (
    <Page
      title={`${`${isEdit ? "Edit" : "Create"} Feature Set`} | OmniML`}
      heading={`${isEdit ? "Edit" : "Create"} Feature Set`}
      links={getLinks()}
    >
      <FeatureSetCreateOrEditForm />
    </Page>
  );
};

export default FeatureSetCreateOrEdit;

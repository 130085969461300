import { useState } from "react";
import { Icon } from "@iconify/react";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import { Box, Card, Grid, Divider } from "@mui/material";
import { CollapseButtonStyle } from "../../../../pipeline-construction/SettingSidebar";
import GraphToolbar from "./components/GraphToolbar";
import ExecutionGraphViewer from "./components/GraphViewer";
import DurationTimeBar from "./components/DurationTimeBar";

export default function DetailGraph() {
  const [showGraph, setShowGraph] = useState<boolean>(false);

  const handleUpdateGraphWidth = (x: number) => {
    const container = document.querySelector(".pipeline-execution");
    if (container) {
      return container.clientWidth * 0.667 - 52;
    }
    return x * 0.667 - 52;
  };

  const handleUpdateGraphHeight = (x: number) => {
    // return x * 0.5;

    return 400;
  };

  return (
    <Box sx={{ height: showGraph ? 456 : 56, mt: 2 }}>
      <CollapseButtonStyle
        disableRipple
        color="inherit"
        onClick={() => setShowGraph(!showGraph)}
        startIcon={
          <Icon
            icon={showGraph ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
        sx={{ "&:hover": { backgroundColor: "transparent" } }}
      >
        Execution graph
      </CollapseButtonStyle>

      <Box sx={{ mx: 2 }}>
        {showGraph && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Card
                sx={{
                  bgcolor: "background.neutral",
                  height: showGraph ? 400 : 0,
                }}
              >
                <GraphToolbar />
                <ExecutionGraphViewer
                  containerName="pipeline-execution"
                  onChangeWidth={handleUpdateGraphWidth}
                  onChangeHeight={handleUpdateGraphHeight}
                />
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <DurationTimeBar sx={{ bgcolor: "background.neutral" }} />
            </Grid>
          </Grid>
        )}
        {!showGraph && <Divider />}
      </Box>
    </Box>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useSelector } from "react-redux";
import { Stack, Collapse, MenuItem, Typography } from "@mui/material";
import { CollapseButtonStyle } from "../../../../SettingSidebar";
import { useEffect, useState } from "react";
import { MlPipelineTextfield } from "../../../../components/PipelineComponents";
import { updateOneKeyofStep } from "@/pages/project/automation/MlPipeline/graph/slices/slice";
import { FeatureSetDownloadStep } from "@/@types/project/mlPipeline/Kubeflow/pipeline-featureset-download";
import { RootState, dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router";
import { getFeatureSetVersionList } from "@/redux/project/dataAsset/featureStore/thunks";

const JobArgument = ({
  currentStep,
  isEdit,
}: {
  currentStep: FeatureSetDownloadStep | null;
  isEdit: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const [showProcessor, setShowProcessor] = useState(true);

  const [fsVersionList, setFsVersionList] = useState<
    Array<{ id: string; name: string; external_id: string }>
  >([]);

  const {
    featureSetDatas: { data: { items: fsList = [] } = {} },
  } = useSelector((state: RootState) => state.featureStore);

  const initialValues = currentStep?.arguments || {
    fsId: "",
    fsVersion: "",
  };

  const ParamSchema = Yup.object().shape({
    fsId: Yup.string().required("Feature Set Name is required"),
    fsVersionId: Yup.string().required("Feature Set Version is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ParamSchema,
    onSubmit: () => {},
  });

  const submitFunc = (fieldName, value) => {
    try {
      dispatch(
        updateOneKeyofStep({
          key: "arguments",
          data: {
            [fieldName]: value,
          },
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const { setFieldValue, values } = formik;

  // same node type，but different node data
  useEffect(() => {
    formik.resetForm();
    formik.setValues(initialValues);
  }, [currentStep?.id]);

  useEffect(() => {
    if (values.fsId) {
      dispatch(
        getFeatureSetVersionList({
          params: {
            featureset_id: values.fsId,
            page_num: 1,
            page_size: 9999,
          },
          isList: true,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((r) => {
          setFsVersionList(r?.items || []);
        });
    }
  }, [values.fsId]);

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setShowProcessor((prev) => !prev)}
        endIcon={
          <Icon
            icon={showProcessor ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
      >
        Job Argument
      </CollapseButtonStyle>

      <Collapse in={showProcessor}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off">
            <Stack spacing={2} sx={{ mx: 2, my: 1, mb: 1.5 }}>
              <MlPipelineTextfield
                select
                label={"Feature Set Name"}
                field={"fsId"}
                disabled={isEdit}
                formik={formik}
                // submitThisValuefunc={(value) => submitFunc("fsId", value)}
                onChange={(e) => {
                  const curFsId = e.target.value;
                  setFieldValue("fsId", curFsId);
                  submitFunc("fsId", curFsId);

                  setFieldValue("fsVersionId", "");
                  submitFunc("fsVersionId", "");
                  submitFunc("fsVersionExternalId", "");
                }}
              >
                {fsList.map((item) => (
                  <MenuItem key={item.id} value={item.id} title={item.name}>
                    <Typography noWrap textOverflow="ellipsis">
                      {item.name}
                    </Typography>
                  </MenuItem>
                ))}
              </MlPipelineTextfield>
              <MlPipelineTextfield
                select
                label={"Feature Set Version"}
                field={"fsVersionId"}
                disabled={isEdit || !values.fsId}
                formik={formik}
                submitThisValuefunc={(value) => {
                  submitFunc("fsVersionId", value);
                  submitFunc(
                    "fsVersionExternalId",
                    fsVersionList.find((i) => i.id === value)?.external_id || ""
                  );
                }}
              >
                {fsVersionList.map((item) => (
                  <MenuItem key={item.id} value={item.id} title={item.name}>
                    <Typography noWrap textOverflow="ellipsis">
                      {item.name}
                    </Typography>
                  </MenuItem>
                ))}
              </MlPipelineTextfield>
            </Stack>
          </Form>
        </FormikProvider>
      </Collapse>
    </>
  );
};

export default JobArgument;

/* eslint-disable react-hooks/exhaustive-deps */
import { Form, useFormik, FormikProvider } from "formik";
import { Box, Button, Stack } from "@mui/material";
import * as Yup from "yup";
import MetaData from "./partComponents/MetaData";
import Objective from "./partComponents/Objective";
import TrailThresholds from "./partComponents/TrailThresholds";
import SearchAlgorithm from "./partComponents/SearchAlgorithm";
import EarlyStopping from "./partComponents/EarlyStopping";
import HyperParameters from "./partComponents/HyperParameters";
import MetricsCollector from "./partComponents/MetricsCollector";
import TrialTemplate from "./partComponents/TrialTemplate";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "@/redux/store";
import { isEmpty } from "lodash";
import { LoadingButton } from "@mui/lab";
import { resetNotebookData } from "@/redux/project/experiment/slice";
import { PATH_DASHBOARD } from "@/routes/paths";
import {
  addHptuner,
  getHptunerTableData,
} from "@/redux/project/experiment/thunks";
import AdvancedSettings from "./partComponents/AdvancedSettings";
import {
  generateAdvancedSettings,
  generateAlgorithm,
  generateEarlyStopping,
  generateMetricsCollector,
  generateObjective,
  generateParameters,
  generateTrailThresholds,
  generateTrialTemplate,
} from "./generators";
import { uuidv7 } from "uuidv7";
import {
  parseAdvancedSettings,
  parseAlgorithm,
  parseEarlyStopping,
  parseMetricsCollector,
  parseObjective,
  parseParameters,
  parseTrailThresholds,
  parseTrialTemplate,
} from "./parsers";

export interface IKubeflowHpTunerFormValues {
  metaData: {
    name: string;
    clusterNames: string[];
  };
  trailThresholds: {
    parallelTrial: string;
    resumePolicy: string;
    maxTrails: string;
    maxFailedTrails: string;
  };
  objective: {
    type: string;
    metric: string;
    goal: string;
    additionalMetrics: Array<{ metricName: string }>;
    isSetMetricStrategies: boolean;
    metricStrategies: Array<{ metricName: string; strategy: string }>;
  };
  searchAlgorithm: {
    name: string;
    settings: {
      randomState?: string;
      baseEstimator?: string;
      riInitialPoints?: string;
      acqFunc?: string;
      acqOptimizer?: string;
      random_state?: string;
    };
  };
  earlyStopping: {
    name: string;
    settings: {
      minTrialsRequired?: string;
      startStep?: string;
    };
  };
  hyperParameters: Array<Record<string, any>>;
  metricsCollector: {
    kind: string;
    metricsFormat: string;
    settings: {
      metricFile?: string;
    };
  };
  trialTemplate: {
    primaryContainerName: string;
    trialParameters: any[];
    settings: {
      sourceType: string;
      content: string;
    };
  };
  advancedSettings: {
    billing: string;
    provider: string;
    region: string;
    node_type: string;
    node_linux_arch: string;
  };
}

const initialValues: IKubeflowHpTunerFormValues = {
  metaData: {
    name: "",
    clusterNames: ["default-k8s"],
  },
  trailThresholds: {
    parallelTrial: "1",
    resumePolicy: "Never",
    maxTrails: "3",
    maxFailedTrails: "1",
  },
  objective: {
    type: "maximize",
    metric: "",
    goal: "0.99",
    additionalMetrics: [],
    isSetMetricStrategies: false,
    metricStrategies: [],
  },
  searchAlgorithm: {
    name: "random",
    settings: {},
  },
  earlyStopping: {
    name: "",
    settings: {},
  },
  hyperParameters: [],
  metricsCollector: {
    kind: "Stdout",
    metricsFormat: "",
    settings: {},
  },
  trialTemplate: {
    primaryContainerName: "",
    trialParameters: [],
    settings: {
      sourceType: "YAML",
      content: "",
    },
  },
  advancedSettings: {
    billing: "",
    provider: "",
    region: "",
    node_type: "",
    node_linux_arch: "",
  },
};

const KubeflowForm = () => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const isDuplicate = pathname.includes("hpTunerDuplicate");

  const {
    userDetail: { project_id, project_list },
  } = useSelector((state: RootState) => state.common);

  const {
    hptunerDetail: { data },
    hptunerTableList: { data: allHptData = {} },
  } = useSelector((state: RootState) => state.experiment);

  const validationSchema = Yup.object({
    metaData: Yup.object({
      name: Yup.string()
        .required("Name is required")
        .max(64, "Description cannot be longer than 64 characters")
        .test("name-exist", "Hptuner Name existed", (value) => {
          const isExisted =
            allHptData?.items?.findIndex((item) => item.name === value) > -1;
          return !isExisted;
        }),
      clusterNames: Yup.array()
        .of(Yup.string())
        .min(1, "Kubernetes Cluster must contain at least one cluster"),
    }),
    trailThresholds: Yup.object({
      parallelTrial: Yup.string().required("Parallel Trial is required"),
      resumePolicy: Yup.string().required("Resume Policy is required"),
      maxTrails: Yup.string().required("Max Trails Policy is required"),
      maxFailedTrails: Yup.string().required("Max Failed Trails is required"),
    }),
    objective: Yup.object({
      type: Yup.string().required("Type is required"),
      metric: Yup.string()
        .required("Metric is required")
        .max(64, "Metric cannot be longer than 64 characters")
        .test("unique-metric", "Metric name must be unique", function (value) {
          const additionalMetrics = this.parent.additionalMetrics || [];
          const metricNames = additionalMetrics.map((m) => m.metricName);
          return !metricNames.includes(value);
        }),
      goal: Yup.string()
        .required("Goal is required")
        .max(64, "Goal cannot be longer than 64 characters"),
      additionalMetrics: Yup.array().of(
        Yup.object().shape({
          metricName: Yup.string()
            .required("Metric Name is required")
            .max(64, "Metric Name cannot be longer than 64 characters")
            .test(
              "unique-additional-metric",
              "Metric Name must be unique",
              function (value) {
                const tThis = this as any;
                const { additionalMetrics, metric } = tThis.from[1].value;

                let metricNames = additionalMetrics.map(
                  (item) => item.metricName
                );
                const idx = metricNames.findIndex((i) => i === value);
                metricNames.splice(idx, 1);

                if (metricNames.includes(value)) {
                  return false;
                }

                if (metric === value) {
                  return false;
                }

                return true;
              }
            ),
        })
      ),
      // metricStrategies
    }),
    searchAlgorithm: Yup.object({
      name: Yup.string().required("Name is required"),
      /* settings: Yup.lazy((value, context) => {
        const { name } = context.parent;
        
        if (!name || (name === 'Grid')) {
          return Yup.mixed().notRequired();
        }
  
        switch (name) {
          case 'random':
            return Yup.object().shape({
              randomState: Yup.string().required('Random State is required for Random'),
            });
          default:
            return Yup.object().shape({
              baseEstimator: Yup.string().required('Base Estimator is required for Bayesian Optimization'),
              riInitialPoints: Yup.string().required('Ri Initial Points is required for Bayesian Optimization'),
              acqFunc: Yup.string().required('Acq Func is required for Bayesian Optimization'),
              acqOptimizer: Yup.string().required('Acq Optimizer is required for Bayesian Optimization'),
              random_state: Yup.string().required('Random State is required for Bayesian Optimization'),
            });
        }
      }), */
    }),
    /* earlyStopping:  Yup.object({
      name: Yup.string().required('Name is required'),
      settings: Yup.lazy((value, context) => {
        const { name } = context.parent;
        
        if (name === 'none') {
          return Yup.mixed().notRequired();
        }
        
        return Yup.object().shape({
          minTrialsRequired: Yup.string().required('Min Trials Required is required'),
          startStep: Yup.string().required('Start Step is required'),
        });
      }),
    }), */
    hyperParameters: Yup.array().min(1, "Hyper Parameters is required"),
    metricsCollector: Yup.object({
      kind: Yup.string().required("Kind is required"),
      // settings: {},
      metricsFormat: Yup.string().required("Metrics Format is required"),
    }),
    trialTemplate: Yup.object({
      primaryContainerName: Yup.string().required(
        "Primary Container Name is required"
      ),
      trialParameters: Yup.array().min(1, "Trial Parameters is required"),
      settings: Yup.object({
        sourceType: Yup.string().required("Source Type is required"),
        content: Yup.string().required("YAML is required"),
      }),
    }),
  });

  const handleCreateSubmit = (values: IKubeflowHpTunerFormValues) => {
    // get organization name
    // const org_name = project_list?.[project_id]?.[1];
    const org_name = project_list.find(
      (item) => item.id === project_id
    ).organization_name;

    const hpt_config = {
      // trailThresholds
      trailThresholds: generateTrailThresholds(values.trailThresholds),

      // objective
      objective: generateObjective(values.objective),

      // searchAlgorithm
      algorithm: generateAlgorithm(values.searchAlgorithm),

      // earlyStopping
      ...(values.earlyStopping.name !== "none" && values.earlyStopping.name
        ? {
            earlyStopping: generateEarlyStopping(values.earlyStopping),
          }
        : {}),

      // hyperParameters
      parameters: generateParameters(values.hyperParameters),

      // metricsCollector
      ...(values.metricsCollector.kind !== "None"
        ? {
            metricsCollectorSpec: generateMetricsCollector(
              values.metricsCollector
            ),
          }
        : {}),

      // trialTemplate
      trialTemplate: generateTrialTemplate(values.trialTemplate),
    };

    const config = {
      env: {
        cluster_names: values.metaData.clusterNames,
      },
      node_selector: generateAdvancedSettings(
        org_name,
        values.advancedSettings
      ),
      hpt_config,
    };
    console.log("hptuner_config", config);

    return dispatch(
      addHptuner({
        params: {
          name: values.metaData.name,
          hptuner_type: "kubeflow",
          resource_config: config,
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      try {
        await handleCreateSubmit(values);
        enqueueSnackbar("Create Success", { variant: "success" });
        actions.setSubmitting(false);

        handleClose();
      } catch (e) {
        console.log("error-in-submit", e);
        actions.setSubmitting(false);
      }
    },
  });

  const {
    values,
    // touched,
    // errors,
    isSubmitting,
    resetForm,
    setFieldValue,
    setValues,
  } = formik;
  console.log("values", values);
  // console.log('touched', touched);
  // console.log('errors', errors);

  const handleClose = () => {
    resetForm();
    dispatch(resetNotebookData("hptunerDetail"));
    navigate(PATH_DASHBOARD.experiment.hpTuner);
  };

  useEffect(() => {
    if (isDuplicate && !isEmpty(data)) {
      const {
        name,
        resource_config: {
          env = {},
          node_selector = {},
          hpt_config: {
            trailThresholds = {},
            objective = {},
            algorithm = {},
            earlyStopping = {},
            parameters = [],
            metricsCollectorSpec = {},
            trialTemplate = {},
          } = {},
        } = {},
      } = data;
      console.log("data", data);

      setValues({
        metaData: {
          name: `${name}-${uuidv7()}`,
          clusterNames: env?.cluster_names || [],
        },
        trailThresholds: parseTrailThresholds(trailThresholds),
        objective: parseObjective(objective),
        searchAlgorithm: parseAlgorithm(algorithm),
        earlyStopping: earlyStopping
          ? {
              name: "",
              settings: {},
            }
          : parseEarlyStopping(earlyStopping),
        hyperParameters: parseParameters(parameters),
        metricsCollector: parseMetricsCollector(metricsCollectorSpec),
        trialTemplate: parseTrialTemplate(trialTemplate),
        advancedSettings: parseAdvancedSettings(node_selector),
      });
    }
  }, [data]);

  useEffect(() => {
    // set create default value
    if (!isDuplicate) {
      setFieldValue("metaData.name", `omniml-hpt-${new Date().getTime()}`);

      setFieldValue("advancedSettings", {
        billing: "elastic",
        provider: "aws",
        region: "beijing",
        node_type: "cpu",
        node_linux_arch: "x86",
      });
    }
  }, []);

  useEffect(() => {
    dispatch(
      getHptunerTableData({
        params: {
          page_num: 1,
          page_size: 9999,
        },
        isAll: true,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box sx={{ pb: 2, width: "800px" }}>
          <MetaData formik={formik} />
          <AdvancedSettings formik={formik} />
          <TrailThresholds formik={formik} />
          <Objective formik={formik} />
          <SearchAlgorithm formik={formik} />
          <EarlyStopping formik={formik} />
          <HyperParameters formik={formik} />
          <MetricsCollector formik={formik} />
          <TrialTemplate formik={formik} />
        </Box>
        <Stack direction="row" justifyContent="center" spacing={2}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            color="primary"
            sx={{ width: "200px", color: "background.paper" }}
          >
            Save
          </LoadingButton>
          <Button
            variant="outlined"
            color="inherit"
            sx={{ width: "200px", color: "text.secondary" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default KubeflowForm;

/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik, FormikProvider, Form, Field } from "formik";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  Stack,
  Grid,
  Box,
  Typography,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import * as yup from "yup";
import Scrollbar from "@/components/project/Scrollbar";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { RootState, dispatch } from "@/redux/store";
import {
  addNotebookVolume,
  editNotebookVolume,
  getNotebookVolumeDetailData,
} from "@/redux/project/experiment/thunks";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { resetNotebookData } from "@/redux/project/experiment/slice";
import { LoadingButton } from "@mui/lab";
import MlNumberInputField from "@/components/project/mlComponents/MlNumberInputField";
import { getNotebookVolumeTableList } from "@/services/experiment";
import {
  getInitResourceQuota,
  queryRestResource,
  restResource,
} from "@/utils/project/resourceRequest";
import { IconButton } from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

interface INotebookVolumeCreateOrEditDialog {
  row: Record<any, any>;
  mode: "create" | "edit";
  isOpen: boolean;
  onClose: VoidFunction;
  handleRefresh: (a: boolean) => void;
}

interface FormValues {
  type: string;
  cluster: string[];
  name: string;
  sizeInGi: string;
  storageClass: string;
  accessMode: string;
}

const initialValues: FormValues = {
  type: "Kubernetes Persistent Volume",
  cluster: ["kubeflow"],
  name: "",
  sizeInGi: "10",
  storageClass: "gp3",
  accessMode: "ReadWriteOnce",
};

const NotebookVolumeCreateOrEditDialog = ({
  mode,
  isOpen,
  onClose,
  row,
  handleRefresh,
}: INotebookVolumeCreateOrEditDialog) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const [allVolumeData, setAllVolumeData] = useState<any>([]);

  const {
    config: { notebook },
    userDetail: { project_id },
  } = useSelector((state: RootState) => state.common);
  const { access_mode: accessModeList } = notebook;

  const {
    notebookVolumeDetail: { data },
  } = useSelector((state: RootState) => state.experiment);

  // resourceQuota
  const [resourceQuota, setResourceQuota] = useState<Record<string, any>>({});

  const validationSchema = yup.object({
    type: yup.string().required("Type is required"),
    name: yup
      .string()
      .required("Name is required")
      .max(64, "Name cannot be longer than 64 characters")
      .test("name-exist", "Notebook volume Name existed", (value) => {
        if (mode === "create") {
          const isExisted =
            allVolumeData?.items?.findIndex((item) => item.name === value) > -1;
          return !isExisted;
        } else {
          return true;
        }
      }),
    sizeInGi: yup
      .number()
      .typeError("Size In Gi must be number type")
      .integer("Size In Gi must be integer")
      .required("Size In Gi is required")
      .min(1, "Size In Gi number must be between 1 and 1000")
      .max(1000, "Size In Gi number must be between 1 and 1000"),
    storageClass: yup.string().required("Storage Class is required"),
    accessMode: yup.string().required("Access Mode is required"),
  });

  const handleCreateSubmit = (values) => {
    const config = {
      env: {
        type: "Kubernetes",
        cluster_names: values.cluster,
      },
      notebook_volume: {
        name: values.name,
        type: values.type,
        size: `${values.sizeInGi}Gi`,
        access_model: values.accessMode,
        storage_class: values.storageClass,
      },
    };
    return dispatch(
      addNotebookVolume({
        params: {
          name: values.name,
          volume_type: values.type,
          resource_config: config,
          is_active: 1,
          status: "wait_start",
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();
  };

  const handleUpdateSubmit = (values) => {
    const config = {
      env: {
        type: "Kubernetes",
        cluster_names: values.cluster,
      },
      notebook_volume: {
        name: values.name,
        type: values.type,
        size: `${values.sizeInGi}Gi`,
        access_model: values.accessMode,
        storage_class: values.storageClass,
      },
    };
    return dispatch(
      editNotebookVolume({
        id: row.id,
        params: {
          resource_config: config,
          status: "wait_start",
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      try {
        if (mode === "edit") {
          await handleUpdateSubmit(values);
          enqueueSnackbar("Edit Success", { variant: "success" });
        } else {
          await handleCreateSubmit(values);
          enqueueSnackbar("Create Success", { variant: "success" });
        }
        actions.setSubmitting(false);

        handleRefresh(true);
        handleClose();
      } catch (e) {
        console.log("error-in-submit", e);
        actions.setSubmitting(false);
      }
    },
  });

  const { errors, touched, setValues, isSubmitting, resetForm } = formik;

  const handleClose = () => {
    onClose();
    resetForm();
    dispatch(resetNotebookData("notebookVolumeDetail"));
  };

  const handleInit = async () => {
    try {
      await dispatch(
        getNotebookVolumeDetailData({
          id: row.id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
    } catch (e) {}
  };

  const resourceHelper = (type) => {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body2">
          {`${resourceQuota[type]?.used || 0}/${
            resourceQuota[type]?.all || 0
          } used/hard`}
        </Typography>
        <Tooltip title="Refresh to query instant resource">
          <IconButton
            size="small"
            sx={{ height: "18px", width: "18px" }}
            onClick={() => getInitResourceQuota(project_id, setResourceQuota)}
          >
            <RefreshOutlinedIcon
              sx={{ color: (theme) => theme.palette.grey[500] }}
            />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  useEffect(() => {
    if (isOpen && mode === "edit") {
      handleInit();
    }
    if (isOpen) {
      getInitResourceQuota(project_id, setResourceQuota);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isEmpty(data) && mode === "edit") {
      const { resource_config: { env = {}, notebook_volume = {} } = {} } = data;
      setValues({
        // type: data?.volume_type || '',
        type: "Kubernetes Persistent Volume",
        cluster: env?.cluster_names || ["default-k8s"],
        name: data?.name || "",
        sizeInGi: notebook_volume?.size.replace(/Gi/gi, "") || "",
        storageClass: notebook_volume?.storage_class || "",
        accessMode: notebook_volume?.access_model || "",
      });
    }
  }, [data]);

  useEffect(() => {
    if (isOpen) {
      getNotebookVolumeTableList(
        {
          page_num: 1,
          page_size: 9999,
        },
        true
      ).then((res) => {
        const { data: { result = {} } = {} } = res;
        setAllVolumeData(result?.items || []);
      });
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown
      onClose={handleClose} // It is not triggered when the dialog box is closed（just click backdrop or escapeKeyDown）
      PaperProps={{
        style: { width: "960px", maxWidth: "none", padding: "20px 40px" },
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        {mode === "create" ? "New" : "Edit"} Volume
      </Typography>
      <FormikProvider value={formik}>
        <Form>
          <Scrollbar sx={{ maxHeight: "600px" }}>
            <Box sx={{ px: 2.5 }}>
              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={touched.type && Boolean(errors.type)}
              >
                <InputLabel>Type</InputLabel>
                <Field
                  as={Select}
                  disabled={mode === "edit"}
                  name="type"
                  label="Type"
                >
                  <MenuItem value="Kubernetes Persistent Volume">
                    Kubernetes Persistent Volume
                  </MenuItem>
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {touched.type && errors.type ? errors.type : ""}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={touched.cluster && Boolean(errors.cluster)}
              >
                <InputLabel>Cluster Name</InputLabel>
                <Field
                  as={Select}
                  disabled={mode === "edit"}
                  name="cluster"
                  multiple
                  label="Cluster Name"
                >
                  <MenuItem value="kubeflow">kubeflow</MenuItem>
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {touched.cluster && errors.cluster ? errors.cluster : ""}
                </FormHelperText>
              </FormControl>
              <Field
                as={TextField}
                name="name"
                disabled={mode === "edit"}
                size="small"
                label="Name"
                fullWidth
                margin="dense"
                helperText={touched.name && errors.name ? errors.name : " "}
                error={touched.name && Boolean(errors.name)}
                FormHelperTextProps={{
                  sx: { minHeight: "18px", marginTop: "2px" },
                }}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <MlNumberInputField
                    name="sizeInGi"
                    label="Size In Gi"
                    formik={formik}
                    helperText={
                      (touched?.sizeInGi && errors?.sizeInGi) ||
                      resourceHelper("storage")
                    }
                    isDouble
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl
                    fullWidth
                    margin="dense"
                    size="small"
                    error={touched.storageClass && Boolean(errors.storageClass)}
                  >
                    <InputLabel>Storage Class</InputLabel>
                    <Field
                      as={Select}
                      name="storageClass"
                      label="Storage Class"
                    >
                      <MenuItem value="gp3">gp3</MenuItem>
                    </Field>
                    <FormHelperText
                      sx={{ minHeight: "18px", marginTop: "2px" }}
                    >
                      {touched.storageClass && errors.storageClass
                        ? errors.storageClass
                        : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={touched.accessMode && Boolean(errors.accessMode)}
              >
                <InputLabel>Access Mode</InputLabel>
                <Field as={Select} name="accessMode" label="Access Mode">
                  {accessModeList.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {touched.accessMode && errors.accessMode
                    ? errors.accessMode
                    : ""}
                </FormHelperText>
              </FormControl>
            </Box>
          </Scrollbar>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ my: 4 }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              color="primary"
              sx={{ width: "200px", color: "background.paper" }}
            >
              Save
            </LoadingButton>
            <Button
              type="button"
              color="inherit"
              variant="outlined"
              onClick={handleClose}
              sx={{ width: "200px", color: "text.secondary" }}
            >
              Cancel
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default NotebookVolumeCreateOrEditDialog;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSnackbar } from "notistack";
import { Form, Field, useFormik, FormikProvider } from "formik";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Stack,
  Button,
  FormHelperText,
  Autocomplete,
} from "@mui/material";
import MlCard from "@/components/project/mlComponents/MlCard";
import { useSelector, RootState, dispatch } from "@/redux/store";
import {
  getFeatureSetTagList,
  getTagList,
  addTag,
} from "@/redux/project/dataAsset/featureStore/thunks";

interface StepProps {
  setActiveStep: Function;
  setBasicInfo: Function;
  setTags: Function;
}

interface FormValues {
  name: string;
  featureset_type: string;
  deliver_to: string;
  tags: string[];
  description: string;
}

const BasicStep = ({ setActiveStep, setBasicInfo, setTags }: StepProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { featureId } = useParams();
  // const allExistTags = useRef<any[]>([]);
  const {
    tagDatas: {
      data: { items: tagList = [] },
    },
    featureSetDescribeDatas: { data: featuresetDescribe },
    featureSetDatas: {
      data: { items: featuresetList = [] },
    },
  } = useSelector((state: RootState) => state.featureStore);

  const isEdit = pathname.includes("featureSetEdit");

  const initialValues: FormValues = {
    name: "",
    featureset_type: "",
    deliver_to: "",
    description: "",
    tags: [],
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Feature Set Name is required")
      .max(64, "Description cannot be longer than 64 characters")
      .test("name-exist", "Feature Set Name existed", (value) => {
        if (!isEdit) {
          const isExisted =
            featuresetList.findIndex((item) => item.name === value) > -1;
          return !isExisted;
        } else {
          return true;
        }
      }),
    featureset_type: Yup.string().required("Feature Set Type is required"),
    deliver_to: Yup.string().required("Deliver To is required"),
  });

  useEffect(() => {
    if (isEdit) {
      dispatch(
        getFeatureSetTagList({
          params: {
            featureset_id: featureId,
            page_num: 1,
            page_size: 9999,
            is_active: 1,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((res) => {
          setFieldValue(
            "tags",
            res.items.map((item) => item.tag_id)
          );
          setTags(res?.items);
        });
    }
    dispatch(
      getTagList({
        params: {
          is_active: 1,
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  }, []);

  useEffect(() => {
    if (isEdit) {
      setFieldValue("name", featuresetDescribe?.name);
      setFieldValue("featureset_type", featuresetDescribe?.featureset_type);
      setFieldValue("deliver_to", featuresetDescribe?.delivered_to);
      setFieldValue("description", featuresetDescribe?.description);
    }
  }, [featuresetDescribe]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setBasicInfo(values);
    },
  });

  const handleCancel = () => {
    navigate(`/ui/dataAsset/featureStore`);
  };

  const { values, errors, touched, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form>
        <MlCard title={"Basic Information"} sx={{ mt: 1.5 }}>
          <Box sx={{ pb: 2, width: "800px" }}>
            <Field
              as={TextField}
              name="name"
              label="Feature Set Name"
              size="small"
              fullWidth
              margin="dense"
              helperText={touched.name && errors.name ? errors.name : " "}
              error={touched.name && Boolean(errors.name)}
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
            <FormControl
              fullWidth
              margin="dense"
              size="small"
              error={touched.featureset_type && Boolean(errors.featureset_type)}
            >
              <InputLabel>Feature Set Type</InputLabel>
              <Field
                as={Select}
                size="small"
                name="featureset_type"
                label="Feature Set Type"
              >
                <MenuItem value="INTERNAL">
                  Data will be synchronized before use
                </MenuItem>
                <MenuItem value="EXTERNAL">
                  Data will NOT be synchronized before use
                </MenuItem>
              </Field>
              <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                {touched.featureset_type && errors.featureset_type
                  ? errors.featureset_type
                  : " "}
              </FormHelperText>
            </FormControl>
            <Field
              as={TextField}
              name="deliver_to"
              label="Deliver To"
              size="small"
              fullWidth
              margin="dense"
              helperText={
                touched.deliver_to && errors.deliver_to
                  ? errors.deliver_to
                  : " "
              }
              error={touched.deliver_to && Boolean(errors.deliver_to)}
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
            <Field
              as={TextField}
              multiline
              rows={4}
              name="description"
              label="Description (Optional)"
              size="small"
              fullWidth
              margin="dense"
              helperText=" "
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
            <Autocomplete
              size="small"
              multiple
              disableCloseOnSelect
              disablePortal
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              limitTags={8}
              getOptionLabel={(option) =>
                typeof option === "object" ? option.name : option
              }
              isOptionEqualToValue={(option, value) => {
                if (typeof option === "object" && typeof value === "object") {
                  return option.id === value.id;
                } else if (
                  typeof option === "string" ||
                  typeof value === "string"
                ) {
                  return option === value;
                }
                return false;
              }}
              options={tagList}
              value={values.tags.map(
                (tagId) => tagList.find((tag) => tag.id === tagId) || tagId
              )}
              onChange={(_, newValues) => {
                const filteredValues = newValues.filter(
                  (item) => typeof item === "string"
                );

                if (tagList.find((i) => i.name === filteredValues[0])) {
                  enqueueSnackbar(`Tag ${filteredValues[0]} existed`, {
                    variant: "warning",
                  });
                  return;
                }

                const updatedTagsPromises = newValues.map(async (item) => {
                  if (typeof item === "string") {
                    // new text ,add a new tag
                    const res = await dispatch(
                      addTag({
                        params: {
                          is_active: 1,
                          name: item,
                        },
                        alertCallback: enqueueSnackbar,
                        pathname,
                      })
                    ).unwrap();

                    enqueueSnackbar(`Add Tag ${item} Success`, {
                      variant: "success",
                    });

                    await dispatch(
                      getTagList({
                        params: {
                          is_active: 1,
                          page_num: 1,
                          page_size: 9999,
                        },
                        alertCallback: enqueueSnackbar,
                        pathname,
                      })
                    );

                    return res.id;
                  }
                  return item.id;
                });

                Promise.all(updatedTagsPromises).then((r) => {
                  setFieldValue("tags", r);
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="tags"
                  label="Tags (Optional)"
                  helperText=" "
                  FormHelperTextProps={{
                    style: {
                      minHeight: "18px",
                      marginTop: "2px",
                    },
                  }}
                />
              )}
              sx={{ mt: 1, mb: 0.5 }}
            />
          </Box>
        </MlCard>

        <Stack
          spacing={2}
          direction="row"
          justifyContent="center"
          sx={{ my: 4 }}
        >
          <Button
            type="button"
            color="inherit"
            variant="outlined"
            sx={{ width: "200px", color: "text.secondary" }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{ width: "200px", color: "background.paper" }}
          >
            Next
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default BasicStep;

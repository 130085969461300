/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import * as Yup from "yup";
import { Form, Field, useFormik, FormikProvider } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Grid,
  Stack,
  FormHelperText,
  Switch,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import MlCard from "@/components/project/mlComponents/MlCard";
import { useSelector, RootState, dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import {
  addSynchronization,
  addSynchronizationJob,
  editSynchronization,
  getSynchronizationList,
  getSynchronizationJobList,
  editSynchronizationJob,
} from "@/redux/project/dataAsset/featureStore/thunks";

interface SynchronizationProps {
  basicInfo: Record<string, any>;
  storageInfo: Record<string, any>;
  featuresetRes: Record<string, any>;
}

interface FormValues {
  source_connection_id: string;
  source_path: string;
  source_type: string;
  source_credential_id: string;
  target_credential_id: string;
  minutes: string;
  hours: string;
  day: string;
  month: string;
  week: string;
}

const SynchronizationStep = ({
  basicInfo,
  storageInfo,
  featuresetRes,
}: SynchronizationProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const [curConnection, setCurConnection] = useState<Record<string, any>>({});
  const [enableSync, setEnableSync] = useState<boolean>(false);
  const [runImmediately, setRunImmediately] = useState<boolean>(false);
  const [runSynchronization, setRunSynchronization] = useState<boolean>(false);
  const {
    featureSetDescribeDatas: { data: featuresetDescribe },
    connectionListDatas: {
      data: { items: connectionList = [] },
    },
    credentialDatas: {
      data: { items: credentialList = [] },
    },
    synchronizationDatas: {
      data: { items: synchronizationList = [] },
    },
    synchronizationJobDatas: {
      data: { items: synchronizationJobList = [] },
    },
  } = useSelector((state: RootState) => state.featureStore);

  const isEdit = pathname.includes("featureSetEdit");
  const pathFileRegex = new RegExp(`^(?:[/]?[a-zA-Z0-9_.=-]+)+[/]?$`);
  const pathTableRegex = new RegExp(
    `^(?:["][a-zA-Z0-9_]+["][.]|[a-zA-Z0-9_]+[.])?(?:["][a-zA-Z0-9_]+["]|[a-zA-Z0-9_]+)$`
  );

  const initialValues: FormValues = {
    source_connection_id: "",
    source_path: "",
    source_type: "",
    source_credential_id: "",
    target_credential_id: "",
    minutes: "",
    hours: "",
    day: "",
    month: "",
    week: "",
  };

  const validationSchema = Yup.object({
    source_connection_id: enableSync
      ? Yup.string().required("Source Connection is required")
      : Yup.string(),
    source_path: enableSync
      ? Yup.string()
          .test("path-valid", "Invalid Source Path", (value) => {
            if (value) {
              return pathFileRegex.test(value) || pathTableRegex.test(value);
            }
            return false;
          })
          .required("Source Path is required")
      : Yup.string(),
    source_type: enableSync
      ? Yup.string().required("Resource Type is required")
      : Yup.string(),
    source_credential_id: enableSync
      ? Yup.string().required("Secrets is required")
      : Yup.string(),
    minutes:
      enableSync && runSynchronization
        ? Yup.number()
            .typeError("Minutes must be number type")
            .integer("Minutes must be integer")
            .min(1, "Minutes number must be between 1 and 60")
            .max(60, "Minutes number must be between 1 and 60")
        : Yup.string(),
    hours:
      enableSync && runSynchronization
        ? Yup.number()
            .typeError("Hours must be number type")
            .integer("Hours must be integer")
            .min(1, "Hours number must be between 1 and 24")
            .max(24, "Hours number must be between 1 and 24")
        : Yup.string(),
    day:
      enableSync && runSynchronization
        ? Yup.number()
            .typeError("Day of Month must be number type")
            .integer("Day of Month must be integer")
            .min(1, "Day of Month number must be between 1 and 31")
            .max(31, "Day of Month number must be between 1 and 31")
        : Yup.string(),
    month:
      enableSync && runSynchronization
        ? Yup.number()
            .typeError("Month must be number type")
            .integer("Month must be integer")
            .min(1, "Month number must be between 1 and 12")
            .max(12, "Month number must be between 1 and 12")
        : Yup.string(),
    week:
      enableSync && runSynchronization
        ? Yup.number()
            .typeError("Day of Week must be number type")
            .integer("Day of Week must be integer")
            .min(1, "Day of Week number must be between 1 and 7")
            .max(7, "Day of Week number must be between 1 and 7")
        : Yup.string(),
  });

  useEffect(() => {
    // get synchronization
    if (isEdit) {
      dispatch(
        getSynchronizationList({
          params: {
            page_num: 1,
            page_size: 9999,
            featureset_id: featuresetDescribe?.id,
            synchronization_state: "RELEASED",
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.items?.length > 0) {
            const synchronization = res?.items[0] || {};
            setEnableSync(true);
            setCurConnection(
              connectionList.find(
                (item) => item.id === synchronization.source_connection_id
              )
            );

            setFieldValue(
              "source_connection_id",
              synchronization.source_connection_id
            );
            setFieldValue("source_path", synchronization.source_path);
            setFieldValue("source_type", synchronization.source_type);
            setFieldValue(
              "source_credential_id",
              synchronization.source_credential_id
            );
            setFieldValue(
              "target_credential_id",
              synchronization.target_credential_id
            );
            // If there is a job
            dispatch(
              getSynchronizationJobList({
                params: {
                  page_num: 1,
                  page_size: 9999,
                  synchronization_id: synchronization.id,
                },
                alertCallback: enqueueSnackbar,
                pathname,
              })
            )
              .unwrap()
              .then((resp) => {
                // eslint-disable-next-line array-callback-return
                resp?.items?.map((item) => {
                  if (item.job_type === "AdHoc") {
                    setRunImmediately(true);
                  } else if (item.job_type === "Scheduled") {
                    setRunSynchronization(true);
                    const jobProperties =
                      item.job_properties?.Cron?.split(" ") || [];
                    if (jobProperties.length === 5) {
                      setFieldValue("minutes", jobProperties[0]);
                      setFieldValue("hours", jobProperties[1]);
                      setFieldValue("day", jobProperties[2]);
                      setFieldValue("month", jobProperties[3]);
                      setFieldValue("week", jobProperties[4]);
                    }
                  }
                });
              });
          }
        });
    }
  }, []);

  const isEmpty = (value: undefined | string | null) => {
    return value === undefined || value === null || value === "";
  };

  const submitSynchronization = (values) => {
    const synchronizationParams = {
      name: isEdit ? featuresetDescribe?.name : basicInfo?.name,
      source_type: values?.source_type,
      source_path: values?.source_path,
      source_connection_id: values?.source_connection_id,
      source_credential_id: values?.source_credential_id,
      synchronization_properties: {
        Cron: `${values.minutes} ${values.hours} ${values.day} ${values.month} ${values.week}`,
      },
      target_type: storageInfo?.resource_type,
      target_path: storageInfo?.resource_path,
      target_connection_id: storageInfo?.resource_connection_id,
      target_credential_id:
        values.target_credential_id || storageInfo?.resource_credential_id,
      featureset_id: isEdit ? featuresetDescribe?.id : featuresetRes?.id,
    };
    if (isEdit && synchronizationList.length > 0) {
      return dispatch(
        editSynchronization({
          id: synchronizationList[0]?.id,
          params: { ...synchronizationParams },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
    } else {
      return dispatch(
        addSynchronization({
          params: {
            ...synchronizationParams,
            synchronization_type: "F",
            synchronization_state: "RELEASED",
            synchronization_family: "Default",
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
    }
  };

  const handleCancel = () => {
    navigate(`/ui/dataAsset/featureStore`);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      try {
        if (enableSync) {
          // add synchronization
          const syncResult = await submitSynchronization(values);
          if (isEdit && synchronizationList.length > 0) {
            // edit synchronization job
            const updatesynchronizationJobParams = {
              name: featuresetDescribe?.name,
              job_properties: {
                Module: "featurestore",
                Cron: `${values.minutes} ${values.hours} ${values.day} ${values.month} ${values.week}`,
              },
            };
            const hocJob = synchronizationJobList.find(
              (item) => item.job_type === "AdHoc"
            );
            const scheduledJob = synchronizationJobList.find(
              (item) => item.job_type === "Scheduled"
            );
            // hocJob and runImmediately then update
            // no hocJob and runImmediately then create
            // hocJob and !runImmediately then status -> deleted
            if (runImmediately && hocJob) {
              await dispatch(
                editSynchronizationJob({
                  id: hocJob.id,
                  params: updatesynchronizationJobParams,
                  alertCallback: enqueueSnackbar,
                  pathname,
                })
              ).unwrap();
            } else if (runImmediately && !hocJob) {
              const synchronizationJobParams = {
                status: "wait_start",
                job_type: "AdHoc",
                job_state: "RELEASED",
                job_family: "K8SConnector",
                synchronization_id: synchronizationList[0]?.id,
                ...updatesynchronizationJobParams,
              };
              await dispatch(
                addSynchronizationJob({
                  params: synchronizationJobParams,
                  alertCallback: enqueueSnackbar,
                  pathname,
                })
              );
            } else if (!runImmediately && hocJob) {
              await dispatch(
                editSynchronizationJob({
                  id: hocJob.id,
                  params: {
                    ...updatesynchronizationJobParams,
                    status: "deleted",
                  },
                  alertCallback: enqueueSnackbar,
                  pathname,
                })
              ).unwrap();
            }
            // scheduledJob and runSynchronization then update
            // no scheduledJob and runSynchronization then create
            // scheduledJob and !runSynchronization then status -> deleted
            if (runSynchronization && scheduledJob) {
              await dispatch(
                editSynchronizationJob({
                  id: scheduledJob.id,
                  params: updatesynchronizationJobParams,
                  alertCallback: enqueueSnackbar,
                  pathname,
                })
              ).unwrap();
            } else if (runSynchronization && !scheduledJob) {
              const synchronizationJobParams = {
                status: "wait_start",
                job_type: "Scheduled",
                job_state: "RELEASED",
                job_family: "K8SConnector",
                synchronization_id: synchronizationList[0]?.id,
                ...updatesynchronizationJobParams,
              };
              await dispatch(
                addSynchronizationJob({
                  params: synchronizationJobParams,
                  alertCallback: enqueueSnackbar,
                  pathname,
                })
              );
            } else if (!runSynchronization && scheduledJob) {
              await dispatch(
                editSynchronizationJob({
                  id: scheduledJob.id,
                  params: {
                    ...updatesynchronizationJobParams,
                    status: "deleted",
                  },
                  alertCallback: enqueueSnackbar,
                  pathname,
                })
              ).unwrap();
            }

            enqueueSnackbar("Update Success", { variant: "success" });
          } else {
            // add synchronization job
            const synchronizationJobParams = {
              name: basicInfo?.name,
              status: "wait_start",
              job_state: "RELEASED",
              job_family: "K8SConnector",
              job_properties: {
                Module: "featurestore",
                Cron: `${values.minutes} ${values.hours} ${values.day} ${values.month} ${values.week}`,
              },
              synchronization_id: syncResult?.id,
            };
            if (runImmediately) {
              await dispatch(
                addSynchronizationJob({
                  params: { ...synchronizationJobParams, job_type: "AdHoc" },
                  alertCallback: enqueueSnackbar,
                  pathname,
                })
              );
            }

            if (
              runSynchronization &&
              (!isEmpty(values.minutes) ||
                !isEmpty(values.hours) ||
                !isEmpty(values.day) ||
                !isEmpty(values.month) ||
                !isEmpty(values.week))
            ) {
              await dispatch(
                addSynchronizationJob({
                  params: {
                    ...synchronizationJobParams,
                    job_type: "Scheduled",
                  },
                  alertCallback: enqueueSnackbar,
                  pathname,
                })
              );
            }

            enqueueSnackbar(`${isEdit ? "Update" : "Create"} Success`, {
              variant: "success",
            });
          }
        }

        actions.setSubmitting(false);
        navigate(`/ui/dataAsset/featureStore`);
      } catch (err) {
        actions.setSubmitting(false);
      }
    },
  });

  const { values, errors, touched, isSubmitting, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form>
        <MlCard title="Source Information" sx={{ mt: 1.5 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Switch
              size="small"
              checked={enableSync}
              onChange={(e) => {
                setEnableSync(e.target.checked);
              }}
            />
            <Typography>Enable Synchronization</Typography>
          </Stack>
          <Box sx={{ pb: 2, width: "800px" }}>
            <FormControl
              fullWidth
              margin="dense"
              size="small"
              error={
                touched.source_connection_id &&
                Boolean(errors.source_connection_id)
              }
            >
              <InputLabel>Source Connection</InputLabel>
              <Field
                as={Select}
                name="source_connection_id"
                label="Source Connection"
                disabled={!enableSync}
                onChange={(e) => {
                  setFieldValue("source_connection_id", e.target.value);
                  setCurConnection(
                    connectionList.find((item) => item.id === e.target.value)
                  );
                }}
              >
                {connectionList.map((item) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </Field>
              <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                {touched.source_connection_id && errors.source_connection_id
                  ? errors.source_connection_id
                  : ""}
              </FormHelperText>
            </FormControl>
            <Field
              as={TextField}
              name="source_path"
              label="Source Path"
              size="small"
              fullWidth
              margin="dense"
              disabled={!enableSync}
              helperText={
                touched.source_path && errors.source_path
                  ? errors.source_path
                  : pathTableRegex.test(values.source_path)
                  ? `Full Address: ${values.source_path}`
                  : pathFileRegex.test(values.source_path)
                  ? `Full Address: ${
                      curConnection?.connection_scheme || ""
                    }://${curConnection?.connection_endpoint || ""}${
                      values.source_path
                    }`
                  : "Full Address: "
              }
              error={touched.source_path && Boolean(errors.source_path)}
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
            <FormControl
              fullWidth
              margin="dense"
              size="small"
              error={touched.source_type && Boolean(errors.source_type)}
            >
              <InputLabel>Resource Type</InputLabel>
              <Field
                as={Select}
                name="source_type"
                label="Resource Type"
                disabled={!enableSync}
              >
                <MenuItem value="SQL">SQL</MenuItem>
                <MenuItem value="ZIP">ZIP</MenuItem>
                <MenuItem value="TXT">TXT</MenuItem>
                <MenuItem value="CSV">CSV</MenuItem>
                <MenuItem value="Parquet">Parquet</MenuItem>
                <MenuItem value="Binary">Binary</MenuItem>
              </Field>
              <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                {touched.source_type && errors.source_type
                  ? errors.source_type
                  : ""}
              </FormHelperText>
            </FormControl>
            <Typography variant="subtitle1">Source Credentials</Typography>
            <FormControl
              fullWidth
              margin="dense"
              size="small"
              error={
                touched.source_credential_id &&
                Boolean(errors.source_credential_id)
              }
            >
              <InputLabel>Secrets</InputLabel>
              <Field
                as={Select}
                name="source_credential_id"
                label="Secrets"
                disabled={!enableSync}
              >
                {credentialList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Field>
              <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                {touched.source_credential_id && errors.source_credential_id
                  ? errors.source_credential_id
                  : ""}
              </FormHelperText>
            </FormControl>
            <Typography variant="subtitle1">
              Target Credentials - Optional
            </Typography>
            <FormControl fullWidth margin="dense" size="small">
              <InputLabel>Secrets</InputLabel>
              <Field
                as={Select}
                name="target_credential_id"
                label="Secrets"
                disabled={!enableSync}
              >
                {credentialList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Field>
              <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }} />
            </FormControl>
            <Typography variant="subtitle1">
              Schedule Pattern (Cron Expression) - Optional
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={runImmediately}
                    disabled={!enableSync}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setRunImmediately(event.target.checked)
                    }
                  />
                }
                label="Run immediately after save"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={runSynchronization}
                    disabled={!enableSync}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setRunSynchronization(event.target.checked)
                    }
                  />
                }
                label="Run synchronization at"
              />
            </FormGroup>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1.5}
            >
              <Grid item xs="auto">
                <Typography variant="subtitle2"> Synchronized at</Typography>
              </Grid>
              <Grid item xs={2.5} md={1.7}>
                <Field
                  as={TextField}
                  name="minutes"
                  label="Minutes"
                  size="small"
                  disabled={!(enableSync && runSynchronization)}
                  helperText={touched?.minutes && errors?.minutes}
                  FormHelperTextProps={{
                    sx: { minHeight: "18px", marginTop: "2px" },
                  }}
                />
              </Grid>
              <Grid item xs={2.5} md={1.7}>
                <Field
                  as={TextField}
                  name="hours"
                  label="Hours"
                  size="small"
                  disabled={!(enableSync && runSynchronization)}
                  helperText={touched?.hours && errors?.hours}
                  FormHelperTextProps={{
                    sx: { minHeight: "18px", marginTop: "2px" },
                  }}
                />
              </Grid>
              <Grid item xs={2.5} md={1.7}>
                <Field
                  as={TextField}
                  name="day"
                  label="Day of Month"
                  size="small"
                  disabled={!(enableSync && runSynchronization)}
                  helperText={touched?.day && errors?.day}
                  FormHelperTextProps={{
                    sx: { minHeight: "18px", marginTop: "2px" },
                  }}
                />
              </Grid>
              <Grid item xs={2.5} md={1.7}>
                <Field
                  as={TextField}
                  name="month"
                  label="Month"
                  size="small"
                  disabled={!(enableSync && runSynchronization)}
                  helperText={touched?.month && errors?.month}
                  FormHelperTextProps={{
                    sx: { minHeight: "18px", marginTop: "2px" },
                  }}
                />
              </Grid>
              <Grid item xs={2.5} md={1.7}>
                <Field
                  as={TextField}
                  name="week"
                  label="Day of Week"
                  size="small"
                  disabled={!(enableSync && runSynchronization)}
                  helperText={touched?.week && errors?.week}
                  FormHelperTextProps={{
                    sx: { minHeight: "18px", marginTop: "2px" },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </MlCard>

        <Stack
          spacing={2}
          direction="row"
          justifyContent="center"
          sx={{ my: 4 }}
        >
          <LoadingButton
            type="submit"
            loading={isSubmitting}
            variant="contained"
            sx={{ width: "200px", color: "background.paper" }}
          >
            Save
          </LoadingButton>
          <Button
            type="button"
            color="inherit"
            variant="outlined"
            sx={{ width: "200px", color: "text.secondary" }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default SynchronizationStep;

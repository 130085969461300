/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
// material
import {
  Box,
  Grid,
  Stack,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Skeleton,
} from "@mui/material";
// hooks
import useLocales from "@/hooks/project/useLocales";
import { useLocation } from "react-router";
// components
import MetricCard from "./MetricCard";
import MetricPlot from "./MetricPlot";
import TimeRangeSelector from "./TimeRangeSelector";
import { colorsForLogPlotting } from "@/utils/project/getColorPresets";
import MetricsDialog from "./MetricsDialog";
import {
  getMetricsList,
  getServiceInferenceList,
  getRelMlServiceMetricsTemplateList,
  getMetricsTemplateList,
} from "@/redux/project/mlService/mlRuntime/thunks";
import { RootState, dispatch, useSelector } from "@/redux/store";
import { useSnackbar } from "notistack";
import ServiceInferenceTable from "./ServiceInferenceTable";
import moment from "moment";
import { isEmpty } from "lodash";

type Props = {
  curDeployment: Record<string, any>;
  setCurDeployment: React.Dispatch<React.SetStateAction<Record<string, any>>>;
};

export default function Metrics({ curDeployment, setCurDeployment }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { translate } = useLocales();
  const [startTime, setStartTime] = useState<Date | null>(
    moment().subtract(1, "day").toDate()
  );
  const [endTime, setEndTime] = useState<Date | null>(new Date());
  const [curInterval, setCurInterval] = useState<string>("day");
  const [templates, setTemplates] = useState<string[]>([]);
  const [metricsCardAndPlotData, setMetricsCardAndPlotData] = useState<
    Record<string, any>
  >({});
  const [openMetricsDialog, setOpenMetricsDialog] = useState<boolean>(false);
  const {
    mlRuntimeDetail: { data: serviceDetail },
    mlServiceDeploymentList: { data },
    metricsList: {
      data: { items: metricsList = [] },
      loading,
    },
  } = useSelector((state: RootState) => state.mlRuntime);

  const IntervalOptions = ["day", "week", "month"];

  const getMetricsData = async () => {
    // get rel_metrics_template
    const relMetricsTemplateList = await dispatch(
      getRelMlServiceMetricsTemplateList({
        params: {
          page_num: 1,
          page_size: 9999,
          mlservice_id: serviceDetail.id,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    // get template list
    const metricsTemplateList = await dispatch(
      getMetricsTemplateList({
        params: { page_num: 1, page_size: 9999, is_active: 1 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    const temps: string[] = [];
    relMetricsTemplateList?.items?.map((rel) => {
      const templateName = metricsTemplateList?.items?.find(
        (template) => template.id === rel.metrics_template_id
      )?.name;
      if (templateName) {
        temps.push(templateName);
      }
      return null;
    });

    setTemplates(temps);

    // get metrics
    dispatch(
      getMetricsList({
        end_date: moment(endTime).add(1, "days").format("YYYY-MM-DD HH:mm:ss"),
        start_date: moment(startTime)
          .add(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        names: temps.join(","),
        params: {
          page_num: 1,
          page_size: 9999,
          mlservice_deployment_id: curDeployment?.id,
          aggregate_by: curInterval,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  };

  useEffect(() => {
    // get rel_metrics_template => get template => get matrics
    getMetricsData();
  }, []);

  useEffect(() => {
    const metricsData = {} as Record<string, any>;
    if (metricsList.length > 0) {
      templates.map((item) => {
        metricsData[item] = [];
        return null;
      });

      metricsList.map((item) => {
        metricsData[item.name]?.push(item);
        return null;
      });
    }
    setMetricsCardAndPlotData(metricsData);
  }, [metricsList]);

  const handleChangeStartTime = (newValue: Date | null) => {
    setStartTime(newValue);
  };

  const handleChangeEndTime = (newValue: Date | null) => {
    setEndTime(newValue);
  };

  const fetchMetricsData = (params: {
    page_num: number;
    page_size: number;
    mlservice_deployment_id: string;
    aggregate_by: string;
  }) => {
    return dispatch(
      getMetricsList({
        end_date: moment(endTime).add(1, "days").format("YYYY-MM-DD HH:mm:ss"),
        start_date: moment(startTime)
          .add(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        names: templates.join(","),
        params,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setCurDeployment(data.items.find((item) => item.id === event.target.value));
    fetchMetricsData({
      page_num: 1,
      page_size: 9999,
      mlservice_deployment_id: event.target.value,
      aggregate_by: curInterval,
    }).then(() => {
      enqueueSnackbar("Search Success", { variant: "success" });
    });
  };

  const handleIntervalChange = (event: SelectChangeEvent<string>) => {
    setCurInterval(event.target.value);
    fetchMetricsData({
      page_num: 1,
      page_size: 9999,
      mlservice_deployment_id: curDeployment?.id,
      aggregate_by: event.target.value,
    }).then(() => {
      enqueueSnackbar("Search Success", { variant: "success" });
    });
  };

  const handleRefresh = async () => {
    try {
      await fetchMetricsData({
        page_num: 1,
        page_size: 9999,
        mlservice_deployment_id: curDeployment?.id,
        aggregate_by: curInterval,
      });
      await dispatch(
        getServiceInferenceList({
          startTime: moment(startTime).valueOf(),
          endTime: moment(endTime).valueOf(),
          params: { page_num: 1, page_size: 9999 },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      );
      enqueueSnackbar("Refresh Success", { variant: "success" });
    } catch (e) {}
  };

  return (
    <>
      <Grid container>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{ width: "100%" }}
        >
          <Box>
            <TimeRangeSelector
              buttonLabel={translate("refresh")}
              startTime={startTime}
              endTime={endTime}
              onButtonClick={handleRefresh}
              onChangeStartTime={handleChangeStartTime}
              onChangeEndTime={handleChangeEndTime}
            />
          </Box>

          <Box sx={{ mb: 2, mt: 2 }}>
            <Stack direction="row" justifyContent="space-between" spacing={3}>
              <FormControl size="small">
                <InputLabel>Deployment</InputLabel>
                <Select
                  id="deployment"
                  value={curDeployment?.id}
                  label="Deployment"
                  onChange={handleSelectChange}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 280,
                        maxWidth: 280,
                      },
                    },
                  }}
                  sx={{ width: 280 }}
                >
                  {data?.items?.map((item, index) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="small">
                <InputLabel>Interval</InputLabel>
                <Select
                  id="interval"
                  value={curInterval}
                  label="Interval"
                  onChange={handleIntervalChange}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        maxWidth: 100,
                      },
                    },
                  }}
                  sx={{ width: 100 }}
                >
                  {IntervalOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                onClick={() => setOpenMetricsDialog(true)}
              >
                Add Metrics
              </Button>
            </Stack>
          </Box>
        </Stack>

        <Grid container sx={{ mb: isEmpty(metricsCardAndPlotData) ? 0 : 2 }}>
          <Box
            sx={{
              display: "grid",
              gap: 3,
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(4, 1fr)",
                lg: "repeat(4, 1fr)",
              },
              width: "100%",
            }}
          >
            {loading &&
              // eslint-disable-next-line array-callback-return
              templates.map((item) => (
                <Skeleton
                  key={item}
                  variant="rectangular"
                  width="100%"
                  height={130}
                  sx={{ borderRadius: 2, mt: 1, mb: 1 }}
                />
              ))}

            {!loading &&
              Object.keys(metricsCardAndPlotData).map((key, index) => {
                if (metricsCardAndPlotData[key]?.length > 0) {
                  return (
                    <MetricCard
                      key={index}
                      title={key}
                      metricData={metricsCardAndPlotData[key]}
                    />
                  );
                }
                return null;
              })}
          </Box>
        </Grid>

        <Stack
          sx={{ width: "100%", mb: isEmpty(metricsCardAndPlotData) ? 0 : 3 }}
          spacing={3}
        >
          {loading && (
            <Skeleton
              key="plot"
              variant="rectangular"
              width="100%"
              height={260}
              sx={{ borderRadius: 2, mt: 1, mb: 1 }}
            />
          )}

          {!loading &&
            Object.keys(metricsCardAndPlotData).map((key, index) => {
              if (metricsCardAndPlotData[key]?.length > 0) {
                const xaxis: string[] = [];
                const series: { name: string; data: string[] }[] = [];
                metricsCardAndPlotData[key].map((item) => {
                  const seriesIndex = series.findIndex(
                    (se) => se.name === item.model_name
                  );
                  if (seriesIndex === -1) {
                    series.push({ name: item.model_name, data: [item.result] });
                  } else {
                    series[seriesIndex].data.push(item.result);
                  }
                  if (item.model_name === series[0].name) {
                    xaxis.push(
                      moment(item.created_at)
                        .subtract(1, "days")
                        .format("YYYY/MM/DD HH:mm:ss")
                    );
                  }
                  return null;
                });
                return (
                  <MetricPlot
                    key={key}
                    title={key}
                    metricData={metricsCardAndPlotData[key]}
                    seriesData={series}
                    chartColors={[colorsForLogPlotting[index + 2].main]}
                    xaxis={xaxis}
                  />
                );
              }
              return null;
            })}
        </Stack>

        {/* Service Inference Result */}
        <ServiceInferenceTable startTime={startTime} endTime={endTime} />
      </Grid>

      <MetricsDialog
        isOpen={openMetricsDialog}
        onClose={() => {
          setOpenMetricsDialog(false);
        }}
      />
    </>
  );
}

import * as Yup from "yup";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Stack, Collapse, Typography, IconButton } from "@mui/material";
// types
import {
  ModelStep,
  ModelContainerStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-model";
//
import {
  updateModelContainer,
  updateTrainingEnvVars,
} from "../../../graph/slices/slice";
import { CollapseButtonStyle } from "../../SettingSidebar";
import TrainingEstimatorEnvVar from "../training/TrainingEstimatorEnvVar";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";

// ----------------------------------------------------------------------

type Props = {
  currentStep: ModelStep;
};

export default function Container({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [isCollapse, setCollapse] = useState(false);

  const ParamSchema = Yup.object().shape({
    // instanceType: Yup.string().required("Instance type is required"),
  });

  const formik = useFormik<ModelContainerStruct>({
    initialValues: currentStep.container,
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values) => {
      try {
        dispatch(updateModelContainer(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const environmentVars = currentStep.container.environmentVars;
  const envVarNum = environmentVars !== undefined ? environmentVars.length : 0;

  const { handleSubmit } = formik;

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setCollapse((prev) => !prev)}
        endIcon={
          <Icon
            icon={isCollapse ? arrowIosForwardFill : arrowIosDownwardFill}
            width={16}
            height={16}
          />
        }
      >
        Model container
      </CollapseButtonStyle>

      <Collapse in={!isCollapse} sx={{ mx: 2, mb: !isCollapse ? 0.5 : 0 }}>
        <Stack spacing={1} sx={{ my: 1 }}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onChange={handleSubmit}>
              <Stack spacing={2}>
                {/* <PipelineSelect
                  label={"Container image"}
                  field={"containerType"}
                  placeholder={"Select an container image"}
                  options={estimatorTypes}
                  getFieldProps={getFieldProps}
                /> */}

                <PipelineParameterEntry
                  label={"Container image"}
                  field={"containerType"}
                  placeholder={
                    "{account}.dkr.ecr.{region}.amazonaws.com/xxx:xxx"
                  }
                  fieldUseParam={"containerTypeUseParam"}
                  formik={formik}
                />

                {/* <PipelineSelect
                  label={"Image version"}
                  field={"frameworkVersion"}
                  placeholder={"Select a framework version"}
                  options={estimatorVerions}
                  getFieldProps={getFieldProps}
                /> */}

                {/* <PipelineParameterEntry
                  label={"Instance type"}
                  field={"instanceType"}
                  fieldUseParam={"instanceTypeUseParam"}
                  formik={formik}
                /> */}
              </Stack>
            </Form>
          </FormikProvider>

          <Stack spacing={1.5} sx={{ mx: 2, my: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", fontWeight: 600, mt: 0.5 }}
              >
                Environment variables {envVarNum > 0 && `(${envVarNum})`}
              </Typography>
              <IconButton
                size="small"
                onClick={() => {
                  dispatch(
                    updateTrainingEnvVars({
                      name: "",
                      value: "",
                    })
                  );
                }}
              >
                <Icon icon={plusFill} width={16} height={16} />
              </IconButton>
            </Stack>

            {environmentVars !== undefined &&
              environmentVars.map((envVar, index) => (
                <TrainingEstimatorEnvVar
                  key={index}
                  id={index}
                  name={envVar.name}
                  value={envVar.value}
                />
              ))}
          </Stack>
        </Stack>
      </Collapse>
    </>
  );
}

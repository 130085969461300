import { useRef } from "react";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import roundCloudUpload from "@iconify/icons-ic/round-cloud-upload";
// import downloadOutline from "@iconify/icons-eva/download-outline";
// import uploadOutline from "@iconify/icons-eva/upload-outline";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
// routes

// material
import { styled } from "@mui/material/styles";
import { Stack, Divider, Tooltip, IconButton } from "@mui/material";
// redux
import { RootState, dispatch, useSelector } from "@/redux/store";
// components
import SvgIconStyle from "@/components/project/SvgIconStyle";
import StepPopover from "./StepPopover";
import { deleteEntities, setShowPropPanel } from "../graph/slices/slice";
import { getGraph } from "../graph/schema/components/GraphEditor";
import {
  createPipeline,
  updatePipeline,
  preventRefreshingPipeline,
  setCurrentKubeflowPipeline,
} from "@/redux/project/automation/pipeline";
import { formPipelineInfoForSM } from "../graph/schema/GeneratorForSmPipeline";
import { addPipelineStorageData } from "@/redux/project/template/thunks";
import {
  addPipelineVersion,
  getPipelineTriggerTableData,
  getPipelineVersionDetail,
} from "@/redux/project/automation/thunks";
import { isEmpty } from "lodash";
import { generatePipelineJson } from "../utils";
import {
  queryRestResource,
  restResource,
} from "@/utils/project/resourceRequest";
// import PipelineImportDialog from "./PipelineImportDialog";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  minHeight: 50,
  display: "flex",
  position: "relative",
  alignItems: "center",
  paddingLeft: theme.spacing(2),
}));

const prefix = process.env.NODE_ENV === "development" ? "" : "/ui";

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`${prefix}/static/icons/pipeline/${name}.svg`}
    sx={{ width: 24, height: 24 }}
  />
);

const ICONS = {
  zoomOut: getIcon("ic_zoomout"),
  zoomIn: getIcon("ic_zoomin"),
  refresh: getIcon("ic_refresh"),
  fullview: getIcon("ic_fullview"),
  eye: getIcon("ic_eye"),
};

// ----------------------------------------------------------------------

type TopToolbarProps = {
  disabled: boolean;
  isEdit: boolean;
};

export default function TopToolbar({ disabled, isEdit }: TopToolbarProps) {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { mlPipelineId, versionId } = useParams();

  const fileInputRef = useRef<HTMLInputElement>(null);
  // const [isOpenConfirm, setOpenConfirm] = useState(false);
  const { pipelineType } = useSelector((state: RootState) => state.schema);
  const isKubeflow = pipelineType === "Kubeflow";

  const timeRef = useRef(Date.now());
  const { schema } = useSelector((state: RootState) => state.schema);
  const { currentPipeline, currentKubeflowPipeline } = useSelector(
    (state: RootState) => state.pipeline
  );
  const commonPipelineData = isKubeflow
    ? currentKubeflowPipeline
    : currentPipeline;

  const {
    pipelineVersionDetail: { data: versionDetail = {} },
  } = useSelector((state: RootState) => state.automation);

  const {
    userDetail: { project_id },
  } = useSelector((state: RootState) => state.common);

  const navigate = useNavigate();

  const callbackForCreate = (content: string, variant: any) => {
    enqueueSnackbar(content, { variant: variant });
    if (variant !== "error") {
      navigate(`/ui/pipeline/${commonPipelineData.PipelineName}/edit`);
    }
  };

  const callback = (content: string, variant: undefined) => {
    enqueueSnackbar(content, { variant: variant });
  };

  /* const downloadPipelineDef = async () => {
    const pipelineDef = await formPipelineInfoForSM(
      schema,
      commonPipelineData,
      ''
    );

    dispatch(stopUploadingPipeline());

    if (pipelineDef) {
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        pipelineDef
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      link.download = `${commonPipelineData.PipelineName}.json`;
      link.click();
    }
  };

  const handleUploadLocalFile = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    if (e.target.files && e.target.files.length > 0) {
      fileReader.readAsText(e.target.files[0], "UTF-8");
      fileReader.onload = (e) => {
        if (e.target && e.target.result)
          dispatch(
            importLocalPipelineDef(
              `Pipeline-${timeRef.current}`,
              JSON.parse(JSON.stringify(e.target.result)),
              callback
            )
          );
      };
    }
  }; */

  const handleUploadPipeline = async () => {
    if (isKubeflow) {
      const timeFlag = new Date().getTime();

      // upload to s3
      await dispatch(
        addPipelineStorageData({
          params: {
            provider: "s3",
            file_path: `${mlPipelineId}/${timeFlag}.json`,
            content: generatePipelineJson(currentKubeflowPipeline, schema),
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      const config = {
        env: {
          type: "kubernetes",
          cluster_names: ["default-k8s"],
        },
        node_selector: {},
        pipeline: {
          pipeline_type: "Kubeflow",
          pipeline_file: `${mlPipelineId}/${`${timeFlag}.json`}`,
        },
      };

      // create a version
      dispatch(
        addPipelineVersion({
          params: {
            mlpipeline_id: mlPipelineId!,
            name: String(timeFlag),
            description: "",
            resource_config: config,
            active_version: 0,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then(({ id }) => {
          // create success, disable the canvans and siderbar
          dispatch(
            getPipelineVersionDetail({
              id,
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap();
        })
        .catch((err) => {
          // create failed, can re-upload the json
        });

      enqueueSnackbar("Upload and Create success", { variant: "success" });
    } else {
      const pipelineDef = await formPipelineInfoForSM(
        schema,
        commonPipelineData,
        ""
      );
      // console.log(pipelineDef);
      dispatch(preventRefreshingPipeline());
      if (isEdit) {
        dispatch(
          updatePipeline(
            commonPipelineData.PipelineName,
            commonPipelineData.RoleArn,
            pipelineDef,
            callback
          )
        );
      } else {
        dispatch(
          createPipeline(
            commonPipelineData.PipelineName,
            commonPipelineData.RoleArn,
            pipelineDef,
            callbackForCreate
          )
        );
      }
    }
  };

  const zoomOut = () => {
    getGraph().zoom(-0.08, { minScale: 0.1 });
  };

  const zoomIn = () => {
    getGraph().zoom(0.08);
  };

  const zoomToFit = () => {
    getGraph().zoomToFit({ maxScale: 0.8 });
  };

  const refresh = () => {
    dispatch(
      getPipelineTriggerTableData({
        params: {
          mlpipeline_version_id: versionId!,
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    )
      .unwrap()
      .then((res) => {
        dispatch(setCurrentKubeflowPipeline({ triggerList: res?.items || [] }));

        enqueueSnackbar("Refresh Success", { variant: "success" });
      });

    dispatch(setShowPropPanel(true));

    getGraph().zoomToFit({ maxScale: 0.8 });
  };

  /* const handleConnectionProp = () => {
    dispatch(setShowConnectionProp());
  }; */

  const removeStep = () => {
    const graph = getGraph();
    const selectedCells = graph.getSelectedCells();
    graph.removeCells(selectedCells);
    dispatch(deleteEntities(selectedCells.map((cell) => cell.id)));
  };

  return (
    <RootStyle>
      <Stack
        direction="row"
        spacing={1}
        sx={{ display: "flex", alignItems: "center", mr: 1.5 }}
      >
        <Tooltip
          title="Send to cloud and create version"
          enterDelay={1000}
          enterTouchDelay={1000}
          arrow
        >
          <IconButton
            disabled={
              disabled ||
              !isEmpty(versionDetail) ||
              isEmpty(schema?.pipelineSteps)
            }
            size="small"
            onClick={handleUploadPipeline}
          >
            <Icon icon={roundCloudUpload} width={24} height={24} />
          </IconButton>
        </Tooltip>
        {/* <Tooltip
          title="Download to local"
          enterDelay={1000}
          enterTouchDelay={1000}
          arrow
        >
          <IconButton disabled={disabled} size="small" onClick={downloadPipelineDef}>
            <Icon icon={downloadOutline} width={24} height={24} />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Upload local file"
          enterDelay={1000}
          enterTouchDelay={1000}
          arrow
        >
          <IconButton disabled={disabled} size="small" onClick={handleUploadLocalFile}>
            <Icon icon={uploadOutline} width={24} height={24} />
          </IconButton>
        </Tooltip>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="json/*"
          onChange={handleFileChange}
        /> */}
      </Stack>

      {/* <PipelineImportDialog
        pipelineName=""
        isOpen={isOpenConfirm}
        setOpenConfirm={setOpenConfirm}
      /> */}

      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ height: 30, my: "auto" }}
      />

      <Stack
        direction="row"
        spacing={1}
        sx={{ display: "flex", alignItems: "center", mx: 1.5 }}
      >
        <IconButton size="small" onClick={zoomIn}>
          {ICONS.zoomIn}
        </IconButton>
        <IconButton size="small" onClick={zoomOut}>
          {ICONS.zoomOut}
        </IconButton>
        <IconButton size="small" onClick={zoomToFit}>
          {ICONS.fullview}
        </IconButton>
        <IconButton size="small" onClick={refresh}>
          {ICONS.refresh}
        </IconButton>
      </Stack>

      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ height: 30, my: "auto" }}
      />

      <Stack
        direction="row"
        spacing={1}
        sx={{ display: "flex", alignItems: "center", mx: 1.5 }}
      >
        <StepPopover disabled={disabled || !isEmpty(versionDetail)} />
        <IconButton
          disabled={disabled || !isEmpty(versionDetail)}
          size="small"
          onClick={removeStep}
        >
          <Icon icon={trash2Fill} width={22} height={22} />
        </IconButton>
      </Stack>
      {/* <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ height: 30, my: "auto" }}
      />
      <Stack
        direction="row"
        spacing={1}
        sx={{ display: "flex", alignItems: "center", mx: 1.5 }}
      >
        <Tooltip
          title="Show/Hide connection properties"
          enterDelay={1000}
          enterTouchDelay={1000}
          arrow
        >
          <IconButton
            disabled={disabled}
            size="small"
            onClick={handleConnectionProp}
          >
            {ICONS.eye}
          </IconButton>
        </Tooltip>
      </Stack> */}
    </RootStyle>
  );
}

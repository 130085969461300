import { Helmet } from "react-helmet-async";
import { forwardRef, ReactNode, useEffect } from "react";
import { useLocation } from "react-router";
import { Box, BoxProps, Container } from "@mui/material";
import HeaderBreadcrumbs from "@/components/project/HeaderBreadcrumbs";
import { findItemAndParentByPath, isAuthed } from "@/layouts/project/utils";
import sidebarConfig from "@/layouts/project/sidebar/SidebarConfig";
import { isEmpty } from "lodash";
import { capitalizeFirstLetter } from "@/utils/project/utils";
import PermissionDenied from "../PermissionDenied";
import { useSelector } from "react-redux";
import { dispatch, RootState } from "@/redux/store";
import { clearPermissDeniedObj } from "@/redux/project/administration/slice";

interface PageProps extends BoxProps {
  children: ReactNode;
  title?: string;
  heading?: string | ReactNode;
  links?: any[];
  noTitle?: boolean;
  action?: ReactNode;
  isPrivate?: boolean; // not exist in siderbar no breadcrumb
}

const Page = forwardRef<HTMLDivElement, PageProps>(
  (
    {
      children,
      title = "",
      heading = "",
      noTitle = false,
      links = [],
      action,
      isPrivate = false,
      ...other
    },
    ref
  ) => {
    const { pathname } = useLocation();
    const { permissDeniedObj } = useSelector(
      (state: RootState) => state.administration
    );

    const {
      isAdmin,
      isNoneUser,
      userDetail: { permissions = [] } = {},
    } = useSelector((state: RootState) => state.common);

    const targetObj = findItemAndParentByPath(sidebarConfig, pathname) || {};

    const helmetTitle = title || `${targetObj?.item?.title} | OmniML`;

    const headText = heading || targetObj?.item?.title || "";

    const breadcrumbLinks = !isEmpty(links)
      ? links
      : [
          { name: capitalizeFirstLetter(targetObj?.subheader || "") },
          { name: targetObj?.item?.title },
        ];

    const isUserPermissionsPassed = () => {
      const { item } = findItemAndParentByPath(sidebarConfig, pathname);

      return !isEmpty(item?.auth)
        ? item.auth.some((auth) => permissions.includes(String(auth)))
        : true;
    };

    useEffect(() => {
      return () => {
        dispatch(clearPermissDeniedObj());
      };
    }, []);

    // user permissions
    if (!isUserPermissionsPassed()) return <PermissionDenied />;

    // admin and no-admin
    if (!isAuthed(pathname, isNoneUser, isAdmin)) return <PermissionDenied />;

    // api return 42001 (no auth in backend)
    if (permissDeniedObj[pathname]) return <PermissionDenied />;

    return (
      <Box ref={ref} {...other}>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        {isPrivate ? (
          <Container maxWidth={false} sx={{ height: "100%" }}>
            <HeaderBreadcrumbs
              noBreadcrumb={isPrivate}
              action={action}
              noTitle={noTitle}
              heading={headText}
              links={breadcrumbLinks}
            />
            {children}
          </Container>
        ) : (
          !isEmpty(targetObj) && (
            <Container maxWidth={false} sx={{ height: "100%" }}>
              <HeaderBreadcrumbs
                action={action}
                noTitle={noTitle}
                heading={headText}
                links={breadcrumbLinks}
              />
              {children}
            </Container>
          )
        )}
      </Box>
    );
  }
);

export default Page;

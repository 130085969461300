/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Card,
} from "@mui/material";
import { useLocation } from "react-router";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import { getPipelineTemplateTableData } from "@/redux/project/template/thunks";
import TemplateList from "./TemplateList";
import PipelineTemplateCreateOrEditDialog from "./PipelineTemplateCreateOrEditDialog";
import PipelineTemplateDetailDrawer from "./PipelineTemplateDetailDrawer";

export default function PipelineTemplateList() {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [mode, setMode] = useState<"create" | "edit">("create");
  const [template, setTemplate] = useState<Record<string, any>>({});
  const [openPipelineTempalteDialog, setOpenPipelineTempalteDialog] =
    useState<boolean>(false);
  const [
    openPipelineTemplateDetailDrawer,
    setOpenPipelineTemplateDetailDrawer,
  ] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  // filter
  const filter = (name, value) => {
    if (value) return { [name]: value };
    return {};
  };

  const fetchData = (params: {
    name?: string;
    page_num: number;
    page_size: number;
  }) => {
    return dispatch(
      getPipelineTemplateTableData({
        params,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      fetchData({ page_num: 1, page_size: 9999, ...filter("name", searchText) })
        .unwrap()
        .then(() => {
          enqueueSnackbar("Search Success", { variant: "success" });
        });
    }
  };

  const handleRefresh = (isUpdate?: boolean) => {
    fetchData({ page_num: 1, page_size: 9999 })
      .unwrap()
      .then(() => {
        if (!isUpdate) {
          enqueueSnackbar("Refresh Success", { variant: "success" });
        }
      });
    setSearchText("");
  };

  useEffect(() => {
    fetchData({ page_num: 1, page_size: 9999 });
  }, []);

  return (
    <>
      <Card>
        <Stack direction="row" justifyContent="space-between">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: 2.5 }}
          >
            <TextField
              id="search"
              sx={{ width: "360px" }}
              variant="outlined"
              size="small"
              label="Search by Name"
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
              onKeyDown={handleKeyDown}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() => {
                        fetchData({
                          page_num: 1,
                          page_size: 9999,
                          ...filter("name", searchText),
                        })
                          .unwrap()
                          .then(() => {
                            enqueueSnackbar("Search Success", {
                              variant: "success",
                            });
                          });
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ pr: 2.5 }}
          >
            <Button
              variant="contained"
              sx={{ width: "120px", height: "36px" }}
              onClick={() => {
                setMode("create");
                setOpenPipelineTempalteDialog(true);
              }}
            >
              Add Template
            </Button>
            <IconButton onClick={() => handleRefresh()}>
              <RefreshOutlinedIcon sx={{ width: "22px", height: "22px" }} />
            </IconButton>
          </Stack>
        </Stack>
        <TemplateList
          handleRefresh={handleRefresh}
          onSetModel={() => setMode("edit")}
          onShowDialog={() => setOpenPipelineTempalteDialog(true)}
          onShowDetail={() => setOpenPipelineTemplateDetailDrawer(true)}
          setTemplate={setTemplate}
        />
      </Card>

      <PipelineTemplateCreateOrEditDialog
        mode={mode}
        isOpen={openPipelineTempalteDialog}
        onClose={() => {
          setMode("create");
          setOpenPipelineTempalteDialog(false);
        }}
        handleRefresh={handleRefresh}
        template={template}
      />

      <PipelineTemplateDetailDrawer
        isOpen={openPipelineTemplateDetailDrawer}
        onClose={() => {
          setOpenPipelineTemplateDetailDrawer(false);
        }}
        template={template}
        clearRowData={() => {
          setTemplate({});
        }}
      />
    </>
  );
}

import { motion } from "framer-motion";
import { Link as RouterLink } from "react-router-dom";
// material
import { Box, Button, Typography } from "@mui/material";
// components
import { ForbiddenIllustration } from "@/assets";
import { MotionContainer, varBounceIn } from "../animate";
import {
  filterAdminSiderConfig,
  filterSiderbarConfigByPermissions,
  findMainPage,
} from "@/layouts/project/utils";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import sidebarConfig from "@/layouts/project/sidebar/SidebarConfig";

type Props = {
  content?: string;
  homeRoute?: string;
  height?: number;
  showHomeBtn?: boolean;
  titleSize?: string;
};

export default function PermissionDenied({
  content = "You do not have permission to access this page.",
  homeRoute = "",
  height = 260,
  showHomeBtn = true,
  titleSize = "h3",
}: Props) {
  const {
    isAdmin,
    isNoneUser,
    userDetail: { permissions = [] } = {},
  } = useSelector((state: RootState) => state.common);

  return (
    <MotionContainer initial="initial" open>
      <Box
        sx={{
          maxWidth: 480,
          margin: "auto",
          marginTop: 5,
          textAlign: "center",
        }}
      >
        <motion.div variants={varBounceIn}>
          <Typography variant={titleSize as any} paragraph>
            Permission Denied
          </Typography>
        </motion.div>
        <Typography sx={{ color: "text.secondary" }}>{content}</Typography>

        <motion.div variants={varBounceIn}>
          <ForbiddenIllustration
            sx={{ height: height, my: { xs: 5, sm: 10 } }}
          />
        </motion.div>

        {showHomeBtn && (
          <Button
            to={
              homeRoute ||
              findMainPage(
                isAdmin,
                filterSiderbarConfigByPermissions(
                  filterAdminSiderConfig(isAdmin, isNoneUser, sidebarConfig),
                  permissions
                )
              )
            }
            size="medium"
            variant="contained"
            component={RouterLink}
          >
            Go to Main
          </Button>
        )}
      </Box>
    </MotionContainer>
  );
}

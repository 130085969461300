/* eslint-disable react-hooks/exhaustive-deps */
import Page from "@/components/project/Page";
import { PATH_DASHBOARD } from "@/routes/paths";
import { Button, Tooltip } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router";
import ProjectListTable from "./components/organizationDetail/ProjectListTable";
import MlCard from "@/components/project/mlComponents/MlCard";
import { useEffect } from "react";
import MlCardList from "@/components/project/mlComponents/MlCardList";
import { useSnackbar } from "notistack";
import { RootState, dispatch } from "@/redux/store";
import { getOrganizationDetailData } from "@/redux/project/administration/thunks";
import { useSelector } from "react-redux";
import { resetAdministrationData } from "@/redux/project/administration/slice";
import { Typography } from "@mui/material";
import Label from "@/components/project/Label";

enum StatusEnum {
  started = "success",
  closed = "default",
  updated = "secondary",
  failed = "error",
}

const OrganizationDetail = () => {
  const { orgId = "" } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const {
    userDetail: { organization_id },
  } = useSelector((state: RootState) => state.common);

  const {
    organizationDetailData: { data, loading },
  } = useSelector((state: RootState) => state.administration);

  const { resource_config: { aws = {}, k8s = {} } = {} } = data;

  const navigate = useNavigate();

  const getLinks = () => {
    return [
      {
        name: "Administration",
      },
      {
        name: "Organization",
        href: PATH_DASHBOARD.administration.organization,
      },
      {
        name: data?.name || "",
      },
    ];
  };

  const getOrgBaseInfoList = () => {
    return [
      { subTitle: "Name", label: data?.name || "-", loading },
      {
        subTitle: "Admin Security Group",
        label: data?.ad_group || "-",
        loading,
      },
      {
        subTitle: "Kubernetes Cluster",
        label: k8s?.cluster_names?.join(",") || "-",
        loading,
      },
      {
        subTitle: "Status",
        label: data?.status ? (
          <Label color={StatusEnum[data?.status]} sx={{ width: "80px" }}>
            {data?.status}
          </Label>
        ) : (
          "-"
        ),
        loading,
      },
      { subTitle: "AWS Region", label: aws?.region || "-", loading },
      { subTitle: "Created At", label: data?.created_at || "-", loading },
      { subTitle: "Last Modified", label: data?.updated_at || "-", loading },
      {
        subTitle: "Description",
        label: data?.description ? (
          <Tooltip title={data?.description}>
            <Typography
              variant="body2"
              textOverflow="ellipsis"
              noWrap
              sx={{ color: "text.secondary" }}
            >
              {data?.description}
            </Typography>
          </Tooltip>
        ) : (
          "-"
        ),
        loading,
      },
    ];
  };

  useEffect(() => {
    dispatch(
      getOrganizationDetailData({
        id: orgId,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );

    return () => {
      dispatch(resetAdministrationData("organizationDetailData"));
    };
  }, []);

  return (
    <Page
      title={`${data?.name} | OmniML`}
      heading={data?.name || ""}
      links={getLinks()}
    >
      <MlCard
        isCanFold
        title="Basic Infomation"
        extraJsx={
          <Button
            disabled={
              !(
                organization_id &&
                (data.status === "started" ||
                  data.status === "draft" ||
                  data.status === "failed")
              )
            }
            variant="outlined"
            color="secondary"
            sx={{ width: 120 }}
            onClick={() => navigate("edit")}
          >
            Edit
          </Button>
        }
      >
        <MlCardList list={getOrgBaseInfoList()} />
      </MlCard>
      <ProjectListTable state={data} />
    </Page>
  );
};

export default OrganizationDetail;

import { checkCode, handleErrorMessage } from "@/utils/project/request";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { MlParams } from "@/@types/project/global";
import {
  addProjectRoleService,
  addProjectTagService,
  addSecretService,
  addTagsDataService,
  createProjectService,
  delProjectRoleService,
  delProjectRolesUser,
  delProjectTagService,
  deleteProjectService,
  getOrganizationDetail,
  getOrganizationList,
  getProjectDetail,
  getProjectList,
  getProjectRolesByProjectId,
  getProjectRolesUsersByRoleId,
  getProjectTagService,
  getTagDetailService,
  getTagsDataService,
  getUsersDataService,
  updateOrganizationService,
  updateProjectRoleService,
  updateProjectService,
  updateSecretService,
} from "@/services/administration";
import { getProjectRoleDetail } from "../template/thunks";
import { dispatch } from "@/redux/store";
import { setPermissDeniedObj } from "./slice";

// 1. organization
interface IGetOrganizationParams extends MlParams {
  params: {
    name?: string;
    id?: string;
    page_num: number;
    page_size: number;
  };
}

// here needs to query all orgs
export const getOrgTableData = createAsyncThunk(
  "common/getOrgTableData",
  async (
    { params, alertCallback, pathname }: IGetOrganizationParams,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await getOrganizationList(params);
      const res = checkCode(data, pathname) as Object & {
        items: Array<Record<any, any>>;
        total: number;
      };

      // org list filtered sys_admin and sys_ops
      const filteredItems = res.items.filter(
        (item) => item.name !== "sys-admin" && item.name !== "sys-ops"
      );

      let filterTotal = res.total;
      if (res.items.findIndex((item) => item.name === "sys-admin") !== -1) {
        filterTotal += -1;
      }

      if (res.items.findIndex((item) => item.name === "sys-ops") !== -1) {
        filterTotal += -1;
      }

      const temRes = {
        ...res,
        items: filteredItems,
        total: filterTotal,
      };

      return temRes;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching organization data failed");
    }
  }
);

interface IGetOrganizationDetailParams extends MlParams {
  id: string;
}

export const getOrganizationDetailData = createAsyncThunk(
  "common/getOrganizationDetailData",
  async (
    { id, alertCallback, pathname }: IGetOrganizationDetailParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getOrganizationDetail(id);
      const res = checkCode(data, pathname) as Object & {
        resource_config: string;
      };
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching organization detail data failed");
    }
  }
);

interface IUpdateOrganizationParams extends MlParams {
  id: string;
  params: {
    name?: string;
    resource_config?: Record<any, any>;
    description?: string;
    status?: string;
  };
}
export const updateOrganization = createAsyncThunk(
  "common/updateOrganization",
  async (
    { id, params, alertCallback, pathname }: IUpdateOrganizationParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await updateOrganizationService(id, params);
      checkCode(data, pathname) as Object;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Update organization data failed");
    }
  }
);

// -------------------------------------------------------------
// 2. project
interface IGetProjectParams extends MlParams {
  params: {
    name?: string;
    organization_id?: string;
    page_num: number;
    page_size: number;
  };
  isAll?: boolean;
}

export const getProjectTableData = createAsyncThunk(
  "common/getProjectTableData",
  async (
    { params, isAll, alertCallback, pathname }: IGetProjectParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getProjectList(params, isAll);
      const res = checkCode(data, pathname) as Object & {
        items: Array<Record<any, any>>;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching organization data failed");
    }
  }
);

interface IGetProjectDetailParams extends MlParams {
  id: string;
  organization_id?: string;
}

export const getProjectDetailData = createAsyncThunk(
  "common/getProjectDetailData",
  async (
    { id, organization_id, alertCallback, pathname }: IGetProjectDetailParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getProjectDetail(id, organization_id);
      const res = checkCode(data, pathname) as Object & {
        organization_id: string;
        resource_config: string;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching project detail data failed");
    }
  }
);

interface IGetProjectTags extends MlParams {
  params: {
    project_id: string;
    page_num: number;
    page_size: number;
  };
}

// get project_tag
export const getProjectTags = createAsyncThunk(
  "common/getProjectTags",
  async (
    { params, alertCallback, pathname }: IGetProjectTags,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getProjectTagService(params);
      const res = checkCode(data, pathname);

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching project tags failed");
    }
  }
);

interface IAddProjectTagParams extends MlParams {
  params: {
    project_id: string;
    tag_id: string;
    organization_id: string;
  };
}

export const addProjectTag = createAsyncThunk(
  "common/addProjectTag",
  async (
    { params, alertCallback, pathname }: IAddProjectTagParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addProjectTagService(params);
      checkCode(data, pathname) as Object;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add project tag failed");
    }
  }
);

interface IDelProjectTagParams extends MlParams {
  id: string;
}

export const delProjectTag = createAsyncThunk(
  "common/delProjectTag",
  async (
    { id, alertCallback, pathname }: IDelProjectTagParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delProjectTagService(id);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete project tag failed");
    }
  }
);

interface ICreateProjectParams extends MlParams {
  params: Record<string, any>;
}
export const createProject = createAsyncThunk(
  "common/createProject",
  async (
    { params, alertCallback, pathname }: ICreateProjectParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createProjectService(params);
      const { code, msg, result } = data;
      // handle duplicate project_name
      if (code === 20000) {
        return result;
      }
      if (
        code === 40001 &&
        result.includes("Duplicate") &&
        result.includes("project.name")
      ) {
        throw new Error(
          "This project name already exists in the system, please input a different name."
        );
      }
      if (code === 42001 && pathname) {
        dispatch(setPermissDeniedObj(pathname));
      }

      throw new Error(`${code} ${msg || "There exists some errors"}`);
    } catch (e) {
      const error = e as Error;
      console.log("error", error);
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Create project data failed");
    }
  }
);

interface IUpdateProjectParams extends MlParams {
  id: string;
  params: {
    project_type: string;
    name: string;
    organization_id: string;
    description?: string;
    resource_config: Record<any, any>;
    status?: string;
  };
}

export const updateProject = createAsyncThunk(
  "common/updateProject",
  async (
    { id, params, alertCallback, pathname }: IUpdateProjectParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await updateProjectService(id, params);
      checkCode(data, pathname) as Object;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Update project data failed");
    }
  }
);

interface IDelProjectParams extends MlParams {
  id: string;
}

export const delProject = createAsyncThunk(
  "common/delProject",
  async (
    { id, alertCallback, pathname }: IDelProjectParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await deleteProjectService(id);
      checkCode(data, pathname) as Object;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete project data failed");
    }
  }
);

// -------------------------------------------------------------

// 3. project role
interface IProjectRolesTableDataParams extends MlParams {
  params: {
    name?: string;
    project_id: string;
    page_num: number;
    page_size: number;
  };
  hasDescription?: boolean;
  isAll?: boolean;
}

// project - role table
export const getProjectRolesTableDataInProject = createAsyncThunk(
  "common/getProjectRolesTableDataInProject",
  async (
    {
      params,
      isAll,
      hasDescription = false,
      alertCallback,
      pathname,
    }: IProjectRolesTableDataParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await getProjectRolesByProjectId(params, isAll);
      const res = checkCode(data, pathname) as {
        items: Array<Object & { role_id: string }>;
      };

      let lastRes: any = res;
      if (hasDescription) {
        // get role detail to join
        const lastItems = (await Promise.allSettled(
          lastRes.items?.map(async (item) => {
            const roleDetail = await dispatch(
              getProjectRoleDetail({
                id: item.role_id,
                alertCallback,
                pathname,
              })
            ).unwrap();

            return {
              ...item,
              name: roleDetail.name,
              description: roleDetail.description,
            };
          })
        )) as Array<{ value: Record<any, any> }>;

        lastRes = {
          ...res,
          items: lastItems.map((i) => i.value),
        };
      }

      return lastRes;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching project roles data failed");
    }
  }
);

interface IUpdateProjectRoleParams extends MlParams {
  id: string;
  params: {
    role_id: string;
    project_id: string;
    ad_group: string;
    is_active?: 1;
  };
}

// update project - role table
// when project_type is production
export const handleUpdateProjectRole = createAsyncThunk(
  "common/handleUpdateProjectRole",
  async (
    { id, params, alertCallback, pathname }: IUpdateProjectRoleParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await updateProjectRoleService(id, params);
      checkCode(data, pathname) as Object;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Update project role failed");
    }
  }
);

interface IAddProjectRoleParams extends MlParams {
  params: {
    role_id: string;
    project_id: string;
    ad_group: string;
    organization_id: string;
  };
}

// add project role
export const handleAddProjectRole = createAsyncThunk(
  "common/handleAddProjectRole",
  async (
    { params, alertCallback, pathname }: IAddProjectRoleParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addProjectRoleService(params);
      checkCode(data, pathname) as Object;
    } catch (e) {
      const error = e as Error;
      console.log("error", error);

      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add project role failed");
    }
  }
);

interface IDelProjectRoleParams extends MlParams {
  id: string;
}

// delete project role
export const handleDeleteProjectRole = createAsyncThunk(
  "common/handleDeleteProjectRole",
  async (
    { id, alertCallback, pathname }: IDelProjectRoleParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delProjectRoleService(id);
      checkCode(data, pathname) as Object;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete project role failed");
    }
  }
);

// -------------------------------------------------------------

// 4. project role user
interface IProjectRolesUserTableDataParams extends MlParams {
  params: {
    ad_group?: string;
    projectrole_id: string;
    page_num: number;
    page_size: number;
  };
}

// project role user table
export const getProjectRolesUsersTableDataInProject = createAsyncThunk(
  "common/getProjectRolesUsersTableDataInProject",
  async (
    { params, alertCallback, pathname }: IProjectRolesUserTableDataParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getProjectRolesUsersByRoleId(params);
      const res = checkCode(data, pathname) as Object;

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching project roles users data failed");
    }
  }
);

interface IDelUser extends MlParams {
  id: string;
}

//  delete project role user table
export const deleteProjectRoleUserById = createAsyncThunk(
  "common/deleteProjectRoleUserById",
  async ({ id, alertCallback, pathname }: IDelUser, { rejectWithValue }) => {
    try {
      const { data } = await delProjectRolesUser(id);
      checkCode(data, pathname) as Object;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete project role user failed");
    }
  }
);

//  add project role user table
interface IAddProjectRoleUserParams extends MlParams {
  params: {
    user_id: string;
    projectrole_id: string;
  };
}

export const handleAddProjectRolesUser = createAsyncThunk(
  "common/handleAddProjectRolesUser",
  async (
    { params, alertCallback, pathname }: IAddProjectRoleUserParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getUsersDataService(params);
      checkCode(data, pathname) as Object;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add project role user failed");
    }
  }
);

interface IUsersParams extends MlParams {
  params: {
    privileged?: number;
    page_num: number;
    page_size: number;
  };
}

// users
export const getUsers = createAsyncThunk(
  "common/getUsers",
  async (
    { params, alertCallback, pathname }: IUsersParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getUsersDataService(params);
      const res = checkCode(data, pathname) as Object;

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching project roles users data failed");
    }
  }
);

interface ITagsParams extends MlParams {
  params: {
    is_active?: number;
    page_num: number;
    page_size: number;
  };
}

// tags
export const getTags = createAsyncThunk(
  "common/getTags",
  async (
    { params, alertCallback, pathname }: ITagsParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getTagsDataService(params);
      const res = checkCode(data, pathname) as Object;

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching tags data failed");
    }
  }
);

interface ITagDetailParams extends MlParams {
  id: string;
}

export const getTagDetail = createAsyncThunk(
  "common/getTagDetail",
  async (
    { id, alertCallback, pathname }: ITagDetailParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getTagDetailService(id);
      const res = checkCode(data, pathname) as Object & { name: string };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching tag detail data failed");
    }
  }
);

interface IAddTagsParams extends MlParams {
  params: {
    is_active?: number;
    name: string;
  };
}

// tags
export const addTags = createAsyncThunk(
  "common/addTags",
  async (
    { params, alertCallback, pathname }: IAddTagsParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addTagsDataService(params);
      const res = checkCode(data, pathname) as { id: string };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add tags failed");
    }
  }
);

interface IAddSecretParams extends MlParams {
  params: {
    name: string;
    content: {
      aws_default_region?: string;
      aws_access_key_id?: string | number;
      aws_secret_access_key?: string | number;
      username?: string;
      password?: string;
    };
  };
}

// secret
export const addSecret = createAsyncThunk(
  "common/addSecret",
  async (
    { params, alertCallback, pathname }: IAddSecretParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addSecretService(params);
      const { code, msg, result } = data;
      // const res = checkCode(data, pathname) as { arn: string };

      if (code === 42001 && pathname) {
        dispatch(setPermissDeniedObj(pathname));
        throw new Error(`${code} ${msg || "There exists some errors"}`);
      }

      if (msg.includes("already exists")) {
        return { isExisted: true };
      }

      return result;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add secret failed");
    }
  }
);

interface IUpdateSecretParams extends MlParams {
  name: string;
  params: {
    content: {
      aws_default_region?: string;
      aws_access_key_id?: string | number;
      aws_secret_access_key?: string | number;
      username?: string;
      password?: string;
    };
  };
}

export const updateSecret = createAsyncThunk(
  "common/updateSecret",
  async (
    { name, params, alertCallback, pathname }: IUpdateSecretParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await updateSecretService(name, params);
      const res = checkCode(data, pathname);

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add secret failed");
    }
  }
);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Form, Field, useFormik, FormikProvider } from "formik";
import {
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Stack,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router";
import MlCard from "@/components/project/mlComponents/MlCard";
import Scrollbar from "@/components/project/Scrollbar";
import { RootState, dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import {
  addSecret,
  updateOrganization,
  updateSecret,
} from "@/redux/project/administration/thunks";
import { PATH_DASHBOARD } from "@/routes/paths";
import { LoadingButton } from "@mui/lab";
import MlPasswordField from "@/components/project/mlComponents/MlPasswordField";
import { useSelector } from "react-redux";
import { isEmpty, isEqual } from "lodash";
import { Switch } from "@mui/material";
import { extractKeys } from "@/utils/project/utils";

const OrgEditForm: React.FC = () => {
  const navigate = useNavigate();
  const { orgId = "" } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  // save the initilial form data
  const initialData = useRef<Record<string, any>>({});

  // determine whether execution is required
  const [statusFlag, setStatusFlag] = useState<boolean>(true);

  const {
    organizationDetailData: { data },
  } = useSelector((state: RootState) => state.administration);

  const {
    resource_config: {
      aws: { region = "", secret_name = "" } = {},
      k8s: { cluster_names = [] } = {},
    } = {},
  } = data;

  const initialValues = {
    region: "us-east-1",
    accessKey: "",
    secretKey: "",
    isAKSKRequired: false,
    cluster: ["default-k8s"],
    description: "",
  };

  const validationSchema = Yup.object({
    region: Yup.string().required("Region is required"),
    accessKey: Yup.string().when("isAKSKRequired", {
      is: true,
      then: Yup.string()
        .required("Access Key is required")
        .max(64, "Access Key cannot be longer than 64 characters"),
      otherwise: Yup.string(),
    }),
    secretKey: Yup.string().when("isAKSKRequired", {
      is: true,
      then: Yup.string()
        .required("Secret Key is required")
        .max(64, "Secret Key cannot be longer than 64 characters"),
      otherwise: Yup.string(),
    }),
    cluster: Yup.array()
      .of(Yup.string())
      .min(1, "Kubernetes Cluster must contain at least one cluster"),
    description: Yup.string().max(
      400,
      "Description cannot be longer than 400 characters"
    ),
  });

  const isValuesEqual = (value1, value2) => {
    const orgFields = [
      "region",
      "cluster",
      "description",
      "accessKey",
      "secretKey",
    ];

    return isEqual(
      extractKeys(value1, ...orgFields),
      extractKeys(value2, ...orgFields)
    );
  };

  const handleSubmit = async (values) => {
    if (secret_name) {
      if (values.isAKSKRequired) {
        // update the organization secret
        await dispatch(
          updateSecret({
            name: secret_name,
            params: {
              content: {
                aws_default_region: "us-east-1",
                aws_access_key_id: values.accessKey,
                aws_secret_access_key: values.secretKey,
              },
            },
            pathname,
            alertCallback: enqueueSnackbar,
          })
        ).unwrap();
        // secret_name will not change, only need update the secret
      } else {
        // not edit, do nothing
      }
    } else {
      // create a new secret with aksk to get arn
      const tempSecretName = `organization+${orgId}+aws_account_aksk`;
      const { isExisted } = await dispatch(
        addSecret({
          params: {
            name: tempSecretName,
            content: {
              aws_default_region: "us-east-1",
              aws_access_key_id: values.accessKey,
              aws_secret_access_key: values.secretKey,
            },
          },
          pathname,
          alertCallback: enqueueSnackbar,
        })
      ).unwrap();

      if (isExisted) {
        // if existed, update the secret with ak/sk
        const tempSecretName = `organization+${orgId}+aws_account_aksk`;
        await dispatch(
          updateSecret({
            name: tempSecretName,
            params: {
              content: {
                aws_default_region: "us-east-1",
                aws_access_key_id: values.accessKey,
                aws_secret_access_key: values.secretKey,
              },
            },
            pathname,
            alertCallback: enqueueSnackbar,
          })
        ).unwrap();
      }
    }

    const config = {
      aws: {
        region: values.region,
        secret_name: `organization+${orgId}+aws_account_aksk`,
      },
      k8s: {
        cluster_names: values.cluster,
      },
    };

    // edit the organization
    if (!isValuesEqual(values, initialData.current)) {
      // edited
      await dispatch(
        updateOrganization({
          id: orgId,
          params: {
            name: values.name,
            resource_config: config,
            description: values.description,
            ...(statusFlag ? { status: "wait_start" } : {}),
          },
          pathname,
          alertCallback: enqueueSnackbar,
        })
      ).unwrap();
    } else {
      if (statusFlag) {
        // only restart the organization execution
        await dispatch(
          updateOrganization({
            id: orgId,
            params: {
              status: "wait_start",
            },
            pathname,
            alertCallback: enqueueSnackbar,
          })
        ).unwrap();
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      try {
        await handleSubmit(values);
        enqueueSnackbar("Edit Success", { variant: "success" });
        actions.setSubmitting(false);

        navigate(PATH_DASHBOARD.administration.organization);
      } catch (e) {
        console.log("error-in-submit", e);
        actions.setSubmitting(false);
      }
    },
  });

  const { values, errors, touched, isSubmitting, setFieldValue } = formik;

  useEffect(() => {
    if (!isEmpty(data)) {
      setFieldValue("region", region);
      setFieldValue("cluster", cluster_names);

      setFieldValue("description", data.description);

      if (isEmpty(secret_name)) {
        setFieldValue("isAKSKRequired", true);
      }

      initialData.current = {
        region: region,
        accessKey: "",
        secretKey: "",
        cluster: cluster_names,
        description: data.description,
      };
    }
  }, [data]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <MlCard title="Basic Infomation">
          <Scrollbar>
            <Box sx={{ pb: 2, width: "800px" }}>
              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={touched.region && Boolean(errors.region)}
              >
                <InputLabel>AWS Region</InputLabel>
                <Field as={Select} name="region" label="Account Region">
                  <MenuItem value="us-east-1">us-east-1</MenuItem>
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {touched.region && errors.region
                    ? errors.region
                    : "AWS account region, default us-east-1"}
                </FormHelperText>
              </FormControl>
              {/* secret_name exists and is not checked by default, secret_name does not exist, it is checked by default and cannot be changed  */}
              <FormControlLabel
                control={
                  // checked to value
                  <Field name="isAKSKRequired">
                    {({ field }) => (
                      <Switch
                        {...field}
                        checked={secret_name ? field.value || false : true} // init undefined to false
                        disabled={isEmpty(secret_name)}
                        onChange={(_, checked) => {
                          setFieldValue(field.name, checked);
                          if (!checked) {
                            setFieldValue("accessKey", "");
                            setFieldValue("secretKey", "");
                          }
                        }}
                        size="small"
                      />
                    )}
                  </Field>
                }
                label="Edit AWS Account AKSK"
                sx={{ pl: 1, pb: 1.5 }}
              />
              {values.isAKSKRequired && (
                <>
                  <MlPasswordField
                    name="accessKey"
                    label="Access Key"
                    formik={formik}
                  />
                  <MlPasswordField
                    name="secretKey"
                    label="Secret Key"
                    formik={formik}
                  />
                </>
              )}
              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={touched.cluster && Boolean(errors.cluster)}
              >
                <InputLabel>Kubernetes Cluster</InputLabel>
                <Field
                  as={Select}
                  multiple
                  name="cluster"
                  label="Kubernetes Cluster"
                >
                  <MenuItem value="default-k8s">default-k8s</MenuItem>
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {touched.cluster && errors.cluster ? errors.cluster : " "}
                </FormHelperText>
              </FormControl>
              <Field
                as={TextField}
                multiline
                rows={4}
                name="description"
                label="Description (Optional)"
                size="small"
                fullWidth
                margin="dense"
                helperText={touched.description && errors.description}
                error={touched.description && Boolean(errors.description)}
                FormHelperTextProps={{
                  sx: { minHeight: "18px", marginTop: "2px" },
                }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={statusFlag}
                    onChange={(evevnt) => {
                      setStatusFlag(evevnt.target.checked);
                    }}
                  />
                }
                sx={{ pl: 1.5 }}
                label="Synchronize this organization immediately"
              />
            </Box>
          </Scrollbar>
        </MlCard>
        <Stack direction="row" justifyContent="center" spacing={2}>
          <LoadingButton
            disabled={isValuesEqual(values, initialData.current) && !statusFlag}
            type="submit"
            variant="contained"
            loading={isSubmitting}
            color="primary"
            sx={{ width: "200px", color: "background.paper" }}
          >
            Save
          </LoadingButton>
          <Button
            type="submit"
            variant="outlined"
            color="inherit"
            sx={{ width: "200px", color: "text.secondary" }}
            onClick={() => {
              navigate(PATH_DASHBOARD.administration.organization);
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default OrgEditForm;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Typography,
  IconButton,
  Stack,
  Paper,
} from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import Scrollbar from "@/components/project/Scrollbar";
import { useSnackbar } from "notistack";
import { RootState, dispatch, useSelector } from "@/redux/store";
import { getServiceInferenceList } from "@/redux/project/mlService/mlRuntime/thunks";
import MlTableBodyContainer from "@/components/project/mlComponents/MlTableBodyContainer";
import MlCard from "@/components/project/mlComponents/MlCard";
import { useLocation } from "react-router";
import moment from "moment";

type Props = {
  startTime: Date | null;
  endTime: Date | null;
};

const ServiceInferenceTable = ({ startTime, endTime }: Props) => {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    serviceInferenceResultList: { data, loading },
  } = useSelector((state: RootState) => state.mlRuntime);

  const columns = [
    { id: "id" },
    { id: "model_name", label: "Deployment", minWidth: 150 },
    { id: "prediction", label: "Prediction", minWidth: 150 },
    { id: "ground_truth", label: "Ground Truth", minWidth: 100 },
    { id: "created_at", label: "Inference Time", minWidth: 150 },
    { id: "result_tag", minWidth: 120 },
  ];

  const fetchData = (params: { page_num?: number; page_size?: number }) => {
    return dispatch(
      getServiceInferenceList({
        startTime: moment(startTime).valueOf(),
        endTime: moment(endTime).valueOf(),
        params,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  };

  useEffect(() => {
    fetchData({ page_num: 1, page_size: 9999 });
  }, []);

  const handleRefresh = () => {
    fetchData({ page_num: 1, page_size: 9999 })
      .unwrap()
      .then(() => {
        enqueueSnackbar("Refresh Success", { variant: "success" });
      });
  };

  return (
    <MlCard
      title="Service Inference Result"
      extraJsx={
        <Stack direction="row" alignItems="center" spacing={2}>
          <IconButton onClick={handleRefresh}>
            <RefreshOutlinedIcon sx={{ width: "22px", height: "22px" }} />
          </IconButton>
        </Stack>
      }
      hasPaddingX={false}
      sx={{ width: "100%" }}
    >
      <Scrollbar>
        <TableContainer sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <MlTableBodyContainer loading={loading} data={data?.items || []}>
              <TableBody>
                {data?.items?.map((row) => (
                  <Row key={row.id} row={row} />
                ))}
              </TableBody>
            </MlTableBodyContainer>
          </Table>
        </TableContainer>
      </Scrollbar>
    </MlCard>
  );
};

function Row({ row }) {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell key="id">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell key="model_name">
          <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
            {row.model_name}
          </Typography>
        </TableCell>
        <TableCell key="prediction">
          <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
            {row.prediction}
          </Typography>
        </TableCell>
        <TableCell key="ground_truth">
          <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
            {row.ground_truth}
          </Typography>
        </TableCell>
        <TableCell key="created_at">
          <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
            {row.created_at}
          </Typography>
        </TableCell>
        <TableCell key="result_tag">
          <Stack direction="row" alignItems="center" spacing={2}>
            <IconButton>
              <ThumbUpIcon
                color={row.result_tag === "like" ? "success" : "inherit"}
                sx={{ width: "22px", height: "22px" }}
              />
            </IconButton>
            <IconButton>
              <ThumbDownIcon
                color={row.result_tag === "dislike" ? "error" : "inherit"}
                sx={{ width: "22px", height: "22px" }}
              />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Paper
              sx={{
                p: 1.5,
                mx: 1,
                mb: 2,
                borderRadius: 1,
                bgcolor: "background.neutral",
                height: { xs: "auto", md: "auto" },
                maxHeight: { xs: "600px", md: "400px" },
              }}
            >
              <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                {row.result_tag}
              </Typography>
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default ServiceInferenceTable;

// material
import { Stack, Switch, Tooltip } from "@mui/material";
//
import PipelineParameterSelector from "./PipelineParameterSelector";
import { PipelineSelect } from "./PipelineComponents";

// ----------------------------------------------------------------------

type Props = {
  label: string;
  field: string;
  fieldUseParam: string;
  formik: any;
  options: { code: string; label: string }[];
  placeholder: string;
};

export default function PipelineParameterWithSelector({
  label,
  field,
  fieldUseParam,
  formik,
  options,
  placeholder,
}: Props) {
  const { values, getFieldProps } = formik;

  return (
    <>
      <Stack
        spacing={0.1}
        direction="row"
        alignItems="center"
        sx={{ width: "103%" }}
      >
        {values[fieldUseParam] ? (
          <PipelineParameterSelector
            label={label}
            placeholder="Select a parameter"
            {...getFieldProps(field)}
          />
        ) : (
          <PipelineSelect
            label={label}
            field={field}
            placeholder={placeholder}
            options={options}
            getFieldProps={getFieldProps}
          />
        )}
        <Tooltip
          title="Open pipeline params"
          enterDelay={1000}
          enterTouchDelay={1000}
          arrow
          sx={{ zIndex: 3000 }}
        >
          <Switch
            size="small"
            {...getFieldProps(fieldUseParam)}
            checked={values[fieldUseParam]}
          />
        </Tooltip>
      </Stack>
    </>
  );
}

import axios, { AxiosRequestConfig, AxiosError } from "axios";
// config
import { TOKEN_FLAG } from "@/config";
// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  // headers: {
  //   "Host": "rrjb40opf8.execute-api.us-east-1.amazonaws.com/dev",
  // }
});

// axiosInstance.interceptors.request.use(
//   (config) => {
//     config.headers["request-starttime"] = new Date().toISOString();
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }

// );

// axiosInstance.interceptors.response.use(
//   (response) => {
//     const endTime = new Date();
//     const duration =
//       (endTime as any) -
//       Date.parse(response.config.headers["request-starttime"]);
//     response.headers["duration"] = duration;
//     return response;
//   },

//   (error) =>
//     Promise.reject(
//       (error.response && error.response.data) || "Something went wrong"
//     )

//   // (error) => {
//   //   error.config.metadata.endTime = new Date();
//   //   error.duration =
//   //     error.config.metadata.endTime - error.config.metadata.startTime;
//   //   return Promise.reject(
//   //     (error.response && error.response.data) || "Something went wrong"
//   //   );
//   // }
// );

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token: string | null = localStorage.getItem(TOKEN_FLAG);
    // console.log("token", token);
    if (token) {
      config.headers = config.headers || {};
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  }
);

export default axiosInstance;

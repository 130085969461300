import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useState } from "react";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import { DeleteIcon, EditIcon } from "@/assets/project/icons";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import { dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router";
import { delNotebookVersion } from "@/redux/project/experiment/thunks";

interface IProps {
  row: {
    [key: string]: any;
  };
  setDialogMode: () => void;
  handleRefresh: (a?: boolean) => void;
}

const NotebookVersionTableActionCol = ({
  row,
  setDialogMode,
  handleRefresh,
}: IProps) => {
  // delete confirm
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);

  // dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelNbVersion = async () => {
    setDelLoading(true);
    try {
      await dispatch(
        delNotebookVersion({
          id: row.id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      setDelLoading(false);
      setOpenConfirm(false);
      enqueueSnackbar("Delete Success", { variant: "success" });

      handleRefresh();
    } catch (e) {
      setDelLoading(false);
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertOutlinedIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          key="edit"
          onClick={() => {
            setDialogMode();
            handleClose();
          }}
        >
          <ListItemIcon sx={{ mr: 1 }}>
            <SvgIconStyle
              src={EditIcon}
              sx={{
                width: 22,
                height: 22,
              }}
            />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: "body2" }}>
            Edit
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setOpenConfirm(true);
            handleClose();
          }}
          key="delete"
          sx={{ color: "error.main" }}
        >
          <ListItemIcon sx={{ mr: 1 }}>
            <SvgIconStyle
              src={DeleteIcon}
              sx={{
                width: 22,
                height: 22,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete notebook version{" "}
            <strong> {row.name} </strong> item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelNbVersion();
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
};

export default NotebookVersionTableActionCol;

import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch } from "react-redux";
// material
import { Stack, Radio, RadioGroup, FormControlLabel } from "@mui/material";
//
import { ConditionStruct } from "@/@types/project/mlPipeline/SageMaker/pipeline-condition";
import { LeftRightValueStruct } from "@/@types/project/mlPipeline/SageMaker/pipeline-condition";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";
import { updateCondition } from "../../../graph/slices/slice";

// ----------------------------------------------------------------------

const sources = [
  { value: "value", label: "Value" },
  { value: "propertyFile", label: "Property file" },
];

export default function LeftRightInput({
  id,
  side,
  data,
  condition,
}: {
  id: number;
  side: string;
  data?: LeftRightValueStruct;
  condition: ConditionStruct;
}) {
  const dispatch = useDispatch();
  const [isInitialzied, setInitialized] = useState(false);
  const [inputValues, setInputValues] = useState(data);

  useEffect(() => {
    if (isInitialzied) {
      const timer = setTimeout(() => {
        const newData = {
          ...condition,
        };

        if (inputValues !== undefined) {
          if (side === "left") newData.leftValue = inputValues;
          else newData.rightValue = inputValues;
        }

        dispatch(
          updateCondition({
            id: id,
            data: newData,
          })
        );
        setInitialized(false);
      }, 200);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [dispatch, id, inputValues, isInitialzied]);

  const ParamSchema = Yup.object().shape({
    // value: Yup.string().required("Value is required"),
  });

  // console.log("data");
  // console.log(data);

  const formik = useFormik<LeftRightValueStruct>({
    initialValues:
      data === undefined
        ? { source: "value", value: "", valueUseParam: false }
        : data,
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values, { setErrors }) => {
      try {
        setInitialized(true);
        setInputValues(values);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { values, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onChange={handleSubmit}>
          <Stack spacing={2}>
            <RadioGroup {...getFieldProps("source")} row>
              <Stack
                spacing={0.1}
                direction="row"
                sx={{ ml: 0.5, mt: -0.5, mb: -0.5 }}
              >
                {sources.map((source) => (
                  <FormControlLabel
                    key={source.value}
                    value={source.value}
                    control={<Radio size="small" />}
                    label={source.label}
                  />
                ))}
              </Stack>
            </RadioGroup>

            {values.source === "value" && (
              <>
                <PipelineParameterEntry
                  label={"Value"}
                  field={"value"}
                  fieldUseParam={"valueUseParam"}
                  formik={formik}
                />
              </>
            )}

            {values.source === "propertyFile" && (
              <>
                <PipelineParameterEntry
                  label={"File"}
                  field={"value"}
                  fieldUseParam={"valueUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Json key"}
                  field={"jsonKey"}
                  fieldUseParam={"jsonKeyUseParam"}
                  formik={formik}
                />
              </>
            )}
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}

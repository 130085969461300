import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Box, Stack, Collapse, IconButton } from "@mui/material";
//
import EmptyTable from "@/components/project/EmptyTable";
import { CollapseButtonStyle } from "../../../../SettingSidebar";
import TrainingInputsItem from "./partComponents/TrainingInputsItem";
import { TrainingStep } from "@/@types/project/mlPipeline/Kubeflow/pipeline-training";
import { handleKubeflowStepArray } from "@/pages/project/automation/MlPipeline/graph/slices/slice";

// ----------------------------------------------------------------------

type Props = {
  currentStep: TrainingStep | null;
  isEdit: boolean;
};

export default function TrainingInputs({ currentStep, isEdit }: Props) {
  const dispatch = useDispatch();

  const [showTrainingInput, setShowTrainingInput] = useState(false);

  const trainingInputs = currentStep?.inputs || [];

  const handleAddTrainingInput = () => {
    if (!showTrainingInput) {
      setShowTrainingInput(true);
    }

    dispatch(
      handleKubeflowStepArray({
        handleType: "create",
        data: {
          name: `TrainingInput-${trainingInputs.length + 1}`,
          value: "",
          dataType: "String",
          description: "",
          useParam: false,
          paramType: 0,
        },
        key: "inputs",
      })
    );
  };

  return (
    <>
      <Box sx={{ mb: 0, position: "relative" }}>
        <CollapseButtonStyle
          fullWidth
          color="inherit"
          onClick={() => setShowTrainingInput((prev) => !prev)}
          endIcon={
            <Icon
              icon={
                showTrainingInput ? arrowIosDownwardFill : arrowIosForwardFill
              }
              width={16}
              height={16}
            />
          }
        >
          Inputs {trainingInputs.length > 0 && `(${trainingInputs.length})`}
        </CollapseButtonStyle>

        <IconButton
          sx={{ zIndex: 1999, position: "absolute", top: 7, right: 30 }}
          disabled={isEdit}
          size="small"
          onClick={handleAddTrainingInput}
        >
          <Icon icon={plusFill} width={16} height={16} />
        </IconButton>
      </Box>

      <Collapse in={showTrainingInput}>
        <Stack
          spacing={1.5}
          sx={{ mx: 2, mb: 1.5, mt: showTrainingInput ? 0.5 : 0 }}
        >
          {trainingInputs.length > 0 &&
            trainingInputs.map((trainingInput, index) => (
              <TrainingInputsItem
                length={trainingInputs.length}
                isEdit={isEdit}
                key={index}
                id={index}
                data={trainingInput}
              />
            ))}
        </Stack>
      </Collapse>

      {showTrainingInput && trainingInputs.length === 0 && (
        <EmptyTable
          emptyImage={false}
          mainTitle={"No training inputs"}
          content={'Create one by clicking "+" button'}
          color={"text.disabled"}
          sx={{ backgroundColor: "transparent", mx: 2, mt: 5, mb: 7 }}
        />
      )}
    </>
  );
}

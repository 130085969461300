import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import expandFill from "@iconify/icons-eva/expand-fill";
import arrowIosBackFill from "@iconify/icons-eva/arrow-ios-back-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
// material
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  Drawer,
  Button,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  IconButtonProps,
} from "@mui/material";
// @types
import { RootState, useDispatch, useSelector } from "@/redux/store";
// components
import { MHidden } from "@/components/project/@material-extend";
//
import PipelineSteps from "./PipelineSteps";
import PipelineParameters from "./PipelineParameters";
import PipelineStepConfig from "./PipelineStepConfig";
import PipelineEdgeConfig from "./PipelineEdgeConfig";
import PipelineConfigurations from "./PipelineConfigurations";
import {
  setShowPropPanel,
  SchemaNodeEntity,
  SchemaEdgeEntity,
} from "../graph/slices/slice";
//
import Scrollbar from "@/components/project/Scrollbar";
import AdvancedSettings from "./AdvancedSettings";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 260;

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
}));

const ToggleButtonStyle = styled((props) => (
  <IconButton disableRipple {...props} />
))<IconButtonProps>(({ theme }) => ({
  right: 0,
  zIndex: 9,
  width: 20,
  height: 32,
  padding: 2,
  position: "absolute",
  top: theme.spacing(7.5),
  boxShadow: theme.customShadows.z4,
  backgroundColor: theme.palette.background.paper,
  border: `solid 1px ${theme.palette.divider}`,
  borderRight: 0,
  borderRadius: `10px 0 0 10px`,
  transition: theme.transitions.create("all"),
  "&:hover": {
    backgroundColor: theme.palette.background.neutral,
  },
}));

export const ContentTypes = [
  { code: "A", label: "None" },
  { code: "B", label: "application/json" },
  { code: "C", label: "application/x-image" },
  { code: "D", label: "application/x-recordio" },
  { code: "E", label: "image/jpeg" },
  { code: "F", label: "image/png" },
  { code: "G", label: "text/csv" },
  { code: "H", label: "csv" },
];

export const InputModes = [
  { code: "A", label: "None" },
  { code: "B", label: "File" },
  { code: "C", label: "Pipe" },
  { code: "D", label: "FastFile" },
];

export const CollapseButtonStyle = styled(Button)(({ theme }) => ({
  ...theme.typography.overline,
  height: 40,
  // flexShrink: 0,
  borderRadius: 0,
  padding: theme.spacing(1, 2),
  justifyContent: "space-between",
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

type SettingSidebarProps = {
  name?: string;
  isEdit: boolean;
  onExpand: VoidFunction;
};

export default function SettingSidebar({
  isEdit,
  name,
  onExpand,
}: SettingSidebarProps) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { schema } = useSelector((state: RootState) => state.schema);
  const { currentPipeline, currentKubeflowPipeline } = useSelector(
    (state: RootState) => state.pipeline
  );
  const { pipelineType } = useSelector((state: RootState) => state.schema);
  const isKubeflow = pipelineType === "Kubeflow";
  const commonPipelineData = isKubeflow
    ? currentKubeflowPipeline
    : currentPipeline;

  console.log("schema", schema);
  console.log("commonPipelineData", commonPipelineData);

  const currentEntity = useSelector((state: RootState) => {
    const currentEntityId = state.schema.currentEntityId;
    let entity: SchemaNodeEntity | SchemaEdgeEntity | undefined =
      state.schema.schema.pipelineSteps.find((entity) => {
        return entity.id === currentEntityId;
      });
    if (!entity) {
      entity = state.schema.schema.edgeEntities.find(
        (entity) => entity.id === currentEntityId
      );
    }
    return entity ? entity : null;
  });

  const [openSidebar, setOpenSidebar] = useState(true);
  const [showPipelineConfig, setShowPipelineConfig] = useState(true);
  const [showPipelineSteps, setShowPipelineSteps] = useState(false);
  const [showPipelineParams, setShowPipelineParams] = useState(false);
  const [showPipelineAdvancedSettings, setShowPipelineAdvancedSettings] =
    useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const isEdgeEntity =
    (currentEntity as SchemaEdgeEntity)?.relationship != null;

  useEffect(() => {
    if (isMobile) {
      return handleCloseSidebar();
    }
    return handleOpenSidebar();
  }, [isMobile]);

  const handleOpenSidebar = () => {
    setOpenSidebar(true);
  };

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  const handleToggleSidebar = () => {
    setOpenSidebar((prev) => !prev);
    setTimeout(() => {
      dispatch(setShowPropPanel(true));
    }, 500);
  };

  const handleExpand = () => {
    onExpand();
  };

  const renderContent = (
    <>
      <Box
        sx={{
          pt: "8px",
          pb: "8px",
          ml: "15px",
          mr: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <LabelStyle variant="h6" color={theme.palette.text.secondary}>
          {currentEntity
            ? isEdgeEntity
              ? "STEP CONNECTIONS"
              : "STEP SETTINGS"
            : "PIPELINE SETTINGS"}
        </LabelStyle>

        <IconButton onClick={handleExpand}>
          <Icon icon={expandFill} width={18} height={18} />
        </IconButton>
      </Box>

      <Divider />
      <Scrollbar>
        {/* Pipeline Settings */}
        {!currentEntity && (
          <Box>
            <PipelineConfigurations
              name={name}
              isEdit={isEdit}
              isCollapse={showPipelineConfig}
              onCollapse={() => setShowPipelineConfig((prev) => !prev)}
            />

            <Divider />

            <PipelineSteps
              allSteps={schema.pipelineSteps}
              isCollapse={showPipelineSteps}
              onCollapse={() => setShowPipelineSteps((prev) => !prev)}
            />

            <Divider />

            <PipelineParameters
              parameters={commonPipelineData.pipelineParamList}
              isCollapse={showPipelineParams}
              onCollapse={() => setShowPipelineParams((prev) => !prev)}
              openCollapse={() => setShowPipelineParams(true)}
              isEdit={isEdit}
            />

            <Divider />

            <AdvancedSettings
              data={commonPipelineData.advancedSettings}
              isCollapse={showPipelineAdvancedSettings}
              onCollapse={() =>
                setShowPipelineAdvancedSettings((prev) => !prev)
              }
              isEdit={isEdit}
            />
          </Box>
        )}
        {currentEntity && isEdgeEntity ? (
          // edge settings
          <PipelineEdgeConfig entity={currentEntity as SchemaEdgeEntity} />
        ) : (
          // node settings
          <PipelineStepConfig
            isEdit={isEdit}
            entity={currentEntity as SchemaNodeEntity}
          />
        )}
      </Scrollbar>
    </>
  );

  return (
    <Box sx={{ position: "relative" }}>
      <ToggleButtonStyle
        onClick={handleToggleSidebar}
        sx={{
          ...(openSidebar && !isMobile && { right: DRAWER_WIDTH }),
        }}
      >
        <Icon
          width={16}
          height={16}
          icon={openSidebar ? arrowIosForwardFill : arrowIosBackFill}
        />
      </ToggleButtonStyle>

      <MHidden width="lgUp">
        <Drawer
          anchor="right"
          ModalProps={{ keepMounted: true }}
          open={openSidebar}
          onClose={handleCloseSidebar}
          sx={{
            height: 1,
            "& .MuiDrawer-paper": {
              width: DRAWER_WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open={openSidebar}
          anchor="right"
          variant="persistent"
          sx={{
            height: 1,
            width: DRAWER_WIDTH,
            transition: theme.transitions.create("width"),
            ...(!openSidebar && { width: "0px" }),
            "& .MuiDrawer-paper": {
              position: "static",
              width: DRAWER_WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </Box>
  );
}

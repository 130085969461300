/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";
import { useLocation } from "react-router";
// material
import { useTheme } from "@mui/material/styles";
import {
  getStatisticTotalList,
  getStatisticDailyList,
} from "@/redux/project/mlService/mlRuntime/thunks";
import { RootState, dispatch, useSelector } from "@/redux/store";
import { useSnackbar } from "notistack";
import StatisticPanel from "./StatisticPanel";
import StatusSummary from "./StatusSummary";
import { isEmpty } from "lodash";

export default function DashbordStatistic() {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [serviceChartList, setServiceChartList] = useState<
    Array<Record<string, any>>
  >([{ title: "Total Inferences" }, { title: "Deployments" }]);
  const [statusChartList, setStatusChartList] = useState<
    Array<Record<string, any>>
  >([]);
  const {
    statisticTotalView: {
      data: { items: totalData = [] },
    },
    statisticDailyView: {
      data: { items: dailyData = [] },
    },
  } = useSelector((state: RootState) => state.mlRuntime);

  const chartOptions: ApexOptions = {
    colors: [theme.palette.primary.main],
    chart: { sparkline: { enabled: true } },
    plotOptions: { bar: { columnWidth: "68%", borderRadius: 2 } },
    tooltip: {
      x: { show: false },
      y: {
        title: {
          formatter: (seriesName: number | string) => "",
        },
      },
      marker: { show: false },
    },
  };

  useEffect(() => {
    dispatch(
      getStatisticTotalList({
        params: { page_num: 1, page_size: 9999 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    dispatch(
      getStatisticDailyList({
        params: { page_num: 1, page_size: 9999 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  }, []);

  useEffect(() => {
    const serviceCharts = [...serviceChartList];
    const statusCharts = [] as Array<Record<string, any>>;
    if (!isEmpty(totalData)) {
      // eslint-disable-next-line array-callback-return
      totalData.map((item) => {
        if (
          item.name === "mlservice_result" ||
          item.name === "mlservice_deployment"
        ) {
          const curChart = dailyData.filter(
            (daily) => daily.name === item.name
          );
          const chartData: number[] = [];
          const labels: string[] = [];
          // eslint-disable-next-line array-callback-return
          curChart?.map((chart) => {
            chartData.push(chart.result);
            labels.push(chart.date);
          });
          const percent =
            (chartData[chartData.length - 1] - chartData[0]) / chartData[0];
          const trend = percent >= 0 ? "up" : "down";
          chartOptions.labels = labels;
          if (item.name === "mlservice_result") {
            serviceCharts[0].total = item.result;
            serviceCharts[0].trend = trend;
            serviceCharts[0].percent = percent;
            serviceCharts[0].chartData = chartData;
            serviceCharts[0].labels = labels;
          } else {
            serviceCharts[1].total = item.result;
            serviceCharts[1].trend = trend;
            serviceCharts[1].percent = percent;
            serviceCharts[1].chartData = chartData;
            serviceCharts[1].labels = labels;
          }
        } else {
          statusCharts.push(item);
        }
      });
      setServiceChartList(serviceCharts);
      setStatusChartList(statusCharts);
    }
  }, [totalData, dailyData]);

  return (
    <>
      {serviceChartList.map((item, index) => {
        return <StatisticPanel key={index} panelInfo={item} />;
      })}
      <StatusSummary statusChartList={statusChartList} />
    </>
  );
}

import {
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useLocation, useNavigate } from "react-router";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import { DeleteIcon, EditIcon } from "@/assets/project/icons";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import { RootState, dispatch } from "@/redux/store";
import { delProject } from "@/redux/project/administration/thunks";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

interface IProps {
  row: {
    id: string;
    [key: string]: any;
  };
  handleRefresh: (a?: boolean) => void;
}

const ProjectListTableActionCol = ({ row, handleRefresh }: IProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  // delete confirm
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);

  // dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {
    isSuperAdmin,
    userDetail: { organization_id },
  } = useSelector((state: RootState) => state.common);

  const isJustSuperAdmin = () => {
    return isSuperAdmin && !organization_id;
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelProject = async () => {
    setDelLoading(true);
    try {
      // projectGovernance manage don't allow delete
      await dispatch(
        delProject({
          id: row.id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      setDelLoading(false);
      setOpenConfirm(false);
      handleRefresh(true);
      enqueueSnackbar("Delete Success", { variant: "success" });
    } catch (e) {
      setDelLoading(false);
    }
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid
          item
          xs={isJustSuperAdmin() ? 12 : 6}
          lg={isJustSuperAdmin() ? 12 : 6}
        >
          <IconButton onClick={() => navigate(`${row.id}`)}>
            <KeyboardArrowRightOutlinedIcon />
          </IconButton>
        </Grid>
        {!isJustSuperAdmin() && (
          <Grid item xs={6} lg={6}>
            <IconButton onClick={handleClick}>
              <MoreVertOutlinedIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                key="edit"
                onClick={() => {
                  navigate(`${row.id}/projectEdit`);
                  handleClose();
                }}
              >
                <ListItemIcon sx={{ mr: 1 }}>
                  <SvgIconStyle
                    src={EditIcon}
                    sx={{
                      width: 22,
                      height: 22,
                    }}
                  />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                  Edit
                </ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpenConfirm(true);
                }}
                sx={{ color: "error.main" }}
              >
                <ListItemIcon sx={{ mr: 1 }}>
                  <SvgIconStyle
                    src={DeleteIcon}
                    sx={{
                      width: 22,
                      height: 22,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Delete"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>
            </Menu>
          </Grid>
        )}
      </Grid>

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete project <strong> {row.name} </strong> item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelProject();
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
};

export default ProjectListTableActionCol;

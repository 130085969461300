import SvgIconStyle from "@/components/project/SvgIconStyle";
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { DeleteIcon, StartIcon, StopIcon } from "@/assets/project/icons";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useState } from "react";
import { useLocation } from "react-router";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import { dispatch } from "@/redux/store";
import {
  delNotebook,
  delRelNotebookVolumeData,
  getRelNotebookVolumeData,
  updateNotebook,
} from "@/redux/project/experiment/thunks";
import { useSnackbar } from "notistack";
import { capitalCase } from "change-case";

interface IProps {
  row: {
    [key: string]: any;
  };
  handleRefresh: (a?: boolean) => void;
}

const NotebookTableActionCol = ({ row, handleRefresh }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  // delete confirm
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);

  // dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeStatus = async (statusFlag) => {
    const status =
      statusFlag === "start"
        ? "wait_start"
        : statusFlag === "stop"
        ? "wait_close"
        : "";
    await dispatch(
      updateNotebook({
        id: row.id,
        params: {
          status,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    enqueueSnackbar(`${capitalCase(statusFlag)} Success`, {
      variant: "success",
    });

    handleRefresh(true);
  };

  const handleDelNotebook = async () => {
    setDelLoading(true);
    try {
      await dispatch(
        delNotebook({
          id: row.id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      // get the hash_id in rel_notebook_notebook_volume
      const data = await dispatch(
        getRelNotebookVolumeData({
          params: {
            notebook_id: row.id,
            page_num: 1,
            page_size: 9999,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      // del rel_notebook_notebook_volume with hash_id
      await Promise.all(
        data.items.map((item) =>
          dispatch(
            delRelNotebookVolumeData({
              id: item.id,
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap()
        )
      );

      setDelLoading(false);
      setOpenConfirm(false);
      enqueueSnackbar("Delete Success", { variant: "success" });

      handleRefresh(true);
    } catch (e) {
      setDelLoading(false);
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertOutlinedIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleChangeStatus("start");
            handleClose();
          }}
          disabled={!(row.status === "closed")}
        >
          <ListItemIcon sx={{ mr: 1 }}>
            <SvgIconStyle
              src={StartIcon}
              sx={{
                width: 22,
                height: 22,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Start"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleChangeStatus("stop");
            handleClose();
          }}
          disabled={!(row.status === "started" || row.status === "failed")}
        >
          <ListItemIcon sx={{ mr: 1 }}>
            <SvgIconStyle
              src={StopIcon}
              sx={{
                width: 22,
                height: 22,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Stop"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={!(row.status === "closed")}
          onClick={() => {
            setOpenConfirm(true);

            handleClose();
          }}
          sx={{ color: "error.main" }}
        >
          <ListItemIcon sx={{ mr: 1 }}>
            <SvgIconStyle
              src={DeleteIcon}
              sx={{
                width: 22,
                height: 22,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete notebook <strong> {row.name} </strong> item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelNotebook();
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
};

export default NotebookTableActionCol;

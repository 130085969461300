import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Box, Stack, Collapse, IconButton } from "@mui/material";
//
import EmptyTable from "@/components/project/EmptyTable";
import { ProcessingStep } from "@/@types/project/mlPipeline/Kubeflow/pipeline-processing";
import { CollapseButtonStyle } from "../../../../SettingSidebar";
import { handleKubeflowStepArray } from "@/pages/project/automation/MlPipeline/graph/slices/slice";
import ProcessingOutputsItem from "./partComponents/ProcessingOutputsItem";

// ----------------------------------------------------------------------

type Props = {
  currentStep: ProcessingStep | null;
  isEdit: boolean;
};

export default function ProcessingOutputs({ currentStep, isEdit }: Props) {
  const dispatch = useDispatch();

  const [showProcessingOutput, setShowProcessingOutput] = useState(false);

  const processingOutputs = currentStep?.outputs || [];

  const handleAddProcessingOutput = () => {
    if (!showProcessingOutput) {
      setShowProcessingOutput(true);
    }

    dispatch(
      handleKubeflowStepArray({
        handleType: "create",
        data: {
          name: `ProcessingOutput-${processingOutputs.length + 1}`,
          dataType: "String",
          description: "",
          paramType: 2,
        },
        key: "outputs",
      })
    );
  };

  return (
    <>
      <Box sx={{ mb: 0, position: "relative" }}>
        <CollapseButtonStyle
          fullWidth
          color="inherit"
          onClick={() => setShowProcessingOutput((prev) => !prev)}
          endIcon={
            <Icon
              icon={
                showProcessingOutput
                  ? arrowIosDownwardFill
                  : arrowIosForwardFill
              }
              width={16}
              height={16}
            />
          }
        >
          Outputs{" "}
          {processingOutputs.length > 0 && `(${processingOutputs.length})`}
        </CollapseButtonStyle>

        <IconButton
          sx={{ zIndex: 1999, position: "absolute", top: 7, right: 30 }}
          disabled={isEdit}
          size="small"
          onClick={handleAddProcessingOutput}
        >
          <Icon icon={plusFill} width={16} height={16} />
        </IconButton>
      </Box>

      <Collapse in={showProcessingOutput}>
        <Stack
          spacing={1.5}
          sx={{ mx: 2, mb: 1.5, mt: showProcessingOutput ? 0.5 : 0 }}
        >
          {processingOutputs.length > 0 &&
            processingOutputs.map((processingOutput, index) => (
              <ProcessingOutputsItem
                length={processingOutputs.length}
                isEdit={isEdit}
                key={index}
                id={index}
                data={processingOutput}
              />
            ))}
        </Stack>
      </Collapse>

      {showProcessingOutput && processingOutputs.length === 0 && (
        <EmptyTable
          emptyImage={false}
          mainTitle={"No processing outputs"}
          content={'Create one by clicking "+" button'}
          color={"text.disabled"}
          sx={{ backgroundColor: "transparent", mx: 2, mt: 5, mb: 7 }}
        />
      )}
    </>
  );
}

import { StepInfo } from "@/@types/project/mlPipeline/SageMaker/pipeline";
import {
  generateUri,
  formEnvVars,
  generateImageUri,
  genArraysFromString,
} from "./helper-functions";
import { RegisterModelStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-register-model";

// ----------------------------------------------------------------------

export default function generateRegisterModelStep(step: RegisterModelStep) {
  const metrics = parseMetrics(step);
  const driftCheckBaselines = parseDriftCheckBaselines(step);
  // console.log(driftCheckBaselines);
  return {
    Name: step.name,
    Type: step.type,
    Arguments: {
      ModelPackageGroupName: generateUri(
        step.model.modelPackageGroupName,
        step.model.modelPackageGroupNameUseParam
      ),
      InferenceSpecification: {
        Containers: parseContainers(step),
        SupportedContentTypes: genArraysFromString(step.model.contentType),
        SupportedResponseMIMETypes: genArraysFromString(
          step.model.responseType
        ),
        SupportedRealtimeInferenceInstanceTypes: genArraysFromString(
          step.model.inferenceInstanceType,
          step.model.inferenceInstanceTypeUseParam
        ),
        SupportedTransformInstanceTypes: genArraysFromString(
          step.model.transformInstanceType,
          step.model.transformInstanceTypeUseParam
        ),
      },
      ModelApprovalStatus: generateUri(
        step.approvalStatus,
        step.approvalStatusUseParam
      ),
      ...(metrics && { ModelMetrics: metrics }),
      ...(driftCheckBaselines && { DriftCheckBaselines: driftCheckBaselines }),
    },
  } as StepInfo;
}

const parseContainers = (step: RegisterModelStep) => {
  const containers = [] as any;
  containers.push({
    Image: generateImageUri(step.model.container, step.model.containerUseParam),
    Environment: formEnvVars(step.model.environmentVars),
    ModelDataUrl: generateUri(
      step.model.modelData,
      step.model.modelDataUseParam
    ),
  });
  return containers;
};

const parseMetrics = (step: RegisterModelStep) => {
  let ModelMetrics = {} as any;
  if (!step.metrics) return undefined;

  if (step.metrics.bias) {
    ModelMetrics.Bias = {
      PostTrainingReport: {
        ContentType: step.metrics.biasContentType,
        S3Uri: generateUri(step.metrics.bias, step.metrics.biasUseParam),
      },
    };
  }
  if (step.metrics.explainability) {
    ModelMetrics.Explainability = {
      Report: {
        ContentType: step.metrics.explainabilityContentType,
        S3Uri: generateUri(
          step.metrics.explainability,
          step.metrics.explainabilityUseParam
        ),
      },
    };
  }
  if (step.metrics.modelStatistics) {
    ModelMetrics.ModelQuality = {
      Statistics: {
        ContentType: step.metrics.modelStatisticsContentType,
        S3Uri: generateUri(
          step.metrics.modelStatistics,
          step.metrics.modelStatisticsUseParam
        ),
      },
    };
  }

  return ModelMetrics;
};

const parseDriftCheckBaselines = (step: RegisterModelStep) => {
  let DriftCheckBaselines = {} as any;

  if (!step.driftCheckBaselines) return undefined;

  if (step.driftCheckBaselines.biasConfigFile) {
    DriftCheckBaselines.Bias = {
      PostTrainingConstraints: {
        ContentType: step.driftCheckBaselines.biasConfigFileContentType,
        S3Uri: generateUri(
          step.driftCheckBaselines.biasConfigFile,
          step.driftCheckBaselines.biasConfigFileUseParam
        ),
      },
    };
  }
  if (step.driftCheckBaselines.explainabilityConfigFile) {
    if (!DriftCheckBaselines.Explainability) {
      DriftCheckBaselines.Explainability = {};
    }
    DriftCheckBaselines.Explainability.ConfigFile = {
      ContentType: step.driftCheckBaselines.explainabilityConfigFileContentType,
      S3Uri: generateUri(
        step.driftCheckBaselines.explainabilityConfigFile,
        step.driftCheckBaselines.explainabilityConfigFileUseParam
      ),
    };
  }
  if (step.driftCheckBaselines.explainabilityConstraints) {
    if (!DriftCheckBaselines.Explainability) {
      DriftCheckBaselines.Explainability = {};
    }
    DriftCheckBaselines.Explainability.Constraints = {
      ContentType:
        step.driftCheckBaselines.explainabilityConstraintsContentType,
      S3Uri: generateUri(
        step.driftCheckBaselines.explainabilityConstraints,
        step.driftCheckBaselines.explainabilityConstraintsUseParam
      ),
    };
  }

  return DriftCheckBaselines;
};

import { motion } from "framer-motion";
import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Container } from "@mui/material";
// components
import { MotionContainer, varBounceIn } from "@/components/project/animate";
import PageNotFoundIllustration from "@/assets/project/unexpectedPages/illustration_404";
import UnexpectedPage from "@/components/project/UnexpectedPage";
import {
  filterAdminSiderConfig,
  filterSiderbarConfigByPermissions,
  findMainPage,
} from "@/layouts/project/utils";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import sidebarConfig from "@/layouts/project/sidebar/SidebarConfig";

// ----------------------------------------------------------------------

const RootStyle = styled(UnexpectedPage)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
}));

// ----------------------------------------------------------------------

export default function Page404() {
  const {
    isAdmin,
    isNoneUser,
    userDetail: { permissions = [] } = {},
  } = useSelector((state: RootState) => state.common);

  return (
    <RootStyle title="404 Page Not Found | OmniML">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Sorry, page not found!
              </Typography>
            </motion.div>
            <Typography sx={{ color: "text.secondary" }}>
              Sorry, we couldn’t find the page you’re looking for.
            </Typography>

            <motion.div variants={varBounceIn}>
              <PageNotFoundIllustration
                sx={{ height: 260, my: { xs: 5, sm: 10 } }}
              />
            </motion.div>

            <Button
              to={findMainPage(
                isAdmin,
                filterSiderbarConfigByPermissions(
                  filterAdminSiderConfig(isAdmin, isNoneUser, sidebarConfig),
                  permissions
                )
              )}
              size="large"
              variant="contained"
              component={RouterLink}
            >
              Go to Main
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}

import { PayloadAction } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

export const addThunkReducers = (builder: any, thunks: any[]) => {
  thunks.forEach(({ action, name, options = {} }) => {
    const {
      updateDirectly = false,
      updateLoading = true,
      updateError = true,
    } = options;

    if (updateLoading) {
      builder.addCase(action.pending, (state: any) => {
        if (!updateDirectly) {
          state[name].loading = true;
        }
      });
    }

    builder.addCase(
      action.fulfilled,
      (state: any, { type, payload }: PayloadAction<any>) => {
        // console.log('type', type);// common/getOrgRoleSelectData/fulfilled（thunk first param）
        // console.log('payload', payload);// thunk return

        if (updateDirectly) {
          state[name] = payload;
        } else {
          if (updateLoading) state[name].loading = false;
          state[name].data = payload;
        }
      }
    );

    if (updateError && !updateDirectly) {
      builder.addCase(action.rejected, (state: any, action) => {
        if (updateLoading) {
          state[name].loading = false;
        }
        // console.log('reject', action);
        /* {
          "type": "common/getOrgRoleSelectData/rejected",
          "payload": "Unable to fetch user info",
          "meta": {
              "arg": {
                  "userId": "xuey17"
              },
              "requestId": "_zRD7qQLXE-_wBwWDyUCz",
              "rejectedWithValue": true,
              "requestStatus": "rejected",
              "aborted": false,
              "condition": false
          },
          "error": {
              "message": "Rejected"
          }
      } */

        state[name].error = action?.error ? action?.error : null;
      });
    }
  });
};

export const handleKubeflowPipelineParametersCurd = (state, action) => {
  const { handleType } = action.payload;
  const key = action.payload.key;
  const paramsList = state.currentKubeflowPipeline[key];

  if (handleType === "create") {
    paramsList.push(action.payload.data); // without id ,id is index
  } else if (handleType === "edit") {
    // change the DefaultValue by DataType
    const curParam = paramsList[action.payload.id]; // id number/string

    if (!isEmpty(curParam)) {
      // { DefaultValue: xxx } or { Name: xxx } merge not replace
      Object.assign(curParam, action.payload.data);
    }
  } else {
    // delete
    const { id } = action.payload;
    if (!isEmpty(paramsList) && id > -1) {
      paramsList.splice(id, 1);
    }
  }
};

import { cloneDeep } from "lodash";

export const parsePipelineJson = (json) => {
  const { edges = [], steps = [], settings = {} } = json || {};
  const { pipelineParamList = [] } = settings;

  const handleInputs = (inputList) => {
    return (
      inputList?.map((item) => {
        if (item.paramType === 1) {
          return {
            dataType: item.dataType,
            name: item.name, // only need name when used pipeline param
            value: item.name,
            paramType: 1,
            useParam: JSON.parse(item.useParam),
            description: item.description,
          };
        }

        return {
          ...item,
          useParam: JSON.parse(item.useParam),
        };
      }) || []
    );
  };

  const tSteps = steps.map((item) => {
    if (["Processing", "Training"].includes(item.type)) {
      let tempInputs = cloneDeep(item?.inputs);
      // switch the useParam string to boolean;
      tempInputs = handleInputs(tempInputs);

      const codeJobArgumentsList =
        tempInputs?.filter((item) => item.source === "jobArg") || [];
      const inputs =
        tempInputs?.filter((item) => item.source !== "jobArg") || [];
      return {
        ...item,
        codeJobArgumentsList,
        inputs,
      };
    }

    const args = item?.arguments || {};

    if (item.type === "FeatureSetDownload") {
      return {
        ...item,
        inputs: handleInputs(item.inputs),
        arguments: {
          fsId: args?.["featureset-id"],
          fsVersionId: args?.["featureset-version-id"] || "",
          fsVersionExternalId: args?.["module-version-external-id"] || "",
        },
      };
    }

    if (item.type === "FeatureSetUpload") {
      return {
        ...item,
        inputs: handleInputs(item.inputs),
        arguments: {
          useParam: JSON.parse(args?.useParam),
          ...(JSON.parse(args.useParam)
            ? {
                fsId: args?.["featureset-id"],
                fsExternalId: args?.["module-external-id"] || "",
              }
            : {
                name: args?.name || "",
                deliverTo: args?.["delivered-to"] || "",
                featureSetType: args?.["module-type"] || "",
                resourceType: args?.["resource-type"] || "",
                resourcePath: args?.["resource-path"] || "",
                resourceConnection: args?.["resource-connection-id"] || "",
                resourceConnectionCredential:
                  args?.["resource-credential-id"] || "",
              }),
        },
      };
    }

    if (item.type === "RegisterModel") {
      return {
        ...item,
        inputs: handleInputs(item.inputs),
        arguments: {
          useParam: JSON.parse(args?.useParam),
          ...(JSON.parse(args.useParam)
            ? {
                mpId: args?.["modelpackage-id"],
                mpExternalId: args?.["module-external-id"] || "",
              }
            : {
                name: args?.name || "",
                deliverTo: args?.["delivered-to"] || "",
                modelPackageType: args?.["module-type"] || "",
                resourceType: args?.["resource-type"] || "",
                resourcePath: args?.["resource-path"] || "",
                resourceConnection: args?.["resource-connection-id"] || "",
                resourceConnectionCredential:
                  args?.["resource-credential-id"] || "",
              }),
        },
      };
    }

    return {
      ...item,
      arguments: {
        name: args?.name || "",
        featureSetType: args?.["module-type"] || "",
        deliverTo: args?.["delivered-to"] || "",
        resourcePath: args?.["resource-path"] || "",
        resourceType: args?.["resource-type"] || "",
        resourceConnection: args?.["resource-connection-id"] || "",
        resourceConnectionCredential: args?.["resource-credential-id"] || "",
      },
    };
  });

  const tParamList = pipelineParamList.map((item) => ({
    Name: item.name,
    Type: item.type,
    DefaultValue: item.default,
  }));

  return { edges, steps: tSteps, pipelineParamList: tParamList };
};

export const generatePipelineJson = (pipelineData, schema) => {
  const { edgeEntities = [], pipelineSteps = [] } = schema;

  const handleInputs = (inputList) => {
    // switch the useParam boolean to string;
    return (
      inputList?.map((item) => {
        if (item.paramType === 1) {
          return {
            dataType: item.dataType,
            name: item.value, // only need name when used pipeline param
            paramType: 1,
            useParam: String(item.useParam),
            description: item.description,
          };
        }
        return {
          ...item,
          useParam: String(item.useParam),
        };
      }) || []
    );
  };

  const tSteps = pipelineSteps.map((item) => {
    if (["Processing", "Training"].includes(item.type)) {
      let tInputs = cloneDeep(item?.inputs || []);
      tInputs.push(...(item?.codeJobArgumentsList || []));
      return {
        ...item,
        inputs: handleInputs(tInputs),
      };
    }

    const args = item?.arguments || {};

    if (item.type === "FeatureSetDownload") {
      return {
        ...item,
        inputs: handleInputs(item.inputs),
        arguments: {
          "featureset-id": args?.fsId,
          "featureset-version-id": args?.fsVersionId,
          "module-version-external-id": args?.fsVersionExternalId || "",
        },
      };
    }

    if (item.type === "FeatureSetUpload") {
      return {
        ...item,
        inputs: handleInputs(item.inputs),
        arguments: {
          useParam: String(args?.useParam),
          ...(args.useParam
            ? {
                "featureset-id": args?.fsId,
                "module-external-id": args?.fsExternalId || "",
              }
            : {
                name: args?.name || "",
                "delivered-to": args?.deliverTo || "",
                "module-type": args?.featureSetType || "",
                "resource-type": args?.resourceType || "",
                "resource-path": args?.resourcePath || "",
                "resource-connection-id": args?.resourceConnection || "",
                "resource-credential-id":
                  args?.resourceConnectionCredential || "",
              }),
        },
      };
    }

    if (item.type === "RegisterModel") {
      return {
        ...item,
        inputs: handleInputs(item.inputs),
        arguments: {
          useParam: String(args?.useParam),
          ...(args.useParam
            ? {
                "modelpackage-id": args?.mpId,
                "module-external-id": args?.mpExternalId || "",
              }
            : {
                name: args?.name || "",
                "delivered-to": args?.deliverTo || "",
                "module-type": args?.modelPackageType || "",
                "resource-type": args?.resourceType || "",
                "resource-path": args?.resourcePath || "",
                "resource-connection-id": args?.resourceConnection || "",
                "resource-credential-id":
                  args?.resourceConnectionCredential || "",
              }),
        },
      };
    }

    return {};
  });

  return {
    settings: {
      nodeSelector: {},
      pipelineName: pipelineData?.PipelineName,
      pipelineVolume: pipelineData?.pipelineVolume,
      pipelineParamList: pipelineData?.pipelineParamList?.map((item) => ({
        name: item?.Name,
        type: item?.Type,
        default: item?.DefaultValue,
      })),
    },
    edges: edgeEntities,
    steps: tSteps,
  };
};

import { Icon } from "@iconify/react";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
// material
import { Box, Stack, Divider, Collapse } from "@mui/material";
// @types
import { RootState, useDispatch, useSelector } from "@/redux/store";
import { TuningStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-tuning";
//
import TuningJobDef from "./TuningJobDef";
import TrainingJobDef from "./TrainingJobDef";
import TrainingInputs from "../training/TrainingInputs";
import { CollapseButtonStyle } from "../../SettingSidebar";
import {
  updateEntityName,
  SchemaNodeEntity,
  updateTuningConfig,
} from "../../../graph/slices/slice";
import {
  StyledTextField,
  PipelineTextfield,
} from "../../components/PipelineComponents";
import { tuningTypes } from "../helper-functions";
import PipelineParameterWithSelector from "../../components/PipelineParameterWithSelector";

// ----------------------------------------------------------------------

type Props = {
  entity: SchemaNodeEntity;
};

export default function TuningStepEntry({ entity }: Props) {
  const dispatch = useDispatch();
  const [showStepConfig, setShowStepConfig] = useState(true);

  const currentStep = useSelector((state: RootState) => {
    let step: TuningStep = state.schema.schema.pipelineSteps.find((step) => {
      return step.id === entity.id;
    }) as TuningStep;
    return step;
  });

  interface TuningStepInput {
    metricName: string;
    tuningType: string;
    tuningTypeUseParam: boolean;
  }

  const formik = useFormik<TuningStepInput>({
    initialValues: {
      metricName: currentStep.metricName,
      tuningType: currentStep.tuningType,
      tuningTypeUseParam: currentStep.tuningTypeUseParam,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      try {
        dispatch(updateTuningConfig(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { handleSubmit } = formik;

  return (
    <>
      {entity && currentStep && (
        <Box>
          <CollapseButtonStyle
            fullWidth
            color="inherit"
            onClick={() => setShowStepConfig((prev) => !prev)}
            endIcon={
              <Icon
                icon={
                  showStepConfig ? arrowIosDownwardFill : arrowIosForwardFill
                }
                width={16}
                height={16}
              />
            }
          >
            Configuration
          </CollapseButtonStyle>

          <Collapse in={showStepConfig}>
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onChange={handleSubmit}>
                <Stack spacing={2} sx={{ mx: 2, mt: 1, mb: 2 }}>
                  <StyledTextField
                    label="Step name"
                    defaultValue={entity.name}
                    onChange={(event: any) => {
                      const newValue = event.target.value;
                      dispatch(updateEntityName({ name: newValue }));
                    }}
                  />

                  <PipelineTextfield
                    label={"Object metric"}
                    field={"metricName"}
                    placeholder={"Metric name"}
                    formik={formik}
                  />

                  <PipelineParameterWithSelector
                    label={"Tuning type"}
                    field={"tuningType"}
                    placeholder={"Select tuning type"}
                    fieldUseParam={"tuningTypeUseParam"}
                    options={tuningTypes}
                    formik={formik}
                  />
                </Stack>
              </Form>
            </FormikProvider>
          </Collapse>

          <Divider />

          <TuningJobDef currentStep={currentStep} />

          <Divider />

          <TrainingJobDef currentStep={currentStep} />

          <Divider />

          <TrainingInputs currentStep={currentStep} />

          <Divider sx={{ mb: 1 }} />
        </Box>
      )}
    </>
  );
}

// material
import { Stack, Switch, Tooltip, TextFieldProps } from "@mui/material";
//
import MlPipelineParameterSelector from "./MlPipelineParameterSelector";
import { StyledTextField } from "../PipelineComponents";

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  label: string;
  field: string;
  fieldUseParam: string;
  formik: any;
  submitThisValuefunc: any;
};

export default function MlPipelineParameterEntry({
  label,
  field,
  fieldUseParam,
  formik,
  submitThisValuefunc,
  disabled,
  ...other
}: Props) {
  const { errors, touched, values, setFieldValue, getFieldProps } = formik;

  /*   const debounceSubmit = debounce((fieldName, value) => {
    submitThisValuefunc(fieldName, value);
  }, 500); */

  return (
    <Stack
      spacing={0.1}
      direction="row"
      alignItems="center"
      sx={{ width: "103%" }}
    >
      {values[fieldUseParam] ? (
        <MlPipelineParameterSelector
          label={label}
          value={String(values[field])}
          formik={formik}
          submitThisValuefunc={submitThisValuefunc}
          /* onChange={(e) => {
            setFieldValue(field, e.target.value);
            // submit this field value whether errors exist or not
            // debounceSubmit(field, e.target.value);
            submitThisValuefunc(e.target.value);
          }} */
          {...getFieldProps(field)}
          error={touched[field] && Boolean(errors[field])}
          helperText={touched[field] && errors[field]}
          disabled={disabled}
        />
      ) : (
        <StyledTextField
          label={label}
          value={values[field]}
          onChange={(e) => {
            setFieldValue(field, e.target.value);
            // submit this field value whether errors exist or not
            // debounceSubmit(field, e.target.value);
            submitThisValuefunc(e.target.value);
          }}
          {...getFieldProps(field)}
          error={touched[field] && Boolean(errors[field])}
          helperText={touched[field] && errors[field]}
          disabled={disabled}
          {...other}
        />
      )}
      <Tooltip
        title="Open pipeline params"
        enterDelay={1000}
        enterTouchDelay={1000}
        arrow
        sx={{ zIndex: 3000 }}
      >
        <Switch
          size="small"
          checked={values[fieldUseParam]}
          disabled={disabled}
          onChange={(e) => {
            setFieldValue(field, "");
            submitThisValuefunc(field, "");

            setFieldValue(fieldUseParam, e.target.checked);
            if (!e.target.checked) {
              setFieldValue("paramType", 0);
            }
            submitThisValuefunc(fieldUseParam, e.target.checked);
            submitThisValuefunc("paramType", 0);
          }}
        />
      </Tooltip>
    </Stack>
  );
}

import { Box, useTheme } from "@mui/material";
import { Stack } from "@mui/material";

const mapColor = {
  PL: "rgba(255, 239, 90, 0.5)",
  DS: "rgba(30, 239, 250, 0.5)",
  ME: "rgba(45, 153, 255, 0.5)",
  DE: "rgba(164, 247, 204, 0.5)",
  OS: "rgba(255, 193, 7, 0.5)",
  BU: "rgba(158, 134, 255, 0.5)",

  SA: "rgba(255, 239, 90, 0.5)",
  SO: "rgba(45, 153, 255, 0.5)",
  GA: "rgba(164, 247, 204, 0.5)",
};

const RoleLabel = ({ children }) => {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";

  const getShortName = (fullName) => {
    const nameArr = fullName.split(" ");
    const shortName = nameArr.map((i) => i.charAt(0)).join("");
    return shortName;
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Box
        component="div"
        title={children}
        sx={{
          padding: theme.spacing(0, 1),
          color: isLight ? "#000" : "#fff",
          bgcolor: mapColor[getShortName(children)] || mapColor[0],
          height: 22,
          minWidth: 22,
          borderRadius: "6px",
        }}
      >
        {getShortName(children)}
      </Box>
    </Stack>
  );
};

export default RoleLabel;

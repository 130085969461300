import {
  Box,
  Collapse,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Field, FormikProps } from "formik";
import { IKubeflowHpTunerFormValues } from "..";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useState } from "react";
import MlNumberInputField from "@/components/project/mlComponents/MlNumberInputField";

interface IProps {
  formik: FormikProps<IKubeflowHpTunerFormValues>;
}

enum SearchAlgorithmEnum {
  "random" = "random",
  "grid" = "grid",
  "bayesianoptimization" = "bayesianoptimization",
}

const SearchAlgorithm = ({ formik }: IProps) => {
  const [open, setOpen] = useState<boolean>(true);

  const { values, errors, touched } = formik;

  const getAlgorithmSettings = (type: SearchAlgorithmEnum) => {
    switch (type) {
      case SearchAlgorithmEnum.random:
        return (
          <MlNumberInputField
            name="searchAlgorithm.settings.randomState"
            label="Random State"
            formik={formik}
            helperText={
              (touched?.searchAlgorithm?.settings?.randomState &&
                errors?.searchAlgorithm?.settings?.randomState) ||
              ""
            }
            isDouble
          />
        );
      case SearchAlgorithmEnum.bayesianoptimization:
        return (
          <>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} lg={6}>
                <FormControl
                  fullWidth
                  margin="dense"
                  size="small"
                  error={
                    touched.searchAlgorithm?.settings?.baseEstimator &&
                    Boolean(errors.searchAlgorithm?.settings?.baseEstimator)
                  }
                >
                  <InputLabel>Base Estimator</InputLabel>
                  <Field
                    as={Select}
                    name="searchAlgorithm.settings.baseEstimator"
                    label="Base Estimator"
                    error={
                      touched.searchAlgorithm?.settings?.baseEstimator &&
                      Boolean(errors.searchAlgorithm?.settings?.baseEstimator)
                    }
                  >
                    <MenuItem value="GP">GP</MenuItem>
                    <MenuItem value="RF">RF</MenuItem>
                    <MenuItem value="ET">ET</MenuItem>
                    <MenuItem value="GBRT">GBRT</MenuItem>
                  </Field>
                  <FormHelperText
                    margin="dense"
                    sx={{ minHeight: "18px", marginTop: "2px" }}
                  >
                    {touched.searchAlgorithm?.settings?.baseEstimator &&
                    errors.searchAlgorithm?.settings?.baseEstimator
                      ? errors.searchAlgorithm?.settings?.baseEstimator
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  as={TextField}
                  name="searchAlgorithm.settings.riInitialPoints"
                  label="Ri Initial Points"
                  size="small"
                  fullWidth
                  margin="dense"
                  helperText={
                    touched.searchAlgorithm?.settings?.riInitialPoints &&
                    errors.searchAlgorithm?.settings?.riInitialPoints
                  }
                  error={
                    touched.searchAlgorithm?.settings?.riInitialPoints &&
                    Boolean(errors.searchAlgorithm?.settings?.riInitialPoints)
                  }
                  FormHelperTextProps={{
                    sx: { minHeight: "18px", marginTop: "2px" },
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl
                  fullWidth
                  margin="dense"
                  size="small"
                  error={
                    touched.searchAlgorithm?.settings?.acqFunc &&
                    Boolean(errors.searchAlgorithm?.settings?.acqFunc)
                  }
                >
                  <InputLabel>Acq Func</InputLabel>
                  <Field
                    as={Select}
                    name="searchAlgorithm.settings.acqFunc"
                    label="Acq Func"
                    error={
                      touched.searchAlgorithm?.settings?.acqFunc &&
                      Boolean(errors.searchAlgorithm?.settings?.acqFunc)
                    }
                  >
                    <MenuItem value="gp_hedge">gp_hedge</MenuItem>
                    <MenuItem value="LCB">LCB</MenuItem>
                    <MenuItem value="El">El</MenuItem>
                    <MenuItem value="Pl">Pl</MenuItem>
                    <MenuItem value="Elps">Elps</MenuItem>
                    <MenuItem value="Plps">Plps</MenuItem>
                  </Field>
                  <FormHelperText
                    margin="dense"
                    sx={{ minHeight: "18px", marginTop: "2px" }}
                  >
                    {touched.searchAlgorithm?.settings?.acqFunc &&
                    errors.searchAlgorithm?.settings?.acqFunc
                      ? errors.searchAlgorithm?.settings?.acqFunc
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl
                  fullWidth
                  margin="dense"
                  size="small"
                  error={
                    touched.searchAlgorithm?.settings?.acqOptimizer &&
                    Boolean(errors.searchAlgorithm?.settings?.acqOptimizer)
                  }
                >
                  <InputLabel>Acq Optimizer</InputLabel>
                  <Field
                    as={Select}
                    name="searchAlgorithm.settings.acqOptimizer"
                    label="Acq Optimizer"
                    error={
                      touched.searchAlgorithm?.settings?.acqOptimizer &&
                      Boolean(errors.searchAlgorithm?.settings?.acqOptimizer)
                    }
                  >
                    <MenuItem value="auto">auto</MenuItem>
                    <MenuItem value="sampling">sampling</MenuItem>
                    <MenuItem value="lbfgs">lbfgs</MenuItem>
                  </Field>
                  <FormHelperText
                    margin="dense"
                    sx={{ minHeight: "18px", marginTop: "2px" }}
                  >
                    {touched.searchAlgorithm?.settings?.acqOptimizer &&
                    errors.searchAlgorithm?.settings?.acqOptimizer
                      ? errors.searchAlgorithm?.settings?.acqOptimizer
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <MlNumberInputField
              name="searchAlgorithm.settings.random_state"
              label="Random State"
              formik={formik}
              helperText={
                (touched?.searchAlgorithm?.settings?.random_state &&
                  errors?.searchAlgorithm?.settings?.random_state) ||
                " "
              }
              isDouble
            />
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Typography variant="subtitle1">Search Algorithm</Typography>
      <Box sx={{ pl: 2 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", pl: 1, py: 1 }}
        >
          The Search Algorithm ls responsible for navigating through the
          optimization search space and creating Trial CRs for each step. The
          algorithms code will be executed from the underlying Suggestion CR,
          You can read the official docs for a full list of supported algorithms
          and their configuratins.
        </Typography>
        <FormControl
          fullWidth
          margin="dense"
          size="small"
          error={
            touched.searchAlgorithm?.name &&
            Boolean(errors.searchAlgorithm?.name)
          }
        >
          <InputLabel>Name</InputLabel>
          <Field
            as={Select}
            name="searchAlgorithm.name"
            label="Name"
            error={
              touched.searchAlgorithm?.name &&
              Boolean(errors.searchAlgorithm?.name)
            }
          >
            <MenuItem value="random">Random</MenuItem>
            <MenuItem value="grid">Grid</MenuItem>
            <MenuItem value="bayesianoptimization">
              Bayesian Optimization
            </MenuItem>
          </Field>
          <FormHelperText
            margin="dense"
            sx={{ minHeight: "18px", marginTop: "2px" }}
          >
            {touched.searchAlgorithm?.name && errors.searchAlgorithm?.name
              ? errors.searchAlgorithm?.name
              : ""}
          </FormHelperText>
        </FormControl>

        <Stack
          direction="row"
          alignItems="center"
          sx={{ cursor: "pointer", width: 200 }}
          onClick={() => setOpen(!open)}
        >
          <ExpandMoreOutlinedIcon
            sx={{
              transform: open ? "rotate(0)" : "rotate(-90deg)",
              transition: "transform 0.5s",
            }}
          />
          <Typography sx={{ pl: 0.5 }} variant="inherit">
            Algorithm Settings
          </Typography>
        </Stack>
        <Collapse in={open}>
          {values.searchAlgorithm.name &&
            getAlgorithmSettings(
              values.searchAlgorithm.name as SearchAlgorithmEnum
            )}
        </Collapse>
      </Box>
    </>
  );
};

export default SearchAlgorithm;

import * as Yup from "yup";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { Stack, Collapse } from "@mui/material";
// utils
import { updateClarifyCheckProcessor } from "../../../graph/slices/slice";
// types
import {
  ClarifyCheckStep,
  ClarifyCheckJobConfigStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-clarify-check";
// components
import { CollapseButtonStyle } from "../../SettingSidebar";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";

// ----------------------------------------------------------------------

type Props = {
  currentStep: ClarifyCheckStep | null;
};

export default function CheckJobConfig({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [showProcessor, setShowProcessorr] = useState(false);

  const ParamSchema = Yup.object().shape({
    processorType: Yup.string().required("Image uri is required"),
    instanceCount: Yup.string().required("Instance count is required"),
    volumeSizeInGb: Yup.string().required("Volume size is required"),
  });

  const formik = useFormik<ClarifyCheckJobConfigStruct>({
    initialValues: {
      processorType: currentStep?.checkJobConfig.processorType || "",
      processorTypeUseParam:
        currentStep?.checkJobConfig.processorTypeUseParam || false,
      configFilePath: currentStep?.checkJobConfig.configFilePath || "",
      configFilePathUseParam:
        currentStep?.checkJobConfig.configFilePathUseParam || false,
      frameworkVersion: currentStep?.checkJobConfig.frameworkVersion || "",
      instanceType: currentStep?.checkJobConfig.instanceType || "ml.m4.xlarge",
      instanceTypeUseParam:
        currentStep?.checkJobConfig.instanceTypeUseParam || false,
      instanceCount: currentStep?.checkJobConfig.instanceCount || "1",
      instanceCountUseParam:
        currentStep?.checkJobConfig.instanceCountUseParam || false,
      volumeSizeInGb: currentStep?.checkJobConfig.volumeSizeInGb || "30",
      volumeSizeInGbUseParam:
        currentStep?.checkJobConfig.volumeSizeInGbUseParam || false,
    },
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values) => {
      try {
        dispatch(updateClarifyCheckProcessor(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { handleSubmit } = formik;

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setShowProcessorr((prev) => !prev)}
        endIcon={
          <Icon
            icon={showProcessor ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
      >
        Job Config
      </CollapseButtonStyle>

      <Collapse in={showProcessor} sx={{ mx: 2 }}>
        <Stack spacing={1} sx={{ my: 1.2, mb: 1.5 }}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onChange={handleSubmit}>
              <Stack spacing={2} sx={{ mb: 0.5 }}>
                {/* <PipelineSelect
                  label={"Processor type"}
                  field={"processorType"}
                  placeholder={"Select a processor"}
                  options={processorTypes}
                  getFieldProps={getFieldProps}
                />

                <PipelineSelect
                  label={"Framework version"}
                  field={"frameworkVersion"}
                  placeholder={"Select a framework version"}
                  options={processorVerions}
                  getFieldProps={getFieldProps}
                /> */}

                <PipelineParameterEntry
                  label={"Processor"}
                  field={"processorType"}
                  fieldUseParam={"processorTypeUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Instance type"}
                  field={"instanceType"}
                  fieldUseParam={"instanceTypeUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Instance count"}
                  field={"instanceCount"}
                  fieldUseParam={"instanceCountUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Volume size (GB)"}
                  field={"volumeSizeInGb"}
                  fieldUseParam={"volumeSizeInGbUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Job config file path (optional)"}
                  field={"configFilePath"}
                  fieldUseParam={"configFilePathUseParam"}
                  formik={formik}
                />
              </Stack>
            </Form>
          </FormikProvider>
        </Stack>
      </Collapse>
    </>
  );
}

import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Stack } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

const Disclaimer = () => {
  const theme = useTheme();

  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const { config: { common = {} } = {} } = useSelector(
    (state: RootState) => state.common
  );

  const { disclaimer_content = "", SSO_LOGOUT_URL = "" } = common || {};

  useEffect(() => {
    const isAcceptedDisclaimer =
      localStorage.getItem("isAcceptedDisclaimer") === "true";

    if (!isAcceptedDisclaimer) {
      setShowDisclaimer(true);
    }
  }, []);

  const handleAccept = () => {
    setShowDisclaimer(false);
    localStorage.setItem("isAcceptedDisclaimer", "true");
  };

  const handleDecline = () => {
    window.location.href = SSO_LOGOUT_URL;
    localStorage.clear();
  };

  const getContent = () => {
    return (
      <DialogContentText sx={{ whiteSpace: "pre-wrap" }}>
        {disclaimer_content}
      </DialogContentText>
    );
  };

  return (
    <Dialog
      open={showDisclaimer}
      disableEscapeKeyDown
      onClose={(_, reason) => {
        if (reason && reason === "backdropClick") {
          return;
        }

        setShowDisclaimer(false);
      }}
      PaperProps={{
        sx: {
          width: "960px",
          maxWidth: "none",
          padding: "20px 40px",
          maxHeight: "70%",
        },
      }}
    >
      <DialogTitle>Disclaimer for OmniML Platform</DialogTitle>

      <DialogContent
        sx={{
          borderRadius: "5px",
          border: `1px solid ${theme.palette.grey[500_32]}`,
          mt: 2,
          pt: "10px!important",
        }}
      >
        {getContent()}
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        <Stack spacing={2} direction="row" justifyContent="center">
          <Button
            variant="contained"
            onClick={handleAccept}
            color="primary"
            sx={{ width: "200px", color: "background.paper" }}
          >
            Accept
          </Button>
          <Button
            type="button"
            color="inherit"
            variant="outlined"
            onClick={handleDecline}
            sx={{ width: "200px", color: "text.secondary" }}
          >
            Decline
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default Disclaimer;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useLocation, useNavigate } from "react-router";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import { DeleteIcon, EditIcon } from "@/assets/project/icons";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useSelector, RootState, dispatch } from "@/redux/store";
import {
  editMlService,
  getServiceDeploymentList,
} from "@/redux/project/mlService/mlRuntime/thunks";

interface IProps {
  row: {
    id: string;
    [key: string]: any;
  };
  fetchData: Function;
}

const MlRuntimeTableActionCol = ({ row, fetchData }: IProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  // delete confirm
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);
  const {
    mlServiceDeploymentList: {
      data: { items: deploymentList = [] },
    },
  } = useSelector((state: RootState) => state.mlRuntime);

  // dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // get deployment
    if (open) {
      dispatch(
        getServiceDeploymentList({
          params: {
            page_num: 1,
            page_size: 9999,
            is_active: 1,
            mlservice_id: row?.id,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
    }
  }, [open]);

  const handleEdit = (record: Record<string, any>) => {
    navigate(`${record?.id}/mlRuntimeEdit`);
  };

  const handleDelete = async (record: Record<string, any>) => {
    try {
      setDelLoading(true);
      const params = {
        is_active: 0,
      };
      await dispatch(
        editMlService({
          id: record.id,
          params,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
      await fetchData({ page_num: 1, page_size: 10, is_active: 1 });
      setOpenConfirm(false);
      setDelLoading(false);
      enqueueSnackbar("Delete Success", { variant: "success" });
    } catch (err) {
      setDelLoading(false);
    }
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={6} lg={6}>
          <IconButton onClick={() => navigate(`${row.id}`)}>
            <KeyboardArrowRightOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6} lg={6}>
          <IconButton onClick={handleClick}>
            <MoreVertOutlinedIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              key="edit"
              onClick={() => {
                handleEdit(row);
                handleClose();
              }}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <SvgIconStyle
                  src={EditIcon}
                  sx={{
                    width: 22,
                    height: 22,
                  }}
                />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                Edit
              </ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenConfirm(true);
              }}
              sx={{ color: "error.main" }}
              disabled={deploymentList.length > 0}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <SvgIconStyle
                  src={DeleteIcon}
                  sx={{
                    width: 22,
                    height: 22,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Delete"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete runtime <strong> {row.name} </strong> item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelete(row);
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
};

export default MlRuntimeTableActionCol;

import { useLocation } from "react-router";
import {
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Typography,
  FormHelperText,
  Stack,
  Button,
  Chip,
  Autocomplete,
} from "@mui/material";
import { Form, Field, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import Scrollbar from "@/components/project/Scrollbar";
import { useSnackbar } from "notistack";
import { RootState, dispatch, useSelector } from "@/redux/store";
import {
  getMetricsTemplateList,
  addRelMlServiceMetrics,
} from "@/redux/project/mlService/mlRuntime/thunks";
import { LoadingButton } from "@mui/lab";

interface IMetricsDialogProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

interface FormValue {
  metrics_type: string;
  metrics_name: Array<{ name: string; id: string }>;
  description: string;
}

const MetricsDialog = ({ isOpen, onClose }: IMetricsDialogProps) => {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    metricsTemplateList: { data },
    mlRuntimeDetail: { data: serviceDetail },
  } = useSelector((state: RootState) => state.mlRuntime);

  const initialValues: FormValue = {
    metrics_type: "",
    metrics_name: [],
    description: "",
  };

  const validationSchema = Yup.object({
    metrics_type: Yup.string().required("Seldon Server Type is required"),
    metrics_name: Yup.array().required("Metrics List is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      try {
        await Promise.all(
          values.metrics_name.map((item) => {
            return dispatch(
              addRelMlServiceMetrics({
                params: {
                  mlservice_id: serviceDetail?.id,
                  metrics_template_id: item.id,
                  is_active: 1,
                },
                alertCallback: enqueueSnackbar,
                pathname,
              })
            ).unwrap();
          })
        );

        actions.setSubmitting(false);
        onClose();
        resetForm();
        enqueueSnackbar("Save Metrics Success", { variant: "success" });
      } catch (err) {
        console.log("err", err);
        actions.setSubmitting(false);
      }
    },
  });

  const handleMetricsTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      getMetricsTemplateList({
        params: {
          page_num: 1,
          page_size: 9999,
          metrics_type: event.target.value,
          is_active: 1,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    setFieldValue("metrics_type", event.target.value);
  };

  const { values, errors, touched, isSubmitting, resetForm, setFieldValue } =
    formik;

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown
      onClose={() => {
        onClose();
        resetForm();
      }}
      PaperProps={{
        style: { width: "960px", maxWidth: "none", padding: "20px 40px" },
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Metrics
      </Typography>
      <FormikProvider value={formik}>
        <Form>
          <Scrollbar sx={{ maxHeight: "600px" }}>
            <FormControl
              fullWidth
              margin="dense"
              size="small"
              error={touched.metrics_type && Boolean(errors.metrics_type)}
            >
              <InputLabel>Metrics Type</InputLabel>
              <Field
                as={Select}
                name="metrics_type"
                label="Metrics Type"
                onChange={handleMetricsTypeChange}
              >
                <MenuItem value="Regression">Regression</MenuItem>
                <MenuItem value="Classification">Classification</MenuItem>
                <MenuItem value="Clustering">Clustering</MenuItem>
              </Field>
              <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                {touched.metrics_type && errors.metrics_type
                  ? errors.metrics_type
                  : ""}
              </FormHelperText>
            </FormControl>
            <Autocomplete
              multiple
              options={data?.items || []}
              getOptionLabel={(metricsList: { name: string; id: string }) =>
                metricsList.name
              }
              value={data?.items?.filter((metric) =>
                values.metrics_name.includes(metric)
              )}
              onChange={(_, newValues) => {
                setFieldValue("metrics_name", newValues || []);
                const curValue = newValues[newValues.length - 1];
                const metrics_fun = data?.items?.find(
                  (item) => item.id === curValue.id
                )?.metrics_func;
                setFieldValue("description", metrics_fun);
              }}
              renderTags={(value: any[], getTagProps) =>
                value.map(
                  (option: { name: string; id: string }, index: number) => (
                    <Chip
                      {...getTagProps({ index })}
                      size="small"
                      variant="soft"
                      label={option.name}
                      key={option.id}
                    />
                  )
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="metrics_name"
                  label="Metrics Name"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  helperText=" "
                  FormHelperTextProps={{
                    style: {
                      minHeight: "18px",
                      marginTop: "2px",
                    },
                  }}
                />
              )}
            />
            <Field
              as={TextField}
              multiline
              rows={4}
              name="description"
              label="Description (Optional)"
              size="small"
              fullWidth
              margin="dense"
              disabled
            />

            <Stack
              spacing={2}
              direction="row"
              justifyContent="center"
              sx={{ my: 4 }}
            >
              <LoadingButton
                type="submit"
                loading={isSubmitting}
                variant="contained"
                sx={{ width: "200px", color: "background.paper" }}
              >
                Send
              </LoadingButton>
              <Button
                type="button"
                color="inherit"
                variant="outlined"
                onClick={() => {
                  onClose();
                  resetForm();
                }}
                sx={{ width: "200px", color: "text.secondary" }}
              >
                Cancel
              </Button>
            </Stack>
          </Scrollbar>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default MetricsDialog;

import {
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useState } from "react";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import { DeleteIcon, EditIcon } from "@/assets/project/icons";
import { useLocation, useNavigate } from "react-router";
import { setSchemaData } from "../../graph/slices/slice";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import { delPipeline } from "@/redux/project/automation/thunks";
import { useSnackbar } from "notistack";
import { dispatch } from "@/redux/store";

interface IProps {
  row: {
    [key: string]: any;
  };
  handleRefresh: (a?: boolean) => void;
}

const PipelineTableActionCol = ({ row, handleRefresh }: IProps) => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  // delete confirm
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);

  // dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelPipeline = async () => {
    setDelLoading(true);
    try {
      await dispatch(
        delPipeline({
          id: row.id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      setDelLoading(false);
      setOpenConfirm(false);
      handleRefresh(true);

      enqueueSnackbar("Delete Success", { variant: "success" });
    } catch (e) {
      setDelLoading(false);
    }
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={6} lg={6}>
          <IconButton
            onClick={() => {
              navigate(`${row.id}`);
            }}
          >
            <KeyboardArrowRightOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6} lg={6}>
          <IconButton onClick={handleClick}>
            <MoreVertOutlinedIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              key={`${row.id}-edit`}
              onClick={() => {
                handleClose();

                navigate(`${row.id}/edit`, { state: { curPipeline: row } });
                dispatch(
                  setSchemaData({
                    mode: "edit",
                    pipelineType: row.type,
                  })
                );
              }}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <SvgIconStyle
                  src={EditIcon}
                  sx={{
                    width: 22,
                    height: 22,
                  }}
                />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                Edit
              </ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setOpenConfirm(true);
                handleClose();
              }}
              key={`${row.id}-delete`}
              sx={{ color: "error.main" }}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <SvgIconStyle
                  src={DeleteIcon}
                  sx={{
                    width: 22,
                    height: 22,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Delete"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete pipeline <strong> {row.name} </strong> item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelPipeline();
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
};

export default PipelineTableActionCol;

import { checkCode, handleErrorMessage } from "@/utils/project/request";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getMlRuntimeDatas,
  getServiceDeploymentDatas,
  getModelPackageDatas,
  getModelPackageDatasById,
  getRelTagServiceDatas,
  getTagDatasById,
  getTagDatas,
  getServiceDeploymentDatasById,
  getServiceInferenceResultDatas,
  createMlService,
  createTag,
  createRelMlServiceTag,
  createMlServiceDeployment,
  updateMlServiceDeployment,
  deleteMlServiceDeployment,
  updateMlService,
  deleteRelMlServiceTag,
  getModelPackageVersionDatas,
  getMetricsTemplateDatas,
  getMetricsTemplateById,
  createRelMlServiceMetrics,
  getModelPackageVersionDatasById,
  getMlServiceById,
  getMlServiceMetricsDatas,
  getMlServiceStatisticTotalViewDatas,
  getMlServiceStatisticDailyViewDatas,
  deleteRelMlServiceMetrics,
  getRelMlServiceMetricsTemplate,
} from "@/services/mlRuntime";
import { MlParams } from "@/@types/project/global";
import moment from "moment";

// service
interface IGetMlRuntimeParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
    is_active?: number;
  };
}
export const getMlRuntimeList = createAsyncThunk(
  "mlRuntime/getMlRuntimeList",
  async (
    { params, alertCallback, pathname }: IGetMlRuntimeParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getMlRuntimeDatas(params);
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching ML Runtime failed");
    }
  }
);
interface ICreateMlServiceParams extends MlParams {
  params: Record<string, any>;
}
export const addMlService = createAsyncThunk(
  "mlRuntime/addMlService",
  async (
    { params, alertCallback, pathname }: ICreateMlServiceParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createMlService({ payload: params });
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Create ML Service failed");
    }
  }
);
interface IUpdateMlServiceParams extends MlParams {
  id: string;
  params: Record<string, any>;
}
export const editMlService = createAsyncThunk(
  "mlRuntime/editMlService",
  async (
    { id, params, alertCallback, pathname }: IUpdateMlServiceParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await updateMlService(id, { payload: params });
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Update ML Service failed");
    }
  }
);
interface IGetMlRuntimeByIdParams extends MlParams {
  id: string;
}
export const getMlRuntimeById = createAsyncThunk(
  "mlRuntime/getMlRuntimeById",
  async (
    { id, alertCallback, pathname }: IGetMlRuntimeByIdParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getMlServiceById(id);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching model runtime failed");
    }
  }
);

// deployment
interface IGetServiceDeploymentParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
    is_active?: number;
    mlservice_id?: string;
  };
}
export const getServiceDeploymentList = createAsyncThunk(
  "mlRuntime/getServiceDeploymentList",
  async (
    { params, alertCallback, pathname }: IGetServiceDeploymentParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getServiceDeploymentDatas(params);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching ML Service Deployment failed");
    }
  }
);
interface IGetServiceDeploymentByIdParams extends MlParams {
  id: string;
}
export const getServiceDeploymentById = createAsyncThunk(
  "mlRuntime/getServiceDeploymentById",
  async (
    { id, alertCallback, pathname }: IGetServiceDeploymentByIdParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getServiceDeploymentDatasById(id);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching service version describe failed");
    }
  }
);
interface ICreateMlServiceDeploymentParams extends MlParams {
  params: Record<string, any>;
}
export const addMlServiceDeployment = createAsyncThunk(
  "mlRuntime/addMlServiceDeployment",
  async (
    { params, alertCallback, pathname }: ICreateMlServiceDeploymentParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createMlServiceDeployment({ payload: params });
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Create ML Service deployment failed");
    }
  }
);
interface IUpdateMlServiceDeploymentParams extends MlParams {
  id: string;
  params: Record<string, any>;
}
export const editMlServiceDeployment = createAsyncThunk(
  "mlRuntime/editMlServiceDeployment",
  async (
    { id, params, alertCallback, pathname }: IUpdateMlServiceDeploymentParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await updateMlServiceDeployment(id, { payload: params });
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Update ML Service deployment failed");
    }
  }
);
interface IDeleteMlServiceDeploymentParams extends MlParams {
  id: string;
}
export const delMlServiceDeployment = createAsyncThunk(
  "mlRuntime/delMlServiceDeployment",
  async (
    { id, alertCallback, pathname }: IDeleteMlServiceDeploymentParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await deleteMlServiceDeployment(id);
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete ML Service deployment failed");
    }
  }
);

// package
interface IGetModelPackageListParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
  };
}
export const getModelPackageList = createAsyncThunk(
  "mlRuntime/getModelPackageList",
  async (
    { params, alertCallback, pathname }: IGetModelPackageListParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getModelPackageDatas(params);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching model package failed");
    }
  }
);
interface IGetModelPackageParams extends MlParams {
  id: string;
}
export const getModelPackageById = createAsyncThunk(
  "mlRuntime/getModelPackageById",
  async (
    { id, alertCallback, pathname }: IGetModelPackageParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getModelPackageDatasById(id);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching model package failed");
    }
  }
);
interface IGetModelPackageVersionListParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
    modelpackage_id: string;
  };
}
export const getModelPackageVersionList = createAsyncThunk(
  "mlRuntime/getModelPackageVersionList",
  async (
    { params, alertCallback, pathname }: IGetModelPackageVersionListParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getModelPackageVersionDatas(params);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching model package verison failed");
    }
  }
);
interface IGetModelPackageVersionByIdParams extends MlParams {
  id: string;
}
export const getModelPackageVersionById = createAsyncThunk(
  "mlRuntime/getModelPackageVersionById",
  async (
    { id, alertCallback, pathname }: IGetModelPackageVersionByIdParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getModelPackageVersionDatasById(id);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching model Package version failed");
    }
  }
);

// rel_tag_service
interface IGetRelTagServiceParams extends MlParams {
  params: {
    mlservice_id: string;
    page_num: number;
    page_size: number;
    is_active: number;
  };
}
export const getRelTagServiceList = createAsyncThunk(
  "mlRuntime/getRelTagServiceList",
  async (
    { params, alertCallback, pathname }: IGetRelTagServiceParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getRelTagServiceDatas(params);
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching service and tag relation failed");
    }
  }
);
interface ICreateRelMlServiceTagParams extends MlParams {
  params: {
    tag_id: string;
    mlservice_id: string;
    is_active: number;
  };
}
export const addRelMlServiceTag = createAsyncThunk(
  "mlRuntime/addRelMlServiceTag",
  async (
    { params, alertCallback, pathname }: ICreateRelMlServiceTagParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createRelMlServiceTag({ payload: params });
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Create tag and ml service relation failed");
    }
  }
);
interface IDeleteRelMlServiceTagParams extends MlParams {
  id: string;
  params: Record<string, any>;
}
export const delRelServiceTag = createAsyncThunk(
  "mlRuntime/delRelServiceTag",
  async (
    { id, params, alertCallback, pathname }: IDeleteRelMlServiceTagParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await deleteRelMlServiceTag(id, { payload: params });
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete ML Service and tag relation failed");
    }
  }
);

// tag
interface IGetTagParams extends MlParams {
  id: string;
}
export const getTagById = createAsyncThunk(
  "mlRuntime/getTagById",
  async (
    { id, alertCallback, pathname }: IGetTagParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getTagDatasById(id);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching tags failed");
    }
  }
);
interface ICreateTagParams extends MlParams {
  params: Record<string, any>;
}
export const addTag = createAsyncThunk(
  "mlRuntime/addTag",
  async (
    { params, alertCallback, pathname }: ICreateTagParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createTag({ payload: params });
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Create tag failed");
    }
  }
);
interface IGetTagListParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
    is_active: number;
  };
}
export const getTagList = createAsyncThunk(
  "mlRuntime/getTagList",
  async (
    { params, alertCallback, pathname }: IGetTagListParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getTagDatas(params);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Tags failed");
    }
  }
);

// inference
interface IGetServiceInferenceParams extends MlParams {
  startTime: number;
  endTime: number;
  params: {
    page_num?: number;
    page_size?: number;
  };
}
export const getServiceInferenceList = createAsyncThunk(
  "mlRuntime/getServiceInferenceList",
  async (
    {
      startTime,
      endTime,
      params,
      alertCallback,
      pathname,
    }: IGetServiceInferenceParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getServiceInferenceResultDatas(params);
      const res = checkCode(data, pathname) as {
        items: Array<Object & { created_at: string }>;
      };
      let inferencetList = res.items?.filter(
        (item) =>
          moment(item.created_at).valueOf() >= startTime &&
          moment(item.created_at).valueOf() <= endTime
      );

      const lastRes = {
        ...res,
        items: inferencetList,
      };
      return lastRes;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching ML Runtime failed");
    }
  }
);

// metrics
interface IGetMetricsTemplateListParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
    metrics_type?: string;
    is_active?: number;
    name?: string;
  };
}
export const getMetricsTemplateList = createAsyncThunk(
  "mlRuntime/getMetricsTemplateList",
  async (
    { params, alertCallback, pathname }: IGetMetricsTemplateListParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getMetricsTemplateDatas(params);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching metrics template list failed");
    }
  }
);
interface IGetMetricsTemplateByIdParams extends MlParams {
  id: string;
}
export const getMetricsTemplateDataById = createAsyncThunk(
  "mlRuntime/getMetricsTemplateDataById",
  async (
    { id, alertCallback, pathname }: IGetMetricsTemplateByIdParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getMetricsTemplateById(id);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching metrics template failed");
    }
  }
);

interface ICreateMlServiceMetricsParams extends MlParams {
  params: {
    mlservice_id: string;
    metrics_template_id: string;
    is_active: number;
  };
}
export const addRelMlServiceMetrics = createAsyncThunk(
  "mlRuntime/addRelMlServiceMetrics",
  async (
    { params, alertCallback, pathname }: ICreateMlServiceMetricsParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createRelMlServiceMetrics({ payload: params });
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Create ML Service and Metrics relation failed");
    }
  }
);
interface IGetMetricsParams extends MlParams {
  end_date: string;
  start_date: string;
  names: string;
  params: {
    page_num: number;
    page_size: number;
    mlservice_deployment_id: string;
    aggregate_by?: string;
  };
}
export const getMetricsList = createAsyncThunk(
  "mlRuntime/getMetricsList",
  async (
    {
      end_date,
      start_date,
      names,
      params,
      alertCallback,
      pathname,
    }: IGetMetricsParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getMlServiceMetricsDatas(
        end_date,
        start_date,
        names,
        params
      );
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching metrics failed");
    }
  }
);
interface IDeleteRelMlServiceMetricsParams extends MlParams {
  id: string;
}
export const delRelMlServiceMetrics = createAsyncThunk(
  "mlRuntime/delRelMlServiceMetrics",
  async (
    { id, alertCallback, pathname }: IDeleteRelMlServiceMetricsParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await deleteRelMlServiceMetrics(id);
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete ML Service metrics relation failed");
    }
  }
);
interface IGetRelMlServiceMetricsParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
    mlservice_id?: string;
    metrics_template_id?: string;
  };
}
export const getRelMlServiceMetricsTemplateList = createAsyncThunk(
  "mlRuntime/getRelMlServiceMetricsTemplateList",
  async (
    { params, alertCallback, pathname }: IGetRelMlServiceMetricsParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getRelMlServiceMetricsTemplate(params);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching mlservice metrics relation failed");
    }
  }
);

// statistic
interface IGetStatisticTotalParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
  };
}
export const getStatisticTotalList = createAsyncThunk(
  "mlRuntime/getStatisticTotalList",
  async (
    { params, alertCallback, pathname }: IGetStatisticTotalParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getMlServiceStatisticTotalViewDatas(params);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue(
        "Fetching mlservice statistic project total view failed"
      );
    }
  }
);

interface IGetStatisticDailyParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
  };
}
export const getStatisticDailyList = createAsyncThunk(
  "mlRuntime/getStatisticDailyList",
  async (
    { params, alertCallback, pathname }: IGetStatisticDailyParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getMlServiceStatisticDailyViewDatas(params);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue(
        "Fetching mlservice statistic project daily view failed"
      );
    }
  }
);

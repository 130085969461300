import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import { useDispatch } from "react-redux";
// material
import { Paper, Stack, IconButton } from "@mui/material";
//
import {
  updateTrainingInput,
  deleteTrainingInput,
} from "../../../graph/slices/slice";
import { TrainingInputStruct } from "@/@types/project/mlPipeline/SageMaker/pipeline-training";
import {
  PipelineTextfield,
  PipelineSelect,
} from "../../components/PipelineComponents";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";
import { ContentTypes, InputModes } from "../../SettingSidebar";

// ----------------------------------------------------------------------

const dataDistributionTypes = [
  { code: "A", label: "FullyReplicated" },
  { code: "B", label: "ShardedByS3Key" },
];

export default function TrainingInput({
  id,
  data,
}: {
  id: number;
  data: TrainingInputStruct;
}) {
  const dispatch = useDispatch();
  const [isInitialzied, setInitialized] = useState(false);
  const [inputValues, setInputValues] = useState(data);

  useEffect(() => {
    if (isInitialzied) {
      const timer = setTimeout(() => {
        dispatch(
          updateTrainingInput({
            id: id,
            data: inputValues,
          })
        );
        setInitialized(false);
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [dispatch, id, inputValues, isInitialzied]);

  const ParamSchema = Yup.object().shape({
    inputName: Yup.string().required("Name is required"),
    // source: Yup.string().required('Source is required'),
    // destination: Yup.string().required('Destination is required')
  });

  const formik = useFormik<TrainingInputStruct>({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values) => {
      try {
        setInitialized(true);
        setInputValues(values);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Paper sx={{ p: 1.5, borderRadius: 1.5, bgcolor: "background.neutral" }}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onChange={handleSubmit}>
            <Stack spacing={2}>
              <PipelineTextfield
                label={"Name"}
                field={"inputName"}
                placeholder={"Input name"}
                formik={formik}
              />

              <PipelineParameterEntry
                label={"S3 data"}
                field={"s3Data"}
                fieldUseParam={"s3DataUseParam"}
                formik={formik}
              />

              <PipelineSelect
                label={"S3 data distribution type"}
                field={"distribution"}
                placeholder={"Select S3 data distribution type"}
                options={dataDistributionTypes}
                getFieldProps={getFieldProps}
              />

              <PipelineSelect
                label={"Input mode"}
                field={"inputMode"}
                placeholder={"Select input mode"}
                options={InputModes}
                getFieldProps={getFieldProps}
              />

              <Stack direction="row" spacing={0.5} alignItems="center">
                <PipelineSelect
                  label={"Content type"}
                  field={"contentType"}
                  placeholder={"Select content MIME type"}
                  options={ContentTypes}
                  getFieldProps={getFieldProps}
                />
                <IconButton
                  size="small"
                  onClick={() => {
                    dispatch(deleteTrainingInput(id));
                  }}
                >
                  <Icon icon={trash2Fill} width={18} height={18} />
                </IconButton>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </Paper>
    </>
  );
}

import * as Yup from "yup";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { useFormik, Form, FormikProvider } from "formik";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import {
  Radio,
  Stack,
  Collapse,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
// redux
import { useDispatch } from "@/redux/store";
//
import LambdaCode from "./LambdaCode";
import {
  LambdaStep,
  LambdaFunctionStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-lambda";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { updateLambdaFunction } from "../../../graph/slices/slice";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";

// ----------------------------------------------------------------------

const functionTypes = [
  { value: "arn", label: "Use an existing function" },
  { value: "code", label: "Create a new function" },
];

// ----------------------------------------------------------------------

type Props = {
  currentStep: LambdaStep;
};

export default function LambdaStepFunction({ currentStep }: Props) {
  const [isCollapsed, setCollapsed] = useState(false);

  const dispatch = useDispatch();

  const ParamSchema = Yup.object().shape({
    content: Yup.string().required("Content is required"),
  });

  const formik = useFormik<LambdaFunctionStruct>({
    initialValues: {
      type: currentStep.function.type,
      content: currentStep.function.content,
    },
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values) => {
      try {
        dispatch(updateLambdaFunction(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { values, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setCollapsed((prev) => !prev)}
        endIcon={
          <Icon
            icon={!isCollapsed ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
      >
        Lambda function
      </CollapseButtonStyle>

      <Collapse in={!isCollapsed} sx={{ mx: 2 }}>
        <Stack spacing={1} sx={{ my: 1.2, mb: 1.5 }}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onChange={handleSubmit}>
              <Stack spacing={2} sx={{ mb: 0 }}>
                <RadioGroup {...getFieldProps("type")} row>
                  <Stack
                    sx={{ ml: 0.5, mt: -0.5, mb: 0.5, alignItems: "left" }}
                  >
                    {functionTypes.map((type) => (
                      <FormControlLabel
                        key={type.value}
                        value={type.value}
                        control={<Radio size="small" />}
                        label={type.label}
                      />
                    ))}
                  </Stack>
                </RadioGroup>

                {values.type === "arn" && (
                  <PipelineParameterEntry
                    label={"Function name"}
                    field={"content"}
                    fieldUseParam={"contentUseParam"}
                    formik={formik}
                  />
                )}

                {values.type === "code" && (
                  <LambdaCode
                    data={{
                      source: "amazon_s3",
                      path: values.content,
                      includeDir: false,
                      jobArgs: [],
                    }}
                  />
                )}
              </Stack>
            </Form>
          </FormikProvider>
        </Stack>
      </Collapse>
    </>
  );
}

import {
  FailStep,
  FailArguments,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-fail";
import { parseUri, checkUseParam } from "./helper-functions";

// ----------------------------------------------------------------------

export default function parseFailStep(
  index: number,
  pipelineStep: any,
  stepPairs: [string, string, string][]
) {
  const args = pipelineStep.Arguments as FailArguments;
  const step = {
    id: String(index),
    type: "Fail",
    name: pipelineStep.Name,
    errorMessage: parseUri(args.ErrorMessage, pipelineStep.Name, stepPairs),
    errorMessageUseParam: checkUseParam(args.ErrorMessage),
    nodeX: 220 * index,
    nodeY: 0,
    tags: [],
    properties: [],
    stepType: "Fail",
  } as FailStep;
  return step;
}

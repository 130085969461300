/* eslint-disable react-hooks/exhaustive-deps */
import Page from "@/components/project/Page";
import { PATH_DASHBOARD } from "@/routes/paths";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router";
import RolePermissionsTable from "./components/projectRoleDetail/RolePermissionsTable";
import { RootState, dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import { getProjectRoleDetail } from "@/redux/project/template/thunks";
import { useSelector } from "react-redux";
import MlCardList from "@/components/project/mlComponents/MlCardList";
import MlCard from "@/components/project/mlComponents/MlCard";
import { getProjectDetailData } from "@/redux/project/administration/thunks";

const ProjectRoleDetail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const { roleId } = useParams();

  const {
    userDetail: { project_id: manageProjectId, organization_id: manageOrgId },
  } = useSelector((state: RootState) => state.common);

  const {
    projectDetailData: { data: projectData },
  } = useSelector((state: RootState) => state.administration);

  const isFromPgManageSiderbar = pathname.includes("/projectGovernance/manage");

  const {
    projectRoleDetail: { data, loading },
  } = useSelector((state: RootState) => state.template);

  const getLinks = () => {
    if (isFromPgManageSiderbar) {
      return [
        {
          name: "Project Governance",
        },
        {
          name: "Manage",
        },
        {
          name: projectData?.name || "",
          href: PATH_DASHBOARD.projectGovernance.manage,
        },
        {
          name: data?.name || "",
        },
      ];
    }
    return [
      {
        name: "Template",
      },
      {
        name: "Project Role",
        href: PATH_DASHBOARD.template.projectRole,
      },
      {
        name: data?.name || "",
      },
    ];
  };

  const getRoleBaseInfoList = () => {
    return [
      { subTitle: "Role Name", label: data?.name || "-", loading },
      { subTitle: "Description", label: data?.description || "-", loading },
    ];
  };

  useEffect(() => {
    dispatch(
      getProjectRoleDetail({
        id: roleId!,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );

    if (isFromPgManageSiderbar) {
      dispatch(
        getProjectDetailData({
          id: manageProjectId,
          organization_id: manageOrgId,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      );
    }
  }, []);

  return (
    <Page
      title={`${data?.name} | OmniML`}
      heading={data?.name}
      links={getLinks()}
    >
      <MlCard title="Basic Information">
        <MlCardList list={getRoleBaseInfoList()} colNum={2} />
      </MlCard>
      <RolePermissionsTable />
    </Page>
  );
};

export default ProjectRoleDetail;

import request from "@/utils/project/request";

// 1. pipeline
export const getPipelineListService = (params, isAll) => {
  return request.get(`/pipeline/mlpipeline`, {
    ...params,
    ...(isAll ? {} : { is_active: 1 }),
  });
};

export const getPipelineDetail = (id) => {
  return request.get(`/pipeline/mlpipeline/${id}`);
};

export const addPipelineService = (params) => {
  return request.post(`/pipeline/mlpipeline`, { payload: params });
};

export const updatePipelineService = (id, params) => {
  return request.put(`/pipeline/mlpipeline/${id}`, { payload: params });
};

export const delPipelineService = (id) => {
  return request.put(`/pipeline/mlpipeline/${id}`, {
    payload: { is_active: 0 },
  });
};

// rel_mlpipeline_tag
export const addPipelineTagsService = (params) => {
  return request.post(`/pipeline/rel_mlpipeline_tag`, { payload: params });
};

export const delPipelineTagsService = (id) => {
  return request.delete(`/pipeline/rel_mlpipeline_tag/${id}`);
};

export const getPipelineTagsService = (params) => {
  return request.get(`/pipeline/rel_mlpipeline_tag`, params);
};

// 2. pipeline version
export const getPipelineVersionTableList = (params) => {
  return request.get(`/pipeline/mlpipeline_version`, {
    ...params,
    is_active: 1,
  });
};

export const getPipelineVersionDetailService = (id) => {
  return request.get(`/pipeline/mlpipeline_version/${id}`);
};

export const addPipelineVersionService = (params) => {
  return request.post(`/pipeline/mlpipeline_version`, { payload: params });
};

export const editPipelineVersionService = (id, params) => {
  return request.put(`/pipeline/mlpipeline_version/${id}`, { payload: params });
};

export const delPipelineVersionService = (id, params) => {
  return request.put(`/pipeline/mlpipeline_version/${id}`, {
    payload: { ...params, is_active: 0 },
  });
};

// 3. pipeline volume
export const getPipelineVolumeListService = (params, isAll) => {
  return request.get(`/pipeline/mlpipeline_volume`, {
    ...params,
    ...(isAll ? {} : { is_active: 1 }),
  });
};

export const getPipelineVolumeDetail = (id) => {
  return request.get(`/pipeline/mlpipeline_volume/${id}`);
};

export const addPipelineVolumeService = (params) => {
  return request.post(`/pipeline/mlpipeline_volume`, { payload: params });
};

export const editPipelineVolumeService = (id, params) => {
  return request.put(`/pipeline/mlpipeline_volume/${id}`, { payload: params });
};

export const delPipelineVolumeService = (id) => {
  return request.put(`/pipeline/mlpipeline_volume/${id}`, {
    payload: { is_active: 0 },
  });
};

// rel_mlpipeline_mlpipeline_volume
export const getRelPipelineVolumeService = (params) => {
  return request.get(`/pipeline/rel_mlpipeline_mlpipeline_volume`, params);
};

export const addRelPipelineVolumeService = (params) => {
  return request.post(`/pipeline/rel_mlpipeline_mlpipeline_volume`, {
    payload: params,
  });
};

export const delRelPipelineVolumeService = (id) => {
  return request.delete(`/pipeline/rel_mlpipeline_mlpipeline_volume/${id}`);
};

// rel_mlpipeline_volume_tag
export const getPipelineVolumeTagsService = (params) => {
  return request.get(`/pipeline/rel_mlpipeline_volume_tag`, params);
};

export const addPipelineVolumeTagsService = (params) => {
  return request.post(`/pipeline/rel_mlpipeline_volume_tag`, {
    payload: params,
  });
};

export const delPipelineVolumeTagsService = (id) => {
  return request.delete(`/pipeline/rel_mlpipeline_volume_tag/${id}`);
};

// 4. pipeline trigger
export const getPipelineTriggerService = (params, isAll) => {
  return request.get(`/pipeline/mlpipeline_trigger`, {
    ...params,
    ...(isAll ? {} : { is_active: 1 }),
  });
};

export const getPipelineTriggerDetailService = (id) => {
  return request.get(`/pipeline/mlpipeline_trigger/${id}`);
};

export const addPipelineTriggerService = (params) => {
  return request.post(`/pipeline/mlpipeline_trigger`, { payload: params });
};

export const editPipelineTriggerService = (id, params) => {
  return request.put(`/pipeline/mlpipeline_trigger/${id}`, { payload: params });
};

export const delPipelineTriggerService = (id) => {
  return request.put(`/pipeline/mlpipeline_trigger/${id}`, {
    payload: { is_active: 0 },
  });
};

// 5. pipeline execution
export const getPipelineExecutionService = (params) => {
  return request.get(`/pipeline/mlpipeline_execution`, params);
};

export const getPipelineExecutionDetailService = (id) => {
  return request.get(`/pipeline/mlpipeline_execution/${id}`);
};

export const addPipelineExecutionService = (params) => {
  return request.post(`/pipeline/mlpipeline_execution`, { payload: params });
};

import { Icon } from "@iconify/react";
import { FormikProps } from "formik";
import checkmarkCircle2Fill from "@iconify/icons-eva/checkmark-circle-2-fill";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Radio,
  Checkbox,
  Collapse,
  TextField,
  Typography,
  RadioGroup,
  CardContent,
  FormHelperText,
  FormControlLabel,
} from "@mui/material";
// @types
import { CardOption } from "@/@types/products";
import {
  CodeSourceOption,
  CodeSourceType,
  JobArgument,
} from "@/@types/project/mlPipeline/SageMaker/pipeline";
//
import { MHidden } from "@/components/project/@material-extend";

// ----------------------------------------------------------------------

const OptionStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 2.5),
  justifyContent: "space-between",
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("all"),
  border: `solid 1px ${theme.palette.grey[500_32]}`,
}));

// ----------------------------------------------------------------------

type CheckoutPaymentMethodsProps = {
  formik: FormikProps<{
    source: CodeSourceType;
    path: string;
    includeDir: boolean;
    jobArgs: JobArgument[];
  }>;
  paymentOptions: CodeSourceOption[];
  cardOptions: CardOption[];
};

export default function LambdaCodeSelector({
  paymentOptions,
  cardOptions,
  formik,
}: CheckoutPaymentMethodsProps) {
  const { errors, touched, values, getFieldProps } = formik;

  return (
    <CardContent>
      <RadioGroup row {...getFieldProps("source")}>
        <Grid container spacing={2}>
          {paymentOptions.map((method) => {
            const { value, title, icons, description } = method;
            const hasChildren =
              value === "amazon_codecommit" || value === "amazon_s3";

            return (
              <Grid key={title} item xs={12}>
                <OptionStyle
                  sx={{
                    ...(values.source === value && {
                      boxShadow: (theme) => theme.customShadows.z4,
                    }),
                    ...(hasChildren && { flexWrap: "wrap" }),
                  }}
                >
                  <FormControlLabel
                    value={value}
                    control={
                      <Radio
                        checkedIcon={<Icon icon={checkmarkCircle2Fill} />}
                      />
                    }
                    label={
                      <Box sx={{ ml: 1 }}>
                        <Typography variant="subtitle2">{title}</Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "text.secondary" }}
                        >
                          {description}
                        </Typography>
                      </Box>
                    }
                    sx={{ flexGrow: 1, py: 3 }}
                  />
                  <MHidden width="smDown">
                    <Box
                      sx={{
                        flexShrink: 0,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {icons.map((icon, index) => (
                        <Box
                          key={icon}
                          component="img"
                          alt="logo codecommit"
                          src={icon}
                          sx={{
                            ...(index === 0 && { mr: 1 }),
                            width: 32,
                            height: 32,
                          }}
                        />
                      ))}
                    </Box>
                  </MHidden>

                  {hasChildren && value === "amazon_codecommit" && (
                    <Collapse
                      in={values.source === "amazon_codecommit"}
                      sx={{ width: "100%" }}
                    >
                      <TextField
                        select
                        fullWidth
                        label="Repository"
                        {...getFieldProps("path")}
                        SelectProps={{ native: true }}
                      >
                        {cardOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.value}
                          </option>
                        ))}
                      </TextField>

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.includeDir}
                            {...getFieldProps("includeDir")}
                          />
                        }
                        label="Include files and folders on the same level"
                        sx={{ my: 2, mt: 1.5 }}
                      />
                    </Collapse>
                  )}
                  {hasChildren && value === "amazon_s3" && (
                    <Collapse
                      in={values.source === "amazon_s3"}
                      sx={{ width: "100%" }}
                    >
                      <TextField
                        size="small"
                        multiline
                        fullWidth
                        label="S3 Prefix"
                        sx={{ mb: 2 }}
                        placeholder="s3://xxx/xxx.zip"
                        {...getFieldProps("path")}
                      ></TextField>
                    </Collapse>
                  )}
                </OptionStyle>
              </Grid>
            );
          })}
        </Grid>
      </RadioGroup>

      {errors.source && (
        <FormHelperText error>
          <Box component="span" sx={{ px: 2 }}>
            {touched.source && errors.source}
          </Box>
        </FormHelperText>
      )}
    </CardContent>
  );
}

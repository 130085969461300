import request from "@/utils/project/request";

export const getMlRuntimeDatas = (params) => {
  return request.get(`/modelruntime/mlservice`, params);
};

export const getServiceDeploymentDatas = (params) => {
  return request.get(`/modelruntime/mlservice_deployment`, params);
};

export const getModelPackageDatas = (params) => {
  return request.get(`/modelregistry/modelpackage`, params);
};

export const getModelPackageDatasById = (id) => {
  return request.get(`/modelregistry/modelpackage/${id}`);
};

export const getModelPackageVersionDatas = (params) => {
  return request.get(`/modelregistry/modelpackage_version`, params);
};

export const getModelPackageVersionDatasById = (id) => {
  return request.get(`/modelregistry/modelpackage_version/${id}`);
};

export const getRelTagServiceDatas = (params) => {
  return request.get(`/modelruntime/rel_mlservice_tag`, params);
};

export const getTagDatasById = (id) => {
  return request.get(`/govern/tag/${id}`);
};

export const getTagDatas = (params) => {
  return request.get(`/govern/tag`, params);
};

export const getServiceDeploymentDatasById = (id) => {
  return request.get(`/modelruntime/mlservice_deployment/${id}`);
};

export const getServiceInferenceResultDatas = (params) => {
  return request.get(`/modelruntime/mlservice_result`, params);
};

export const createMlService = (params) => {
  return request.post(`/modelruntime/mlservice`, params);
};

export const createTag = (params) => {
  return request.post(`/govern/tag`, params);
};

export const createRelMlServiceTag = (params) => {
  return request.post(`/modelruntime/rel_mlservice_tag`, params);
};

export const createMlServiceDeployment = (params) => {
  return request.post(`/modelruntime/mlservice_deployment`, params);
};

export const updateMlServiceDeployment = (id, params) => {
  return request.put(`/modelruntime/mlservice_deployment/${id}`, params);
};

export const deleteMlServiceDeployment = (id) => {
  return request.delete(`/modelruntime/mlservice_deployment/${id}`);
};

export const updateMlService = (id, params) => {
  return request.put(`/modelruntime/mlservice/${id}`, params);
};

export const deleteRelMlServiceTag = (id, params) => {
  return request.put(`/modelruntime/rel_mlservice_tag/${id}`, params);
};

export const getMetricsTemplateDatas = (params) => {
  return request.get(`/modelruntime/metrics_template`, params);
};

export const getMetricsTemplateById = (id) => {
  return request.get(`/modelruntime/metrics_template/${id}`);
};

export const getRelMlServiceMetricsTemplate = (params) => {
  return request.get(`/modelruntime/rel_mlservice_metrics_template`, params);
};

export const createRelMlServiceMetrics = (params) => {
  return request.post(`/modelruntime/rel_mlservice_metrics_template`, params);
};

export const deleteRelMlServiceMetrics = (id) => {
  return request.delete(`/modelruntime/rel_mlservice_metrics_template/${id}`);
};

export const getMlServiceById = (id) => {
  return request.get(`/modelruntime/mlservice/${id}`);
};

export const getMlServiceMetricsDatas = (et, st, names, params) => {
  return request.get(
    `/modelruntime/mlservice_metrics?end_date=${et}&start_date=${st}&names=${names}`,
    params
  );
};

export const getMlServiceStatisticTotalViewDatas = (params) => {
  return request.get(
    `/modelruntime/mlservice_statistics_project_total_view`,
    params
  );
};

export const getMlServiceStatisticDailyViewDatas = (params) => {
  return request.get(
    `/modelruntime/mlservice_statistics_project_daily_view`,
    params
  );
};

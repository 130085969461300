const permissionList = [
  // Govern
  {
    id: 10100,
    module: "Govern",
    description:
      "Able to modify and browse the components within Template & Connection. Can access Template and Conection section menus.",
  },
  {
    id: 10200,
    module: "Govern",
    description:
      "Able to browse the components within Template & Connection. Can access Template and Conection section menus.",
  },
  {
    id: 10300,
    module: "Govern",
    description:
      "Able to modify and browse the components about Organization. Can access Adminstration section menus. Can view and edit Organization inside.",
  },
  {
    id: 10400,
    module: "Govern",
    description:
      "Able to browse the components about Organization. Can access Administration section menus. Can view organizations inside.",
  },
  {
    id: 10500,
    module: "Govern",
    description:
      "Able to modify and browse the components about Project. Platform user could access Project Governance menus. Can view and edit projects inside.",
  },
  {
    id: 10600,
    module: "Govern",
    description:
      "Able to browse the components about Project. Can access Template and Conection section menus.  Platform user could access Project Governance menus. Can view projects inside.",
  },
  {
    id: 10700,
    module: "Govern",
    description:
      "Able to modify and browse the components about Project-User & Project-Role. Can access Roles information in project detail page. Can view and edit project role inside.",
  },
  {
    id: 10800,
    module: "Govern",
    description:
      "Able to browse the components about Project-User & Project-Role. Can access Roles information in project detail page. Can view project role inside.",
  },
  {
    id: 11100,
    module: "Govern",
    description:
      "Able to modify and browse the components within Observability(Platform-Monitor). Can access Observability section menus.",
  },
  {
    id: 11200,
    module: "Govern",
    description:
      "Able to browse the components within Observability(Platform-Monitor). Can access Observability section menus.",
  },

  // Experiment
  {
    id: 20100,
    module: "Experiment",
    description:
      "Able to modify and browse the components within Experiment. Can access Experiment section menus.",
  },
  {
    id: 20200,
    module: "Experiment",
    description:
      "Able to browse the components within Experiment. Can access Experiment section menus.",
  },

  // Pipeline
  {
    id: 20300,
    module: "Pipeline",
    description:
      "Able to modify and browse the components within Automation. Can access Automation section menus.",
  },
  {
    id: 20400,
    module: "Pipeline",
    description:
      "Able to browse the components within Automation. Can access Automation section menus.",
  },

  // Feature Store
  {
    id: 20500,
    module: "Feature Store",
    description:
      "Able to modify and browse the components within Feature Store. Can access Feature Store section menus.",
  },
  {
    id: 20600,
    module: "Feature Store",
    description:
      "Able to browse the components within Feature Store. Can access Feature Store section menus.",
  },

  // Model Registry
  {
    id: 20700,
    module: "Model Registry",
    description:
      "Able to modify and browse the components within Model Registry. Can access Model Registry section menus.",
  },
  {
    id: 20800,
    module: "Model Registry",
    description:
      "Able to browse the components within Model Registry. Can access Model Registry section menus.",
  },

  // ML Runtime
  {
    id: 20900,
    module: "ML Runtime",
    description:
      "Able to modify and browse the components within ML Runtime. Can access ML Runtime menu.",
  },
  {
    id: 21000,
    module: "ML Runtime",
    description:
      "Able to browse the components within ML Runtime. Can access ML Runtime menu.",
  },

  // App Gallery
  {
    id: 21100,
    module: "App Gallery",
    description:
      "Able to modify and browse the components within App Gallery. Can access App Gallery menu.",
  },
  {
    id: 21200,
    module: "App Gallery",
    description:
      "Able to browse the components within App Gallery. Can access App Gallery menu.",
  },
];

const role_permissions = {
  "Project Leader": [10200, 10600, 10700],
  "Data Scientist": [20100, 20300, 20500, 20700, 21000],
  "ML Engineer": [20300, 20500, 20700, 20900, 21100],
  "Business User": [20600, 20800, 21000, 21200],
  "Ops Support": [20100, 20300, 20500, 20700, 21000, 21100],
  "Data Engineer": [20500, 20700],
};

export { permissionList, role_permissions };

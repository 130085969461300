import request from "@/utils/project/request";

// ml app
export const getAppGalleryDatas = (params) => {
  return request.get(`/appgallery/mlapp`, params);
};
export const getAppGalleryById = (id) => {
  return request.get(`/appgallery/mlapp/${id}`);
};
export const createAppGallery = (params) => {
  return request.post(`/appgallery/mlapp`, params);
};
export const updateAppGallery = (id, params) => {
  return request.put(`/appgallery/mlapp/${id}`, params);
};

// ml app version
export const getAppVersion = (params) => {
  return request.get(`/appgallery/mlapp_version`, params);
};
export const createAppVersion = (params) => {
  return request.post(`/appgallery/mlapp_version`, params);
};
export const updateAppVersion = (id, params) => {
  return request.put(`/appgallery/mlapp_version/${id}`, params);
};

// tags
export const getTagDatas = (params) => {
  return request.get(`/govern/tag`, params);
};
export const createTag = (params) => {
  return request.post(`/govern/tag`, params);
};

export const createRelTagApp = (params) => {
  return request.post(`/appgallery/rel_tag_mlapp`, params);
};
export const getRelTagApp = (params) => {
  return request.get(`/appgallery/rel_tag_mlapp`, params);
};
export const deleteRelTagApp = (id, params) => {
  return request.put(`/appgallery/rel_tag_mlapp/${id}`, params);
};

// files
export const uploadFile = (params) => {
  return request.post(`/appgallery/file_upload`, params);
};
export const getFile = (params) => {
  return request.post(`/govern/s3_presigned_url`, params);
};
export const deleteFile = (s3_key) => {
  return request.delete(`/appgallery/file_upload/${s3_key}`);
};

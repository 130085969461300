// material
import { Box, Typography } from "@mui/material";
// @types
import { RootState, useSelector } from "@/redux/store";
//
import Scrollbar from "@/components/project/Scrollbar";
import { SchemaNodeEntity, SchemaEdgeEntity } from "../graph/slices/slice";
import EdgeNodeCard from "./edge-config-components/EdgeNodeCard";
import EdgeSrcNodeCardForCond from "./edge-config-components/EdgeSrcNodeCardForCond";
import EdgeTarNodeCardForCond from "./edge-config-components/EdgeTarNodeCardForCond";

// ----------------------------------------------------------------------

type PipelineEdgeConfigProps = {
  entity: SchemaEdgeEntity | null;
};

export default function PipelineEdgeConfig({
  entity,
}: PipelineEdgeConfigProps) {
  const srcEntityId = entity?.sourceEntityId;
  const srcEntity = useSelector((state: RootState) => {
    let entity: SchemaNodeEntity | undefined =
      state.schema.schema.pipelineSteps.find((entity) => {
        return entity.id === srcEntityId;
      });
    return entity ? entity : null;
  });

  return (
    <>
      <Box>
        <Scrollbar>
          {entity && (
            <Box>
              <Typography
                variant="overline"
                sx={{
                  mt: 2,
                  mb: 1,
                  px: 2,
                  display: "block",
                  color: "text.disabled",
                }}
              >
                source
              </Typography>

              {srcEntity?.stepType === "Condition" && (
                <EdgeSrcNodeCardForCond
                  nodeId={entity.sourceEntityId}
                  property={
                    entity.property ? entity.property.split("&&")[0] : ""
                  }
                  edge={entity}
                />
              )}
              {srcEntity?.stepType !== "Condition" && (
                <EdgeNodeCard
                  nodeId={entity.sourceEntityId}
                  property={
                    entity.property ? entity.property.split("&&")[0] : ""
                  }
                />
              )}

              <Typography
                variant="overline"
                sx={{
                  mt: 2,
                  mb: 1,
                  px: 2,
                  display: "block",
                  color: "text.disabled",
                }}
              >
                target
              </Typography>

              {srcEntity?.stepType === "Condition" && (
                <EdgeTarNodeCardForCond
                  nodeId={entity.targetEntityId}
                  property={
                    entity.property ? entity.property.split("&&")[1] : ""
                  }
                />
              )}
              {srcEntity?.stepType !== "Condition" && (
                <EdgeNodeCard
                  nodeId={entity.targetEntityId}
                  property={
                    entity.property ? entity.property.split("&&")[1] : ""
                  }
                />
              )}
            </Box>
          )}
        </Scrollbar>
      </Box>
    </>
  );
}

import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as fs from "fs";
import { parseFromXml, parseToXml } from "../schema/Parser";
import {
  Step,
  StepCodeStruct,
  PipelineParameter,
  TrainingCodeStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline";
import {
  ProcessingStep,
  NetworkConfigStruct,
  ProcessingProcessorStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-processing";
import {
  TrainingStep,
  TrainingOutputStruct,
  TrainingEstimatorStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-training";
import {
  RegisterModelStep,
  ModelMetricStruct,
  DriftCheckBaselinesStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-register-model";
import { ConditionStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-condition";
import {
  TuningStep,
  TuningJobDefStruct,
  TrainingJobDefStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-tuning";
import {
  ModelStep,
  ModelDataStruct,
  ModelContainerStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-model";
import {
  LambdaStep,
  LambdaFunctionStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-lambda";
import { FailStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-fail";
import { TransformStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-transform";
import {
  ClarifyCheckStep,
  ClarifyCheckStepInput,
  ClarifyCheckJobConfigStruct,
  ClarifyCheckDataConfigStruct,
  ClarifyCheckModelConfigStruct,
  ClarifyCheckBiasConfigStruct,
  ClarifyCheckShapConfigStruct,
  ClarifyCheckPredLabelConfigStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-clarify-check";
import { modeType, PipelineType } from "@/@types/project/model/schema";
import { importFromSmPipelineDef } from "./thunks";
import {
  UpdateProcessingInputPayload,
  UpdateProcessingOutputPayload,
  UpdatePropertyFilePayload,
  UpdateConditionPayload,
  UpdateConditionEdgesPayload,
  UpdateConditionIfElseStepsPayload,
  UpdateSelectedEntitiesPayload,
  UpdateNodeEntityPayload,
  AddEdgeEntityPayload,
  AddTagPayload,
  UpdateEntityNamePayload,
  UpdateTagPayload,
  UpdateEntityTagPayload,
  AddEntityPropertyPayload,
  UpdateEntityPropertyPayload,
  RelationshipPayload,
  updateRegisterModelPayload,
  updateTuningConfigPayload,
  UpdateLambdaInputPayload,
  UpdateLambdaOutputPayload,
} from "@/@types/project/model/schema";
import {
  findEntityById,
  findEdgeById,
  findParentEntityById,
  findStepById,
  updateNodePostion,
  generateNodeStepInfoWhenStepNew,
  handleCrudKubeflowJobArgs,
} from "./utils";

export interface SchemaState {
  mode: modeType;
  pipelineType: PipelineType;
  fixNextRender: boolean;
  showPropPanel: boolean;
  showConnectionProp: boolean;
  schema: Schema;
  currentEntityId: string | null;
  selectedEntities: string[];
  parentEntities: string[];
}

export interface Schema {
  name: string;
  tags: SchemaEntityTag[];
  relationships: SchemaEntityRelationship[];
  // nodeEntities: SchemaNodeEntity[];
  edgeEntities: SchemaEdgeEntity[];
  pipelineRoleArn: string;
  pipelineSteps: Step[];
  pipelineParameters?: PipelineParameter[];
}

export interface SchemaNodeEntity {
  id: string;
  name: string;
  nodeX: number;
  nodeY: number;
  tags?: number[];
  properties: SchemaEntityProperty[];
  stepType: string;
}

export interface SchemaEdgeEntity {
  id: string;
  relationship: number;
  property?: string;
  properties: SchemaEntityProperty[];
  sourceEntityId: string;
  sourcePortId: string;
  targetEntityId: string;
  targetPortId: string;
}

export interface SchemaEntityProperty {
  id: number;
  title: string;
  type: number;
}

export interface SchemaEntityTag {
  id: number;
  tag: string;
  color: string;
  isNew: boolean;
}

export interface SchemaEntityRelationship {
  id: number;
  value: string;
  isNew: boolean;
}

const initialState: SchemaState = {
  mode: "create",
  pipelineType: "Kubeflow",
  fixNextRender: false,
  showPropPanel: true,
  showConnectionProp: false,
  schema: {
    name: "",
    tags: [],
    // nodeEntities: [],
    edgeEntities: [],
    relationships: [],
    pipelineRoleArn: "",
    pipelineSteps: [],
  },
  currentEntityId: null,
  selectedEntities: [],
  parentEntities: [],
};

// abandoned
export const importFromXml = createAsyncThunk(
  "schema/importFromXmlAsync",
  async (pipeline: string) => {
    // try {
    //   return await parseFromXml(pipeline);
    // } catch (e) {
    //   return parseFromXml(pipeline);
    // }
    return parseFromXml(pipeline);
  }
);

const schemaSlice = createSlice({
  name: "schema",
  initialState,
  reducers: {
    setSchemaData(state, action) {
      Object.assign(state, action.payload);
    },

    // first level data, such as { name: xx }
    updateStepAttrs(state, action) {
      const step = findStepById(state, state.currentEntityId);
      if (!step) return;

      Object.assign(step, action.payload);
    },

    // second level data, such as processpr: {}
    updateOneKeyofStep(state, action) {
      const step = findStepById(state, state.currentEntityId);
      if (!step) return;
      const stepKey = action.payload.key;

      Object.assign(step[stepKey], action.payload.data);
    },

    // Kubeflow reducers
    handleKubeflowStepArray(state, action) {
      handleCrudKubeflowJobArgs(state, action);
    },

    // Kubeflow reducers END
    setSchemaName(state, action: PayloadAction<string>) {
      state.schema.name = action.payload;
    },
    setShowPropPanel(state, action: PayloadAction<boolean>) {
      state.showPropPanel = action.payload;
    },
    setShowConnectionProp(state) {
      state.showConnectionProp = !state.showConnectionProp;
    },
    setCurrentEntity(state, action: PayloadAction<string | null>) {
      const entityId = action.payload;
      state.currentEntityId = entityId;
      if (entityId != null) {
        state.selectedEntities = [entityId];
        findParentEntityById(state, state.currentEntityId);
      } else {
        state.selectedEntities = [];
        state.parentEntities = [];
      }
    },
    clearPipelineParameter(state) {
      if (state.schema.pipelineParameters !== undefined) {
        state.schema.pipelineParameters = undefined;
      }
    },
    updateProcessingInput(
      state,
      action: PayloadAction<UpdateProcessingInputPayload>
    ) {
      const step = findStepById(state, state.currentEntityId);
      if (action.payload.id !== undefined) {
        const processingInputs = (step as ProcessingStep).processingInputs;
        if (processingInputs) {
          processingInputs[action.payload.id] = action.payload.data;
        }
      } else {
        (step as ProcessingStep).processingInputs?.push(action.payload.data);
      }
    },
    deleteProcessingInput(state, action: PayloadAction<number>) {
      const step = findStepById(state, state.currentEntityId);
      const processingInputs = (step as ProcessingStep).processingInputs;
      const processingInputId = action.payload;
      if (processingInputs && processingInputId > -1) {
        processingInputs.splice(processingInputId, 1);
      }
    },
    updateProcessingOutput(
      state,
      action: PayloadAction<UpdateProcessingOutputPayload>
    ) {
      const step = findStepById(state, state.currentEntityId);
      if (action.payload.id !== undefined) {
        const processingOutputs = (step as ProcessingStep).processingOutputs;
        if (processingOutputs) {
          processingOutputs[action.payload.id] = action.payload.data;
        }
      } else {
        (step as ProcessingStep).processingOutputs?.push(action.payload.data);
      }
    },
    deleteProcessingOutput(state, action: PayloadAction<number>) {
      const step = findStepById(state, state.currentEntityId);
      const processingOutputs = (step as ProcessingStep).processingOutputs;
      const processingOutputId = action.payload;
      if (processingOutputs && processingOutputId > -1) {
        processingOutputs.splice(processingOutputId, 1);
      }
    },
    updatePropertyFile(
      state,
      action: PayloadAction<UpdatePropertyFilePayload>
    ) {
      const step = findStepById(state, state.currentEntityId);
      if (action.payload.id !== undefined) {
        const propertyFiles = (step as ProcessingStep).propertyFiles;
        if (propertyFiles) {
          propertyFiles[action.payload.id] = action.payload.data;
        }
      } else {
        (step as ProcessingStep).propertyFiles?.push(action.payload.data);
      }
    },
    deletePropertyFile(state, action: PayloadAction<number>) {
      const step = findStepById(state, state.currentEntityId);
      const propertyFiles = (step as ProcessingStep).propertyFiles;
      const propertyFileId = action.payload;
      if (propertyFiles && propertyFileId > -1) {
        propertyFiles.splice(propertyFileId, 1);
      }
    },
    updateProcessingProcessor(
      state,
      action: PayloadAction<ProcessingProcessorStruct>
    ) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        const envs = (step as ProcessingStep).processor.environmentVars;
        (step as ProcessingStep).processor = {
          ...action.payload,
          environmentVars: envs,
        };
      }
    },
    updateProcessingEnvVars(state, action) {
      const step = findStepById(state, state.currentEntityId);
      if (action.payload.id != null) {
        const envVar = (step as ProcessingStep).processor.environmentVars?.[
          action.payload.id
        ];
        if (!envVar) return;
        envVar.name = action.payload.name;
        envVar.value = action.payload.value;
      } else {
        (step as ProcessingStep).processor.environmentVars?.push(
          action.payload
        );
      }
    },
    deleteProcessingEnvVars(state, action: PayloadAction<number>) {
      const step = findStepById(state, state.currentEntityId);
      const envVars = (step as ProcessingStep).processor.environmentVars;
      const envVarId = action.payload;
      if (envVars && envVarId > -1) {
        envVars.splice(envVarId, 1);
      }
    },
    updateProcessingCode(state, action: PayloadAction<StepCodeStruct>) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        const args = (step as ProcessingStep).code?.jobArgs;
        (step as ProcessingStep).code = { ...action.payload, jobArgs: args };
      }
    },
    updateProcessingNetwork(state, action: PayloadAction<NetworkConfigStruct>) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        (step as ProcessingStep).networkConfig = {
          ...action.payload,
        };
      }
    },
    updateProcessingJobArgs(state, action) {
      const step = findStepById(state, state.currentEntityId);
      if (action.payload.id != null) {
        const jobArgs = (step as ProcessingStep).code.jobArgs;
        if (jobArgs) {
          jobArgs[action.payload.id] = action.payload.data;
        }
      } else {
        (step as ProcessingStep).code.jobArgs?.push(action.payload.data);
      }
    },
    deleteProcessingJobArgs(state, action: PayloadAction<number>) {
      const step = findStepById(state, state.currentEntityId);
      const jobArgs = (step as ProcessingStep).code.jobArgs;
      const jobArgId = action.payload;
      if (jobArgs && jobArgId > -1) {
        jobArgs.splice(jobArgId, 1);
      }
    },
    // TRAINING STEP
    updateTrainingEstimator(
      state,
      action: PayloadAction<TrainingEstimatorStruct>
    ) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        const envs = (step as TrainingStep).estimator.environmentVars;
        const hps = (step as TrainingStep).estimator.hyperparameters;
        (step as TrainingStep).estimator = {
          ...action.payload,
          environmentVars: envs,
          hyperparameters: hps,
        };
      }
      // console.log((step as ProcessingStep).processor);
    },
    updateTrainingCode(state, action: PayloadAction<TrainingCodeStruct>) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        (step as TrainingStep).code = { ...action.payload };
      }
    },
    updateTrainingInput(state, action) {
      const step = findStepById(state, state.currentEntityId);
      if (step) {
        if (step.type === "Training") {
          if (action.payload.id !== undefined) {
            const trainingInputs = (step as TrainingStep).trainingInputs;
            if (trainingInputs) {
              trainingInputs[action.payload.id] = action.payload.data;
            }
          } else {
            (step as TrainingStep).trainingInputs?.push(action.payload.data);
          }
        } else if (step.type === "Tuning") {
          if (action.payload.id !== undefined) {
            const trainingInputs = (step as TuningStep).trainingInputs;
            if (trainingInputs) {
              trainingInputs[action.payload.id] = action.payload.data;
            }
          } else {
            (step as TuningStep).trainingInputs?.push(action.payload.data);
          }
        }
      }
      // console.log((step as TrainingStep).trainingInputs[0]);
    },
    deleteTrainingInput(state, action: PayloadAction<number>) {
      const step = findStepById(state, state.currentEntityId);
      const trainingInputId = action.payload;
      if (step && trainingInputId > -1) {
        if (step.type === "Training") {
          const trainingInputs = (step as TrainingStep).trainingInputs;
          if (trainingInputs) {
            trainingInputs.splice(trainingInputId, 1);
          }
        } else if (step.type === "Tuning") {
          const trainingInputs = (step as TuningStep).trainingInputs;
          if (trainingInputs) {
            trainingInputs.splice(trainingInputId, 1);
          }
        }
      }
    },
    updateTrainingOutput(state, action: PayloadAction<TrainingOutputStruct>) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        (step as TrainingStep).trainingOutput = {
          ...action.payload,
        };
      }
    },
    updateTrainingEnvVars(state, action) {
      const step = findStepById(state, state.currentEntityId);

      if (step) {
        if (step.type === "Training") {
          if (action.payload.id !== undefined) {
            const envVar = (step as TrainingStep).estimator.environmentVars?.[
              action.payload.id
            ];
            if (!envVar) return;
            envVar.name = action.payload.name;
            envVar.value = action.payload.value;
          } else {
            (step as TrainingStep).estimator.environmentVars?.push(
              action.payload
            );
          }
        } else if (step.type === "Model") {
          if (action.payload.id !== undefined) {
            const envVar = (step as ModelStep).container.environmentVars?.[
              action.payload.id
            ];
            if (!envVar) return;
            envVar.name = action.payload.name;
            envVar.value = action.payload.value;
          } else {
            (step as ModelStep).container.environmentVars?.push(action.payload);
          }
        }
      }
    },
    deleteTrainingEnvVars(state, action: PayloadAction<number>) {
      const step = findStepById(state, state.currentEntityId);
      if (step) {
        const envVars =
          step.type === "Training"
            ? (step as TrainingStep).estimator.environmentVars
            : (step as ModelStep).container.environmentVars;
        const envVarId = action.payload;
        if (envVars && envVarId > -1) {
          envVars.splice(envVarId, 1);
        }
      }
    },
    updateTrainingHyperparameters(state, action) {
      const step = findStepById(state, state.currentEntityId);

      if (step) {
        if (step.type === "Training") {
          if (action.payload.id !== undefined) {
            const hps = (step as TrainingStep).estimator.hyperparameters;
            if (hps) {
              hps[action.payload.id] = action.payload.data;
            }
          } else {
            (step as TrainingStep).estimator.hyperparameters?.push(
              action.payload.data
            );
          }
        } else if (step.type === "Tuning") {
          if (action.payload.id !== undefined) {
            const hps = (step as TuningStep).trainingJob.hyperparameters;
            if (hps) {
              hps[action.payload.id] = action.payload.data;
            }
          } else {
            (step as TuningStep).trainingJob.hyperparameters?.push(
              action.payload.data
            );
          }
        }
      }
    },
    deleteTrainingHyperparameters(state, action: PayloadAction<number>) {
      const step = findStepById(state, state.currentEntityId);
      const hpId = action.payload;

      if (step && hpId > -1) {
        if (step.type === "Training") {
          const hps = (step as TrainingStep).estimator.hyperparameters;
          if (hps && hpId > -1) {
            hps.splice(hpId, 1);
          }
        } else if (step.type === "Tuning") {
          const hps = (step as TuningStep).trainingJob.hyperparameters;
          if (hps && hpId > -1) {
            hps.splice(hpId, 1);
          }
        }
      }
    },
    updateMetricDefinition(state, action) {
      const step = findStepById(state, state.currentEntityId);
      if (action.payload.id != null) {
        const md = (step as TrainingStep).metricDefinitions?.[
          action.payload.id
        ];
        if (!md) return;
        md.Name = action.payload.name;
        md.Regex = action.payload.value;
      } else {
        (step as TrainingStep).metricDefinitions?.push(action.payload);
      }
    },
    deleteMetricDefinition(state, action: PayloadAction<number>) {
      const step = findStepById(state, state.currentEntityId);
      const mds = (step as TrainingStep).metricDefinitions;
      const mdId = action.payload;
      if (mds && mdId > -1) {
        mds.splice(mdId, 1);
      }
    },
    // REGISTER MODEL STEP
    updateModelConfig(
      state,
      action: PayloadAction<updateRegisterModelPayload>
    ) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        (step as RegisterModelStep).approvalStatus =
          action.payload.approvalStatus;
        (step as RegisterModelStep).approvalStatusUseParam =
          action.payload.approvalStatusUseParam;
        (step as RegisterModelStep).model = {
          ...action.payload,
        };
      }
    },
    updateModelMetrics(state, action: PayloadAction<ModelMetricStruct>) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        (step as RegisterModelStep).metrics = action.payload;
      }
    },
    updateDriftCheckBaslines(
      state,
      action: PayloadAction<DriftCheckBaselinesStruct>
    ) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        (step as RegisterModelStep).driftCheckBaselines = action.payload;
      }
    },
    // End
    // CONDITION STEP
    updateCondition(state, action: PayloadAction<UpdateConditionPayload>) {
      const step = findStepById(state, state.currentEntityId);
      if (action.payload.id !== undefined) {
        const conditions = (step as ConditionStep).conditions;
        if (conditions) {
          conditions[action.payload.id] = action.payload.data;
        }
      } else {
        (step as ConditionStep).conditions?.push(action.payload.data);
      }
    },
    deleteCondition(state, action: PayloadAction<number>) {
      const step = findStepById(state, state.currentEntityId);
      const conditions = (step as ConditionStep).conditions;
      const conditionId = action.payload;
      if (conditions && conditionId > -1) {
        conditions.splice(conditionId, 1);
      }
    },
    updateConditionIfElseSteps(
      state,
      action: PayloadAction<UpdateConditionIfElseStepsPayload>
    ) {
      const payload = action.payload;
      if (payload.id.length === 0) return;
      const step = findStepById(state, payload.id);
      if (!step) return;

      if (action.payload.branch === "if") {
        (step as ConditionStep).ifSteps = [...action.payload.data];
      } else if (action.payload.branch === "else") {
        (step as ConditionStep).elseSteps = [...action.payload.data];
      }
    },
    updateConditionEdges(
      state,
      action: PayloadAction<UpdateConditionEdgesPayload>
    ) {
      const payload = action.payload;
      if (payload.id.length === 0) return;
      const entity = findEdgeById(state, payload.id);
      if (!entity) return;
      (entity as SchemaEdgeEntity).property = action.payload.data;

      const tarStep = findStepById(
        state,
        (entity as SchemaEdgeEntity).targetEntityId
      );

      const srcStep = findStepById(
        state,
        (entity as SchemaEdgeEntity).sourceEntityId
      ) as ConditionStep;

      if (!tarStep || !srcStep) return;

      if (action.payload.data.includes("true")) {
        if (!srcStep.ifSteps.includes(tarStep.name)) {
          srcStep.ifSteps.push(tarStep.name);
        }
      } else if (action.payload.data.includes("false")) {
        if (!srcStep.elseSteps.includes(tarStep.name)) {
          srcStep.elseSteps.push(tarStep.name);
        }
      }
    },
    // END OF CONDITION STEP
    //
    // TUNING STEP
    updateTuningJobDef(state, action: PayloadAction<TuningJobDefStruct>) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        const prs = (step as TuningStep).tuningJob.parameterRanges;
        (step as TuningStep).tuningJob = {
          ...action.payload,
          parameterRanges: prs,
        };
      }
      // console.log((step as ProcessingStep).processor);
    },
    updateTuningParameterRanges(state, action) {
      const step = findStepById(state, state.currentEntityId);
      const prId = action.payload.id;
      if (prId !== undefined) {
        const prs = (step as TuningStep).tuningJob.parameterRanges;
        if (prs) {
          prs[prId] = action.payload.data;
        }
      } else {
        (step as TuningStep).tuningJob.parameterRanges?.push(
          action.payload.data
        );
      }
    },
    deleteTuningParameterRange(state, action: PayloadAction<number>) {
      const step = findStepById(state, state.currentEntityId);
      const prs = (step as TuningStep).tuningJob.parameterRanges;
      const prId = action.payload;
      if (prs && prId > -1) {
        prs.splice(prId, 1);
      }
    },
    updateTrainingJobDef(state, action: PayloadAction<TrainingJobDefStruct>) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        const hps = (step as TuningStep).trainingJob.hyperparameters;
        (step as TuningStep).trainingJob = {
          ...action.payload,
          hyperparameters: hps,
        };
      }
    },
    updateTuningConfig(
      state,
      action: PayloadAction<updateTuningConfigPayload>
    ) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        (step as TuningStep).metricName = action.payload.metricName;
        (step as TuningStep).tuningType = action.payload.tuningType;
        (step as TuningStep).tuningTypeUseParam =
          action.payload.tuningTypeUseParam;
      }
    },
    // END OF TUNING STEP
    // MODEL STEP
    updateModelContainer(state, action: PayloadAction<ModelContainerStruct>) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        const envs = (step as ModelStep).container.environmentVars;
        (step as ModelStep).container = {
          ...action.payload,
          environmentVars: envs,
        };
      }
    },
    updateModelData(state, action: PayloadAction<ModelDataStruct>) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        (step as ModelStep).modelData = {
          ...action.payload,
        };
      }
    },
    // END OF MODEL STEP
    // TRANSFORM STEP
    updateTransformData(state, action: PayloadAction<TransformStep>) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        (step as TransformStep).modelName = action.payload.modelName;
        (step as TransformStep).modelNameUseParam =
          action.payload.modelNameUseParam;
        (step as TransformStep).dataSource = action.payload.dataSource;
        (step as TransformStep).dataSourceUseParam =
          action.payload.dataSourceUseParam;
        (step as TransformStep).outputPath = action.payload.outputPath;
        (step as TransformStep).outputPathUseParam =
          action.payload.outputPathUseParam;
        (step as TransformStep).instanceType = action.payload.instanceType;
        (step as TransformStep).instanceTypeUseParam =
          action.payload.instanceTypeUseParam;
        (step as TransformStep).instanceCount = action.payload.instanceCount;
        (step as TransformStep).instanceCountUseParam =
          action.payload.instanceCountUseParam;
      }
    },
    // END OF TRANSFORM STEP
    // FAIL STEP
    updateFailData(state, action: PayloadAction<FailStep>) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        (step as FailStep).errorMessage = action.payload.errorMessage;
        (step as FailStep).errorMessageUseParam =
          action.payload.errorMessageUseParam;
      }
    },
    // END OF FAIL STEP
    // LAMBDA STEP
    updateLambdaFunction(state, action: PayloadAction<LambdaFunctionStruct>) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        (step as LambdaStep).function = action.payload;
      }
    },
    updateLambdaInput(state, action: PayloadAction<UpdateLambdaInputPayload>) {
      const step = findStepById(state, state.currentEntityId);
      if (action.payload.id !== undefined) {
        const lambdaInputs = (step as LambdaStep).lambdaInputs;
        if (lambdaInputs) {
          lambdaInputs[action.payload.id] = action.payload.data;
        }
      } else {
        (step as LambdaStep).lambdaInputs?.push(action.payload.data);
      }
    },
    deleteLambdaInput(state, action: PayloadAction<number>) {
      const step = findStepById(state, state.currentEntityId);
      const lambdaInputs = (step as LambdaStep).lambdaInputs;
      const lambdaInputId = action.payload;
      if (lambdaInputs && lambdaInputId > -1) {
        lambdaInputs.splice(lambdaInputId, 1);
      }
    },
    updateLambdaOutput(
      state,
      action: PayloadAction<UpdateLambdaOutputPayload>
    ) {
      const step = findStepById(state, state.currentEntityId);
      if (action.payload.id !== undefined) {
        const lambdaOutputs = (step as LambdaStep).lambdaOutputs;
        if (lambdaOutputs) {
          lambdaOutputs[action.payload.id] = action.payload.data;
        }
      } else {
        (step as LambdaStep).lambdaOutputs?.push(action.payload.data);
      }
    },
    deleteLambdaOutput(state, action: PayloadAction<number>) {
      const step = findStepById(state, state.currentEntityId);
      const lambdaOutputs = (step as LambdaStep).lambdaOutputs;
      const lambdaOutputId = action.payload;
      if (lambdaOutputs && lambdaOutputId > -1) {
        lambdaOutputs.splice(lambdaOutputId, 1);
      }
    },
    // END OF LAMBDA STEP
    // START OF CLARIFYCHECK STEP
    updateClarifyCheckInput(
      state,
      action: PayloadAction<ClarifyCheckStepInput>
    ) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        (step as ClarifyCheckStep).checkStepInput = action.payload;
      }
    },
    updateClarifyCheckProcessor(
      state,
      action: PayloadAction<ClarifyCheckJobConfigStruct>
    ) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        (step as ClarifyCheckStep).checkJobConfig = action.payload;
      }
    },
    updateClarifyCheckModelConfig(
      state,
      action: PayloadAction<ClarifyCheckModelConfigStruct>
    ) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        (step as ClarifyCheckStep).modelConfig = action.payload;
      }
    },
    updateClarifyChecDataConfig(
      state,
      action: PayloadAction<ClarifyCheckDataConfigStruct>
    ) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        (step as ClarifyCheckStep).dataConfig = action.payload;
      }
    },
    updateClarifyPredLabelConfig(
      state,
      action: PayloadAction<ClarifyCheckPredLabelConfigStruct>
    ) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        (step as ClarifyCheckStep).predLabelConfig = action.payload;
      }
    },
    updateClarifyBiasConfig(
      state,
      action: PayloadAction<ClarifyCheckBiasConfigStruct>
    ) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        (step as ClarifyCheckStep).biasConfig = action.payload;
      }
    },
    updateClarifyShapConfig(
      state,
      action: PayloadAction<ClarifyCheckShapConfigStruct>
    ) {
      const step = findStepById(state, state.currentEntityId);
      if (step && action.payload) {
        (step as ClarifyCheckStep).shapConfig = action.payload;
      }
    },
    // END OF CLARIFYCHECK STEP

    // Global step or edge utils functions
    updateSelectedEntities(
      state,
      action: PayloadAction<UpdateSelectedEntitiesPayload>
    ) {
      state.selectedEntities = action.payload.selected;
    },
    updateNodeEntity(state, action: PayloadAction<UpdateNodeEntityPayload>) {
      const payload = action.payload;
      if (payload.id.length > 0) {
        const node = state.schema.pipelineSteps.find(
          (entity) => entity.id === action.payload.id
        );
        if (node) {
          node.nodeX = action.payload.nodeX;
          node.nodeY = action.payload.nodeY;
        }
      } else {
        const currentMaxId = state.schema.pipelineSteps.length
          ? Math.max(
              ...state.schema.pipelineSteps.map((entity) => parseInt(entity.id))
            )
          : 0;
        const id = currentMaxId ? currentMaxId + 1 : 1;

        let position = updateNodePostion(state, payload.nodeX, payload.nodeY);

        state.schema.pipelineSteps.push(
          generateNodeStepInfoWhenStepNew({
            id,
            position,
            stepType: payload.stepType,
            isKubeflow: payload.isKubeflow,
          })
        );
      }
    },
    deleteEntities(state, action: PayloadAction<Array<string>>) {
      action.payload.forEach((id) => {
        const index = state.schema.pipelineSteps.findIndex(
          (entity) => entity.id === id
        );
        if (index >= 0) {
          // state.schema.nodeEntities.splice(index, 1);
          state.schema.pipelineSteps.splice(index, 1);
          for (var i = state.schema.edgeEntities.length - 1; i >= 0; i--) {
            const edgeEntity = state.schema.edgeEntities[i];
            if (
              edgeEntity.sourceEntityId === id ||
              edgeEntity.targetEntityId === id
            ) {
              state.schema.edgeEntities.splice(i, 1);
            }
          }
        } else {
          const index = state.schema.edgeEntities.findIndex(
            (entity) => entity.id === id
          );
          if (index >= 0) {
            state.schema.edgeEntities.splice(index, 1);
          }
        }
      });
    },
    addEdgeEntity(state, action: PayloadAction<AddEdgeEntityPayload>) {
      const edgeEntity = action.payload.entity;
      const edgeEntityIndex = state.schema.edgeEntities.findIndex(
        (entity) => entity.id === edgeEntity.id
      );
      if (edgeEntityIndex > -1) {
        const oldEdgeEntity = state.schema.edgeEntities[edgeEntityIndex];
        edgeEntity.properties = oldEdgeEntity.properties;
        edgeEntity.relationship = oldEdgeEntity.relationship;
        state.schema.edgeEntities[edgeEntityIndex] = edgeEntity;
      } else {
        if (
          edgeEntity.relationship === 0 &&
          state.schema.relationships.length > 0
        ) {
          edgeEntity.relationship = state.schema.relationships[0].id;
        }
        state.schema.edgeEntities.push(edgeEntity);
      }
    },
    addEntityTag(state, action: PayloadAction<number>) {
      const entity = findEntityById(state, state.currentEntityId);
      if (!entity) return;
      const tags = (entity as SchemaNodeEntity).tags;
      if (tags) {
        const tagId = action.payload;
        if (state.schema.tags.length <= 0) return;
        const tag = state.schema.tags.find((tag) => tag.id === tagId);
        if (!tag) {
          tags.push(state.schema.tags[0].id);
        } else {
          tags.push(tagId);
        }
      }
    },
    updateEntityName(state, action: PayloadAction<UpdateEntityNamePayload>) {
      const entity = findEntityById(state, state.currentEntityId);
      if (!entity) return;

      // Check condition step
      state.schema.pipelineSteps.forEach((step) => {
        if (step.type === "Condition") {
          const id1 = (step as ConditionStep).ifSteps.indexOf(
            (entity as SchemaNodeEntity).name
          );
          if (id1 !== -1) {
            (step as ConditionStep).ifSteps[id1] = action.payload.name;
          }

          const id2 = (step as ConditionStep).elseSteps.indexOf(
            (entity as SchemaNodeEntity).name
          );
          if (id2 !== -1) {
            (step as ConditionStep).elseSteps[id2] = action.payload.name;
          }
        }
      });

      (entity as SchemaNodeEntity).name = action.payload.name;
    },
    updateEntityTag(state, action: PayloadAction<UpdateEntityTagPayload>) {
      const entity = findEntityById(state, state.currentEntityId);
      if (!entity) return;
      (entity as SchemaNodeEntity).tags = action.payload.tagIds;
    },
    deleteEntityTag(state, action: PayloadAction<number>) {
      const tagId = action.payload;
      const entity = findEntityById(state, state.currentEntityId);
      if (entity != null) {
        const tags = (entity as SchemaNodeEntity).tags;
        if (tags) {
          const tagIndex = tags.findIndex(
            (currentTagId) => currentTagId === tagId
          );
          if (tagIndex > -1) {
            tags.splice(tagIndex, 1);
          }
        }
      }
    },
    addEntityProperty(state, action: PayloadAction<AddEntityPropertyPayload>) {
      const entity = findEntityById(state, state.currentEntityId);
      if (!entity) return;
      const payload = action.payload;
      const currentMaxId = entity.properties.length
        ? Math.max(...entity.properties.map((tag) => tag.id))
        : 0;
      const id = currentMaxId ? currentMaxId + 1 : 1;
      entity.properties.push({
        id: id,
        title: payload.title,
        type: payload.type,
      });
    },
    updateEntityProperty(
      state,
      action: PayloadAction<UpdateEntityPropertyPayload>
    ) {
      const entity = findEntityById(state, state.currentEntityId);
      if (!entity) return;
      const payload = action.payload;
      const property = entity.properties.find(
        (property) => property.id === payload.id
      );
      if (!property) return;
      property.title = payload.title;
      property.type = payload.type;
    },
    deleteEntityProperty(state, action: PayloadAction<number>) {
      const propertyId = action.payload;
      const entity = findEntityById(state, state.currentEntityId);
      if (entity != null) {
        const propertyIndex = entity.properties.findIndex(
          (property) => property.id === propertyId
        );
        if (propertyIndex > -1) {
          entity.properties.splice(propertyIndex, 1);
        }
      }
    },
    updateEntityRelationship(state, action: PayloadAction<number>) {
      const entity = findEntityById(state, state.currentEntityId);
      if (entity) {
        if (entity as SchemaEdgeEntity) {
          (entity as SchemaEdgeEntity).relationship = action.payload;
        }
      }
    },
    addTag(state, action: PayloadAction<AddTagPayload>) {
      const currentMaxId = state.schema.tags.length
        ? Math.max(...state.schema.tags.map((tag) => tag.id))
        : 0;
      const id = currentMaxId ? currentMaxId + 1 : 1;
      const payload = action.payload;
      const tag = payload.tag.length <= 0 ? `*tag${id}` : payload.tag;
      state.schema.tags.push({
        id: id,
        tag: tag,
        color: payload.color,
        isNew: true,
      });
    },
    updateTag(state, action: PayloadAction<UpdateTagPayload>) {
      const tag = state.schema.tags.find((tag) => tag.id === action.payload.id);
      if (tag) {
        tag.tag = action.payload.tag;
        tag.color = action.payload.color;
      }
    },
    deleteTag(state, action: PayloadAction<number>) {
      const tagId = action.payload;
      const tagIndex = state.schema.tags.findIndex((tag) => tag.id === tagId);
      if (tagIndex > -1) {
        state.schema.tags.splice(tagIndex, 1);
      }
      // state.schema.nodeEntities.forEach((entity) => {
      //   for (var index = entity.tags.length - 1; index >= 0; index--) {
      //     if (entity.tags[index] === tagId) {
      //       entity.tags.splice(index, 1);
      //     }
      //   }
      // });
    },
    addRelationship(state, action: PayloadAction<string>) {
      const currentMaxId = state.schema.relationships.length
        ? Math.max(
            ...state.schema.relationships.map((relationship) => relationship.id)
          )
        : 0;
      const id = currentMaxId ? currentMaxId + 1 : 1;
      const newRelationship =
        action.payload.length > 0
          ? action.payload
          : `*relationship-${state.schema.relationships.length + 1}`;
      state.schema.relationships.push({
        id: id,
        value: newRelationship,
        isNew: true,
      });
    },
    updateRelationship(state, action: PayloadAction<RelationshipPayload>) {
      const newRelationship = action.payload.relationship;
      const relationshipIndex = state.schema.relationships.findIndex(
        (relationship) => relationship.id === newRelationship.id
      );
      if (relationshipIndex > -1) {
        state.schema.relationships[relationshipIndex] = newRelationship;
      }
    },
    deleteRelationship(state, action: PayloadAction<number>) {
      const relationshipIndex = state.schema.relationships.findIndex(
        (relationship) => relationship.id === action.payload
      );
      if (relationshipIndex > -1) {
        state.schema.relationships.splice(relationshipIndex, 1);
      }
      state.schema.edgeEntities.forEach((entity) => {
        if (entity.relationship === action.payload) {
          entity.relationship = 0;
        }
      });
    },
    resetTagNewStatus(state, action: PayloadAction<number>) {
      const tag = state.schema.tags.find((tag) => tag.id === action.payload);
      if (tag) {
        tag.isNew = false;
      }
    },
    resetRelationshipNewStatus(state, action: PayloadAction<number>) {
      const relationship = state.schema.relationships.find(
        (relationship) => relationship.id === action.payload
      );
      if (relationship) {
        relationship.isNew = false;
      }
    },
    exportToXml(state, action: PayloadAction<string>) {
      const path = action.payload;
      const xml = parseToXml(state.schema);
      fs.writeFile(path, xml, function (err) {
        if (err) throw err;
      });
    },
    updateFixNextRender(state, action: PayloadAction<boolean>) {
      state.fixNextRender = action.payload;
    },
    clearSchema(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.schema = {
          name: state.schema.name,
          tags: [],
          relationships: [],
          // nodeEntities: [],
          edgeEntities: [],
          pipelineRoleArn: "",
          pipelineSteps: [],
        };
      }
    },
  },
  extraReducers: (builder) => {
    /* builder.addCase(importFromXml.fulfilled, (_state, action) => {
      return {
        ...initialState,
        ...{ schema: action.payload },
        fixNextRender: true,
      };
    }); */
    builder.addCase(importFromSmPipelineDef.fulfilled, (_state, action) => {
      return {
        ...initialState,
        mode: _state.mode,
        pipelineType: _state.pipelineType,
        ...{ schema: action.payload },
        fixNextRender: true,
      };
    });
  },
});

export const {
  setSchemaData,
  updateStepAttrs,
  updateOneKeyofStep,

  // Kubeflow
  handleKubeflowStepArray,
  // Kubeflow end

  setSchemaName,
  setShowPropPanel,
  setShowConnectionProp,
  setCurrentEntity,
  //Processing step
  updateProcessingInput,
  deleteProcessingInput,
  updateProcessingOutput,
  deleteProcessingOutput,
  updatePropertyFile,
  deletePropertyFile,
  updateProcessingProcessor,
  updateProcessingEnvVars,
  deleteProcessingEnvVars,
  updateProcessingCode,
  updateProcessingNetwork,
  updateProcessingJobArgs,
  deleteProcessingJobArgs,
  //Training step
  updateTrainingCode,
  updateTrainingInput,
  deleteTrainingInput,
  updateTrainingOutput,
  updateTrainingEnvVars,
  deleteTrainingEnvVars,
  updateTrainingEstimator,
  updateTrainingHyperparameters,
  deleteTrainingHyperparameters,
  updateMetricDefinition,
  deleteMetricDefinition,
  //RegisterModel step
  updateModelConfig,
  updateModelMetrics,
  updateDriftCheckBaslines,
  //Condition step
  updateCondition,
  deleteCondition,
  updateConditionEdges,
  updateConditionIfElseSteps,
  // Tuning step
  updateTuningConfig,
  updateTuningJobDef,
  updateTrainingJobDef,
  deleteTuningParameterRange,
  updateTuningParameterRanges,
  // Model step
  updateModelData,
  updateModelContainer,
  // Transform step
  updateTransformData,
  // Lambda step
  updateLambdaInput,
  deleteLambdaInput,
  updateLambdaOutput,
  deleteLambdaOutput,
  updateLambdaFunction,
  // Fail step
  updateFailData,
  //ClarifyCheck step
  updateClarifyCheckInput,
  updateClarifyBiasConfig,
  updateClarifyShapConfig,
  updateClarifyCheckProcessor,
  updateClarifyChecDataConfig,
  updateClarifyPredLabelConfig,
  updateClarifyCheckModelConfig,

  updateSelectedEntities,

  clearPipelineParameter,

  updateNodeEntity,
  deleteEntities,

  updateEntityName,

  addEdgeEntity,

  addEntityProperty,
  deleteEntityProperty,
  updateEntityProperty,

  addEntityTag,
  updateEntityTag,
  deleteEntityTag,

  updateEntityRelationship,

  addTag,
  updateTag,
  deleteTag,

  addRelationship,
  updateRelationship,
  deleteRelationship,

  resetRelationshipNewStatus,
  resetTagNewStatus,

  exportToXml,
  updateFixNextRender,

  clearSchema,
} = schemaSlice.actions;

export default schemaSlice.reducer;

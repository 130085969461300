/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Typography,
  FormHelperText,
  Stack,
  Button,
  Collapse,
  Tooltip,
} from "@mui/material";
import MlNumberInputField from "@/components/project/mlComponents/MlNumberInputField";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { Form, Field, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import Scrollbar from "@/components/project/Scrollbar";
import {
  getMlRuntimeById,
  addMlServiceDeployment,
  editMlServiceDeployment,
  getModelPackageVersionList,
  getServiceDeploymentList,
} from "@/redux/project/mlService/mlRuntime/thunks";
import { dispatch, useSelector, RootState } from "@/redux/store";
import { useSnackbar } from "notistack";
import moment from "moment";
import { isEmpty } from "lodash";
import {
  getInitResourceQuota,
  queryRestResource,
  restResource,
} from "@/utils/project/resourceRequest";
import { IconButton } from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

const useStyles = makeStyles({
  titleMark: {
    marginTop: "10px",
    marginBottom: "10px",
    fontWeight: 600,
  },
});

interface IAddDeploymentDialogProps {
  isOpen: boolean;
  onClose: VoidFunction;
  mlServiceType: string;
  editDeployment?: boolean;
  mlServiceRes?: Record<string, any>;
}

interface FormValues {
  name: string;
  deployment_type: string;
  cluster: string;
  phase: string;
  model_name: string;
  model_package_version: string;
  model_a_name: string;
  model_a_version: string;
  model_a_traffic: string;
  model_b_name: string;
  model_b_version: string;
  model_b_traffic: string;
  seldon_server_type: string;
  image: string;
  role: string;
  gpu_vendor: string;
  sagemaker_instance_type: string;
  sagemaker_instance_count: string;
  replicas: string;
  gpu: string;
  cpu: string;
  mem: string;
  billing: string;
  provider: string;
  region: string;
  node_type: string;
  node_linux_arch: string;
  endpoint_type: string;
  tester_sample_payload: string;
}

const DeploymentCreateFormDialog = ({
  isOpen,
  onClose,
  mlServiceType,
  editDeployment,
  mlServiceRes,
}: IAddDeploymentDialogProps) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { runtimeId = "" } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const {
    modelPackageVersionList: {
      data: { items: mpvList = [] },
    },
    mlServiceDeploymentList: {
      data: { items: deploymentList = [] },
    },
    mlServiceVersionDescribe: { data: versionDescribe },
  } = useSelector((state: RootState) => state.mlRuntime);
  const {
    userDetail: { project_id },
  } = useSelector((state: RootState) => state.common);

  // resourceQuota
  const [resourceQuota, setResourceQuota] = useState<Record<string, any>>({});

  const isCreate = pathname.includes("mlRuntimeCreate");

  const initialValues: FormValues = {
    name: `mldeployment-${moment().valueOf()}`,
    deployment_type: "Single Model",
    cluster: "default-k8s",
    phase: "DEV",
    model_name: "a-model",
    model_package_version: "",
    model_a_name: "a-model",
    model_a_version: "",
    model_a_traffic: "",
    model_b_name: "b-model",
    model_b_version: "",
    model_b_traffic: "",
    seldon_server_type: "",
    image: "",
    role: "",
    gpu_vendor: "nvidia",
    sagemaker_instance_type: "",
    sagemaker_instance_count: "",
    replicas: "2",
    gpu: "None",
    cpu: "1",
    mem: "1",
    billing: "elastic",
    provider: "aws",
    region: "beijing",
    node_type: "cpu",
    node_linux_arch: "x86",
    endpoint_type: "REST",
    tester_sample_payload: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Deployment Name is required")
      .test("name-exist", "Deployment Name existed", (value) => {
        if (!editDeployment) {
          const isExisted =
            deploymentList.findIndex((item) => item.name === value) > -1;
          return !isExisted;
        } else {
          return true;
        }
      }),
    deployment_type: Yup.string().required("Deployment Type is required"),
    cluster: Yup.string().required("Cluster Name is required"),
    phase: Yup.string().required("Phase is required"),
    model_name: Yup.string().when("deployment_type", {
      is: "Single Model",
      then: Yup.string().required("Model Name is required"),
      otherwise: Yup.string(),
    }),
    model_package_version: Yup.string().when("deployment_type", {
      is: "Single Model",
      then: Yup.string().required("Model Package Version is required"),
      otherwise: Yup.string(),
    }),
    model_a_name: Yup.string().when("deployment_type", {
      is: "AB Testing",
      then: Yup.string().required("Model A Name is required"),
      otherwise: Yup.string(),
    }),
    model_a_version: Yup.string().when("deployment_type", {
      is: "AB Testing",
      then: Yup.string().required("Model A Package Version is required"),
      otherwise: Yup.string(),
    }),
    model_a_traffic: Yup.mixed().when("deployment_type", {
      is: "AB Testing",
      then: Yup.number()
        .typeError("Model A Traffic Ratio must be number type")
        .integer("Model A Traffic Ratio must be integer")
        .required("Model A Traffic Ratio is required")
        .min(1, "Model A Traffic Ratio number must be between 1 and 100")
        .max(100, "Model A Traffic Ratio number must be between 1 and 100"),
      otherwise: Yup.number(),
    }),
    model_b_name: Yup.string().when("deployment_type", {
      is: "AB Testing",
      then: Yup.string().required("Model B Name is required"),
      otherwise: Yup.string(),
    }),
    model_b_version: Yup.string().when("deployment_type", {
      is: "AB Testing",
      then: Yup.string().required("Model B Package Version is required"),
      otherwise: Yup.string(),
    }),
    model_b_traffic: Yup.mixed().when("deployment_type", {
      is: "AB Testing",
      then: Yup.number()
        .typeError("Model B Traffic Ratio must be number type")
        .integer("Model B Traffic Ratio must be integer")
        .required("Model B Traffic Ratio is required")
        .min(1, "Model B Traffic Ratio number must be between 1 and 100")
        .max(100, "Model B Traffic Ratio number must be between 1 and 100"),
      otherwise: Yup.string(),
    }),
    seldon_server_type:
      mlServiceType === "Kubeflow"
        ? Yup.string().required("Seldon Server Type is required")
        : Yup.string(),
    image:
      mlServiceType === "Kubeflow"
        ? Yup.string().when("seldon_server_type", {
            is: "CUSTOM_IMAGE",
            then: Yup.string().required("Image is required"),
            otherwise: Yup.string(),
          })
        : Yup.string().required("Image is required"),
    role:
      mlServiceType === "SageMaker"
        ? Yup.string().required("IAM Role is required")
        : Yup.string(),
    gpu_vendor:
      mlServiceType === "Kubeflow"
        ? Yup.string().required("Instance Type is required")
        : Yup.string(),
    sagemaker_instance_type:
      mlServiceType === "SageMaker"
        ? Yup.string().required("Instance Type is required")
        : Yup.string(),
    sagemaker_instance_count:
      mlServiceType === "SageMaker"
        ? Yup.string().required("Instance Count is required")
        : Yup.string(),
    replicas:
      mlServiceType === "Kubeflow"
        ? Yup.string().required("Replicas is required")
        : Yup.string(),
    gpu:
      mlServiceType === "Kubeflow"
        ? Yup.string().required("GPU is required")
        : Yup.string(),
    cpu:
      mlServiceType === "Kubeflow"
        ? Yup.string().required("CPU is required")
        : Yup.string(),
    mem:
      mlServiceType === "Kubeflow"
        ? Yup.string().required("Memory is required")
        : Yup.string(),
    billing: Yup.string().required("Billing is required"),
    provider: Yup.string().required("Provider is required"),
    region: Yup.string().required("Region is required"),
    node_type: Yup.string().required("Node Type is required"),
    node_linux_arch: Yup.string().required("Node Linux Arch is required"),
    endpoint_type:
      mlServiceType === "Kubeflow"
        ? Yup.string().required("Endpoint Type is required")
        : Yup.string(),
  });

  useEffect(() => {
    // get package version
    dispatch(
      getMlRuntimeById({
        id: isCreate ? mlServiceRes?.id : runtimeId,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    )
      .unwrap()
      .then((resp) => {
        dispatch(
          getModelPackageVersionList({
            params: {
              page_num: 1,
              page_size: 9999,
              modelpackage_id: resp?.mp_external_id,
            },
            alertCallback: enqueueSnackbar,
            pathname,
          })
        ).unwrap();
      });
    // get all deployment
    dispatch(
      getServiceDeploymentList({
        params: { page_num: 1, page_size: 9999 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    // return () => {
    //   resetForm();
    // }
  }, []);

  useEffect(() => {
    // initvalue
    const { env = {}, model_deploy = {} } =
      versionDescribe?.resource_config || {};
    if (editDeployment) {
      setFieldValue("name", versionDescribe?.name);
      setFieldValue("deployment_type", versionDescribe?.deploment_type);
      setFieldValue("cluster", env.cluster_names);
      setFieldValue("phase", versionDescribe?.phase);
      setFieldValue("description", versionDescribe?.description);

      if (model_deploy.models?.length > 0) {
        setFieldValue(
          "seldon_server_type",
          model_deploy.models[0].seldon_server_type
        );
        setFieldValue("image", model_deploy.models[0].image);
        setFieldValue("role", model_deploy.models[0].iam_role);
        setFieldValue("gpu_vendor", model_deploy.models[0].k8s_gpu_type);
        setFieldValue(
          "replicas",
          model_deploy.models[0].k8s_replicas?.toString()
        );
        setFieldValue(
          "gpu",
          model_deploy.models[0].k8s_gpu?.toString() || "None"
        );
        setFieldValue("cpu", model_deploy.models[0].k8s_cpu?.toString());
        setFieldValue("mem", model_deploy.models[0].k8s_mem?.toString());
        setFieldValue(
          "sagemaker_instance_type",
          model_deploy.models[0].sagemaker_instance_type
        );
        setFieldValue(
          "sagemaker_instance_count",
          model_deploy.models[0].sagemaker_instance_count
        );
      }

      if (
        versionDescribe?.deploment_type === "Single Model" &&
        model_deploy.models?.length === 1
      ) {
        setFieldValue("model_name", model_deploy.models[0].modelname);
        setFieldValue(
          "model_package_version",
          model_deploy.models[0].mpv_external_id
        );
      }

      if (
        versionDescribe?.deploment_type === "AB Testing" &&
        model_deploy.models?.length > 1
      ) {
        setFieldValue("model_a_name", model_deploy.models[0].modelname);
        setFieldValue(
          "model_a_version",
          model_deploy.models[0].mpv_external_id
        );
        setFieldValue("model_a_traffic", model_deploy.models[0].modeltraffic);
        setFieldValue("model_b_name", model_deploy.models[1].modelname);
        setFieldValue(
          "model_b_version",
          model_deploy.models[1].mpv_external_id
        );
        setFieldValue("model_b_traffic", model_deploy.models[1].modeltraffic);
      }

      if (model_deploy?.node_selector) {
        setFieldValue("billing", "elastic");
        setFieldValue("provider", "aws");
        setFieldValue("region", "beijing");
        setFieldValue("node_type", "cpu");
        setFieldValue("node_linux_arch", "x86");
      }

      setFieldValue("endpoint_type", model_deploy?.endpoint_type);
      setFieldValue(
        "tester_sample_payload",
        !isEmpty(versionDescribe?.input_payload)
          ? JSON.stringify(versionDescribe?.input_payload)
          : ""
      );
    }
  }, [versionDescribe]);

  const handleSubmitDeployment = (values) => {
    const resource_config = {
      env: {
        type: mlServiceType,
        cluster_names: values.cluster,
        secret_name: [],
      },
      model_deploy: {
        deployment_name: values.name,
        endpoint_type: values.endpoint_type,
        node_selector: {},
        models:
          values.deployment_type === "Single Model"
            ? [
                {
                  modelname: values.model_name,
                  modeltraffic: 100,
                  seldon_server_type:
                    mlServiceType === "Kubeflow"
                      ? values.seldon_server_type
                      : "",
                  image:
                    mlServiceType !== "Kubeflow"
                      ? values.image
                      : mlServiceType === "Kubeflow" &&
                        values.seldon_server_type === "CUSTOM_IMAGE"
                      ? values.image
                      : "",
                  modeluri: mpvList.find(
                    (mpv) => mpv.external_id === values.model_package_version
                  )?.resource_path,
                  k8s_cpu:
                    mlServiceType === "Kubeflow"
                      ? parseInt(values.cpu, 10)
                      : "",
                  k8s_gpu:
                    mlServiceType === "Kubeflow"
                      ? values.gpu === "None"
                        ? ""
                        : parseInt(values.gpu, 10)
                      : "",
                  k8s_gpu_type:
                    mlServiceType === "Kubeflow" ? values.gpu_vendor : "",
                  k8s_replicas:
                    mlServiceType === "Kubeflow"
                      ? parseInt(values.replicas, 10)
                      : "",
                  k8s_mem:
                    mlServiceType === "Kubeflow"
                      ? parseInt(values.mem, 10)
                      : "",
                  sagemaker_instance_type:
                    mlServiceType !== "Kubeflow"
                      ? values.sagemaker_instance_type
                      : "",
                  sagemaker_instance_count:
                    mlServiceType !== "Kubeflow"
                      ? values.sagemaker_instance_count
                      : "",
                  iam_role: mlServiceType !== "Kubeflow" ? values.role : "",
                  mpv_external_id: values.model_package_version,
                  mpv_version_number: mpvList.find(
                    (mpv) => mpv.external_id === values.model_package_version
                  )?.modelpackage_version_number,
                },
              ]
            : [
                {
                  modelname: values.model_a_name,
                  modeltraffic: values.model_a_traffic,
                  seldon_server_type:
                    mlServiceType === "Kubeflow"
                      ? values.seldon_server_type
                      : "",
                  image:
                    mlServiceType !== "Kubeflow"
                      ? values.image
                      : mlServiceType === "Kubeflow" &&
                        values.seldon_server_type === "CUSTOM_IMAGE"
                      ? values.image
                      : "",
                  modeluri: mpvList.find(
                    (mpv) => mpv.external_id === values.model_a_version
                  )?.resource_path,
                  k8s_cpu:
                    mlServiceType === "Kubeflow"
                      ? parseInt(values.cpu, 10)
                      : "",
                  k8s_gpu:
                    mlServiceType === "Kubeflow"
                      ? values.gpu === "None"
                        ? ""
                        : parseInt(values.gpu, 10)
                      : "",
                  k8s_gpu_type:
                    mlServiceType === "Kubeflow" ? values.gpu_vendor : "",
                  k8s_replicas:
                    mlServiceType === "Kubeflow"
                      ? parseInt(values.replicas, 10)
                      : "",
                  k8s_mem:
                    mlServiceType === "Kubeflow"
                      ? parseInt(values.mem, 10)
                      : "",
                  sagemaker_instance_type:
                    mlServiceType !== "Kubeflow"
                      ? values.sagemaker_instance_type
                      : "",
                  sagemaker_instance_count:
                    mlServiceType !== "Kubeflow"
                      ? values.sagemaker_instance_count
                      : "",
                  iam_role: mlServiceType !== "Kubeflow" ? values.role : "",
                  mpv_external_id: values.model_a_version,
                  mpv_version_number: mpvList.find(
                    (mpv) => mpv.external_id === values.model_a_version
                  )?.modelpackage_version_number,
                },
                {
                  modelname: values.model_b_name,
                  modeltraffic: values.model_b_traffic,
                  seldon_server_type:
                    mlServiceType === "Kubeflow"
                      ? values.seldon_server_type
                      : "",
                  image:
                    mlServiceType !== "Kubeflow"
                      ? values.image
                      : mlServiceType === "Kubeflow" &&
                        values.seldon_server_type === "CUSTOM_IMAGE"
                      ? values.image
                      : "",
                  modeluri: mpvList.find(
                    (mpv) => mpv.external_id === values.model_b_version
                  )?.resource_path,
                  k8s_cpu:
                    mlServiceType === "Kubeflow"
                      ? parseInt(values.cpu, 10)
                      : "",
                  k8s_gpu:
                    mlServiceType === "Kubeflow"
                      ? values.gpu === "None"
                        ? ""
                        : parseInt(values.gpu, 10)
                      : "",
                  k8s_gpu_type:
                    mlServiceType === "Kubeflow" ? values.gpu_vendor : "",
                  k8s_replicas:
                    mlServiceType === "Kubeflow"
                      ? parseInt(values.replicas, 10)
                      : "",
                  k8s_mem:
                    mlServiceType === "Kubeflow"
                      ? parseInt(values.mem, 10)
                      : "",
                  sagemaker_instance_type:
                    mlServiceType !== "Kubeflow"
                      ? values.sagemaker_instance_type
                      : "",
                  sagemaker_instance_count:
                    mlServiceType !== "Kubeflow"
                      ? values.sagemaker_instance_count
                      : "",
                  iam_role: mlServiceType !== "Kubeflow" ? values.role : "",
                  mpv_external_id: values.model_b_version,
                  mpv_version_number: mpvList.find(
                    (mpv) => mpv.external_id === values.model_b_version
                  )?.modelpackage_version_number,
                },
              ],
      },
    };

    const params = {
      name: values.name,
      description: values.description || "",
      deploment_type: values.deployment_type,
      endpoint_url: `/taichi-prj-${project_id}/${values.name}/`, //
      input_payload: values.tester_sample_payload || {},
      resource_config,
      phase: values.phase,
    };
    if (editDeployment) {
      return dispatch(
        editMlServiceDeployment({
          id: versionDescribe?.id,
          params,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then(() => {
          onClose();
          resetForm();
          enqueueSnackbar("Edit Success", { variant: "success" });
        });
    } else {
      return dispatch(
        addMlServiceDeployment({
          params: {
            ...params,
            mlservice_id: isCreate ? mlServiceRes?.id : runtimeId,
            is_active: 1,
            status: "wait_start",
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then(() => {
          onClose();
          resetForm();
          enqueueSnackbar("Create Success", { variant: "success" });
        });
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      try {
        // input_payload
        let payload;
        if (
          values.tester_sample_payload &&
          values.tester_sample_payload !== ""
        ) {
          payload = JSON.parse(values.tester_sample_payload);
          if (
            typeof payload !== "object" ||
            Array.isArray(payload) ||
            isEmpty(payload)
          ) {
            throw new Error("Payload should input a JSON string");
          }
        }
        await handleSubmitDeployment({
          ...values,
          tester_sample_payload: payload,
        });
        actions.setSubmitting(false);
      } catch (err) {
        const error = err as Error;
        console.log("error", error.message);
        if (error.message.includes("JSON")) {
          enqueueSnackbar(
            "Please enter the key:value format for the Tester Sample Payload field",
            { variant: "warning" }
          );
        }
        actions.setSubmitting(false);
      }
    },
  });

  const { errors, touched, values, resetForm, setFieldValue } = formik;

  const resourceHelper = (type) => {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body2">
          {`${resourceQuota[type]?.used || 0}/${
            resourceQuota[type]?.all || 0
          } used/hard`}
        </Typography>
        <Tooltip title="Refresh to query instant resource">
          <IconButton
            size="small"
            sx={{ height: "18px", width: "18px" }}
            onClick={() => getInitResourceQuota(project_id, setResourceQuota)}
          >
            <RefreshOutlinedIcon
              sx={{ color: (theme) => theme.palette.grey[500] }}
            />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  useEffect(() => {
    if (isOpen) {
      getInitResourceQuota(project_id, setResourceQuota);
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown
      onClose={() => {
        onClose();
        resetForm();
      }}
      PaperProps={{
        style: { width: "960px", maxWidth: "none", padding: "20px 40px" },
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>{`${
        editDeployment ? "Edit" : "Add"
      } Deployment - ${mlServiceType}`}</Typography>
      <FormikProvider value={formik}>
        <Form>
          <Scrollbar>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Field
                  as={TextField}
                  name="name"
                  size="small"
                  label="Deployment Name"
                  fullWidth
                  margin="dense"
                  disabled={editDeployment}
                  helperText={touched.name && errors.name ? errors.name : " "}
                  error={touched.name && Boolean(errors.name)}
                  FormHelperTextProps={{
                    sx: { minHeight: "18px", marginTop: "2px" },
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl
                  fullWidth
                  margin="dense"
                  size="small"
                  error={
                    touched.deployment_type && Boolean(errors.deployment_type)
                  }
                >
                  <InputLabel>Deployment Type</InputLabel>
                  <Field
                    as={Select}
                    name="deployment_type"
                    label="Deployment Type"
                    disabled={editDeployment}
                  >
                    <MenuItem value="Single Model">Single Model</MenuItem>
                    <MenuItem value="AB Testing">AB Testing</MenuItem>
                  </Field>
                  <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                    {touched.deployment_type && errors.deployment_type
                      ? errors.deployment_type
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <FormControl
                  fullWidth
                  margin="dense"
                  size="small"
                  error={touched.cluster && Boolean(errors.cluster)}
                >
                  <InputLabel>Kubernetes Cluster</InputLabel>
                  <Field as={Select} name="cluster" label="Kubernetes Cluster">
                    <MenuItem value="default-k8s">default-k8s</MenuItem>
                  </Field>
                  <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                    {touched.cluster && errors.cluster ? errors.cluster : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl
                  fullWidth
                  margin="dense"
                  size="small"
                  error={touched.phase && Boolean(errors.phase)}
                >
                  <InputLabel>Phase</InputLabel>
                  <Field
                    as={Select}
                    name="phase"
                    label="Phase"
                    disabled={editDeployment}
                  >
                    <MenuItem value="DEV">DEV</MenuItem>
                  </Field>
                  <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                    {touched.phase && errors.phase ? errors.phase : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Field
              as={TextField}
              multiline
              rows={4}
              name="description"
              label="Description (Optional)"
              size="small"
              fullWidth
              margin="dense"
            />
            <Typography
              variant="body2"
              className={classes.titleMark}
              sx={{ color: "text.secondary" }}
            >
              Select Model
            </Typography>
            {values.deployment_type !== "AB Testing" ? (
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Field
                    as={TextField}
                    name="model_name"
                    size="small"
                    label="Name"
                    fullWidth
                    margin="dense"
                    disabled={editDeployment}
                    helperText={
                      touched.model_name && errors.model_name
                        ? errors.model_name
                        : " "
                    }
                    error={touched.model_name && Boolean(errors.model_name)}
                    FormHelperTextProps={{
                      sx: { minHeight: "18px", marginTop: "2px" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl
                    fullWidth
                    margin="dense"
                    size="small"
                    error={
                      touched.model_package_version &&
                      Boolean(errors.model_package_version)
                    }
                  >
                    <InputLabel>Model Package Version</InputLabel>
                    <Field
                      as={Select}
                      name="model_package_version"
                      label="Model Package Version"
                    >
                      {mpvList.map((mpv) => (
                        <MenuItem value={mpv.external_id} key={mpv.external_id}>
                          {mpv.name}
                        </MenuItem>
                      ))}
                    </Field>
                    <FormHelperText
                      sx={{ minHeight: "18px", marginTop: "2px" }}
                    >
                      {touched.model_package_version &&
                      errors.model_package_version
                        ? errors.model_package_version
                        : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <Field
                      as={TextField}
                      name="model_a_name"
                      size="small"
                      label="Model A Name"
                      fullWidth
                      margin="dense"
                      helperText={
                        touched.model_a_name && errors.model_a_name
                          ? errors.model_a_name
                          : " "
                      }
                      error={
                        touched.model_a_name && Boolean(errors.model_a_name)
                      }
                      FormHelperTextProps={{
                        sx: { minHeight: "18px", marginTop: "2px" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl
                      fullWidth
                      margin="dense"
                      size="small"
                      error={
                        touched.model_a_version &&
                        Boolean(errors.model_a_version)
                      }
                    >
                      <InputLabel>Model A Package Version</InputLabel>
                      <Field
                        as={Select}
                        name="model_a_version"
                        label="Model A Package Version"
                      >
                        {mpvList.map((mpv) => (
                          <MenuItem
                            value={mpv.external_id}
                            key={mpv.external_id}
                          >
                            {mpv.name}
                          </MenuItem>
                        ))}
                      </Field>
                      <FormHelperText
                        sx={{ minHeight: "18px", marginTop: "2px" }}
                      >
                        {touched.model_a_version && errors.model_a_version
                          ? errors.model_a_version
                          : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Field
                  as={TextField}
                  name="model_a_traffic"
                  size="small"
                  label="Model A Traffic Ratio"
                  fullWidth
                  margin="dense"
                  helperText={
                    touched.model_a_traffic && errors.model_a_traffic
                      ? errors.model_a_traffic
                      : "0 - 100 number"
                  }
                  error={
                    touched.model_a_traffic && Boolean(errors.model_a_traffic)
                  }
                  FormHelperTextProps={{
                    sx: { minHeight: "18px", marginTop: "2px" },
                  }}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <Field
                      as={TextField}
                      name="model_b_name"
                      size="small"
                      label="Model B Name"
                      fullWidth
                      margin="dense"
                      helperText={
                        touched.model_b_name && errors.model_b_name
                          ? errors.model_b_name
                          : " "
                      }
                      error={
                        touched.model_b_name && Boolean(errors.model_b_name)
                      }
                      FormHelperTextProps={{
                        sx: { minHeight: "18px", marginTop: "2px" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl
                      fullWidth
                      margin="dense"
                      size="small"
                      error={
                        touched.model_b_version &&
                        Boolean(errors.model_b_version)
                      }
                    >
                      <InputLabel>Model B Package Version</InputLabel>
                      <Field
                        as={Select}
                        name="model_b_version"
                        label="Model B Package Version"
                      >
                        {mpvList.map((mpv) => (
                          <MenuItem
                            value={mpv.external_id}
                            key={mpv.external_id}
                          >
                            {mpv.name}
                          </MenuItem>
                        ))}
                      </Field>
                      <FormHelperText
                        sx={{ minHeight: "18px", marginTop: "2px" }}
                      >
                        {touched.model_b_version && errors.model_b_version
                          ? errors.model_b_version
                          : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Field
                  as={TextField}
                  name="model_b_traffic"
                  size="small"
                  label="Model B Traffic Ratio"
                  fullWidth
                  margin="dense"
                  helperText={
                    touched.model_b_traffic && errors.model_b_traffic
                      ? errors.model_b_traffic
                      : "0 - 100 number"
                  }
                  error={
                    touched.model_b_traffic && Boolean(errors.model_b_traffic)
                  }
                  FormHelperTextProps={{
                    sx: { minHeight: "18px", marginTop: "2px" },
                  }}
                />
              </>
            )}

            {mlServiceType === "SageMaker" && (
              <>
                <Typography
                  variant="body2"
                  className={classes.titleMark}
                  sx={{ color: "text.secondary" }}
                >
                  Config
                </Typography>
                <Field
                  as={TextField}
                  name="image"
                  size="small"
                  label="Image"
                  fullWidth
                  margin="dense"
                  disabled={editDeployment}
                  helperText={
                    touched.image && errors.image ? errors.image : " "
                  }
                  error={touched.image && Boolean(errors.image)}
                  FormHelperTextProps={{
                    sx: { minHeight: "18px", marginTop: "2px" },
                  }}
                />
                <Field
                  as={TextField}
                  name="role"
                  size="small"
                  label="IAM Role"
                  fullWidth
                  margin="dense"
                  helperText={touched.role && errors.role ? errors.role : " "}
                  error={touched.role && Boolean(errors.role)}
                  FormHelperTextProps={{
                    sx: { minHeight: "18px", marginTop: "2px" },
                  }}
                />
                <Field
                  as={TextField}
                  name="sagemaker_instance_type"
                  size="small"
                  label="Instance Type"
                  fullWidth
                  margin="dense"
                  helperText={
                    touched.sagemaker_instance_type &&
                    errors.sagemaker_instance_type
                      ? errors.sagemaker_instance_type
                      : " "
                  }
                  error={
                    touched.sagemaker_instance_type &&
                    Boolean(errors.sagemaker_instance_type)
                  }
                  FormHelperTextProps={{
                    sx: { minHeight: "18px", marginTop: "2px" },
                  }}
                />
                <Field
                  as={TextField}
                  name="sagemaker_instance_count"
                  size="small"
                  label="Instance Count"
                  fullWidth
                  margin="dense"
                  helperText={
                    touched.sagemaker_instance_count &&
                    errors.sagemaker_instance_count
                      ? errors.sagemaker_instance_count
                      : " "
                  }
                  error={
                    touched.sagemaker_instance_count &&
                    Boolean(errors.sagemaker_instance_count)
                  }
                  FormHelperTextProps={{
                    sx: { minHeight: "18px", marginTop: "2px" },
                  }}
                />
              </>
            )}
            {mlServiceType === "Kubeflow" && (
              <>
                <Typography
                  variant="body2"
                  className={classes.titleMark}
                  sx={{ color: "text.secondary" }}
                >
                  Config
                </Typography>
                <FormControl
                  fullWidth
                  margin="dense"
                  size="small"
                  error={
                    touched.seldon_server_type &&
                    Boolean(errors.seldon_server_type)
                  }
                >
                  <InputLabel>Seldon Server Type</InputLabel>
                  <Field
                    as={Select}
                    name="seldon_server_type"
                    label="Seldon Server Type"
                    disabled={editDeployment}
                  >
                    <MenuItem value="XGBOOST_SERVER">XGBOOST_SERVER</MenuItem>
                    <MenuItem value="TRITON_SERVER">TRITON_SERVER</MenuItem>
                    <MenuItem value="SKLEARN_SERVER">SKLEARN_SERVER</MenuItem>
                    <MenuItem value="TENSORFLOW_SERVER">
                      TENSORFLOW_SERVER
                    </MenuItem>
                    <MenuItem value="CUSTOM_IMAGE">CUSTOM_IMAGE</MenuItem>
                  </Field>
                  <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                    {touched.seldon_server_type && errors.seldon_server_type
                      ? errors.seldon_server_type
                      : ""}
                  </FormHelperText>
                </FormControl>
                {values.seldon_server_type === "CUSTOM_IMAGE" && (
                  <Field
                    as={TextField}
                    name="image"
                    size="small"
                    label="Image"
                    fullWidth
                    margin="dense"
                    disabled={editDeployment}
                    helperText={
                      touched.image && errors.image ? errors.image : " "
                    }
                    error={touched.image && Boolean(errors.image)}
                    FormHelperTextProps={{
                      sx: { minHeight: "18px", marginTop: "2px" },
                    }}
                  />
                )}
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <FormControl
                      fullWidth
                      margin="dense"
                      size="small"
                      error={touched.gpu_vendor && Boolean(errors.gpu_vendor)}
                    >
                      <InputLabel>GPU Vendor</InputLabel>
                      <Field as={Select} name="gpu_vendor" label="GPU Vendor">
                        <MenuItem value="nvidia" key="nvidia">
                          NVIDIA
                        </MenuItem>
                      </Field>
                      <FormHelperText
                        sx={{ minHeight: "18px", marginTop: "2px" }}
                      >
                        {touched.gpu_vendor && errors.gpu_vendor
                          ? errors.gpu_vendor
                          : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl
                      fullWidth
                      margin="dense"
                      size="small"
                      error={touched.gpu && Boolean(errors.gpu)}
                    >
                      <InputLabel>GPU Number</InputLabel>
                      <Field as={Select} name="gpu" label="GPU Number">
                        <MenuItem value="None" key="None">
                          None
                        </MenuItem>
                        <MenuItem value="1" key="1">
                          1
                        </MenuItem>
                        <MenuItem value="2" key="2">
                          2
                        </MenuItem>
                        <MenuItem value="4" key="4">
                          4
                        </MenuItem>
                        <MenuItem value="5" key="5">
                          5
                        </MenuItem>
                        <MenuItem value="8" key="8">
                          8
                        </MenuItem>
                      </Field>
                      <FormHelperText
                        sx={{ minHeight: "18px", marginTop: "2px" }}
                      >
                        {touched.gpu && errors.gpu
                          ? errors.gpu
                          : resourceHelper("gpu")}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <MlNumberInputField
                      name="replicas"
                      label="Replicas"
                      formik={formik}
                      isDouble
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MlNumberInputField
                      name="cpu"
                      label="CPU"
                      formik={formik}
                      isDouble
                      helperText={resourceHelper("cpu")}
                    />
                  </Grid>
                </Grid>
                <MlNumberInputField
                  name="mem"
                  label="Memory"
                  formik={formik}
                  isDouble
                  helperText={resourceHelper("memory")}
                />
              </>
            )}
            <Field
              as={TextField}
              multiline
              rows={4}
              name="tester_sample_payload"
              size="small"
              label="Tester Sample Payload (Optional)"
              fullWidth
              margin="dense"
              placeholder="Please enter the key: value format"
            />
            <Stack
              direction="row"
              alignItems="center"
              sx={{ cursor: "pointer", width: 200 }}
              onClick={() => setOpen(!open)}
            >
              <ExpandMoreOutlinedIcon
                sx={{
                  transform: open ? "rotate(0)" : "rotate(-90deg)",
                  transition: "transform 0.5s",
                }}
              />
              <Typography variant="subtitle1" paddingY={2}>
                Advanced Settings
              </Typography>
            </Stack>
            <Collapse in={open}>
              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={touched.billing && Boolean(errors.billing)}
              >
                <InputLabel>Billing</InputLabel>
                <Field as={Select} name="billing" label="Billing">
                  <MenuItem value="shared">shared</MenuItem>
                  <MenuItem value="elastic">elastic</MenuItem>
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {touched.billing && errors.billing ? errors.billing : ""}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={touched.provider && Boolean(errors.provider)}
              >
                <InputLabel>Provider</InputLabel>
                <Field as={Select} name="provider" label="Provider">
                  <MenuItem value="aws">aws</MenuItem>
                  <MenuItem value="on-prem">on-prem</MenuItem>
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {touched.provider && errors.provider ? errors.provider : ""}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={touched.region && Boolean(errors.region)}
              >
                <InputLabel>Region</InputLabel>
                <Field as={Select} name="region" label="Region">
                  {values.provider !== "aws" && (
                    <MenuItem value="shanghai">shanghai</MenuItem>
                  )}
                  <MenuItem value="beijing">beijing</MenuItem>
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {touched.region && errors.region ? errors.region : ""}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={touched.node_type && Boolean(errors.node_type)}
              >
                <InputLabel>Node Type</InputLabel>
                <Field as={Select} name="node_type" label="Node Type">
                  <MenuItem value="gpu">gpu</MenuItem>
                  <MenuItem value="cpu">cpu</MenuItem>
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {touched.node_type && errors.node_type
                    ? errors.node_type
                    : ""}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={
                  touched.node_linux_arch && Boolean(errors.node_linux_arch)
                }
              >
                <InputLabel>Node Linux Arch</InputLabel>
                <Field
                  as={Select}
                  name="node_linux_arch"
                  label="Node Linux Arch"
                >
                  <MenuItem value="x86">x86</MenuItem>
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {touched.node_linux_arch && errors.node_linux_arch
                    ? errors.node_linux_arch
                    : ""}
                </FormHelperText>
              </FormControl>
              {mlServiceType === "Kubeflow" && (
                <FormControl
                  fullWidth
                  margin="dense"
                  size="small"
                  error={touched.endpoint_type && Boolean(errors.endpoint_type)}
                >
                  <InputLabel>Endpoint Type</InputLabel>
                  <Field as={Select} name="endpoint_type" label="Endpoint Type">
                    <MenuItem value="REST">REST</MenuItem>
                    <MenuItem value="GPRC">GPRC</MenuItem>
                  </Field>
                  <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                    {touched.endpoint_type && errors.endpoint_type
                      ? errors.endpoint_type
                      : ""}
                  </FormHelperText>
                </FormControl>
              )}
            </Collapse>

            <Stack
              spacing={2}
              direction="row"
              justifyContent="center"
              sx={{ my: 4 }}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{ width: "200px", color: "background.paper" }}
              >
                Save
              </Button>
              <Button
                type="button"
                color="inherit"
                variant="outlined"
                onClick={() => {
                  onClose();
                  resetForm();
                }}
                sx={{ width: "200px", color: "text.secondary" }}
              >
                Cancel
              </Button>
            </Stack>
          </Scrollbar>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default DeploymentCreateFormDialog;

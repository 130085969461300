import React, { useState } from "react";
import { Box, Stepper, Step, StepLabel } from "@mui/material";
import BasicStep from "./BasicStep";
import StorageStep from "./StorageStep";
import SynchronizationStep from "./SynchronizationStep";

const FeatureSetCreateOrEditForm: React.FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [basicInfo, setBasicInfo] = useState<Record<string, any>>({});
  const [tags, setTags] = useState<any[]>([]);
  const [storageInfo, setStorageInfo] = useState<Record<string, any>>({});
  const [featuresetRes, setFeaturesetRes] = useState<Record<string, any>>({});

  const steps = [
    "Basic Information",
    "Storage Information",
    "Synchronization - Optional",
  ];

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {activeStep === 0 && (
        <BasicStep
          setActiveStep={setActiveStep}
          setBasicInfo={setBasicInfo}
          setTags={setTags}
        />
      )}
      {activeStep === 1 && (
        <StorageStep
          setActiveStep={setActiveStep}
          setFeaturesetRes={setFeaturesetRes}
          setStorageInfo={setStorageInfo}
          basicInfo={basicInfo}
          tags={tags}
        />
      )}
      {activeStep === 2 && (
        <SynchronizationStep
          basicInfo={basicInfo}
          storageInfo={storageInfo}
          featuresetRes={featuresetRes}
        />
      )}
    </>
  );
};

export default FeatureSetCreateOrEditForm;

const cn = {
  demo: {
    title: `Chinese`,
    introduction: `Lorem Ipsum 只是印刷和排版行业的虚拟文本。 自 1500 年代以来，Lorem Ipsum 一直是行业的标准虚拟文本，当时一位不知名的印刷商采用了一种类型的厨房并将其加扰以制作类型样本书。 它不仅经历了五个世纪，而且经历了电子排版的飞跃，基本保持不变。 它在 1960 年代随着包含 Lorem Ipsum 段落的 Letraset 表的发布而流行起来，最近还随着 Aldus PageMaker 等桌面出版软件（包括 Lorem Ipsum 的版本）而普及.`,
  },
  docs: {
    hi: `你好`,
    description: `需要帮忙？\n 请查看我们的文档.`,
    documentation: `文件`,
  },
  up: `以上`,
  key: `参数名称`,
  app: `应用程序`,
  user: `用户`,
  list: `列表`,
  time: `耗时`,
  size: `字节大小`,
  body: `响应体`,
  edit: `编辑`,
  shop: `商店`,
  blog: `博客`,
  post: `邮政`,
  mail: `电子邮件`,
  chat: `聊天`,
  name: `名称`,
  send: `发送`,
  cards: `牌`,
  posts: `帖子`,
  share: `分享`,
  apply: `确定`,
  enter: `进入`,
  query: `请求`,
  value: `值`,
  param: `参数`,
  model: `模型`,
  reset: `重置`,
  phase: `阶段`,
  cancel: `取消`,
  region: `区域`,
  status: `状态`,
  create: `创建`,
  kanban: `登机`,
  delete: `删除`,
  rating: `评分`,
  config: `配置`,
  source: `来源`,
  confirm: `确认`,
  no_data: `无数据`,
  up4Star: `4星及以上`,
  up3Star: `3星及以上`,
  up2Star: `2星及以上`,
  up1Star: `1星及以上`,
  sortby: `排序方式`,
  browse: `浏览`,
  filters: `过滤器`,
  refresh: `刷新`,
  general: `总体`,
  banking: `银行业`,
  booking: `预订`,
  profile: `基本信息`,
  account: `帐户`,
  product: `产品`,
  invoice: `发票`,
  details: `细节`,
  waiting: `等待`,
  compete: `开始对比`,
  disable: `取消授权`,
  disabled: `已取消`,
  optional: `可选`,
  duration: `用时`,
  rejected: `拒绝`,
  approved: `批准`,
  submitted: `提交`,
  endpoint: `部署端点`,
  download: `下载`,
  pipeline: `自动化管道`,
  calendar: `日历`,
  timestamp: `时间戳`,
  log_body: `内容`,
  log_list: `日志列表`,
  test_log: `测试日志`,
  create_at: `创建时间`,
  clear_all: `清除所有`,
  algorithm: `算法`,
  candidate: `候选模型`,
  operation: `操作`,
  deployment: `部署环境`,
  production: `生产模型`,
  remove_all: `移除所有`,
  prediction: `预测值`,
  predictions: `预测结果`,
  output_log: `输出日志`,
  search_log: `查找日志 ...`,
  search_model: `查找模型 ...`,
  search_pipeline: `查找管道 ...`,
  stop_service: `停止服务`,
  delete_service: `删除服务`,
  apply_changes: `应用更改`,
  repository: `代码库`,
  tutorial: `教程`,
  dashboard: `推荐服务仪表盘`,
  data_type: `数据类型`,
  analytics: `分析`,
  ecommerce: `电子商务`,
  model_name: `模型名称`,
  model_file: `模型文件`,
  management: `管理`,
  add_model: `添加模型`,
  edit_model: `编辑模型`,
  go_to_home: `返回主页面`,
  custom_data: `自定义数据`,
  history_data: `历史数据`,
  test_request: `测试请求`,
  test_response: `测试回复`,
  app_scenario: `应用场景`,
  input_params: `输入参数`,
  manual_input: `输入参数`,
  upload_file: `上传文件`,
  api_call: `API请求`,
  menu_level_1: `菜单级别 1`,
  menu_level_2: `菜单级别 2`,
  menu_level_3: `菜单级别 3`,
  add_candidate: `添加候选模型`,
  local_model: `本地模型`,
  official_image: `官方镜像`,
  custom_image: `自定义镜像`,
  env_var: `环境变量`,
  running_env: `运行环境`,
  select_model: `选择模型`,
  model_config: `模型配置`,
  model_registry: `模型注册表`,
  third_party_api: `第三方API`,
  edit_candidate: `编辑候选模型`,
  training_data: `训练数据`,
  item_disabled: `项目已禁用`,
  item_label: `项目标签`,
  item_caption: `项目标题`,
  service_type: `服务类型`,
  operation_time: `运行时长`,
  service_version: `服务版本`,
  description: `描述`,
  other_cases: `其他情况`,
  ml_app_store: `推荐服务应用商店`,
  feature_store: `数据特征库`,
  set_time_range: `选择时间区间`,
  start_time: `开始时间`,
  start_date: `开始日期`,
  end_time: `结束时间`,
  end_date: `结束日期`,
  config_query: `配置请求`,
  enter_request_url: `输入请求Url`,
  model_monitor: `模型监控`,
  model_lineage: `模型血缘`,
  initial_weight: `初始流量权重`,
  instance_type: `机器实例类型`,
  candidate_model_list: `候选模型列表`,
  setting_history: `设置历史`,
  reference_value: `对比值`,
  competitor_model: `候选模型`,
  drop_or_select_file: `选择文件`,
  thorough_your_machine: `本地文件`,
  drop_files_here_or_click: `将文件拖放至此或点击`,
  sorry_page_not_found: `抱歉，未找到页面`,
  deployment_environment: `部署环境`,
  only_admin_can_see_this_item: `只有管理员可以看到这个项目`,

  // Log in
  welcome_back: `欢迎回来`,
  sign_in: `登录南航智能推荐平台`,
  sign_out: `退出登录`,
  login: `登录`,
  email: `电子邮箱`,
  password: `密码`,
  forget_password: `忘记密码`,
  remember_me: `记住我`,
  use: `使用`,
  dont_have_account: `还没有账号`,
  get_started: `注册账号`,
  enter_details: `在下方填写信息`,

  // Serive Dashboard
  service: {
    ml_service: `推荐服务`,
    ml_service_metric: `服务指标汇总`,
    ml_service_list: `服务列表`,
    ml_service_name: `服务名称`,
    ml_service_running: `运行`,
    ml_service_running_days: `天`,
    ml_service_maintainer: `维护人员`,
    ml_service_launch_time: `上线时间`,
    ml_service_status: `服务状态`,
    ml_service_status_healthy: `正常`,
    ml_service_status_warning: `告警`,
    ml_service_status_fail: `失败`,
    ml_service_phase: `服务阶段`,
    ml_service_activity: `活跃度(过去7日)`,
    ml_service_last_prediction: `最近一次请求`,
    ml_service_priority: `重要性`,
    ml_service_priority_high: `高`,
    ml_service_priority_medium: `中`,
    ml_service_priority_low: `低`,
    ml_service_phase_test: `测试`,
    ml_service_phase_prod: `生产`,
    ml_service_phase_dev: `开发`,
    ml_service_metric_inferences: `总请求数`,
    ml_service_metric_deployments: `总服务数`,
    ml_service_metric_feedbacks: `总反馈数`,
    ml_service_metric_statuses: `服务状态汇总`,

    ml_service_destails: "服务细节",

    ml_service_tab_overview: "概览",
    ml_service_tab_overview_summary: "基本信息",
    ml_service_tab_overview_lineage: "血缘信息",
    ml_service_tab_overview_lineage_pipelines: "关联管道",
    ml_service_tab_overview_lineage_models: "关联模型",
    ml_service_tab_overview_lineage_datasets: "关联数据",
    ml_service_tab_overview_governance: "治理信息",
    ml_service_tab_overview_governance_history: "审批日志",

    ml_service_tab_logger: "日志",
    ml_service_tab_metrics: "模型指标",
    ml_service_tab_tester: "服务测试",
    // ml_service_tab_campaign: '模型竞赛',
    ml_service_tab_models: "模型设置",
    ml_service_tab_settings: "服务设置",
    ml_service_tab_powerbi: "Power BI",

    log_business_metric: `业务指标`,
  },

  // Pipeline
  ml_pipeline: {
    list: `自动化管道列表`,
    total: "管道总数",
    succeeded: "执行成功",
    executing: "执行中",
    failed: "执行失败",
    no_execution: "未执行",
    edit: "编辑管道",
    delete: "删除管道",
    duplicate: "复制管道",
    delete_pipeline: "删除管道",
    action_cannot_be_undone: "请注意此操作不可撤销.",
    confirm_deletion_click: "确认删除, 点击",
    table: {
      name: "管道名称",
      scenario: "应用场景",
      tags: "标签",
      creatAt: "创建时间",
      executions: "执行数量",
      lastExecution: "最近一次执行",
    },
    new_pipeline: "新建管道",
    pipeline_list: "管道列表",
    create_pipeline: "新建自动化管道",
    trigger: "触发器",
    no_triggers: "无触发器",
    pipeline_settings: "管道设定",
    pipeline_config: "基本信息",
    pipeline_steps: "管道组件",
    pipeline_params: "管道参数",
    pipeline_name: "管道名称",
    pipeline_arn: "管道资源标识",
    no_pipeline_steps: "无管道组件",
    no_pipeline_params: "无管道参数",
    create_pipeline_params_by: '点击 "+" 按钮创建管道参数',
    steps: {
      processing: "数据处理",
      registermodel: "模型注册",
      training: "模型训练",
      createmodel: "创建模型",
      types_of_pipeline_steps: "种类型的管道组件",
    },
  },

  role_arn: "管道执行角色",

  // ML Store
  ml_store: `推荐应用商店`,
  ml_store_list: `应用列表`,
};

export default cn;

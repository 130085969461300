import { TOKEN_FLAG } from "@/config";
import IframeHttp from "@/utils/project/iframeRequest";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";

interface IProps {
  src: string;
}

const MlIframe = React.memo(({ src }: IProps) => {
  const [cookieSet, setCookieSet] = useState<boolean>(false);
  const [curIframeHeight, setCurIframeHeight] = useState<number>(
    window.innerHeight
  );

  useEffect(() => {
    Cookies.set(TOKEN_FLAG, localStorage.getItem(TOKEN_FLAG)!, { path: "/" });
    setCookieSet(true);

    // ajax to get the http code is 401 (will redirect to login) or not
    IframeHttp.get(src);

    return () => {
      Cookies.remove(TOKEN_FLAG, { path: "/" });
      setCookieSet(false);
    };
  }, []);

  const handleResize = () => {
    setCurIframeHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {cookieSet ? (
        <iframe
          title="omniml-app-gallery-iframe"
          width="100%"
          height={curIframeHeight - 54 - 40}
          src={src}
          style={{ border: "none", display: "block" }}
        />
      ) : (
        <></>
      )}
    </>
  );
});

export default MlIframe;

// @mui
import { Box, Typography, Stack } from "@mui/material";
// assets
import UploadIllustration from "@/assets/project/unexpectedPages/illustration_upload";
// hooks
import useLocales from "@/hooks/project/useLocales";

// ----------------------------------------------------------------------

export default function BlockContent() {
  const { translate } = useLocales();
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: "column", md: "row" }}
      sx={{ width: 1, textAlign: { xs: "center", md: "left" } }}
    >
      <UploadIllustration sx={{ width: 180 }} />

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          Select files
        </Typography>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {translate("drop_files_here_or_click")}&nbsp;
          <Typography
            variant="body2"
            component="span"
            sx={{ color: "primary.main", textDecoration: "underline" }}
          >
            {translate("browse")}
          </Typography>
          &nbsp;{translate("thorough_your_machine")}
        </Typography>
      </Box>
    </Stack>
  );
}

import { useEffect, useRef, useState } from "react";
import { Graph, Cell, Shape } from "@antv/x6";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import {
  setCurrentEntity,
  updateNodeEntity,
  updateFixNextRender,
  addEdgeEntity,
  SchemaEdgeEntity,
  SchemaNodeEntity,
  setShowPropPanel,
  deleteEntities,
  SchemaEntityRelationship,
  SchemaEntityTag,
  Schema,
} from "../../slices/slice";
import { RootState } from "@/redux/rootReducer";
import useSettings from "@/hooks/project/useSettings";
// utils
import "./Graph/shape";
import {
  stepTypeColorMapper,
  kubeflowStepTypeColorMapper,
} from "../../../pipeline-construction/StepPopover";

let graph: Graph;

let lineColor = "#fff";

type GraphEditorProps = {
  containerName: string;
  onChangeWidth: Function;
  onChangeHeight: Function;
  isEdit: boolean;
};

const getGraph = () => {
  return graph;
};

const GraphEditor = ({
  containerName,
  onChangeWidth,
  onChangeHeight,
  isEdit,
}: GraphEditorProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { themeMode } = useSettings();

  // const [mousePoint, setMousePoint] = useState(new Point(0, 0));
  // const [showPorts, setShowPorts] = useState(false);
  const [addFirstStep, setAddFirstStep] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const {
    pipelineType,
    fixNextRender,
    showPropPanel,
    showConnectionProp,
    schema,
    selectedEntities,
  } = useSelector((state: RootState) => state.schema);

  const commonStepColorMap =
    pipelineType === "Kubeflow"
      ? kubeflowStepTypeColorMapper
      : stepTypeColorMapper;
  const isKubeflow = pipelineType === "Kubeflow";

  const setLineColor = (newColor: string) => {
    lineColor = newColor;
  };

  const update = (
    schema: Schema,
    selected: Array<string>,
    showConnectionProp: boolean
  ) => {
    // const graph = EditorGraph.graph;
    graph.getNodes().forEach((node: any) => {
      const nodeEntity = schema.pipelineSteps.find(
        (entity) => entity.id === node.id
      );
      if (!nodeEntity) {
        graph.removeCell(node);
      }
    });
    graph.getEdges().forEach((edge: any) => {
      const edgeEntity = schema.edgeEntities.find(
        (entity) => entity.id === edge.id
      );
      if (!edgeEntity) {
        graph.removeCell(edge);
      }
    });
    schema.pipelineSteps.forEach((entity) => {
      updateNodeEntityLocal(
        entity,
        schema.tags,
        selected.includes(entity.id),
        false
      );
    });

    schema.edgeEntities.forEach((entity) => {
      updateEdgeEntity(entity, schema.relationships, false, showConnectionProp);
    });
  };

  const initGraph = (theme: any) => {
    const nodes = graph.getNodes();
    if (nodes.length > 0) {
      nodes.forEach((node) => {
        node.attr("label/fill", `red`);
        node.attr("label/fill", `${theme.palette.text.primary}`);
      });
    }

    // The purpose is to mock "Clicking" action to rearange edges
    // const selectedCells = graph.getSelectedCells();
    // graph.getNodes().forEach((cell) => graph.select(cell));
    // if (selectedCells.length === 0) graph.cleanSelection();
    // else selectedCells.forEach((cell) => graph.select(cell));
  };

  const updateEdgeEntity = (
    edgeEntity: SchemaEdgeEntity,
    relationships: Array<SchemaEntityRelationship>,
    silent: boolean,
    showConnectionProp: boolean
  ) => {
    var edge = graph.getEdges().find((edge) => edge.id === edgeEntity.id);
    if (!edge) {
      const allNodes = graph.getNodes();
      const sourceNode = allNodes.find(
        (node) => node.id === edgeEntity.sourceEntityId
      );
      const targetNode = allNodes.find(
        (node) => node.id === edgeEntity.targetEntityId
      );
      if (sourceNode && targetNode) {
        const showCondition =
          edgeEntity.property &&
          (edgeEntity.property.startsWith("true") ||
            edgeEntity.property.startsWith("false"));
        const condition =
          showCondition && edgeEntity.property
            ? edgeEntity.property.split("&&")[0]
            : "";
        edge = graph.addEdge({
          id: edgeEntity.id,
          shape: "schema-edge",
          router: {
            // name: 'metro',
            // name: "er",
            name: "manhattan",
            args: {
              offset: "center",
              padding: {
                left: 50,
              },
              // direction: "L",
            },
          },
          anchor: "center",
          connector: {
            name: "rounded",
            args: { radius: 5 },
          },
          source: {
            cell: sourceNode,
            port: edgeEntity.sourcePortId,
          }, // 源节点和链接桩 ID
          target: {
            cell: targetNode,
            port: edgeEntity.targetPortId,
          }, // 目标节点 ID 和链接桩 ID
          labels: [
            {
              attrs: {
                text: {
                  text: showConnectionProp
                    ? edgeEntity.property
                      ? edgeEntity.property.split("&&")[0]
                      : ""
                    : condition,
                  fontSize: 14,
                },
                rect: {
                  refWidth: "126%",
                  refHeight: "140%",
                  refX: "-13%",
                  refY: "-20%",
                  rx: 0,
                  ry: 0,
                },
              },
            },
          ],
          // silent: silent,
        });
      }
    } else {
      const showCondition =
        edgeEntity.property &&
        (edgeEntity.property.startsWith("true") ||
          edgeEntity.property.startsWith("false"));

      const condition =
        showCondition && edgeEntity.property
          ? edgeEntity.property.split("&&")[0]
          : "";

      edge.setLabels({
        attrs: {
          text: {
            text: showConnectionProp
              ? edgeEntity.property
                ? edgeEntity.property.split("&&")[0]
                : ""
              : condition,
          },
          rect: {
            refWidth: showConnectionProp
              ? edgeEntity.property
                ? "126%"
                : "0"
              : showCondition
              ? "126%"
              : "0",
            refHeight: showConnectionProp
              ? edgeEntity.property
                ? "140%"
                : "0"
              : showCondition
              ? "140%"
              : "0",
            refX: showConnectionProp
              ? edgeEntity.property
                ? "-13%"
                : "0"
              : showCondition
              ? "-13%"
              : "0",
            refY: showConnectionProp
              ? edgeEntity.property
                ? "-20%"
                : "0"
              : showCondition
              ? "-20%"
              : "0",
            rx: 0,
            ry: 0,
          },
        },
      });
      edge.attr("rect/fill", "white");
    }
  };

  const updateNodeEntityLocal = (
    entity: SchemaNodeEntity,
    tags: Array<SchemaEntityTag>,
    selected: boolean,
    silent: boolean
  ) => {
    const existNode = graph.getNodes().find((node) => node.id === entity.id);

    // const tag = entity.tags.length > 0 ? tags.find((tag) => tag.id === entity.tags[0]) : null;
    // const entityTitle = tag != null ? tag.tag : `Step-${entity.id}`;
    const entityTitle = entity.name;
    const entityColor = commonStepColorMap[entity.stepType];
    // const entityTitle = entity.properties[0].title;
    const node =
      existNode != null
        ? existNode
        : graph.addNode(
            {
              id: entity.id,
              shape: "schema-node",
              x: entity.nodeX,
              y: entity.nodeY,
            },
            { silent: silent }
          );
    node.attr("label/text", entityTitle);
    node.attr("body/fill", entityColor);
    node.position(entity.nodeX, entity.nodeY);
    node.attr("body/stroke", entityColor);
    // EditorGraph.udpateEntitySelected(node, selected);
    if (selected) {
      node.attr("body/stroke", "#8cb4fc"); //#007aff
      node.attr("body/strokeWidth", 4);
    } else {
      node.attr("body/stroke", entityColor);
      node.attr("body/strokeWidth", 2);
    }
  };

  const udpateEntitySelected = (cell: Cell, selected: boolean) => {
    if (selected) {
      if (cell.isNode()) {
        cell.attr("body/stroke", "#8cb4fc"); //#007aff
        cell.attr("body/strokeWidth", 3);
      } else {
        cell.attr("line/stroke", "#43B4E3");
      }
    } else {
      if (cell.isNode()) {
        cell.attr("body/stroke", cell.getAttrs().body.fill);
        cell.attr("body/strokeWidth", 2);
      } else {
        cell.attr("line/stroke", lineColor);
      }
    }
  };
  // Init the graph. Just once.
  useEffect(() => {
    setLineColor(`${theme.palette.text.secondary}`);
    const container = containerRef.current as unknown as HTMLDivElement;
    if (graph) {
      graph.dispose();
    }

    if (isEdit) {
      graph = new Graph({
        container: container,
        interacting: false,
        autoResize: true,
        panning: true,
        grid: {
          visible: false,
        },
        selecting: {
          enabled: true,
        },
        mousewheel: {
          enabled: true,
          factor: 1.05,
          minScale: 0.3,
          maxScale: 3,
          zoomAtMousePosition: true,
          modifiers: ["ctrl", "meta"],
        },
      });
    } else {
      graph = new Graph({
        container: container,
        autoResize: true,
        grid: false,
        history: true,
        panning: true,
        selecting: {
          enabled: true,
          rubberband: true, // 框选
          multiple: false,
          strict: false,
          showNodeSelectionBox: false,
          showEdgeSelectionBox: false,
          modifiers: ["shift"],
        },
        keyboard: {
          enabled: true,
        },
        snapline: {
          enabled: true,
          sharp: true,
        },
        // scroller: {
        //   enabled: true,
        //   pageVisible: false,
        //   pageBreak: false,
        //   pannable: true,
        // },
        mousewheel: {
          enabled: true,
          factor: 1.05,
          minScale: 0.3,
          maxScale: 3,
          zoomAtMousePosition: true,
          modifiers: ["ctrl", "meta"],
        },
        connecting: {
          createEdge() {
            return new Shape.Edge({
              shape: "schema-edge",
              attrs: {
                line: {
                  stroke: lineColor,
                  lineWidth: 0.5,
                },
              },
              router: {
                name: "manhattan",
                // name: "er",
                args: {
                  offset: "center",
                  // padding: 20
                },
              },
              connector: {
                name: "rounded",
                args: { radius: 5 },
              },
              anchor: "center",
              // connectionPoint: 'anchor',
            });
          },
          allowBlank: false,
          allowMulti: true,
          allowLoop: false,
          allowNode: false,
          allowEdge: false,
          allowPort: true,
          highlight: true,
          snap: {
            radius: 50,
          },
        },
      });
    }

    graph.centerContent();
    initEvents();
    // container.addEventListener('mousemove', mouseMoveOnGraph);
    window.addEventListener("resize", resize);
    dispatch(updateFixNextRender(true));
    return () => {
      // container.removeEventListener('mousemove', mouseMoveOnGraph);
      window.removeEventListener("resize", resize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setShowPropPanel(false));
    resize();
    // eslint-disable-next-line
  }, [showPropPanel]);

  useEffect(() => {
    schema.edgeEntities.forEach((entity) => {
      updateEdgeEntity(entity, schema.relationships, false, showConnectionProp);
    });
    refreshEdges();
    // eslint-disable-next-line
  }, [showConnectionProp]);

  useEffect(() => {
    refreshNodesAndEdges();
    // eslint-disable-next-line
  }, [themeMode]);

  useEffect(() => {
    update(schema, selectedEntities, showConnectionProp);
    refreshNodesAndEdges();
    graph.getNodes().forEach((node) => updateNodeSize(node.id));
    if (graph.getNodes().length === 1 && !addFirstStep) {
      setAddFirstStep(true);
      graph.zoomToFit({ maxScale: 0.9 });
    }
    initGraph(theme);

    graph.centerContent();
  }, [schema]);

  useEffect(() => {
    if (fixNextRender) {
      graph.zoomToFit({ maxScale: 0.9 });
      dispatch(updateFixNextRender(false));
    }
    // eslint-disable-next-line
  }, [fixNextRender]);

  // const initGraph = (graph: Graph) => {};

  const initEvents = () => {
    // const graph = EditorGraph.graph;
    // graph.on('blank:dblclick', ({ x, y }) => {
    // dispatch(updateNodeEntity({ id: '', nodeX: x, nodeY: y }));
    // });

    graph.on("node:mouseenter", ({ node }) => {
      switchShowPorts(node.id, true);
    });
    graph.on("node:mouseleave", ({ node }) => {
      switchShowPorts(node.id, false);
    });
    graph.on("node:moved", ({ node }) => {
      const { x, y } = node.getPosition();
      dispatch(
        updateNodeEntity({
          id: node.id,
          nodeX: x,
          nodeY: y,
          stepType: "",
          isKubeflow,
        })
      );
    });
    graph.on("edge:change:target", ({ edge }) => {
      const sourcePortId = edge.getSourcePortId();
      const targetPortId = edge.getTargetPortId();
      if (sourcePortId && targetPortId) {
        dispatch(
          addEdgeEntity({
            entity: {
              id: edge.id,
              relationship: 0,
              properties: [],
              sourceEntityId: edge.getSourceCellId(),
              targetEntityId: edge.getTargetCellId(),
              sourcePortId: sourcePortId,
              targetPortId: targetPortId,
            },
          })
        );
      }
    });
    graph.on("cell:selected", ({ cell }) => {
      dispatch(setCurrentEntity(cell.id));
    });

    graph.on("cell:unselected", () => {
      dispatch(setCurrentEntity(null));
    });

    graph.on("selection:changed", ({ added, removed }) => {
      added.forEach((cell) => udpateEntitySelected(cell, true));
      removed.forEach((cell) => udpateEntitySelected(cell, false));
    });

    if (!isEdit) {
      graph.bindKey("backspace", () => {
        // const graph = EditorGraph.graph;
        const selectedCells = graph.getSelectedCells();
        graph.removeCells(selectedCells);
        dispatch(deleteEntities(selectedCells.map((cell) => cell.id)));
      });
      graph.bindKey("delete", () => {
        // const graph = EditorGraph.graph;
        const selectedCells = graph.getSelectedCells();
        graph.removeCells(selectedCells);
        dispatch(deleteEntities(selectedCells.map((cell) => cell.id)));
      });
    }
  };

  const updateNodeSize = (nodeId: string) => {
    const node = graph.getNodes().find((node) => node.id === nodeId);
    if (!node) return;
    const zoom = graph.zoom();
    const nodeElements = document.getElementsByClassName("x6-node");
    const length = nodeElements.length | 0;
    for (let i = 0; i < length; i += 1) {
      const nodeElement = nodeElements[i] as HTMLElement;
      if (nodeElement.dataset.cellId === nodeId) {
        const rectElement = nodeElements[i].children[1];
        if (rectElement) {
          const rect = rectElement.getBoundingClientRect();
          // const width = (rect.width + 16) / zoom;
          const width = Math.ceil(rect.width / zoom);
          // const height = Math.ceil((rect.height + 16) / zoom);
          // const finalWidth = width < 100 ? 100 : width;
          const finalWidth = width % 2 === 0 ? width + 26 : width + 25;
          // const finalHeight = height < 36 ? 36 : height;
          node.resize(finalWidth, node.getSize().height);
        }
      }
    }
    // refreshNodesAndEdges();
  };

  const refreshNodesAndEdges = () => {
    const nodes = graph.getNodes();
    if (nodes) {
      nodes.forEach((node) => {
        node.attr("label/fill", `${theme.palette.text.primary}`);
        node.attr("label/fontFamily", `${theme.typography.fontFamily}`);
      });
    }
    refreshEdges();
  };

  const refreshEdges = () => {
    const edges = graph.getEdges();
    if (edges) {
      edges.forEach((edge) => {
        edge.attr("rect/fill", `${theme.palette.background.paper}`);
        edge.attr("text/fill", `${theme.palette.text.secondary}`);
        edge.attr("text/fontFamily", `${theme.typography.fontFamily}`);
        edge.attr("line/stroke", `${theme.palette.text.secondary}`);
      });
    }
  };

  const resize = () => {
    // const graph = EditorGraph.graph;
    const chatWindow = document.querySelector(`.${containerName}`);

    let graphHeight, graphWidth;
    if (chatWindow) {
      graphWidth = onChangeWidth(chatWindow.clientWidth);
      graphHeight = onChangeHeight(chatWindow.clientHeight);
    } else {
      graphHeight = document.documentElement.clientHeight * 0.72 - 58;
      graphWidth = document.documentElement.clientWidth - 700;
    }
    graph.resize(graphWidth, graphHeight);
    // initGraph(theme);
    // refreshNodesAndEdges();
  };

  const switchShowPorts = (nodeId, show: boolean) => {
    const container = containerRef.current as unknown as HTMLDivElement;
    if (container) {
      const targetNode = container.querySelector(`[data-cell-id="${nodeId}"]`);
      const ports = targetNode?.querySelectorAll(
        ".x6-port-body"
      ) as NodeListOf<SVGAElement>;
      for (let i = 0, len = ports.length; i < len; i += 1) {
        ports[i].style.visibility = show ? "visible" : "hidden";
      }
    }
  };

  return (
    <div>
      <div>
        <div ref={containerRef} style={{ height: "100vh" }} />
      </div>
    </div>
  );
};

export default GraphEditor;
export { getGraph };

import { DeleteIcon } from "@/assets/project/icons";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Field, FormikProps } from "formik";
import { useState } from "react";
import { IKubeflowNotebookCreateFormValues } from "../KubeflowNotebook";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface IVolumeErrorsType {
  volumeType: string;
  type: string;
  name: string;
  cluster: string;
  giSize?: string;
  accessMode?: string;
  isReadOnly?: string;
  mountPath: string;
}

interface IVolumeTouchedType {
  volumeType: boolean;
  type: boolean;
  name: boolean;
  cluster: string;
  giSize?: boolean;
  accessMode?: boolean;
  isReadOnly?: boolean;
  mountPath: boolean;
}

interface IProps {
  index: number;
  removeFunc: VoidFunction;
  formik: FormikProps<IKubeflowNotebookCreateFormValues>;
}

const KubeflowNewVolumeForm = ({ index, removeFunc, formik }: IProps) => {
  const [expanded, setExpanded] = useState(true);

  const {
    config: { notebook },
  } = useSelector((state: RootState) => state.common);
  const { access_mode: accessModeList } = notebook;

  const getExpandedText = (volumeObj) => {
    const arr: string[] = [];

    if (volumeObj?.name) {
      arr.push(volumeObj?.name);
    }
    if (volumeObj?.type) {
      arr.push(volumeObj?.type);
    }
    if (volumeObj?.giSize) {
      arr.push(`${volumeObj?.giSize}GB`);
    }

    return arr.join(",");
  };

  const { values, errors, touched, setFieldValue, setFieldTouched } = formik;

  const volumeErrors = errors.volumes as Array<IVolumeErrorsType>;
  const volumeTouched = touched.volumes as Array<IVolumeTouchedType>;

  return (
    <Accordion
      key={index}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Typography>New Volume</Typography>
          {!expanded && (
            <Typography sx={{ color: "text.secondary" }}>
              {getExpandedText(values.volumes[index])}
            </Typography>
          )}
          <IconButton onClick={() => removeFunc()}>
            <SvgIconStyle
              src={DeleteIcon}
              sx={{
                width: 22,
                height: 22,
              }}
            />
          </IconButton>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl
          margin="dense"
          size="small"
          fullWidth
          variant="outlined"
          error={
            volumeTouched?.[index]?.type && Boolean(volumeErrors?.[index]?.type)
          }
        >
          <InputLabel>Type</InputLabel>
          <Field as={Select} name={`volumes.${index}.type`} label="Type">
            <MenuItem value="Empty Volume">Empty Volume</MenuItem>
          </Field>
          <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
            {(volumeTouched?.[index]?.type && volumeErrors?.[index]?.type) ||
              ""}
          </FormHelperText>
        </FormControl>
        <Field
          as={TextField}
          name={`volumes.${index}.name`}
          label="Name"
          size="small"
          fullWidth
          margin="dense"
          helperText={
            (volumeTouched?.[index]?.name && volumeErrors?.[index]?.name) || " "
          }
          error={
            volumeTouched?.[index]?.name && Boolean(volumeErrors?.[index]?.name)
          }
          FormHelperTextProps={{ sx: { minHeight: "18px", marginTop: "2px" } }}
        />
        <FormControl
          fullWidth
          margin="dense"
          size="small"
          error={touched.cluster && Boolean(errors.cluster)}
        >
          <InputLabel>Kubernetes Cluster</InputLabel>
          <Field as={Select} name="cluster" disabled label="Kubernetes Cluster">
            <MenuItem value="default-k8s" key="default-k8s">
              default-k8s
            </MenuItem>
          </Field>
          <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
            {touched.cluster && errors.cluster ? errors.cluster : ""}
          </FormHelperText>
        </FormControl>
        <Field
          as={TextField}
          name={`volumes.${index}.giSize`}
          label="Size In Gi"
          onChange={(e) => {
            let cleanedValue = e.target.value;

            if (cleanedValue.match(/^0+/)) {
              cleanedValue = cleanedValue.replace(/^0+/, "");
              if (cleanedValue === "") {
                cleanedValue = "0";
              }
            }

            cleanedValue = cleanedValue.replace(/[^0-9]/g, "");

            setFieldValue(`volumes.${index}.giSize`, cleanedValue);
            setFieldTouched(`volumes.${index}.giSize`, true);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="body2" sx={{ mr: 1 }}>
                  Gi
                </Typography>
                <Stack>
                  <IconButton
                    size="small"
                    sx={{ height: "18px", width: "18px" }}
                    onClick={() =>
                      setFieldValue(
                        `volumes.${index}.giSize`,
                        String(Number(values.volumes[index].giSize) + 1)
                      )
                    }
                  >
                    <KeyboardArrowUpIcon />
                  </IconButton>
                  <IconButton
                    disabled={Number(values.volumes[index].giSize) === 0}
                    size="small"
                    sx={{ height: "18px", width: "18px" }}
                    onClick={() =>
                      setFieldValue(
                        `volumes.${index}.giSize`,
                        String(Number(values.volumes[index].giSize) - 1)
                      )
                    }
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Stack>
              </InputAdornment>
            ),
          }}
          size="small"
          fullWidth
          margin="dense"
          helperText={
            (volumeTouched?.[index]?.giSize && volumeErrors?.[index]?.giSize) ||
            " "
          }
          error={
            volumeTouched?.[index]?.giSize &&
            Boolean(volumeErrors?.[index]?.giSize)
          }
          FormHelperTextProps={{ sx: { minHeight: "18px", marginTop: "2px" } }}
        />
        <FormControl
          margin="dense"
          size="small"
          fullWidth
          variant="outlined"
          error={
            volumeTouched?.[index]?.accessMode &&
            Boolean(volumeErrors?.[index]?.accessMode)
          }
        >
          <InputLabel>Access Mode</InputLabel>
          <Field
            as={Select}
            name={`volumes.${index}.accessMode`}
            label="Access Mode"
          >
            {accessModeList.map((item) => (
              <MenuItem value={item} key={item}>
                {item}
              </MenuItem>
            ))}
          </Field>
          <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
            {(volumeTouched?.[index]?.accessMode &&
              volumeErrors?.[index]?.accessMode) ||
              ""}
          </FormHelperText>
        </FormControl>
        <Field
          as={TextField}
          name={`volumes.${index}.mountPath`}
          label="Mount Path"
          // eslint-disable-next-line no-template-curly-in-string
          placeholder={"/home/jovyan/${name}"}
          size="small"
          fullWidth
          margin="dense"
          helperText={
            (volumeTouched?.[index]?.mountPath &&
              volumeErrors?.[index]?.mountPath) ||
            " "
          }
          error={
            volumeTouched?.[index]?.mountPath &&
            Boolean(volumeErrors?.[index]?.mountPath)
          }
          FormHelperTextProps={{ sx: { minHeight: "18px", marginTop: "2px" } }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default KubeflowNewVolumeForm;

import moment from "moment";
import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
// @types
import { useSelector, RootState } from "@/redux/store";
// @mui
import { styled } from "@mui/material/styles";
import { Card, CardHeader, CardProps } from "@mui/material";
// components
import { BaseOptionChart } from "@/components/project/charts";
import { fDuration } from "@/utils/project/formatTime";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 356;
const LEGEND_HEIGHT = 122;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(1),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    fontSize: "11px",
    alignContent: "center",
    position: "relative !important" as "relative",
    // borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT - 2}px) !important`,
  },
}));

interface Props extends CardProps {
  subheader?: string;
}

export default function DurationTimeBar({ ...other }: Props) {
  const exeSteps = useSelector(
    (state: RootState) => state.execution.executionGraph.executionSteps
  );

  const calDuration = (start: string, end: string) =>
    moment(end).diff(moment(start), "seconds");

  // const chartColors = exeSteps
  //   ? exeSteps.map((step) => stepTypeColorMapper[step.stepType])
  //   : [];

  const chartData = exeSteps
    ? exeSteps.map((step) => {
        if (step.startTime !== "None" && step.endTime !== "None") {
          const duration = calDuration(step.startTime, step.endTime);
          if (!isNaN(duration)) {
            return {
              label: step.name,
              value: calDuration(step.startTime, step.endTime),
            };
          }
        } else if (step.startTime !== "None" && step.endTime === "None") {
          const duration = calDuration(
            step.startTime,
            moment().format("YYYY-MM-DD HH:mm:ss.SSSZ")
          );
          if (!isNaN(duration)) {
            return {
              label: step.name,
              value: duration,
            };
          }
        }
        return {
          label: step.name,
          value: 0,
        };
      })
    : [];

  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = merge(BaseOptionChart(), {
    labels: chartLabels,
    stroke: { colors: "transparent" },
    legend: {
      floating: true,
      horizontalAlign: "center",
      fontSize: "12px",
      fontWeight: "400",
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName: number) => fDuration(seriesName),
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "90%",
          labels: {
            value: {
              formatter: (val: number) => fDuration(val),
            },
            total: {
              label: "Total time",
              formatter: (w: { globals: { seriesTotals: number[] } }) => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return fDuration(sum);
              },
            },
          },
        },
      },
    },
  });

  return (
    <Card {...other}>
      <CardHeader
        title={"Time Distribution"}
        sx={{ mt: -1.5, ml: -0.5, color: "text.secondary" }}
      />

      <ChartWrapperStyle dir="ltr">
        <ReactApexChart
          type="donut"
          series={chartSeries}
          options={chartOptions}
          height={250}
        />
      </ChartWrapperStyle>
    </Card>
  );
}

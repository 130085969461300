import { createSlice } from "@reduxjs/toolkit";
import { addThunkReducers } from "../utils";
import { ITempalteState } from "@/@types/project/model/template";
import {
  getNotebookTemplateDetail,
  getNotebookTemplateTableData,
  getPipelineTemplateDetail,
  getPipelineTemplateTableData,
  getProjectRoleDetail,
  getProjectRoleTableData,
} from "./thunks";

const initialState: ITempalteState = {
  notebookTemplateTableList: {
    loading: false,
    data: {},
    error: null,
  },
  notebookTemplateDetail: {
    loading: false,
    data: {},
    error: null,
  },
  pipelineTemplateTableList: {
    loading: false,
    data: {},
    error: null,
  },
  pipelineTemplateDetail: {
    loading: false,
    data: {},
    error: null,
  },
  projectRoleTableList: {
    loading: false,
    data: {},
    error: null,
  },
  projectRoleDetail: {
    loading: false,
    data: {},
    error: null,
  },
};

const slice = createSlice({
  name: "template",
  initialState,
  reducers: {
    setTemplateData(state, action) {
      Object.assign(state, action.payload);
    },
    resetTemplateData(state, action) {
      Object.assign(state[action.payload], { data: {} });
    },
  },
  extraReducers: (builder) => {
    addThunkReducers(builder, [
      {
        action: getNotebookTemplateTableData,
        name: "notebookTemplateTableList",
      },
      { action: getNotebookTemplateDetail, name: "notebookTemplateDetail" },
      {
        action: getPipelineTemplateTableData,
        name: "pipelineTemplateTableList",
      },
      { action: getPipelineTemplateDetail, name: "pipelineTemplateDetail" },
      { action: getProjectRoleTableData, name: "projectRoleTableList" },
      { action: getProjectRoleDetail, name: "projectRoleDetail" },
    ]);
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setTemplateData, resetTemplateData } = slice.actions;

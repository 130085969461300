/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { PATH_DASHBOARD } from "@/routes/paths";
import Page from "@/components/project/Page";
import { useLocation, useParams } from "react-router";
import MlCard from "@/components/project/mlComponents/MlCard";
import MlCardList from "@/components/project/mlComponents/MlCardList";
import ModelPackageDetailTable from "./components/modelPackageDetail/ModelPackageDetailTable";
import UploadDialog from "./components/modelPackageDetail/UploadDialog";
import RequestAccessDialog from "./components/modelPackageDetail/RequestAccessDialog";
import AddToProjectDialog from "./components/modelPackageDetail/AddToProjectDialog";
// import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Stack,
  Button,
  Chip,
  Typography,
  Popover,
  Grid,
  Box,
  IconButton,
} from "@mui/material";
import {
  getModelPackageVersionList,
  getProjectList,
  getModelPackageDescribe,
  getModelPackageTagList,
  getTagList,
  getConnectionById,
  getSynchronizationList,
  getSynchronizationJobList,
  getSynchronizationJobExecutionVersionList,
  getSynchronizationJobExecutionDataById,
} from "@/redux/project/dataAsset/modelRegistry/thunks";
import { dispatch, useSelector, RootState } from "@/redux/store";
import { useSnackbar } from "notistack";
import { isEmpty } from "lodash";

interface ICardListCell {
  subTitle: string;
  label: string | JSX.Element;
  loading?: boolean;
}

const ModelPackageDetail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { modelId = "" } = useParams();

  const [jobExecution, setJobExecution] = useState<Record<string, any>>({});
  const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false);
  const [openRequestAccessDialog, setOpenRequestAccessDialog] =
    useState<boolean>(false);
  const [openAddToProjectDialog, setOpenAddToProjectDialog] =
    useState<boolean>(false);
  const {
    modelPackageVersionDatas: {
      data: { items: versionList = [] },
      loading: versionLoading,
    },
    modelPackageDescribeDatas: { data: modelPackageDescribe, loading },
    connectionDatas: { data: connectionData, loading: connectionLoading },
    synchronizationJobDatas: {
      data: { items: jobList = [] },
      loading: syncJobLoading,
    },
    modelPackageTagDatas: {
      data: { items: modelPackageTagList = [] },
      loading: tagLoading,
    },
    tagDatas: {
      data: { items: tagList = [] },
    },
  } = useSelector((state: RootState) => state.modelRegistry);
  const { userDetail } = useSelector((state: RootState) => state.common);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElPop, setAnchorElPop] = useState<null | HTMLElement>(null);
  const openPopover = Boolean(anchorElPop);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPop(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorElPop(null);
  };

  const getLinks = () => {
    return [
      {
        name: "Data Asset",
      },
      {
        name: "Model Package",
        href: PATH_DASHBOARD.dataAsset.modelRegistry,
      },
      {
        name: modelPackageDescribe?.name || "",
      },
    ];
  };

  const formatSize = (size: string) => {
    if (size) {
      const value = parseFloat(size);
      if (value < 1024) {
        return `${value} KB`;
      }
      if (value < 1024 * 1024) {
        return `${(value / 1024).toFixed(2)} MB`;
      }
      if (value < 1024 * 1024 * 1024) {
        return `${(value / 1024 / 1024).toFixed(2)} GB`;
      }
    }
  };

  const getModelPacakgeDetails = useCallback(() => {
    const modelPackageInfoList: ICardListCell[] = [
      {
        subTitle: "Head Version Number",
        label: versionList[0]?.modelpackage_version_number || "-",
        loading: versionLoading,
      },
      {
        subTitle: "Type",
        label: modelPackageDescribe?.modelpackage_type || "-",
        loading,
      },
      {
        subTitle: "Resource Type",
        label: modelPackageDescribe?.resource_type || "-",
        loading,
      },
      {
        subTitle: "Resource Size",
        label: formatSize(versionList[0]?.SizeOfContents) || "-",
        loading: versionLoading,
      },
      {
        subTitle: "Resource Path",
        label: modelPackageDescribe?.resource_path || "-",
        loading,
      },
      {
        subTitle: "Resource Connection",
        label: getConnectionContent(),
        loading: connectionLoading,
      },
      {
        subTitle: "Synchronization Job Status",
        label: getSynchronizationStatus(),
        loading: syncJobLoading,
      },
      {
        subTitle: "Created By",
        label: modelPackageDescribe?.created_by || "-",
        loading,
      },
      {
        subTitle: "Created At",
        label: modelPackageDescribe?.created_at || "-",
        loading,
      },
      {
        subTitle: "Synchronized At",
        label: versionList[0]?.SynchronizedAt || "-",
        loading: versionLoading,
      },
      {
        subTitle: "Group",
        label: modelPackageDescribe?.delivered_to || "-",
        loading,
      },
      {
        subTitle: "Released By",
        label: versionList[0]?.ReleasedBy || "-",
        loading: versionLoading,
      },
      {
        subTitle: "Description",
        label: modelPackageDescribe?.description || "-",
        loading,
      },
      { subTitle: "Tags", label: getTagsContent(), loading: tagLoading },
      {
        subTitle: "Released At",
        label: versionList[0]?.ReleasedAt || "-",
        loading: versionLoading,
      },
    ];
    return modelPackageInfoList;
  }, [
    versionList,
    modelPackageDescribe,
    connectionData,
    openPopover,
    modelPackageTagList,
    jobList,
    open,
  ]);

  const getConnectionContent = () => {
    const connectionDetails = [
      { key: "Type", value: connectionData?.connection_type },
      { key: "Scheme", value: connectionData?.connection_scheme },
      { key: "Endpoint", value: connectionData?.connection_endpoint },
      { key: "Port", value: connectionData?.connection_port },
    ];
    return (
      <>
        <Typography
          variant="body2"
          aria-owns={openPopover ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          sx={{
            color: "text.secondary",
            wordBreak: "break-word",
            cursor: "pointer",
          }}
        >
          {connectionData?.name || "-"}
        </Typography>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={openPopover}
          anchorEl={anchorElPop}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Grid
            container
            columnSpacing={1}
            sx={{ width: 200, mr: 0.5, ml: 0.5 }}
            columns={6}
          >
            {connectionDetails.map(({ key, value }) => (
              <>
                <Grid item xs={3} lg={3}>
                  <Typography variant="subtitle2" paddingY={1}>
                    {`${key}:`}
                  </Typography>
                </Grid>
                <Grid item xs={3} lg={3}>
                  <Typography
                    variant="body2"
                    paddingY={1}
                    sx={{ wordBreak: "break-word" }}
                  >
                    {value}
                  </Typography>
                </Grid>
              </>
            ))}
          </Grid>
        </Popover>
      </>
    );
  };

  const getSynchronizationStatus = () => {
    return (
      <>
        <Typography
          variant="body2"
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          color="secondary"
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          sx={{ wordBreak: "break-word", cursor: "pointer" }}
        >
          {jobList[0]?.status || "-"}
        </Typography>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handleClose}
          disableRestoreFocus
        >
          <Grid
            container
            columnSpacing={1}
            sx={{ width: 200, mr: 0.5, ml: 0.5 }}
            columns={3}
          >
            <Grid item xs={3} lg={3}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" paddingY={1}>
                  Job Name:
                </Typography>
                <Typography
                  variant="body2"
                  paddingY={1}
                  sx={{ wordBreak: "break-word" }}
                >
                  {jobList[0]?.name || "-"}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={3} lg={3}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" paddingY={1}>
                  Job Status:
                </Typography>
                <Typography
                  variant="body2"
                  paddingY={1}
                  sx={{ wordBreak: "break-word" }}
                >
                  {jobList[0]?.status || "-"}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={3} lg={3}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" paddingY={1}>
                  Job Execution Name:
                </Typography>
                <Typography
                  variant="body2"
                  paddingY={1}
                  sx={{ wordBreak: "break-word" }}
                >
                  {jobExecution.name || "-"}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={3} lg={3}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" paddingY={1}>
                  Job Execution Status:
                </Typography>
                <Typography
                  variant="body2"
                  paddingY={1}
                  sx={{ wordBreak: "break-word" }}
                >
                  {jobExecution.status || "-"}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Popover>
      </>
    );
  };

  const getTagsContent = useCallback(() => {
    const tags: string[] = [];
    // eslint-disable-next-line array-callback-return
    modelPackageTagList.map((rel) => {
      const tagName = tagList.find((tag) => tag.id === rel.tag_id)?.name;
      if (tagName) tags.push(tagName);
    });
    return tags.length > 0 ? (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {tags.map((tag) => {
          return <Chip label={tag} size="small" key={tag} />;
        })}
      </Box>
    ) : (
      "-"
    );
  }, [modelPackageTagList]);

  useEffect(() => {
    // get mp detail
    dispatch(
      getModelPackageDescribe({
        id: modelId,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    // get version
    dispatch(
      getModelPackageVersionList({
        params: { page_num: 1, page_size: 10, modelpackage_id: modelId },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    // get tag and mp_tag
    dispatch(
      getModelPackageTagList({
        params: {
          page_num: 1,
          page_size: 9999,
          is_active: 1,
          modelpackage_id: modelId,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    dispatch(
      getTagList({
        params: { page_num: 1, page_size: 9999, is_active: 1 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  }, []);

  useEffect(() => {
    if (modelId) {
      // get sync
      dispatch(
        getSynchronizationList({
          params: {
            page_num: 1,
            page_size: 9999,
            modelpackage_id: modelId,
            synchronization_state: "RELEASED",
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((resp) => {
          // get sync job
          if (resp?.items?.length > 0) {
            dispatch(
              getSynchronizationJobList({
                params: {
                  page_num: 1,
                  page_size: 9999,
                  synchronization_id: resp.items[0]?.id,
                },
                alertCallback: enqueueSnackbar,
                pathname,
              })
            ).unwrap();
          }
        });
    }
  }, [modelId]);

  useEffect(() => {
    // get connection
    if (
      modelPackageDescribe?.resource_connection_id &&
      isEmpty(connectionData)
    ) {
      dispatch(
        getConnectionById({
          id: modelPackageDescribe?.resource_connection_id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
    }
  }, [modelPackageDescribe]);

  useEffect(() => {
    // get synchronization job execution feature set version
    if (versionList.length > 0) {
      dispatch(
        getSynchronizationJobExecutionVersionList({
          params: {
            page_num: 1,
            page_size: 9999,
            modelpackage_version_id: versionList[0].id,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((resp) => {
          if (
            resp?.items?.length > 0 &&
            resp.items[0].synchronization_job_execution_id
          ) {
            dispatch(
              getSynchronizationJobExecutionDataById({
                id: resp.items[0].synchronization_job_execution_id,
                alertCallback: enqueueSnackbar,
                pathname,
              })
            )
              .unwrap()
              .then((res) => {
                setJobExecution(res);
              });
          }
        });
    }
  }, [versionList]);

  const fetchProjectData = (params: {
    page_num: number;
    page_size: number;
  }) => {
    dispatch(
      getProjectList({
        params,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  };

  const handleAddToProject = () => {
    fetchProjectData({ page_num: 1, page_size: 10 });
    setOpenAddToProjectDialog(true);
  };

  const getExtraBtn = () => {
    return (
      <Stack direction="row" spacing={2} alignItems="center" sx={{ mr: 4 }}>
        {modelPackageDescribe.created_by === userDetail.user_id && (
          <IconButton onClick={() => setOpenUploadDialog(true)}>
            <FileUploadIcon sx={{ height: "16px", width: "16px" }} />
          </IconButton>
        )}
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setOpenRequestAccessDialog(true)}
        >
          Request Access
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleAddToProject}
        >
          Add To Project
        </Button>
      </Stack>
    );
  };

  return (
    <Page
      title={`${modelPackageDescribe?.name}| OmniML`}
      heading={modelPackageDescribe?.name || ""}
      links={getLinks()}
    >
      <MlCard title="Details" extraJsx={getExtraBtn()}>
        <MlCardList list={getModelPacakgeDetails()} />
      </MlCard>
      <ModelPackageDetailTable />
      {openUploadDialog && (
        <UploadDialog
          isOpen={openUploadDialog}
          onClose={() => setOpenUploadDialog(false)}
        />
      )}
      {openRequestAccessDialog && (
        <RequestAccessDialog
          isOpen={openRequestAccessDialog}
          onClose={() => {
            setOpenRequestAccessDialog(false);
          }}
        />
      )}
      {openAddToProjectDialog && (
        <AddToProjectDialog
          isOpen={openAddToProjectDialog}
          onClose={() => {
            setOpenAddToProjectDialog(false);
          }}
        />
      )}
    </Page>
  );
};

export default ModelPackageDetail;

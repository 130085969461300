import { useEffect, useRef, useState } from "react";
import { Graph, Point, Cell } from "@antv/x6";

import "./shape";

import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import {
  setCurrentEntity,
  updateFixNextRender,
  ExecutionGraphEdgeEntity,
  ExecutionGraphEntityRelationship,
  ExecutionGraph,
} from "../executionSlice";
import { RootState } from "@/redux/rootReducer";
import useSettings from "@/hooks/project/useSettings";
// utils
import {
  stepTypeColorMapper,
  kubeflowStepTypeColorMapper,
} from "../../../../../pipeline-construction/StepPopover";
import { ExecutionStep } from "@/@types/project/mlPipeline/SageMaker/pipeline";

let graph: Graph;

let lineColor = "#fff";

type GraphEditorProps = {
  containerName: string;
  onChangeWidth: Function;
  onChangeHeight: Function;
};

const prefix = process.env.NODE_ENV === "development" ? "" : "/ui";

const getStatusIcon = (status: string) => {
  if (status === "Succeeded") {
    return `${prefix}/static/icons/ic_status_succeeded.svg`;
  } else if (status === "Stopped") {
    return `${prefix}/static/icons/ic_status_stopped.svg`;
  } else if (status === "Executing") {
    return `${prefix}/static/icons/ic_status_executing.svg`;
  } else if (status === "Failed") {
    return `${prefix}/static/icons/ic_status_failed.svg`;
  } else {
    return `${prefix}/static/icons/ic_status_none.svg`;
  }
};

const setLineColor = (newColor: string) => {
  lineColor = newColor;
};

const getGraph = () => {
  return graph;
};

const initGraph = () => {
  graph.getNodes().forEach((cell) => graph.select(cell));
  graph.cleanSelection();
};

const ExecutionGraphViewer = ({
  containerName,
  onChangeWidth,
  onChangeHeight,
}: GraphEditorProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { themeMode } = useSettings();

  // const [mousePoint, setMousePoint] = useState(new Point(0, 0));
  const mousePoint = new Point(0, 0);
  const [showPorts, setShowPorts] = useState(false);
  const [addFirstStep, setAddFirstStep] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const { fixNextRender, executionGraph, selectedEntities } = useSelector(
    (state: RootState) => state.execution
  );

  const { pipelineType } = useSelector((state: RootState) => state.schema);
  const commonStepColorMap =
    pipelineType === "Kubeflow"
      ? kubeflowStepTypeColorMapper
      : stepTypeColorMapper;

  const update = (schema: ExecutionGraph, selected: Array<string>) => {
    // const graph = EditorGraph.graph;
    graph.getNodes().forEach((node: any) => {
      const nodeEntity = schema.executionSteps.find(
        (entity) => entity.id === node.id
      );
      if (!nodeEntity) {
        graph.removeCell(node);
      }
    });
    graph.getEdges().forEach((edge: any) => {
      const edgeEntity = schema.edgeEntities.find(
        (entity) => entity.id === edge.id
      );
      if (!edgeEntity) {
        graph.removeCell(edge);
      }
    });
    schema.executionSteps.forEach((entity) => {
      updateNodeEntityLocal(entity, selected.includes(entity.id), false);
    });

    schema.edgeEntities.forEach((entity) => {
      updateEdgeEntity(entity, schema.relationships, false);
    });
  };

  const updateEdgeEntity = (
    edgeEntity: ExecutionGraphEdgeEntity,
    relationships: Array<ExecutionGraphEntityRelationship>,
    silent: boolean
  ) => {
    // const graph = EditorGraph.graph;

    var edge = graph.getEdges().find((edge) => edge.id === edgeEntity.id);
    if (!edge) {
      const allNodes = graph.getNodes();
      const sourceNode = allNodes.find(
        (node) => node.id === edgeEntity.sourceEntityId
      );
      const targetNode = allNodes.find(
        (node) => node.id === edgeEntity.targetEntityId
      );
      if (sourceNode && targetNode) {
        edge = graph.addEdge(
          {
            id: edgeEntity.id,
            shape: "schema-edge",
            router: {
              name: "manhattan",
              args: {
                offset: "center",
              },
            },
            connector: {
              name: "rounded",
              args: { radius: 5 },
            },
            source: {
              cell: sourceNode,
              port: edgeEntity.sourcePortId,
            }, // 源节点和链接桩 ID
            target: {
              cell: targetNode,
              port: edgeEntity.targetPortId,
            }, // 目标节点 ID 和链接桩 ID
          },
          { silent: silent }
        );
      }
    }

    if (edge) {
      const relationship = relationships.find(
        (relationship) => relationship.id === edgeEntity.relationship
      );
      edge.attr("bg/fill", "#000");

      if (relationship?.value) {
        edge.setLabels({
          attrs: {
            text: { text: relationship ? relationship?.value : "true" },
          },
          position: {
            distance: 0.5,
            options: {
              keepGradient: true,
              ensureLegibility: true,
            },
          },
        });
      }
    }
  };

  const updateNodeEntityLocal = (
    entity: ExecutionStep,
    selected: boolean,
    silent: boolean
  ) => {
    const existNode = graph.getNodes().find((node) => node.id === entity.id);

    // const tag = entity.tags.length > 0 ? tags.find((tag) => tag.id === entity.tags[0]) : null;
    // const entityTitle = tag != null ? tag.tag : `Step-${entity.id}`;

    const entityTitle = entity.name;
    const entityColor = commonStepColorMap[entity.stepType];
    // const entityColor =
    //   entity.status === 'Succeeded' ? '#548e47' : stepTypeColorMapper[entity.stepType];
    // const entityTitle = entity.properties[0].title;
    const node =
      existNode != null
        ? existNode
        : graph.addNode(
            {
              shape: "custom-image",
              id: entity.id,
              x: entity.nodeX,
              y: entity.nodeY,
            },
            { silent: silent }
          );
    node.attr("label/text", entityTitle);
    node.attr("body/fill", entityColor);
    node.attr("image/xlink:href", getStatusIcon(entity.status));
    // node.attr('body/fill', entityColor);
    // node.attr('body/stroke', '#fff');
    // node.attr('body/strokeWidth', 2);
    node.position(entity.nodeX, entity.nodeY);
    if (selected) {
      node.attr("body/stroke", "#C9DAFB");
    } else {
      node.attr("body/stroke", entityColor);
    }
  };

  const udpateEntitySelected = (cell: Cell, selected: boolean) => {
    if (selected) {
      if (cell.isNode()) {
        cell.attr("body/stroke", "#8cb4fc"); //#007aff
        cell.attr("body/strokeWidth", 3);
      } else {
        cell.attr("line/stroke", "#43B4E3");
      }
    } else {
      if (cell.isNode()) {
        cell.attr("body/stroke", cell.getAttrs().body.fill);
        cell.attr("body/strokeWidth", 2);
      } else {
        cell.attr("line/stroke", lineColor);
      }
    }
  };

  // Init the graph. Just once.
  useEffect(() => {
    const container = containerRef.current as unknown as HTMLDivElement;
    if (graph) {
      graph.dispose();
    }
    graph = new Graph({
      container: container,
      interacting: false,
      autoResize: true,
      panning: true,
      grid: {
        visible: false,
      },
      selecting: {
        enabled: true,
        rubberband: false, // 框选
        multiple: false,
        strict: false,
        showNodeSelectionBox: false,
        showEdgeSelectionBox: false,
        modifiers: ["shift"],
      },
      mousewheel: {
        enabled: true,
        factor: 1.1,
        minScale: 0.3,
        maxScale: 3,
        zoomAtMousePosition: true,
        modifiers: ["ctrl", "meta"],
      },
    });
    graph.centerContent();
    initEvents();
    window.addEventListener("resize", resize);
    dispatch(updateFixNextRender(true));
    return () => {
      window.removeEventListener("resize", resize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resize();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refreshNodesAndEdges();
    // eslint-disable-next-line
  }, [themeMode]);

  useEffect(() => {
    update(executionGraph, selectedEntities);
    refreshShowPorts();
    refreshNodesAndEdges();
    graph.getNodes().forEach((node) => updateNodeSize(node.id));
    if (graph.getNodes().length === 1 && !addFirstStep) {
      setAddFirstStep(true);
      graph.zoomToFit({ maxScale: 0.9 });
    }
    // eslint-disable-next-line
  }, [executionGraph]);

  useEffect(() => {
    if (fixNextRender) {
      graph.zoomToFit({ maxScale: 0.9 });
      dispatch(updateFixNextRender(false));
    }
    // eslint-disable-next-line
  }, [fixNextRender]);

  const initEvents = () => {
    graph.on("cell:selected", ({ cell }) => {
      dispatch(setCurrentEntity(cell.id));
    });
    graph.on("cell:unselected", () => {
      dispatch(setCurrentEntity(null));
    });
    graph.on("selection:changed", ({ added, removed }) => {
      added.forEach((cell) => udpateEntitySelected(cell, true));
      removed.forEach((cell) => udpateEntitySelected(cell, false));
    });
  };

  const updateNodeSize = (nodeId: string) => {
    const node = graph.getNodes().find((node) => node.id === nodeId);
    if (!node) return;
    const zoom = graph.zoom();
    const nodeElements = document.getElementsByClassName("x6-node");
    const length = nodeElements.length | 0;
    for (let i = 0; i < length; i = (i + 1) | 0) {
      const nodeElement = nodeElements[i] as HTMLElement;
      if (nodeElement.dataset.cellId === nodeId) {
        const rectElement = nodeElements[i].children[2];
        if (rectElement) {
          const rect = rectElement.getBoundingClientRect();
          const width = rect.width / zoom + 20;
          // const height = (rect.height + 16) / zoom;
          const finalWidth = width % 2 === 0 ? width + 26 : width + 25;
          // const finalHeight = height < 36 ? 36 : height;
          node.resize(finalWidth, node.getSize().height);
        }
      }
    }
  };

  const refreshNodesAndEdges = () => {
    setLineColor(`${theme.palette.text.secondary}`);

    const nodes = graph.getNodes();
    if (nodes) {
      nodes.forEach((node) => {
        node.attr("label/fill", `${theme.palette.text.primary}`);
        node.attr("label/fontFamily", `${theme.typography.fontFamily}`);
      });
    }

    const edges = graph.getEdges();
    if (edges) {
      edges.forEach((edge) => {
        edge.attr("rect/fill", `${theme.palette.background.paper}`);
        edge.attr("text/fill", `${theme.palette.text.primary}`);
        edge.attr("text/fontFamily", `${theme.typography.fontFamily}`);
        edge.attr("line/stroke", `${theme.palette.text.secondary}`);
      });
    }
  };

  const resize = () => {
    // const graph = EditorGraph.graph;
    const chatWindow = document.querySelector(`.${containerName}`);

    let graphHeight, graphWidth;
    if (chatWindow) {
      graphWidth = onChangeWidth(chatWindow.clientWidth);
      graphHeight = onChangeHeight(chatWindow.clientHeight);
    } else {
      graphHeight = document.documentElement.clientHeight * 0.72 - 58;
      graphWidth = document.documentElement.clientWidth - 700;
    }

    graph.resize(graphWidth, graphHeight);
    initGraph();
    // refreshNodesAndEdges();
  };

  useEffect(() => {
    refreshShowPorts();
    // eslint-disable-next-line
  }, [mousePoint]);

  const refreshShowPorts = () => {
    // const graph = EditorGraph.graph;
    const hasNode = graph.getNodesFromPoint(mousePoint).length > 0;
    setShowPorts(hasNode);
  };

  useEffect(() => {
    switchShowPorts(showPorts);
  }, [showPorts]);

  const switchShowPorts = (show: boolean) => {
    const container = containerRef.current as unknown as HTMLDivElement;
    if (container) {
      const ports = container.querySelectorAll(
        ".x6-port-body"
      ) as NodeListOf<SVGAElement>;
      for (let i = 0, len = ports.length; i < len; i += 1) {
        // ports[i].style.visibility = show ? "visible" : "hidden";
        ports[i].style.visibility = "hidden";
      }
    }
  };

  return (
    <div>
      <div>
        <div ref={containerRef} />
      </div>
    </div>
  );
};

export default ExecutionGraphViewer;
export { getGraph };

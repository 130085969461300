// import * as Yup from "yup";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { useFormik, Form, FormikProvider } from "formik";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// redux
import { useDispatch } from "react-redux";
// material
import { Stack, Collapse } from "@mui/material";
// types
import {
  RegisterModelStep,
  ModelMetricStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-register-model";
import { updateModelMetrics } from "../../../graph/slices/slice";
//
import ModelMetricCard from "./ModelMetricCard";
import { CollapseButtonStyle } from "../../SettingSidebar";

// ----------------------------------------------------------------------

type Props = {
  currentStep: RegisterModelStep | null;
};

const metricList = [
  ["Bias", "bias"],
  ["Bias pre-training", "biasPreTraining"],
  ["Bias post-training", "biasPostTraining"],
  ["Explainability", "explainability"],
  ["Model statistics", "modelStatistics"],
  ["Model constraints", "modelConstraints"],
  ["Model data statistics", "modelDataStatistics"],
  ["Model data constraints", "modelDataConstraints"],
];

export default function ModelMetrics({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [isCollapse, setCollapse] = useState(true);

  const formik = useFormik<ModelMetricStruct>({
    initialValues: {
      //model_statistics
      modelStatistics: currentStep?.metrics?.modelStatistics || "",
      modelStatisticsUseParam:
        currentStep?.metrics?.modelStatisticsUseParam || false,
      modelStatisticsContentType:
        currentStep?.metrics?.modelStatisticsContentType || "None",
      //model_constraints
      modelConstraints: currentStep?.metrics?.modelConstraints || "",
      modelConstraintsUseParam:
        currentStep?.metrics?.modelConstraintsUseParam || false,
      modelConstraintsContentType:
        currentStep?.metrics?.modelConstraintsContentType || "None",
      //model_data_statistics
      modelDataStatistics: currentStep?.metrics?.modelDataStatistics || "",
      modelDataStatisticsUseParam:
        currentStep?.metrics?.modelDataStatisticsUseParam || false,
      modelDataStatisticsContentType:
        currentStep?.metrics?.modelDataStatisticsContentType || "None",
      //model_data_constraints
      modelDataConstraints: currentStep?.metrics?.modelDataConstraints || "",
      modelDataConstraintsUseParam:
        currentStep?.metrics?.modelDataConstraintsUseParam || false,
      modelDataConstraintsContentType:
        currentStep?.metrics?.modelDataConstraintsContentType || "None",
      //bias
      bias: currentStep?.metrics?.bias || "",
      biasUseParam: currentStep?.metrics?.biasUseParam || false,
      biasContentType: currentStep?.metrics?.biasContentType || "None",
      //explainability
      explainability: currentStep?.metrics?.explainability || "",
      explainabilityUseParam:
        currentStep?.metrics?.explainabilityUseParam || false,
      explainabilityContentType:
        currentStep?.metrics?.explainabilityContentType || "None",
      //bias_pre_training
      biasPreTraining: currentStep?.metrics?.biasPreTraining || "",
      biasPreTrainingUseParam:
        currentStep?.metrics?.biasPreTrainingUseParam || false,
      biasPreTrainingContentType:
        currentStep?.metrics?.biasPreTrainingContentType || "None",
      //bias_post_training
      biasPostTraining: currentStep?.metrics?.biasPostTraining || "",
      biasPostTrainingUseParam:
        currentStep?.metrics?.biasPostTrainingUseParam || false,
      biasPostTrainingContentType:
        currentStep?.metrics?.biasPostTrainingContentType || "None",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      try {
        dispatch(updateModelMetrics(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { handleSubmit } = formik;

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setCollapse((prev) => !prev)}
        endIcon={
          <Icon
            icon={isCollapse ? arrowIosForwardFill : arrowIosDownwardFill}
            width={16}
            height={16}
          />
        }
      >
        Model metrics
      </CollapseButtonStyle>

      <Collapse in={!isCollapse} sx={{ mx: 2, mb: !isCollapse ? 0.5 : 0 }}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onChange={handleSubmit}>
            <Stack spacing={0} sx={{ mt: 0.5 }}>
              {metricList.map(([label, field]) => (
                <ModelMetricCard
                  key={field}
                  label={label}
                  field={field}
                  fieldUseParam={`${field}UseParam`}
                  formik={formik}
                  contentType={`${field}ContentType`}
                />
              ))}
            </Stack>
          </Form>
        </FormikProvider>
      </Collapse>

      {/* {isCollapse && <Divider />} */}
    </>
  );
}

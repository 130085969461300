/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Typography,
  Stack,
  Button,
  Divider,
  Box,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import { extractKeys } from "@/utils/project/utils";
import Scrollbar from "@/components/project/Scrollbar";
import DeploymentCreateFormDialog from "./DeploymentCreateFormDialog";
import DeploymentDetailDrawer from "./DeploymentDetailDrawer";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "@/redux/store";
import {
  getServiceDeploymentList,
  getServiceDeploymentById,
} from "@/redux/project/mlService/mlRuntime/thunks";
import MlTableBodyContainer from "@/components/project/mlComponents/MlTableBodyContainer";
import DeploymentTableActionCol from "./DeploymentTableActionCol";
import Label, { LabelColor } from "@/components/project/Label";
import { useLocation } from "react-router";
import { isNil } from "lodash";

enum StatusEnum {
  Running = "success",
  Starting = "info",
  Closing = "info",
  Closed = "default",
  Stared = "primary",
}

interface IDeploymentsStepProps {
  setActiveStep: Function;
  mlServiceType: string;
  mlServiceRes: Record<string, any>;
  serviceInfo: Record<string, any>;
}

const DeploymentsStep = ({
  mlServiceType,
  setActiveStep,
  mlServiceRes,
  serviceInfo,
}: IDeploymentsStepProps) => {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [editDeployment, setEditDeployment] = useState<boolean>(false);
  const [openDeploymentDialog, setOpenDeploymentDialog] =
    useState<boolean>(false);
  const [openDeploymentDrawer, setOpenDeploymentDrawer] =
    useState<boolean>(false);
  const {
    mlServiceDeploymentList: { data, loading },
  } = useSelector((state: RootState) => state.mlRuntime);

  const columns = [
    { id: "name", label: "Deployment", minWidth: 150 },
    { id: "status", label: "Status", minWidth: 150 },
    {
      id: "model_version_number",
      label: "Model Version Number",
      minWidth: 100,
    },
    { id: "phase", label: "Phase", minWidth: 150 },
    { id: "created_at", label: "Added At", minWidth: 150 },
    { id: "", minWidth: 120 },
  ];

  useEffect(() => {
    // get deployment
    dispatch(
      getServiceDeploymentList({
        params: {
          page_num: 1,
          page_size: 9999,
          is_active: 1,
          mlservice_id: mlServiceRes?.id,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  }, [mlServiceRes]);

  const handleDeploymentDrawer = (record: Record<string, any>) => {
    dispatch(
      getServiceDeploymentById({
        id: record.id,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    setOpenDeploymentDrawer(true);
  };

  const handleEdit = (record: Record<string, any>) => {
    dispatch(
      getServiceDeploymentById({
        id: record.id,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    setEditDeployment(true);
    setOpenDeploymentDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDeploymentDialog(false);
    setEditDeployment(false);
    dispatch(
      getServiceDeploymentList({
        params: {
          page_num: 1,
          page_size: 9999,
          is_active: 1,
          mlservice_id: mlServiceRes?.id,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  };

  const renderCell = (row, column) => {
    switch (column.id) {
      case "name":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <CircleIcon
                color="secondary"
                sx={{ borderRadius: 4, width: 12, height: 12 }}
              />
              <Typography variant="subtitle2"> {row[column.id]} </Typography>
            </Stack>
          </TableCell>
        );
      case "status":
        const state = row[column.id];
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Label color={StatusEnum[state] as LabelColor}>{state}</Label>
            </Stack>
          </TableCell>
        );
      case "phase":
        const phase = row[column.id];
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Label color={StatusEnum[phase] as LabelColor}>{phase}</Label>
            </Stack>
          </TableCell>
        );
      case "model_version_number":
        const mpv = row.resource_config?.model_deploy?.models;
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Stack direction="row" spacing={0.5} alignItems="center">
              {mpv?.map((item) =>
                !isNil(item.mpv_version_number) ? (
                  <Label color="default"> {item.mpv_version_number}</Label>
                ) : (
                  "-"
                )
              )}
            </Stack>
          </TableCell>
        );
      case "":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width"), pr: 2 }}
          >
            <DeploymentTableActionCol
              row={row}
              mlServiceRes={mlServiceRes}
              handleDeploymentDrawer={handleDeploymentDrawer}
              handleEdit={handleEdit}
            />
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {row[column.id]}
            </Typography>
          </TableCell>
        );
    }
  };

  return (
    <>
      <Card sx={{ mt: 1.5, pt: 2 }}>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <MlTableBodyContainer loading={loading} data={data?.items || []}>
                <TableBody>
                  {data?.items?.map((row, index) => (
                    <TableRow key={index}>
                      {columns.map((column) => renderCell(row, column))}
                    </TableRow>
                  ))}
                </TableBody>
              </MlTableBodyContainer>
            </Table>
          </TableContainer>
        </Scrollbar>
        <Divider sx={{ borderStyle: "dashed", mt: 1 }} />
        <Box
          sx={{ position: "relative", width: "100%", ml: "45%", mt: 1, mb: 1 }}
        >
          <TableFooter
            component="div"
            children={
              <Button
                variant="text"
                color="inherit"
                startIcon={<AddOutlinedIcon />}
                onClick={() => {
                  setEditDeployment(false);
                  setOpenDeploymentDialog(true);
                }}
                sx={{ justifyContent: "center" }}
              >
                Deployment
              </Button>
            }
          />
        </Box>
      </Card>

      <Stack spacing={2} direction="row" justifyContent="center" sx={{ my: 4 }}>
        <Button
          type="button"
          color="inherit"
          variant="outlined"
          sx={{ width: "200px", color: "text.secondary" }}
          onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
        >
          Previous
        </Button>
        <Button
          type="submit"
          variant="contained"
          sx={{ width: "200px", color: "background.paper" }}
          onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
        >
          Next
        </Button>
      </Stack>

      {openDeploymentDialog && (
        <DeploymentCreateFormDialog
          isOpen={openDeploymentDialog}
          onClose={handleDialogClose}
          mlServiceType={mlServiceType}
          editDeployment={editDeployment}
          mlServiceRes={mlServiceRes}
        />
      )}

      {openDeploymentDrawer && (
        <DeploymentDetailDrawer
          isOpen={openDeploymentDrawer}
          onClose={() => {
            setOpenDeploymentDrawer(false);
          }}
          mlServiceType={mlServiceType}
          serviceInfo={serviceInfo}
        />
      )}
    </>
  );
};

export default DeploymentsStep;

import { useLocation } from "react-router";
import { useFormik, FormikProvider, Form, Field } from "formik";
import {
  Dialog,
  Typography,
  Stack,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
} from "@mui/material";
import * as Yup from "yup";
import Scrollbar from "@/components/project/Scrollbar";
import { dispatch, RootState, useSelector } from "@/redux/store";
import { addRelProjectModelPackage } from "@/redux/project/dataAsset/modelRegistry/thunks";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

interface IAddToProjectDialogProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

const RequestAccessDialog = ({ isOpen, onClose }: IAddToProjectDialogProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const {
    projectDatas: { data },
    modelPackageDescribeDatas: { data: modelPackageDescribe },
  } = useSelector((state: RootState) => state.modelRegistry);

  const initialValues = {
    project_id: "",
  };

  const validationSchema = Yup.object({
    project_id: Yup.string().required("My Project is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      try {
        const params = {
          project_id: values?.project_id,
          modelpackage_id: modelPackageDescribe?.id,
        };
        await dispatch(
          addRelProjectModelPackage({
            params,
            alertCallback: enqueueSnackbar,
            pathname,
          })
        );
        onClose();
        actions.setSubmitting(false);
        enqueueSnackbar("Add To Project Success", { variant: "success" });
      } catch (err) {
        actions.setSubmitting(false);
      }
    },
  });

  const { errors, touched, isSubmitting, resetForm } = formik;

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown
      onClose={() => {
        onClose();
        resetForm();
      }}
      PaperProps={{
        sx: {
          width: "960px",
          maxWidth: "none",
          padding: "20px 40px",
        },
      }}
    >
      <FormikProvider value={formik}>
        <Form>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Add To Project
          </Typography>
          <Box sx={{ marginLeft: "20px" }}>
            <Scrollbar sx={{ maxHeight: "300px" }}>
              <Stack sx={{ minWidth: "100%" }} spacing={1} direction="row">
                <Typography variant="body2" sx={{ mb: 2 }}>
                  {
                    <>
                      Model Package:{" "}
                      <strong>{modelPackageDescribe.name}</strong>
                    </>
                  }
                </Typography>
              </Stack>
              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={touched.project_id && Boolean(errors.project_id)}
              >
                <InputLabel>My Project</InputLabel>
                <Field as={Select} name="project_id" label="My Project">
                  {data?.items?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {touched.project_id && errors.project_id
                    ? errors.project_id
                    : ""}
                </FormHelperText>
              </FormControl>
            </Scrollbar>
            <Typography variant="subtitle2" sx={{ mt: 3 }}>
              Are you sure to add this Model Package?
            </Typography>
          </Box>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ my: 4 }}
          >
            <LoadingButton
              type="submit"
              loading={isSubmitting}
              variant="contained"
              sx={{ width: "200px", color: "background.paper" }}
            >
              Send
            </LoadingButton>
            <Button
              type="button"
              color="inherit"
              variant="outlined"
              onClick={() => {
                onClose();
                resetForm();
              }}
              sx={{ width: "200px", color: "text.secondary" }}
            >
              Cancel
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default RequestAccessDialog;

import { isEmpty } from "lodash";
import {
  constructFeatureSetDownloadStep,
  constructFeatureSetUploadStep,
  constructProcessingStep as constructKubeflowProcessingStep,
  constructRegisterModelStep as constructKubeflowRegisterModelStep,
  constructTrainingStep as constructKubeflowTrainingStep,
} from "../schema/Kubeflow/KubeflowStepConstructors";
import {
  constructClarifyCheckStep,
  constructConditionStep,
  constructFailStep,
  constructLambdaStep,
  constructModelStep,
  constructProcessingStep,
  constructRegisterModelStep,
  constructTrainingStep,
  constructTransformStep,
  constructTuningStep,
} from "../schema/StepConstructors";
import { SchemaState } from "./slice";

// Global
export const findEntityById = (state: SchemaState, entityId: string | null) => {
  if (entityId === null) return null;
  const nodeEntity = state.schema.pipelineSteps.find(
    (entity) => entity.id.toString() === entityId
  );
  if (nodeEntity) {
    return nodeEntity;
  } else {
    const edgeEntity = state.schema.edgeEntities.find(
      (entity) => entity.id === entityId
    );
    return edgeEntity ? edgeEntity : null;
  }
};

export const findEdgeById = (state: SchemaState, entityId: string | null) => {
  if (entityId === null) return null;
  const edgeEntity = state.schema.edgeEntities.find(
    (entity) => entity.id === entityId
  );
  return edgeEntity ? edgeEntity : null;
};

export const findParentEntityById = (
  state: SchemaState,
  entityId: string | null
) => {
  if (entityId === null) return [];

  state.parentEntities = [];
  state.schema.edgeEntities.forEach((entity) => {
    if (entity.targetEntityId === entityId) {
      state.parentEntities.push(entity.sourceEntityId);
    }
  });
};

export const findStepById = (state: SchemaState, stepId: string | null) => {
  if (stepId === null) return null;
  const step = state.schema.pipelineSteps.find(
    (step) => step.id.toString() === stepId
  );
  if (step) {
    return step;
  } else {
    // const edgeEntity = state.schema.edgeEntities.find((entity) => entity.id === entityId);
    // return edgeEntity ? edgeEntity : null;
    return null;
  }
};

export const updateNodePostion = (
  state: SchemaState,
  nodeX: number,
  nodeY: number
) => {
  let flag = false;
  for (let node of state.schema.pipelineSteps) {
    if (Math.abs(node.nodeX - nodeX) < 120) {
      nodeX += 120;
      flag = true;
    }
    // else if (Math.abs(node.nodeY - nodeY) < 50) {
    //   nodeY += 50;
    //   flag = true;
    // }
    if (flag) {
      break;
    }
  }
  if (flag) {
    [nodeX, nodeY] = updateNodePostion(state, nodeX, nodeY);
  }

  return [nodeX, nodeY];
};

// updateNodeEntity - generateNodeStepInfoWhenStepNew
export const generateNodeStepInfoWhenStepNew = (data) => {
  const { id, position, stepType, isKubeflow } = data;
  if (isKubeflow) {
    switch (stepType) {
      case "Processing":
        return constructKubeflowProcessingStep(id, position, stepType);
      case "FeatureSetUpload":
        return constructFeatureSetUploadStep(id, position, stepType);
      case "RegisterModel":
        return constructKubeflowRegisterModelStep(id, position, stepType);
      case "FeatureSetDownload":
        return constructFeatureSetDownloadStep(id, position, stepType);
      case "Training":
        return constructKubeflowTrainingStep(id, position, stepType);
      default:
        // empty to show Processing
        return constructKubeflowProcessingStep(id, position, stepType);
    }
  } else {
    switch (stepType) {
      case "Processing":
        return constructProcessingStep(id, position, stepType);
      case "Training":
        return constructTrainingStep(id, position, stepType);
      case "RegisterModel":
        return constructRegisterModelStep(id, position, stepType);
      case "Condition":
        return constructConditionStep(id, position, stepType);
      case "Tuning":
        return constructTuningStep(id, position, stepType);
      case "Model":
        return constructModelStep(id, position, stepType);
      case "Transform":
        return constructTransformStep(id, position, stepType);
      case "Fail":
        return constructFailStep(id, position, stepType);
      case "Lambda":
        return constructLambdaStep(id, position, stepType);
      case "ClarifyCheck":
        return constructClarifyCheckStep(id, position, stepType);
      default:
        return constructProcessingStep(id, position, stepType);
    }
  }
};

// kubeflow processing training job args inputs outputs
export const handleCrudKubeflowJobArgs = <StepType>(state, action) => {
  const step = findStepById(state, state.currentEntityId);
  const { handleType } = action.payload;
  const key = action.payload.key;

  const jobArgs = (step as StepType)[key];

  if (handleType === "create") {
    jobArgs.push(action.payload.data); // without id ,id is index
  } else if (handleType === "edit") {
    // change the default value by dataType
    const curJobArg = jobArgs[action.payload.id]; // id number/string

    if (!isEmpty(curJobArg)) {
      // { value: xxx } or { name: xxx } merge not replace
      Object.assign(curJobArg, action.payload.data);
    }
  } else {
    // delete
    const { id } = action.payload;
    if (jobArgs && id > -1) {
      jobArgs.splice(id, 1);
    }
  }
};

/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Field, useFormik, FormikProvider, FieldArray } from "formik";
import {
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Stack,
  Box,
  Autocomplete,
  Grid,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import * as Yup from "yup";
import MlCard from "@/components/project/mlComponents/MlCard";
import Scrollbar from "@/components/project/Scrollbar";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import KubeflowNewVolumeForm from "./Kubeflow/KubeflowNewVolumeForm";
import KubeflowExistingVolumeForm from "./Kubeflow/KubeflowExistingVolumeForm";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "@/redux/store";
import { useEffect, useState } from "react";
import { addTags, getTags } from "@/redux/project/administration/thunks";
import { useLocation, useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import {
  addNotebook,
  addNotebookTags,
  addRelNotebookVolumeData,
  getNotebookTableData,
  getNotebookVolumeTableData,
} from "@/redux/project/experiment/thunks";
import {
  getNotebookTemplateTableData,
  getNotebookTemplateVersionData,
} from "@/redux/project/template/thunks";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { PATH_DASHBOARD } from "@/routes/paths";
import { isEmpty } from "lodash";
import AdvancedSettings from "./Kubeflow/AdvanceSettings";
import MlNumberInputField from "@/components/project/mlComponents/MlNumberInputField";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import {
  getInitResourceQuota,
  queryRestResource,
  restResource,
} from "@/utils/project/resourceRequest";
import { getFeatureSetList } from "@/redux/project/dataAsset/featureStore/thunks";
import { getModelPackageList } from "@/redux/project/dataAsset/modelRegistry/thunks";

export interface IKubeflowNotebookCreateFormValues {
  notebookName: string;
  notebookType: string;
  cluster: string;
  image: string;
  tags: string[];
  notebookTemplate: string;
  miniCpu: string;
  miniMemory: string;
  gpuNum: string;
  gpuVendor: string;
  volumes: Array<{
    volumeType: "new" | "existing";
    type: string;
    name: string;
    giSize?: string;
    accessMode?: string;
    isReadOnly?: boolean;
    mountPath: string;
    isSyncFs?: boolean;
    isSyncMp?: boolean;
    fs_id?: string;
    fs_version_id?: string;
    fs_version_external_id?: string;
    mp_id?: string;
    mp_version_id?: string;
    mp_version_external_id?: string;
  }>;
  advancedSettings: {
    billing: string;
    provider: string;
    region: string;
    node_type: string;
    node_linux_arch: string;
  };
}

const initialValues: IKubeflowNotebookCreateFormValues = {
  notebookName: "",
  notebookType: "",
  cluster: "default-k8s",
  image: "",
  notebookTemplate: "",
  tags: [],
  miniCpu: "1",
  miniMemory: "2",
  gpuNum: "",
  gpuVendor: "",
  volumes: [],
  advancedSettings: {
    billing: "",
    provider: "",
    region: "",
    node_type: "",
    node_linux_arch: "",
  },
};

const KubeflwNotebook = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

  const {
    config: { notebook },
  } = useSelector((state: RootState) => state.common);
  const {
    notebook_type: typeList,
    image: imageListObj,
    GPU: { number: gpuNumList, vender: gpuVendorList },
  } = notebook;

  const {
    userDetail: { id, user_id, project_id },
  } = useSelector((state: RootState) => state.common);

  const {
    tags: { items: tagList },
  } = useSelector((state: RootState) => state.administration);

  const {
    notebookTemplateTableList: {
      data: { items: templateList },
    },
  } = useSelector((state: RootState) => state.template);

  const {
    notebookVolumeTableList: {
      data: { items: volumeList = [] },
    },
  } = useSelector((state: RootState) => state.experiment);
  const startedVolumeList = volumeList?.filter(
    (item) => item.status === "started"
  );

  const [images, setImages] = useState([]);

  const {
    notebookTableList: { data: allNbData },
  } = useSelector((state: RootState) => state.experiment);

  // resourceQuota
  const [resourceQuota, setResourceQuota] = useState<Record<string, any>>({});

  const validationSchema = Yup.object({
    notebookName: Yup.string()
      .matches(
        /^[a-zA-Z0-9][a-zA-Z0-9-]*$/,
        `Support alphabet、number and '-', can't start with '-'`
      )
      .required("Notebook Server Name is required")
      .max(64, "Notebook Name cannot be longer than 64 characters")
      .test("name-exist", "Notebook Server Name existed", (value) => {
        const isExisted =
          allNbData?.items?.findIndex((item) => item.name === value) > -1;
        return !isExisted;
      }),
    notebookType: Yup.string().required("Notebook Type is required"),
    cluster: Yup.string().required("Kubernetes Cluster is required"),
    image: Yup.string().required("Image is required"),
    notebookTemplate: Yup.string().required("Notebook Template is required"),
    // miniCpu: Yup.number().typeError('Minimum Cpu must be number type'),
    volumes: Yup.array().of(
      Yup.object().shape({
        volumeType: Yup.string(),
        name: Yup.string()
          .matches(
            /^[a-zA-Z0-9][a-zA-Z0-9-]*$/,
            `Support alphabet、number and '-', can't start with '-'`
          )
          .required("Name is required"),
        type: Yup.string().required("Type is required"),
        cluster: Yup.string().when("volumeType", {
          is: "new",
          then: Yup.string().required("KuberNetes Cluster is required"),
        }),
        giSize: Yup.number().when("volumeType", {
          is: "new",
          then: Yup.number()
            .typeError("Gi Size must be number type")
            .required("Gi Size is required"),
        }),
        accessMode: Yup.string().when("volumeType", {
          is: "new",
          then: Yup.string().required("Access Mode is required for new volume"),
        }),
        // isReadOnly: Yup.boolean(),
        mountPath: Yup.string().required("Mount Path is required"),
        fs_id: Yup.string().when(["volumeType", "isSyncFs"], {
          is: (volumeType, isSyncFs) => volumeType === "existing" && isSyncFs,
          then: Yup.string().required("Feature Set is required"),
        }),
        fs_version_id: Yup.string().when(["volumeType", "isSyncFs"], {
          is: (volumeType, isSyncFs) => volumeType === "existing" && isSyncFs,
          then: Yup.string().required("Feature Set Version is required"),
        }),
        mp_id: Yup.string().when(["volumeType", "isSyncMp"], {
          is: (volumeType, isSyncMp) => volumeType === "existing" && isSyncMp,
          then: Yup.string().required("Model Package is required"),
        }),
        mp_version_id: Yup.string().when(["volumeType", "isSyncMp"], {
          is: (volumeType, isSyncMp) => volumeType === "existing" && isSyncMp,
          then: Yup.string().required("Model Package Version is required"),
        }),
      })
    ),
  });

  const keyExist = (key, value) => {
    if (value) {
      return {
        [key]: value,
      };
    }
    return {};
  };

  const handleSubmit = async (values: IKubeflowNotebookCreateFormValues) => {
    const ephemeralVolume = values.volumes.find(
      (item) => item?.volumeType === "new"
    );

    // get the notebook_template_version_id
    const { items } = await dispatch(
      getNotebookTemplateVersionData({
        params: {
          notebook_template_id: values.notebookTemplate,
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    const mostRecent = items.reduce((prev, cur) => {
      return new Date(cur.updated_at) > new Date(prev.updated_at) ? cur : prev;
    });

    // get organization name
    // const org_name = project_list[project_id][1];
    // const org_name = project_list.find(item => item.id === project_id).organization_name;

    const config = {
      env: {
        type: "Kubernetes",
        cluster_names: values.cluster,
      },
      node_selector: {},
      notebook: {
        ...keyExist("minimum_cpu", values.miniCpu),
        ...keyExist("minimum_memory", values.miniMemory),
        ...keyExist("number_of_gpu", values.gpuNum),
        ...keyExist("gpu_vendor", values.gpuVendor),
        image: values.image,
        notebook_type: values.notebookType,

        // ephemeral volume (one) - new volume default to mount
        /* ...keyExist('ephemeral_volume_name', ephemeralVolume?.name),
        ...keyExist('ephemeral_volume_size', ephemeralVolume?.giSize),
        ...keyExist('ephemeral_volume_access_model', ephemeralVolume?.accessMode),
        ...keyExist('ephemeral_volume_mount_path', ephemeralVolume?.mountPath), */
        ephemeral_volume_name: `${new Date().getTime()}`,
        ephemeral_volume_size: "1",
        ephemeral_volume_access_model: "ReadWriteOnce",
        ephemeral_volume_mount_path: values?.notebookName
          ? `/home/jovyan/${values?.notebookName}/code`
          : "",

        exist_volumes: values.volumes
          .filter((volume) => volume.volumeType === "existing")
          .map((item) => ({
            name: volumeList.find((i) => i.id === item.name).name, // must be pvc name
            mount_path: item.mountPath,
            access_model: item.accessMode || "ReadWriteOnce",
            read_only: String(item.isReadOnly),

            ...(item.isSyncFs
              ? { fs_version_external_id: item.fs_version_external_id }
              : {}),
            ...(item.isSyncMp
              ? { mp_version_external_id: item.mp_version_external_id }
              : {}),
          })),
      },
    };

    let urlSuffix = "";
    if (values.notebookType === "JupyterLab") {
      urlSuffix = "lab/";
    } else if (values.notebookType === "VSCode") {
      urlSuffix = `?folder=/home/jovyan/${values.notebookName}`;
    }

    const addParams = {
      name: values.notebookName,
      resource_config: config,
      notebook_url: `/notebook/taichi-prj-${project_id}/${values.notebookName}/${urlSuffix}`,
      notebook_type: "Kubeflow",
      git_repo_url: `${values.notebookName}.git`,
      artifactory_url: `${values.notebookName}`,
      notebook_template_version_id: mostRecent?.id,
      is_active: 1,
      status: "wait_start",
    };
    console.log("addParams", addParams);

    // create notebook
    const { id } = await dispatch(
      addNotebook({
        params: addParams,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    // after notebook created
    // 1. get notebook_id to add tags
    if (!isEmpty(values.tags)) {
      await Promise.all(
        values.tags.map((item) =>
          dispatch(
            addNotebookTags({
              params: {
                notebook_id: id,
                tag_id: item,
              },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap()
        )
      );
    }
    // 2. get notebook_id to add rel_notebook_notebook_volume
    await Promise.all(
      values.volumes
        .filter((volume) => volume.volumeType === "existing")
        .map((item) =>
          dispatch(
            addRelNotebookVolumeData({
              params: {
                notebook_id: id,
                notebook_volume_id: item.name, // existing volume name is notebook_volume_id
              },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap()
        )
    );
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      try {
        await handleSubmit(values);

        enqueueSnackbar("Create Success", { variant: "success" });
        actions.setSubmitting(false);

        navigate(PATH_DASHBOARD.experiment.notebook);
      } catch (e) {
        console.log("error-in-submit", e);
        actions.setSubmitting(false);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    // isValid,
  } = formik;
  // console.log('notebook-values', values);
  // console.log('touched', touched);
  // console.log('errors', errors);

  const handleNameChange = async (name) => {
    await setFieldValue("notebookName", name);
    setFieldTouched("notebookName", true);

    if (!isEmpty(values.volumes)) {
      let existCount = 0;
      const tempVolumes = values.volumes.map((i) => {
        if (i.volumeType === "new") {
          return {
            ...i,
            mountPath: `/home/jovyan/${name}/code`,
          };
        } else {
          existCount++;
          return {
            ...i,
            mountPath: `/home/jovyan/${name}/data-${existCount}`,
          };
        }
      });

      setFieldValue("volumes", tempVolumes);
    }
  };

  const init = () => {
    // get existed featureset and modelpackage
    dispatch(
      getFeatureSetList({
        jwt_id: id,
        jwt_user_id: user_id,
        params: {
          page_num: 1,
          page_size: 9999,
        },
        isList: true,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
    dispatch(
      getModelPackageList({
        jwt_id: id,
        jwt_user_id: user_id,
        params: {
          page_num: 1,
          page_size: 9999,
        },
        isList: true,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );

    dispatch(
      getNotebookTableData({
        params: {
          page_num: 1,
          page_size: 9999,
        },
        isAll: true,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );

    // all tags
    dispatch(
      getTags({
        params: {
          is_active: 1,
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );

    // all Volumes (exist)
    dispatch(
      getNotebookVolumeTableData({
        params: {
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );

    // all templates
    dispatch(
      getNotebookTemplateTableData({
        params: {
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );

    getInitResourceQuota(project_id, setResourceQuota);
  };

  const resourceHelper = (type) => {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body2">
          {`${resourceQuota[type]?.used || 0}/${
            resourceQuota[type]?.all || 0
          } used/hard`}
        </Typography>
        <Tooltip title="Refresh to query instant resource">
          <IconButton
            size="small"
            sx={{ height: "18px", width: "18px" }}
            onClick={() => getInitResourceQuota(project_id, setResourceQuota)}
          >
            <RefreshOutlinedIcon
              sx={{ color: (theme) => theme.palette.grey[500] }}
            />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  useEffect(() => {
    init();
  }, []);

  // set the form default value
  useEffect(() => {
    if (!isEmpty(notebook)) {
      const type = typeList[0];
      setFieldValue("notebookType", type);

      setImages(imageListObj[type]);
      setFieldValue("image", imageListObj[type][0]);

      setFieldValue("gpuNum", gpuNumList[0]);
      setFieldValue("gpuVendor", gpuVendorList[0]);
    }
  }, [notebook]);

  useEffect(() => {
    if (!isEmpty(templateList)) {
      setFieldValue("notebookTemplate", templateList[0].id);
    }
  }, [templateList]);

  useEffect(() => {
    setFieldValue("advancedSettings", {
      billing: "elastic",
      provider: "aws",
      region: "beijing",
      node_type: "cpu",
      node_linux_arch: "x86",
    });

    setFieldValue("notebookName", `nb-jupyterlab-${new Date().getTime()}`);
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form>
        <MlCard title="Notebook Server Settings">
          <Scrollbar>
            <Box sx={{ pb: 2, width: "800px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Field
                    as={TextField}
                    name="notebookName"
                    label="Notebook Server Name"
                    size="small"
                    fullWidth
                    margin="dense"
                    helperText={
                      (touched.notebookName && errors.notebookName) || " "
                    }
                    error={touched.notebookName && Boolean(errors.notebookName)}
                    FormHelperTextProps={{
                      sx: { minHeight: "18px", marginTop: "2px" },
                    }}
                    onChange={(event) => {
                      handleNameChange(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl
                    fullWidth
                    margin="dense"
                    size="small"
                    error={touched.notebookType && Boolean(errors.notebookType)}
                  >
                    <InputLabel>Notebook Type</InputLabel>
                    <Field
                      as={Select}
                      name="notebookType"
                      label="Notebook Type"
                      onChange={async (event) => {
                        const match =
                          values.notebookName.match(/(nb-)(\w+)(-.*)/);
                        if (
                          match &&
                          typeList
                            .map((i) => i.toLowerCase())
                            .includes(match[2])
                        ) {
                          setFieldValue(
                            "notebookName",
                            values.notebookName.replace(
                              /(nb-)(.*?)(-.*)/g,
                              `$1${event.target.value.toLowerCase()}$3`
                            )
                          );
                        }

                        await setFieldValue("notebookType", event.target.value);
                        setFieldTouched("notebookType", true);

                        setFieldValue(
                          "image",
                          imageListObj[event.target.value][0]
                        );

                        setImages(imageListObj[event.target.value]);
                      }}
                    >
                      {typeList.map((item) => (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Field>
                    <FormHelperText
                      sx={{ minHeight: "18px", marginTop: "2px" }}
                    >
                      {touched.notebookType && errors.notebookType
                        ? errors.notebookType
                        : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={touched.cluster && Boolean(errors.cluster)}
              >
                <InputLabel>Kubernetes Cluster</InputLabel>
                <Field
                  as={Select}
                  name="cluster"
                  disabled
                  label="Kubernetes Cluster"
                >
                  <MenuItem value="default-k8s" key="default-k8s">
                    default-k8s
                  </MenuItem>
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {touched.cluster && errors.cluster ? errors.cluster : ""}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={touched.image && Boolean(errors.image)}
              >
                <InputLabel>image</InputLabel>
                <Field
                  as={Select}
                  name="image"
                  label="image"
                  disabled={isEmpty(images)}
                >
                  {images.map((item) => (
                    <MenuItem
                      title={item}
                      value={item}
                      key={item}
                      sx={{ width: "800px" }}
                    >
                      <Typography textOverflow="ellipsis" noWrap>
                        {item}
                      </Typography>
                    </MenuItem>
                  ))}
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {touched.image && errors.image ? errors.image : ""}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={
                  touched.notebookTemplate && Boolean(errors.notebookTemplate)
                }
              >
                <InputLabel>Notebook Template</InputLabel>
                <Field
                  as={Select}
                  name="notebookTemplate"
                  label="Notebook Template"
                  onMouseEnter={() => setIsMouseOver(true)}
                  onMouseLeave={() => setIsMouseOver(false)}
                  endAdornment={
                    isMouseOver &&
                    values.notebookTemplate && (
                      <IconButton
                        onClick={() => {
                          setFieldValue("notebookTemplate", "");
                        }}
                        size="small"
                        sx={{ mr: 2, width: "28px", height: "28px" }}
                      >
                        <ClearIcon />
                      </IconButton>
                    )
                  }
                >
                  {templateList?.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {touched.notebookTemplate && errors.notebookTemplate
                    ? errors.notebookTemplate
                    : ""}
                </FormHelperText>
              </FormControl>
              <Autocomplete
                size="small"
                multiple
                disableCloseOnSelect
                disablePortal
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                limitTags={8}
                getOptionLabel={(option) =>
                  typeof option === "object" ? option.name : option
                }
                isOptionEqualToValue={(option, value) => {
                  if (typeof option === "object" && typeof value === "object") {
                    return option.id === value.id;
                  } else if (
                    typeof option === "string" ||
                    typeof value === "string"
                  ) {
                    return option === value;
                  }
                  return false;
                }}
                options={tagList}
                value={values.tags.map(
                  (tagId) => tagList.find((tag) => tag.id === tagId) || tagId
                )}
                onChange={(_, newValues) => {
                  const filteredValues = newValues.filter(
                    (item) => typeof item === "string"
                  );

                  if (tagList.find((i) => i.name === filteredValues[0])) {
                    enqueueSnackbar(`Tag ${filteredValues[0]} existed`, {
                      variant: "warning",
                    });
                    return;
                  }

                  const updatedTagsPromises = newValues.map(async (item) => {
                    if (typeof item === "string") {
                      // new text ,add a new tag
                      const res = await dispatch(
                        addTags({
                          params: {
                            is_active: 1,
                            name: item,
                          },
                          alertCallback: enqueueSnackbar,
                          pathname,
                        })
                      ).unwrap();

                      enqueueSnackbar(`Add Tag ${item} Success`, {
                        variant: "success",
                      });

                      await dispatch(
                        getTags({
                          params: {
                            is_active: 1,
                            page_num: 1,
                            page_size: 9999,
                          },
                          alertCallback: enqueueSnackbar,
                          pathname,
                        })
                      );

                      return res.id;
                    }
                    return item.id;
                  });

                  Promise.all(updatedTagsPromises).then((r) => {
                    setFieldValue("tags", r);
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tags (Optional)"
                    helperText=" "
                    FormHelperTextProps={{
                      style: {
                        minHeight: "18px",
                        marginTop: "2px",
                      },
                    }}
                  />
                )}
                sx={{ mt: 1, mb: 0.5 }}
              />
              <Typography variant="subtitle1">CPU/RAM</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <MlNumberInputField
                    name="miniCpu"
                    label="Minimum CPU"
                    formik={formik}
                    unit="vCore"
                    isDouble
                    helperText={resourceHelper("cpu")}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MlNumberInputField
                    name="miniMemory"
                    label="Minimum Memory"
                    formik={formik}
                    unit="GB"
                    helperText={resourceHelper("memory")}
                  />
                </Grid>
              </Grid>
              <Typography variant="subtitle1">GPU</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <FormControl
                    fullWidth
                    margin="dense"
                    size="small"
                    error={touched.gpuNum && Boolean(errors.gpuNum)}
                  >
                    <InputLabel>Number of GPU</InputLabel>
                    <Field as={Select} name="gpuNum" label="Number of GPU">
                      {gpuNumList.map((item) => (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Field>
                    <FormHelperText
                      sx={{ minHeight: "18px", marginTop: "2px" }}
                    >
                      {touched.gpuNum && errors.gpuNum
                        ? errors.gpuNum
                        : resourceHelper("gpu")}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl
                    fullWidth
                    margin="dense"
                    size="small"
                    error={touched.gpuVendor && Boolean(errors.gpuVendor)}
                  >
                    <InputLabel>GPU Vendor</InputLabel>
                    <Field as={Select} name="gpuVendor" label="GPU Vendor">
                      {gpuVendorList.map((item) => (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Field>
                    <FormHelperText
                      sx={{ minHeight: "18px", marginTop: "2px" }}
                    >
                      {touched.gpuVendor && errors.gpuVendor
                        ? errors.gpuVendor
                        : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Data Volumes */}
              <Typography variant="subtitle1">Data Volumes</Typography>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", pl: 2, py: 1 }}
              >
                Additional volumes that will be mounted in your Notebook
              </Typography>
              <FieldArray
                name="volumes"
                render={({ push, remove }) => (
                  <>
                    <Grid container spacing={3}>
                      {/* <Grid item xs={12} lg={6}>
                        <Button
                          variant='outlined'
                          fullWidth
                          disabled={values.volumes.filter(item => item.volumeType === 'new').length >= 1}
                          color='inherit'
                          startIcon={<AddOutlinedIcon />}
                          onClick={() => {
                            if (!values.notebookName) {
                              enqueueSnackbar('Input notebook name first', { variant: 'warning' });
                              return;
                            };

                            if (!values.cluster) {
                              enqueueSnackbar('Select kubernetes cluster first', { variant: 'warning' });
                              return;
                            };

                            const tempCluster = values?.cluster || '';
                            const tempPath = values?.notebookName ? `/home/jovyan/${values?.notebookName}/code` : '';
                            push({
                              volumeType: 'new',
                              type: 'Kubernetes Persistent Volume',
                              cluster: tempCluster,
                              name: `new-volume-1`,
                              giSize: '1',
                              accessMode: 'ReadWriteOnce',
                              // mountPath: '/home/jovyan/yuutygyu-datavol-2',
                              mountPath: tempPath,
                            })
                          }}
                        >
                          Add new volume
                        </Button>
                      </Grid> */}
                      <Grid item xs={12} lg={6}>
                        <Button
                          variant="outlined"
                          fullWidth
                          disabled={
                            startedVolumeList.length ===
                              values.volumes.filter(
                                (item) => item.volumeType === "existing"
                              ).length || startedVolumeList.length === 0
                          }
                          color="inherit"
                          startIcon={<AddOutlinedIcon />}
                          onClick={() => {
                            if (!values.notebookName) {
                              enqueueSnackbar("Input notebook name first", {
                                variant: "warning",
                              });
                              return;
                            }

                            const existedVolumes = values.volumes.filter(
                              (v) => v.volumeType === "existing"
                            );

                            const tempPath = values?.notebookName
                              ? `/home/jovyan/${values?.notebookName}/data-${
                                  existedVolumes.length + 1
                                }`
                              : "";

                            const filterVolumes = startedVolumeList.filter(
                              (i) => {
                                if (
                                  existedVolumes.findIndex(
                                    (v) => v.name === i.id
                                  ) > -1
                                ) {
                                  return false;
                                }
                                return true;
                              }
                            );
                            push({
                              volumeType: "existing",
                              type: "Kubernetes Volume",
                              name: filterVolumes[0].id,
                              isReadOnly: false,
                              mountPath: tempPath,
                              isSyncFs: false,
                              isSyncMp: false,
                              fs_id: "",
                              fs_version_id: "",
                              fs_version_external_id: "",
                              mp_id: "",
                              mp_version_id: "",
                              mp_version_external_id: "",
                            });
                          }}
                        >
                          Add existing volume
                        </Button>
                      </Grid>
                    </Grid>
                    {/* new volume optional，exist volume at least one */}

                    {values.volumes.map((volume, index) =>
                      volume.volumeType === "new" ? (
                        <KubeflowNewVolumeForm
                          index={index}
                          removeFunc={() => {
                            remove(index);
                          }}
                          formik={formik}
                        />
                      ) : (
                        <KubeflowExistingVolumeForm
                          index={index}
                          removeFunc={() => {
                            remove(index);
                          }}
                          formik={formik}
                        />
                      )
                    )}
                  </>
                )}
              />

              {/* node_selector */}
              <AdvancedSettings formik={formik} />
            </Box>
          </Scrollbar>
        </MlCard>
        <Stack direction="row" justifyContent="center" spacing={2}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            color="primary"
            sx={{ width: "200px", color: "background.paper" }}
          >
            Save
          </LoadingButton>
          <Button
            variant="outlined"
            color="inherit"
            sx={{ width: "200px", color: "text.secondary" }}
            onClick={() => navigate(PATH_DASHBOARD.experiment.notebook)}
          >
            Cancel
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default KubeflwNotebook;

// @types
import { RootState, useSelector } from "@/redux/store";
import { StyledTextField } from "./PipelineComponents";
import { Step } from "@/@types/project/mlPipeline/SageMaker/pipeline";
import { ProcessingStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-processing";
// import { TrainingStep } from "../../../../../@types/pipeline-training";

// ----------------------------------------------------------------------

const OptGroup = (isKubeflow: boolean, parentStep: any) => {
  if (!isKubeflow) {
    switch (parentStep.type) {
      case "Processing":
        return (
          <optgroup label={parentStep.name} key={parentStep.id}>
            {(parentStep as ProcessingStep).processingOutputs?.map(
              (processingOutput, _) => (
                <option
                  key={`${parentStep.name}.ProcessingOutputs.${processingOutput.outputName}`}
                  value={`${parentStep.name}.ProcessingOutputs.${processingOutput.outputName}`}
                >
                  {`ProcessingOutputs.${processingOutput.outputName}`}
                </option>
              )
            )}
            {(parentStep as ProcessingStep).propertyFiles?.map(
              (propertyFile, _) => (
                <option
                  key={`${parentStep.name}.PropertyFiles.${propertyFile.name}`}
                  value={`${parentStep.name}.PropertyFiles.${propertyFile.name}`}
                >
                  {`PropertyFiles.${propertyFile.name}`}
                </option>
              )
            )}
          </optgroup>
        );
      case "Training":
        return (
          <optgroup label={parentStep.name} key={parentStep.id}>
            <option
              value={`${parentStep.name}.ModelArtifacts.S3ModelArtifacts`}
            >
              ModelArtifacts.S3ModelArtifacts
            </option>
          </optgroup>
        );
      case "Model":
        return (
          <optgroup label={parentStep.name} key={parentStep.id}>
            <option value={`${parentStep.name}.ModelName`}>ModelName</option>
          </optgroup>
        );
      case "RegisterModel":
        return (
          <optgroup label={parentStep.name} key={parentStep.id}>
            <option value={`${parentStep.name}.ModelPackageArn`}>
              ModelPackageArn
            </option>
          </optgroup>
        );
      case "ClarifyCheck":
        return (
          <optgroup label={parentStep.name} key={parentStep.id}>
            <option value={`${parentStep.name}.CalculatedBaselineConstraints`}>
              CalculatedBaselineConstraints
            </option>
            <option
              value={`${parentStep.name}.BaselineUsedForDriftCheckConstraints`}
            >
              BaselineUsedForDriftCheckConstraints
            </option>
          </optgroup>
        );
      default:
        return null;
    }
  }

  return (
    <optgroup label={parentStep.name} key={parentStep.id}>
      {(parentStep as { outputs: any }).outputs?.map((output, _) => (
        <option
          key={`${parentStep.name}.${output.name}`}
          value={`${parentStep.name}.${output.name}`}
        >
          {`output.${output.name}`}
        </option>
      ))}
    </optgroup>
  );
};

export default function PipelineParameterSelector({ formik, name, ...other }) {
  const { pipelineType } = useSelector((state: RootState) => state.schema);
  const isKubeflow = pipelineType === "Kubeflow";

  const { currentPipeline, currentKubeflowPipeline } = useSelector(
    (state: RootState) => state.pipeline
  );
  const commonPipelineData = isKubeflow
    ? currentKubeflowPipeline
    : currentPipeline;

  const { parentEntities } = useSelector((state: RootState) => state.schema);

  const params = commonPipelineData.pipelineParamList;
  // console.log(parentEntities);

  const parentSteps = useSelector((state: RootState) => {
    let steps: Step[] = [];
    parentEntities.forEach((entityId, _) => {
      let step: Step | undefined = state.schema.schema.pipelineSteps.find(
        (step) => {
          return step.id === entityId;
        }
      ) as Step;
      steps.push(step);
    });
    return steps;
  });

  const { values, setValues, setFieldTouched } = formik;

  return (
    <StyledTextField
      select
      SelectProps={{ native: true }}
      {...other}
      title={values[name]}
      onChange={async (event) => {
        const e = event as any;
        const curValue = e.target.value;
        const label = e.target.options[e.target.selectedIndex].parentNode.label;

        if (values.useParam) {
          if (label === "Pipeline parameters") {
            await setValues({
              ...values,
              [name]: curValue,
              paramType: 1,
            });
          } else {
            await setValues({
              ...values,
              [name]: curValue,
              paramType: 3,
            });
          }
        }
        setFieldTouched(name, true);
      }}
    >
      <option aria-label="None" value="" />
      <optgroup label={"Pipeline parameters"}>
        {params.map((param, index) => (
          <option key={index} value={param.Name}>
            {param.Name}
          </option>
        ))}
      </optgroup>
      {parentSteps.length > 0 &&
        parentSteps.map((parentStep) => OptGroup(isKubeflow, parentStep))}
    </StyledTextField>
  );
}

import { customMatchPath } from "@/utils/project/utils";
import sidebarConfig from "./sidebar/SidebarConfig";
import { PATH_DASHBOARD } from "@/routes/paths";

export const findItemAndParentByPath = (config, path: string) => {
  for (const section of config) {
    const firstMenuItem = section.firstMenuItem; // get firstMenuItem
    if (section.secondMenuData) {
      for (const submenu of section.secondMenuData) {
        if (submenu.items) {
          for (const item of submenu.items) {
            if (customMatchPath(item.path, path)) {
              // target values
              return { item, subheader: submenu.subheader, firstMenuItem };
            }
          }
        }
      }
    }
  }
  return {};
};

// admin or platform user
export const isAuthed = (pathname, isNoneUser, isAdmin) => {
  // exclude some private pages that not exist in the sidebar
  if (pathname.includes("myCredential")) {
    return true;
  }

  let flag = true;
  const nemuItemName = findItemAndParentByPath(
    sidebarConfig,
    pathname
  ).firstMenuItem;

  if (isNoneUser) {
    flag = false;
  }

  if (isAdmin && nemuItemName !== "FirstMenuItem3") {
    flag = false;
  }

  if (!isAdmin && nemuItemName === "FirstMenuItem3") {
    flag = false;
  }

  return flag;
};

// filter the config arr by permissions
export const filterSiderbarConfigByPermissions = (config, permissions) => {
  return config
    .map((item) => {
      // handle secondMenuData
      let tSecondMenuData: Array<any> = [];
      if (item.secondMenuData && item.secondMenuData.length > 0) {
        tSecondMenuData = item.secondMenuData
          .map((secondMenuItem) => {
            // handle items
            let tItems: Array<any> = [];
            if (secondMenuItem.items && secondMenuItem.items.length > 0) {
              tItems = secondMenuItem.items.filter((item) => {
                // check auth is in permissions or not
                return item.auth.some((auth) =>
                  permissions.includes(String(auth))
                );
              });
            }

            return {
              ...secondMenuItem,
              items: tItems,
            };
          })
          .filter((secondMenuItem) => secondMenuItem.items.length > 0); // filter items empty secondMenuItem
      }

      return {
        ...item,
        secondMenuData: tSecondMenuData,
      };
    })
    .filter((item) => item.secondMenuData.length > 0); // filter secondMenuData empty item
};

// judge admin、non-admin permissions
export const filterAdminSiderConfig = (isAdmin, isNoneUser, sidebarConfig) => {
  return sidebarConfig.filter((item) => {
    if (isAdmin) {
      return item.firstMenuItem === "FirstMenuItem3";
    } else {
      if (!isNoneUser) {
        return item.firstMenuItem !== "FirstMenuItem3";
      } else {
        return false;
      }
    }
  });
};

// different kinds of users have different main page
export const findMainPage = (isAdmin, filteredConfig) => {
  for (const section of filteredConfig) {
    if (section.secondMenuData) {
      for (const submenu of section.secondMenuData) {
        // secondMenuData
        if (submenu.items) {
          // return the first authorized menu item
          return submenu.items?.[0]?.path;
        }
      }
    }
  }

  // secure step
  let mainPath = "";
  if (isAdmin) {
    mainPath = PATH_DASHBOARD.administration.organization;
  } else {
    mainPath = PATH_DASHBOARD.experiment.notebook;
  }

  return mainPath;
};

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import pipelineReducer from "./project/automation/pipeline";
import schemaReducer from "@/pages/project/automation/MlPipeline/graph/slices/slice";
// import executionReducer from "@/pages/project/automation/MlPipeline/pipeline-execution/execution-graph/executionSlice";
import executionReducer from "@/pages/project/automation/MlPipeline/pipeline-execution/Kubeflow/ExecutionDetailDialog/Graph/executionSlice";

// mlOps
import common from "./project/common/slice";
import administration from "./project/administration/slice";
import template from "./project/template/slice";
import connection from "./project/connection/slice";
import mlRuntimeReducer from "./project/mlService/mlRuntime/slice";
import experiment from "./project/experiment/slice";
import automation from "./project/automation/slice";
import appGalleryReducer from "./project/mlService/appGallery/slice";
import modelRegistryReducer from "./project/dataAsset/modelRegistry/slice";
import featureSetReducer from "./project/dataAsset/featureStore/slice";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const commonPersistConfig = {
  key: "common",
  storage,
  keyPrefix: "redux-",
};

const rootReducer = combineReducers({
  common: persistReducer(commonPersistConfig, common),
  administration,
  template,
  connection,
  experiment,
  automation,
  schema: schemaReducer,
  pipeline: pipelineReducer,
  execution: executionReducer,
  mlRuntime: mlRuntimeReducer,
  appGallery: appGalleryReducer,
  modelRegistry: modelRegistryReducer,
  featureStore: featureSetReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export { rootPersistConfig, rootReducer };

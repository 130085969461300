import { createSlice } from "@reduxjs/toolkit";
import { addThunkReducers } from "../utils";
import { IConnectionState } from "@/@types/project/model/connection";
import {
  getConnectionDetail,
  getConnectionTableData,
  getCredentialDetail,
  getCredentialTableData,
} from "./thunks";

const initialState: IConnectionState = {
  connectionTableList: {
    loading: false,
    data: {},
    error: null,
  },
  connectionDetail: {
    loading: false,
    data: {},
    error: null,
  },
  credentialTableList: {
    loading: false,
    data: {},
    error: null,
  },
  credentialDetail: {
    loading: false,
    data: {},
    error: null,
  },
};

const slice = createSlice({
  name: "connection",
  initialState,
  reducers: {
    setConnectionData(state, action) {
      Object.assign(state, action.payload);
    },
    resetConnectionData(state, action) {
      Object.assign(state[action.payload], { data: {} });
    },
  },
  extraReducers: (builder) => {
    addThunkReducers(builder, [
      { action: getConnectionTableData, name: "connectionTableList" },
      { action: getConnectionDetail, name: "connectionDetail" },
      { action: getCredentialTableData, name: "credentialTableList" },
      { action: getCredentialDetail, name: "credentialDetail" },
    ]);
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setConnectionData, resetConnectionData } = slice.actions;

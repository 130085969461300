import { checkCode, handleErrorMessage } from "@/utils/project/request";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { MlParams } from "@/@types/project/global";
import {
  addPipelineExecutionService,
  addPipelineService,
  addPipelineTagsService,
  addPipelineTriggerService,
  addPipelineVersionService,
  addPipelineVolumeService,
  addPipelineVolumeTagsService,
  addRelPipelineVolumeService,
  delPipelineService,
  delPipelineTagsService,
  delPipelineTriggerService,
  delPipelineVersionService,
  delPipelineVolumeService,
  delPipelineVolumeTagsService,
  delRelPipelineVolumeService,
  editPipelineTriggerService,
  editPipelineVersionService,
  editPipelineVolumeService,
  getPipelineDetail,
  getPipelineExecutionDetailService,
  getPipelineExecutionService,
  getPipelineListService,
  getPipelineTagsService,
  getPipelineTriggerDetailService,
  getPipelineTriggerService,
  getPipelineVersionDetailService,
  getPipelineVersionTableList,
  getPipelineVolumeDetail,
  getPipelineVolumeListService,
  getPipelineVolumeTagsService,
  getRelPipelineVolumeService,
  updatePipelineService,
} from "@/services/automation";

interface IGetPipelineParams extends MlParams {
  params: {
    name?: string;
    page_num: number;
    page_size: number;
  };
  isAll?: boolean;
}

// 1. pipeline
export const getPipelineTableData = createAsyncThunk(
  "common/getPipelineTableData",
  async (
    { params, isAll, alertCallback, pathname }: IGetPipelineParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getPipelineListService(params, isAll);
      const res = checkCode(data, pathname) as Object & {
        items: Array<Record<any, any>>;
      };
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching pipeline data failed");
    }
  }
);

interface IGetPipelineDetailParams extends MlParams {
  id: string;
}

// pipeline detail
export const getPipelineDetailData = createAsyncThunk(
  "common/getPipelineDetailData",
  async (
    { id, alertCallback, pathname }: IGetPipelineDetailParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getPipelineDetail(id);
      const res = checkCode(data, pathname) as Object & {
        mlpipeline_template_version_id: string;
        resource_config: string;
        name: string;
        description: string;
        mlpipeline_type: string;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching pipeline detail data failed");
    }
  }
);

interface IAddPipelineParams extends MlParams {
  params: {
    name: string;
    mlpipeline_type: string;
    description?: string;
    scenario?: string;
    mlpipeline_template_version_id?: string;
  };
}

export const addPipeline = createAsyncThunk(
  "common/addPipeline",
  async (
    { params, alertCallback, pathname }: IAddPipelineParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addPipelineService(params);
      const res = checkCode(data, pathname) as { id: string };
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add mlpipeline failed");
    }
  }
);

interface IUpdatePipelineParams extends MlParams {
  id: string;
  params: {
    name: string;
    mlpipeline_type: string;
    description?: string;
    scenario?: string;
  };
}

export const updatePipeline = createAsyncThunk(
  "common/updatePipeline",
  async (
    { id, params, alertCallback, pathname }: IUpdatePipelineParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await updatePipelineService(id, params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Update mlpipeline failed");
    }
  }
);

interface IDelPipelineParams extends MlParams {
  id: string;
}

export const delPipeline = createAsyncThunk(
  "common/delPipeline",
  async (
    { id, alertCallback, pathname }: IDelPipelineParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delPipelineService(id);
      checkCode(data, pathname) as Object;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete mlpipeline failed");
    }
  }
);

interface IGetPipelineTags extends MlParams {
  params: {
    mlpipeline_id: string;
    page_num: number;
    page_size: number;
  };
}

// mlpipeline tags
export const getPipelineTags = createAsyncThunk(
  "common/getPipelineTags",
  async (
    { params, alertCallback, pathname }: IGetPipelineTags,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getPipelineTagsService(params);
      const res = checkCode(data, pathname) as Object & {
        items: Array<Object & { tag_id: string }>;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching mlpipeline tags failed");
    }
  }
);

interface IAddPipelineTags extends MlParams {
  params: {
    mlpipeline_id: string;
    tag_id: string;
  };
}

export const addPipelineTags = createAsyncThunk(
  "common/addPipelineTags",
  async (
    { params, alertCallback, pathname }: IAddPipelineTags,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addPipelineTagsService(params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Update mlpipeline tags failed");
    }
  }
);

interface IDeletePipelineTags extends MlParams {
  id: string;
}

export const delPipelineTags = createAsyncThunk(
  "common/delPipelineTags",
  async (
    { id, alertCallback, pathname }: IDeletePipelineTags,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delPipelineTagsService(id);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete mlpipeline tags failed");
    }
  }
);

interface IGetPipelineVersionParams extends MlParams {
  params: {
    id?: string;
    name?: string;
    mlpipeline_id?: string;
    active_version?: number;
    page_num: number;
    page_size: number;
  };
}

// 2. mlpipeline version
export const getPipelineVersionTableData = createAsyncThunk(
  "common/getPipelineVersionTableData",
  async (
    { params, alertCallback, pathname }: IGetPipelineVersionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getPipelineVersionTableList(params);
      const res = checkCode(data, pathname) as Object & {
        items: Array<{ id: string; mlpipeline_template_id: string }>;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching mlpipeline version data failed");
    }
  }
);
interface IGetPipelineVersionDetail extends MlParams {
  id: string;
}

export const getPipelineVersionDetail = createAsyncThunk(
  "common/getPipelineVersionDetail",
  async (
    { id, alertCallback, pathname }: IGetPipelineVersionDetail,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getPipelineVersionDetailService(id);
      const res = checkCode(data, pathname) as Object & { name: string };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching mlpipeline version detail data failed");
    }
  }
);
interface IAddPipelineVersionParams extends MlParams {
  params: {
    name?: string;
    description?: string;
    mlpipeline_id: string;
    active_version?: number;
    resource_config: Record<any, any>;
  };
}

export const addPipelineVersion = createAsyncThunk(
  "common/addPipelineVersion",
  async (
    { params, alertCallback, pathname }: IAddPipelineVersionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addPipelineVersionService(params);
      const res = checkCode(data, pathname) as Object & { id: string };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Edit mlpipeline version data failed");
    }
  }
);
interface IEditPipelineVersionParams extends MlParams {
  id: string;
  params: {
    name?: string;
    description?: string;
    mlpipeline_id?: string;
    active_version?: number;
  };
}

export const editPipelineVersion = createAsyncThunk(
  "common/editPipelineVersion",
  async (
    { id, params, alertCallback, pathname }: IEditPipelineVersionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await editPipelineVersionService(id, params);
      const res = checkCode(data, pathname) as Object;

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Edit mlpipeline version data failed");
    }
  }
);

interface IDelPipelineVersionParams extends MlParams {
  id: string;
  params?: {
    status?: string;
  };
}

export const delPipelineVersion = createAsyncThunk(
  "common/delPipelineVersion",
  async (
    { id, params, alertCallback, pathname }: IDelPipelineVersionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delPipelineVersionService(id, params);
      const res = checkCode(data, pathname) as Object;

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete mlpipeline version data failed");
    }
  }
);

interface IGetPipelineVolumeParams extends MlParams {
  params: {
    name?: string;
    page_num: number;
    page_size: number;
  };
  isAll?: boolean;
}

// 3. pipeline volume
export const getPipelineVolumeTableData = createAsyncThunk(
  "common/getPipelineVolumeTableData",
  async (
    { params, isAll, alertCallback, pathname }: IGetPipelineVolumeParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getPipelineVolumeListService(params, isAll);
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching pipeline volume data failed");
    }
  }
);

interface IGetPipelineVolumeDetailParams extends MlParams {
  id: string;
}

export const getPipelineVolumeDetailData = createAsyncThunk(
  "common/getPipelineVolumeDetailData",
  async (
    { id, alertCallback, pathname }: IGetPipelineVolumeDetailParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getPipelineVolumeDetail(id);
      const res = checkCode(data, pathname) as Object & { name: string };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching mlpipeline volume detail data failed");
    }
  }
);

interface IAddPipelineVolumeParams extends MlParams {
  params: {
    name: string;
    volume_type: string;
    resource_config: any;
    status?: string;
  };
}

export const addPipelineVolume = createAsyncThunk(
  "common/addPipelineVolume",
  async (
    { params, alertCallback, pathname }: IAddPipelineVolumeParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addPipelineVolumeService(params);
      const res = checkCode(data, pathname) as { id: string };
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add mlpipeline Volume failed");
    }
  }
);

interface IEditPipelineVolumeParams extends MlParams {
  id: string;
  params: {
    resource_config?: any;
    status?: string;
  };
}

export const editPipelineVolume = createAsyncThunk(
  "common/editPipelineVolume",
  async (
    { id, params, alertCallback, pathname }: IEditPipelineVolumeParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await editPipelineVolumeService(id, params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Edit mlpipeline failed");
    }
  }
);

interface IDelPipelineParams extends MlParams {
  id: string;
}

export const delPipelineVolume = createAsyncThunk(
  "common/delPipelineVolume",
  async (
    { id, alertCallback, pathname }: IDelPipelineParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delPipelineVolumeService(id);
      checkCode(data, pathname) as Object;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete mlpipeline volume failed");
    }
  }
);

// rel_mlpipeline_mlpipeline_volume
interface IGetRelPipelineVolumeParams extends MlParams {
  params: {
    mlpipeline_id?: string;
    mlpipeline_volume_id?: string;
    page_num: number;
    page_size: number;
  };
}

export const getRelPipelineVolumeData = createAsyncThunk(
  "common/getRelPipelineVolumeData",
  async (
    { params, alertCallback, pathname }: IGetRelPipelineVolumeParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getRelPipelineVolumeService(params);
      const res = checkCode(data, pathname) as {
        items: Array<{
          id: string;
          mlpipeline_volume_id: string;
          mlpipeline_id: string;
        }>;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching relatetion mlpipeline volume failed");
    }
  }
);

interface IAddRelPipelineVolumeParams extends MlParams {
  params: {
    mlpipeline_id: string;
    mlpipeline_volume_id: string;
  };
}

export const addRelPipelineVolumeData = createAsyncThunk(
  "common/addRelPipelineVolumeData",
  async (
    { params, alertCallback, pathname }: IAddRelPipelineVolumeParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addRelPipelineVolumeService(params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add relatetion mlpipeline volume failed");
    }
  }
);

interface IDelRelPipelineVolumeParams extends MlParams {
  id: string;
}

export const delRelPipelineVolumeData = createAsyncThunk(
  "common/delRelPipelineVolumeData",
  async (
    { id, alertCallback, pathname }: IDelRelPipelineVolumeParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delRelPipelineVolumeService(id);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete relatetion mlpipeline volume failed");
    }
  }
);

// rel_mlpipeline_tag
interface IGetPipelineVolumeTags extends MlParams {
  params: {
    mlpipeline_volume_id: string;
    page_num: number;
    page_size: number;
  };
}

export const getPipelineVolumeTags = createAsyncThunk(
  "common/getPipelineVolumeTags",
  async (
    { params, alertCallback, pathname }: IGetPipelineVolumeTags,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getPipelineVolumeTagsService(params);
      const res = checkCode(data, pathname) as Object & {
        items: Array<Object & { tag_id: string }>;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching mlpipeline volume tags failed");
    }
  }
);

interface IAddPipelineVolumeTags extends MlParams {
  params: {
    mlpipeline_volume_id: string;
    tag_id: string;
  };
}

export const addPipelineVolumeTags = createAsyncThunk(
  "common/addPipelineVolumeTags",
  async (
    { params, alertCallback, pathname }: IAddPipelineVolumeTags,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addPipelineVolumeTagsService(params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add mlpipeline volume tags failed");
    }
  }
);

interface IDeletePipelineVolumeTags extends MlParams {
  id: string;
}

export const delPipelineVolumeTags = createAsyncThunk(
  "common/delPipelineVolumeTags",
  async (
    { id, alertCallback, pathname }: IDeletePipelineVolumeTags,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delPipelineVolumeTagsService(id);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete mlpipeline volume tags failed");
    }
  }
);

interface IGetPipelineTriggerParams extends MlParams {
  params: {
    mlpipeline_version_id: string;
    name?: string;
    page_num: number;
    page_size: number;
  };
  isAll?: boolean;
}

// 4. pipeline trigger
export const getPipelineTriggerTableData = createAsyncThunk(
  "common/getPipelineTriggerTableData",
  async (
    { params, isAll, alertCallback, pathname }: IGetPipelineTriggerParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getPipelineTriggerService(params, isAll);
      const res = checkCode(data, pathname) as Object & {
        items: Array<Record<any, any>>;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching trigger data failed");
    }
  }
);

interface IGetPipelineTriggerDetailParams extends MlParams {
  id: string;
}

export const getPipelineTriggerDetailData = createAsyncThunk(
  "common/getPipelineTriggerDetailData",
  async (
    { id, alertCallback, pathname }: IGetPipelineTriggerDetailParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getPipelineTriggerDetailService(id);
      const res = checkCode(data, pathname) as Object & { name: string };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching trigger detail data failed");
    }
  }
);

interface IAddPipelineTriggerParams extends MlParams {
  params: {
    mlpipeline_version_id: string;
    name: string;
    description?: string;
    trigger_type: string;
    resource_config: Record<any, any>;
    status?: string;
  };
}

export const addPipelineTrigger = createAsyncThunk(
  "common/addPipelineTrigger",
  async (
    { params, alertCallback, pathname }: IAddPipelineTriggerParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addPipelineTriggerService(params);
      const res = checkCode(data, pathname) as { id: string };
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add trigger failed");
    }
  }
);

interface IEditPipelineTriggerParams extends MlParams {
  id: string;
  params: {
    description?: string;
    resource_config?: Record<any, any>;
    status?: string;
  };
}

export const editPipelineTrigger = createAsyncThunk(
  "common/editPipelineTrigger",
  async (
    { id, params, alertCallback, pathname }: IEditPipelineTriggerParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await editPipelineTriggerService(id, params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Edit trigger failed");
    }
  }
);

interface IDelPipelineParams extends MlParams {
  id: string;
}

export const delPipelineTrigger = createAsyncThunk(
  "common/delPipelineTrigger",
  async (
    { id, alertCallback, pathname }: IDelPipelineParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delPipelineTriggerService(id);
      checkCode(data, pathname) as Object;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete mlpipeline trigger failed");
    }
  }
);

interface IGetPipelineExecutionParams extends MlParams {
  params: {
    mlpipeline_version_id: string;
  };
  // isAll?: boolean,
}

// not paginate temporarily
// 5. pipeline execution
export const getPipelineExecutionTableData = createAsyncThunk(
  "common/getPipelineTriggerTableData",
  async (
    { params, alertCallback, pathname }: IGetPipelineExecutionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getPipelineExecutionService(params);
      const res = checkCode(data, pathname) as Object & {
        items: Array<Record<any, any>>;
      };
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching execution data failed");
    }
  }
);

interface IGetPipelineExecutionDetailParams extends MlParams {
  id: string;
}

export const getPipelineExecutionDetailData = createAsyncThunk(
  "common/getPipelineExecutionDetailData",
  async (
    { id, alertCallback, pathname }: IGetPipelineExecutionDetailParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getPipelineExecutionDetailService(id);
      const res = checkCode(data, pathname) as Object & { name: string };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching execution detail data failed");
    }
  }
);

interface IAddPipelineExecutionParams extends MlParams {
  params: {
    mlpipeline_version_id: string;
  };
}

export const addPipelineExecution = createAsyncThunk(
  "common/addPipelineExecution",
  async (
    { params, alertCallback, pathname }: IAddPipelineExecutionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addPipelineExecutionService(params);
      const res = checkCode(data, pathname) as { id: string };
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add execution failed");
    }
  }
);

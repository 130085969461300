import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import { useDispatch } from "react-redux";
// material
import { Paper, Stack, IconButton } from "@mui/material";
//
import {
  updateProcessingEnvVars,
  deleteProcessingEnvVars,
} from "../../../graph/slices/slice";
import { PipelineTextfield } from "../../components/PipelineComponents";

// ----------------------------------------------------------------------

type FormValues = {
  name: string;
  value: string;
};

export default function ProcessingProcessorEnvVar({
  id,
  name,
  value,
}: {
  id: number;
  name: string;
  value: string;
}) {
  const dispatch = useDispatch();

  const ParamSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    value: Yup.string().required("Value is required"),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      name: name,
      value: value,
    },
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values, { setErrors }) => {
      try {
        dispatch(
          updateProcessingEnvVars({
            id: id,
            ...values,
          })
        );
      } catch (error) {
        // setErrors(error.code);
      }
    },
  });

  const { handleSubmit } = formik;

  return (
    <>
      <Paper
        sx={{
          p: 1.5,
          mx: 2,
          mb: 1,
          mt: 0,
          borderRadius: 1.5,
          bgcolor: "background.neutral",
        }}
      >
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onChange={handleSubmit}>
            <Stack spacing={2}>
              <PipelineTextfield
                label={"Variable name"}
                field={"name"}
                placeholder={"Variable name"}
                formik={formik}
              />

              <Stack direction="row" spacing={1} alignItems="center">
                <PipelineTextfield
                  label={"Value"}
                  field={"value"}
                  placeholder={"Variable value"}
                  formik={formik}
                />

                <IconButton
                  size="small"
                  onClick={() => {
                    dispatch(deleteProcessingEnvVars(id));
                  }}
                >
                  <Icon icon={trash2Fill} width={18} height={18} />
                </IconButton>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </Paper>
    </>
  );
}

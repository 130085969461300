import Page from "@/components/project/Page";
import NotebookTemplateList from "./components/NotebookTemplateList";

const NotebookTemplate = () => {
  return (
    <Page>
      <NotebookTemplateList />
    </Page>
  );
};

export default NotebookTemplate;

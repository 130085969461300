import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Box, Stack, Collapse, IconButton } from "@mui/material";
//
import EmptyTable from "@/components/project/EmptyTable";
import Condition from "./Condition";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { ConditionStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-condition";
import { updateCondition } from "../../../graph/slices/slice";

// ----------------------------------------------------------------------

type Props = {
  currentStep: ConditionStep | null;
};

export default function Conditions({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [isCollapsed, setCollapsed] = useState(true);

  const conditions = currentStep?.conditions || [];

  const handleAddCondition = () => {
    dispatch(
      updateCondition({
        data: {
          conditionType: "Equals",
          leftValue: {
            source: "value",
            value: "",
            valueUseParam: false,
            jsonKey: "",
            jsonKeyUseParam: false,
          },
          rightValue: {
            source: "value",
            value: "",
            valueUseParam: false,
            jsonKey: "",
            jsonKeyUseParam: false,
          },
        },
      })
    );
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <CollapseButtonStyle
          fullWidth
          color="inherit"
          onClick={() => setCollapsed((prev) => !prev)}
          endIcon={
            <Icon
              icon={isCollapsed ? arrowIosForwardFill : arrowIosDownwardFill}
              width={16}
              height={16}
            />
          }
        >
          Conditions {conditions.length > 0 && `(${conditions.length})`}
        </CollapseButtonStyle>

        <IconButton
          sx={{ zIndex: 1999, position: "absolute", top: 7, right: 30 }}
          size="small"
          onClick={handleAddCondition}
        >
          <Icon icon={plusFill} width={16} height={16} />
        </IconButton>
      </Box>

      <Collapse in={!isCollapsed}>
        <Stack
          spacing={1.5}
          sx={{ mx: 2, mb: 1.5, mt: !isCollapsed ? 0.5 : 0 }}
        >
          {conditions.length > 0 &&
            conditions.map((condition, index) => (
              <Condition key={index} id={index} data={condition} />
            ))}
        </Stack>
      </Collapse>

      {/* {isCollapsed && <Divider />} */}

      {!isCollapsed && conditions.length === 0 && (
        <EmptyTable
          mainTitle={"No conditions"}
          content={'Create one by clicking "+" button'}
          color={"text.disabled"}
          sx={{ backgroundColor: "transparent", mx: 2, mt: 5, mb: 7 }}
        />
      )}
    </>
  );
}

import Scrollbar from "@/components/project/Scrollbar";
import { Grid, Skeleton, Stack, Typography, useTheme } from "@mui/material";

interface ICardListCell {
  subTitle: string;
  label: string | JSX.Element;
  loading?: boolean;
}

interface IProps {
  list: ICardListCell[];
  colNum?: number;
  minWidth?: number;
}

const MlCardList = ({ list, colNum = 3, minWidth = 900 }: IProps) => {
  const theme = useTheme();

  // pad the list to show the divider
  const padList = (arr) => {
    const remainder = arr.length % colNum;
    if (remainder === 0) {
      return arr;
    }

    const itemsToAdd = colNum - remainder;
    for (let i = 0; i < itemsToAdd; i++) {
      arr.push({ subTitle: "", label: "" });
    }

    return arr;
  };

  return (
    <Scrollbar>
      <Grid container sx={{ minWidth }}>
        {padList(list).map(({ subTitle, label, loading = false }, index) => (
          <Grid
            key={`${subTitle}-${index}`}
            item
            xs={12 / colNum}
            lg={12 / colNum}
            sx={{
              [theme.breakpoints.up("lg")]: {
                borderLeft:
                  index % colNum !== 0
                    ? `1px solid ${theme.palette.grey[500_16]}`
                    : "none",
              },
              pl: 2,
              pr: 6,
              pb: 2,
            }}
          >
            <Stack sx={{ minWidth: "100%" }}>
              <Typography
                variant="subtitle2"
                sx={{ mb: 0.3, fontWeight: 550, fontSize: "14px" }}
              >
                {subTitle}
              </Typography>
              {loading && subTitle && (
                <Skeleton
                  variant="rectangular"
                  width={150}
                  height={22}
                  sx={{ borderRadius: 2, mt: 1, mb: 1 }}
                />
              )}

              {!loading &&
                (typeof label === "string" ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary", wordBreak: "break-word" }}
                  >
                    {label}
                  </Typography>
                ) : (
                  label
                ))}
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Scrollbar>
  );
};

export default MlCardList;

import { Icon } from "@iconify/react";
import bxsTagAlt from "@iconify/icons-bx/bxs-tag-alt";
import applicationVariableOutline from "@iconify/icons-mdi/application-variable-outline";
// material
import { styled } from "@mui/material/styles";
import { Paper, Stack, Typography } from "@mui/material";
// @types
import { RootState, useSelector } from "@/redux/store";
//
import {
  stepTypeColorMapper,
  kubeflowStepTypeColorMapper,
} from "../StepPopover";
import { SchemaNodeEntity } from "../../graph/slices/slice";

// ----------------------------------------------------------------------

const RowStyle = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

const RowIconStyle = styled(Icon)(({ theme }) => ({
  width: 16,
  height: 16,
  marginRight: theme.spacing(1.2),
  color: theme.palette.text.secondary,
}));

const RowTextStyle = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  maxWidth: "calc(100% - 30px)",
  wordBreak: "break-all",
  color: theme.palette.text.primary,
  ...theme.typography.body2,
}));

export default function EdgeNodeCard({
  nodeId,
  property,
}: {
  nodeId: string;
  property: string;
}) {
  const entity = useSelector((state: RootState) => {
    let entity: SchemaNodeEntity | undefined =
      state.schema.schema.pipelineSteps.find((entity) => {
        return entity.id === nodeId;
      });
    return entity ? entity : null;
  });

  const { pipelineType } = useSelector((state: RootState) => state.schema);

  const commonStepColorMap =
    pipelineType === "Kubeflow"
      ? kubeflowStepTypeColorMapper
      : stepTypeColorMapper;

  return (
    <>
      {entity && (
        <Paper
          sx={{
            mx: 2,
            p: 1.5,
            borderRadius: 1.5,
            bgcolor: "background.neutral",
          }}
        >
          <Stack spacing={1.5}>
            <Typography
              variant="subtitle2"
              sx={{ fontSize: "13px", color: "text.secondary" }}
            >
              Step
            </Typography>
            <RowStyle>
              <RowIconStyle
                icon={bxsTagAlt}
                sx={{ color: commonStepColorMap[entity.stepType] }}
              />
              <RowTextStyle>{entity.name}</RowTextStyle>
            </RowStyle>
            <Typography
              variant="subtitle2"
              sx={{ fontSize: "13px", color: "text.secondary" }}
            >
              Variable
            </Typography>
            <RowStyle>
              <RowIconStyle icon={applicationVariableOutline} />
              <RowTextStyle>{property}</RowTextStyle>
            </RowStyle>
          </Stack>
        </Paper>
      )}
    </>
  );
}

import moment from "moment";
import { format, getTime, formatDistanceToNow } from "date-fns";
// import zhCN from 'date-fns/locale/zh-CN'

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || "dd MMM yyyy";

  return date ? format(new Date(date), fm) : "";
}

export function fDateTime(date: Date | string | number) {
  // return format(new Date(date), 'dd MMM yyyy p', { locale: zhCN });
  return format(new Date(date), "dd MMM yyyy p");
}

export function fDateTime2(date: InputValue) {
  return date ? format(new Date(date), "yyyy-MM-dd HH:mm:ss") : "";
}

export function fDateTime3(date: number) {
  return date ? moment.unix(date).format("YYYY-MM-DD HH:mm") : "";
}

export function fDateTime4(date: number) {
  return date ? moment.unix(date).format("YYYY-MM-DD HH:mm:ss") : "";
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export const fDuration = (duration: number) => {
  const days = moment.duration(duration, "seconds").days();
  const hrs = moment.duration(duration, "seconds").hours();
  const mins = moment.duration(duration, "seconds").minutes();
  const secs = moment.duration(duration, "seconds").seconds();

  let result = "";
  if (days > 0) result += ` ${days}d`;
  if (hrs > 0) result += ` ${hrs}h`;
  if (mins > 0) result += ` ${mins}m`;
  if (secs > 0) result += ` ${secs}s`;

  if (result === "") return "0";

  return result.trim();
};

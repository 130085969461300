import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import { useDispatch } from "react-redux";
// material
import { Paper, Stack, IconButton } from "@mui/material";
//
import {
  updateTrainingHyperparameters,
  deleteTrainingHyperparameters,
} from "../../../graph/slices/slice";
import { PipelineTextfield } from "../../components/PipelineComponents";
import { Hyperparameter } from "@/@types/project/mlPipeline/SageMaker/pipeline-training";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";

// ----------------------------------------------------------------------

export default function TrainingHyperparameter({
  id,
  data,
}: {
  id: number;
  data: Hyperparameter;
}) {
  const dispatch = useDispatch();

  const ParamSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    // value: Yup.string().required('Value is required')
  });

  const formik = useFormik<Hyperparameter>({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values, { setErrors }) => {
      try {
        dispatch(
          updateTrainingHyperparameters({
            id: id,
            data: values,
          })
        );
      } catch (error) {
        // setErrors(error.code);
      }
    },
  });

  const { handleSubmit } = formik;

  return (
    <>
      <Paper
        sx={{
          p: 1.5,
          mx: 2,
          mb: 1,
          mt: 0,
          borderRadius: 1.5,
          bgcolor: "background.neutral",
        }}
      >
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onChange={handleSubmit}>
            <Stack spacing={2}>
              <PipelineTextfield
                label={"Name"}
                field={"name"}
                placeholder={"Name"}
                formik={formik}
              />

              <Stack direction="row" spacing={-0.2} alignItems="center">
                <PipelineParameterEntry
                  label={"Value"}
                  field={"value"}
                  fieldUseParam={"useParam"}
                  formik={formik}
                />
                <IconButton
                  size="small"
                  onClick={() => {
                    dispatch(deleteTrainingHyperparameters(id));
                  }}
                >
                  <Icon icon={trash2Fill} width={18} height={18} />
                </IconButton>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </Paper>
    </>
  );
}

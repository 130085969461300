// material
import { Divider, Stack } from "@mui/material";
//
import TopToolbar from "./TopToolbar";
import GraphEditor from "../graph/schema/components/GraphEditor";

// ----------------------------------------------------------------------

export default function PipelineCanvas({ isEdit }: { isEdit: boolean }) {
  return (
    <Stack sx={{ flexGrow: 1, minWidth: "1px" }} className="pipeline-space">
      <TopToolbar disabled={isEdit} isEdit={false} />
      <Divider />

      <GraphEditor
        containerName="pipeline-space"
        onChangeWidth={(x: number) => {
          return x + 0;
        }}
        onChangeHeight={(x: number) => {
          return x - 54;
        }}
        isEdit={isEdit}
      />
    </Stack>
  );
}

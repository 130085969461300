import { useTheme } from "@mui/material/styles";
import {
  Box,
  Stack,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import EmptyContent from "@/components/project/EmptyContent";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

type Props = {
  selectedStepName: string;
  onSetSelectedStepName: React.Dispatch<React.SetStateAction<string>>;
};

export default function ExecutionStepSelector({
  selectedStepName,
  onSetSelectedStepName,
}: Props) {
  const theme = useTheme();

  const { executionSteps: stepList } = useSelector(
    (state: RootState) => state.execution.executionGraph
  );
  const isListEmpty = stepList.length === 0;

  const prefix = process.env.NODE_ENV === "development" ? "" : "/ui";

  return (
    <Stack
      alignItems={"center"}
      direction={"row"}
      spacing={1.5}
      sx={{ ml: 2, mb: 1.5 }}
    >
      <FormControl
        fullWidth
        margin="normal"
        size="small"
        sx={{ width: "300px" }}
      >
        <InputLabel>Select Step</InputLabel>
        <Select
          value={selectedStepName}
          onChange={(event) => {
            onSetSelectedStepName(event.target.value);
          }}
          label="Select Step"
          displayEmpty
          sx={{
            color: selectedStepName === "" ? "text.disabled" : "text.primary",
            textOverflow: "ellipsis",
            typography: theme.typography.body2,
          }}
        >
          {stepList.map((step) => (
            <MenuItem
              key={step.id}
              value={step.name}
              sx={{
                mx: 0.5,
                mb: 0.5,
                px: 1.5,
                borderRadius: 0.75,
                typography: "body2",
              }}
            >
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Box
                  component="img"
                  src={`${prefix}/static/icons/ic_status_${step.status.toLowerCase()}.svg`}
                  sx={{ width: 18, height: 18 }}
                />

                <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  {step.name}
                </div>
              </Stack>
            </MenuItem>
          ))}

          {/* Body for no step */}
          {isListEmpty && (
            <EmptyContent
              title={"No step"}
              height={100}
              sx={{
                color: "text.disabled",
                py: 4,
                "& span.MuiBox-root": { height: 120 },
              }}
            />
          )}
        </Select>
      </FormControl>
    </Stack>
  );
}

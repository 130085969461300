import { Box, Stack, useTheme } from "@mui/material";

const AppScenarioLabel = ({ children = "" }) => {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";

  const colorList = [
    "rgba(255, 239, 90, 0.5)",
    "rgba(158, 134, 255, 0.5)",
    "rgba(255, 193, 7, 0.5)",
    "rgba(45, 153, 255, 0.5)",
    "rgba(164, 247, 204, 0.5)",
  ];

  // hash value by label
  const hashString = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0;
    }
    return hash;
  };

  const colorIndex = Math.abs(hashString(children)) % colorList.length;
  const bgcolor = colorList[colorIndex];

  return children && typeof children === "string" ? (
    <Stack
      direction="row"
      justifyContent="start"
      alignItems="center"
      whiteSpace="nowrap"
    >
      <Box
        sx={{
          padding: theme.spacing(0, 1),
          color: isLight ? "#000" : "#fff",
          bgcolor,
          height: 22,
          minWidth: 22,
          borderRadius: "6px",
        }}
      >
        {children}
      </Box>
    </Stack>
  ) : (
    <>-</>
  );
};

export default AppScenarioLabel;

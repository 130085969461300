import { createSlice } from "@reduxjs/toolkit";
import { addThunkReducers } from "../../utils";
import { IAppGalleryState } from "@/@types/project/model/appGallery";
import {
  getAppGalleryList,
  getAppGalleryDetail,
  getAppVersionList,
  getTagList,
  getRelTagAppList,
} from "./thunks";

const initialState: IAppGalleryState = {
  mlAppGalleryList: {
    loading: false,
    data: {},
    error: null,
  },
  mlAppDescribeData: {
    loading: false,
    data: {},
    error: null,
  },
  mlAppVersionDatas: {
    loading: false,
    data: {},
    error: null,
  },
  tagDatas: {
    loading: false,
    data: {},
    error: null,
  },
  appTagDatas: {
    loading: false,
    data: {},
    error: null,
  },
};

const slice = createSlice({
  name: "appGallery",
  initialState,
  reducers: {
    setAppGalleryData(state, action) {
      Object.assign(state, {
        [action.payload]: {
          loading: false,
          data: {},
          error: null,
        },
      });
    },
  },
  extraReducers: (builder) => {
    addThunkReducers(builder, [
      { action: getAppGalleryList, name: "mlAppGalleryList" },
      { action: getAppGalleryDetail, name: "mlAppDescribeData" },
      { action: getAppVersionList, name: "mlAppVersionDatas" },
      { action: getTagList, name: "tagDatas" },
      { action: getRelTagAppList, name: "appTagDatas" },
    ]);
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setAppGalleryData } = slice.actions;

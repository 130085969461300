import * as Yup from "yup";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Stack, Collapse, Typography, IconButton } from "@mui/material";
//
import {
  updateProcessingProcessor,
  updateProcessingEnvVars,
} from "../../../graph/slices/slice";
import {
  ProcessingStep,
  ProcessingProcessorStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-processing";
import ProcessingProcessorEnvVar from "./ProcessingProcessorEnvVar";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";
// import { PipelineSelect } from "../../components/PipelineComponents";
import { CollapseButtonStyle } from "../../SettingSidebar";
// import { processorTypes, processorVerions } from "../helper-functions";

// ----------------------------------------------------------------------

type Props = {
  currentStep: ProcessingStep | null;
};

export default function ProcessingProcessor({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [showProcessor, setShowProcessorr] = useState(false);

  const ParamSchema = Yup.object().shape({
    instanceType: Yup.string().required("Instance type is required"),
    instanceCount: Yup.string().required("Instance count is required"),
    volumeSizeInGb: Yup.string().required("Volume size is required"),
  });

  const formik = useFormik<ProcessingProcessorStruct>({
    initialValues: {
      processorType: currentStep?.processor.processorType || "SKLearnProcessor",
      processorTypeUseParam:
        currentStep?.processor.processorTypeUseParam || false,
      frameworkVersion: currentStep?.processor.frameworkVersion || "0.23-1",
      instanceType: currentStep?.processor.instanceType || "ml.c4.xlarge",
      instanceTypeUseParam:
        currentStep?.processor.instanceTypeUseParam || false,
      instanceCount: currentStep?.processor.instanceCount || "1",
      instanceCountUseParam:
        currentStep?.processor.instanceCountUseParam || false,
      volumeSizeInGb: currentStep?.processor.volumeSizeInGb || "30",
      volumeSizeInGbUseParam:
        currentStep?.processor.volumeSizeInGbUseParam || false,
    },
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values) => {
      try {
        dispatch(updateProcessingProcessor(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const environmentVars = currentStep?.processor.environmentVars;

  const envVarNum = environmentVars !== undefined ? environmentVars.length : 0;

  const { handleSubmit } = formik;

  const handleAddEnvVariable = () => {
    dispatch(
      updateProcessingEnvVars({
        name: "",
        value: "",
      })
    );
  };

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setShowProcessorr((prev) => !prev)}
        endIcon={
          <Icon
            icon={showProcessor ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
      >
        Processor
      </CollapseButtonStyle>

      <Collapse in={showProcessor} sx={{ mx: 2 }}>
        <Stack spacing={1} sx={{ my: 1.2, mb: 1.5 }}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onChange={handleSubmit}>
              <Stack spacing={2} sx={{ mb: 0.5 }}>
                {/* <PipelineSelect
                  label={"Processor type"}
                  field={"processorType"}
                  placeholder={"Select a processor"}
                  options={processorTypes}
                  getFieldProps={getFieldProps}
                />

                <PipelineSelect
                  label={"Framework version"}
                  field={"frameworkVersion"}
                  placeholder={"Select a framework version"}
                  options={processorVerions}
                  getFieldProps={getFieldProps}
                /> */}

                <PipelineParameterEntry
                  label={"Processor"}
                  field={"processorType"}
                  fieldUseParam={"processorTypeUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Instance type"}
                  field={"instanceType"}
                  fieldUseParam={"instanceTypeUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Instance count"}
                  field={"instanceCount"}
                  fieldUseParam={"instanceCountUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Volume size (GB)"}
                  field={"volumeSizeInGb"}
                  fieldUseParam={"volumeSizeInGbUseParam"}
                  formik={formik}
                />
              </Stack>
            </Form>
          </FormikProvider>

          <Stack spacing={1.5} sx={{ mx: 2, my: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", fontWeight: 600 }}
              >
                Environment variables {envVarNum > 0 && `(${envVarNum})`}
              </Typography>
              <IconButton size="small" onClick={handleAddEnvVariable}>
                <Icon icon={plusFill} width={16} height={16} />
              </IconButton>
            </Stack>

            {environmentVars !== undefined &&
              environmentVars.map((envVar, index) => (
                <ProcessingProcessorEnvVar
                  key={index}
                  id={index}
                  name={envVar.name}
                  value={envVar.value}
                />
              ))}
          </Stack>
        </Stack>
      </Collapse>
    </>
  );
}

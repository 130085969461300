import Page from "@/components/project/Page";
import Scrollbar from "@/components/project/Scrollbar";
import MlCard from "@/components/project/mlComponents/MlCard";
import MlRadio from "@/components/project/mlComponents/MlRadio";
import { PATH_DASHBOARD } from "@/routes/paths";
import { Box, Button, Stack } from "@mui/material";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import PipelineSettings from "./components/PipelineCreateOrEdit/Settings";
import PipelineTemplate from "./components/PipelineCreateOrEdit/Template";
import { RootState, dispatch } from "@/redux/store";
import { clearSchema } from "./graph/slices/slice";
import { LoadingButton } from "@mui/lab";
import {
  addPipeline,
  addPipelineTags,
  addRelPipelineVolumeData,
  delPipelineTags,
  getPipelineDetailData,
  getPipelineTableData,
  getPipelineTags,
  getPipelineVolumeTableData,
  updatePipeline,
} from "@/redux/project/automation/thunks";
import { isEmpty } from "lodash";
import {
  getPipelineTemplateTableData,
  getPipelineTemplateVersionData,
} from "@/redux/project/template/thunks";
import { getTags } from "@/redux/project/administration/thunks";
import { useSelector } from "react-redux";

const radioList = [
  {
    id: 1,
    value: "Kubeflow",
    title: "Kubeflow",
    description: "Kubeflow Pipeline",
  },
  {
    id: 2,
    value: "AWS SageMaker",
    title: "AWS SageMaker",
    description: "Amazon SageMaker Pipeline",
    disabled: true,
  },
];

export interface IPipelineInitialValuesTypes {
  type: "Kubeflow" | "AWS SageMaker";
  name: string;
  volume: string;
  template: Record<string, any>;
  scenario: string;
  tags: Array<string>;
  description: string;
}

const PipelineCreateOrEdit = () => {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const { mlPipelineId } = useParams();

  const isEdit = pathname.includes("edit");

  // record the initial rel pipeline tags
  const allExistTags = useRef<any[]>([]);

  const {
    pipelineTableList: { data: allData },
    pipelineDetail: { data },
  } = useSelector((state: RootState) => state.automation);

  const initialValues: IPipelineInitialValuesTypes = {
    type: "Kubeflow",
    name: "",
    volume: "",
    scenario: "",
    tags: [],
    description: "",
    template: {},
  };

  const validationSchema = Yup.object({
    type: Yup.string().required("Type is required"),
    name: Yup.string()
      .required("Name is required")
      .test("name-exist", "Pipeline Name existed", (value) => {
        if (!isEdit) {
          const isExisted =
            allData?.items?.findIndex((item) => item.name === value) > -1;
          return !isExisted;
        } else {
          return true;
        }
      }),
    volume: Yup.string().when("type", {
      is: "AWS SageMaker",
      then: Yup.string().required("Pipeline Volume is required"),
    }),
    // template: '',
  });

  const getLinks = () => {
    return [
      {
        name: "Automation",
      },
      {
        name: "Pipeline",
        href: PATH_DASHBOARD.automation.mlPipeline,
      },
      ...(isEdit
        ? [
            {
              name: data?.name,
              href: `${PATH_DASHBOARD.automation.mlPipeline}/${mlPipelineId}`,
            },
          ]
        : []),
      {
        name: `${isEdit ? "Edit" : "Create"} Pipeline`,
      },
    ];
  };

  const handleAddSubmit = async (values: IPipelineInitialValuesTypes) => {
    // get the notebook_template_version_id
    const { items } = await dispatch(
      getPipelineTemplateVersionData({
        params: {
          mlpipeline_template_id: values.template.id,
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    // get the lastest pipeline template version sorted by updated_by
    const mostRecent = items.reduce((prev, cur) => {
      return new Date(cur.updated_at) > new Date(prev.updated_at) ? cur : prev;
    });

    // create a pipeline
    const { id } = await dispatch(
      addPipeline({
        params: {
          name: values.name,
          mlpipeline_type: values.type,
          description: values.description,
          scenario: values.scenario,
          mlpipeline_template_version_id: mostRecent.id,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    // after pipeline created
    // 1. get mlpipeline_id to add tags
    if (!isEmpty(values.tags)) {
      await Promise.all(
        values.tags.map((item) =>
          dispatch(
            addPipelineTags({
              params: {
                mlpipeline_id: id,
                tag_id: item,
              },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap()
        )
      );
    }

    // 2. get mlpipeline_id to add volume
    await dispatch(
      addRelPipelineVolumeData({
        params: {
          mlpipeline_id: id,
          mlpipeline_volume_id: values.volume,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  };

  const updatePipelineTags = async (tags) => {
    const deleteIds: string[] = [];
    const addIds: string[] = [];

    tags.forEach((item) => {
      if (allExistTags.current.findIndex((i) => i.tag_id === item) === -1) {
        addIds.push(item);
      }
    });
    allExistTags.current.forEach((item) => {
      if (!tags.includes(item.tag_id)) {
        deleteIds.push(item.tag_id);
      }
    });

    // delete
    await Promise.all(
      deleteIds.map((item) =>
        dispatch(
          delPipelineTags({
            id: allExistTags.current.find((i) => i.tag_id === item).id,
            alertCallback: enqueueSnackbar,
            pathname,
          })
        ).unwrap()
      )
    );

    // add
    await Promise.all(
      addIds.map((item) =>
        dispatch(
          addPipelineTags({
            params: {
              mlpipeline_id: mlPipelineId!,
              tag_id: item,
            },
            alertCallback: enqueueSnackbar,
            pathname,
          })
        ).unwrap()
      )
    );
  };

  const handleEditSubmit = async (values: IPipelineInitialValuesTypes) => {
    // update rel_pipeline_tags
    await updatePipelineTags(values.tags);

    await dispatch(
      updatePipeline({
        id: mlPipelineId!,
        params: {
          name: values.name,
          mlpipeline_type: values.type,
          description: values.description,
          scenario: values.scenario,
          // mlpipeline_template_version_id: '4dko3kmo', // only can update by code now, template version (commit_id changed, actually add a new template_version), must create a new pipeline to apply it
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      try {
        if (!isEdit) {
          await handleAddSubmit(values);
          enqueueSnackbar("Create Success", { variant: "success" });
        } else {
          await handleEditSubmit(values);
          enqueueSnackbar("Edit Success", { variant: "success" });
        }
        actions.setSubmitting(false);

        navigate(PATH_DASHBOARD.automation.mlPipeline);
      } catch (e) {
        console.log("error-in-submit", e);
        actions.setSubmitting(false);
      }
    },
  });

  const { values, isSubmitting, setFieldValue } = formik;

  useEffect(() => {
    if (isEdit) {
      dispatch(
        getPipelineDetailData({
          id: mlPipelineId!,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      );

      // get selected tags
      dispatch(
        getPipelineTags({
          params: {
            mlpipeline_id: mlPipelineId!,
            page_num: 1,
            page_size: 9999,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((res) => {
          setFieldValue(
            "tags",
            res?.items?.map((item) => item.tag_id)
          );

          allExistTags.current = res?.items;
        });
    } else {
      dispatch(
        getPipelineTableData({
          params: {
            page_num: 1,
            page_size: 9999,
          },
          isAll: true,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      );

      // create
      dispatch(
        getPipelineTemplateTableData({
          params: {
            page_num: 1,
            page_size: 9999,
          },
          hasDescription: true,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((res) => {
          setFieldValue("template", res?.items?.[0] || {});
        });

      setFieldValue("name", `omniml-pipeline-${String(new Date().getTime())}`);
    }

    // get all tags
    dispatch(
      getTags({
        params: {
          is_active: 1,
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );

    // get volumes
    dispatch(
      getPipelineVolumeTableData({
        params: {
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  }, []);

  return (
    <Page
      title={`${isEdit ? "Edit" : "Create"} | OmniML`}
      heading={`${isEdit ? "Edit" : "Create"} Pipeline`}
      links={getLinks()}
    >
      <FormikProvider value={formik}>
        <Form>
          {!isEdit && (
            <MlCard title="Pipeline Type">
              <Scrollbar>
                <Box sx={{ width: "800px" }}>
                  <MlRadio
                    curValue={values.type}
                    setCurValue={(pType) => {
                      // clear the template
                      setFieldValue("template", {});
                      dispatch(clearSchema(true));

                      setFieldValue("type", pType);
                    }}
                    list={radioList}
                  />
                </Box>
              </Scrollbar>
            </MlCard>
          )}

          <MlCard title="Pipeline Settings">
            <PipelineSettings formik={formik} />
          </MlCard>

          {!isEdit && (
            <>
              <MlCard title="Pipeline Template">
                <PipelineTemplate formik={formik} />
              </MlCard>
            </>
          )}

          <Stack direction="row" justifyContent="center" spacing={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              color="primary"
              sx={{ width: "200px", color: "background.paper" }}
            >
              Save
            </LoadingButton>
            <Button
              variant="outlined"
              color="inherit"
              sx={{ width: "200px", color: "text.secondary" }}
              onClick={() => navigate(PATH_DASHBOARD.automation.mlPipeline)}
            >
              Cancel
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Page>
  );
};

export default PipelineCreateOrEdit;

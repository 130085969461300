import { useState } from "react";
import Page from "@/components/project/Page";
import { Box, Stack } from "@mui/material";
import DashbordStatistic from "./components/modelPackageList/DashbordStatistic";
import ModelPackageTable from "./components/modelPackageList/ModelPackageTable";
import ModelPackageDetailDrawer from "./components/modelPackageList/ModelPackageDetailDrawer";

export default function ModelPackage() {
  const [openModelPackageDetailDrawer, setOpenModelPackageDetailDrawer] =
    useState<boolean>(false);
  const [modelPackageDetail, setModelPackageDetail] = useState<
    Record<string, any>
  >({});

  const getLinks = () => {
    return [
      {
        name: "Data Asset",
      },
      {
        name: "Model Package",
      },
    ];
  };

  return (
    <Page heading="Model Package" links={getLinks()}>
      <Box sx={{ mb: 2 }}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <DashbordStatistic />
        </Stack>
      </Box>
      <ModelPackageTable
        setDrawerOpenAndSetData={(rowData) => {
          setOpenModelPackageDetailDrawer(true);
          setModelPackageDetail(rowData);
        }}
      />
      {openModelPackageDetailDrawer && (
        <ModelPackageDetailDrawer
          isOpen={openModelPackageDetailDrawer}
          onClose={() => {
            setOpenModelPackageDetailDrawer(false);
          }}
          detailData={modelPackageDetail}
          clearDetailData={() => {
            setModelPackageDetail({});
          }}
        />
      )}
    </Page>
  );
}

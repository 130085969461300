import { checkCode, handleErrorMessage } from "@/utils/project/request";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { MlParams } from "@/@types/project/global";
import {
  createConnectionService,
  createCredentialService,
  createRelConnCredentialService,
  delConnectionService,
  delCredentialService,
  getConnectionDetailService,
  getConnectionListService,
  getCredentialDetailService,
  getCredentialListService,
  getRelConnCredentialListService,
  updateConnectionService,
  updateCredentialService,
} from "@/services/connection";

interface IGetConnectionParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
  };
}

// 1.connection
export const getConnectionTableData = createAsyncThunk(
  "common/getConnectionTableData",
  async (
    { params, pathname, alertCallback }: IGetConnectionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getConnectionListService(params);
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching connection data failed");
    }
  }
);

interface IGetConnectionDetail extends MlParams {
  id: string;
}

export const getConnectionDetail = createAsyncThunk(
  "common/getConnectionDetail",
  async (
    { id, pathname, alertCallback }: IGetConnectionDetail,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getConnectionDetailService(id);
      const res = checkCode(data, pathname) as Object & {
        connection_properties: {
          [key: string]: string;
        };
      };
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching connection detail data failed");
    }
  }
);

interface IAddConnection extends MlParams {
  params: {
    external_id: string;
    name: string;
    connection_type: string;
    connection_state: string;
    connection_family: string;
    connection_scheme: string;
    connection_endpoint: string;
    connection_port: string;
    connection_properties: Record<any, any>;
    description?: string;
  };
}

export const addConnection = createAsyncThunk(
  "common/addConnection",
  async (
    { params, pathname, alertCallback }: IAddConnection,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createConnectionService(params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Create connection failed");
    }
  }
);

interface IUpdateConnection extends MlParams {
  id: string;
  params: {
    external_id: string;
    name: string;
    connection_type: string;
    connection_state: string;
    connection_scheme: string;
    connection_endpoint: string;
    connection_port: string;
    connection_properties: Record<any, any>;
    description?: string;
  };
}

export const updateConnection = createAsyncThunk(
  "common/updateConnection",
  async (
    { id, params, pathname, alertCallback }: IUpdateConnection,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await updateConnectionService(id, params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Update connection failed");
    }
  }
);

interface IDelConnection extends MlParams {
  id: string;
}

export const deleteConnection = createAsyncThunk(
  "common/deleteConnection",
  async (
    { id, pathname, alertCallback }: IDelConnection,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delConnectionService(id);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete connection failed");
    }
  }
);

interface IGetCredentialParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
  };
}

// 2.credential
export const getCredentialTableData = createAsyncThunk(
  "common/getCredentialTableData",
  async (
    { params, pathname, alertCallback }: IGetCredentialParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getCredentialListService(params);
      const res = checkCode(data, pathname) as Object;

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching credential data failed");
    }
  }
);

interface IGetCredentialDetail extends MlParams {
  id: string;
}

export const getCredentialDetail = createAsyncThunk(
  "common/getCredentialDetail",
  async (
    { id, pathname, alertCallback }: IGetCredentialDetail,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getCredentialDetailService(id);
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching credential detail data failed");
    }
  }
);

interface IAddCredential extends MlParams {
  params: {
    name: string;
    description: string;
    credential_type: string;
    credential_state: string;
    credential_family: string;
    credential_properties: {};
    resource_location: string;
  };
}

export const addCredential = createAsyncThunk(
  "common/addCredential",
  async (
    { params, pathname, alertCallback }: IAddCredential,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createCredentialService(params);
      const res = checkCode(data, pathname) as { id: string };
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Create credential failed");
    }
  }
);

interface IUpdateCredential extends MlParams {
  id: string;
  params: {
    name: string;
    description: string;
    credential_type: string;
    credential_state: string;
    credential_family: string;
    credential_properties: {};
    resource_location?: string;
  };
}

export const updateCredential = createAsyncThunk(
  "common/updateCredential",
  async (
    { id, params, pathname, alertCallback }: IUpdateCredential,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await updateCredentialService(id, params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Update credential failed");
    }
  }
);

interface IDelCredential extends MlParams {
  id: string;
}

export const deleteCredential = createAsyncThunk(
  "common/deleteCredential",
  async (
    { id, pathname, alertCallback }: IDelCredential,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delCredentialService(id);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete credential failed");
    }
  }
);

interface IGetRelConnCredentialParams extends MlParams {
  params: {
    connection_id?: string;
    credential_id?: string;
    page_num: number;
    page_size: number;
  };
}

// 3.rel_connection_credential
export const getRelConnCredentialTableData = createAsyncThunk(
  "common/getRelConnCredentialTableData",
  async (
    { params, pathname, alertCallback }: IGetRelConnCredentialParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getRelConnCredentialListService(params);
      const res = checkCode(data, pathname) as { items: Record<any, any> };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching rel_connection_credential data failed");
    }
  }
);

interface IAddRelConnCredential extends MlParams {
  params: {
    connection_id: string;
    credential_id: string;
  };
}

export const addRelConnCredential = createAsyncThunk(
  "common/addRelConnCredential",
  async (
    { params, pathname, alertCallback }: IAddRelConnCredential,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createRelConnCredentialService(params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Create rel_connection_credential failed");
    }
  }
);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  InputAdornment,
  TablePagination,
  Stack,
  Button,
  Typography,
} from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MlCard from "@/components/project/mlComponents/MlCard";
import Scrollbar from "@/components/project/Scrollbar";
import { extractKeys } from "@/utils/project/utils";
import ProjectListTableActionCol from "./ProjectListTableActionCol";
import { useLocation, useNavigate, useParams } from "react-router";
import { RootState, dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import { getProjectTableData } from "@/redux/project/administration/thunks";
import { useSelector } from "react-redux";
import MlTableBodyContainer from "@/components/project/mlComponents/MlTableBodyContainer";
import Label from "@/components/project/Label";

interface Column {
  id: string;
  label?: string;
  width?: number;
  minWidth?: number;
}

interface OrgTableProps {
  state: any;
}

enum StatusEnum {
  started = "success",
  closed = "default",
  updated = "secondary",
  failed = "error",
}

const useStyles = makeStyles({
  searchContainer: {
    padding: 20,
    width: 360,
  },
});

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 150 },
  // { id: 'organization_id', label: 'Organization' },
  { id: "project_type", label: "Project Type" },
  // { id: 'leader', label: 'Leader' },
  { id: "updated_at", label: "Last Modified" },
  { id: "status", label: "Status" },
  { id: "", width: 150 },
];

const ProjectListTable: React.FC<OrgTableProps> = ({ state }) => {
  const { orgId } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const [searchText, setSearchText] = useState<string>("");
  const [page_num, setPageNum] = useState<number>(1);
  const [page_size, setPageSize] = useState<number>(10);

  const {
    isSuperAdmin,
    userDetail: { organization_id },
  } = useSelector((state: RootState) => state.common);

  const {
    projectTableList: { data, loading },
  } = useSelector((state: RootState) => state.administration);

  const {
    organizationDetailData: { data: orgDetail },
  } = useSelector((state: RootState) => state.administration);

  const {
    config: { govern },
  } = useSelector((state: RootState) => state.common);

  const isJustSuperAdmin = () => {
    return isSuperAdmin && !organization_id;
  };

  const nameExist = (name) => {
    if (name) {
      return {
        name,
      };
    }
    return {};
  };

  const fetchData = (params: {
    name?: string;
    page_num: number;
    page_size: number;
  }) => {
    return dispatch(
      getProjectTableData({
        params: {
          organization_id: orgId,
          ...params,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  };

  const handleRefresh = (isUpdate?: boolean) => {
    setSearchText("");
    setPageNum(1);
    setPageSize(10);

    fetchData({ page_num: 1, page_size: 10 }).then(() => {
      if (!isUpdate) {
        enqueueSnackbar("Refresh Success", { variant: "success" });
      }
    });
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNum(newPage + 1);
    fetchData({ ...nameExist(searchText), page_num: newPage + 1, page_size });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNum(1);

    fetchData({
      ...nameExist(searchText),
      page_num: 1,
      page_size: parseInt(event.target.value, 10),
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      fetchData({ ...nameExist(searchText), page_num: 1, page_size: 10 }).then(
        () => {
          enqueueSnackbar("Search Success", { variant: "success" });
        }
      );
    }
  };

  const renderCell = (row, column) => {
    switch (column.id) {
      case "name":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="subtitle2">{row[column.id]}</Typography>
          </TableCell>
        );
      case "project_type":
        const flag = row[column.id];
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2">
              {govern?.project_type?.[flag] || "-"}
            </Typography>
          </TableCell>
        );
      case "status":
        const status = row[column.id];
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width", "maxWidth") }}
          >
            {status ? <Label color={StatusEnum[status]}>{status}</Label> : "-"}
          </TableCell>
        );
      case "":
        return (
          <TableCell
            align="right"
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <ProjectListTableActionCol
              row={row}
              handleRefresh={handleRefresh}
            />
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {row[column.id] || "-"}
            </Typography>
          </TableCell>
        );
    }
  };

  useEffect(() => {
    dispatch(
      getProjectTableData({
        params: {
          organization_id: orgId,
          page_num,
          page_size,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  }, []);

  return (
    <MlCard
      title="Projects"
      extraJsx={
        !isJustSuperAdmin() && (
          <Button
            disabled={!(organization_id && orgDetail.status === "started")}
            variant="contained"
            sx={{ width: 120 }}
            onClick={() =>
              navigate("projectCreate", {
                state: { mode: "create", curOrganization: state },
              })
            }
          >
            Add Project
          </Button>
        )
      }
      hasBorderBottom
      hasPaddingX={false}
    >
      <Stack direction="row" justifyContent="space-between">
        <Box className={classes.searchContainer}>
          <TextField
            id="search"
            label="Search By Name"
            variant="outlined"
            size="small"
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            onKeyDown={handleKeyDown}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => {
                      fetchData({
                        ...nameExist(searchText),
                        page_num: 1,
                        page_size: 5,
                      }).then(() => {
                        enqueueSnackbar("Search Success", {
                          variant: "success",
                        });
                      });
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Box>
        <Stack direction="column" justifyContent="center" sx={{ pr: "20px" }}>
          <IconButton onClick={() => handleRefresh()}>
            <RefreshOutlinedIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <MlTableBodyContainer loading={loading} data={data?.items}>
                {data?.items?.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((column) => renderCell(row, column))}
                  </TableRow>
                ))}
              </MlTableBodyContainer>
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25]}
        component="div"
        count={data?.total || 0}
        rowsPerPage={page_size}
        page={page_num - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </MlCard>
  );
};

export default ProjectListTable;

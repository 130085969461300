import { TableCell, TableRow } from "@mui/material";
import { ReactNode } from "react";
import TableSkeleton from "../TableSkeleton";
import EmptyTable from "../EmptyTable";

interface IProps {
  loading: boolean;
  children: ReactNode;
  data: Array<Record<string, any>>;
  noDataMainTitle?: string;
  noDataContent?: string;
}

const MlTableBodyContainer = ({
  loading,
  children,
  data,
  noDataMainTitle,
  noDataContent,
}: IProps) => {
  return (
    <>
      {loading && (
        <TableRow>
          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
            <TableSkeleton />
          </TableCell>
        </TableRow>
      )}

      {!loading && (data || []).length === 0 && (
        <TableRow>
          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
            <EmptyTable
              mainTitle={noDataMainTitle || "No Data"}
              content={
                noDataContent ||
                "There is no data here, please create some items first"
              }
            />
          </TableCell>
        </TableRow>
      )}
      {!loading && (data || []).length > 0 && children}
    </>
  );
};

export default MlTableBodyContainer;

import { IResponse } from "@/@types/project/global";
import { TOKEN_FLAG } from "@/config";
import { setPermissDeniedObj } from "@/redux/project/administration/slice";
import { dispatch } from "@/redux/store";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";

const { config } = JSON.parse(localStorage.getItem("redux-common") || "{}");
const { common: { BASE_URL = "", SSO_LOGOUT_URL = "" } = {} } = JSON.parse(
  config || "{}"
);

const http = axios.create({
  baseURL: BASE_URL,
  // timeout: 5000,
});

// request
http.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token: string | null = localStorage.getItem(TOKEN_FLAG);
    if (token) {
      config.headers = config.headers || {};
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  }
);

// OK = 20000 => only this code is success
// DB_ERROR = 40001
// PARAM_ERROR = 41001
// AUTHORIZATION_ERROR = 42001
// UNKNOWN_ERROR = 43001

// {
//   'code': code,
//   'msg': msg or CODE_MSG_MAP.get(code),
//   'result': data or {}
// }

// response
http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      localStorage.clear();
      window.location.replace(SSO_LOGOUT_URL);
    }
    return Promise.reject(error);
  }
);

/* const source: CancelTokenSource = axios.CancelToken.source(); 
source.cancel("Request canceled by the user."); */

// transmit the source.token to cancelToken, then call source.cacel(), you can cancel the request

const requestUrlPrefix = "/api/v1";
const makeRequest = (method, url, params, data, cancelToken?) => {
  const config = {
    method,
    url: `${requestUrlPrefix}${url}`,
    data,
    params,
    ...(cancelToken ? { cancelToken } : {}),
  };

  return http(config);
};

const request = {
  get(url, params?, cancelToken?): Promise<AxiosResponse> {
    return makeRequest("get", url, params, undefined, cancelToken);
  },

  post(url, data?, params?, cancelToken?): Promise<AxiosResponse> {
    return makeRequest("post", url, params, data, cancelToken);
  },

  put(url, data?, params?, cancelToken?): Promise<AxiosResponse> {
    return makeRequest("put", url, params, data, cancelToken);
  },

  patch(url, data?, params?, cancelToken?): Promise<AxiosResponse> {
    return makeRequest("patch", url, params, data, cancelToken);
  },

  delete(url, data?, params?, cancelToken?): Promise<AxiosResponse> {
    return makeRequest("delete", url, params, data, cancelToken);
  },
};

export default request;

export const checkCode = (
  { code, msg, result }: IResponse,
  pathname?: string
) => {
  // console.log(window.location.pathname); // intercept all request
  if (code === 20000) {
    return result;
  }

  // some global common reuquest don't need permissions isolate
  if (code === 42001 && pathname) {
    dispatch(setPermissDeniedObj(pathname));
    // return {};
  }

  throw new Error(`${code} ${msg || "There exists some errors"}`);
};

export const handleErrorMessage = (msg, alertCallback) => {
  if (msg?.split(" ")?.[0] !== "42001") {
    alertCallback(msg, { variant: "error" });
  }
};

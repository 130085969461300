/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik, FormikProvider, Form, Field } from "formik";
import {
  Button,
  TextField,
  Dialog,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { RootState, dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import { useLocation, useParams } from "react-router";
import { useSelector } from "react-redux";
import {
  addNotebookVersion,
  editNotebookVersion,
  getNotebookVersionDetail,
} from "@/redux/project/experiment/thunks";
import { setExperimentData } from "@/redux/project/experiment/slice";
import { LoadingButton } from "@mui/lab";

interface INotebookVersionCreateOrEditDialog {
  row: Record<any, any>;
  mode: "create" | "edit";
  isOpen: boolean;
  onClose: VoidFunction;
  handleRefresh: (a?: boolean) => void;
}

interface FormValues {
  name: string;
  description: string;
}

const initialValues: FormValues = {
  name: String(new Date().getTime()),
  description: "",
};

const validationSchema = yup.object({
  name: yup.string().required("name is required"),
  description: yup
    .string()
    .max(400, "Description cannot be longer than 400 characters"),
});

const NotebookVersionCreateOrEditDialog = ({
  row,
  mode,
  isOpen,
  onClose,
  handleRefresh,
}: INotebookVersionCreateOrEditDialog) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { notebookId } = useParams();

  const { notebookVersionDetail: data } = useSelector(
    (state: RootState) => state.experiment
  );

  const handleCreateSubmit = (values) => {
    return dispatch(
      addNotebookVersion({
        params: {
          name: values.name,
          notebook_id: notebookId!,
          description: values?.description || "",
          is_active: 1,
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();
  };

  const handleUpdateSubmit = (values) => {
    return dispatch(
      editNotebookVersion({
        id: row.id,
        params: {
          name: values.name,
          notebook_id: notebookId!,
          description: values?.description || "",
          is_active: 1,
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      try {
        if (mode === "edit") {
          await handleUpdateSubmit(values);
          enqueueSnackbar("Edit Success", { variant: "success" });
        } else {
          await handleCreateSubmit(values);
          enqueueSnackbar("Create Success", { variant: "success" });
        }
        actions.setSubmitting(false);

        handleRefresh(true);
        handleClose();
      } catch (e) {
        console.log("error-in-submit", e);
        actions.setSubmitting(false);
      }
    },
  });

  const { errors, touched, setValues, resetForm, isSubmitting } = formik;

  const handleClose = () => {
    onClose();
    resetForm();
    dispatch(setExperimentData("notebookVersionDetail"));
  };

  const handleInit = async () => {
    try {
      await dispatch(
        getNotebookVersionDetail({
          id: row.id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
    } catch (e) {}
  };

  useEffect(() => {
    if (isOpen && mode === "edit") {
      handleInit();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isEmpty(data) && mode === "edit") {
      setValues({
        name: data?.name || "",
        description: data?.description || "",
      });
    }
  }, [data]);

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown
      onClose={() => {
        handleClose();
      }}
      PaperProps={{
        style: { width: "960px", maxWidth: "none", padding: "20px 40px" },
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        {mode === "create" ? "New" : "Edit"} Version
      </Typography>
      <FormikProvider value={formik}>
        <Form>
          <Box sx={{ px: 2.5 }}>
            <Field
              as={TextField}
              name="name"
              disabled={mode === "edit"}
              size="small"
              label="Name"
              fullWidth
              margin="dense"
              helperText={touched.name && errors.name ? errors.name : " "}
              error={touched.name && Boolean(errors.name)}
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
            <Field
              as={TextField}
              name="description"
              size="small"
              multiline
              rows={4}
              label="Description (Optional)"
              fullWidth
              margin="dense"
              helperText={
                touched.description && errors.description
                  ? errors.description
                  : " "
              }
              error={touched.description && Boolean(errors.description)}
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
          </Box>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ my: 4 }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              color="primary"
              sx={{ width: "200px", color: "background.paper" }}
            >
              Save
            </LoadingButton>
            <Button
              type="button"
              color="inherit"
              variant="outlined"
              onClick={() => {
                resetForm();
                onClose();
              }}
              sx={{ width: "200px", color: "text.secondary" }}
            >
              Cancel
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default NotebookVersionCreateOrEditDialog;

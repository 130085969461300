import { useState } from "react";
import { Icon } from "@iconify/react";
import { Form, FormikProvider } from "formik";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { Stack, Collapse } from "@mui/material";
//
import { CollapseButtonStyle } from "../../SettingSidebar";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";

// ----------------------------------------------------------------------

type Props = {
  formik: any;
};

export default function ErrorMessage({ formik }: Props) {
  const [isCollapse, setCollapse] = useState(false);

  const { handleSubmit } = formik;

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setCollapse((prev) => !prev)}
        endIcon={
          <Icon
            icon={isCollapse ? arrowIosForwardFill : arrowIosDownwardFill}
            width={16}
            height={16}
          />
        }
      >
        Error message
      </CollapseButtonStyle>

      <Collapse in={!isCollapse} sx={{ mx: 2, mb: !isCollapse ? 1 : 0 }}>
        <Stack spacing={1} sx={{ my: 1 }}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onChange={handleSubmit}>
              <Stack spacing={2}>
                <PipelineParameterEntry
                  multiline
                  label={"Content"}
                  field={"errorMessage"}
                  fieldUseParam={"errorMessageUseParam"}
                  formik={formik}
                  placeholder={""}
                />
              </Stack>
            </Form>
          </FormikProvider>
        </Stack>
      </Collapse>
    </>
  );
}

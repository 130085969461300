import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
// material
import { Box, Button, Typography, Container } from "@mui/material";
// components
import SeverErrorIllustration from "@/assets/project/unexpectedPages/illustration_500";
import UnexpectedPage from "@/components/project/UnexpectedPage";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import {
  filterAdminSiderConfig,
  filterSiderbarConfigByPermissions,
  findMainPage,
} from "@/layouts/project/utils";
import sidebarConfig from "@/layouts/project/sidebar/SidebarConfig";

// ----------------------------------------------------------------------

const RootStyle = styled(UnexpectedPage)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
}));

// ----------------------------------------------------------------------

export default function Page500() {
  const {
    isAdmin,
    isNoneUser,
    userDetail: { permissions = [] } = {},
  } = useSelector((state: RootState) => state.common);

  return (
    <RootStyle title="500 Internal Server Error | OmniML">
      <Container>
        <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
          <Typography variant="h3" paragraph>
            500 Internal Server Error
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            There was an error, please try again later.
          </Typography>

          <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />

          <Button
            to={findMainPage(
              isAdmin,
              filterSiderbarConfigByPermissions(
                filterAdminSiderConfig(isAdmin, isNoneUser, sidebarConfig),
                permissions
              )
            )}
            size="large"
            variant="contained"
            component={RouterLink}
          >
            Go to Main
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { PATH_DASHBOARD } from "@/routes/paths";
import Page from "@/components/project/Page";
import { useLocation } from "react-router";
import { LoadingButton } from "@mui/lab";
import { Button, Menu, MenuItem, Stack, Typography, Box } from "@mui/material";
import useCollapseDrawer from "@/hooks/project/useCollapseDrawer";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import AppGalleryDetailCards from "./components/appGalleryDetail/AppGalleryDetailCards";
import DeployConfigVersionPanel from "./components/appGalleryDetail/DeployConfigVersionPanel";
import { dispatch, useSelector, RootState } from "@/redux/store";
import { useSnackbar } from "notistack";
import {
  getAppGalleryDetail,
  getAppVersionList,
  getRelTagAppList,
  getTagList,
  editAppVersion,
  editAppGallery,
  delRelTagApp,
  delImage,
} from "@/redux/project/mlService/appGallery/thunks";
import ConfirmDialog from "@/components/project/ConfirmDialog";

const MlAppGalleryDetail = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isCollapse, toggleIsCollapseByBol } = useCollapseDrawer();
  const { pathname } = useLocation();
  const { appId } = useParams();
  const [detailWidth, setDetailWidth] = useState<number>(0);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);
  const [openDeployConfigVersion, setOpenDeployConfigVersion] =
    useState<boolean>(false);
  const [selectedVersionInfo, setSelectedVersionInfo] = useState<
    Record<string, any>
  >({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {
    mlAppDescribeData: { data: appDetail },
    mlAppVersionDatas: {
      data: { items: versionData = [] },
      loading,
    },
    appTagDatas: {
      data: { items: appTagList = [] },
    },
  } = useSelector((state: RootState) => state.appGallery);
  const {
    userDetail: { permissions = [] },
  } = useSelector((state: RootState) => state.common);

  // const detailWidth = window.innerWidth - 72 - 24;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLinks = () => {
    return [
      {
        name: "ML Service",
      },
      {
        name: "App Gallery",
        href: PATH_DASHBOARD.mlService.appGallery,
      },
      {
        name: appDetail?.name || "",
      },
    ];
  };

  useEffect(() => {
    dispatch(
      getAppGalleryDetail({
        id: appId,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    dispatch(
      getAppVersionList({
        params: { page_num: 1, page_size: 9999, is_active: 1, mlapp_id: appId },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    dispatch(
      getRelTagAppList({
        params: { page_num: 1, page_size: 9999, is_active: 1, mlapp_id: appId },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    dispatch(
      getTagList({
        params: { page_num: 1, page_size: 9999, is_active: 1 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  }, []);

  useEffect(() => {
    setSelectedVersionInfo(versionData[0] || {});
  }, [versionData]);

  useEffect(() => {
    const width = window.innerWidth - 72 - 24;
    if (!openDeployConfigVersion) {
      setDetailWidth(0);
    } else if (openDeployConfigVersion && !isCollapse) {
      setDetailWidth(width - 240 - 300);
    } else if (openDeployConfigVersion && isCollapse) {
      setDetailWidth(width - 300);
    }
  }, [openDeployConfigVersion, isCollapse]);

  const handleDelete = async () => {
    // version -> is_active = 0, status => wait_close
    // app -> is_active = 0
    // rel_app_tag -> is_active = 0
    // delete image
    try {
      setDelLoading(true);
      await Promise.all(
        versionData.map((version) =>
          dispatch(
            editAppVersion({
              id: version.id,
              params: { is_active: 0, status: "wait_close" },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap()
        )
      );

      await Promise.all(
        appTagList.map((rel) =>
          dispatch(
            delRelTagApp({
              id: rel.id,
              params: { ...rel, is_active: 0 },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap()
        )
      );

      // delete image
      if (appDetail.cover_url) {
        const coverUrlTobase64 = encodeURIComponent(appDetail.cover_url);
        await dispatch(
          delImage({
            s3_key: btoa(coverUrlTobase64),
            alertCallback: enqueueSnackbar,
            pathname,
          })
        );
      }

      await dispatch(
        editAppGallery({
          id: appDetail.id,
          params: { is_active: 0 },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      );

      setDelLoading(false);
      navigate("/ui/mlService/appGallery");
      enqueueSnackbar("Delete Success", { variant: "success" });
    } catch (err) {
      throw err;
    }
  };

  const handleOpenApp = () => {
    toggleIsCollapseByBol(true);
    navigate(`appGalleryOpen`);
  };

  const getHeading = () => {
    return (
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="h4" gutterBottom>
          {appDetail?.name || ""}{" "}
        </Typography>
        {appDetail?.name && (
          <Button
            color="secondary"
            size="small"
            onClick={handleOpenApp}
            sx={{ marginBottom: "6px" }}
          >
            Open
          </Button>
        )}
      </Stack>
    );
  };

  const getActionBtn = () => {
    return (
      permissions.includes("21100") && (
        <>
          <Button
            onClick={handleClick}
            variant="outlined"
            endIcon={
              <ExpandMoreOutlinedIcon
                sx={{
                  transform: open ? "rotate(-180deg)" : "rotate(0deg)",
                  transition: "transform 0.5s",
                }}
              />
            }
            color="secondary"
            sx={{ width: "150px" }}
          >
            Actions
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: "150px",
              },
            }}
          >
            <MenuItem onClick={() => navigate("appGalleryEdit")}>
              {" "}
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenConfirm(true);
                handleClose();
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </>
      )
    );
  };

  return (
    <Page
      title={`${appDetail?.name}| OmniML`}
      heading={getHeading()}
      links={getLinks()}
      action={getActionBtn()}
    >
      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Box sx={{ width: detailWidth || "100%" }}>
          <AppGalleryDetailCards
            setOpenDeployConfigVersion={setOpenDeployConfigVersion}
            selectedVersionInfo={selectedVersionInfo}
            versionLoading={loading}
          />
        </Box>

        {openDeployConfigVersion && (
          <DeployConfigVersionPanel
            onClose={() => {
              setOpenDeployConfigVersion(false);
            }}
            setSelectedVersionInfo={setSelectedVersionInfo}
            selectedVersionInfo={selectedVersionInfo}
          />
        )}
      </Stack>

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete app <strong> {appDetail.name} </strong> item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelete();
              setOpenConfirm(false);
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </Page>
  );
};

export default MlAppGalleryDetail;

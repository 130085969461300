import {
  EnvVariable,
  TrainingCodeStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline";
import {
  TrainingStep,
  TrainingArguments,
  TrainingInputStruct,
  TrainingOutputStruct,
  TrainingEstimatorStruct,
  Hyperparameter,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-training";
import {
  parseUri,
  checkUseParam,
  // parseFramework,
  // parseFrameworkVersion,
} from "./helper-functions";

// ----------------------------------------------------------------------

export default function parseTrainingStep(
  index: number,
  pipelineStep: any,
  stepPairs: [string, string, string][]
) {
  const args = pipelineStep.Arguments as TrainingArguments;
  const step = {
    id: String(index),
    type: "Training",
    name: pipelineStep.Name,
    estimator: parseEstimatorProps(
      args.ResourceConfig,
      args.HyperParameters,
      args.AlgorithmSpecification,
      args.Environment
    ),
    trainingInputs: parseTrainingInputs(
      args.InputDataConfig,
      pipelineStep.Name,
      stepPairs
    ),
    trainingOutput: parseTrainingOutput(args.OutputDataConfig),
    metricDefinitions: (args.AlgorithmSpecification as any).MetricDefinitions,
    code: parseEstimatorCode(args.HyperParameters),
    nodeX: 220 * index,
    nodeY: 0,
    tags: [],
    properties: [],
    stepType: "Training",
  } as TrainingStep;
  return step;
}

const parseEstimatorProps = (
  resources: any,
  hyperParameters: any,
  algoSpec: any,
  env: any
) => {
  const estimatorProperties: TrainingEstimatorStruct = {
    estimatorType: parseEstimator(algoSpec.TrainingImage),
    estimatorTypeUseParam: checkUseParam(algoSpec.TrainingImage),
    // frameworkVersion: parseFrameworkVersion(algoSpec.TrainingImage),
    frameworkVersion: "",
    instanceType: parseUri(resources.InstanceType),
    instanceTypeUseParam: checkUseParam(resources.InstanceType),
    instanceCount: parseUri(resources.InstanceCount),
    instanceCountUseParam: checkUseParam(resources.InstanceCount),
    volumeSizeInGb: parseUri(resources.VolumeSizeInGB),
    volumeSizeInGbUseParam: checkUseParam(resources.VolumeSizeInGB),
    environmentVars: [],
    hyperparameters: parseHyperParameter(hyperParameters),
  };

  if (env !== undefined) {
    const environmentVars: Array<EnvVariable> = [];
    for (const [_key, _value] of Object.entries(env)) {
      environmentVars.push({ name: _key, value: String(_value) });
    }
    estimatorProperties.environmentVars = environmentVars;
  }

  return estimatorProperties;
};

export const parseTrainingInputs = (
  inputs: any,
  stepName: string,
  stepPairs: [string, string, string][]
) => {
  const trainingInputs: Array<TrainingInputStruct> = [];
  for (let input of inputs) {
    if (input.InputName !== "code") {
      // console.log(input);
      // let s3Data = input.DataSource.S3DataSource.S3Uri;
      // if (typeof s3Data === "object") {
      //   const s3UriItems = s3Data.Get.split(".");
      //   if (s3UriItems[0] === "Steps") {
      //     // Dependent steps
      //     s3Data = `${s3UriItems[1]}.${s3UriItems[2].replace("Config", "")}s.${
      //       s3UriItems[3].split("'")[1]
      //     }`;
      //     addStepPairs(
      //       stepPairs,
      //       s3UriItems[1],
      //       stepName,
      //       s3Data.replace(`${s3UriItems[1]}.`, "") +
      //         "&&" +
      //         `TrainingInputs.${input.ChannelName}`
      //     );
      //   } else {
      //     // Pipeline parameters
      //     s3Data = s3UriItems[1];
      //   }
      // }
      trainingInputs.push({
        inputName: input.ChannelName,
        s3Data: parseUri(
          input.DataSource.S3DataSource.S3Uri,
          stepName,
          stepPairs,
          `TrainingInputs.${input.InputName}`
        ),
        s3DataUseParam: checkUseParam(input.DataSource.S3DataSource.S3Uri),
        distribution: input.DataSource.S3DataSource.S3DataDistributionType,
        contentType: input.ContentType,
        inputMode: input.inputMode ? input.inputMode : "None",
      });
    }
  }

  return trainingInputs;
};

export const parseTrainingOutput = (output: any) => {
  const trainingOutputs = {
    s3OutputPath: parseUri(output.S3OutputPath),
    s3OutputPathUseParam: checkUseParam(output.S3OutputPath),
  } as TrainingOutputStruct;

  return trainingOutputs;
};

const parseEstimatorCode = (hyperParameters: any) => {
  const codeProperties: TrainingCodeStruct = {
    source: "amazon_s3",
    sourceDir: "",
    entryPoint: "",
  };
  if (hyperParameters) {
    if (hyperParameters.sagemaker_submit_directory)
      codeProperties.sourceDir = hyperParameters.sagemaker_submit_directory;
    if (hyperParameters.sagemaker_program)
      codeProperties.entryPoint = hyperParameters.sagemaker_program;
  }

  return codeProperties;
};

const parseHyperParameter = (hyperParameters: any) => {
  const hps: Array<Hyperparameter> = [];
  if (hyperParameters) {
    for (const [_key, _value] of Object.entries(hyperParameters)) {
      if (_key.indexOf("sagemaker_") >= 0) continue;
      hps.push({
        name: _key,
        value: parseUri(_value),
        useParam: checkUseParam(_value),
      });
    }
  }
  return hps;
};

// const parseMetricDefinitions = (inputs: any) => {
//   const trainingInputs: Array<TrainingInputStruct> = [];
//   for (let input of inputs) {
//     if (input.InputName !== 'code') {
//       trainingInputs.push({
//         inputName: input.ChannelName,
//         s3Data: input.DataSource.S3DataSource.S3Uri,
//         s3DataUseParam: typeof input.DataSource.S3DataSource.S3Uri === 'object' ? false : false,
//         distribution: input.DataSource.S3DataSource.S3DataDistributionType,
//         contentType: input.ContentType
//       });
//     }
//   }
//   return trainingInputs;
// };

const parseEstimator = (imageName: string) => {
  const uri = parseUri(imageName);
  return uri;
  // const items = uri.split("/");
  // if (items.length > 0) return items[1];
  // else return items[0];
};

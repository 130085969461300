import StepDetailTable from "../StepDetailTable";

const ContainerTable = ({ title, rows }) => {
  const columns = [
    { id: "image", label: "Image", minWidth: 150 },
    { id: "codePath", label: "Code Path" },
    { id: "arguments", label: "Arguments" },
  ];

  return <StepDetailTable title={title} columns={columns} rows={rows} />;
};

export default ContainerTable;

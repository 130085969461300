import * as Yup from "yup";
import { Icon } from "@iconify/react";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Stack, Collapse } from "@mui/material";
//
import { updateClarifyCheckModelConfig } from "../../../graph/slices/slice";
import {
  ClarifyCheckStep,
  ClarifyCheckModelConfigStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-clarify-check";
import { CollapseButtonStyle } from "../../SettingSidebar";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";

// ----------------------------------------------------------------------

type Props = {
  currentStep: ClarifyCheckStep | null;
};

export default function ModelConfig({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [showProcessor, setShowProcessorr] = useState(false);

  const ParamSchema = Yup.object().shape({
    modelDataUrl: Yup.string().required("Model is required"),
  });

  const formik = useFormik<ClarifyCheckModelConfigStruct>({
    initialValues: {
      modelDataUrl: currentStep?.modelConfig.modelDataUrl || "",
      modelDataUrlUseParam:
        currentStep?.modelConfig.modelDataUrlUseParam || false,
      instanceType: currentStep?.modelConfig.instanceType || "ml.m4.xlarge",
      instanceTypeUseParam:
        currentStep?.modelConfig.instanceTypeUseParam || false,
      instanceCount: currentStep?.modelConfig.instanceCount || "1",
      instanceCountUseParam:
        currentStep?.modelConfig.instanceCountUseParam || false,
      modelPackageGroupName:
        currentStep?.modelConfig.modelPackageGroupName || "",
      modelPackageGroupNameUseParam:
        currentStep?.modelConfig.modelPackageGroupNameUseParam || false,
    },
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values) => {
      try {
        dispatch(updateClarifyCheckModelConfig(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { handleSubmit } = formik;

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setShowProcessorr((prev) => !prev)}
        endIcon={
          <Icon
            icon={showProcessor ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
      >
        Model config
      </CollapseButtonStyle>

      <Collapse in={showProcessor} sx={{ mx: 2 }}>
        <Stack spacing={1} sx={{ my: 1.2, mb: 1.5 }}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onChange={handleSubmit}>
              <Stack spacing={2} sx={{ mb: 0.5 }}>
                {/* <PipelineSelect
                  label={"Processor type"}
                  field={"processorType"}
                  placeholder={"Select a processor"}
                  options={processorTypes}
                  getFieldProps={getFieldProps}
                />

                <PipelineSelect
                  label={"Framework version"}
                  field={"frameworkVersion"}
                  placeholder={"Select a framework version"}
                  options={processorVerions}
                  getFieldProps={getFieldProps}
                /> */}

                <PipelineParameterEntry
                  label={"Model data url"}
                  field={"modelDataUrl"}
                  fieldUseParam={"modelDataUrlUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Instance type"}
                  field={"instanceType"}
                  fieldUseParam={"instanceTypeUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Instance count"}
                  field={"instanceCount"}
                  fieldUseParam={"instanceCountUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Model package group name"}
                  field={"modelPackageGroupName"}
                  fieldUseParam={"modelPackageGroupNameUseParam"}
                  placeholder={"(Optional)"}
                  formik={formik}
                />
              </Stack>
            </Form>
          </FormikProvider>
        </Stack>
      </Collapse>
    </>
  );
}

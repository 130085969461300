import MlCard from "@/components/project/mlComponents/MlCard";
import MlRadio from "@/components/project/mlComponents/MlRadio";
import Page from "@/components/project/Page";
import { PATH_DASHBOARD } from "@/routes/paths";
import { Box } from "@mui/material";
import { useState } from "react";
import KubeflowNotebook from "./components/notebookCreate/KubeflowNotebook";
import SageMakerNotebook from "./components/notebookCreate/SageMakerNotebook";
import Scrollbar from "@/components/project/Scrollbar";

const radioList = [
  {
    id: 1,
    value: "Kubeflow",
    title: "Kubeflow",
    description: "Created by Kubeflow and running in Kubernetes",
  },
  {
    id: 2,
    value: "SageMaker",
    title: "AWS SageMaker",
    description: "AWS ML compute instance running the Jupyter Notebook App",
    disabled: true,
  },
];

const NoteBookCreate = () => {
  const [notebookType, setNotebookType] = useState<"Kubeflow" | "SageMaker">(
    "Kubeflow"
  );

  const getLinks = () => {
    return [
      {
        name: "Experiment",
      },
      {
        name: "Notebook",
        href: PATH_DASHBOARD.experiment.notebook,
      },
      {
        name: "Create Notebook",
      },
    ];
  };

  return (
    <Page
      title="Create Notebook | OmniML"
      heading="Create Notebook"
      links={getLinks()}
    >
      <MlCard title="NoteBook Type">
        <Scrollbar>
          <Box sx={{ width: 800 }}>
            <MlRadio
              curValue={notebookType}
              setCurValue={setNotebookType}
              list={radioList}
            />
          </Box>
        </Scrollbar>
      </MlCard>
      {notebookType === "Kubeflow" ? (
        <KubeflowNotebook />
      ) : (
        <SageMakerNotebook />
      )}
    </Page>
  );
};

export default NoteBookCreate;

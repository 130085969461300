import { styled, useTheme } from "@mui/material/styles";
import useCollapseDrawer from "@/hooks/project/useCollapseDrawer";
import SiderbarDom from "./components/SiderbarDom";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { useMediaQuery } from "@mui/material";

export const FIRST_DRAWER_WIDTH = 72;
export const SECOND_DRAWER_WIDTH = 240;

export type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function DashboardSidebar(props: DashboardSidebarProps) {
  const { isCollapse } = useCollapseDrawer();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const { pathname } = useLocation();

  const { isOpenSidebar, onCloseSidebar } = props;

  const RootStyle = styled("div")(({ theme }) => ({
    width: !isMobile
      ? isCollapse
        ? FIRST_DRAWER_WIDTH
        : FIRST_DRAWER_WIDTH + SECOND_DRAWER_WIDTH
      : 0,
    [theme.breakpoints.up("lg")]: {
      flexShrink: 0,
      transition: theme.transitions.create("width", {
        duration: theme.transitions.duration.complex,
      }),
    },
  }));

  // click the route to hidden the drawer when isMobile
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <RootStyle>
      <SiderbarDom {...props} />
    </RootStyle>
  );
}

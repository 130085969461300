// material
import { Box, Stack, Typography } from "@mui/material";
import { StyledTextField } from "../components/PipelineComponents";

// ----------------------------------------------------------------------

type Props = {
  formik: any;
};

export default function S3TriggerInput({ formik }: Props) {
  const { errors, touched, getFieldProps } = formik;

  return (
    <Box>
      <Typography
        variant="overline"
        sx={{ my: 2.5, display: "block", color: "text.secondary" }}
      >
        Select Target
      </Typography>
      <Stack spacing={2}>
        <StyledTextField
          size="small"
          label="Bucket name"
          placeholder="e.g. bucket1,bucket2,..."
          {...getFieldProps("s3BucketName")}
          error={Boolean(touched.s3BucketName && errors.s3BucketName)}
          helperText={errors.s3BucketName}
        />
        <StyledTextField
          size="small"
          label="Prefix (optional)"
          placeholder="e.g. folder/file1,folder/file2,..."
          {...getFieldProps("s3Prefix")}
          error={Boolean(touched.s3Prefix && errors.s3Prefix)}
          helperText={errors.s3Prefix}
        />
        {/* <StyledTextField
          size="small"
          label="Suffix (optional)"
          {...getFieldProps("s3Suffix")}
          error={Boolean(touched.s3Suffix && errors.s3Suffix)}
          helperText={errors.s3Suffix}
        /> */}
      </Stack>
    </Box>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Drawer,
  Stack,
  Typography,
  Divider,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import Scrollbar from "@/components/project/Scrollbar";
import { dispatch, useSelector, RootState } from "@/redux/store";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { getModelPackageById } from "@/redux/project/mlService/mlRuntime/thunks";
import { useSnackbar } from "notistack";

interface IDeploymentDetailDrawerProps {
  isOpen: boolean;
  onClose: VoidFunction;
  mlServiceType: string;
  serviceInfo: Record<string, any>;
}

const DeploymentDetailDrawer = ({
  isOpen,
  onClose,
  mlServiceType,
  serviceInfo,
}: IDeploymentDetailDrawerProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [modelpackage, setModelpackage] = useState<Record<string, any>>({});
  const {
    mlServiceVersionDescribe: { data: versionDescribe },
  } = useSelector((state: RootState) => state.mlRuntime);

  const isCreate = pathname.includes("mlRuntimeCreate");

  useEffect(() => {
    // get package
    dispatch(
      getModelPackageById({
        id: isCreate
          ? serviceInfo?.model_package.id
          : serviceInfo?.mp_external_id,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    )
      .unwrap()
      .then((resp) => {
        setModelpackage(resp);
      });
  }, []);

  const getOrderedDetail = () => {
    const { env = {}, model_deploy = {} } =
      versionDescribe?.resource_config || {};
    const models = model_deploy.models || [];
    return [
      {
        name: "Properties",
        datas: {
          "K8s Cluster": env.cluster_names || "-",
          "Deployment Type": versionDescribe?.deploment_type || "-",
          "Model Package": (
            <Stack direction="row" spacing={0.5} alignItems="center">
              {modelpackage?.name ? (
                <>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary", wordBreak: "break-word" }}
                  >
                    {modelpackage.name}
                  </Typography>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      navigate(
                        `/ui/dataAsset/modelRegistry/${modelpackage.id}`
                      );
                    }}
                  >
                    <OpenInNewOutlinedIcon
                      sx={{ width: 16, height: 16, borderRadius: 4 }}
                    />
                  </IconButton>
                </>
              ) : (
                "-"
              )}
            </Stack>
          ),
          Description: versionDescribe?.description || "-",
          "Created At": versionDescribe?.created_at || "-",
          "Last Modified": versionDescribe?.updated_at || "-",
        },
      },
      {
        name: "Model Info",
        datas:
          versionDescribe?.deploment_type === "AB Testing"
            ? {
                "Model A Name": models[0]?.modelname || "-",
                "Model A Version": models[0]?.mpv_external_id || "-",
                "Traffic A Ratio": models[0]?.modeltraffic || "-",
                "Model B Name": models[1]?.modelname || "-",
                "Model B Version": models[1]?.mpv_external_id || "-",
                "Traffic B Ratio": models[1]?.modeltraffic || "-",
              }
            : {
                "Model Name": models[0]?.modelname || "-",
                "Model Version": models[0]?.mpv_version_number || "-",
              },
      },
      {
        name: "Config",
        datas:
          mlServiceType === "Kubeflow"
            ? {
                "Seldon Server Type": models[0]?.seldon_server_type || "-",
                "Instance Type": models[0]?.k8s_gpu_type || "-",
                Image: models[0]?.image || "-",
                GPU: models[0]?.k8s_gpu || "-",
                CPU: models[0]?.k8s_cpu || "-",
                Replicas: models[0]?.k8s_replicas || "-",
              }
            : {
                "Instance Type": models[0]?.sagemaker_instance_type || "-",
                Image: models[0]?.image || "-",
                Role: models[0]?.iam_role || "-",
                "Instance Count": models[0]?.sagemaker_instance_count || "-",
              },
      },
    ];
  };

  useEffect(() => {
    getOrderedDetail();
  }, [versionDescribe, modelpackage]);

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      anchor="right"
      sx={{
        "& .MuiPaper-root": {
          width: "320px",
          bgcolor: "background.default",
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          py: 2.5,
          px: 4,
          bgcolor: "background.paper",
        }}
      >
        <Typography variant="subtitle1">Info</Typography>
        <CloseOutlinedIcon sx={{ cursor: "pointer" }} onClick={onClose} />
      </Stack>
      <Scrollbar>
        <Stack sx={{ px: 4, mb: 10 }}>
          <Typography variant="h6" align="center" paddingY={2}>
            {versionDescribe.name}
          </Typography>
          {getOrderedDetail().map(({ name, datas }) => (
            <>
              <Divider sx={{ mb: 1, borderStyle: "dashed" }} />
              <Accordion
                defaultExpanded
                elevation={0}
                sx={{
                  width: "100% !important",
                  boxShadow: "none !important",
                  "&.Mui-expanded": { margin: 0 },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    padding: 0,
                    minHeight: "64px",
                    "& .MuiAccordionSummary-content": {
                      margin: "0 !important",
                    },
                  }}
                >
                  <Typography variant="subtitle1" paddingY={2}>
                    {name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0 5px" }}>
                  <Grid container columnSpacing={1}>
                    {Object.entries(datas).map(([key, value]) => (
                      <>
                        <Grid item xs={6} lg={6}>
                          <Typography variant="subtitle2" paddingY={1}>
                            {key}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                          {typeof value === "string" ? (
                            <Typography
                              variant="body2"
                              paddingY={1}
                              sx={{
                                color: "text.secondary",
                                wordBreak: "break-word",
                              }}
                            >
                              {value}
                            </Typography>
                          ) : (
                            value
                          )}
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          ))}
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};

export default DeploymentDetailDrawer;

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { DeleteIcon, EditIcon } from "@/assets/project/icons";
import {
  Box,
  Card,
  Typography,
  Stack,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Avatar,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import { styled, useTheme } from "@mui/material/styles";
import { isEmpty } from "lodash";
import { dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import {
  delNotebookTemplate,
  getNotebookImage,
  delNotebookImage,
} from "@/redux/project/template/thunks";
import ConfirmDialog from "@/components/project/ConfirmDialog";

interface INotebookTemplateCardProps {
  template: Record<string, any>;
  handleRefresh: (a?: boolean) => void;
  onSetModel: VoidFunction;
  onShowDialog: VoidFunction;
  onShowDetail: VoidFunction;
  setTemplate: (a: Record<string, any>) => void;
}

const ProductImgStyle = styled("img")(() => ({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
}));

export default function TemplateCard({
  template,
  handleRefresh,
  onSetModel,
  onShowDialog,
  onShowDetail,
  setTemplate,
}: INotebookTemplateCardProps) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const [mouseEnter, setMouseEnter] = useState(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string>("");
  const { id, name, icon_url } = template;

  // delete dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // get imageurl
    if (icon_url && icon_url !== "default") {
      dispatch(
        getNotebookImage({
          params: { s3_key: icon_url },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((resp) => {
          setImageSrc(resp.url);
        });
    }
  }, []);

  const handleDelTemplate = async () => {
    setDelLoading(true);
    try {
      if (icon_url && icon_url !== "default") {
        const iconUrlTobase64 = encodeURIComponent(icon_url);
        await dispatch(
          delNotebookImage({
            s3_key: btoa(iconUrlTobase64),
            alertCallback: enqueueSnackbar,
            pathname,
          })
        );
      }

      await dispatch(
        delNotebookTemplate({
          id: template.id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      setDelLoading(false);
      setOpenConfirm(false);
      enqueueSnackbar("Delete Success", { variant: "success" });

      handleRefresh(true);
    } catch (e) {
      setDelLoading(false);
    }
  };

  const handleMouseEnter = () => {
    setMouseEnter(true);
  };

  const handleMouseLeave = () => {
    setMouseEnter(false);
  };

  return (
    <Card
      key={id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        height: 320,
        width: 240,
        mb: 2,
        border: "1px solid rgba(145, 158, 171, 0.23)",
        boxShadow: theme.customShadows.z1,
        ...(mouseEnter && {
          boxShadow: (theme) => theme.customShadows.z24,
        }),
      }}
    >
      <Box sx={{ pt: "95%", position: "relative" }}>
        {isEmpty(imageSrc) ? (
          <Avatar
            variant="square"
            sx={{
              bgcolor: (theme) => theme.palette.grey[5008],
              top: 0,
              height: "100%",
              width: "100%",
              position: "absolute",
            }}
          >
            <Typography variant="body2" fontSize="100px">
              {name ? name[0] : ""}
            </Typography>
          </Avatar>
        ) : (
          <ProductImgStyle alt="" src={imageSrc} />
        )}
      </Box>

      <Stack
        spacing={1.5}
        direction="row"
        justifyContent="space-between"
        sx={{ pt: 1 }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{ pt: 0.5, pr: 1.5, pl: 1.5 }}
        >
          {name}
        </Typography>
        <>
          <IconButton size="small" onClick={handleClick}>
            <MoreVertOutlinedIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                onSetModel();
                setTemplate(template);
                handleClose();
                onShowDialog();
              }}
              sx={{ color: "text.secondary" }}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <SvgIconStyle
                  src={EditIcon}
                  sx={{
                    width: 22,
                    height: 22,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Edit"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setOpenConfirm(true);
                handleClose();
              }}
              key="delete"
              sx={{ color: "error.main" }}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <SvgIconStyle
                  src={DeleteIcon}
                  sx={{
                    width: 22,
                    height: 22,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Delete"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          </Menu>
        </>
      </Stack>

      <Stack
        spacing={1.5}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ pt: 1, pr: 1.5, pb: 1.5, pl: 1.5 }}
      >
        <Button
          size="small"
          color="inherit"
          sx={{ height: 24 }}
          endIcon={<KeyboardArrowRightIcon />}
          onClick={() => {
            setTemplate(template);
            onShowDetail();
          }}
        >
          Detail
        </Button>
      </Stack>
      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete notebook template{" "}
            <strong> {template.name} </strong> item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelTemplate();
              setOpenConfirm(false);
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </Card>
  );
}

import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Stack, Collapse, Typography, IconButton } from "@mui/material";
// types
import {
  TuningStep,
  TuningJobDefStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-tuning";
//
import {
  updateTuningJobDef,
  updateTuningParameterRanges,
} from "../../../graph/slices/slice";
import ParameterRange from "./ParameterRange";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { PipelineSelect } from "../../components/PipelineComponents";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";
import PipelineParameterWithSelector from "../../components/PipelineParameterWithSelector";

// ----------------------------------------------------------------------

const strateyTypes = [
  { code: "A", label: "Random" },
  { code: "B", label: "Bayesian" },
];

export const trainingJobEarlyStoppingTypes = [
  { code: "A", label: "Off" },
  { code: "B", label: "Auto" },
];

type Props = {
  currentStep: TuningStep;
};

export default function TuningJobDef({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [isCollapse, setCollapse] = useState(true);

  const tuningJobDef = currentStep.tuningJob;

  const formik = useFormik<TuningJobDefStruct>({
    initialValues: tuningJobDef,
    enableReinitialize: true,
    onSubmit: (values) => {
      try {
        dispatch(updateTuningJobDef(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const parameterRanges = currentStep?.tuningJob.parameterRanges;
  const prNum = parameterRanges !== undefined ? parameterRanges.length : 0;

  const { handleSubmit, getFieldProps } = formik;

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setCollapse((prev) => !prev)}
        endIcon={
          <Icon
            icon={isCollapse ? arrowIosForwardFill : arrowIosDownwardFill}
            width={16}
            height={16}
          />
        }
      >
        Tuning Job Definition
      </CollapseButtonStyle>

      <Collapse in={!isCollapse} sx={{ mx: 2, mb: !isCollapse ? 0.5 : 0 }}>
        <Stack spacing={1} sx={{ my: 1 }}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onChange={handleSubmit}>
              <Stack spacing={2}>
                <PipelineSelect
                  label={"Strategy"}
                  field={"strategyType"}
                  placeholder={"Select a tuning strategy"}
                  options={strateyTypes}
                  getFieldProps={getFieldProps}
                />

                <PipelineParameterEntry
                  label={"Max training job num"}
                  field={"maxTrainingJob"}
                  fieldUseParam={"maxTrainingJobUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Max parallel training job num"}
                  field={"maxParallelTrainingJob"}
                  fieldUseParam={"maxParallelTrainingJobUseParam"}
                  formik={formik}
                />

                <PipelineParameterWithSelector
                  label={"Training early stopping type"}
                  field={"trainingJobEarlyStoppingType"}
                  placeholder={"Select early stopping type"}
                  fieldUseParam={"trainingJobEarlyStoppingTypeUseParam"}
                  options={trainingJobEarlyStoppingTypes}
                  formik={formik}
                />
              </Stack>
            </Form>
          </FormikProvider>

          <Stack spacing={1.5} sx={{ mx: 2, my: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", fontWeight: 600, mt: 0.5 }}
              >
                Parameter ranges {prNum > 0 && `(${prNum})`}
              </Typography>
              <IconButton
                size="small"
                onClick={() => {
                  dispatch(
                    updateTuningParameterRanges({
                      data: {
                        name: "",
                        type: "Continuous",
                        minValue: "",
                        minValueUseParam: false,
                        maxValue: "",
                        maxValueUseParam: false,
                        scalingType: "Auto",
                        scalingTypeUseParam: false,
                      },
                    })
                  );
                }}
              >
                <Icon icon={plusFill} width={16} height={16} />
              </IconButton>
            </Stack>

            {parameterRanges !== undefined &&
              parameterRanges.map((pr, index) => (
                <ParameterRange key={index} id={index} data={pr} />
              ))}
          </Stack>
        </Stack>
      </Collapse>
    </>
  );
}

import { Icon } from "@iconify/react";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import { useDispatch } from "react-redux";
// material
import {
  Paper,
  Box,
  Stack,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";
//
import { ConditionStruct } from "@/@types/project/mlPipeline/SageMaker/pipeline-condition";
import { updateCondition, deleteCondition } from "../../../graph/slices/slice";
import LeftRightInput from "./LeftRightInput";
import { StyledTextField } from "../../components/PipelineComponents";
import { ConditionTypes } from "./ConditionStepEntry";

// ----------------------------------------------------------------------

export default function Condition({
  id,
  data,
}: {
  id: number;
  data: ConditionStruct;
}) {
  const dispatch = useDispatch();

  return (
    <>
      <Paper sx={{ borderRadius: 1.5, bgcolor: "background.neutral" }}>
        <Stack spacing={2} sx={{ px: 1.5, py: 0.5 }}>
          <Box>
            <Typography
              variant="overline"
              lineHeight={3}
              sx={{
                color: "text.secondary",
              }}
            >
              Left value
            </Typography>

            <LeftRightInput
              id={id}
              side={"left"}
              data={data.leftValue}
              condition={data}
            />
          </Box>

          <Box>
            <Typography
              variant="overline"
              lineHeight={3}
              sx={{
                color: "text.secondary",
              }}
            >
              Condition type
            </Typography>

            <StyledTextField
              select
              label={""}
              SelectProps={{ native: true }}
              value={data.conditionType}
              onChange={(event: any) => {
                dispatch(
                  updateCondition({
                    id: id,
                    data: { ...data, conditionType: event.target.value },
                  })
                );
              }}
            >
              {ConditionTypes.map((option) => (
                <option key={option.code} value={option.value}>
                  {option.label}
                </option>
              ))}
            </StyledTextField>
          </Box>

          <Box>
            <Typography
              variant="overline"
              lineHeight={3}
              sx={{
                color: "text.secondary",
              }}
            >
              Right value
            </Typography>

            <LeftRightInput
              id={id}
              side={"right"}
              data={data.rightValue}
              condition={data}
            />
          </Box>

          <Stack spacing={0.5}>
            <Divider />
            <Box display="flex" alignItems="center" justifyContent="center">
              <IconButton
                size="small"
                onClick={() => {
                  dispatch(deleteCondition(id));
                }}
              >
                <Icon icon={trash2Fill} width={18} height={18} />
              </IconButton>
            </Box>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
}

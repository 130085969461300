import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Box, Stack, Collapse, IconButton } from "@mui/material";
//
import EmptyTable from "@/components/project/EmptyTable";
import { CollapseButtonStyle } from "../../../../SettingSidebar";
import FeatureSetDownloadOutputItem from "./partComponents/FeatureSetDownloadOutputItem";
import { FeatureSetDownloadStep } from "@/@types/project/mlPipeline/Kubeflow/pipeline-featureset-download";
import { handleKubeflowStepArray } from "@/pages/project/automation/MlPipeline/graph/slices/slice";

// ----------------------------------------------------------------------

type Props = {
  currentStep: FeatureSetDownloadStep | null;
  isEdit: boolean;
};

export default function FeatureSetDownloadOutputs({
  currentStep,
  isEdit,
}: Props) {
  const dispatch = useDispatch();

  const [showFeatureSetDownloadOutput, setShowFeatureSetDownloadOutput] =
    useState(false);

  const featureSetDownloadOutputs = currentStep?.outputs || [];

  const handleAddFeatureSetDownloadOutput = () => {
    if (!showFeatureSetDownloadOutput) {
      setShowFeatureSetDownloadOutput(true);
    }

    dispatch(
      handleKubeflowStepArray({
        handleType: "create",
        data: {
          name: `FeatureSetDownloadOutput-${
            featureSetDownloadOutputs.length + 1
          }`,
          dataType: "String",
          description: "",
          paramType: 2,
        },
        key: "outputs",
      })
    );
  };

  return (
    <>
      <Box sx={{ mb: 0, position: "relative" }}>
        <CollapseButtonStyle
          fullWidth
          color="inherit"
          onClick={() => setShowFeatureSetDownloadOutput((prev) => !prev)}
          endIcon={
            <Icon
              icon={
                showFeatureSetDownloadOutput
                  ? arrowIosDownwardFill
                  : arrowIosForwardFill
              }
              width={16}
              height={16}
            />
          }
        >
          OUTPUTS{" "}
          {featureSetDownloadOutputs.length > 0 &&
            `(${featureSetDownloadOutputs.length})`}
        </CollapseButtonStyle>

        <IconButton
          sx={{ zIndex: 1999, position: "absolute", top: 7, right: 30 }}
          disabled={isEdit}
          size="small"
          onClick={handleAddFeatureSetDownloadOutput}
        >
          <Icon icon={plusFill} width={16} height={16} />
        </IconButton>
      </Box>

      <Collapse in={showFeatureSetDownloadOutput}>
        <Stack
          spacing={1.5}
          sx={{ mx: 2, mb: 1.5, mt: showFeatureSetDownloadOutput ? 0.5 : 0 }}
        >
          {featureSetDownloadOutputs.length > 0 &&
            featureSetDownloadOutputs.map((featureSetDownloadOutput, index) => (
              <FeatureSetDownloadOutputItem
                length={featureSetDownloadOutputs.length}
                isEdit={isEdit}
                key={index}
                id={index}
                data={featureSetDownloadOutput}
              />
            ))}
        </Stack>
      </Collapse>

      {showFeatureSetDownloadOutput &&
        featureSetDownloadOutputs.length === 0 && (
          <EmptyTable
            emptyImage={false}
            mainTitle={"No featureSetDownload outputs"}
            content={'Create one by clicking "+" button'}
            color={"text.disabled"}
            sx={{ backgroundColor: "transparent", mx: 2, mt: 5, mb: 7 }}
          />
        )}
    </>
  );
}

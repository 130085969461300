import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Step,
  ExecutionStep,
} from "@/@types/project/mlPipeline/SageMaker/pipeline";

export interface ExecutionGraphState {
  fixNextRender: boolean;
  showPropPanel: boolean;
  executionGraph: ExecutionGraph;
  currentEntityId: string | null;
  selectedEntities: string[];
}

export interface ExecutionGraph {
  name: string;
  tags: ExecutionGraphEntityTag[];
  relationships: ExecutionGraphEntityRelationship[];
  // nodeEntities: SchemaNodeEntity[];
  edgeEntities: ExecutionGraphEdgeEntity[];
  pipelineRoleArn: string;
  executionSteps: ExecutionStep[];
}

export interface ExecutionGraphEdgeEntity {
  id: string;
  relationship: number;
  properties: ExecutionGraphEntityProperty[];
  sourceEntityId: string;
  sourcePortId: string;
  targetEntityId: string;
  targetPortId: string;
}

export interface ExecutionGraphEntityProperty {
  id: number;
  title: string;
  type: number;
}

export interface ExecutionGraphEntityTag {
  id: number;
  tag: string;
  color: string;
  isNew: boolean;
}

export interface ExecutionGraphEntityRelationship {
  id: number;
  value: string;
  isNew: boolean;
}

interface UpdateSelectedEntitiesPayload {
  selected: Array<string>;
}

interface AddEdgeEntityPayload {
  entity: ExecutionGraphEdgeEntity;
}

interface RelationshipPayload {
  relationship: ExecutionGraphEntityRelationship;
}

const initialState: ExecutionGraphState = {
  fixNextRender: false,
  showPropPanel: true,
  executionGraph: {
    name: "",
    tags: [],
    // nodeEntities: [],
    edgeEntities: [],
    relationships: [],
    pipelineRoleArn: "",
    executionSteps: [],
  },
  currentEntityId: null,
  selectedEntities: [],
};

const findEntityById = (
  state: ExecutionGraphState,
  entityId: string | null
) => {
  if (entityId === null) return null;
  const nodeEntity = state.executionGraph.executionSteps.find(
    (entity) => entity.id.toString() === entityId
  );
  if (nodeEntity) {
    return nodeEntity;
  } else {
    const edgeEntity = state.executionGraph.edgeEntities.find(
      (entity) => entity.id === entityId
    );
    return edgeEntity ? edgeEntity : null;
  }
};

const executionSlice = createSlice({
  name: "executionGraph",
  initialState,
  reducers: {
    setSchemaName(state, action: PayloadAction<string>) {
      state.executionGraph.name = action.payload;
    },
    setCurrentEntity(state, action: PayloadAction<string | null>) {
      const entityId = action.payload;
      state.currentEntityId = entityId;
      if (entityId != null) {
        state.selectedEntities = [entityId];
      } else {
        state.selectedEntities = [];
      }
    },
    updateSelectedEntities(
      state,
      action: PayloadAction<UpdateSelectedEntitiesPayload>
    ) {
      state.selectedEntities = action.payload.selected;
    },
    addEdgeEntity(state, action: PayloadAction<AddEdgeEntityPayload>) {
      const edgeEntity = action.payload.entity;
      const edgeEntityIndex = state.executionGraph.edgeEntities.findIndex(
        (entity) => entity.id === edgeEntity.id
      );
      if (edgeEntityIndex > -1) {
        const oldEdgeEntity =
          state.executionGraph.edgeEntities[edgeEntityIndex];
        edgeEntity.properties = oldEdgeEntity.properties;
        edgeEntity.relationship = oldEdgeEntity.relationship;
        state.executionGraph.edgeEntities[edgeEntityIndex] = edgeEntity;
      } else {
        if (
          edgeEntity.relationship === 0 &&
          state.executionGraph.relationships.length > 0
        ) {
          edgeEntity.relationship = state.executionGraph.relationships[0].id;
        }
        state.executionGraph.edgeEntities.push(edgeEntity);
      }
    },
    updateEntityRelationship(state, action: PayloadAction<number>) {
      const entity = findEntityById(state, state.currentEntityId);
      if (entity) {
        if (entity as ExecutionGraphEdgeEntity) {
          (entity as ExecutionGraphEdgeEntity).relationship = action.payload;
        }
      }
    },
    addRelationship(state, action: PayloadAction<string>) {
      const currentMaxId = state.executionGraph.relationships.length
        ? Math.max(
            ...state.executionGraph.relationships.map(
              (relationship) => relationship.id
            )
          )
        : 0;
      const id = currentMaxId ? currentMaxId + 1 : 1;
      const newRelationship =
        action.payload.length > 0
          ? action.payload
          : `*relationship-${state.executionGraph.relationships.length + 1}`;
      state.executionGraph.relationships.push({
        id: id,
        value: newRelationship,
        isNew: true,
      });
    },
    updateRelationship(state, action: PayloadAction<RelationshipPayload>) {
      const newRelationship = action.payload.relationship;
      const relationshipIndex = state.executionGraph.relationships.findIndex(
        (relationship) => relationship.id === newRelationship.id
      );
      if (relationshipIndex > -1) {
        state.executionGraph.relationships[relationshipIndex] = newRelationship;
      }
    },
    deleteRelationship(state, action: PayloadAction<number>) {
      const relationshipIndex = state.executionGraph.relationships.findIndex(
        (relationship) => relationship.id === action.payload
      );
      if (relationshipIndex > -1) {
        state.executionGraph.relationships.splice(relationshipIndex, 1);
      }
      state.executionGraph.edgeEntities.forEach((entity) => {
        if (entity.relationship === action.payload) {
          entity.relationship = 0;
        }
      });
    },
    resetRelationshipNewStatus(state, action: PayloadAction<number>) {
      const relationship = state.executionGraph.relationships.find(
        (relationship) => relationship.id === action.payload
      );
      if (relationship) {
        relationship.isNew = false;
      }
    },
    updateFixNextRender(state, action: PayloadAction<boolean>) {
      state.fixNextRender = action.payload;
    },
    mapSchema(state, action) {
      state.executionGraph.executionSteps = [];
      action.payload.pipelineSteps.forEach((step: Step) => {
        const { id, name, nodeX, nodeY, stepType, type } = step;

        const executionInfo = action.payload.executionStepInfo.find(
          (info: any) => info.name === name
        );

        state.executionGraph.executionSteps.push({
          id: id,
          name: name,
          nodeX: nodeX,
          nodeY: nodeY,
          stepType: stepType,
          type: type,
          status: executionInfo.phase,
          startTime: executionInfo.started_at,
          endTime: executionInfo.finished_at,
        });
      });
      state.executionGraph.edgeEntities = action.payload.edgeEntities;
    },
    clearSchema(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.selectedEntities = [];
        state.currentEntityId = null;
        state.executionGraph = {
          name: state.executionGraph.name,
          tags: [],
          relationships: [],
          edgeEntities: [],
          pipelineRoleArn: "",
          executionSteps: [],
        };
      }
    },
  },
});

export const {
  setSchemaName,
  setCurrentEntity,
  updateSelectedEntities,

  addEdgeEntity,

  updateEntityRelationship,

  addRelationship,
  updateRelationship,
  deleteRelationship,

  resetRelationshipNewStatus,

  updateFixNextRender,

  mapSchema,
  clearSchema,
} = executionSlice.actions;

export default executionSlice.reducer;

/* eslint-disable react-hooks/exhaustive-deps */
import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { useTheme, styled } from "@mui/material/styles";
import { Card, Typography, Box } from "@mui/material";
// hooks
import useLocales from "@/hooks/project/useLocales";
// utils
import { BaseOptionChart } from "@/components/project/charts";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  display: "flex",
  position: "relative",
  alignItems: "center",
  paddingTop: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingBottom: theme.spacing(-2),
  boxShadow: "none",
}));

export default function MetricCard({ title, metricData }) {
  const theme = useTheme();
  const { translate } = useLocales();
  const [labels, setLabels] = useState<string[]>([]);
  const [seriesData, setSeriesData] = useState<number[]>([]);
  const [datas, setDatas] = useState<Record<string, any>>({});

  useEffect(() => {
    if (metricData.length > 0) {
      const labelkeys: string[] = [];
      const values: number[] = [];
      const series: Record<string, any> = {};
      metricData.map((item) => {
        if (item.model_name in series) {
          series[item.model_name].push(item.result);
        } else {
          series[item.model_name] = [item.result];
        }
        return null;
      });
      for (const [key, value] of Object.entries(series)) {
        labelkeys.push(key);
        const first = parseFloat(value[0]);
        const last = parseFloat(value[value.length - 1]);
        values.push(parseFloat(((last / first) * 100).toFixed(2)));
      }
      setSeriesData(values);
      setLabels(labelkeys);
      setDatas(series);
    }
  }, []);

  // const chartData = [((refMetricValue / metricValue) * 100)?.toFixed(2)];

  const chartOptions = merge(BaseOptionChart(), {
    chart: { sparkline: { enabled: true } },
    legend: { show: false },
    fill: {
      type: "gradient",
      gradient: {
        colorStops: [
          [
            { offset: 0, color: theme.palette.secondary.light },
            { offset: 100, color: theme.palette.secondary.main },
          ],
          [
            { offset: 0, color: theme.palette.primary.light },
            { offset: 100, color: theme.palette.primary.main },
          ],
        ],
      },
    },
    plotOptions: {
      radialBar: {
        // hollow: { size: "72%" },
        track: { margin: 3 },
        dataLabels: {
          name: {
            show: true,
            offsetY: -4,
          },
          total: { show: false },
          value: {
            offsetY: -2,
            fontSize: "12px",
          },
        },
      },
    },
    labels,
  });

  return (
    <Card sx={{ display: "block", pb: 1, alignItems: "center" }}>
      <RootStyle>
        <Box sx={{ flexGrow: 1 }}>
          <ReactApexChart
            type="radialBar"
            series={seriesData}
            options={chartOptions}
            width={90}
            height={90}
          />
        </Box>
        <Box sx={{ mr: 2 }}>
          {Object.entries(datas).map(([key, value]) => {
            return (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6" sx={{ marginRight: 2 }}>
                  {`${key}: `}
                </Typography>
                <Box>
                  <Stack direction="column">
                    <Typography
                      variant="h6"
                      color={theme.palette.secondary.main}
                    >
                      {value[value?.length - 1]}
                    </Typography>
                    <Typography variant="body2" sx={{ opacity: 0.72 }}>
                      {`${translate("reference_value")}: ${value[0]}`}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            );
          })}
        </Box>
      </RootStyle>
      <Typography
        variant="h6"
        textAlign="center"
        color={theme.palette.text.secondary}
      >
        {title}
      </Typography>
    </Card>
  );
}

// create or edit page
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
// material
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
// redux
import { useDispatch, useSelector, RootState } from "@/redux/store";
import { clearSchema, setSchemaData } from "./graph/slices/slice";
import {
  // duplicatePipeline,
  resetCurrentPipeline,
  allowRefreshingPipeline,
  resetCurrentKubeflowPipeline,
  setCurrentKubeflowPipeline,
} from "@/redux/project/automation/pipeline";
// components
import Page from "@/components/project/Page";
import PipelineConstructionPanel from "./components/pipelinePage/PipelineConstructionPanel";
import { PATH_DASHBOARD } from "@/routes/paths";
import {
  getPipelineDetailData,
  getPipelineVersionDetail,
  getPipelineVolumeDetailData,
  getPipelineVolumeTableData,
  getRelPipelineVolumeData,
} from "@/redux/project/automation/thunks";
import {
  getPipelineStorageData,
  getPipelineTemplateDetail,
  getPipelineTemplateVersionData,
} from "@/redux/project/template/thunks";
import { parsePipelineJson } from "./utils";
import { resetPipelineData } from "@/redux/project/automation/slice";
import KubeflowExecutionTable from "./pipeline-execution/Kubeflow/KubeflowExecutionTable";
import {
  getConnectionTableData,
  getCredentialTableData,
} from "@/redux/project/connection/thunks";
import { getFeatureSetList } from "@/redux/project/dataAsset/featureStore/thunks";
import { getModelPackageList } from "@/redux/project/dataAsset/modelRegistry/thunks";

// ----------------------------------------------------------------------

/* const SkeletonLoad = (
  <Grid container spacing={2}>
    <Grid item xs={12} md={6} lg={9}>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={600}
        sx={{ borderRadius: 2 }}
        // sx={{ paddingTop: "70%", borderRadius: 2 }}
      />
    </Grid>
    <Grid item xs={12} md={6} lg={3}>
      <Skeleton variant="circular" width={80} height={80} sx={{ mb: 1.7 }} />
      <Skeleton variant="text" height={70} sx={{ mb: 1 }} />
      <Skeleton variant="text" height={70} sx={{ mb: 1 }} />
      <Skeleton variant="text" height={70} sx={{ mb: 1 }} />
      <Skeleton variant="text" height={70} sx={{ mb: 1 }} />
      <Skeleton variant="text" height={70} sx={{ mb: 1 }} />
      <Skeleton variant="text" height={70} sx={{ mb: 1 }} />
    </Grid>
  </Grid>
); */

export default function PipelinePage() {
  // mode : is blank template or not
  const { pathname } = useLocation();

  const { mlPipelineId, versionId } = useParams();
  const dispatch = useDispatch();
  const isCreate = pathname.includes("/createVersion");
  const isDuplicate = pathname.includes("/duplicateVersion");
  const isEdit = !isCreate && !isDuplicate; // canvas is disabled to be edited, can add trigger and executions
  const { enqueueSnackbar } = useSnackbar();

  const { pipelineType } = useSelector((state: RootState) => state.schema);
  const isKubeflow = pipelineType === "Kubeflow";

  const {
    pipelineDetail: { data: detailData = {} },
    pipelineVersionDetail: { data: versionDetail = {} },
  } = useSelector((state: RootState) => state.automation);
  const name = detailData?.name || "";

  // kubeflow cluster
  const [kubeflowCluster, setKubeflowCluster] = useState<string>("default-k8s");

  /* const schema = useSelector(
    (state: RootState) => state.schema.schema,
    shallowEqual
  ); */

  const shouldRefreshPipeline = useSelector(
    (state: RootState) => state.pipeline.shouldRefreshPipeline,
    shallowEqual
  );

  const pipelineArn = useSelector(
    (state: RootState) => state.pipeline.currentPipeline.PipelineArn,
    shallowEqual
  );

  const {
    userDetail: { id, user_id },
  } = useSelector((state: RootState) => state.common);

  const getLinks = () => {
    return [
      {
        name: "Automation",
      },
      {
        name: "ML Pipeline",
        href: PATH_DASHBOARD.automation.mlPipeline,
      },
      {
        name,
        href: `${PATH_DASHBOARD.automation.mlPipeline}/${mlPipelineId}`,
      },
      ...(isEdit
        ? [
            {
              name: versionDetail?.name || "",
            },
          ]
        : [
            {
              name: "Create Version",
            },
          ]),
    ];
  };

  const getExtraAction = () => {
    /* let isKubeflow = true;
    if (isDuplicate) {
      isKubeflow = state?.curPipeline.type === 'Kubeflow';
    }else {
      isKubeflow = state?.createPipelineType === 'Kubeflow'
    } */
    return isKubeflow ? (
      <Stack direction="row" alignItems="center" spacing={2} sx={{ pr: 2 }}>
        <Chip
          label="Kubeflow"
          color="secondary"
          sx={{
            height: "40px",
            bgcolor: (theme) => theme.palette.secondary.light,
          }}
        />
        <Box sx={{ width: "150px" }}>
          <FormControl fullWidth margin="dense" size="small">
            <InputLabel>Kubernetes Cluster</InputLabel>
            <Select
              name="kubeflowCluster"
              label="Kubernetes Cluster"
              value={kubeflowCluster}
              onChange={(event) => {
                setKubeflowCluster(event.target.value);
              }}
            >
              <MenuItem value="default-k8s">default-k8s</MenuItem>
            </Select>
            {/* <FormHelperText sx={{ minHeight: '18px', marginTop: '2px' }}></FormHelperText> */}
          </FormControl>
        </Box>
      </Stack>
    ) : (
      <Box sx={{ pr: 2 }}>
        <Chip
          label="AWS On Cloud"
          color="primary"
          sx={{
            height: "40px",
            bgcolor: (theme) => theme.palette.warning.light,
          }}
        />
      </Box>
    );
  };

  const getTemplateData = async (versionId) => {
    // get the latest template version to get commit _id
    const { items } = await dispatch(
      getPipelineTemplateVersionData({
        params: {
          id: versionId,
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    // get template name
    const r = await dispatch(
      getPipelineTemplateDetail({
        id: items?.[0]?.mlpipeline_template_id,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    const res = await dispatch(
      getPipelineStorageData({
        params: {
          provider: "gitlab",
          location: `pipeline-template-test`,
          // file_path: data.name,
          file_path: `${r.name}/pipeline_template.json`,
          id: items[0].git_commit_id,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    const { edges, steps, pipelineParamList } = parsePipelineJson(res.content);
    dispatch(
      setSchemaData({
        schema: {
          name: "",
          tags: [],
          relationships: [],
          pipelineRoleArn: "",
          edgeEntities: edges,
          pipelineSteps: steps,
        },
      })
    );

    dispatch(setCurrentKubeflowPipeline({ pipelineParamList }));
  };

  const getVolumeData = () => {
    // getVolumList
    dispatch(
      getPipelineVolumeTableData({
        params: {
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );

    // get rel_mlpipeline_volume
    dispatch(
      getRelPipelineVolumeData({
        params: {
          mlpipeline_id: mlPipelineId!,
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    )
      .unwrap()
      .then((res) => {
        // get volume name
        dispatch(
          getPipelineVolumeDetailData({
            id: res?.items?.[0]?.mlpipeline_volume_id,
            alertCallback: enqueueSnackbar,
            pathname,
          })
        )
          .unwrap()
          .then((r) => {
            dispatch(
              setCurrentKubeflowPipeline({
                pipelineVolume: r?.name,
              })
            );
          });
      });
  };

  const handleRenderCanvas = async () => {
    const res = await dispatch(
      getPipelineVersionDetail({
        id: versionId!,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    // get version json from s3
    const result = await dispatch(
      getPipelineStorageData({
        params: {
          provider: "s3",
          file_path: `${mlPipelineId}/${res.name}.json`,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    const { edges, steps, pipelineParamList } = parsePipelineJson(
      result.content
    );
    dispatch(
      setSchemaData({
        schema: {
          name: "",
          tags: [],
          relationships: [],
          pipelineRoleArn: "",
          edgeEntities: edges,
          pipelineSteps: steps,
        },
      })
    );

    dispatch(setCurrentKubeflowPipeline({ pipelineParamList }));

    // if duplicate clear pipeVersionDetail, manifest the s3 file has been uploaded (cause we need exp_id、 version_id)
    if (isDuplicate) {
      dispatch(resetPipelineData("pipelineVersionDetail"));
    }

    // only detail need get triggers and executions (done in corresponding components)
  };

  const handleInit = () => {
    // get existed featureset and modelpackage
    dispatch(
      getFeatureSetList({
        jwt_id: id,
        jwt_user_id: user_id,
        params: {
          page_num: 1,
          page_size: 9999,
        },
        isList: true,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
    dispatch(
      getModelPackageList({
        jwt_id: id,
        jwt_user_id: user_id,
        params: {
          page_num: 1,
          page_size: 9999,
        },
        isList: true,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );

    // get connection
    dispatch(
      getConnectionTableData({
        params: {
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
    // get credential
    dispatch(
      getCredentialTableData({
        params: {
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );

    dispatch(
      getPipelineDetailData({
        id: mlPipelineId!,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    )
      .unwrap()
      .then((res) => {
        dispatch(
          setCurrentKubeflowPipeline({
            PipelineName: res?.name,
            description: res?.description,
          })
        );

        dispatch(
          setSchemaData({ pipelineType: res?.mlpipeline_type || "Kubeflow" })
        );

        if (isCreate) {
          // get the pipeline_template json to render
          getTemplateData(res.mlpipeline_template_version_id);
        }
      });

    getVolumeData();
  };

  /*   useEffect(() => {
    if (!isDuplicate) {
      dispatch(clearSchema((state?.mode === 'blank')));
    }
  }, [dispatch, pathname]); */

  useEffect(() => {
    if (isDuplicate || isEdit) {
      handleRenderCanvas();
    }
  }, []);

  useEffect(() => {
    if (name.length > 0) {
      if (shouldRefreshPipeline) {
        // dispatch(getPipelineDescription(name, enqueueSnackbar));
        // dispatch(getPipelineExecutionList(name));
        // dispatch(getPipelineTriggers(name));
      } else {
        dispatch(allowRefreshingPipeline());
      }
    }
  }, [dispatch, name]);

  useEffect(() => {
    if (pipelineArn.length > 0) {
      if (shouldRefreshPipeline) {
        const items = pipelineArn.split("/");
        // dispatch(getPipelineTriggers(items[items.length - 1]));
      } else {
        dispatch(allowRefreshingPipeline());
      }
    }
  }, [dispatch, pipelineArn]);

  /*   useEffect(() => {
    if (name.length === 0 && (state?.mode === 'blank')) {
      // use blank template to create
      dispatch(
        resetCurrentPipeline({
          defaultName: `Pipeline-${timeRef.current}`,
          paramList: schema.pipelineParameters,
          roleArn: schema.pipelineRoleArn,
        })
      );
    }
  }, [dispatch, name, schema.pipelineRoleArn, schema.pipelineParameters]); */

  // clear pipeline redux state when leave
  useEffect(() => {
    return () => {
      dispatch(clearSchema(true));
      dispatch(
        resetCurrentPipeline({
          defaultName: "",
          roleArn: "",
        })
      );
      dispatch(resetCurrentKubeflowPipeline());

      dispatch(resetPipelineData("pipelineVersionDetail"));

      dispatch(resetPipelineData("pipelineExecutionTableList"));
    };
  }, []);

  useEffect(() => {
    handleInit();

    // search the s3 file, if exist, disable the canvas (when create version success, or view the canvas)
    // if duplicate s3 not exist , just another create
  }, []);

  return (
    <Page
      heading={isEdit ? `${versionDetail.name}` : "Create Version"}
      title="Create Version | OmniML"
      links={getLinks()}
      action={getExtraAction()}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <PipelineConstructionPanel isEdit={isEdit} name={name} />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          {/* <ExecutionTable pipelineName={name} /> */}
          <KubeflowExecutionTable />
        </Grid>
      </Grid>
    </Page>
  );
}

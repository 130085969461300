import { TOKEN_FLAG } from "@/config";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import Decimal from "decimal.js";

const { config } = JSON.parse(localStorage.getItem("redux-common") || "{}");
const { common: { BASE_URL = "", SSO_LOGOUT_URL = "" } = {} } = JSON.parse(
  config || "{}"
);

const http = axios.create({
  baseURL: BASE_URL,
  // timeout: 5000,
});

// request
http.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token: string | null = localStorage.getItem(TOKEN_FLAG);
    if (token) {
      config.headers = config.headers || {};
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  }
);

// response
http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      localStorage.clear();
      window.location.replace(SSO_LOGOUT_URL);
    }
    return Promise.reject(error);
  }
);

const makeRequest = (method, url, params, data, cancelToken?) => {
  const config = {
    method,
    url,
    data,
    params,
    ...(cancelToken ? { cancelToken } : {}),
  };

  return http(config);
};

const resourceRequest = {
  get(url, params?, cancelToken?): Promise<AxiosResponse> {
    return makeRequest("get", url, params, undefined, cancelToken);
  },
};

export default resourceRequest;

export const queryResourceQuota = (data) => {
  return resourceRequest.get(`/prometheus/api/v1/query`, data);
};

export const queryRestResource = async (data: {
  namespace: string;
  resource?: string;
}) => {
  const { namespace, resource } = data;
  const getQueryStr = (type) => {
    return resource
      ? `kube_resourcequota{namespace="${namespace}", resource="${resource}", type="${type}"}`
      : `kube_resourcequota{namespace="${namespace}", type="${type}"}`;
  };

  // used
  const used = await queryResourceQuota({
    query: getQueryStr("used"),
    time: Date.now() / 1000,
  });

  // all
  const all = await queryResourceQuota({
    query: getQueryStr("hard"),
    time: Date.now() / 1000,
  });

  return {
    used: used?.data?.data?.result || [],
    all: all?.data?.data?.result || [],
  };
};

export const restResource = (used, all, resource) => {
  const usedItem =
    used?.find((item) => item?.metric?.resource === resource) || {};

  const usedCount = usedItem?.value?.[1] || 0;

  const allItem =
    all?.find((item) => item?.metric?.resource === resource) || {};
  const allCount = allItem?.value?.[1] || 0;

  const usedNum = new Decimal(Number(usedCount));
  const allNum = new Decimal(Number(allCount));

  const computeNum = String(allNum.minus(usedNum));
  return computeNum;
};

// init resource_quota
export const getInitResourceQuota = (project_id, setResourceQuotaFunc) => {
  const getResource = (used, all, resource) => {
    const usedItem =
      used?.find((item) => item?.metric?.resource === resource) || {};
    const usedCount = String(usedItem?.value?.[1] || 0);

    const allItem =
      all?.find((item) => item?.metric?.resource === resource) || {};
    const allCount = String(allItem?.value?.[1] || 0);

    return { usedCount, allCount };
  };

  // all resource
  queryRestResource({
    namespace: `taichi-prj-${project_id}`,
  }).then(({ used, all }) => {
    setResourceQuotaFunc({
      cpu: {
        used: Number(getResource(used, all, "cpu").usedCount).toFixed(2),
        all: Number(getResource(used, all, "cpu").allCount).toFixed(2),
      },
      memory: {
        used: (
          Number(getResource(used, all, "memory").usedCount) /
          (1024 * 1024 * 1024)
        ).toFixed(2),
        all: (
          Number(getResource(used, all, "memory").allCount) /
          (1024 * 1024 * 1024)
        ).toFixed(2),
      },
      gpu: {
        used: Number(
          getResource(used, all, "nvidia.com/gpu").usedCount
        ).toFixed(2),
        all: Number(getResource(used, all, "nvidia.com/gpu").allCount).toFixed(
          2
        ),
      },
      storage: {
        used: (
          Number(getResource(used, all, "requests.storage").usedCount) /
          (1024 * 1024 * 1024)
        ).toFixed(2),
        all: (
          Number(getResource(used, all, "requests.storage").allCount) /
          (1024 * 1024 * 1024)
        ).toFixed(2),
      },
    });
  });
};

import MlNumberInputField from "@/components/project/mlComponents/MlNumberInputField";
import { TextField } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import { Field } from "formik";

interface IProps {
  formik: any;
}

const ResourceQuota = ({ formik }: IProps) => {
  const { errors, touched } = formik || {};

  return (
    <>
      <MlNumberInputField
        name="resource.cpu"
        label="CPU"
        formik={formik}
        helperText={
          (touched?.resource?.cpu && errors?.resource?.cpu) ||
          "Across all pods in a non-terminal state, the sum of CPU requests cannot exceed this value."
        }
        isDouble
      />
      <MlNumberInputField
        name="resource.memory"
        label="Memory"
        formik={formik}
        helperText={
          (touched?.resource?.memory && errors?.resource?.memory) ||
          "Across all pods in a non-terminal state, the sum of memory requests cannot exceed this value."
        }
        isDouble
      />
      <MlNumberInputField
        name="resource.storage"
        label="Storage"
        formik={formik}
        helperText={
          (touched?.resource?.storage && errors?.resource?.storage) ||
          "Across all persistent volume claims, the sum of storage requests cannot exceed this value."
        }
        isDouble
      />
      <MlNumberInputField
        name="resource.ephemeral_storage"
        label="Ephemeral Storage"
        formik={formik}
        helperText={
          (touched?.resource?.ephemeral_storage &&
            errors?.resource?.ephemeral_storage) ||
          "Across all pods in the namespace, the sum of local ephemeral storage requests cannot exceed this value."
        }
        isDouble
      />

      <Typography variant="body2" paddingY={2}>
        Gpu
      </Typography>

      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={6}>
          <Field
            as={TextField}
            disabled
            name="resource.gpu_type"
            label="GPU Type"
            size="small"
            fullWidth
            margin="dense"
            helperText={
              touched?.resource?.gpu_type && errors?.resource?.gpu_type
            }
            error={
              touched?.resource?.gpu_type && Boolean(errors?.resource?.gpu_type)
            }
            FormHelperTextProps={{
              sx: { minHeight: "18px", marginTop: "2px" },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MlNumberInputField
            name="resource.gpu_number"
            label="GPU Number"
            formik={formik}
            helperText={
              (touched?.resource?.gpu_number && errors?.resource?.gpu_number) ||
              " "
            }
            isDouble
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ResourceQuota;

import {
  Box,
  Collapse,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Stack,
} from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { Typography } from "@mui/material";
import { Field } from "formik";
import { Select } from "@mui/material";
import { useState } from "react";

interface IProps {
  formik: any;
}

const AdvancedSettings = ({ formik }: IProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const { values, errors, touched } = formik;

  return (
    <Box sx={{ mb: 2 }}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ cursor: "pointer", width: 200 }}
        onClick={() => setOpen(!open)}
      >
        <ExpandMoreOutlinedIcon
          sx={{
            transform: open ? "rotate(0)" : "rotate(-90deg)",
            transition: "transform 0.5s",
          }}
        />
        <Typography variant="subtitle1" paddingY={2}>
          Advanced Settings
        </Typography>
      </Stack>
      <Collapse in={open}>
        <FormControl
          fullWidth
          margin="dense"
          size="small"
          error={
            touched?.advancedSettings?.billing &&
            Boolean(errors?.advancedSettings?.billing)
          }
        >
          <InputLabel>Billing</InputLabel>
          <Field as={Select} name="advancedSettings.billing" label="Billing">
            <MenuItem value="elastic">Elastic</MenuItem>
            <MenuItem value="shared">Shared</MenuItem>
          </Field>
          <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
            {touched?.advancedSettings?.billing &&
            errors?.advancedSettings?.billing
              ? errors?.advancedSettings?.billing
              : ""}
          </FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          margin="dense"
          size="small"
          error={
            touched?.advancedSettings?.provider &&
            Boolean(errors?.advancedSettings?.provider)
          }
        >
          <InputLabel>Provider</InputLabel>
          <Field as={Select} name="advancedSettings.provider" label="Provider">
            <MenuItem value="aws">AWS</MenuItem>
            <MenuItem value="on-prem">On-prem</MenuItem>
          </Field>
          <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
            {touched?.advancedSettings?.provider &&
            errors?.advancedSettings?.provider
              ? errors?.advancedSettings?.provider
              : ""}
          </FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          margin="dense"
          size="small"
          error={
            touched?.advancedSettings?.region &&
            Boolean(errors?.advancedSettings?.region)
          }
        >
          <InputLabel>Region</InputLabel>
          <Field as={Select} name="advancedSettings.region" label="Region">
            {values.advancedSettings.provider !== "aws" && (
              <MenuItem value="shanghai">shanghai</MenuItem>
            )}
            <MenuItem value="beijing">beijing</MenuItem>
          </Field>
          <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
            {touched?.advancedSettings?.region &&
            errors?.advancedSettings?.region
              ? errors?.advancedSettings?.region
              : ""}
          </FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          margin="dense"
          size="small"
          error={
            touched?.advancedSettings?.node_type &&
            Boolean(errors?.advancedSettings?.node_type)
          }
        >
          <InputLabel>Node Type</InputLabel>
          <Field
            as={Select}
            name="advancedSettings.node_type"
            label="Node Type"
          >
            <MenuItem value="gpu">gpu</MenuItem>
            <MenuItem value="cpu">cpu</MenuItem>
          </Field>
          <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
            {touched?.advancedSettings?.node_type &&
            errors?.advancedSettings?.node_type
              ? errors?.advancedSettings?.node_type
              : ""}
          </FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          margin="dense"
          size="small"
          error={
            touched?.advancedSettings?.node_linux_arch &&
            Boolean(errors?.advancedSettings?.node_linux_arch)
          }
        >
          <InputLabel>Node Linux Arch</InputLabel>
          <Field
            as={Select}
            name="advancedSettings.node_linux_arch"
            label="Node Linux Arch"
          >
            <MenuItem value="x86">x86</MenuItem>
          </Field>
          <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
            {touched?.advancedSettings?.node_linux_arch &&
            errors?.advancedSettings?.node_linux_arch
              ? errors?.advancedSettings?.node_linux_arch
              : ""}
          </FormHelperText>
        </FormControl>
      </Collapse>
    </Box>
  );
};

export default AdvancedSettings;

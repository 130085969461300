import { createSlice } from "@reduxjs/toolkit";
import { addThunkReducers } from "../utils";

import { IExperimentState } from "@/@types/project/model/experiment";
import {
  getHptunerDetail,
  getHptunerResult,
  getHptunerTableData,
  getNotebookDetailData,
  getNotebookTableData,
  getNotebookVersionDetail,
  getNotebookVersionTableData,
  getNotebookVolumeDetailData,
  getNotebookVolumeTableData,
} from "./thunks";

const initialState: IExperimentState = {
  notebookTableList: {
    loading: false,
    data: {},
    error: null,
  },
  notebookDetail: {
    loading: false,
    data: {},
    error: null,
  },
  notebookVolumeTableList: {
    loading: false,
    data: {},
    error: null,
  },
  notebookVolumeDetail: {
    loading: false,
    data: {},
    error: null,
  },
  notebookVersionTableList: {
    loading: false,
    data: {},
    error: null,
  },
  notebookVersionDetail: {},
  hptunerTableList: {
    loading: false,
    data: {},
    error: null,
  },
  hptunerDetail: {
    loading: false,
    data: {},
    error: null,
  },
  hptunerResult: {
    loading: false,
    data: {},
    error: null,
  },
};

const slice = createSlice({
  name: "experiment",
  initialState,
  reducers: {
    setExperimentData(state, action) {
      Object.assign(state, action.payload);
    },
    resetNotebookData(state, action) {
      Object.assign(state[action.payload], { data: {} });
    },
  },
  extraReducers: (builder) => {
    addThunkReducers(builder, [
      { action: getNotebookTableData, name: "notebookTableList" },
      { action: getNotebookDetailData, name: "notebookDetail" },
      { action: getNotebookVolumeTableData, name: "notebookVolumeTableList" },
      { action: getNotebookVolumeDetailData, name: "notebookVolumeDetail" },
      { action: getNotebookVersionTableData, name: "notebookVersionTableList" },
      {
        action: getNotebookVersionDetail,
        name: "notebookVersionDetail",
        options: { updateDirectly: true },
      },
      { action: getHptunerTableData, name: "hptunerTableList" },
      { action: getHptunerDetail, name: "hptunerDetail" },
      { action: getHptunerResult, name: "hptunerResult" },
    ]);
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setExperimentData, resetNotebookData } = slice.actions;

import { Icon } from "@iconify/react";
import bxsTagAlt from "@iconify/icons-bx/bxs-tag-alt";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { Box, Stack, Collapse, Typography } from "@mui/material";
//
import Scrollbar from "@/components/project/Scrollbar";
import { SchemaNodeEntity } from "../graph/slices/slice";
import {
  stepTypeColorMapper,
  kubeflowStepTypeColorMapper,
} from "./StepPopover";
import { CollapseButtonStyle } from "./SettingSidebar";
import EmptyTable from "@/components/project/EmptyTable";
import { useSelector } from "@/redux/store";
import { RootState } from "@/redux/rootReducer";

// ----------------------------------------------------------------------
type PipelineStepsProps = {
  allSteps: Array<SchemaNodeEntity>;
  isCollapse: boolean;
  onCollapse: VoidFunction;
};

export default function PipelineSteps({
  allSteps,
  isCollapse,
  onCollapse,
}: PipelineStepsProps) {
  const { pipelineType } = useSelector((state: RootState) => state.schema);
  const commonStepColorMap =
    pipelineType === "Kubeflow"
      ? kubeflowStepTypeColorMapper
      : stepTypeColorMapper;

  const StepTag = ({
    stepType,
    quantity,
  }: {
    stepType: string;
    quantity: number;
  }) => {
    return (
      <>
        <Stack
          direction="row"
          spacing={1}
          sx={{ mx: 2, my: 1, justifyContent: "space-between" }}
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Icon
              icon={bxsTagAlt}
              width={16}
              height={16}
              color={commonStepColorMap[stepType]}
            />
            <Typography variant="body2" sx={{ color: "text.primary" }}>
              {stepType}
            </Typography>
          </Stack>
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            {quantity}
          </Typography>
        </Stack>
      </>
    );
  };

  const getUniqueStepList = (allSteps: Array<SchemaNodeEntity>) => {
    let counts: { [key: string]: any } = {};
    allSteps.map(
      (step) => (counts[step.stepType] = 1 + (counts[step.stepType] || 0))
    );
    return counts;
  };

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={onCollapse}
        endIcon={
          <Icon
            icon={isCollapse ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
      >
        Pipeline Steps {allSteps.length > 0 && `(${allSteps.length})`}
      </CollapseButtonStyle>

      <Box sx={{ mb: isCollapse ? 0.5 : 0 }}>
        <Scrollbar>
          <Collapse in={isCollapse}>
            {Object.keys(getUniqueStepList(allSteps)).map((step, index) => (
              <StepTag
                key={index}
                stepType={step}
                quantity={getUniqueStepList(allSteps)[step]}
              />
            ))}
          </Collapse>

          {isCollapse &&
            Object.keys(getUniqueStepList(allSteps)).length === 0 && (
              <EmptyTable
                mainTitle={"No pipeline steps"}
                emptyImage={false}
                content={""}
                sx={{ backgroundColor: "transparent", my: 9 }}
              />
            )}
        </Scrollbar>
      </Box>
    </>
  );
}

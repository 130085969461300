// @mui
import {
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogProps,
} from "@mui/material";
//

interface ConfirmDialogProps extends Omit<DialogProps, "title" | "content"> {
  title: React.ReactNode;
  action: React.ReactNode;
  open: boolean;
  onClose: VoidFunction;
  content?: React.ReactNode;
}

export default function ConfirmDialog({
  title,
  content,
  action,
  open,
  onClose,
  ...other
}: ConfirmDialogProps) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      {content && (
        <DialogContent sx={{ typography: "body2" }}> {content} </DialogContent>
      )}

      <DialogActions>
        {action}

        <Button
          variant="outlined"
          color="inherit"
          onClick={onClose}
          sx={{ width: "80px" }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { filter } from "lodash";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PipelineManager, PipelineParameter } from "@/@types/pipeline";
import { importFromSmPipelineDef } from "@/pages/project/automation/MlPipeline/graph/slices/thunks";
import { dispatch } from "@/redux/store";
import axios from "axios";
import { handleKubeflowPipelineParametersCurd } from "../utils";

// ----------------------------------------------------------------------

type PipelineState = {
  isLoading: boolean;
  isLoadingPipeline: boolean;
  isUploadingPipeline: boolean;
  isLoadingExecutionStatus: boolean;
  isLoadingExecutionParams: boolean;
  isLoadingExecutionSteps: boolean;
  isLoadingExecutionStepLog: boolean;
  shouldRefreshPipeline: boolean;
  error: boolean;
  pipelineList: PipelineManager[];
  currentPipeline: PipelineManager;
  currentKubeflowPipeline: Record<string, any>;
};

interface UpdatePipelineParameterPayload {
  isKubeflow: boolean;
  id?: number;
  data: PipelineParameter;
}

const initialState: PipelineState = {
  isLoading: false,
  isLoadingPipeline: false,
  isUploadingPipeline: false,
  isLoadingExecutionStatus: false,
  isLoadingExecutionParams: false,
  isLoadingExecutionSteps: false,
  isLoadingExecutionStepLog: false,
  shouldRefreshPipeline: true,
  error: false,
  pipelineList: [],
  currentPipeline: {
    id: "",
    avatarUrl: "",
    PipelineName: "",
    description: "",
    email: "",
    CreationTime: "",
    LastModifiedTime: "",
    executions: 0,
    scenario: "",
    tags: [],
    lastExecution: "",
    dataType: "",
    modelType: "",
    PipelineArn: "",
    RoleArn: "",
    latestExecutionStatus: "none",
    triggerList: [],
    executionList: [],
    pipelineParamList: [],
    // loadingMetaData: false,
    // loadingExecutionInfo: false,
    loadingPipelineInfo: false,
    hasLoadedPipelineInfo: false,
  },
  currentKubeflowPipeline: {
    id: "",
    PipelineName: "",
    pipelineVolume: "",
    description: "",
    pipelineParamList: [],
    triggerList: [],
    executionList: [],
    advancedSettings: {
      billing: "elastic",
      provider: "aws",
      region: "beijing",
      node_type: "cpu",
      node_linux_arch: "x86",
    },
  },
};

const slice = createSlice({
  name: "pipeline",
  initialState,
  reducers: {
    setPipelineData(state, action) {
      Object.assign(state, action.payload);
    },
    setCurrentKubeflowPipeline(state, action) {
      Object.assign(state.currentKubeflowPipeline, action.payload);
    },
    resetCurrentKubeflowPipeline(state) {
      Object.assign(
        state.currentKubeflowPipeline,
        initialState.currentKubeflowPipeline
      );
    },
    handleKubeflowParameters(state, action) {
      handleKubeflowPipelineParametersCurd(state, action);
    },

    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // START LOADING Pipeline
    startLoadingPipeline(state) {
      state.isLoadingPipeline = true;
    },
    // START UPLOADING Pipeline
    startUploadingPipeline(state) {
      state.isUploadingPipeline = true;
    },
    // STOP UPLOADING Pipeline
    stopUploadingPipeline(state) {
      state.isUploadingPipeline = false;
    },
    // STOP LOADING Pipeline
    stopLoadingPipeline(state) {
      state.isLoadingPipeline = false;
    },
    // PREVENT REFRESHING Pipeline
    preventRefreshingPipeline(state) {
      state.shouldRefreshPipeline = false;
    },
    // ALLOW REFRESHING Pipeline
    allowRefreshingPipeline(state) {
      state.shouldRefreshPipeline = true;
    },
    // START LOADING EXECUTION PARAMS
    startLoadingExecutionParams(state) {
      state.isLoadingExecutionParams = true;
    },
    // STOP LOADING EXECUTION PARAMS
    stopLoadingExecutionParams(state) {
      state.isLoadingExecutionParams = false;
    },
    // START LOADING EXECUTION INFO
    startLoadingExecutionSteps(state) {
      state.isLoadingExecutionSteps = true;
    },
    // START LOADING EXECUTION PARAMS
    startLoadingExecutionStatus(state) {
      state.isLoadingExecutionStatus = true;
    },
    // START LOADING EXECUTION LOG INFO
    startLoadingExecutionStepLog(state) {
      state.isLoadingExecutionStepLog = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isLoadingPipeline = false;
      state.isUploadingPipeline = false;
      state.shouldRefreshPipeline = true;
      state.isLoadingExecutionParams = false;
      state.isLoadingExecutionSteps = false;
      state.isLoadingExecutionStepLog = false;
      state.isLoadingExecutionStatus = false;
      state.error = action.payload;
    },
    // DELETE PIPELINES
    deletePipeline(state, action) {
      state.isLoading = false;
      const deletePipeline = filter(
        state.pipelineList,
        (pipeline) => pipeline.PipelineName !== action.payload
      );
      state.pipelineList = deletePipeline;
    },
    // GET PIPELINE METADATA
    // startLoadingPipelineMetaData(state, action) {
    //   const pipeline = state.pipelineList.find(
    //     (pipeline) => pipeline.PipelineName === action.payload.pipelineName
    //   );

    //   if (pipeline) {
    //     pipeline.loadingMetaData = true;
    //   }
    // },
    // failLoadingPipelineMetaData(state, action) {
    //   const pipeline = state.pipelineList.find(
    //     (pipeline) => pipeline.PipelineName === action.payload.pipelineName
    //   );

    //   if (pipeline) {
    //     state.error = action.payload.error;
    //     pipeline.loadingMetaData = false;
    //   }
    // },
    // GET PIPELINE INFO
    startLoadingPipelineInfo(state, action) {
      const pipeline = state.pipelineList.find(
        (pipeline) => pipeline.PipelineName === action.payload.pipelineName
      );

      if (pipeline) {
        pipeline.loadingPipelineInfo = true;
      }
    },
    failLoadingPipelineInfo(state, action) {
      const pipeline = state.pipelineList.find(
        (pipeline) => pipeline.PipelineName === action.payload.pipelineName
      );

      if (pipeline) {
        state.error = action.payload.error;
        pipeline.loadingPipelineInfo = false;
      }
    },
    getPipelineInfoSuccess(state, action) {
      const pipeline = state.pipelineList.find(
        (pipeline) => pipeline.PipelineName === action.payload.pipelineName
      );

      if (pipeline) {
        pipeline.loadingPipelineInfo = false;
        pipeline.hasLoadedPipelineInfo = true;
        if (action.payload.email) pipeline.email = action.payload.email;

        if (action.payload.scenario)
          pipeline.scenario = action.payload.scenario;

        if (action.payload.tags && action.payload.tags.length > 0)
          pipeline.tags = action.payload.tags.split(",");

        if (action.payload.executionNumber !== undefined)
          pipeline.executions = action.payload.executionNumber;

        if (action.payload.latestExecutionStatus !== undefined)
          pipeline.latestExecutionStatus = action.payload.latestExecutionStatus;
      }
    },
    // getPipelineMetaDataSuccess(state, action) {
    //   const pipeline = state.pipelineList.find(
    //     (pipeline) => pipeline.PipelineName === action.payload.pipelineName
    //   );

    //   if (pipeline) {
    //     pipeline.loadingMetaData = false;
    //     pipeline.hasLoadedPipelineInfo = true;
    //     if (action.payload.email) pipeline.email = action.payload.email;
    //     if (action.payload.scenario)
    //       pipeline.scenario = action.payload.scenario;
    //     if (action.payload.tags && action.payload.tags.length > 0)
    //       pipeline.tags = action.payload.tags.split(",");
    //   }
    // },
    // GET PIPELINES
    getPipelineListSuccess(state, action) {
      state.isLoading = false;
      state.pipelineList = action.payload ? action.payload : [];
    },
    // GET EXECUTIONS
    getExecutionListSuccess(state, action) {
      state.isLoading = false;
      state.currentPipeline.executionList = action.payload;

      for (const execution of state.currentPipeline.executionList) {
        if (execution.PipelineExecutionDescription) {
          try {
            const items = JSON.parse(execution.PipelineExecutionDescription);
            const keys = Object.keys(items);

            if (keys.includes("createdby"))
              execution.CreatedBy = items["createdby"];

            if (keys.includes("description"))
              execution.PipelineExecutionDescription = items["description"];
            else execution.PipelineExecutionDescription = "";
          } catch (error) {
            continue;
          }
        }
      }
      // console.log(state.currentPipeline.executionList);

      // Update execution status in pipeline list
      const pipeline = state.pipelineList.find(
        (pipeline) =>
          pipeline.PipelineName === state.currentPipeline.PipelineName
      );

      if (pipeline && state.currentPipeline.executionList.length > 0) {
        pipeline.latestExecutionStatus =
          state.currentPipeline.executionList[0].PipelineExecutionStatus;
      }
    },
    // GET EXECUTION NUMBER
    // startLoadingExecutionInfo(state, action) {
    //   const pipeline = state.pipelineList.find(
    //     (pipeline) => pipeline.PipelineName === action.payload.pipelineName
    //   );

    //   if (pipeline) {
    //     pipeline.loadingExecutionInfo = true;
    //   }
    // },
    // failLoadingExecutionInfo(state, action) {
    //   const pipeline = state.pipelineList.find(
    //     (pipeline) => pipeline.PipelineName === action.payload.pipelineName
    //   );

    //   if (pipeline) {
    //     state.error = action.payload.error;
    //     pipeline.loadingExecutionInfo = false;
    //   }
    // },
    // getPipelineExecutionInfoSuccess(state, action) {
    //   const pipeline = state.pipelineList.find(
    //     (pipeline) => pipeline.PipelineName === action.payload.pipelineName
    //   );

    //   if (pipeline) {
    //     pipeline.loadingExecutionInfo = false;
    //     if (action.payload.executionNumber !== undefined)
    //       pipeline.executions = action.payload.executionNumber;

    //     if (action.payload.latestExecutionStatus !== undefined)
    //       pipeline.latestExecutionStatus = action.payload.latestExecutionStatus;
    //   }
    // },
    // GET PIPELINE EXECUTION METADATA
    startLoadingExecutionMetaData(state, action) {
      const execution = state.currentPipeline.executionList.find(
        (execution) =>
          execution.PipelineExecutionArn === action.payload.PipelineExecutionArn
      );

      if (execution) {
        execution.loadingMetaData = true;
      }
    },
    getExecutionMetaDataSuccess(state, action) {
      const execution = state.currentPipeline.executionList.find(
        (execution) =>
          execution.PipelineExecutionArn === action.payload.PipelineExecutionArn
      );

      if (execution) {
        execution.loadingMetaData = false;
        // execution.CreatedBy =
        //   Object.keys(action.payload.CreatedBy).length === 0
        //     ? "N/A"
        //     : action.payload.CreatedBy.UserProfileName;
        execution.CreationTime = action.payload.CreationTime;
        execution.LastModifiedTime = action.payload.LastModifiedTime;
      }
    },
    failLoadingExecutionMetaData(state, action) {
      const execution = state.currentPipeline.executionList.find(
        (execution) =>
          execution.PipelineExecutionArn === action.payload.PipelineExecutionArn
      );

      if (execution) {
        state.error = action.payload.error;
        execution.loadingMetaData = false;
      }
    },
    // GET PIPELINE EXECUTION PARAMETERS
    getExecutionStatusSuccess(state, action) {
      state.isLoadingExecutionStatus = false;
      const execution = state.currentPipeline.executionList.find(
        (execution) =>
          execution.PipelineExecutionArn === action.payload.PipelineExecutionArn
      );

      if (execution) {
        execution.PipelineExecutionStatus =
          action.payload.PipelineExecutionInfo.PipelineExecutionStatus;
        execution.ElapsedTime =
          action.payload.PipelineExecutionInfo.ElapsedTime;
      }
    },
    // GET PIPELINE EXECUTION PARAMETERS
    getExecutionParametersSuccess(state, action) {
      state.isLoadingExecutionParams = false;
      const execution = state.currentPipeline.executionList.find(
        (execution) =>
          execution.PipelineExecutionArn === action.payload.PipelineExecutionArn
      );

      if (execution) {
        execution.PipelineExecutionParameters =
          action.payload.PipelineExecutionParameters;
      }
    },
    // GET PIPELINE EXECUTION INFORMATION
    getExecutionStepsSuccess(state, action) {
      state.isLoadingExecutionSteps = false;
      const execution = state.currentPipeline.executionList.find(
        (execution) =>
          execution.PipelineExecutionArn === action.payload.PipelineExecutionArn
      );
      if (execution) {
        execution.PipelineExecutionSteps =
          action.payload.PipelineExecutionSteps;
        // console.log(execution.PipelineExecutionSteps);
      }
    },
    // GET PIPELINE EXECUTION PROCESSING JOB LOG
    getExecutionStepLogSuccess(state, action) {
      state.isLoadingExecutionStepLog = false;
      const execution = state.currentPipeline.executionList.find(
        (execution) =>
          execution.PipelineExecutionArn === action.payload.PipelineExecutionArn
      );
      if (execution) {
        const step = execution.PipelineExecutionSteps.find(
          (executionStep) => executionStep.StepName === action.payload.StepName
        );
        step.ExecutionLog = action.payload.ExecutionLog;
        step.ExecutionInfo = action.payload.ExecutionInfo;
        step.ExecutionInfoInput = action.payload.ExecutionInfoInput;
        step.ExecutionInfoOutput = action.payload.ExecutionInfoOutput;
        step.ExecutionInfoOther = action.payload.ExecutionInfoOther;
        step.ExecutionOutputFiles = action.payload.ExecutionOutputFiles;
        step.ExecutionInfoMetrics = action.payload.ExecutionInfoMetrics;
        // step.FailureReason = action.payload.FailureReason || null;
        // console.log("FailureReason");
        // console.log(step.FailureReason);
        // this section is for HP Tuning Jobs
        // TuningJobObjectiveList, TuningJobConfigurationList, WarmStartConfigList, ParameterRangeList,StaticHPList
        // bestTrainingJobList, TunedHPList, TrainingJobStatusList
        // step.TuningJobObjectiveList = action.payload.TuningJobObjectiveList;
        // step.TuningJobConfigurationList =
        //   action.payload.TuningJobConfigurationList;
        // step.WarmStartConfigList = action.payload.WarmStartConfigList;
        // step.ParameterRangeList = action.payload.ParameterRangeList;
        // step.StaticHPList = action.payload.StaticHPList;
        // step.bestTrainingJobList = action.payload.bestTrainingJobList;
        // step.TunedHPList = action.payload.TunedHPList;
        // step.TrainingJobStatusList = action.payload.TrainingJobStatusList;
        // console.log("Step Log");
        // console.log(step.ExecutionInfoInput);
      }
    },
    // GET PIPELINES
    getTriggerListSuccess(state, action) {
      state.currentPipeline.triggerList = action.payload ? action.payload : [];
    },
    // SET PIPELINE EMAIL
    setPipelineEmail(state, action) {
      state.currentPipeline.email = action.payload;
    },
    // SET PIPELINE DESCRIPTION
    setPipelineDescription(state, action) {
      state.currentPipeline.description = action.payload;
    },
    // SET PIPELINE SCENARIO
    setPipelineScenario(state, action) {
      state.currentPipeline.scenario = action.payload;
    },
    // SET PIPELINE TAGS
    setPipelineTags(state, action) {
      state.currentPipeline.tags = action.payload;
    },
    // SET PIPELINE ARN
    setPipelineArn(state, action) {
      state.currentPipeline.PipelineArn = action.payload;
    },
    // SET ROLE ARN
    setPipelineRoleArn(state, action) {
      state.currentPipeline.RoleArn = action.payload;
    },
    // SET CURRENT PIPELINE
    setCurrentPipeline(state, action) {
      const pipelineDef = JSON.parse(action.payload.PipelineDefinition);

      // console.log(action.payload.PipelineDefinition);
      const metaKeys = Object.keys(pipelineDef.Metadata);

      if (metaKeys.includes("description"))
        state.currentPipeline.description = pipelineDef.Metadata.description;
      else state.currentPipeline.description = "";

      if (metaKeys.includes("createdby"))
        state.currentPipeline.email = pipelineDef.Metadata.createdby;
      else state.currentPipeline.email = "";

      if (metaKeys.includes("scenario"))
        state.currentPipeline.scenario = pipelineDef.Metadata.scenario;
      else state.currentPipeline.scenario = "";

      if (metaKeys.includes("tags"))
        state.currentPipeline.tags = pipelineDef.Metadata.tags.split(",");
      else state.currentPipeline.tags = [];

      state.currentPipeline.PipelineName = action.payload.PipelineName;
      state.currentPipeline.PipelineArn = action.payload.PipelineArn;
      state.currentPipeline.RoleArn = action.payload.RoleArn;
      state.currentPipeline.pipelineParamList = pipelineDef.Parameters;
      // state.isLoadingPipeline = false;
    },
    // SET CURRENT PIPELINE
    setCurrentPipelineFromLocal(state, action) {
      state.currentPipeline = {
        id: "",
        description: "",
        avatarUrl: "",
        PipelineName: "",
        email: "",
        CreationTime: "",
        LastModifiedTime: "",
        executions: 0,
        tags: [],
        scenario: "",
        lastExecution: "",
        dataType: "",
        modelType: "",
        PipelineArn: "",
        latestExecutionStatus: "none",
        RoleArn: "",
        triggerList: [],
        executionList: [],
        pipelineParamList: [],
        // loadingMetaData: false,
        // loadingExecutionInfo: false,
        loadingPipelineInfo: false,
        hasLoadedPipelineInfo: false,
      };

      const pipelineDef = JSON.parse(action.payload.PipelineDefinition);
      const metaKeys = Object.keys(pipelineDef.Metadata);

      if (metaKeys.includes("description"))
        state.currentPipeline.description = pipelineDef.Metadata.description;
      else state.currentPipeline.description = "";

      if (metaKeys.includes("createdby"))
        state.currentPipeline.email = pipelineDef.Metadata.createdby;
      else state.currentPipeline.email = "";

      if (metaKeys.includes("scenario"))
        state.currentPipeline.scenario = pipelineDef.Metadata.scenario;
      else state.currentPipeline.scenario = "";

      if (metaKeys.includes("tags"))
        state.currentPipeline.tags = pipelineDef.Metadata.tags.split(",");
      else state.currentPipeline.tags = [];

      state.currentPipeline.PipelineName = action.payload.PipelineName;
      state.currentPipeline.pipelineParamList = pipelineDef.Parameters;
      // state.isLoadingPipeline = false;
    },
    // SET CURRENT PIPELINE
    setCurrentPipelineName(state, action) {
      state.currentPipeline.PipelineName = action.payload;
    },
    // RESET CURRENT PIPELIINE
    resetCurrentPipeline(state, action) {
      state.currentPipeline = {
        id: "",
        description: "",
        avatarUrl: "",
        PipelineName: action.payload.defaultName || "",
        email: "",
        CreationTime: "",
        LastModifiedTime: "",
        executions: 0,
        tags: [],
        scenario: "",
        lastExecution: "",
        dataType: "",
        modelType: "",
        PipelineArn: "",
        latestExecutionStatus: "none",
        RoleArn: action.payload.roleArn || "",
        triggerList: [],
        executionList: [],
        pipelineParamList:
          action.payload.paramList === undefined
            ? []
            : action.payload.paramList,
        // loadingMetaData: false,
        // loadingExecutionInfo: false,
        loadingPipelineInfo: false,
        hasLoadedPipelineInfo: false,
      };
    },
    // UPDATE PIPELINE PARAM
    updatePipelineParameter(
      state,
      action: PayloadAction<UpdatePipelineParameterPayload>
    ) {
      const isKubeflow = action.payload.isKubeflow;
      const pipelineDataFlagStr = isKubeflow
        ? "currentKubeflowPipeline"
        : "currentPipeline";

      if (action.payload.id !== undefined) {
        const pipelineParam =
          state[pipelineDataFlagStr].pipelineParamList[action.payload.id];
        if (pipelineParam) {
          pipelineParam.Name = action.payload.data.Name;
          pipelineParam.Type = action.payload.data.Type;
          if (action.payload.data.Type === "Integer") {
            pipelineParam.DefaultValue = Number(
              action.payload.data.DefaultValue
            );
          } else if (action.payload.data.Type === "Float") {
            pipelineParam.DefaultValue = Number(
              action.payload.data.DefaultValue
            );
            // if (pipelineParam.DefaultValue % 1 === 0) {
            //   pipelineParam.DefaultValue = pipelineParam.DefaultValue + ".0";
            //   console.log(pipelineParam.DefaultValue);
            // }
          } else if (action.payload.data.Type === "Boolean") {
            pipelineParam.DefaultValue = Boolean(
              String(action.payload.data.DefaultValue).toLowerCase() === "true"
            );
          } else {
            pipelineParam.DefaultValue = action.payload.data.DefaultValue;
          }
        }
      } else {
        const pipelineParam = {} as PipelineParameter;
        pipelineParam.Name = action.payload.data.Name;
        pipelineParam.Type = action.payload.data.Type;
        if (action.payload.data.Type === "Integer") {
          pipelineParam.DefaultValue = Number(action.payload.data.DefaultValue);
        } else if (action.payload.data.Type === "Boolean") {
          pipelineParam.DefaultValue = Boolean(
            action.payload.data.DefaultValue
          );
        } else {
          pipelineParam.DefaultValue = action.payload.data.DefaultValue;
        }
        state[pipelineDataFlagStr].pipelineParamList.push(pipelineParam);
      }
    },
    // DELETE PIPELINE PARAM
    deletePipelineParameter(
      state,
      action: PayloadAction<{ pipelineParamId: number; isKubeflow: boolean }>
    ) {
      const { pipelineParamId, isKubeflow } = action.payload;
      const pipelineDataFlagStr = isKubeflow
        ? "currentKubeflowPipeline"
        : "currentPipeline";

      if (pipelineParamId > -1) {
        state[pipelineDataFlagStr].pipelineParamList.splice(pipelineParamId, 1);
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setPipelineData,
  setCurrentKubeflowPipeline,
  resetCurrentKubeflowPipeline,
  handleKubeflowParameters,

  setPipelineArn,
  setPipelineTags,
  setPipelineRoleArn,
  stopLoadingPipeline,
  setPipelineScenario,
  resetCurrentPipeline,
  stopUploadingPipeline,
  startUploadingPipeline,
  setCurrentPipelineName,
  setPipelineDescription,
  updatePipelineParameter,
  deletePipelineParameter,
  allowRefreshingPipeline,
  preventRefreshingPipeline,
} = slice.actions;

// ----------------------------------------------------------------------

export function getPipelineList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log(axios.defaults.headers.common.Authorization);
      const response = await axios.get("/omniml/pipelines");
      if (response.status === 200) {
        dispatch(slice.actions.getPipelineListSuccess(response.data.pipelines));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function refreshPipelineList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/omniml/pipelines");
      if (response.status === 200) {
        dispatch(slice.actions.getPipelineListSuccess(response.data.pipelines));
        response.data.pipelines.forEach((pipeline: PipelineManager) => {
          dispatch(getPipelineInfo(pipeline.PipelineName));
        });
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createPipeline(
  pipelineName: String,
  roleArn: String,
  pipelineDef: String,
  callback: Function
) {
  return async () => {
    dispatch(slice.actions.startUploadingPipeline());
    try {
      const response = await axios.post("/omniml/pipelines/create", {
        PipelineName: pipelineName,
        RoleArn: roleArn,
        PipelineDefinition: pipelineDef,
      });
      if (response.status === 200 && response.data.success) {
        dispatch(setPipelineArn(response.data.PipelineArn));
        dispatch(getPipelineList());
        dispatch(slice.actions.stopUploadingPipeline());
        callback(`Pipeline "${pipelineName}" created`, "success");
      } else {
        dispatch(slice.actions.stopUploadingPipeline());
        dispatch(slice.actions.allowRefreshingPipeline());
        if (response.data.message !== undefined) {
          callback(response.data.message, "error");
        } else {
          callback(`Failed to create pipeline "${pipelineName}"`, "error");
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePipeline(
  pipelineName: String,
  roleArn: String,
  pipelineDef: String,
  callback: Function
) {
  return async () => {
    dispatch(slice.actions.startUploadingPipeline());
    try {
      const response = await axios.post("/omniml/pipelines/update", {
        PipelineName: pipelineName,
        RoleArn: roleArn,
        PipelineDefinition: pipelineDef,
      });
      // console.log(response);
      if (response.status === 200 && response.data.success) {
        dispatch(slice.actions.stopUploadingPipeline());
        callback(`Pipeline "${pipelineName}" updated`, "success");
      } else {
        dispatch(slice.actions.stopUploadingPipeline());
        if (response.data.message !== undefined) {
          callback(`${response.data.message}`, "error");
        } else {
          callback(`Failed to update pipeline "${pipelineName}"`, "error");
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deletePipeline(pipelineName: String, callback: Function) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/omniml/pipelines/${pipelineName}`);
      if (response.status === 200 && response.data.success) {
        dispatch(slice.actions.deletePipeline(pipelineName));
        callback(`Pipeline "${pipelineName}" deleted`, "success");
      } else {
        if (response.data.message !== undefined) {
          callback(`${response.data.message}`, "error");
        } else {
          callback(`Failed to delete pipeline "${pipelineName}"`, "error");
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      callback(`Failed to delete pipeline "${pipelineName}"`, "error");
    }
  };
}

// ----------------------------------------------------------------------

export function getPipelineDescription(
  pipelineName: String,
  enqueueSnackbar: Function
) {
  return async () => {
    dispatch(slice.actions.startLoadingPipeline());
    try {
      const response = await axios.get(`/omniml/pipelines/${pipelineName}`);

      if (response.status === 200 && response.data.success === undefined) {
        dispatch(slice.actions.setCurrentPipeline(response.data));
        dispatch(importFromSmPipelineDef(response.data.PipelineDefinition));
      } else {
        if (!response.data.success && response.data.message !== undefined) {
          enqueueSnackbar(`${response.data.message}`, "error");
        } else {
          enqueueSnackbar(
            `Failed to get information of ${pipelineName}`,
            "error"
          );
        }
      }
    } catch (error) {
      enqueueSnackbar(`Failed to get information of ${pipelineName}`, "error");
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function duplicatePipeline(
  pipelineName: String,
  enqueueSnackbar: Function
) {
  return async () => {
    dispatch(slice.actions.startLoadingPipeline());
    try {
      const response = await axios.get(`/omniml/pipelines/${pipelineName}`);

      if (response.status === 200 && response.data.success === undefined) {
        dispatch(
          slice.actions.setCurrentPipeline({
            ...response.data,
            PipelineName: `${pipelineName}-copy`,
          })
        );
        dispatch(slice.actions.getExecutionListSuccess([]));
        dispatch(slice.actions.getTriggerListSuccess([]));
        dispatch(importFromSmPipelineDef(response.data.PipelineDefinition));
      } else {
        if (!response.data.success && response.data.message !== undefined) {
          enqueueSnackbar(`${response.data.message}`, "error");
        } else {
          enqueueSnackbar(
            `Failed to get information of ${pipelineName}`,
            "error"
          );
        }
      }
    } catch (error) {
      enqueueSnackbar(`Failed to get information of ${pipelineName}`, "error");
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function importLocalPipelineDef(
  pipelineName: String,
  pipelineDef: string,
  callback: Function
) {
  return async () => {
    dispatch(slice.actions.startLoadingPipeline());
    try {
      // const def = JSON.parse(pipelineDef);
      // console.log(pipelineDef);
      dispatch(
        slice.actions.setCurrentPipelineFromLocal({
          PipelineName: pipelineName,
          PipelineDefinition: pipelineDef,
        })
      );
      dispatch(importFromSmPipelineDef(pipelineDef));
      callback("Pipeline imported", "success");
    } catch (error) {
      callback("Failed to import pipeline", "error");
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// export function getPipelineExecutionInfo(pipelineName: String) {
//   return async () => {
//     dispatch(
//       slice.actions.startLoadingExecutionInfo({
//         pipelineName: pipelineName,
//       })
//     );
//     try {
//       const response = await axios.get(
//         `/omniml/${pipelineName}/executions/summary`
//       );

//       if (response.status === 200) {
//         dispatch(
//           slice.actions.getPipelineExecutionInfoSuccess({
//             pipelineName: pipelineName,
//             ...response.data,
//           })
//         );
//       }
//     } catch (error) {
//       dispatch(
//         slice.actions.failLoadingExecutionInfo({
//           pipelineName: pipelineName,
//           error: error,
//         })
//       );
//     }
//   };
// }

// ----------------------------------------------------------------------

export function getPipelineInfo(pipelineName: String) {
  return async () => {
    dispatch(
      slice.actions.startLoadingPipelineInfo({
        pipelineName: pipelineName,
      })
    );
    try {
      const response = await axios.get(`/omniml/${pipelineName}/info`);

      if (response.status === 200) {
        dispatch(
          slice.actions.getPipelineInfoSuccess({
            pipelineName: pipelineName,
            ...response.data.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        slice.actions.failLoadingPipelineInfo({
          pipelineName: pipelineName,
          error: error,
        })
      );
    }
  };
}

// ----------------------------------------------------------------------

// export function getPipelineMetaData(pipelineName: String) {
//   return async () => {
//     dispatch(
//       slice.actions.startLoadingPipelineMetaData({
//         pipelineName: pipelineName,
//       })
//     );
//     try {
//       const response = await axios.get(`/omniml/${pipelineName}/meta`);
//       if (response.status === 200) {
//         dispatch(
//           slice.actions.getPipelineMetaDataSuccess({
//             pipelineName: pipelineName,
//             ...response.data.message,
//           })
//         );
//       }
//     } catch (error) {
//       dispatch(
//         slice.actions.failLoadingPipelineMetaData({
//           pipelineName: pipelineName,
//           error: error,
//         })
//       );
//     }
//   };
// }

// ----------------------------------------------------------------------

export function getPipelineExecutionList(pipelineName: String) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/omniml/${pipelineName}/executions`);
      if (response.status === 200) {
        // console.log(response.data);
        dispatch(
          slice.actions.getExecutionListSuccess(response.data.executions)
        );
        response.data.executions.forEach((execution: any) => {
          dispatch(
            getPipelineExecutionMetaData(
              pipelineName,
              execution.PipelineExecutionArn
            )
          );
        });
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPipelineExecutionMetaData(
  pipelineName: String,
  pipelineExecutionArn: String
) {
  return async () => {
    dispatch(
      slice.actions.startLoadingExecutionMetaData({
        PipelineExecutionArn: pipelineExecutionArn,
      })
    );
    try {
      const execArnItems = pipelineExecutionArn.split("/");
      // console.log(execArnItems);
      const response = await axios.get(
        `/omniml/${execArnItems[1]}/executions/${
          execArnItems[execArnItems.length - 1]
        }/meta`
      );
      // console.log(response);
      if (response.status === 200) {
        dispatch(
          slice.actions.getExecutionMetaDataSuccess({
            PipelineExecutionArn: pipelineExecutionArn,
            ...response.data,
          })
        );
      }
    } catch (error) {
      dispatch(
        slice.actions.failLoadingExecutionMetaData({
          PipelineExecutionArn: pipelineExecutionArn,
          error: error,
        })
      );
    }
  };
}

// ----------------------------------------------------------------------

// export function resetCurrentPipeline(pipelineName: String) {
//   return async () => {
//     dispatch(slice.actions.startLoadingPipeline());
//     try {
//       dispatch(slice.actions.resetCurrentPipeline(pipelineName));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

export function startPipelineExecution(
  pipelineName: String,
  pipelineExecutionParams: Object,
  enqueueSnackbar: Function
) {
  return async () => {
    try {
      const response = await axios.post("/omniml/pipelines/executions/start", {
        PipelineName: pipelineName,
        pipelineExecutionParams: pipelineExecutionParams,
      });
      if (response.status === 200 && response.data.success) {
        dispatch(getPipelineExecutionList(pipelineName));
        enqueueSnackbar("Execution started", { variant: "success" });
      } else {
        if (response.data.message !== undefined) {
          enqueueSnackbar(response.data.message, { variant: "error" });
        } else {
          enqueueSnackbar("Failed to start execution", { variant: "error" });
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar("Failed to start execution", { variant: "error" });
    }
  };
}

// ----------------------------------------------------------------------

export function stopPipelineExecution(
  pipelineName: String,
  pipelineExecutionArn: String,
  callback: Function
) {
  return async () => {
    try {
      const execArnItems = pipelineExecutionArn.split("/");
      const response = await axios.post("/omniml/pipelines/executions/stop", {
        PipelineName: execArnItems[execArnItems.length - 3],
        ExecutionName: execArnItems[execArnItems.length - 1],
      });
      if (response.status === 200) {
        if (response.data.success !== undefined) {
          if (response.data.success === true) {
            callback("Execution stopped", "success");
            dispatch(getPipelineExecutionList(pipelineName));
          } else if (response.data.success === false) {
            if (response.data.message) callback(response.data.message, "error");
            else callback("Failed to stop execution", "error");
          }
        } else {
          dispatch(getPipelineExecutionList(pipelineName));
        }
      } else {
        callback("Failed to stop execution", "error");
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      callback("Failed to stop execution", "error");
    }
  };
}

// ----------------------------------------------------------------------

export function resumePipelineExecution(
  pipelineName: String,
  pipelineExecutionArn: String,
  callback: Function
) {
  return async () => {
    try {
      const execArnItems = pipelineExecutionArn.split("/");
      const response = await axios.post("/omniml/pipelines/executions/resume", {
        PipelineName: pipelineName,
        ExecutionName: execArnItems[execArnItems.length - 1],
      });
      if (response.status === 200) {
        callback("Execution resumed", "success");
        dispatch(getPipelineExecutionList(pipelineName));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPipelineExecutionParameters(pipelineExecutionArn: String) {
  return async () => {
    dispatch(slice.actions.startLoadingExecutionParams());
    try {
      const execArnItems = pipelineExecutionArn.split("/");
      const response = await axios.get(
        `/omniml/${execArnItems[execArnItems.length - 3]}/executions/${
          execArnItems[execArnItems.length - 1]
        }/parameters`
      );
      if (response.status === 200) {
        dispatch(
          slice.actions.getExecutionParametersSuccess({
            PipelineExecutionArn: pipelineExecutionArn,
            PipelineExecutionParameters: response.data.parameters,
          })
        );
      } else {
        dispatch(slice.actions.stopLoadingExecutionParams());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPipelineExecutionSteps(pipelineExecutionArn: String) {
  return async () => {
    dispatch(slice.actions.startLoadingExecutionSteps());
    try {
      const execArnItems = pipelineExecutionArn.split("/");
      const response = await axios.get(
        `/omniml/${execArnItems[execArnItems.length - 3]}/executions/${
          execArnItems[execArnItems.length - 1]
        }/steps`
      );
      // console.log(response);
      if (response.status === 200) {
        dispatch(
          slice.actions.getExecutionStepsSuccess({
            PipelineExecutionArn: pipelineExecutionArn,
            PipelineExecutionSteps: response.data.steps,
          })
        );
      } else {
        // dispatch(slice.actions.stopLoadingExecutionParams());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPipelineExecutionStatus(pipelineExecutionArn: String) {
  return async () => {
    dispatch(slice.actions.startLoadingExecutionStatus());
    try {
      const execArnItems = pipelineExecutionArn.split("/");
      const response = await axios.get(
        `/omniml/${execArnItems[execArnItems.length - 3]}/executions/${
          execArnItems[execArnItems.length - 1]
        }/status`
      );
      if (response.status === 200) {
        dispatch(
          slice.actions.getExecutionStatusSuccess({
            PipelineExecutionArn: pipelineExecutionArn,
            PipelineExecutionInfo: response.data,
          })
        );
      } else {
        // dispatch(slice.actions.stopLoadingExecutionParams());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPipelineExecutionProcessingJobLog(
  stepName: String,
  pipelineExecutionArn: String,
  processingJobArn: String
) {
  return async () => {
    dispatch(slice.actions.startLoadingExecutionStepLog());
    try {
      const items = processingJobArn.split("/");
      // console.log(`Items are ${items[items.length - 1]}`);
      const response = await axios.get(
        `/omniml/pipelines/executions/processing/${items[items.length - 1]}`
      );
      // console.log(response);
      if (response.status === 200) {
        dispatch(
          slice.actions.getExecutionStepLogSuccess({
            StepName: stepName,
            ExecutionInfo: response.data.job_info,
            ExecutionInfoInput: response.data.inputList,
            ExecutionInfoOutput: response.data.outputList,
            ExecutionInfoOther: response.data.otherList,
            ExecutionLog: response.data.job_logs.events,
            ExecutionOutputFiles: response.data.outputFileList,
            ExecutionInfoMetrics: response.data.outputMetricsList,
            PipelineExecutionArn: pipelineExecutionArn,
          })
        );
      } else {
        // dispatch(slice.actions.stopLoadingExecutionParams());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPipelineExecutionTrainingJobLog(
  stepName: String,
  pipelineExecutionArn: String,
  trainingJobArn: String
) {
  return async () => {
    dispatch(slice.actions.startLoadingExecutionStepLog());
    try {
      const items = trainingJobArn.split("/");
      const response = await axios.get(
        `/omniml/pipelines/executions/training/${items[items.length - 1]}`
      );
      if (response.status === 200) {
        dispatch(
          slice.actions.getExecutionStepLogSuccess({
            StepName: stepName,
            ExecutionInfo: response.data.job_info,
            ExecutionInfoInput: response.data.inputList,
            ExecutionInfoinputFile: response.data.inputFileList,
            ExecutionInfoOutput: response.data.outputList,
            ExecutionInfooutputFile: response.data.outputFileList,
            ExecutionLog: response.data.job_logs.events,
            PipelineExecutionArn: pipelineExecutionArn,
          })
        );
      } else {
        // dispatch(slice.actions.stopLoadingExecutionParams());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPipelineHyperParameterTuningJobLog(
  stepName: String,
  pipelineExecutionArn: String,
  hyperparameterTuningJobArn: String
) {
  return async () => {
    dispatch(slice.actions.startLoadingExecutionStepLog());
    try {
      const items = hyperparameterTuningJobArn.split("/");
      const response = await axios.get(
        `/omniml/pipelines/executions/hyperparametertuning/${
          items[items.length - 1]
        }`
      );
      if (response.status === 200) {
        // console.log(response);
        dispatch(
          slice.actions.getExecutionStepLogSuccess({
            StepName: stepName,
            ExecutionInfo: response.data.job_info,
            // TuningJobObjectiveList, TuningJobConfigurationList, WarmStartConfigList, ParameterRangeList,StaticHPList
            // bestTrainingJobList, TunedHPList, TrainingJobStatusList
            TuningJobObjectiveList: response.data.TuningJobObjectiveList,
            TuningJobConfigurationList:
              response.data.TuningJobConfigurationList,
            WarmStartConfigList: response.data.WarmStartConfigList,
            ParameterRangeList: response.data.ParameterRangeList,
            StaticHPList: response.data.StaticHPList,
            bestTrainingJobList: response.data.bestTrainingJobList,
            TunedHPList: response.data.TunedHPList,
            TrainingJobStatusList: response.data.TrainingJobStatusList,
            ExecutionLog: response.data.job_logs.events,
            PipelineExecutionArn: pipelineExecutionArn,
          })
        );
      } else {
        // dispatch(slice.actions.stopLoadingExecutionParams());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPipelineLambdaStepLog(
  stepName: String,
  pipelineExecutionArn: String,
  lambdaArn: String,
  startTime: String,
  endTime: String
) {
  return async () => {
    dispatch(slice.actions.startLoadingExecutionStepLog());
    try {
      const items = lambdaArn.split(":");
      const response = await axios.get(
        `/omniml/pipelines/executions/lambda/${
          items[items.length - 1]
        }/${startTime}/${endTime}`
      );
      // lambda/sagemaker-lambda-step-endpoint-deploy-02-23-07-59-28/Thu, 23 Feb 2023 08:12:54 GMT
      if (response.status === 200) {
        // console.log("response:....")
        // console.log(response);
        dispatch(
          slice.actions.getExecutionStepLogSuccess({
            StepName: stepName,
            ExecutionInfo: response.data.job_info,
            // ExecutionInfoInput:response.data.inputList,
            // ExecutionInfoinputFile:response.data.inputFileList,
            // ExecutionInfoOutput:response.data.outputList,
            // ExecutionInfooutputFile:response.data.outputFileList,
            ExecutionLog: response.data.job_logs.events,
            PipelineExecutionArn: pipelineExecutionArn,
          })
        );
      } else {
        // dispatch(slice.actions.stopLoadingExecutionParams());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPipelineTransformJobLog(
  stepName: String,
  pipelineExecutionArn: String,
  transformJobArn: String
) {
  return async () => {
    dispatch(slice.actions.startLoadingExecutionStepLog());
    try {
      const items = transformJobArn.split("/");
      const response = await axios.get(
        `/omniml/pipelines/executions/transform/${items[items.length - 1]}`
      );
      // lambda/sagemaker-lambda-step-endpoint-deploy-02-23-07-59-28/Thu, 23 Feb 2023 08:12:54 GMT
      if (response.status === 200) {
        dispatch(
          slice.actions.getExecutionStepLogSuccess({
            StepName: stepName,
            ExecutionInfo: response.data.job_info,
            ExecutionInfoInput: response.data.inputList,
            ExecutionInfoinputFile: response.data.inputFileList,
            ExecutionInfoOutput: response.data.outputList,
            ExecutionInfooutputFile: response.data.outputFileList,
            ExecutionLog: response.data.job_logs.events,
            PipelineExecutionArn: pipelineExecutionArn,
          })
        );
      } else {
        // dispatch(slice.actions.stopLoadingExecutionParams());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getPipelineModelStepLog(
  stepName: String,
  pipelineExecutionArn: String,
  modelStepArn: String
) {
  return async () => {
    dispatch(slice.actions.startLoadingExecutionStepLog());
    try {
      const items = modelStepArn.split("/");
      const response = await axios.get(
        `/omniml/pipelines/executions/model/${items[items.length - 1]}`
      );
      if (response.status === 200) {
        dispatch(
          slice.actions.getExecutionStepLogSuccess({
            StepName: stepName,
            ExecutionInfo: response.data.job_info,
            ExecutionInfoInput: response.data.inputList,
            ExecutionInfoinputFile: response.data.inputFileList,
            ExecutionInfoOutput: response.data.outputList,
            ExecutionInfooutputFile: response.data.outputFileList,
            ExecutionLog: response.data.job_logs.events,
            PipelineExecutionArn: pipelineExecutionArn,
          })
        );
      } else {
        // dispatch(slice.actions.stopLoadingExecutionParams());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getPipelineModelRegisterLog(
  stepName: String,
  pipelineExecutionArn: String,
  modelRegisterArn: String
) {
  return async () => {
    dispatch(slice.actions.startLoadingExecutionStepLog());
    try {
      const items = modelRegisterArn.split("/");
      const region = items[0].split(":")[3];
      const account = items[0].split(":")[4];
      const packageName = items[1];
      const id = items[2];
      const response = await axios.get(
        `/omniml/pipelines/executions/modelregister/${region}/${account}/${packageName}/${id}`
      );
      // lambda/sagemaker-lambda-step-endpoint-deploy-02-23-07-59-28/Thu, 23 Feb 2023 08:12:54 GMT
      if (response.status === 200) {
        dispatch(
          slice.actions.getExecutionStepLogSuccess({
            StepName: stepName,
            ExecutionInfo: response.data.job_info,
            ExecutionInfoInput: response.data.inputList,
            ExecutionInfoinputFile: response.data.inputFileList,
            ExecutionInfoOutput: response.data.outputList,
            ExecutionInfooutputFile: response.data.outputFileList,
            ExecutionLog: response.data.job_logs.events,
            PipelineExecutionArn: pipelineExecutionArn,
          })
        );
      } else {
        // dispatch(slice.actions.stopLoadingExecutionParams());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createPipelineScheduledTrigger(
  triggerName: String,
  scheduleExpression: String,
  enableTrigger: boolean,
  pipelineName: String,
  pipelineArn: String,
  roleArn: String,
  description: String,
  callback: Function,
  isEdit: boolean
) {
  return async () => {
    try {
      const triggerState = enableTrigger ? "ENABLED" : "DISABLED";
      const response = await axios.post(
        "/omniml/pipelines/triggers/scheduled/create",
        {
          triggerName: triggerName,
          triggerState: triggerState,
          pipelineName: pipelineName,
          pipelineArn: pipelineArn,
          roleArn: roleArn,
          scheduleExpression: scheduleExpression,
          description: description,
        }
      );
      if (response.status === 200) {
        callback(
          `Trigger "${triggerName}" ${isEdit ? "updated" : "created"}`,
          "success"
        );
        const items = pipelineArn.split("/");
        dispatch(getPipelineTriggers(items[items.length - 1]));
      } else {
        callback(`Failed to create pipeline trigger "${triggerName}"`, "error");
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createPipelineS3Trigger(
  triggerName: String,
  bucketName: String,
  objectKey: String,
  enableTrigger: boolean,
  pipelineName: String,
  pipelineArn: String,
  roleArn: String,
  description: String,
  callback: Function,
  isEdit: boolean
) {
  return async () => {
    try {
      const triggerState = enableTrigger ? "ENABLED" : "DISABLED";
      const response = await axios.post(
        "/omniml/pipelines/triggers/s3/create",
        {
          triggerName: triggerName,
          triggerState: triggerState,
          pipelineName: pipelineName,
          pipelineArn: pipelineArn,
          roleArn: roleArn,
          bucketName: bucketName,
          objectKey: objectKey,
          description: description,
        }
      );
      if (response.status === 200) {
        callback(
          `Trigger "${triggerName}" ${isEdit ? "updated" : "created"}`,
          "success"
        );
        const items = pipelineArn.split("/");
        dispatch(getPipelineTriggers(items[items.length - 1]));
      } else {
        callback(
          `Failed to ${
            isEdit ? "update" : "create"
          } pipeline trigger "${triggerName}"`,
          "error"
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPipelineTriggers(pipelineName: String) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/omniml/${pipelineName}/triggers`);
      // console.log(response);
      if (response.status === 200) {
        dispatch(
          slice.actions.getTriggerListSuccess(response.data.message.Rules)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deletePipelineTrigger(
  triggerName: String,
  pipelineArn: String,
  callback: Function
) {
  return async () => {
    try {
      const response = await axios.delete(`/omniml/triggers/${triggerName}`);
      // console.log(response);
      if (response.status === 200) {
        callback(`Trigger "${triggerName} deleted`, "success");

        const items = pipelineArn.split("/");
        dispatch(getPipelineTriggers(items[items.length - 1]));
      } else {
        callback(`Failed to delete pipeline trigger "${triggerName}"`, "error");
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

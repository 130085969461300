function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const prefix = "ui";

const ROOTS_DASHBOARD = `/${prefix}`;

export const PATH_DASHBOARD = {
  experiment: {
    notebook: path(ROOTS_DASHBOARD, "/experiment/notebook"),
    notebookVolume: path(ROOTS_DASHBOARD, "/experiment/notebookVolume"),
    hpTuner: path(ROOTS_DASHBOARD, "/experiment/hpTuner"),
  },
  automation: {
    mlPipeline: path(ROOTS_DASHBOARD, "/automation/mlPipeline"),
    pipelineVolume: path(ROOTS_DASHBOARD, "/automation/pipelineVolume"),
  },
  dataAsset: {
    modelRegistry: path(ROOTS_DASHBOARD, "/dataAsset/modelRegistry"),
    featureStore: path(ROOTS_DASHBOARD, "/dataAsset/featureStore"),
  },
  mlService: {
    mlRuntime: path(ROOTS_DASHBOARD, "/mlService/mlRuntime"),
    appGallery: path(ROOTS_DASHBOARD, "/mlService/appGallery"),
  },
  projectGovernance: {
    manage: path(ROOTS_DASHBOARD, "/projectGovernance/manage"),
    monitor: path(ROOTS_DASHBOARD, "/projectGovernance/monitor"),
  },
  administration: {
    organization: path(ROOTS_DASHBOARD, "/administration/organization"),
    project: path(ROOTS_DASHBOARD, "/administration/project"),
  },
  template: {
    projectRole: path(ROOTS_DASHBOARD, "/template/projectRole"),
    notebookTemplate: path(ROOTS_DASHBOARD, "/template/notebookTemplate"),
    pipelineTemplate: path(ROOTS_DASHBOARD, "/template/pipelineTemplate"),
  },
  connection: {
    connection: path(ROOTS_DASHBOARD, "/connection/connection"),
    credential: path(ROOTS_DASHBOARD, "/connection/credential"),
  },
  observability: {
    projectMonitor: path(ROOTS_DASHBOARD, "/observability/projectMonitor"),
    grafana: path(ROOTS_DASHBOARD, "/observability/grafana"),
    kibana: path(ROOTS_DASHBOARD, "/observability/kibana"),
  },
};

import * as Yup from "yup";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Dialog,
  Stack,
  Divider,
  Button,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import { LoadingButton } from "@mui/lab";
// redux
import { useDispatch } from "@/redux/store";
// @types
import { CardOption } from "@/@types/products";
import {
  CodeSourceOption,
  StepCodeStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline";
//
import LambdaCodeSelector from "./LambdaCodeSelector";
import Scrollbar from "@/components/project/Scrollbar";
import { updateLambdaFunction } from "../../../graph/slices/slice";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 1999,
  minHeight: 440,
  outline: "none",
  display: "flex",
  position: "fixed",
  overflow: "hidden",
  flexDirection: "row",
  margin: theme.spacing(3),
  boxShadow: theme.customShadows.z20,
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.background.paper,
}));

const prefix = process.env.NODE_ENV === "development" ? "" : "/ui";

const PAYMENT_OPTIONS: CodeSourceOption[] = [
  {
    value: "amazon_s3",
    title: "Amazon S3",
    description:
      "Input the S3 prefix of your zipped code package in the textfield.",
    icons: [`${prefix}/static/icons/ic_s3.svg`],
  },
  {
    value: "amazon_codecommit",
    title: "Amazon CodeCommit",
    description:
      "Select the CodeCommit repo where your processing code is located.",
    icons: [`${prefix}/static/icons/ic_codecommit.svg`],
  },
  {
    value: "local",
    title: "Local",
    description: "Select a zipped code package on your local machine.",
    icons: [`${prefix}/static/icons/ic_local.svg`],
  },
];

const CARDS_OPTIONS: CardOption[] = [
  { value: "InternWork_2021_YC_DD", label: "InternWork_2021_YC_DD" },
  { value: "KGQA-NLP-Neptune", label: "KGQA-NLP-Neptune" },
  { value: "AWS_ML_Lab_Workshop", label: "AWS_ML_Lab_Workshop" },
];

// ----------------------------------------------------------------------

type ExecutionParameterPanelProps = {
  isOpenCompose: boolean;
  onCloseCompose: VoidFunction;
  codeProps: StepCodeStruct;
};

export default function LambdaCodePopover({
  isOpenCompose,
  onCloseCompose,
  codeProps,
}: ExecutionParameterPanelProps) {
  const dispatch = useDispatch();

  const PaymentSchema = Yup.object().shape({
    path: Yup.mixed().required("Cold file path is required"),
  });

  const formik = useFormik({
    initialValues: codeProps,
    validationSchema: PaymentSchema,
    onSubmit: async (values) => {
      try {
        dispatch(updateLambdaFunction({ type: "code", content: values.path }));
        onCloseCompose();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { handleSubmit } = formik;

  if (!isOpenCompose) {
    return null;
  }

  return (
    // <Portal>
    <Dialog open={isOpenCompose} onClose={onCloseCompose}>
      {/* <Backdrop open={fullScreen || isMobile} sx={{ zIndex: 1998 }} /> */}

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <RootStyle
            sx={{
              ...{
                top: 0,
                left: 0,
                display: "flex",
                margin: "auto",
                width: {
                  xs: "90%",
                  sm: "90%",
                  md: "70%",
                  lg: "50%",
                },
                height: {
                  xs: "85vh",
                  md: "80vh",
                },
              },
            }}
          >
            <Stack sx={{ flexGrow: 1 }}>
              <DialogTitle>{"Select lambda function code"}</DialogTitle>

              <Scrollbar sx={{ flexDirection: "row" }}>
                <Box sx={{ p: 0, height: "100%" }}>
                  <LambdaCodeSelector
                    formik={formik}
                    cardOptions={CARDS_OPTIONS}
                    paymentOptions={PAYMENT_OPTIONS}
                  />
                </Box>
              </Scrollbar>

              <Divider />

              <DialogActions>
                <LoadingButton type="submit" variant="contained">
                  Confirm
                </LoadingButton>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={onCloseCompose}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Stack>
          </RootStyle>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}

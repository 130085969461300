/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik, FormikProvider, Form, Field } from "formik";
import {
  Button,
  TextField,
  Dialog,
  Stack,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import * as yup from "yup";
import { isEmpty } from "lodash";
import { useEffect, useRef, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import {
  addNotebookTemplate,
  addNotebookTemplateVersion,
  editNotebookTemplate,
  getNotebookTemplateDetail,
  getNotebookTemplateVersionData,
  getNotebookTemplateVersionDetail,
  uploadNotebookImage,
  getNotebookImage,
  delNotebookImage,
} from "@/redux/project/template/thunks";
import { RootState, dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { resetTemplateData } from "@/redux/project/template/slice";
import { LoadingButton } from "@mui/lab";

const DropZoneStyle = styled("div")(({ theme }) => ({
  position: "absolute",
  width: 100,
  height: 100,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
  // border: `1px dashed ${theme.palette.grey[500_32]}`,
  "&:hover": { opacity: 0.72, cursor: "pointer" },
}));
interface INotebookTemplateCreateOrEdit {
  template: Record<string, any>;
  mode: "create" | "edit";
  isOpen: boolean;
  onClose: VoidFunction;
  handleRefresh: (a?: boolean) => void;
}

interface FormValues {
  name: string;
  gitRepoUrl: string;
  icon_url?: string;
  git_commit_id: string;
  description?: string;
  version_description?: string;
}

const initialValues: FormValues = {
  name: "",
  gitRepoUrl: "",
  icon_url: "",
  git_commit_id: "",
  description: "",
  version_description: "",
};

const validationSchema = yup.object({
  name: yup
    .string()
    .required("Name is required")
    .max(64, "Name cannot be longer than 64 characters"),
  gitRepoUrl: yup
    .string()
    .required("gitRepoUrl is required")
    .max(128, "Git Repo Url cannot be longer than 128 characters"),
  git_commit_id: yup.string().required("Git Commit Id is required"),
  description: yup
    .string()
    .max(400, "Description cannot be longer than 400 characters"),
  version_description: yup
    .string()
    .max(400, "Description cannot be longer than 400 characters"),
});

const NotebookTemplateCreateOrEditDialog = ({
  template,
  mode,
  isOpen,
  onClose,
  handleRefresh,
}: INotebookTemplateCreateOrEdit) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const [files, setFiles] = useState<File[]>([]);
  const [imagesOnHold, setImagesOnHold] = useState<string[]>([]);
  const [imageSrc, setImageSrc] = useState<string>("");
  const initialCommitId = useRef<string>();

  const {
    notebookTemplateDetail: { data },
  } = useSelector((state: RootState) => state.template);

  const handleDropMultiFile = useCallback(
    (acceptedFiles) => {
      const objUrls = acceptedFiles.map((file: File) =>
        URL.createObjectURL(file)
      );
      setImagesOnHold(objUrls);
      setFiles(
        acceptedFiles.map((file: File, id: number) =>
          Object.assign(file, {
            preview: objUrls[id],
          })
        )
      );
    },
    [setImagesOnHold]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      maxFiles: 1,
      accept: ["image/jpeg", "image/png", "image/jpg"],
      onDrop: handleDropMultiFile,
    });

  const handleCreateSubmit = async (values) => {
    // upload image
    let icon_url = "";
    if (files.length > 0) {
      const formData = new FormData();
      formData.append("filename", files[0].name);
      formData.append("file", files[0]);
      const { s3_key } = await dispatch(
        uploadNotebookImage({
          formData,
          pathname,
          alertCallback: enqueueSnackbar,
        })
      ).unwrap();
      icon_url = s3_key;
    }

    const { id: notebook_template_id } = await dispatch(
      addNotebookTemplate({
        params: {
          name: values.name,
          icon_url: icon_url || "default",
          git_repo_url: values.gitRepoUrl || "",
          description: values?.description || "",
          is_active: 1,
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();

    // add template_version with git_commit_id
    await dispatch(
      addNotebookTemplateVersion({
        params: {
          name: String(new Date().getTime()),
          git_commit_id: values.git_commit_id,
          notebook_template_id,
          description: values?.version_description || "",
          is_active: 1,
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();
  };

  const handleUpdateSubmit = async (values) => {
    // imagesOnHold exist upload image and delete pre image
    let icon_url = "";
    if (imagesOnHold.length > 0) {
      // upload image
      const formData = new FormData();
      formData.append("filename", files[0].name);
      formData.append("file", files[0]);
      const { s3_key } = await dispatch(
        uploadNotebookImage({
          formData,
          pathname,
          alertCallback: enqueueSnackbar,
        })
      ).unwrap();
      icon_url = s3_key;

      // delete image
      if (template.icon_url !== "default") {
        const iconUrlTobase64 = encodeURIComponent(template.icon_url);
        await dispatch(
          delNotebookImage({
            s3_key: btoa(iconUrlTobase64),
            alertCallback: enqueueSnackbar,
            pathname,
          })
        );
      }
    }

    await dispatch(
      editNotebookTemplate({
        id: template.id,
        params: {
          description: values?.description || "",
          icon_url:
            imagesOnHold.length > 0 ? icon_url || "default" : template.icon_url,
          is_active: 1,
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();

    // when editing, you must add a new template_version with git_commit_id, then when you create notebook ,you must sort by updated_at to get the latest version
    // if git_commit_id isn't changed, needn't add a new version
    if (initialCommitId.current !== values.git_commit_id) {
      await dispatch(
        addNotebookTemplateVersion({
          params: {
            name: String(new Date().getTime()),
            git_commit_id: values.git_commit_id,
            notebook_template_id: template.id,
            is_active: 1,
            description: values.version_description,
          },
          pathname,
          alertCallback: enqueueSnackbar,
        })
      ).unwrap();
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      try {
        if (mode === "edit") {
          await handleUpdateSubmit(values);
          enqueueSnackbar("Edit Success", { variant: "success" });
        } else {
          await handleCreateSubmit(values);
          enqueueSnackbar("Create Success", { variant: "success" });
        }
        actions.setSubmitting(false);

        handleRefresh(true);
        handleClose();
      } catch (e) {
        console.log("error-in-submit", e);
        actions.setSubmitting(false);
      }
    },
  });

  const { values, errors, touched, setFieldValue, resetForm, isSubmitting } =
    formik;

  const handleClose = () => {
    onClose();
    resetForm();
    dispatch(resetTemplateData("notebookTemplateDetail"));
  };

  const handleInit = async () => {
    try {
      await dispatch(
        getNotebookTemplateDetail({
          id: template.id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
    } catch (e) {}
  };

  useEffect(() => {
    if (isOpen && mode === "edit") {
      handleInit();
    }
    return () => {
      setImageSrc("");
      setImagesOnHold([]);
      setFiles([]);
    };
  }, [isOpen]);

  useEffect(() => {
    if (!isEmpty(data) && mode === "edit") {
      // getImage
      if (data.icon_url && data.icon_url !== "default") {
        dispatch(
          getNotebookImage({
            params: { s3_key: data.icon_url },
            pathname,
            alertCallback: enqueueSnackbar,
          })
        )
          .unwrap()
          .then((resp) => {
            setImageSrc(resp.url);
          });
      }

      setFieldValue("name", data?.name || "");
      setFieldValue("gitRepoUrl", data?.git_repo_url || "");
      setFieldValue("icon_url", data?.icon_url || "");
      setFieldValue("description", data?.description || "");

      // get the commit_id
      // you must sort by updated_at to get the latest version, then get the git_commit_id
      dispatch(
        getNotebookTemplateVersionData({
          params: {
            notebook_template_id: template.id,
            page_num: 1,
            page_size: 9999,
          },
          pathname,
          alertCallback: enqueueSnackbar,
        })
      )
        .unwrap()
        .then((res) => {
          const mostRecent = res.items.reduce((prev, cur) => {
            return new Date(cur.updated_at) > new Date(prev.updated_at)
              ? cur
              : prev;
          });

          setFieldValue("git_commit_id", mostRecent?.git_commit_id || "");
          initialCommitId.current = mostRecent?.git_commit_id;

          // call detail api get version_description
          dispatch(
            getNotebookTemplateVersionDetail({
              id: mostRecent.id,
              pathname,
              alertCallback: enqueueSnackbar,
            })
          )
            .unwrap()
            .then((r) => {
              setFieldValue("version_description", r?.description || "");
            });
        });
    }
  }, [data]);

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown
      onClose={() => {
        handleClose();
      }}
      PaperProps={{
        style: { width: "960px", maxWidth: "none", padding: "20px 40px" },
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        {mode === "create" ? "New" : "Edit"} Template
      </Typography>
      <FormikProvider value={formik}>
        <Form>
          <Box sx={{ px: 2.5 }}>
            <Field
              as={TextField}
              name="name"
              disabled={mode === "edit"}
              size="small"
              label="Name"
              fullWidth
              margin="dense"
              helperText={touched.name && errors.name ? errors.name : " "}
              error={touched.name && Boolean(errors.name)}
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
            <Field
              as={TextField}
              name="gitRepoUrl"
              disabled={mode === "edit"}
              size="small"
              label="Git Repo Url"
              fullWidth
              margin="dense"
              helperText={
                touched.gitRepoUrl && errors.gitRepoUrl
                  ? errors.gitRepoUrl
                  : "format: xxx.git"
              }
              error={touched.gitRepoUrl && Boolean(errors.gitRepoUrl)}
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
            <Field
              as={TextField}
              name="git_commit_id"
              size="small"
              label="Git Commit Id"
              fullWidth
              margin="dense"
              helperText={
                touched.git_commit_id && errors.git_commit_id
                  ? errors.git_commit_id
                  : " "
              }
              error={touched.git_commit_id && Boolean(errors.git_commit_id)}
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
            <Typography variant="subtitle1" color="text.secondary">
              Cover Image
            </Typography>
            <Box
              sx={{
                position: "relative",
                minWidth: 100,
                height: 100,
                mb: 1.5,
                ml: 1.5,
                mt: 1.5,
              }}
            >
              {(imagesOnHold.length > 0 || imageSrc) && (
                <IconButton
                  onClick={() => setImagesOnHold([])}
                  edge="end"
                  sx={{
                    position: "absolute",
                    top: -19,
                    left: 78,
                    zIndex: 9999,
                  }}
                >
                  <HighlightOffIcon />
                </IconButton>
              )}

              <DropZoneStyle
                {...getRootProps()}
                sx={{
                  ...(isDragActive && { opacity: 0.72 }),
                  ...(isDragReject && {
                    color: "error.main",
                    borderColor: "error.light",
                    bgcolor: "error.lighter",
                  }),
                }}
              >
                <input {...getInputProps()} />
                {((mode === "edit" &&
                  imageSrc &&
                  values.icon_url !== "default") ||
                  (mode === "edit" && imagesOnHold[0])) && (
                  <ImageSrc
                    src={imagesOnHold[0] ? imagesOnHold[0] : imageSrc}
                  />
                )}

                {mode !== "edit" && imagesOnHold.length > 0 && (
                  <ImageSrc src={imagesOnHold[0]} />
                )}
                <Image>
                  {/* <VisuallyHiddenInput type="file" /> */}
                  <Stack alignItems="center" justifyContent="center">
                    <AddAPhotoIcon sx={{ width: 20, height: 20, mb: 0.5 }} />
                    <Typography variant="caption">Update photo</Typography>
                  </Stack>
                </Image>
              </DropZoneStyle>
            </Box>
            <Field
              as={TextField}
              name="description"
              size="small"
              multiline
              rows={4}
              label="Description (Optional)"
              fullWidth
              margin="dense"
              helperText=" "
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
            <Field
              as={TextField}
              name="version_description"
              size="small"
              multiline
              rows={4}
              label="Template Version Description (Optional)"
              fullWidth
              margin="dense"
              helperText=" "
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
          </Box>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ my: 4 }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              color="primary"
              sx={{ width: "200px", color: "background.paper" }}
            >
              Save
            </LoadingButton>
            <Button
              type="button"
              color="inherit"
              variant="outlined"
              onClick={() => {
                resetForm();
                onClose();
              }}
              sx={{ width: "200px", color: "text.secondary" }}
            >
              Cancel
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

const ImageSrc = styled("img")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

export default NotebookTemplateCreateOrEditDialog;

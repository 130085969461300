import { IUserDetail } from "@/@types/project/model/common";
import { format } from "date-fns";
import { isEmpty } from "lodash";

type InputValue = Date | string | number | null;

export const mlSleep = (time: number, data?: any) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data || "");
    }, time);
  });
};

export const capitalizeFirstLetter = (string: string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const decodeJWT = (token: string): IUserDetail => {
  const parts = token.split(".");
  const payload = JSON.parse(
    window.atob(parts[1].replace(/-/g, "+").replace(/_/g, "/"))
  );
  return payload;
};

export const customMatchPath = (baseRoute, path) => {
  const escapedBaseRoute = baseRoute.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
  const regex = new RegExp(`^${escapedBaseRoute}(/|$)([^/]*)?(/[^/]*)*$`);
  return regex.test(path);
};

export const extractKeys = (obj, ...keys) => {
  const result = {};
  keys.forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      result[key] = obj[key];
    }
  });
  return result;
};

export const fDate = (date: InputValue, newFormat?: string) => {
  const fm = newFormat || "dd MMM yyyy";

  return date ? format(new Date(date), fm) : "";
};

export const obj2Arr = (
  obj,
  keyStr: string = "key",
  valueStr: string = "value"
) => {
  if (isEmpty(obj)) {
    return [];
  }

  const arr: Array<Record<string, any>> = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      arr.push({
        [keyStr]: key,
        [valueStr]: obj[key],
      });
    }
  }
};

export const getNumberStep = (value) => {
  if (value) {
    const valueStr = value.toString();

    const decimalIndex = valueStr.indexOf(".");

    if (decimalIndex === -1) {
      return 1;
    } else {
      const decimalPartLength = valueStr.length - decimalIndex - 1;

      return parseFloat("0." + "1".padStart(decimalPartLength, "0"));
    }
  }
  return 1;
};

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useEffect, useState } from "react";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import { DeleteIcon, EditIcon, StopIcon } from "@/assets/project/icons";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import { dispatch } from "@/redux/store";
import { LoadingButton } from "@mui/lab";
import {
  delNotebookVolume,
  editNotebookVolume,
  getRelNotebookVolumeData,
} from "@/redux/project/experiment/thunks";
import { capitalCase } from "change-case";

interface IProps {
  row: {
    [key: string]: any;
  };
  setDialogMode: VoidFunction;
  handleRefresh: (a?: boolean) => void;
}

const CredentialTableActionCol = ({
  row,
  setDialogMode,
  handleRefresh,
}: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const [isUsedByNb, setIsUsedByNb] = useState<boolean>(false);

  // delete confirm
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);

  // dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeStatus = async (statusFlag) => {
    // status is started or failed or not used by notebook
    const status = statusFlag === "stop" ? "wait_close" : "";
    await dispatch(
      editNotebookVolume({
        id: row.id,
        params: {
          status,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    enqueueSnackbar(`${capitalCase(statusFlag)} Success`, {
      variant: "success",
    });

    handleRefresh(true);
  };

  const handleDelNotebookVolume = async () => {
    setDelLoading(true);
    try {
      await dispatch(
        delNotebookVolume({
          id: row.id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      setDelLoading(false);
      setOpenConfirm(false);
      enqueueSnackbar("Delete Success", { variant: "success" });

      handleRefresh(true);
    } catch (e) {
      setDelLoading(false);
    }
  };

  // judge the volume is used by notebook or not
  useEffect(() => {
    if (open) {
      dispatch(
        getRelNotebookVolumeData({
          params: {
            notebook_volume_id: row.id,
            page_num: 1,
            page_size: 9999,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((res) => {
          setIsUsedByNb(res.items.length > 0);
        });
    }
  }, [open]);

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertOutlinedIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleChangeStatus("stop");
            handleClose();
          }}
          // valid: started、failed
          // invalid：is used by notebook
          disabled={
            !(row.status === "started" || row.status === "failed") || isUsedByNb
          }
        >
          <ListItemIcon sx={{ mr: 1 }}>
            <SvgIconStyle
              src={StopIcon}
              sx={{
                width: 22,
                height: 22,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Stop"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        <Divider />
        <MenuItem
          key="edit"
          disabled={
            !(
              row.status === "started" ||
              row.status === "failed" ||
              row.status === "closed"
            )
          }
          onClick={() => {
            setDialogMode();
            handleClose();
          }}
        >
          <ListItemIcon sx={{ mr: 1 }}>
            <SvgIconStyle
              src={EditIcon}
              sx={{
                width: 22,
                height: 22,
              }}
            />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: "body2" }}>
            Edit
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={!(row.status === "closed")}
          onClick={() => {
            setOpenConfirm(true);
            handleClose();
          }}
          key="delete"
          sx={{ color: "error.main" }}
        >
          <ListItemIcon sx={{ mr: 1 }}>
            <SvgIconStyle
              src={DeleteIcon}
              sx={{
                width: 22,
                height: 22,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete notebook volume <strong> {row.name} </strong>{" "}
            item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelNotebookVolume();
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
};

export default CredentialTableActionCol;

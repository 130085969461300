/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Stack,
  IconButton,
  Divider,
} from "@mui/material";
import MlCard from "@/components/project/mlComponents/MlCard";
import Scrollbar from "@/components/project/Scrollbar";
import { extractKeys } from "@/utils/project/utils";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import MlTableBodyContainer from "@/components/project/mlComponents/MlTableBodyContainer";
import { RootState, dispatch } from "@/redux/store";
import { getProjectRolesTableDataInProject } from "@/redux/project/administration/thunks";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import { EditIcon } from "@/assets/project/icons";
import ProjectRolesTableRow from "./ProjectRolesTableRow";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ProjectRoleAddDialog from "./ProjectRoleAddDialog";
import ProjectRolesTableActionCol from "./ProjectRolesTableActionCol";

interface Column {
  id: string;
  label?: string;
  minWidth?: number;
  width?: number;
  maxWidth?: number;
}

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 150 },
  { id: "ad_group", label: "Security Group", minWidth: 300 },
  { id: "description", label: "Description", minWidth: 300 },
  { id: "", width: 150 },
];

const ProjectRolesTable = ({ curProjectId }: { curProjectId: string }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  // const isFromPgManageSiderbar = pathname.includes('/projectGovernance/manage');

  // dialog
  const [roleAddOrEditDialogOpen, setRoleAddOrEditDialogOpen] =
    useState<boolean>(false);

  // const [openAddUserDialog, setOpenAddUserDialog] = useState<boolean>(false);

  const [roleMode, setRoleMode] = useState<"add" | "edit">("add");

  const [roleDetailData, setRoleDetailData] = useState({});

  const {
    projectDetailRolesTableData: { data, loading },
    projectDetailData: { data: prjDetail },
  } = useSelector((state: RootState) => state.administration);

  const isSandbox = prjDetail?.project_type === "0";

  const fetchData = () => {
    return dispatch(
      getProjectRolesTableDataInProject({
        params: {
          project_id: curProjectId!,
          page_num: 1,
          page_size: 10,
        },
        hasDescription: true,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  };

  const handleRefresh = (isUpdate?: boolean) => {
    fetchData().then(() => {
      if (!isUpdate) {
        enqueueSnackbar("Refresh Success", { variant: "success" });
      }
    });
  };

  const renderCell = (row, column) => {
    switch (column.id) {
      case "name":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="subtitle2">{row[column.id]}</Typography>
          </TableCell>
        );
      case "ad_group":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            {isSandbox ? (
              "-"
            ) : (
              <Stack direction="row" alignItems="center">
                <Typography variant="subtitle2">{row[column.id]}</Typography>
                {
                  <IconButton
                    onClick={() => {
                      setRoleMode("edit");
                      setRoleDetailData(row);
                      setRoleAddOrEditDialogOpen(true);
                    }}
                  >
                    <SvgIconStyle
                      src={EditIcon}
                      sx={{
                        width: 22,
                        height: 22,
                      }}
                    />
                  </IconButton>
                }
              </Stack>
            )}
          </TableCell>
        );
      case "":
        return (
          <TableCell
            align="right"
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <ProjectRolesTableActionCol
              row={row}
              handleRefresh={handleRefresh}
            />
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width", "maxWidth") }}
          >
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {row[column.id] || "-"}
            </Typography>
          </TableCell>
        );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MlCard
      title="Project Roles"
      /* extraJsx={(isSandbox) ?(
        <Button 
          variant="contained" 
          sx={{ width: 120 }}
          onClick={() => setOpenAddUserDialog(true)}
        >
          Add User
        </Button>
      ) : <></>} */
      // hasBorderBottom
      hasPaddingX={false}
      extraJsx={
        <IconButton onClick={() => handleRefresh()}>
          <RefreshOutlinedIcon />
        </IconButton>
      }
    >
      <Scrollbar>
        <TableContainer sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell padding='checkbox' /> */}
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <MlTableBodyContainer loading={loading} data={data?.items}>
                {data?.items?.map((row, index) => (
                  <ProjectRolesTableRow key={index} row={row}>
                    {columns.map((column) => renderCell(row, column))}
                  </ProjectRolesTableRow>
                ))}
              </MlTableBodyContainer>
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Divider />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ pt: 2 }}
      >
        <Button
          variant="text"
          color="secondary"
          startIcon={<AddOutlinedIcon />}
          onClick={() => {
            setRoleMode("add");
            setRoleAddOrEditDialogOpen(true);
          }}
        >
          Add Project Role
        </Button>
      </Stack>

      {/* Currently only adding AD groups is supported, users cannot be added directly, users are bound to AD groups */}
      {/* <ProjectAddUserDialog 
        isOpen={openAddUserDialog}
        onClose={() => { setOpenAddUserDialog(false) }}
        fetchData={fetchData}
      /> */}

      <ProjectRoleAddDialog
        mode={roleMode}
        handleRefresh={handleRefresh}
        roleDetailData={roleDetailData}
        isOpen={roleAddOrEditDialogOpen}
        onClose={() => {
          setRoleAddOrEditDialogOpen(false);
        }}
        curProjectId={curProjectId}
      />
    </MlCard>
  );
};

export default ProjectRolesTable;

import Label from "@/components/project/Label";
import MlCard from "@/components/project/mlComponents/MlCard";
import MlCardList from "@/components/project/mlComponents/MlCardList";
import { RootState } from "@/redux/store";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";

enum StatusEnum {
  Succeeded = "success",
  Failed = "error",
}

const TrailCard = () => {
  const {
    hptunerResult: { data, loading },
  } = useSelector((state: RootState) => state.experiment);

  const projectBaseInfoList = () => {
    const { experiment = {} } = data || {};
    const {
      running_trials,
      failed_trials,
      succeeded_trails,
      goal,
      status,
      best_trial: {
        best_trial_name = "",
        parameter_assignments: params = [],
        observation: { metrics = [] } = {},
      } = {},
    } = experiment;
    return [
      { subTitle: "Kubernetes Cluster", label: "default-k8s", loading },
      {
        subTitle: "Status",
        label: status ? (
          <Label
            sx={{ maxWidth: "120px" }}
            color={StatusEnum[status] || "default"}
          >
            {status}
          </Label>
        ) : (
          "-"
        ),
        loading,
      },
      { subTitle: "User Detail Goal", label: goal, loading },
      { subTitle: "Running Tials", label: running_trials, loading },
      { subTitle: "Failed Trials", label: failed_trials, loading },
      { subTitle: "Succeeded Trials", label: succeeded_trails, loading },
      { subTitle: "Best Trial", label: best_trial_name, loading },
      {
        subTitle: `Best Trials's Params`,
        label: (
          <Stack direction="row" spacing={1}>
            {params?.map((item) => (
              <Label color="default">{`${item.name}: ${Number(
                item.value
              )?.toFixed(5)}`}</Label>
            ))}
          </Stack>
        ),
        loading,
      },
      {
        subTitle: `Best Trials's Performance`,
        label: (
          <Stack direction="row" spacing={1}>
            {metrics?.map((item) => (
              <Label color="default">{`${item.name}: ${Number(
                item.latest
              )?.toFixed(5)}`}</Label>
            ))}
          </Stack>
        ),
        loading,
      },
    ];
  };

  return (
    <MlCard title="Overview">
      <MlCardList list={projectBaseInfoList()} />
    </MlCard>
  );
};

export default TrailCard;

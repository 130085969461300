import { useState } from "react";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { Stack, Collapse } from "@mui/material";
// utils
import { updateClarifyShapConfig } from "../../../graph/slices/slice";
// components
import {
  ClarifyCheckStep,
  ClarifyCheckShapConfigStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-clarify-check";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { PipelineTextfield } from "../../components/PipelineComponents";

// ----------------------------------------------------------------------

type Props = {
  currentStep: ClarifyCheckStep | null;
};

export default function ShapConfig({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [showProcessor, setShowProcessorr] = useState(false);

  const formik = useFormik<ClarifyCheckShapConfigStruct>({
    initialValues: {
      baseline: currentStep?.shapConfig.baseline || "",
      num_samples: currentStep?.shapConfig.num_samples || "",
      agg_method: currentStep?.shapConfig.agg_method || "mean_abs",
      use_logit: currentStep?.shapConfig.use_logit || "false",
      save_local_shap_values:
        currentStep?.shapConfig.save_local_shap_values || "true",
      seed: currentStep?.shapConfig.seed || "",
      num_clusters: currentStep?.shapConfig.num_clusters || "",
      text_config: currentStep?.shapConfig.text_config || "",
      image_config: currentStep?.shapConfig.image_config || "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      try {
        dispatch(updateClarifyShapConfig(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { handleSubmit } = formik;

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setShowProcessorr((prev) => !prev)}
        endIcon={
          <Icon
            icon={showProcessor ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
      >
        Shap Config
      </CollapseButtonStyle>

      <Collapse in={showProcessor} sx={{ mx: 2 }}>
        <Stack spacing={1} sx={{ my: 1.2, mb: 1.5 }}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onChange={handleSubmit}>
              <Stack spacing={2} sx={{ mb: 0.5 }}>
                <PipelineTextfield
                  label={"Baseline"}
                  field={"baseline"}
                  placeholder={"None | str | list"}
                  formik={formik}
                />

                <PipelineTextfield
                  label={"Num of samples"}
                  field={"num_samples"}
                  placeholder={"None | int"}
                  formik={formik}
                />

                <PipelineTextfield
                  label={"Aggregation method"}
                  field={"agg_method"}
                  placeholder={"None | mean_abs | median | mean_sq"}
                  formik={formik}
                />

                <PipelineTextfield
                  label={"Use logit"}
                  field={"use_logit"}
                  placeholder={"true | false"}
                  formik={formik}
                />

                <PipelineTextfield
                  label={"Save local shap values"}
                  field={"save_local_shap_values"}
                  placeholder={"true | false"}
                  formik={formik}
                />

                <PipelineTextfield
                  label={"Seed"}
                  field={"seed"}
                  placeholder={"None | int"}
                  formik={formik}
                />

                <PipelineTextfield
                  label={"Num of clusters"}
                  field={"num_clusters"}
                  placeholder={"None | int"}
                  formik={formik}
                />
              </Stack>
            </Form>
          </FormikProvider>
        </Stack>
      </Collapse>
    </>
  );
}

import { alpha, Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Card(theme: Theme) {
  const isLight = theme.palette.mode === "light";
  const shadowColor = isLight
    ? theme.palette.grey[500]
    : theme.palette.common.black;

  return {
    MuiCard: {
      styleOverrides: {
        root: {
          position: "relative",
          // boxShadow: theme.customShadows.z16,
          // boxShadow: theme.customShadows.card,
          boxShadow: `0 0 1px 0 ${alpha(
            shadowColor,
            0.42
          )}, 0 1px 3px -1px ${alpha(shadowColor, 0.24)}`,
          // borderRadius: theme.shape.borderRadiusMd,
          borderRadius: theme.shape.borderRadiusSm,
          // borderRadius: theme.shape.borderRadius,
          zIndex: 0, // Fix Safari overflow: hidden with border radius
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: "h6" },
        subheaderTypographyProps: {
          variant: "body2",
          marginTop: theme.spacing(0.5),
        },
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(2.5, 2.5, 0),
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
        },
      },
    },
  };
}

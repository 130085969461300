/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  InputAdornment,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
  Box,
  Button,
  Stack,
} from "@mui/material";
import Scrollbar from "@/components/project/Scrollbar";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { extractKeys } from "@/utils/project/utils";
import { useNavigate, useLocation } from "react-router";
import SearchIcon from "@mui/icons-material/Search";
import MlCard from "@/components/project/mlComponents/MlCard";
import Label from "@/components/project/Label";
import { dispatch, RootState, useSelector } from "@/redux/store";
import { useSnackbar } from "notistack";
import { getModelPackageList } from "@/redux/project/dataAsset/modelRegistry/thunks";
import MlTableBodyContainer from "@/components/project/mlComponents/MlTableBodyContainer";
import { isEmpty } from "lodash";
interface ModelPackageTableProps {
  setDrawerOpenAndSetData: (a: Record<string, any>) => void;
}

enum StateEnum {
  DRAFT = "secondary",
  RELEASED = "success",
  DEPRECATED = "warning",
  DELETE = "default",
}

const useStyles = makeStyles({
  searchContainer: {
    padding: 20,
    width: 300,
  },
  menuItem: {
    "&.MuiMenuItem-root": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
});

const ModelPackageTable: React.FC<ModelPackageTableProps> = ({
  setDrawerOpenAndSetData,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const {
    modelPackageDatas: { data, loading },
  } = useSelector((state: RootState) => state.modelRegistry);
  const {
    userDetail: { id, user_id },
  } = useSelector((state: RootState) => state.common);

  // filter
  const [searchText, setSearchText] = useState<string>("");
  const [deliveredTo, setDeliveredTo] = useState<string | undefined>("");
  const [deliveredToList, setDeliveredToList] = useState<Array<string> | []>(
    []
  );
  const [createdBy, setCreatedBy] = useState<string | undefined>("");
  const [createdByList, setCreatedByList] = useState<Array<string> | []>([]);
  const [state, setState] = useState<string | undefined>("");
  const [granted, setGranted] = useState<string | undefined>("");

  useEffect(() => {
    const deliveredTo: Array<string> = [];
    const createdBy: Array<string> = [];
    // eslint-disable-next-line array-callback-return
    data?.items?.map((row) => {
      if (deliveredTo.findIndex((item) => item === row.delivered_to) === -1) {
        deliveredTo.push(row.delivered_to);
      }
      if (createdBy.findIndex((item) => item === row.created_by) === -1) {
        createdBy.push(row.created_by);
      }
    });
    setDeliveredToList(deliveredTo);
    setCreatedByList(createdBy);
  }, [data]);

  // table
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage + 1);
    fetchData({ page_num: newPage, page_size: rowsPerPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    fetchData({ page_num: 1, page_size: parseInt(event.target.value, 10) });
  };

  // filter
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      fetchData({
        page_num: 1,
        page_size: 10,
        ...filter("name", searchText),
        ...filter("delivered_to", deliveredTo),
        ...filter("created_by", createdBy),
        ...filter("modelpackage_state", state),
        ...filter("granted", granted),
      })
        .unwrap()
        .then(() => {
          enqueueSnackbar("Search Success", { variant: "success" });
        });
    }
  };

  const filter = (name, value) => {
    if (value) return { [name]: value };
    return {};
  };

  const handleDeliverdToChange = (event) => {
    setDeliveredTo(event.target.value);
    fetchData({
      page_num: 1,
      page_size: 10,
      ...filter("delivered_to", event.target.value),
      ...filter("name", searchText),
      ...filter("created_by", createdBy),
      ...filter("modelpackage_state", state),
      ...filter("granted", granted),
    })
      .unwrap()
      .then(() => {
        enqueueSnackbar("Search Success", { variant: "success" });
      });
  };

  const handleCreatedByChange = (event) => {
    setCreatedBy(event.target.value);
    fetchData({
      page_num: 1,
      page_size: 10,
      ...filter("created_by", event.target.value),
      ...filter("name", searchText),
      ...filter("delivered_to", deliveredTo),
      ...filter("modelpackage_state", state),
      ...filter("granted", granted),
    })
      .unwrap()
      .then(() => {
        enqueueSnackbar("Search Success", { variant: "success" });
      });
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
    fetchData({
      page_num: 1,
      page_size: 10,
      ...filter("modelpackage_state", event.target.value),
      ...filter("name", searchText),
      ...filter("delivered_to", deliveredTo),
      ...filter("created_by", createdBy),
      ...filter("granted", granted),
    })
      .unwrap()
      .then(() => {
        enqueueSnackbar("Search Success", { variant: "success" });
      });
  };

  const handleGrantedChange = (event) => {
    setGranted(event.target.value);
    fetchData({
      page_num: 1,
      page_size: 10,
      ...filter("granted", event.target.value),
      ...filter("name", searchText),
      ...filter("delivered_to", deliveredTo),
      ...filter("created_by", createdBy),
      ...filter("modelpackage_state", state),
    })
      .unwrap()
      .then(() => {
        enqueueSnackbar("Search Success", { variant: "success" });
      });
  };

  const handleRefresh = () => {
    fetchData({ page_num: 1, page_size: 10 })
      .unwrap()
      .then(() => {
        enqueueSnackbar("Refresh Success", { variant: "success" });
      });
    setSearchText("");
    setDeliveredTo(undefined);
    setCreatedBy(undefined);
    setState(undefined);
    setGranted(undefined);
  };

  const fetchData = (params: {
    page_num: number;
    page_size: number;
    name?: string;
    delivered_to?: string;
    created_by?: string;
    modelpackage_state?: string;
    granted?: string;
  }) => {
    return dispatch(
      getModelPackageList({
        jwt_id: id,
        jwt_user_id: user_id,
        params,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  };

  useEffect(() => {
    // get model package
    fetchData({ page_num: 1, page_size: 10 });
  }, []);

  const columns = [
    { id: "name", label: "Name", minWidth: 150 },
    { id: "modelpackage_type", label: "Type", minWidth: 120 },
    { id: "delivered_to", label: "Group", minWidth: 120 },
    { id: "modelpackage_state", label: "State", minWidth: 80 },
    { id: "granted", label: "Granted", minWidth: 80 },
    { id: "created_by", label: "Created By", minWidth: 150 },
    { id: "created_at", label: "Created At", minWidth: 100 },
    { id: "updated_at", label: "Last Modified", minWidth: 100 },
    { id: "" },
  ];

  const renderCell = (row, column) => {
    switch (column.id) {
      case "name":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography
              variant="subtitle2"
              color="secondary"
              sx={{ cursor: "pointer" }}
              onClick={() => setDrawerOpenAndSetData(row)}
            >
              {row[column.id] || "-"}
            </Typography>
          </TableCell>
        );
      case "modelpackage_state":
        const state = row[column.id];
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            {!isEmpty(state) ? (
              <Label color={StateEnum[state]}>{state}</Label>
            ) : (
              "-"
            )}
          </TableCell>
        );
      case "":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width"), pr: 2 }}
          >
            <Grid container rowSpacing={1} columnSpacing={1}>
              <Grid item xs={6} lg={6}>
                <IconButton onClick={() => navigate(`${row.id}`)}>
                  <KeyboardArrowRightOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {row[column.id] || "-"}
            </Typography>
          </TableCell>
        );
    }
  };

  return (
    <MlCard
      title="Model Package"
      extraJsx={
        <Stack direction="row" alignItems="center" spacing={2}>
          <Button
            variant="contained"
            // sx={{ width: 150 }}
            onClick={() => navigate("modelPackageCreate")}
          >
            Add Model Package
          </Button>
          <IconButton onClick={handleRefresh}>
            <RefreshOutlinedIcon sx={{ width: "22px", height: "22px" }} />
          </IconButton>
        </Stack>
      }
      hasPaddingX={false}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <Box className={classes.searchContainer}>
          <TextField
            id="outlined-search"
            label="Search"
            type="search"
            variant="outlined"
            placeholder="Search by name"
            size="small"
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            onKeyDown={handleKeyDown}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => {
                      fetchData({
                        page_num: 1,
                        page_size: 10,
                        ...filter("name", searchText),
                        ...filter("delivered_to", deliveredTo),
                        ...filter("created_by", createdBy),
                        ...filter("modelpackage_state", state),
                        ...filter("granted", granted),
                      })
                        .unwrap()
                        .then(() => {
                          enqueueSnackbar("Search Success", {
                            variant: "success",
                          });
                        });
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Box>
        <FormControl size="small">
          <InputLabel>Group</InputLabel>
          <Select
            id="delivered_to"
            value={deliveredTo}
            label="Group"
            onChange={handleDeliverdToChange}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                  maxWidth: 200,
                },
              },
            }}
            sx={{ width: 150 }}
          >
            {deliveredToList.map((item, index) => (
              <MenuItem key={item} value={item} className={classes.menuItem}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small">
          <InputLabel>Created By</InputLabel>
          <Select
            id="created_by"
            value={createdBy}
            label="Created By"
            onChange={handleCreatedByChange}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                  maxWidth: 200,
                },
              },
            }}
            sx={{ width: 150 }}
          >
            {createdByList.map((item, index) => (
              <MenuItem key={item} value={item} className={classes.menuItem}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small">
          <InputLabel>State</InputLabel>
          <Select
            id="state"
            value={state}
            label="State"
            onChange={handleStateChange}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                  maxWidth: 200,
                },
              },
            }}
            sx={{ width: 150 }}
          >
            <MenuItem key="DRAFT" value="DRAFT" className={classes.menuItem}>
              DRAFT
            </MenuItem>
            <MenuItem
              key="RELEASED"
              value="RELEASED"
              className={classes.menuItem}
            >
              RELEASED
            </MenuItem>
            <MenuItem
              key="DEPRECATED"
              value="DEPRECATED"
              className={classes.menuItem}
            >
              DEPRECATED
            </MenuItem>
            <MenuItem
              key="DELETED"
              value="DELETED"
              className={classes.menuItem}
            >
              DELETED
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small">
          <InputLabel>Granted</InputLabel>
          <Select
            id="granted"
            value={granted}
            label="Granted"
            onChange={handleGrantedChange}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                  maxWidth: 200,
                },
              },
            }}
            sx={{ width: 150 }}
          >
            <MenuItem key="Yes" value="Yes" className={classes.menuItem}>
              Yes
            </MenuItem>
            <MenuItem key="No" value="No" className={classes.menuItem}>
              No
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <MlTableBodyContainer loading={loading} data={data?.items || []}>
              <TableBody>
                {data?.items?.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((column) => renderCell(row, column))}
                  </TableRow>
                ))}
              </TableBody>
            </MlTableBodyContainer>
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25]}
        component="div"
        count={data?.items?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </MlCard>
  );
};

export default ModelPackageTable;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  MenuItem,
  Stack,
  FormControl,
  InputLabel,
  Select,
  Switch,
  Checkbox,
} from "@mui/material";
import MlCard from "@/components/project/mlComponents/MlCard";
import Scrollbar from "@/components/project/Scrollbar";
import { extractKeys } from "@/utils/project/utils";
import { permissionList, role_permissions } from "../../constants";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { FormControlLabel } from "@mui/material";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  width?: number;
}

/* const useStyles = makeStyles({
  searchContainer: {
    padding: 20,
    width: 300,
  },
}); */

const columns: Column[] = [
  { id: "id", label: "Permission Id" },
  { id: "module", label: "Module" },
  { id: "description", label: "Description" },
];

const RolePermissionsTable = () => {
  // const classes = useStyles();
  const [list, setList] = useState<any>([]);

  const [showAll, setShowAll] = useState<boolean>(false);

  const [module, setModule] = useState<string>("");

  const [moduleList, setModuleList] = useState<string[]>([]);

  const {
    projectRoleDetail: { data = {} },
  } = useSelector((state: RootState) => state.template);

  // table
  // const [searchText, setSearchText] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  /* const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  }; 

  const handleSearch = () => {
    
  };*/

  const handleModuleChange = (event) => {
    setModule(event.target.value);

    if (event.target.value === "All") {
      handleShowAll(showAll);
    } else {
      const tList = handleShowAll(showAll, true);
      setList(tList.filter((item) => item.module === event.target.value));
    }
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleInitList = () => {
    const filterList = permissionList.filter((item) =>
      role_permissions[data.name].includes(item.id)
    );
    const tFilterList = filterList.map((item) => ({
      ...item,
      isSelected: true,
    }));
    setList(tFilterList);

    return tFilterList;
  };

  const handleSetModuleList = (listData) => {
    let obj = {};
    listData.forEach((item) => {
      if (!obj[item.module]) {
        obj[item.module] = true;
      }
    });

    setModuleList(["All", ...Object.keys(obj)]);
  };

  const handleShowAll = (isShowAll: boolean, isFiltered?: boolean) => {
    setPage(0);
    setRowsPerPage(10);
    if (!isFiltered) {
      setModule("All");
    }

    if (isShowAll) {
      const tempList = permissionList.map((item) => ({
        ...item,
        isSelected: role_permissions[data.name].includes(item.id),
      }));
      setList(tempList);

      if (!isFiltered) {
        handleSetModuleList(tempList);
      }

      return tempList;
    } else {
      const filterList = handleInitList();
      if (!isFiltered) {
        handleSetModuleList(filterList);
      }

      return filterList;
    }
  };

  const getCurrentRows = () => {
    const indexOfLastRow = (page + 1) * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = list.slice(indexOfFirstRow, indexOfLastRow);
    return currentRows;
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      const filterList = handleInitList();

      handleSetModuleList(filterList);
      setModule("All");
    }
  }, [data]);

  return (
    <MlCard title="Permissions">
      <Stack sx={{ mb: 1 }} direction="row" spacing={2} alignItems="center">
        {/* <Box className={classes.searchContainer}>
          <TextField
            id="search"
            label="Search permission..."
            variant="outlined"
            size="small"
            value={searchText}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Box> */}
        <FormControl size="small">
          <InputLabel>Module</InputLabel>
          <Select
            value={module}
            label="Module"
            onChange={handleModuleChange}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                  maxWidth: 200,
                },
              },
            }}
            sx={{ width: 200 }}
          >
            {moduleList.map((item, index) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControlLabel
          label="Show All"
          control={
            <Switch
              checked={showAll}
              onChange={(event) => {
                setShowAll(event.target.checked);
                handleShowAll(event.target.checked);
              }}
            />
          }
        />
      </Stack>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getCurrentRows().map((row) => (
                <TableRow key={row.id}>
                  <TableCell padding="checkbox">
                    <Checkbox checked={row?.isSelected || false} disabled />
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{ ...extractKeys(column, "minWidth", "width") }}
                    >
                      {row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 10, 15, 25]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </MlCard>
  );
};

export default RolePermissionsTable;

import { Form, Field, useFormik, FormikProvider } from "formik";
import {
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Stack,
  Box,
  Autocomplete,
} from "@mui/material";
import * as Yup from "yup";
import MlCard from "@/components/project/mlComponents/MlCard";
import Scrollbar from "@/components/project/Scrollbar";
import { Chip } from "@mui/material";
import { PATH_DASHBOARD } from "@/routes/paths";
import { useNavigate } from "react-router";

interface FormValues {
  instanceName: string;
  instanceType: string;
  platformIdentifier: string;
  volumeSize: string;
  tags: string[];
  template: string;
}

const initialValues: FormValues = {
  instanceName: "",
  instanceType: "",
  platformIdentifier: "",
  volumeSize: "",
  tags: [],
  template: "",
};

const validationSchema = Yup.object({
  instanceName: Yup.string()
    .required("Instance Name is required")
    .max(64, "Instance Name cannot be longer than 64 characters"),
  instanceType: Yup.string().required("Instance Type is required"),
  platformIdentifier: Yup.string().required("Platform Identifier is required"),
  volumeSize: Yup.number()
    .typeError("Volume Size must be number type")
    .integer("Volume Size must be integer")
    .required("Volume Size is required")
    .min(1, "Port number must be between 1 and 1000")
    .max(1000, "Port number must be between 1 and 1000"),
});

const SageMakerNotebook: React.FC = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      console.log(values);
      actions.setSubmitting(false);
    },
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    // isSubmitting,
    // isValid,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form>
        <MlCard title="Notebook Instance Settings">
          <Scrollbar>
            <Box sx={{ pb: 2, width: "800px" }}>
              <Field
                as={TextField}
                name="instanceName"
                label="Notebook Instance Name"
                size="small"
                fullWidth
                margin="dense"
                helperText={
                  (touched.instanceName && errors.instanceName) ||
                  "Maximum of 63 alphanumeric characters. Can include hyphens (-), but not spaces. Must be unque within your account in an AWS Region"
                }
                error={touched.instanceName && Boolean(errors.instanceName)}
                FormHelperTextProps={{
                  sx: { minHeight: "18px", marginTop: "2px" },
                }}
              />
              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={touched.instanceType && Boolean(errors.instanceType)}
              >
                <InputLabel>Notebook Instance Type</InputLabel>
                <Field
                  as={Select}
                  name="instanceType"
                  label="Notebook Instance Type"
                >
                  <MenuItem value="ml.t3.medium">ml.t3.medium</MenuItem>
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {touched.instanceType && errors.instanceType
                    ? errors.instanceType
                    : ""}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={
                  touched.platformIdentifier &&
                  Boolean(errors.platformIdentifier)
                }
              >
                <InputLabel>Platform Identifier</InputLabel>
                <Field
                  as={Select}
                  name="platformIdentifier"
                  label="Platform Identifier"
                  error={
                    touched.platformIdentifier &&
                    Boolean(errors.platformIdentifier)
                  }
                >
                  <MenuItem value="Amazon Linux 2, Jupyter Lab 3">
                    Amazon Linux 2, Jupyter Lab 3
                  </MenuItem>
                </Field>
                <FormHelperText
                  margin="dense"
                  sx={{ minHeight: "18px", marginTop: "2px" }}
                >
                  {touched.platformIdentifier && errors.platformIdentifier
                    ? errors.platformIdentifier
                    : ""}
                </FormHelperText>
              </FormControl>
              <Field
                as={TextField}
                name="volumeSize"
                label="Volume Size In GB"
                size="small"
                fullWidth
                margin="dense"
                helperText={
                  (touched.volumeSize && errors.volumeSize) ||
                  "Enter the volume size of the notebook instance in GB. The volume size must be from 5 GB to 16384 (16TB)."
                }
                error={touched.volumeSize && Boolean(errors.volumeSize)}
                FormHelperTextProps={{
                  sx: { minHeight: "18px", marginTop: "2px" },
                }}
              />
              <Autocomplete
                size="small"
                // autoSelect -- blur one less value
                multiple
                disableCloseOnSelect
                disablePortal
                // freeSolo allow the value not in options
                limitTags={8}
                options={["Test H2O", "Experment2", "Epm1"]}
                value={values.tags}
                // onInputChange={} input value - e.target.value
                onChange={(_, newValues) => {
                  setFieldValue("tags", newValues);
                }}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      {...getTagProps({ index })}
                      size="small"
                      variant="soft"
                      label={option}
                      key={option}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tags (Optional)"
                    fullWidth
                    margin="dense"
                    helperText=" "
                    FormHelperTextProps={{
                      style: {
                        minHeight: "18px",
                        marginTop: "2px",
                      },
                    }}
                  />
                )}
              />
              <FormControl fullWidth margin="dense" size="small">
                <InputLabel>Notebook Template (Optional)</InputLabel>
                <Field
                  as={Select}
                  name="template"
                  label="Notebook Template (Optional)"
                >
                  <MenuItem value="template1">template1</MenuItem>
                  <MenuItem value="template2">template2</MenuItem>
                  <MenuItem value="template3">template3</MenuItem>
                </Field>
              </FormControl>
            </Box>
          </Scrollbar>
        </MlCard>
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ width: "200px", color: "background.paper" }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            sx={{ width: "200px", color: "text.secondary" }}
            onClick={() => navigate(PATH_DASHBOARD.experiment.notebook)}
          >
            Cancel
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default SageMakerNotebook;

import { createSlice } from "@reduxjs/toolkit";
import { addThunkReducers } from "../utils";
import { IAutomationState } from "@/@types/project/model/automation";
import {
  getPipelineDetailData,
  getPipelineExecutionDetailData,
  getPipelineExecutionTableData,
  getPipelineTableData,
  getPipelineVersionDetail,
  getPipelineVersionTableData,
  getPipelineVolumeDetailData,
  getPipelineVolumeTableData,
} from "./thunks";

const initialState: IAutomationState = {
  pipelineTableList: {
    loading: false,
    data: {},
    error: null,
  },
  pipelineDetail: {
    loading: false,
    data: {},
    error: null,
  },
  pipelineVolumeTableList: {
    loading: false,
    data: {},
    error: null,
  },
  pipelineVolumeDetail: {
    loading: false,
    data: {},
    error: null,
  },
  pipelineVersionTableList: {
    loading: false,
    data: {},
    error: null,
  },
  pipelineVersionDetail: {
    loading: false,
    data: {},
    error: null,
  },
  pipelineExecutionTableList: {
    loading: false,
    data: {},
    error: null,
  },
  pipelineExecutionDetail: {
    loading: false,
    data: {},
    error: null,
  },
};

const slice = createSlice({
  name: "automation",
  initialState,
  reducers: {
    setAutomationData(state, action) {
      Object.assign(state, action.payload);
    },

    resetPipelineData(state, action) {
      Object.assign(state[action.payload], { data: {} });
    },
  },
  extraReducers: (builder) => {
    addThunkReducers(builder, [
      { action: getPipelineTableData, name: "pipelineTableList" },
      { action: getPipelineDetailData, name: "pipelineDetail" },
      { action: getPipelineVolumeTableData, name: "pipelineVolumeTableList" },
      { action: getPipelineVolumeDetailData, name: "pipelineVolumeDetail" },
      { action: getPipelineVersionTableData, name: "pipelineVersionTableList" },
      { action: getPipelineVersionDetail, name: "pipelineVersionDetail" },
      {
        action: getPipelineExecutionTableData,
        name: "pipelineExecutionTableList",
      },
      {
        action: getPipelineExecutionDetailData,
        name: "pipelineExecutionDetail",
      },
    ]);
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setAutomationData, resetPipelineData } = slice.actions;

/* eslint-disable react-hooks/exhaustive-deps */
import Page from "@/components/project/Page";
import {
  getHptunerDetail,
  getHptunerResult,
} from "@/redux/project/experiment/thunks";
import { RootState, dispatch } from "@/redux/store";
import { PATH_DASHBOARD } from "@/routes/paths";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { resetNotebookData } from "@/redux/project/experiment/slice";
import TrailCard from "./components/hpTunerDetail/TrailCard";
import TrailTable from "./components/hpTunerDetail/TrailTable";
import TrailChart from "./components/hpTunerDetail/TrailChart";

const HptunerDetail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { hpTunerId } = useParams();

  const {
    hptunerDetail: { data },
  } = useSelector((state: RootState) => state.experiment);

  const getLinks = () => {
    return [
      {
        name: "Experiment",
      },
      {
        name: "HP Tuner",
        href: PATH_DASHBOARD.experiment.hpTuner,
      },
      {
        name: data.name || "",
      },
    ];
  };

  /*   const handleDelProject = async () => {
    setDelLoading(true);
    try {
      await dispatch(
        deleteHptuner({
          id: hpTunerId!,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      setDelLoading(false);
      enqueueSnackbar('Delete Success', { variant: 'success' })

      navigate(PATH_DASHBOARD.experiment.hpTuner);
    } catch (e) {
      setDelLoading(false)
    }
  } */

  /* const getAction = () => {
    return (
      <Box sx={{ width: '120px' }}>
        <Button onClick={handleClick} variant="outlined" endIcon={<ExpandMoreOutlinedIcon sx={{ transform: open ? 'rotate(0)' : 'rotate(-90deg)', transition: 'transform 0.5s' }} />} color='secondary' fullWidth>
          Actions
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '120px'
            }
          }}
        >
          <MenuItem onClick={() => {
            handleClose();
            setOpenConfirm(true);
          }}>Delete</MenuItem>
        </Menu>
      </Box>
    )
  }; */

  useEffect(() => {
    dispatch(
      getHptunerDetail({
        id: hpTunerId!,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    )
      .unwrap()
      .then((res) => {
        dispatch(
          getHptunerResult({
            params: {
              name: res.name,
              hptuner_type: res.hptuner_type,
            },
            alertCallback: enqueueSnackbar,
            pathname,
          })
        );
      });

    return () => {
      dispatch(resetNotebookData("hptunerDetail"));
      dispatch(resetNotebookData("hptunerResult"));
    };
  }, []);

  return (
    <Page heading={data.name} title={data.name} links={getLinks()}>
      <TrailChart />
      <TrailCard />
      <TrailTable />

      {/* <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete hptuner <strong> {data.name} </strong>{" "}
            item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: '80px' }}
            onClick={() => {
              handleDelProject();
            }}
            loading={delLoading}
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      /> */}
    </Page>
  );
};

export default HptunerDetail;

import request from "@/utils/project/request";

// organization
export const getOrganizationList = (params) => {
  return request.get(`/govern/organization`, { ...params, is_active: 1 });
};

export const getOrganizationDetail = (id) => {
  return request.get(`/govern/organization/${id}`);
};

export const updateOrganizationService = (id, data) => {
  return request.put(`/govern/organization/${id}`, { payload: data });
};

// project
export const getProjectList = (params, isAll) => {
  return request.get(`/govern/project`, {
    ...params,
    ...(isAll ? {} : { is_active: 1 }),
  });
};

export const getProjectDetail = (id, organization_id) => {
  if (organization_id) {
    return request.get(`/govern/project/${id}`, { organization_id });
  } else {
    return request.get(`/govern/project/${id}`);
  }
};

export const getProjectTagService = (params) => {
  return request.get(`/govern/rel_project_tag`, { ...params, is_active: 1 });
};

export const addProjectTagService = (params) => {
  return request.post(`/govern/rel_project_tag`, { payload: params });
};

export const delProjectTagService = (id) => {
  return request.put(`/govern/rel_project_tag/${id}`, {
    payload: { is_active: 0 },
  });
};

export const createProjectService = (data) => {
  return request.post(`/govern/project`, { payload: data });
};

export const updateProjectService = (id, data) => {
  return request.put(`/govern/project/${id}`, { payload: data });
};

export const deleteProjectService = (id) => {
  return request.put(`/govern/project/${id}`, {
    payload: { is_active: 0, status: "wait_close" },
  });
};

// project role
export const getProjectRolesByProjectId = (params, isAll) => {
  return request.get(`/govern/rel_project_role`, {
    ...params,
    ...(isAll ? {} : { is_active: 1 }),
  });
};

export const addProjectRoleService = (params) => {
  return request.post(`/govern/rel_project_role`, { payload: params });
};

export const delProjectRoleService = (id) => {
  return request.put(`/govern/rel_project_role/${id}`, {
    payload: { is_active: 0 },
  });
};

export const updateProjectRoleService = (id, params) => {
  return request.put(`/govern/rel_project_role/${id}`, { payload: params });
};

// project role users
export const getProjectRolesUsersByRoleId = (params) => {
  return request.get(`/govern/rel_user_projectrole`, {
    ...params,
    is_active: 1,
  });
};

export const addProjectRolesUser = (params) => {
  return request.post(`/govern/rel_user_projectrole`, { payload: params });
};

export const delProjectRolesUser = (id) => {
  return request.put(`/govern/rel_user_projectrole/${id}`, {
    payload: { is_active: 0 },
  });
};

// users
export const getUsersDataService = (params) => {
  return request.get(`/govern/user`, { ...params, is_active: 1 });
};

// tags
export const getTagsDataService = (params) => {
  return request.get(`/govern/tag`, { ...params, is_active: 1 });
};

export const getTagDetailService = (id) => {
  return request.get(`/govern/tag/${id}`);
};

export const addTagsDataService = (params) => {
  return request.post(`/govern/tag`, { payload: params });
};

// secret
export const addSecretService = (params) => {
  return request.post(`/govern/secret`, { payload: params });
};

export const updateSecretService = (name, params) => {
  return request.put(`/govern/secret/${name}`, { payload: params });
};

import { Box, Stepper, Step, StepLabel } from "@mui/material";
import { useState } from "react";
import BasicInformationStep from "./BasicInformationStep";
import DeploymentStep from "./DeploymentsStep";
import MetricsStep from "./MetricsStep";

const MlRuntimeCreateOrEditForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [mlServiceType, setMlServiceType] = useState<"Kubeflow" | "SageMaker">(
    "Kubeflow"
  );
  const [serviceInfo, setServiceInfo] = useState<Record<string, any>>({});
  const [mlServiceRes, setMlServiceRes] = useState<Record<string, any>>({});

  const steps = ["Basic Information", "Deployments", "Metrics(Optional)"];

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {activeStep === 0 && (
        <BasicInformationStep
          mlServiceType={mlServiceType}
          setMlServiceType={setMlServiceType}
          setActiveStep={setActiveStep}
          setMlServiceRes={setMlServiceRes}
          setServiceInfo={setServiceInfo}
        />
      )}
      {activeStep === 1 && (
        <DeploymentStep
          mlServiceType={mlServiceType}
          setActiveStep={setActiveStep}
          mlServiceRes={mlServiceRes}
          serviceInfo={serviceInfo}
        />
      )}
      {activeStep === 2 && <MetricsStep mlServiceRes={mlServiceRes} />}
    </>
  );
};

export default MlRuntimeCreateOrEditForm;

import { useState } from "react";
import { Icon } from "@iconify/react";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { Box, Stack, Divider, Collapse } from "@mui/material";
// redux
import { RootState, useDispatch, useSelector } from "@/redux/store";
// @types
import { TrainingStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-training";
// components
import Estimator from "./Estimator";
import TrainingCode from "./TrainingCode";
import TrainingOutput from "./TrainingOutput";
import TrainingInputs from "./TrainingInputs";
import MetricDefinitions from "./MetricDefinitions";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { StyledTextField } from "../../components/PipelineComponents";
import {
  SchemaNodeEntity,
  updateEntityName,
} from "../../../graph/slices/slice";

// ----------------------------------------------------------------------

type Props = {
  entity: SchemaNodeEntity;
};

export default function TrainingStepEntry({ entity }: Props) {
  const dispatch = useDispatch();
  const [showStepConfig, setShowStepConfig] = useState(true);

  const currentStep = useSelector((state: RootState) => {
    let step: TrainingStep | undefined = state.schema.schema.pipelineSteps.find(
      (step) => {
        return step.id === entity.id;
      }
    ) as TrainingStep;
    return step ? step : null;
  });

  const codeProps = currentStep?.code || {
    source: "amazon_s3",
    sourceDir: "",
    entryPoint: "",
  };

  return (
    <>
      {entity && (
        <Box>
          <CollapseButtonStyle
            fullWidth
            color="inherit"
            onClick={() => setShowStepConfig((prev) => !prev)}
            endIcon={
              <Icon
                icon={
                  showStepConfig ? arrowIosDownwardFill : arrowIosForwardFill
                }
                width={16}
                height={16}
              />
            }
          >
            Configuration
          </CollapseButtonStyle>

          <Collapse in={showStepConfig}>
            <Stack spacing={2} sx={{ mx: 2, mt: 1, mb: 2 }}>
              <StyledTextField
                label="Step name"
                defaultValue={entity.name}
                onChange={(event: any) => {
                  const newValue = event.target.value;
                  dispatch(updateEntityName({ name: newValue }));
                }}
              />

              <TrainingCode data={codeProps} />
            </Stack>
          </Collapse>

          <Divider />

          <Estimator currentStep={currentStep} />

          <Divider />

          <TrainingInputs currentStep={currentStep} />

          <Divider />

          <TrainingOutput currentStep={currentStep} />

          <Divider />

          <MetricDefinitions currentStep={currentStep} />

          <Divider sx={{ mb: 1 }} />
        </Box>
      )}
    </>
  );
}

import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Box, Stack, Collapse, IconButton } from "@mui/material";
//
import EmptyTable from "@/components/project/EmptyTable";
import ProcessingInput from "./ProcessingInput";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { ProcessingStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-processing";
import { updateProcessingInput } from "../../../graph/slices/slice";

// ----------------------------------------------------------------------

type Props = {
  currentStep: ProcessingStep | null;
};

export default function ProcessingInputs({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [showProcessingInput, setShowProcessingInput] = useState(false);

  const processingInputs = currentStep?.processingInputs || [];

  const handleAddProcessingInput = () => {
    dispatch(
      updateProcessingInput({
        data: {
          inputName: `ProcessingInput-${processingInputs.length + 1}`,
          source: "",
          sourceUseParam: false,
          destination: "/opt/ml/processing/ie/data/",
          destinationUseParam: false,
          s3DataDistributionType: "ShardedByS3Key",
          s3CompressionType: "None",
        },
      })
    );
  };

  return (
    <>
      <Box sx={{ mb: 0, position: "relative" }}>
        <CollapseButtonStyle
          fullWidth
          color="inherit"
          onClick={() => setShowProcessingInput((prev) => !prev)}
          endIcon={
            <Icon
              icon={
                showProcessingInput ? arrowIosDownwardFill : arrowIosForwardFill
              }
              width={16}
              height={16}
            />
          }
        >
          Processing Inputs{" "}
          {processingInputs.length > 0 && `(${processingInputs.length})`}
        </CollapseButtonStyle>

        <IconButton
          sx={{ zIndex: 1999, position: "absolute", top: 7, right: 30 }}
          size="small"
          onClick={handleAddProcessingInput}
        >
          <Icon icon={plusFill} width={16} height={16} />
        </IconButton>
      </Box>

      <Collapse in={showProcessingInput}>
        <Stack
          spacing={1.5}
          sx={{ mx: 2, mb: 1.5, mt: showProcessingInput ? 0.5 : 0 }}
        >
          {processingInputs.length > 0 &&
            processingInputs.map((processingInput, index) => (
              <ProcessingInput key={index} id={index} data={processingInput} />
            ))}
        </Stack>
      </Collapse>

      {showProcessingInput && processingInputs.length === 0 && (
        <EmptyTable
          mainTitle={"No processing inputs"}
          content={'Create one by clicking "+" button'}
          color={"text.disabled"}
          sx={{ backgroundColor: "transparent", mx: 2, mt: 5, mb: 7 }}
        />
      )}
    </>
  );
}

import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import { useDispatch } from "react-redux";
// material
import { Paper, Stack, IconButton } from "@mui/material";
// components
import { LambdaOutputStruct } from "@/@types/project/mlPipeline/SageMaker/pipeline-lambda";
import {
  PipelineTextfield,
  PipelineSelect,
} from "../../components/PipelineComponents";
import {
  updateLambdaOutput,
  deleteLambdaOutput,
} from "../../../graph/slices/slice";

// ----------------------------------------------------------------------
const lambdaOutputTypes = [
  { code: "A", label: "String" },
  { code: "B", label: "Integer" },
  { code: "C", label: "Float" },
];

export default function LambdaOutput({
  id,
  data,
}: {
  id: number;
  data: LambdaOutputStruct;
}) {
  const dispatch = useDispatch();
  const [isInitialzied, setInitialized] = useState(false);
  const [inputValues, setInputValues] = useState(data);

  useEffect(() => {
    if (isInitialzied) {
      const timer = setTimeout(() => {
        dispatch(
          updateLambdaOutput({
            id: id,
            data: inputValues,
          })
        );
        setInitialized(false);
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [dispatch, id, inputValues, isInitialzied]);

  const ParamSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    // source: Yup.string().required('Source is required'),
    // destination: Yup.string().required('Destination is required')
  });

  const formik = useFormik<LambdaOutputStruct>({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values, { setErrors }) => {
      try {
        setInitialized(true);
        setInputValues(values);
      } catch (error) {
        // console.log(error);
      }
    },
  });

  const { handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Paper sx={{ p: 1.5, borderRadius: 1.5, bgcolor: "background.neutral" }}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onChange={handleSubmit}>
            <Stack spacing={2}>
              <PipelineTextfield
                label={"Name"}
                field={"name"}
                placeholder={"Lambda output name"}
                formik={formik}
              />

              <Stack direction="row" spacing={0.5} alignItems="center">
                <PipelineSelect
                  label={"Output type"}
                  field={"type"}
                  placeholder={"Select lambda output type"}
                  options={lambdaOutputTypes}
                  getFieldProps={getFieldProps}
                />
                <IconButton
                  size="small"
                  onClick={() => {
                    dispatch(deleteLambdaOutput(id));
                  }}
                >
                  <Icon icon={trash2Fill} width={18} height={18} />
                </IconButton>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </Paper>
    </>
  );
}

import { useEffect } from "react";
import { Dialog, Stack, Typography } from "@mui/material";
import { RootState, dispatch } from "@/redux/store";
import { getPipelineExecutionDetailData } from "@/redux/project/automation/thunks";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import DetailTable from "./DetailTable";
import DetailGraph from "./Graph";
import StepDetial from "./StepDetail";
import { isEmpty } from "lodash";
import { clearSchema, mapSchema } from "./Graph/executionSlice";

type Props = {
  isOpenDialog: boolean;
  onCloseDialog: VoidFunction;
  row: Record<any, any>;
};

export default function ExecutionDetailDialog({
  isOpenDialog,
  onCloseDialog,
  row,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const { pipelineSteps, edgeEntities } = useSelector(
    (state: RootState) => state.schema.schema
  );

  const {
    pipelineExecutionDetail: { data, loading },
  } = useSelector((state: RootState) => state.automation);

  useEffect(() => {
    if (isOpenDialog) {
      dispatch(
        getPipelineExecutionDetailData({
          id: row.id,
          pathname,
          alertCallback: enqueueSnackbar,
        })
      );
    }
  }, [isOpenDialog]);

  useEffect(() => {
    if (!isEmpty(data) && isOpenDialog) {
      dispatch(
        mapSchema({
          pipelineSteps: pipelineSteps,
          edgeEntities: edgeEntities,
          executionStepInfo: data.steps,
        })
      );
    }

    return () => {
      dispatch(clearSchema(true));
    };
  }, [data, isOpenDialog]);

  return (
    <Dialog
      open={isOpenDialog}
      onClose={onCloseDialog}
      sx={{ borderRadius: "8px" }}
      PaperProps={{
        sx: {
          width: {
            xs: "80%",
            md: "85%",
          },
          height: {
            xs: "80%",
            md: "80vh",
          },
          maxWidth: "none",
          p: "20px",
        },
      }}
    >
      <Stack direction="column" className="pipeline-execution">
        <Typography variant="subtitle2" sx={{ mb: 1.5 }}>
          {data?.name}
        </Typography>

        <DetailTable />

        <DetailGraph />

        <StepDetial />
      </Stack>
    </Dialog>
  );
}

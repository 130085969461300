// import * as Yup from "yup";
import { Icon } from "@iconify/react";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
// material
import { Box, Stack, Divider, Collapse } from "@mui/material";
// redux
import { RootState, useDispatch, useSelector } from "@/redux/store";
// @types
import {
  RegisterModelStep,
  ModelConfigStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-register-model";
//
import {
  SchemaNodeEntity,
  updateEntityName,
  updateModelConfig,
} from "../../../graph/slices/slice";
import ModelMetrics from "./ModelMetrics";
import ModelConfiguration from "./ModelConfiguration";
import DriftCheckBaselines from "./DriftCheckBaselines";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { StyledTextField } from "../../components/PipelineComponents";
import PipelineParameterWithSelector from "../../components/PipelineParameterWithSelector";

// ----------------------------------------------------------------------

export const ApprovalStatuses = [
  { code: "A", label: "PendingManualApproval" },
  { code: "B", label: "Approved" },
  { code: "C", label: "Rejected" },
];

type Props = {
  entity: SchemaNodeEntity;
};

export default function RegisterModelStepEntry({ entity }: Props) {
  const dispatch = useDispatch();
  const [showStepConfig, setShowStepConfig] = useState(true);

  const currentStep = useSelector((state: RootState) => {
    let step: RegisterModelStep = state.schema.schema.pipelineSteps.find(
      (step) => {
        return step.id === entity.id;
      }
    ) as RegisterModelStep;
    return step;
  });

  interface RegisterModelInput extends ModelConfigStruct {
    approvalStatus: string;
    approvalStatusUseParam: boolean;
  }

  const formik = useFormik<RegisterModelInput>({
    initialValues: {
      approvalStatus: currentStep.approvalStatus,
      approvalStatusUseParam: currentStep.approvalStatusUseParam,
      ...currentStep.model,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      try {
        dispatch(updateModelConfig(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { handleSubmit } = formik;

  return (
    <>
      {entity && currentStep && (
        <Box>
          <CollapseButtonStyle
            fullWidth
            color="inherit"
            onClick={() => setShowStepConfig((prev) => !prev)}
            endIcon={
              <Icon
                icon={
                  showStepConfig ? arrowIosDownwardFill : arrowIosForwardFill
                }
                width={16}
                height={16}
              />
            }
          >
            Configuration
          </CollapseButtonStyle>

          <Collapse in={showStepConfig}>
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onChange={handleSubmit}>
                <Stack spacing={2} sx={{ mx: 2, mt: 1, mb: 2 }}>
                  <StyledTextField
                    label="Step name"
                    defaultValue={entity.name}
                    onChange={(event: any) => {
                      const newValue = event.target.value;
                      dispatch(updateEntityName({ name: newValue }));
                    }}
                  />

                  <PipelineParameterWithSelector
                    label={"Approval status"}
                    field={"approvalStatus"}
                    placeholder={"Select approval status"}
                    fieldUseParam={"approvalStatusUseParam"}
                    options={ApprovalStatuses}
                    formik={formik}
                  />
                </Stack>
              </Form>
            </FormikProvider>
          </Collapse>

          <Divider />

          <ModelConfiguration formik={formik} />

          <Divider />

          <ModelMetrics currentStep={currentStep} />

          <Divider />

          <DriftCheckBaselines currentStep={currentStep} />

          <Divider sx={{ mb: 1 }} />
        </Box>
      )}
    </>
  );
}

import { alpha, Theme } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { paper, menuItem } from "../css";

// ----------------------------------------------------------------------

// export default function Autocomplete(theme: Theme) {
//   return {
//     MuiAutocomplete: {
//       styleOverrides: {
//         paper: {
//           boxShadow: theme.customShadows.z20
//         }
//       }
//     }
//   };
// }

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& span.MuiAutocomplete-tag": {
            ...theme.typography.body2,
            width: 24,
            height: 24,
            lineHeight: "24px",
            textAlign: "center",
            padding: theme.spacing(0, 0.75),
            color: theme.palette.text.secondary,
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.grey[500], 0.16),
          },
        },
        paper: {
          ...paper({ theme, dropdown: true }),
          // boxShadow: theme.customShadows.dropdown,
        },
        listbox: {
          padding: theme.spacing(0, 0.5),
          [`& .${autocompleteClasses.option}`]: {
            ...menuItem(theme),
          },
        },
        option: {
          ...theme.typography.body2,
          padding: theme.spacing(0.5),
          margin: theme.spacing(0.75, 0),
          borderRadius: theme.shape.borderRadius,
        },
      },
    },
  };
}

import { Box, Divider } from "@mui/material";
import { RootState, useSelector } from "@/redux/store";
import { SchemaNodeEntity } from "../../../../graph/slices/slice";
import { FeatureSetUploadStep } from "@/@types/project/mlPipeline/Kubeflow/pipeline-featureset-upload";
import StepConfig from "./components/StepConfig";
import FeatureSetUploadProcessor from "./components/FeatureSetUploadProcessor";
import FeatureSetUploadInput from "./components/FeatureSetUploadInput";
import JobArgument from "./components/JobArgument";

type FeatureSetUploadStepProps = {
  entity: SchemaNodeEntity;
  isEdit: boolean;
};

export default function FeatureSetUploadStepEntry({
  entity,
  isEdit,
}: FeatureSetUploadStepProps) {
  const currentStep = useSelector((state: RootState) => {
    let step: FeatureSetUploadStep | undefined =
      state.schema.schema.pipelineSteps.find((step) => {
        return step.id === entity.id;
      }) as FeatureSetUploadStep;
    return step ? step : null;
  });

  return (
    <>
      {entity && (
        <Box>
          <StepConfig isEdit={isEdit} currentStep={currentStep} />

          <Divider />

          <JobArgument isEdit={isEdit} currentStep={currentStep} />

          <Divider />

          <FeatureSetUploadProcessor
            isEdit={isEdit}
            currentStep={currentStep}
          />

          <Divider />

          <FeatureSetUploadInput isEdit={isEdit} currentStep={currentStep} />

          <Divider sx={{ mb: 1 }} />
        </Box>
      )}
    </>
  );
}

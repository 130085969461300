import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import { useDispatch } from "react-redux";
// material
import { Paper, Stack, IconButton } from "@mui/material";
//
import { ProcessingOutputStruct } from "@/@types/project/mlPipeline/SageMaker/pipeline-processing";
import {
  updateProcessingOutput,
  deleteProcessingOutput,
} from "../../../graph/slices/slice";

import {
  PipelineTextfield,
  PipelineSelect,
} from "../../components/PipelineComponents";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";

// ----------------------------------------------------------------------
const s3UploadModes = [
  { code: "A", label: "EndOfJob" },
  { code: "B", label: "StartOfJob" },
];

export default function ProcessingOutput({
  id,
  data,
}: {
  id: number;
  data: ProcessingOutputStruct;
}) {
  const dispatch = useDispatch();
  const [isInitialzied, setInitialized] = useState(false);
  const [inputValues, setInputValues] = useState(data);

  useEffect(() => {
    if (isInitialzied) {
      const timer = setTimeout(() => {
        dispatch(
          updateProcessingOutput({
            id: id,
            data: inputValues,
          })
        );
        setInitialized(false);
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [dispatch, id, inputValues, isInitialzied]);

  const ParamSchema = Yup.object().shape({
    outputName: Yup.string().required("Name is required"),
    // source: Yup.string().required('Source is required'),
    // destination: Yup.string().required('Destination is required')
  });

  const formik = useFormik<ProcessingOutputStruct>({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values, { setErrors }) => {
      try {
        setInitialized(true);
        setInputValues(values);
      } catch (error) {
        // console.log(error);
      }
    },
  });

  const { handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Paper sx={{ p: 1.5, borderRadius: 1.5, bgcolor: "background.neutral" }}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onChange={handleSubmit}>
            <Stack spacing={2}>
              {/* <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: -1, mt: -0.5 }}
              >
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Input 1
                </Typography>
                <IconButton size="small" onClick={() => {}}>
                  <Icon icon={trash2Fill} width={16} height={16} />
                </IconButton>
              </Stack> */}

              <PipelineTextfield
                label={"Name"}
                field={"outputName"}
                placeholder={"Output name"}
                formik={formik}
              />

              <PipelineParameterEntry
                label={"Source"}
                field={"source"}
                fieldUseParam={"sourceUseParam"}
                formik={formik}
              />

              <PipelineParameterEntry
                label={"Destination"}
                field={"destination"}
                fieldUseParam={"destinationUseParam"}
                formik={formik}
              />

              <Stack direction="row" spacing={0.5} alignItems="center">
                <PipelineSelect
                  label={"S3 upload mode"}
                  field={"s3UploadMode"}
                  placeholder={"Select S3 data upload mode"}
                  options={s3UploadModes}
                  getFieldProps={getFieldProps}
                />
                <IconButton
                  size="small"
                  onClick={() => {
                    dispatch(deleteProcessingOutput(id));
                  }}
                >
                  <Icon icon={trash2Fill} width={18} height={18} />
                </IconButton>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </Paper>
    </>
  );
}

import {
  Box,
  Collapse,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Field, FormikProps } from "formik";
import { IKubeflowHpTunerFormValues } from "..";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useState } from "react";

interface IProps {
  formik: FormikProps<IKubeflowHpTunerFormValues>;
}

const EarlyStopping = ({ formik }: IProps) => {
  const [open, setOpen] = useState<boolean>(true);

  const { values, errors, touched } = formik;

  const getAlgorithmSettings = (name) => {
    if (!name || name === "none") return <></>;

    return (
      <>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} lg={6}>
            <Field
              as={TextField}
              name="earlyStopping.settings.minTrialsRequired"
              label="Min Trials Required"
              size="small"
              fullWidth
              margin="dense"
              helperText={
                touched.earlyStopping?.settings?.minTrialsRequired &&
                errors.earlyStopping?.settings?.minTrialsRequired
              }
              error={
                touched.earlyStopping?.settings?.minTrialsRequired &&
                Boolean(errors.earlyStopping?.settings?.minTrialsRequired)
              }
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              as={TextField}
              name="earlyStopping.settings.startStep"
              label="Start Step"
              size="small"
              fullWidth
              margin="dense"
              helperText={
                touched.earlyStopping?.settings?.startStep &&
                errors.earlyStopping?.settings?.startStep
              }
              error={
                touched.earlyStopping?.settings?.startStep &&
                Boolean(errors.earlyStopping?.settings?.startStep)
              }
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        Early Stopping
      </Typography>
      <Box sx={{ pl: 2 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", pl: 1, py: 1 }}
        >
          Early siopping allows you to avoid overfitting when you train your
          model during Katib Experiments, You can read the{" "}
          <a
            href="https://www.kubeflow.org/docs/components/katib/user-guides/early-stopping/"
            target="_blank"
            rel="noreferrer"
          >
            official docs
          </a>{" "}
          for more information
        </Typography>
        <FormControl
          fullWidth
          margin="dense"
          size="small"
          error={
            touched.earlyStopping?.name && Boolean(errors.earlyStopping?.name)
          }
        >
          <InputLabel>Name</InputLabel>
          <Field
            as={Select}
            name="earlyStopping.name"
            label="Name"
            error={
              touched.earlyStopping?.name && Boolean(errors.earlyStopping?.name)
            }
          >
            <MenuItem value="none">None</MenuItem>
            <MenuItem value="medianstop">Median Stopping Rule</MenuItem>
          </Field>
          <FormHelperText
            margin="dense"
            sx={{ minHeight: "18px", marginTop: "2px" }}
          >
            {touched.earlyStopping?.name && errors.earlyStopping?.name
              ? errors.earlyStopping?.name
              : ""}
          </FormHelperText>
        </FormControl>

        <Stack
          direction="row"
          alignItems="center"
          sx={{ cursor: "pointer", width: 200 }}
          onClick={() => setOpen(!open)}
        >
          <ExpandMoreOutlinedIcon
            sx={{
              transform: open ? "rotate(0)" : "rotate(-90deg)",
              transition: "transform 0.5s",
            }}
          />
          <Typography sx={{ pl: 0.5 }} variant="inherit">
            Algorithm Settings
          </Typography>
        </Stack>
        <Collapse in={open}>
          {values.earlyStopping.name &&
            getAlgorithmSettings(values.earlyStopping.name)}
        </Collapse>
      </Box>
    </>
  );
};

export default EarlyStopping;

import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import { useDispatch } from "react-redux";
// material
import { Paper, Stack, IconButton } from "@mui/material";
//
import {
  PropertyFileStruct,
  ProcessingOutputStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-processing";
import {
  updatePropertyFile,
  deletePropertyFile,
} from "../../../graph/slices/slice";

import {
  PipelineTextfield,
  StyledTextField,
} from "../../components/PipelineComponents";

// ----------------------------------------------------------------------

export default function PropertyFile({
  id,
  data,
  processingOutputs,
}: {
  id: number;
  data: PropertyFileStruct;
  processingOutputs: ProcessingOutputStruct[];
}) {
  const dispatch = useDispatch();
  const [isInitialzied, setInitialized] = useState(false);
  const [inputValues, setInputValues] = useState(data);

  useEffect(() => {
    if (isInitialzied) {
      const timer = setTimeout(() => {
        dispatch(
          updatePropertyFile({
            id: id,
            data: inputValues,
          })
        );
        setInitialized(false);
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [dispatch, id, inputValues, isInitialzied]);

  const ParamSchema = Yup.object().shape({
    outputName: Yup.string().required("Name is required"),
  });

  const formik = useFormik<PropertyFileStruct>({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values, { setErrors }) => {
      try {
        setInitialized(true);
        setInputValues(values);
      } catch (error) {
        // console.log(error);
      }
    },
  });

  const { handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Paper sx={{ p: 1.5, borderRadius: 1.5, bgcolor: "background.neutral" }}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onChange={handleSubmit}>
            <Stack spacing={2}>
              <PipelineTextfield
                label={"Name"}
                field={"name"}
                placeholder={"Variable name"}
                formik={formik}
              />

              <StyledTextField
                select
                label={"Processing output"}
                SelectProps={{ native: true }}
                {...getFieldProps("outputName")}
              >
                {processingOutputs.map((output) => (
                  <option key={output.outputName} value={output.outputName}>
                    {output.outputName}
                  </option>
                ))}
              </StyledTextField>

              <Stack direction="row" spacing={0.5} alignItems="center">
                <PipelineTextfield
                  label={"File name"}
                  field={"path"}
                  placeholder={"File name"}
                  formik={formik}
                />
                <IconButton
                  size="small"
                  onClick={() => {
                    dispatch(deletePropertyFile(id));
                  }}
                >
                  <Icon icon={trash2Fill} width={18} height={18} />
                </IconButton>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </Paper>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import ReactApexChart from "react-apexcharts";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import { Box, Card, Typography, Stack, Grid } from "@mui/material";
import { BaseOptionChart } from "@/components/project/charts";
import { merge } from "lodash";
import { useEffect, useState } from "react";

const IconWrapperStyle = styled("div")(({ theme }) => ({
  width: 9,
  height: 9,
  display: "flex",
  marginRight: "4px",
  borderRadius: "50%",
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.8),
}));

const CHART_HEIGHT = 72;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
  width: 60,
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2.5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    display: "none",
  },
}));

export default function StatusSummary({ statusChartList }) {
  const theme = useTheme();
  const [seriesData, setSeriesData] = useState<number[]>([]);

  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      alpha(theme.palette.success.main, 0.8),
      alpha(theme.palette.warning.main, 0.8),
      alpha(theme.palette.error.main, 0.8),
    ],
    labels: [],
    stroke: { colors: [theme.palette.background.paper], thickness: "1px" },
    legend: { floating: true, horizontalAlign: "center" },
    dataLabels: { enabled: false, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        title: {
          formatter: (seriesName: string) => ``,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  useEffect(() => {
    if (statusChartList.length > 0) {
      const datas: number[] = [];
      // eslint-disable-next-line array-callback-return
      statusChartList?.map((item) => {
        const title = item.name.includes("mlservice_deployment_")
          ? item.name.substring(21)
          : "";
        datas.push(item.result);
        chartOptions.labels.push(title);
      });
      setSeriesData(datas);
    }
  }, [statusChartList]);

  const TOTAL =
    statusChartList.length > 0
      ? statusChartList.reduce((pre, cur) => {
          return cur.result + pre;
        }, 0)
      : 0;

  return (
    <Card
      sx={{ alignItems: "center", width: "33%", px: 3, py: 2, height: "134px" }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Typography variant="subtitle1">Status Summary</Typography>
          <Box>
            <Grid container>
              {statusChartList?.map((item, index) => {
                const title = item.name.includes("mlservice_deployment_")
                  ? item.name.substring(21)
                  : "";
                return (
                  <Grid
                    key={index}
                    item
                    xs={statusChartList.length > 3 ? 6 : 12}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      spacing={0.3}
                      sx={{ mt: 0, mb: 0, mr: 1 }}
                      key={item.id}
                    >
                      <IconWrapperStyle />
                      <Typography component="span" variant="subtitle2">
                        {title}
                      </Typography>
                      <Typography
                        variant="caption"
                        component="span"
                        sx={{ color: "text.secondary" }}
                      >
                        {`(${((item.result / TOTAL) * 100).toFixed(2)}%)`}
                      </Typography>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>

        <ChartWrapperStyle dir="ltr">
          <ReactApexChart
            type="donut"
            series={seriesData}
            options={chartOptions}
            height={90}
          />
        </ChartWrapperStyle>
      </Stack>
    </Card>
  );
}

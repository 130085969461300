import xml2js from "xml2js";
import { DataTypeMap } from "./constants";
import { stepTypeTitles } from "../../pipeline-construction/StepPopover";
import {
  Schema,
  SchemaEdgeEntity,
  SchemaEntityProperty,
  SchemaEntityTag,
  SchemaNodeEntity,
} from "../slices/slice";
import {
  EnvVariable,
  PipelineParameter,
  StepCodeStruct,
  JobArgument,
} from "@/@types/project/mlPipeline/SageMaker/pipeline";
import {
  ProcessingStep,
  ProcessingInputStruct,
  ProcessingOutputStruct,
  ProcessingProcessorStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-processing";
import {
  TrainingStep,
  TrainingInputStruct,
  MetricDefinitionStruct,
  TrainingEstimatorStruct,
  Hyperparameter,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-training";

export const parseFromXml = async (xmlContent: string) => {
  const schema: Schema = {
    name: "",
    tags: [],
    relationships: [],
    // nodeEntities: [],
    edgeEntities: [],
    pipelineRoleArn: "",
    pipelineSteps: [],
  };

  var parser = new xml2js.Parser();
  return parser
    .parseStringPromise(xmlContent)
    .then((xmlDoc) => {
      const name = xmlDoc.Triples.$.name;
      const roleArn = xmlDoc.Triples.$.roleArn;
      const parameters = xmlDoc.Triples.Parameters[0].parameter;
      const entities = xmlDoc.Triples.Entities[0].entity;

      if (name) {
        schema.name = name;
      }

      if (roleArn) {
        schema.pipelineRoleArn = roleArn;
      }

      if (parameters) {
        parameters.forEach((parameterDoc: any) => {
          const parameter: PipelineParameter = {
            Name: parameterDoc.$.Name,
            Type: parameterDoc.$.Type,
            DefaultValue: parameterDoc.$.DefaultValue,
          };

          if (parameter.Type === "Integer") {
            parameter.DefaultValue = Number(parameter.DefaultValue);
          }
          if (parameter.Type === "Boolean") {
            parameter.DefaultValue = Boolean(parameter.DefaultValue);
          }
          if (schema.pipelineParameters === undefined) {
            schema.pipelineParameters = [];
          }
          schema.pipelineParameters?.push(parameter);
        });
      }

      if (entities) {
        entities.forEach((entityDoc: any) => {
          const type = entityDoc.$.label;
          if (type === "Processing") {
            schema.pipelineSteps.push(parseXmlProcessingStep(entityDoc));
          } else if (type === "Training") {
            schema.pipelineSteps.push(parseXmlTrainingStep(entityDoc));
          } else {
            schema.pipelineSteps.push(parseXmlProcessingStep(entityDoc));
          }
        });
      }

      if (xmlDoc.Triples.Relations !== undefined) {
        const relations = xmlDoc.Triples.Relations[0].relation;
        if (relations) {
          relations.forEach((relationDoc: any) => {
            const label = relationDoc.$.label;

            const relationIndex = schema.relationships.findIndex(
              (relationship) => relationship.value === label
            );
            var relationshipId: number = 0;
            if (relationIndex < 0) {
              relationshipId = schema.relationships.length + 1;
              schema.relationships.push({
                id: relationshipId,
                value: label,
                isNew: false,
              });
            } else {
              relationshipId = schema.relationships[relationIndex].id;
            }

            const properties = relationDoc.property;
            const entityProperties = parseXmlProperties(properties);

            const fromEntity = relationDoc.from[0].$.id;
            const toEntity = relationDoc.to[0].$.id;

            const entity: SchemaEdgeEntity = {
              id: relationDoc.$.id,
              relationship: relationshipId,
              properties: entityProperties,
              sourceEntityId: fromEntity,
              sourcePortId: relationDoc.from[0].$.port,
              targetEntityId: toEntity,
              targetPortId: relationDoc.to[0].$.port,
            };
            schema.edgeEntities.push(entity);
          });
        }
      }
      // console.log(schema);
      return schema;
    })
    .catch((error: Error) => {
      // console.log(error);
      throw error;
    });
};

const parseXmlProcessingStep = (entityDoc: any) => {
  const coords = entityDoc.$.coords;
  const label = entityDoc.$.label;
  const stepType = parseInt(entityDoc.$.stepType);
  const stepName = entityDoc.$.name
    ? entityDoc.$.name
    : stepTypeTitles[stepType];
  const entityCoordsList = coords
    .split(", ")
    .map((entry: any) => parseInt(entry));

  const codeProps =
    entityDoc.code !== undefined
      ? parseXmlProcessingCode(entityDoc.code[0])
      : {
          source: "amazon_s3",
          path: "",
          includeDir: false,
          jobArgs: [],
        };

  const processorProperties =
    entityDoc.processor !== undefined
      ? parseXmlProcessor(entityDoc.processor[0])
      : {
          processorType: "SKLearnProcessor",
          processorTypeUseParam: false,
          frameworkVersion: "0.23-1",
          instanceType: "ml.c4.xlarge",
          instanceTypeUseParam: false,
          instanceCount: 1,
          instanceCountUseParam: false,
          volumeSizeInGb: 30,
          volumeSizeInGbUseParam: false,
          environmentVars: [],
        };

  const processingInputs =
    entityDoc.processingInput !== undefined
      ? parseXmlProcessingInput(entityDoc.processingInput)
      : [];

  const processingOutputs =
    entityDoc.processingOutput !== undefined
      ? parseXmlProcessingOutput(entityDoc.processingOutput)
      : [];

  return {
    id: entityDoc.$.id,
    type: "Processing",
    name: stepName,
    processor: processorProperties,
    processingInputs: processingInputs,
    processingOutputs: processingOutputs,
    code: codeProps,
    nodeX: entityCoordsList[0],
    nodeY: entityCoordsList[1],
    tags: [],
    properties: [],
    stepType: label,
  } as ProcessingStep;
};

const parseXmlTrainingStep = (entityDoc: any) => {
  const coords = entityDoc.$.coords;
  const label = entityDoc.$.label;
  const stepType = parseInt(entityDoc.$.stepType);
  const stepName = entityDoc.$.name
    ? entityDoc.$.name
    : stepTypeTitles[stepType];
  const entityCoordsList = coords
    .split(", ")
    .map((entry: any) => parseInt(entry));

  const codeProps =
    entityDoc.code !== undefined
      ? parseXmlProcessingCode(entityDoc.code[0])
      : {
          source: "amazon_s3",
          sourceDir: "",
          entryPoint: "",
        };

  const estimatorProperties =
    entityDoc.estimator !== undefined
      ? parseXmlEstimator(entityDoc.estimator[0])
      : {
          estimatorType: "PyTorch",
          estimatorTypeUseParam: false,
          frameworkVersion: "1.8.1",
          instanceType: "ml.c4.xlarge",
          instanceTypeUseParam: false,
          instanceCount: "1",
          instanceCountUseParam: false,
          volumeSizeInGb: "30",
          volumeSizeInGbUseParam: false,
          environmentVars: [],
          hyperparameters: [],
        };

  const trainingInputs =
    entityDoc.trainingInput !== undefined
      ? parseXmlTrainingInput(entityDoc.trainingInput)
      : [];

  const metricDefinitions =
    entityDoc.metricDefinition !== undefined
      ? parseXmlMetricDefinition(entityDoc.metricDefinition)
      : [];

  return {
    id: entityDoc.$.id,
    type: "Training",
    name: stepName,
    estimator: estimatorProperties,
    trainingInputs: trainingInputs,
    trainingOutput: { s3OutputPath: "", s3OutputPathUseParam: false },
    metricDefinitions: metricDefinitions,
    code: codeProps,
    nodeX: entityCoordsList[0],
    nodeY: entityCoordsList[1],
    tags: [],
    properties: [],
    stepType: label,
  } as TrainingStep;
};

const parseXmlProperties = (properties: any) => {
  const entityProperties: Array<SchemaEntityProperty> = [];
  if (properties) {
    properties.forEach((property: any) => {
      const propertyId = entityProperties.length + 1;
      const typeName = property.$.type;
      const typeId = DataTypeMap.find((type) => type.type === typeName)?.id;
      entityProperties.push({
        id: propertyId,
        title: property.$.name,
        type: typeId ? typeId : 0,
      });
    });
  }
  return entityProperties;
};

const parseXmlProcessingCode = (code: any) => {
  if (code) {
    const jobArgs: Array<JobArgument> = [];
    const jobArguments = code.argument;
    if (jobArguments) {
      jobArguments.forEach((arg: any) => {
        jobArgs.push(arg.$);
      });
    }
    const codeProperties: StepCodeStruct = {
      ...code.$,
      includeDir: JSON.parse(code.$.includeDir) || false,
      jobArgs: jobArgs,
    };
    return codeProperties;
  }
  return undefined;
};

const parseXmlProcessor = (processor: any) => {
  if (processor) {
    const environmentVars: Array<EnvVariable> = [];
    const envVars = processor.environment;
    if (envVars) {
      envVars.forEach((env: any) => {
        environmentVars.push(env.$);
      });
    }

    const processorProperties: ProcessingProcessorStruct = {
      ...processor.$,
      instanceTypeUseParam:
        JSON.parse(processor.$.instanceTypeUseParam) || false,
      instanceCountUseParam:
        JSON.parse(processor.$.instanceCountUseParam) || false,
      volumeSizeInGbUseParam:
        JSON.parse(processor.$.volumeSizeInGbUseParam) || false,
      environmentVars: environmentVars,
    };
    return processorProperties;
  }
  return undefined;
};

const parseXmlProcessingInput = (inputs: any) => {
  const processingInputs: Array<ProcessingInputStruct> = [];
  if (inputs) {
    inputs.forEach((input: any) => {
      processingInputs.push({
        ...input.$,
        sourceUseParam: JSON.parse(input.$.sourceUseParam),
        destinationUseParam: JSON.parse(input.$.destinationUseParam),
      });
    });
  }
  return processingInputs;
};

const parseXmlProcessingOutput = (inputs: any) => {
  const processingOutputs: Array<ProcessingOutputStruct> = [];
  if (inputs) {
    inputs.forEach((input: any) => {
      processingOutputs.push({
        ...input.$,
        sourceUseParam: JSON.parse(input.$.sourceUseParam),
        destinationUseParam: JSON.parse(input.$.destinationUseParam),
      });
    });
  }
  return processingOutputs;
};

const parseXmlEstimator = (estimator: any) => {
  if (estimator) {
    const environmentVars: Array<EnvVariable> = [];
    const envVars = estimator.environment;
    if (envVars) {
      envVars.forEach((env: any) => {
        environmentVars.push(env.$);
      });
    }

    const hyperparameters: Array<Hyperparameter> = [];
    const hps = estimator.hyperparameter;
    if (hps) {
      hps.forEach((hp: any) => {
        hyperparameters.push({
          ...hp.$,
          useParam: JSON.parse(hp.$.useParam) || false,
        });
      });
    }

    const estimatorProperties: TrainingEstimatorStruct = {
      ...estimator.$,
      instanceTypeUseParam:
        JSON.parse(estimator.$.instanceTypeUseParam) || false,
      instanceCountUseParam:
        JSON.parse(estimator.$.instanceCountUseParam) || false,
      volumeSizeInGbUseParam:
        JSON.parse(estimator.$.volumeSizeInGbUseParam) || false,
      environmentVars: environmentVars,
      hyperparameters: hyperparameters,
    };
    return estimatorProperties;
  }
  return undefined;
};

const parseXmlTrainingInput = (inputs: any) => {
  const trainingInputs: Array<TrainingInputStruct> = [];
  if (inputs) {
    inputs.forEach((input: any) => {
      trainingInputs.push({
        ...input.$,
        s3DataUseParam: JSON.parse(input.$.s3DataUseParam),
      });
    });
  }
  return trainingInputs;
};

const parseXmlMetricDefinition = (inputs: any) => {
  const metricDefs: Array<MetricDefinitionStruct> = [];
  if (inputs) {
    inputs.forEach((input: any) => {
      metricDefs.push(input.$);
    });
  }
  return metricDefs;
};

export const parseToXml = (schema: Schema) => {
  const entities = schema.pipelineSteps.map((entity) => {
    const labels: Array<string> = [];
    const labelColors: Array<string> = [];
    entity.tags.forEach((currentTag) => {
      const foundedTag = schema.tags.find((tag) => tag.id === currentTag);
      if (foundedTag) {
        labels.push(foundedTag.tag);
        labelColors.push(foundedTag.color);
      }
    });

    const properties = entity.properties.map((property) => {
      const propertyType = dataType(property.type);
      return {
        $: { name: property.title, type: propertyType },
      };
    });

    return {
      $: {
        id: entity.id,
        label: labels.join(","),
        color: labelColors.join(","),
        coords: `${entity.nodeX}, ${entity.nodeY}`,
      },
      property: properties,
    };
  });

  const relations = schema.edgeEntities.map((entity) => {
    const foundedTag = schema.relationships.find(
      (relationship) => relationship.id === entity.relationship
    );
    const label = foundedTag?.value;

    const fromEntityLabel = entityLabel(
      entity.sourceEntityId,
      schema.pipelineSteps,
      schema.tags
    );
    const toEntityLabel = entityLabel(
      entity.targetEntityId,
      schema.pipelineSteps,
      schema.tags
    );

    const properties = entity.properties.map((property) => {
      const propertyType = dataType(property.type);
      return {
        $: { name: property.title, type: propertyType },
      };
    });

    return {
      $: { id: entity.id, label: label },
      from: {
        $: {
          entity: fromEntityLabel,
          id: entity.sourceEntityId,
          port: entity.sourcePortId,
        },
      },
      to: {
        $: {
          entity: toEntityLabel,
          id: entity.targetEntityId,
          port: entity.targetPortId,
        },
      },
      property: properties,
    };
  });

  const result = {
    Triples: {
      Entities: { entity: entities },
      Relations: { relation: relations },
    },
  };

  var builder = new xml2js.Builder();
  return builder.buildObject(result);
};

const dataType = (id: number) => {
  const entry = DataTypeMap.find((entry) => entry.id === id);
  if (entry) {
    return entry.type;
  } else {
    return "undefined";
  }
};

const entityLabel = (
  entityId: string,
  nodeEntities: Array<SchemaNodeEntity>,
  tags: Array<SchemaEntityTag>
) => {
  const entity = nodeEntities.find((node) => node.id === entityId);
  if (entity && entity.tags.length > 0) {
    const firstTag = tags.find((tag) => tag.id === entity.tags[0]);
    if (firstTag) {
      return firstTag.tag;
    }
  }
  return undefined;
};

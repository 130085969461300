import { Schema } from "../../slices/slice";
import {
  parseFailStep,
  parseModelStep,
  parseTuningStep,
  parseLambdaStep,
  parseTrainingStep,
  parseConditionStep,
  parseTransformStep,
  parseProcessingStep,
  parseClarifyCheckStep,
  parseRegisterModelStep,
} from "./";

export default async function parseStep(
  schema: Schema,
  step: any,
  stepId: number,
  stepPairs: [string, string, string][]
) {
  if (step.Type === "Processing") {
    schema.pipelineSteps.push(parseProcessingStep(stepId, step, stepPairs));
  } else if (step.Type === "Training") {
    schema.pipelineSteps.push(parseTrainingStep(stepId, step, stepPairs));
  } else if (step.Type === "RegisterModel") {
    schema.pipelineSteps.push(parseRegisterModelStep(stepId, step, stepPairs));
  } else if (step.Type === "Condition") {
    schema.pipelineSteps.push(
      await parseConditionStep(stepId, step, stepPairs, schema)
    );
  } else if (step.Type === "Tuning") {
    schema.pipelineSteps.push(parseTuningStep(stepId, step, stepPairs));
  } else if (step.Type === "Model") {
    schema.pipelineSteps.push(parseModelStep(stepId, step, stepPairs));
  } else if (step.Type === "Transform") {
    schema.pipelineSteps.push(parseTransformStep(stepId, step, stepPairs));
  } else if (step.Type === "Lambda") {
    schema.pipelineSteps.push(parseLambdaStep(stepId, step, stepPairs));
  } else if (step.Type === "Fail") {
    schema.pipelineSteps.push(parseFailStep(stepId, step, stepPairs));
  } else if (step.Type === "ClarifyCheck") {
    schema.pipelineSteps.push(
      await parseClarifyCheckStep(stepId, step, stepPairs)
    );
  }

  if (step.DependsOn) {
    step.DependsOn.forEach((item: string) =>
      addStepPairs(stepPairs, item, step.Name, "")
    );
  }
}

export function addStepPairs(
  stepPairs: [string, string, string][],
  srcStep: string,
  targetStep: string,
  connectionProp: string
) {
  const isPairExisted =
    stepPairs.find(
      ([start, end]) => start === srcStep && end === targetStep
    ) !== undefined;

  if (!isPairExisted) stepPairs.push([srcStep, targetStep, connectionProp]);
}

export const parseProcessor = (imageName: string) => {
  if (imageName.includes("pytorch")) return "PyTorch";
  else if (imageName.includes("xgboost")) return "XGBoost";
  else if (imageName.includes("tensorflow")) return "TensorFlow";
  return "SKLearn";
};

export const parseFramework = (imageName: string) => {
  return parseUri(imageName);
  // if (imageName.includes("pytorch")) return "PyTorch";
  // else if (imageName.includes("xgboost")) return "XGBoost";
  // else if (imageName.includes("tensorflow")) return "TensorFlow";
  // return "SKLearn";
};

export const parseFrameworkVersion = (imageName: string) => {
  const items = imageName.split(":");
  if (items.length <= 1) return "";
  const versionItems = items[1].split("-");
  return `${versionItems[0]}.${versionItems[1]}`;
};

export const parseS3Prefix = (s3Path: string) => {
  if (typeof s3Path === "object") {
    return parseUri(s3Path);
  } else {
    const items = s3Path.split("/");
    const prefix = items.slice(3, items.length).join("/");
    return prefix;
  }
};

export const parseS3BucketAndPrefix = (s3Path: string) => {
  if (s3Path.toLowerCase().startsWith("s3://")) s3Path = s3Path.slice(5);

  const items = s3Path.split("/");
  const bucket = items[0];
  const prefix = items.slice(1, items.length).join("/");
  return [bucket, prefix];
};

export const parseUri = (
  s3Uri: any,
  stepName?: string,
  stepPairs?: [string, string, string][],
  inputVariableName?: string
) => {
  if (s3Uri === null || s3Uri === undefined) {
    return "";
  } else {
    if (typeof s3Uri === "object") {
      if (Object.keys(s3Uri)[0] === "Std:Join") {
        return formStdJoinValue(
          s3Uri["Std:Join"],
          stepName,
          stepPairs,
          inputVariableName
        );
      } else if (Object.keys(s3Uri)[0] === "Std:JsonGet") {
        // console.log(s3Uri);
        return formStdJsonGetValue(
          s3Uri["Std:JsonGet"],
          stepName,
          stepPairs,
          inputVariableName
        );
      } else {
        return reformUriAndAddStepPair(
          s3Uri,
          stepName,
          stepPairs,
          inputVariableName
        );
      }
    } else {
      return s3Uri;
    }
  }
};

function formStdJoinValue(
  input: any,
  stepName?: string,
  stepPairs?: [string, string, string][],
  inputVariableName?: string
) {
  if (input.Values.length > 1) {
    const connector = input.On;
    const reformed = input.Values.map((val: any) => {
      if (typeof val === "object") {
        reformUriAndAddStepPair(val, stepName, stepPairs, inputVariableName);
        return `{${val.Get}}`.replace("{", "${");
      } else return val;
    });
    return reformed.join(connector);
  } else {
    return input.Values[0].Get.split(".")[1];
  }
}

function formStdJsonGetValue(
  input: any,
  stepName?: string,
  stepPairs?: [string, string, string][],
  inputVariableName?: string
) {
  if (input.PropertyFile) {
    let reformedS3Uri = "";
    if (Object.keys(input.PropertyFile)[0] === "Get") {
      const s3UriItems = input.PropertyFile.Get.split(".");
      reformedS3Uri = s3UriItems.slice(1, s3UriItems.length).join(".");
      if (input.Path.length > 0)
        reformedS3Uri = `\${${reformedS3Uri}}/${input.Path}`;
      if (stepPairs && stepName && inputVariableName) {
        addStepPairs(
          stepPairs,
          s3UriItems[1],
          stepName,
          reformedS3Uri.replace(`${s3UriItems[1]}.`, "") +
            "&&" +
            inputVariableName
        );
      }
    } else {
      reformedS3Uri = `${input.PropertyFile}/${input.Path}`;
    }
    // console.log(reformedS3Uri);
    return reformedS3Uri;
  } else {
    return "";
  }
}

export function checkUseParam(input: any) {
  if (input !== null && typeof input === "object") {
    if (Object.keys(input)[0] === "Get") {
      return true;
    } else if (Object.keys(input)[0] === "Std:Join") {
      if (input["Std:Join"].Values.length === 1) {
        if (Object.keys(input["Std:Join"].Values[0])[0] === "Get") {
          return true;
        }
        return false;
      }
      return false;
    } else if (Object.keys(input)[0] === "Std:JsonGet") {
      if (input["Std:JsonGet"].PropertyFile) {
        if (Object.keys(input["Std:JsonGet"].PropertyFile)[0] === "Get") {
          return false;
          // return true;
        }
        return false;
      }
      return false;
    }
  }
  return false;
}

const reformUriAndAddStepPair = (
  s3Uri: any,
  stepName?: string,
  stepPairs?: [string, string, string][],
  inputVariableName?: string
) => {
  const s3UriItems = s3Uri.Get.split(".");
  let reformedS3Uri = "";

  if (s3UriItems[0] === "Steps") {
    // Dependent steps
    if (s3UriItems[2].includes("ProcessingOutputConfig")) {
      // Processing
      reformedS3Uri = `${s3UriItems[1]}.${s3UriItems[2].replace(
        "Config",
        ""
      )}s.${s3UriItems[3].split("'")[1]}`;
    } else if (s3UriItems[2].includes("ModelArtifacts")) {
      // Training
      reformedS3Uri = `${s3UriItems[1]}.ModelArtifacts.S3ModelArtifacts`;
    } else if (s3UriItems[2].includes("TrainingJobSummaries")) {
      // Tuning
      reformedS3Uri = `${s3UriItems[1]}.${s3UriItems[2]}.TrainingJobName`;
    } else if (s3UriItems[2].includes("ModelName")) {
      // Tranform
      reformedS3Uri = `${s3UriItems[1]}.${s3UriItems[2]}`;
    } else if (s3UriItems[2].includes("ModelPackageArn")) {
      // RegisterMoel
      reformedS3Uri = `${s3UriItems[1]}.${s3UriItems[2]}`;
    } else if (s3UriItems[2].includes("CalculatedBaselineConstraints")) {
      // Clarify Check
      reformedS3Uri = `${s3UriItems[1]}.${s3UriItems[2]}`;
    } else if (s3UriItems[2].includes("BaselineUsedForDriftCheckConstraints")) {
      // Clarify Check
      reformedS3Uri = `${s3UriItems[1]}.${s3UriItems[2]}`;
    }
    if (stepPairs && stepName && inputVariableName) {
      addStepPairs(
        stepPairs,
        s3UriItems[1],
        stepName,
        reformedS3Uri.replace(`${s3UriItems[1]}.`, "") +
          "&&" +
          inputVariableName
      );
    }
  } else {
    // Pipeline parameters
    reformedS3Uri = s3UriItems[1];
  }

  return reformedS3Uri;
};

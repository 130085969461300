import { Icon } from "@iconify/react";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useState } from "react";
// material
import { Box, Stack, Collapse, IconButton } from "@mui/material";
//
import EmptyTable from "@/components/project/EmptyTable";
import { CollapseButtonStyle } from "../../../../SettingSidebar";
import TrainingOutputsItem from "./partComponents/TrainingOutputsItem";
import { TrainingStep } from "@/@types/project/mlPipeline/Kubeflow/pipeline-training";
import plusFill from "@iconify/icons-eva/plus-fill";
import { dispatch } from "@/redux/store";
import { handleKubeflowStepArray } from "@/pages/project/automation/MlPipeline/graph/slices/slice";

// ----------------------------------------------------------------------

type Props = {
  currentStep: TrainingStep | null;
  isEdit: boolean;
};

export default function TrainingOutputs({ currentStep, isEdit }: Props) {
  const [showTrainingOutput, setShowTrainingOutput] = useState(false);

  const trainingOutputs = currentStep?.outputs || [];

  const handleAddTrainingOutput = () => {
    if (!showTrainingOutput) {
      setShowTrainingOutput(true);
    }

    dispatch(
      handleKubeflowStepArray({
        handleType: "create",
        data: {
          name: `TrainingOutput-${trainingOutputs.length + 1}`,
          dataType: "",
          description: "",
          paramType: 2,
        },
        key: "outputs",
      })
    );
  };

  return (
    <>
      <Box sx={{ mb: 0, position: "relative" }}>
        <CollapseButtonStyle
          fullWidth
          color="inherit"
          onClick={() => setShowTrainingOutput((prev) => !prev)}
          endIcon={
            <Icon
              icon={
                showTrainingOutput ? arrowIosDownwardFill : arrowIosForwardFill
              }
              width={16}
              height={16}
            />
          }
        >
          Outputs {trainingOutputs.length > 0 && `(${trainingOutputs.length})`}
        </CollapseButtonStyle>

        <IconButton
          sx={{ zIndex: 1999, position: "absolute", top: 7, right: 30 }}
          disabled={isEdit}
          size="small"
          onClick={handleAddTrainingOutput}
        >
          <Icon icon={plusFill} width={16} height={16} />
        </IconButton>
      </Box>

      <Collapse in={showTrainingOutput}>
        <Stack
          spacing={1.5}
          sx={{ mx: 2, mb: 1.5, mt: showTrainingOutput ? 0.5 : 0 }}
        >
          {trainingOutputs.length > 0 &&
            trainingOutputs.map((trainingOutput, index) => (
              <TrainingOutputsItem
                length={trainingOutputs.length}
                isEdit={isEdit}
                key={index}
                id={index}
                data={trainingOutput}
              />
            ))}
        </Stack>
      </Collapse>

      {showTrainingOutput && trainingOutputs.length === 0 && (
        <EmptyTable
          emptyImage={false}
          mainTitle={"No training outputs"}
          content={'Create one by clicking "+" button'}
          color={"text.disabled"}
          sx={{ backgroundColor: "transparent", mx: 2, mt: 5, mb: 7 }}
        />
      )}
    </>
  );
}

import { IconButton, Stack, StandardTextFieldProps } from "@mui/material";
import { InputAdornment, TextField, Typography } from "@mui/material";
import { Field } from "formik";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { get } from "lodash";
import Decimal from "decimal.js";
import { getNumberStep } from "@/utils/project/utils";

interface IProps extends StandardTextFieldProps {
  name: string;
  label: string;
  unit?: string;
  step?: number;
  formik: any;
  noValidate?: boolean;
  hideEndAdornment?: boolean;
  isDouble?: boolean;
}

const MlNumberInputField = ({
  name,
  label,
  unit = "",
  step = 1,
  formik,
  noValidate = false,
  hideEndAdornment = false,
  isDouble = false,
  ...other
}: IProps) => {
  const { values, errors, touched, setFieldValue, setFieldTouched } = formik;

  const handleNumberInputChange = async (key, value) => {
    let cleanedValue = value;

    if (cleanedValue.match(/^0+/)) {
      cleanedValue = cleanedValue.replace(/^0+/, "");
      if (cleanedValue === "") {
        cleanedValue = "0";
      }
    }

    cleanedValue = cleanedValue.replace(/[^0-9]/g, "");

    await setFieldValue(key, cleanedValue);
    setFieldTouched(key, true);
  };

  const handleDoubleInputChange = async (key, value) => {
    let cleanedValue = value;

    // without number and dot
    cleanedValue = cleanedValue.replace(/[^0-9.]/g, "");

    // remove the head zero
    if (cleanedValue.match(/^0+[1-9]/)) {
      cleanedValue = cleanedValue.replace(/^0+/, "");
    }
    // add zero before dot
    else if (cleanedValue.match(/^\./)) {
      cleanedValue = "0" + cleanedValue;
    }
    // support 0.01, 0.001
    else if (cleanedValue.match(/^0+\.[0-9]*[1-9]/)) {
      // do nothing
    }
    // multiple zero to reserve one zero
    else if (cleanedValue.match(/^0+$/)) {
      cleanedValue = "0";
    }
    // multiple zero with dot, reserve one zero
    else {
      const match = cleanedValue.match(/^0+(\d+\.?\d*)/);
      if (match) {
        cleanedValue = match[1];
      }
    }

    // restrict one dot
    const parts = cleanedValue.split(".");
    if (parts.length > 2) {
      cleanedValue = `${parts[0]}.${parts.slice(1).join("")}`;
    }

    await setFieldValue(key, cleanedValue);
    setFieldTouched(key, true);
  };

  const getNumberInputEnd = (key, value) => {
    const disabled =
      value === null || value === undefined || (value === "" && value !== 0);

    const valueNum = new Decimal(Number(value));
    const stepNum = new Decimal(Number(getNumberStep(value)));

    const addedValue = String(valueNum.plus(stepNum));

    const minusedValue = String(valueNum.minus(stepNum));

    return (
      <InputAdornment position="end">
        <Typography variant="body2" sx={{ mr: 1 }}>
          {unit}
        </Typography>
        <Stack sx={{ py: "2px" }}>
          <IconButton
            disabled={disabled}
            size="small"
            sx={{ height: "18px", width: "18px" }}
            onClick={async () => {
              await setFieldValue(key, addedValue);
              setFieldTouched(key, true);
            }}
          >
            <ArrowDropUpIcon />
          </IconButton>
          <IconButton
            disabled={disabled || Number(value) === 0}
            size="small"
            sx={{ height: "18px", width: "18px" }}
            onClick={async () => {
              await setFieldValue(key, minusedValue);
              setFieldTouched(key, true);
            }}
          >
            <ArrowDropDownIcon />
          </IconButton>
        </Stack>
      </InputAdornment>
    );
  };

  return (
    <Field
      as={TextField}
      name={name}
      label={label}
      onChange={(e) => {
        if (isDouble) {
          handleDoubleInputChange(name, e.target.value);
        } else {
          handleNumberInputChange(name, e.target.value);
        }
      }}
      {...(!hideEndAdornment
        ? {
            InputProps: {
              endAdornment: getNumberInputEnd(name, get(values, name)), // access nested properties
            },
          }
        : {})}
      size="small"
      fullWidth
      margin="dense"
      {...(!noValidate
        ? {
            helperText: (get(touched, name) && get(errors, name)) || " ",
            error: get(touched, name) && Boolean(get(errors, name)),
            FormHelperTextProps: {
              sx: { minHeight: "18px", marginTop: "2px" },
            },
          }
        : {})}
      {...other}
    />
  );
};

export default MlNumberInputField;

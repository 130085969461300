import Page from "@/components/project/Page";
import { PATH_DASHBOARD } from "@/routes/paths";
import { useEffect, useState } from "react";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { Box } from "@mui/system";
import { Button, Menu, MenuItem } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSnackbar } from "notistack";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import MlCard from "@/components/project/mlComponents/MlCard";
import MlCardList from "@/components/project/mlComponents/MlCardList";
import { RootState, dispatch } from "@/redux/store";
import {
  delPipeline,
  getPipelineDetailData,
  getPipelineTags,
  getPipelineVolumeDetailData,
  getRelPipelineVolumeData,
} from "@/redux/project/automation/thunks";
import { useSelector } from "react-redux";
import PipelineVersionTable from "./components/PipelineDetail/PipelineVersionTable";
import {
  getPipelineTemplateDetail,
  getPipelineTemplateVersionData,
} from "@/redux/project/template/thunks";
import { getTagDetail } from "@/redux/project/administration/thunks";
import { Stack } from "@mui/material";
import Label from "@/components/project/Label";
import { isEmpty } from "lodash";

const PipelineDetail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const { mlPipelineId } = useParams();
  const navigate = useNavigate();

  const {
    pipelineDetail: { data, loading },
  } = useSelector((state: RootState) => state.automation);

  const [volumeData, setVolumeData] = useState<{
    value: string;
    loading: boolean;
  }>({ value: "", loading: false });

  const [templateData, setTemplateData] = useState<{
    value: string;
    loading: boolean;
  }>({ value: "", loading: false });

  const [tagsData, setTagsData] = useState<{
    value: string[];
    loading: boolean;
  }>({ value: [], loading: false });

  // delete confirm
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLinks = () => {
    return [
      {
        name: "Automation",
      },
      {
        name: "Pipeline",
        href: PATH_DASHBOARD.automation.mlPipeline,
      },
      {
        name: data?.name,
      },
    ];
  };

  const getVolume = () => {
    try {
      setVolumeData({
        ...volumeData,
        loading: true,
      });
      // get rel_mlpipeline_volume
      dispatch(
        getRelPipelineVolumeData({
          params: {
            mlpipeline_id: mlPipelineId!,
            page_num: 1,
            page_size: 9999,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((res) => {
          // get volume name
          dispatch(
            getPipelineVolumeDetailData({
              id: res?.items?.[0]?.mlpipeline_volume_id,
              alertCallback: enqueueSnackbar,
              pathname,
            })
          )
            .unwrap()
            .then((r) => {
              setVolumeData({
                value: r?.name,
                loading: false,
              });
            });
        });
    } catch (e) {
      setVolumeData({
        value: "",
        loading: false,
      });
    }
  };

  const getTemplate = (templateVersionId) => {
    try {
      setTemplateData({
        ...templateData,
        loading: true,
      });
      dispatch(
        getPipelineTemplateVersionData({
          params: {
            id: templateVersionId,
            page_num: 1,
            page_size: 9999,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((res) => {
          // get template name
          dispatch(
            getPipelineTemplateDetail({
              id: res?.items?.[0]?.mlpipeline_template_id,
              alertCallback: enqueueSnackbar,
              pathname,
            })
          )
            .unwrap()
            .then((r) => {
              setTemplateData({
                value: r?.name,
                loading: false,
              });
            });
        });
    } catch (e) {
      setTemplateData({
        value: "",
        loading: false,
      });
    }
  };

  const getTags = () => {
    try {
      setTagsData({
        ...tagsData,
        loading: false,
      });
      dispatch(
        getPipelineTags({
          params: {
            mlpipeline_id: mlPipelineId!,
            page_num: 1,
            page_size: 9999,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((res) => {
          Promise.all(
            res.items.map((item) =>
              dispatch(
                getTagDetail({
                  id: item.tag_id,
                  alertCallback: enqueueSnackbar,
                  pathname,
                })
              ).unwrap()
            )
          ).then((r) => {
            setTagsData({
              value: r?.map((i) => i.name) || [],
              loading: false,
            });
          });
        });
    } catch (e) {
      setTagsData({
        value: [],
        loading: false,
      });
    }
  };

  const pipelineBaseInfoList = () => {
    return [
      { subTitle: "Name", label: data?.name || "-", loading: loading },
      {
        subTitle: "Type",
        label: data?.mlpipeline_type || "-",
        loading: loading,
      },
      {
        subTitle: "Volume",
        label: volumeData?.value || "-",
        loading: volumeData?.loading,
      },
      { subTitle: "Scenario", label: data?.scenario || "-", loading: loading },
      {
        subTitle: "Template",
        label: templateData?.value || "-",
        loading: templateData?.loading,
      },
      {
        subTitle: "Tags",
        label: !isEmpty(tagsData?.value) ? (
          <Stack direction="row" spacing={1}>
            {tagsData.value.map((i) => (
              <Label color="default" sx={{ width: "80px" }}>
                {i}
              </Label>
            ))}
          </Stack>
        ) : (
          "-"
        ),
        loading: tagsData?.loading,
      },
      {
        subTitle: "Description",
        label: data?.description || "-",
        loading: false,
      },
    ];
  };

  const getExtraBtn = () => {
    return (
      <Box sx={{ width: "120px" }}>
        <Button
          onClick={handleClick}
          variant="outlined"
          endIcon={
            <ExpandMoreOutlinedIcon
              sx={{
                transform: open ? "rotate(0)" : "rotate(90deg)",
                transition: "transform 0.5s",
              }}
            />
          }
          color="secondary"
          fullWidth
        >
          Actions
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "120px",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              navigate("edit");
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              setOpenConfirm(true);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </Box>
    );
  };

  const handleDelPipeline = async () => {
    setDelLoading(true);
    try {
      await dispatch(
        delPipeline({
          id: mlPipelineId!,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      setDelLoading(false);
      setOpenConfirm(false);
      enqueueSnackbar("Delete Success", { variant: "success" });

      navigate(PATH_DASHBOARD.automation.mlPipeline);
    } catch (e) {
      setDelLoading(false);
    }
  };

  useEffect(() => {
    dispatch(
      getPipelineDetailData({
        id: mlPipelineId!,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    )
      .unwrap()
      .then((res) => {
        getTemplate(res.mlpipeline_template_version_id);
      });
  }, []);

  useEffect(() => {
    getVolume();
    getTags();
  }, []);

  return (
    <Page title={data?.name} heading={data?.name} links={getLinks()}>
      <MlCard isCanFold title="Basic Information" extraJsx={getExtraBtn()}>
        <MlCardList list={pipelineBaseInfoList()} />
      </MlCard>

      <PipelineVersionTable />

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete pipeline <strong>{data.name}</strong> item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelPipeline();
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </Page>
  );
};

export default PipelineDetail;

import { Icon } from "@iconify/react";
import refreshFill from "@iconify/icons-eva/refresh-fill";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// material
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
// hooks
import useLocales from "@/hooks/project/useLocales";
import useResponsive from "@/hooks/project/useResponsive";
// components
import MobileDateTimePicker from "@mui/lab/MobileDateTimePicker";

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(0),
}));

type Props = {
  buttonLabel: string;
  startTime: Date | null;
  endTime: Date | null;
  btnDisabled?: boolean;
  onButtonClick: VoidFunction;
  onChangeStartTime: (newValue: Date | null) => void;
  onChangeEndTime: (newValue: Date | null) => void;
};

export default function LoggerTimeRange({
  buttonLabel,
  startTime,
  endTime,
  btnDisabled = false,
  onButtonClick,
  onChangeStartTime,
  onChangeEndTime,
}: Props) {
  const theme = useTheme();
  const smUp = useResponsive("up", "sm");
  const smDown = useResponsive("down", "sm");
  const { translate } = useLocales();

  return (
    <Box sx={{ width: "100%", mb: 2, mt: 2 }}>
      <LabelStyle>{translate("set_time_range")}</LabelStyle>

      {/* Mobile */}
      {smDown && (
        <Grid container spacing={2} sx={{ mt: 1.5 }}>
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              spacing={0.5}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <MobileDateTimePicker
                label={translate("start_time")}
                value={startTime}
                inputFormat="dd/MM/yyyy hh:mm a"
                onChange={(newValue) => {
                  onChangeStartTime(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />

              <ArrowForwardIcon sx={{ color: theme.palette.primary.main }} />

              <MobileDateTimePicker
                label={translate("end_time")}
                value={endTime}
                inputFormat="dd/MM/yyyy hh:mm a"
                onChange={(newValue) => {
                  onChangeEndTime(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>

            <Button
              fullWidth
              disabled={btnDisabled}
              size="large"
              variant="contained"
              startIcon={<Icon icon={refreshFill} />}
              sx={{ boxShadow: "none", mt: 2.5 }}
              onClick={onButtonClick}
            >
              {buttonLabel}
            </Button>
          </Grid>
        </Grid>
      )}

      {/* Desktop */}
      {smUp && (
        <Grid container spacing={2} sx={{ mt: 1.5 }}>
          <Grid item xs={12} md={12} lg={12}>
            <Stack
              direction={"row"}
              spacing={1}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <MobileDateTimePicker
                label={translate("start_time")}
                value={startTime}
                inputFormat="dd/MM/yyyy hh:mm a"
                onChange={(newValue) => {
                  onChangeStartTime(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} className="metricTimePicker" />
                )}
              />

              <ArrowForwardIcon
                sx={{
                  color: theme.palette.primary.main,
                  m: { xs: 12, sm: 12 },
                }}
              />

              <MobileDateTimePicker
                label={translate("end_time")}
                value={endTime}
                inputFormat="dd/MM/yyyy hh:mm a"
                onChange={(newValue) => {
                  onChangeEndTime(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} className="metricTimePicker" />
                )}
              />

              <Button
                size="medium"
                variant="contained"
                disabled={btnDisabled}
                startIcon={<Icon icon={refreshFill} />}
                sx={{ boxShadow: "none" }}
                onClick={onButtonClick}
                color="secondary"
              >
                {buttonLabel}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
// material
import {
  Box,
  TextField,
  Stack,
  Paper,
  Select,
  Divider,
  MenuItem,
  InputBase,
  DialogActions,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
// hooks
import { useTheme } from "@mui/material/styles";
import useLocales from "@/hooks/project/useLocales";
import axios from "@/utils/project/axiosForPython";
import Scrollbar from "@/components/project/Scrollbar";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { RootState, useSelector } from "@/redux/store";

// ----------------------------------------------------------------------

const ConditionTypes = [
  { code: "A", label: "POST", value: "post" },
  // { code: "B", label: "GET", value: "get" },
  // { code: "C", label: "PUT", value: "put" },
];

type TesterResponseStruct = {
  prediction: {
    pred: string;
    time: string;
    size: string;
    statusCode: number;
  };
  body: string;
};

type Props = {
  id: string;
  curDeployment: Record<string, any>;
  loadingResponse: boolean;
  dataType?: string;
  onHandleReponse: Function;
  onSetLoadingResponse: React.Dispatch<React.SetStateAction<boolean>>;
  onSetResponses: React.Dispatch<React.SetStateAction<TesterResponseStruct[]>>;
};

export default function TesterRequestApiCall({
  curDeployment,
  loadingResponse,
  onSetResponses,
  onHandleReponse,
  onSetLoadingResponse,
}: Props) {
  const theme = useTheme();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const [curConditionType, setCurConditionType] = useState("post");
  const {
    userDetail: { project_id },
    config: { common = {} },
  } = useSelector((state: RootState) => state.common);
  const [apiConfig, setApiConfig] = useState({
    url: "" as string,
    params: "" as string,
  });
  const { BASE_URL } = common;

  const sendApiRequest = async () => {
    try {
      onSetLoadingResponse(true);

      const startTime = new Date().toISOString();
      const response = await axios.post(
        apiConfig.url,
        JSON.parse(apiConfig.params)
      );
      // console.log(response);
      onHandleReponse(response, startTime);
    } catch (error) {
      const err = error as Error;
      enqueueSnackbar(err.message, { variant: "error" });
      onSetResponses([]);
      onSetLoadingResponse(false);
    }
  };

  useEffect(() => {
    setApiConfig({
      url: curDeployment
        ? `${BASE_URL}/seldon/taichi-prj-${project_id}/${curDeployment?.name}/`
        : "",
      params: JSON.stringify(curDeployment?.input_payload),
    });
  }, [curDeployment]);

  const handleCopy = () => {
    navigator.clipboard.writeText(apiConfig.url);
    enqueueSnackbar("Copy success", { variant: "success" });
  };

  return (
    <>
      <Stack sx={{ flexGrow: 1 }} spacing={1.5}>
        <Scrollbar sx={{ height: "calc(64vh - 216px)" }}>
          <Box
            sx={{
              px: 2.5,
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Paper
                sx={{
                  mb: 1,
                  mr: 1,
                  p: "2px 4px",
                  width: "95%",
                  display: "flex",
                  alignItems: "center",
                  border: `solid 1px ${theme.palette.divider}`,
                  backgroundColor: theme.palette.background.neutral,
                }}
              >
                <Select
                  size="small"
                  value={curConditionType}
                  onChange={(event) => setCurConditionType(event.target.value)}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  sx={{
                    border: 0,
                    width: "95px",
                    color: theme.palette.text.secondary,
                    typography: theme.typography.overline,
                    "& .MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                >
                  {ConditionTypes.map((option) => (
                    <MenuItem
                      key={option.code}
                      value={option.value}
                      sx={{
                        mx: 1,
                        my: 0,
                        borderRadius: 0.75,
                        typography: "body2",
                        textTransform: "capitalize",
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <Divider sx={{ height: 30, m: 0.5 }} orientation="vertical" />
                <InputBase
                  sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                  placeholder={translate("enter_request_url")}
                  value={apiConfig.url}
                  onChange={(event) => {
                    setApiConfig({
                      ...apiConfig,
                      url: event.target.value,
                    });
                  }}
                />
              </Paper>
              <IconButton color="secondary" onClick={handleCopy} sx={{ mb: 1 }}>
                <ContentCopyRoundedIcon
                  sx={{ width: 22, height: 22, borderRadius: 4 }}
                />
              </IconButton>
            </Stack>

            <TextField
              multiline
              rows={6}
              name="payload"
              label="Payload"
              value={apiConfig.params}
              onChange={(event) => {
                setApiConfig({
                  ...apiConfig,
                  params: event.target.value,
                });
              }}
              fullWidth
              margin="dense"
            />
          </Box>
        </Scrollbar>

        {apiConfig.url !== "" && (
          <Stack
            sx={{ position: "absolute", bottom: 0, right: 0, width: "100%" }}
            spacing={1.5}
          >
            <Divider />

            <DialogActions>
              <LoadingButton
                type="submit"
                disabled={apiConfig.url === ""}
                variant="contained"
                loading={loadingResponse}
                sx={{
                  mt: -0.6,
                  mb: 1,
                  mr: 1,
                }}
                onClick={sendApiRequest}
              >
                {translate("send")}
              </LoadingButton>
            </DialogActions>
          </Stack>
        )}
      </Stack>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import Page from "@/components/project/Page";
import { PATH_DASHBOARD } from "@/routes/paths";
import { useLocation, useParams } from "react-router";
import { dispatch, useSelector, RootState } from "@/redux/store";
import ModelPackageCreateOrEditForm from "./components/modelPackageCreateOrEdit/ModelPackageCreateOrEditForm";
import {
  getModelPackageDescribe,
  getTagList,
  getModelPackageList,
} from "@/redux/project/dataAsset/modelRegistry/thunks";
import { useSnackbar } from "notistack";

const ModelPackageCreateOrEdit = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { modelId = "" } = useParams();
  const isEdit = pathname.includes("modelPackageEdit");
  const {
    modelPackageDescribeDatas: { data: modelPackageDescribe },
  } = useSelector((state: RootState) => state.modelRegistry);
  const {
    userDetail: { id, user_id },
  } = useSelector((state: RootState) => state.common);

  const getLinks = () => {
    return [
      {
        name: "Data Asset",
      },
      {
        name: "Model Package",
        href: PATH_DASHBOARD.dataAsset.modelRegistry,
      },
      ...(isEdit
        ? [
            {
              name: modelPackageDescribe?.name || "",
              href: `${PATH_DASHBOARD.dataAsset.modelRegistry}/${modelPackageDescribe?.id}`,
            },
          ]
        : []),
      {
        name: `${isEdit ? "Edit" : "Create"} Model Package` || "",
      },
    ];
  };

  useEffect(() => {
    // get model package、tags
    if (isEdit) {
      dispatch(
        getModelPackageDescribe({
          id: modelId,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
      dispatch(
        getTagList({
          params: { page_num: 1, page_size: 9999, is_active: 1 },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      );
    }
    // get all model package list
    dispatch(
      getModelPackageList({
        jwt_id: id,
        jwt_user_id: user_id,
        params: { page_num: 1, page_size: 9999 },
        isList: true,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  }, []);

  return (
    <Page
      title={`${`${isEdit ? "Edit" : "Create"} Model Package`} | OmniML`}
      heading={`${isEdit ? "Edit" : "Create"} Model Package`}
      links={getLinks()}
    >
      <ModelPackageCreateOrEditForm />
    </Page>
  );
};

export default ModelPackageCreateOrEdit;

import {
  LambdaStep,
  LambdaInputStruct,
  LambdaOutputStruct,
  LambdaFunctionStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-lambda";
import { parseUri, checkUseParam } from "./helper-functions";

// ----------------------------------------------------------------------

export default function parseLambdaStep(
  index: number,
  pipelineStep: any,
  stepPairs: [string, string, string][]
) {
  const step = {
    id: String(index),
    type: "Lambda",
    name: pipelineStep.Name,
    function: parseFunctionProps(pipelineStep.FunctionArn),
    lambdaInputs: parseLambdaInputs(
      pipelineStep.Arguments,
      pipelineStep.Name,
      stepPairs
    ),
    lambdaOutputs: parseLambdaOutputs(pipelineStep.OutputParameters),
    nodeX: 220 * index,
    nodeY: 100,
    tags: [],
    properties: [],
    stepType: "Lambda",
  } as LambdaStep;

  return step;
}

const parseFunctionProps = (functionArn: string) => {
  // const items = functionArn.split(":");
  const functionProperties: LambdaFunctionStruct = {
    type: "arn",
    // content: items[items.length - 1],
    content: functionArn,
  };
  return functionProperties;
};

const parseLambdaInputs = (
  inputs: any,
  stepName: string,
  stepPairs: [string, string, string][]
) => {
  const lambdaInputs: Array<LambdaInputStruct> = [];
  Object.keys(inputs).map((key) =>
    lambdaInputs.push({
      name: key,
      value: parseUri(inputs[key], stepName, stepPairs, key),
      valueUseParam: checkUseParam(inputs[key]),
    })
  );
  return lambdaInputs;
};

const parseLambdaOutputs = (OutputParameters: any) => {
  if (OutputParameters === undefined) return [];
  const lambdaOutputs: Array<LambdaOutputStruct> = [];
  OutputParameters.map((output: any) =>
    lambdaOutputs.push({
      name: output.OutputName,
      type: output.OutputType,
    })
  );
  return lambdaOutputs;
};

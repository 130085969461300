import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "@/layouts/project";
// guards
import AuthorityGuard from "@/guards/project/AuthorityGuard";

import Notebook from "@/pages/project/experiment/Notebook";
import NotebookVolume from "@/pages/project/experiment/NotebookVolume";
import HpTuner from "@/pages/project/experiment/HpTuner";
import MlPipeline from "@/pages/project/automation/MlPipeline";
import PipelineVolume from "@/pages/project/automation/PipelineVolume";
import ModelRegistry from "@/pages/project/dataAsset/ModelRegistry";
import ModelPackageDetail from "@/pages/project/dataAsset/ModelRegistry/ModelPackageDetail";
import ModelPackageCreateOrEdit from "@/pages/project/dataAsset/ModelRegistry/ModelPackageCreateOrEdit";
import FeatureStore from "@/pages/project/dataAsset/FeatureStore";
import FeatureSetDetail from "@/pages/project/dataAsset/FeatureStore/FeatureSetDetail";
import FeatureSetCreateOrEdit from "@/pages/project/dataAsset/FeatureStore/FeatureSetCreateOrEdit";

import MlRuntime from "@/pages/project/mlService/MlRuntime";
import MlRuntimeDetail from "@/pages/project/mlService/MlRuntime/MlRuntimeDetail";
import MlRuntimeCreate from "@/pages/project/mlService/MlRuntime/MlRuntimeCreate";
import MlRuntimeEdit from "@/pages/project/mlService/MlRuntime/MlRuntimeEdit";
import MlAppGallery from "@/pages/project/mlService/MlAppGallery";
import MlAppGalleryCreateOrEdit from "@/pages/project/mlService/MlAppGallery/MlAppGalleryCreateOrEdit";
import MlAppGalleryDetail from "@/pages/project/mlService/MlAppGallery/MlAppGalleryDetail";
import MlAppGalleryIframe from "@/pages/project/mlService/MlAppGallery/MlAppGalleryIframe";

import Organization from "@/pages/project/administration/Organization";
import Connection from "@/pages/project/connection/Connection";
import Credential from "@/pages/project/connection/Credential";

// Main
import Page500 from "@/pages/project/other/Page500";
import NotFound from "@/pages/project/other/Page404";
import PlatformMetrics from "@/pages/project/observability/PlatformMetrics";
import PlatformLogging from "@/pages/project/observability/PlatformLogging";
import OrganizationDetail from "@/pages/project/administration/Organization/OrganizationDetail";
import OrganizationEdit from "@/pages/project/administration/Organization/OrganizationEdit";
import ProjectCreateOrEdit from "@/pages/project/administration/Organization/ProjectCreateOrEdit";
import ProjectDetail from "@/pages/project/administration/Organization/ProjectDetail";

import NoteBookCreate from "@/pages/project/experiment/Notebook/NoteBookCreate";
import NoteBookIframe from "@/pages/project/experiment/Notebook/NoteBookIframe";
import NotebookVersion from "@/pages/project/experiment/Notebook/NotebookVersion";
import { default as NewPipelinePage } from "@/pages/project/automation/MlPipeline/PipelinePage";
import ProjectRole from "@/pages/project/template/ProjectRole";
import ProjectRoleDetail from "@/pages/project/template/ProjectRole/ProjectRoleDetail";
import NotebookTemplate from "@/pages/project/template/NotebookTemplate";
import PipelineTemplate from "@/pages/project/template/PipelineTemplate";
import Swagger from "@/pages/project/other/Swagger";
import Monitor from "@/pages/project/projectGvernance/Monitor";
import HptunerDetail from "@/pages/project/experiment/HpTuner/HptunerDetail";
import HpTunerCreateOrDuplicate from "@/pages/project/experiment/HpTuner/HpTunerCreateOrDuplicate";
import PipelineCreateOrEdit from "@/pages/project/automation/MlPipeline/PipelineCreateOrEdit";
import PipelineDetail from "@/pages/project/automation/MlPipeline/PipelineDetail";

let prefix = "ui";

// The absolute path is defined directly under the root path and will not match its parent path（cannot match the layout in the parent path）

// if current path changed，Router() will be triggered, <AuthorityGuard /> will be render
export default function Router({ globalConfig }) {
  return useRoutes([
    {
      path: prefix,
      element: (
        <AuthorityGuard globalConfig={globalConfig}>
          <DashboardLayout />
        </AuthorityGuard>
      ),
      children: [
        {
          index: true,
          element: <Navigate to="administration/organization" replace />,
        },
        {
          path: "myCredential",
          element: <Credential />,
        },
        {
          path: "swagger",
          element: <Swagger />,
        },
        {
          path: "experiment",
          children: [
            { index: true, element: <Navigate to="notebook" replace /> }, // /ui/administration redirect to the first page
            {
              path: "notebook",
              element: <Notebook />,
            },
            // hide in menu
            {
              path: "notebook/notebookCreate",
              element: <NoteBookCreate />,
            },
            // hide in menu
            {
              path: "notebook/:notebookId",
              element: <NoteBookIframe />,
            },
            // hide in menu
            {
              path: "notebook/:notebookId/notebookVersion",
              element: <NotebookVersion />,
            },
            {
              path: "notebookVolume",
              element: <NotebookVolume />,
            },
            {
              path: "hpTuner",
              element: <HpTuner />,
            },
            {
              path: "hpTuner/:hpTunerId",
              element: <HptunerDetail />,
            },
            {
              path: "hpTuner/hpTunerCreate",
              element: <HpTunerCreateOrDuplicate />,
            },
            {
              path: "hpTuner/:hpTunerId/hpTunerDuplicate",
              element: <HpTunerCreateOrDuplicate />,
            },
          ],
        },
        {
          path: "automation",
          children: [
            { index: true, element: <Navigate to="mlPipeline" replace /> },
            {
              path: "mlPipeline",
              element: <MlPipeline />,
            },
            {
              path: "pipelineVolume",
              element: <PipelineVolume />,
            },
            // hide in menu
            {
              path: "mlPipeline/create",
              element: <PipelineCreateOrEdit />,
            },
            // hide in menu
            {
              path: "mlPipeline/:mlPipelineId",
              element: <PipelineDetail />,
            },
            // hide in menu
            {
              path: "mlPipeline/:mlPipelineId/edit",
              element: <PipelineCreateOrEdit />,
            },
            // hide in menu
            {
              path: "mlPipeline/:mlPipelineId/createVersion",
              element: <NewPipelinePage />,
            },
            // hide in menu
            {
              path: "mlPipeline/:mlPipelineId/:versionId/duplicateVersion",
              element: <NewPipelinePage />,
            },
            // hide in menu
            {
              path: "mlPipeline/:mlPipelineId/:versionId",
              element: <NewPipelinePage />,
            },
          ],
        },
        {
          path: "dataAsset",
          children: [
            { index: true, element: <Navigate to="modelRegistry" replace /> },
            {
              path: "modelRegistry",
              element: <ModelRegistry />,
            },
            // hide in menu
            {
              path: "modelRegistry/:modelId",
              element: <ModelPackageDetail />,
            },
            // hide in menu
            {
              path: "modelRegistry/:modelId/modelPackageEdit",
              element: <ModelPackageCreateOrEdit />,
            },
            // hide in menu
            {
              path: "modelRegistry/modelPackageCreate",
              element: <ModelPackageCreateOrEdit />,
            },
            {
              path: "featureStore",
              element: <FeatureStore />,
            },
            // hide in menu
            {
              path: "featureStore/:featureId",
              element: <FeatureSetDetail />,
            },
            // hide in menu
            {
              path: "featureStore/:featureId/featureSetEdit",
              element: <FeatureSetCreateOrEdit />,
            },
            // hide in menu
            {
              path: "featureStore/featureSetCreate",
              element: <FeatureSetCreateOrEdit />,
            },
          ],
        },
        {
          path: "mlService",
          children: [
            { index: true, element: <Navigate to="mlRuntime" replace /> },
            {
              path: "mlRuntime",
              element: <MlRuntime />,
            },
            // hide in menu
            {
              path: "mlRuntime/:runtimeId",
              element: <MlRuntimeDetail />,
            },
            // hide in menu
            {
              path: "mlRuntime/mlRuntimeCreate",
              element: <MlRuntimeCreate />,
            },
            // hide in menu
            {
              path: "mlRuntime/:runtimeId/mlRuntimeEdit",
              element: <MlRuntimeEdit />,
            },
            {
              path: "appGallery",
              element: <MlAppGallery />,
            },
            // hide in menu
            {
              path: "appGallery/:appId",
              element: <MlAppGalleryDetail />,
            },
            // hide in menu
            {
              path: "appGallery/appGalleryCreate",
              element: <MlAppGalleryCreateOrEdit />,
            },
            // hide in menu
            {
              path: "appGallery/:appId/appGalleryEdit",
              element: <MlAppGalleryCreateOrEdit />,
            },
            // hide in menu
            {
              path: "appGallery/:appId/appGalleryOpen",
              element: <MlAppGalleryIframe />,
            },
          ],
        },
        {
          path: "projectGovernance",
          children: [
            { index: true, element: <Navigate to="mlRuntime" replace /> },
            {
              path: "manage",
              element: <ProjectDetail />,
            },
            // hide in menu
            /* {
              path: 'manage/:projectId/projectEdit',
              element: <ProjectCreateOrEdit />
            }, */
            {
              path: "manage/:roleId",
              element: <ProjectRoleDetail />,
            },
            {
              path: "monitor",
              element: <Monitor />,
            },
          ],
        },
        {
          path: "administration",
          children: [
            // { path: "pipeline/:id/duplicate", element: <PipelinePage /> }, use by useParams() to get the id
            { index: true, element: <Navigate to="organization" replace /> }, // /ui/administration redirect to the first page
            {
              path: "organization",
              element: <Organization />,
            },
            // hide in menu
            {
              path: "organization/:orgId",
              element: <OrganizationDetail />,
            },
            // hide in menu
            {
              path: "organization/:orgId/edit",
              element: <OrganizationEdit />,
            },
            // hide in menu
            {
              path: "organization/:orgId/projectCreate",
              element: <ProjectCreateOrEdit />,
            },
            // hide in menu
            {
              path: "organization/:orgId/:projectId",
              element: <ProjectDetail />,
            },
            // hide in menu
            {
              path: "organization/:orgId/:projectId/projectEdit",
              element: <ProjectCreateOrEdit />,
            },
            /* {
              path: 'project',
              element: <Project />
            },
            // hide in menu
            {
              path: 'project/projectCreate',
              element: <ProjectCreateOrEdit />
            },
            // hide in menu
            {
              path: 'project/:projectId',
              element: <ProjectDetail />
            },
            {
              path: 'project/:projectId/projectEdit',
              element: <ProjectCreateOrEdit />
            }, */
          ],
        },
        {
          path: "template",
          children: [
            { index: true, element: <Navigate to="projectRole" replace /> },
            {
              path: "projectRole",
              element: <ProjectRole />,
            },
            // hide in menu
            {
              path: "projectRole/:roleId",
              element: <ProjectRoleDetail />,
            },
            {
              path: "notebookTemplate",
              element: <NotebookTemplate />,
            },
            {
              path: "pipelineTemplate",
              element: <PipelineTemplate />,
            },
          ],
        },
        {
          path: "connection",
          children: [
            { index: true, element: <Navigate to="connection" replace /> },
            {
              path: "connection",
              element: <Connection />,
            },
            /* {
              path: 'credential',
              element: <Credential />
            }, */
            // hide in menu
            /* {
              path: 'credential/myCredential',
              element: <Credential />
            }, */
          ],
        },
        {
          path: "observability",
          children: [
            { index: true, element: <Navigate to="projectMonitor" replace /> },
            /* {
              path: 'projectMonitor',
              element: <ProjectMonitor />
            }, */
            {
              path: "grafana",
              element: <PlatformMetrics />,
            },
            {
              path: "kibana",
              element: <PlatformLogging />,
            },
          ],
        },
      ],
    },

    {
      path: prefix,
      children: [
        /* {
          path: "login",
          element: <Login />
        },  */
        // { path: "coming-soon", element: <ComingSoon /> }, reserve temporarily
        // { path: "maintenance", element: <Maintenance /> },
        { path: "500", element: <Page500 /> }, // undetermined. probably need the errorBoundary（react-error-boundary）
        { path: "404", element: <NotFound /> },
        // { path: "403", element: <Forbidden /> },// must be embeded in the layout

        {
          path: "*",
          element: (
            <AuthorityGuard>
              <Navigate to="404" replace />
            </AuthorityGuard>
          ),
        },
      ],
    },
  ]);
}

import { Menu, MenuItem, Stack, Typography } from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router";
import useCollapseDrawer from "@/hooks/project/useCollapseDrawer";

const HelpBtnMenuItem = ({ item, handleClose: handleFirstClose }) => {
  const navigate = useNavigate();
  const { toggleIsCollapseByBol } = useCollapseDrawer();

  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const subMenuOpen = Boolean(subMenuAnchorEl);

  const openSubMenu = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };

  const closeSubMenu = () => {
    setSubMenuAnchorEl(null);
  };

  return (
    <>
      <MenuItem
        onClick={(e) => {
          if (!isEmpty(item?.children)) {
            openSubMenu(e);
          } else {
            if (item?.isExternal && item?.link) {
              window.open(item?.link, "_blank");
            }
            handleFirstClose();
          }
        }}
      >
        <Stack direction="row" alignItems="center" sx={{ cursor: "pointer" }}>
          {!isEmpty(item?.children) && (
            <ExpandMoreOutlinedIcon
              sx={{
                transform: subMenuOpen ? "rotate(90deg)" : "rotate(0deg)",
                transition: "transform 0.5s",
                ml: -1.7,
              }}
            />
          )}
          <Typography
            sx={{ ...(isEmpty(item?.children) ? { pl: 1.5 } : {}) }}
            variant="inherit"
          >
            {item.title}
          </Typography>
          {item.isExternal && (
            <OpenInNewOutlinedIcon
              sx={{ width: 20, height: 20, color: "secondary.main", ml: 1 }}
            />
          )}
        </Stack>
      </MenuItem>
      {!isEmpty(item?.children) && (
        <Menu
          id="simple-menu-2"
          anchorEl={subMenuAnchorEl}
          keepMounted
          open={subMenuOpen}
          onClose={closeSubMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {item.children.map((i) => (
            <MenuItem
              onClick={() => {
                navigate(i.link);
                toggleIsCollapseByBol(true);

                closeSubMenu();
                handleFirstClose();
              }}
            >
              {i.title}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default HelpBtnMenuItem;

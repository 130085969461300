/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router";
import {
  Dialog,
  Typography,
  Stack,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import UploadMultiFile from "@/components/project/upload/UploadMultiFile";
import { dispatch, RootState, useSelector } from "@/redux/store";
import {
  uploadFiles,
  addModelPackageVersion,
  getModelPackageVersionList,
  getCredentialList,
  getToken,
} from "@/redux/project/dataAsset/modelRegistry/thunks";
import { useSnackbar } from "notistack";
import { isEmpty } from "lodash";
import { uuidv7 } from "uuidv7";
import { LoadingButton } from "@mui/lab";

interface IUploadDialogProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

export interface CustomFile extends File {
  path?: string;
  preview?: string;
  lastModifiedDate?: Date;
}

const UploadDialog = ({ isOpen, onClose }: IUploadDialogProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const [credential, setCredential] = useState<Record<string, any>>({});
  const [files, setFiles] = useState<File[]>([]);
  const [imagesOnHold, setImagesOnHold] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    modelPackageDescribeDatas: { data: modelPackageDescribe },
    connectionDatas: { data: connectionData },
    modelPackageVersionDatas: {
      data: { items: versionList = [] },
    },
    credentialDatas: {
      data: { items: credentialList = [] },
    },
  } = useSelector((state: RootState) => state.modelRegistry);

  useEffect(() => {
    // get all version
    dispatch(
      getModelPackageVersionList({
        isAll: true,
        params: {
          page_num: 1,
          page_size: 9999,
          modelpackage_id: modelPackageDescribe.id,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    dispatch(
      getCredentialList({
        params: { page_num: 1, page_size: 9999, is_active: 1 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  }, []);

  useEffect(() => {
    const curCredential =
      credentialList.find(
        (item) => item.id === modelPackageDescribe?.resource_credential_id
      ) || {};
    setCredential(curCredential);
  }, [credentialList]);

  const handleDropMultiFile = useCallback(
    (acceptedFiles) => {
      const objUrls = acceptedFiles.map((file: File) =>
        URL.createObjectURL(file)
      );

      setImagesOnHold(objUrls);
      setFiles(
        acceptedFiles.map((file: File, id: number) =>
          Object.assign(file, {
            preview: objUrls[id],
          })
        )
      );
    },
    [setImagesOnHold]
  );

  const handleRemoveAll = () => {
    setImagesOnHold([]);
  };

  const handleRemove = (file: any) => {
    const filteredImgs = imagesOnHold.filter((_file) => _file !== file.preview);
    setImagesOnHold(filteredImgs);

    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems);
  };

  const onUpload = async () => {
    try {
      if (!isEmpty(files)) {
        setLoading(true);
        // get hash token
        const hash = await dispatch(
          getToken({
            id: credential?.resource_location,
            alertCallback: enqueueSnackbar,
            pathname,
          })
        ).unwrap();

        if (hash?.access_token) {
          // upload file
          const externalId = uuidv7();
          const formData = new FormData();
          formData.append("external_id", externalId);
          formData.append(
            "s3dataset_bucket",
            connectionData?.connection_endpoint
          );
          formData.append(
            "s3dataset_prefix",
            modelPackageDescribe.resource_path
          );
          formData.append("s3dataset_access", hash.access_token);
          // eslint-disable-next-line array-callback-return
          files.map((file) => {
            formData.append("s3dataset_upload", file);
          });

          const fileResult = await dispatch(
            uploadFiles({
              formData,
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap();

          // create feature set version
          const params = {
            external_id: externalId,
            name: modelPackageDescribe.name,
            modelpackage_version_state: "DRAFT",
            modelpackage_version_number: versionList.length + 1,
            resource_type: modelPackageDescribe.resource_type,
            resource_path: fileResult.id || "",
            resource_checksum: "",
            modelpackage_id: modelPackageDescribe.id,
          };
          await dispatch(
            addModelPackageVersion({
              params,
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap();

          // get version list
          await dispatch(
            getModelPackageVersionList({
              params: {
                page_num: 1,
                page_size: 10,
                modelpackage_id: modelPackageDescribe.id,
              },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap();

          setLoading(false);
          onClose();
          enqueueSnackbar("Upload Success", { variant: "success" });
        }
      } else {
        enqueueSnackbar("Please upload files first.", { variant: "warning" });
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const curCredential =
      credentialList.find((item) => item.id === event.target.value) || {};
    setCredential(curCredential);
  };

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown
      onClose={() => {
        onClose();
      }}
      PaperProps={{
        sx: {
          width: "960px",
          maxWidth: "none",
          padding: "20px 40px",
        },
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Upload Model Package
      </Typography>
      <Typography variant="subtitle1">Credentials - Optional</Typography>
      <FormControl size="small" sx={{ mb: 2, mt: 2 }}>
        <InputLabel>Secrets</InputLabel>
        <Select
          value={credential?.id || modelPackageDescribe?.resource_credential_id}
          label="Secrets"
          onChange={handleSelectChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 280,
                maxWidth: 280,
              },
            },
          }}
          sx={{ width: 280 }}
        >
          {credentialList.map((item, index) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <UploadMultiFile
        showButtons={false}
        files={files}
        onDrop={handleDropMultiFile}
        onRemove={handleRemove}
        onRemoveAll={handleRemoveAll}
      />

      <Stack spacing={2} direction="row" justifyContent="center" sx={{ my: 4 }}>
        <LoadingButton
          type="submit"
          loading={loading}
          variant="contained"
          sx={{ width: "200px", color: "background.paper" }}
          onClick={onUpload}
        >
          Save
        </LoadingButton>
        <Button
          type="button"
          color="inherit"
          variant="outlined"
          onClick={onClose}
          sx={{ width: "200px", color: "text.secondary" }}
        >
          Cancel
        </Button>
      </Stack>
    </Dialog>
  );
};

export default UploadDialog;

/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import MlCard from "@/components/project/mlComponents/MlCard";
import MlCardList from "@/components/project/mlComponents/MlCardList";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Stack,
  Button,
  Chip,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Collapse,
  Grid,
} from "@mui/material";
import Label, { LabelColor } from "@/components/project/Label";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import AppDeployDialog from "./AppDeployDialog";
import { useSelector, RootState } from "@/redux/store";
import { useSnackbar } from "notistack";

interface IAppGalleryDetailCards {
  setOpenDeployConfigVersion: Function;
  selectedVersionInfo: Record<string, any>;
  versionLoading: boolean;
}

enum StatusEnum {
  draft = "secondary",
  wait_start = "warning",
  starting = "secondary",
  started = "success",
  wait_close = "warning",
  closing = "secondary",
  closed = "default",
  failed = "error",
}

const AppGalleryDetailCards = ({
  setOpenDeployConfigVersion,
  selectedVersionInfo,
  versionLoading,
}: IAppGalleryDetailCards) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [showVariableValue, setShowVariableValue] = useState<boolean[]>([]);
  const [openAppDeployDialog, setOpenAppDeployDialog] =
    useState<boolean>(false);
  const {
    mlAppDescribeData: { data: appDetail, loading },
    appTagDatas: {
      data: { items: appTagList = [] },
      loading: tagLoading,
    },
    tagDatas: {
      data: { items: tagList = [] },
    },
  } = useSelector((state: RootState) => state.appGallery);
  const {
    userDetail: { project_id },
    config: { common = {} },
  } = useSelector((state: RootState) => state.common);
  const { BASE_URL } = common;

  const handleClickShowPassword = (index) => {
    const variableValue = [...showVariableValue];
    variableValue[index] = !showVariableValue[index];
    setShowVariableValue(variableValue);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `${BASE_URL}/appgallery/taichi-prj-${project_id}/app-${appDetail?.id}/`
    );
    enqueueSnackbar("Copy success", { variant: "success" });
  };

  const handleImageURLCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar("Copy success", { variant: "success" });
  };

  const getTagsContent = useCallback(() => {
    const tags: string[] = [];
    // eslint-disable-next-line array-callback-return
    appTagList.map((rel) => {
      const tagName = tagList.find((tag) => tag.id === rel.tag_id)?.name;
      if (tagName) tags.push(tagName);
    });
    return tags.length > 0 ? (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {tags.map((tag) => {
          return <Chip label={tag} size="small" key={tag} />;
        })}
      </Box>
    ) : (
      "-"
    );
  }, [appTagList]);

  const getAppGalleryInfo = useCallback(() => {
    const resource_config = selectedVersionInfo?.resource_config || {};
    return [
      { subTitle: "Title", label: appDetail?.name || "-", loading },
      { subTitle: "Scenario", label: appDetail?.scenario || "-", loading },
      {
        subTitle: "Endpoint",
        label: (
          <Stack direction="row" spacing={0} alignItems="center">
            <IconButton color="secondary" size="small" onClick={handleCopy}>
              <ContentCopyRoundedIcon
                sx={{ width: 16, height: 16, borderRadius: 4 }}
              />
            </IconButton>
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", wordBreak: "break-word" }}
            >
              {`${BASE_URL}/appgallery/taichi-prj-${project_id}/app-${appDetail?.id}/` ||
                "-"}
            </Typography>
            <IconButton
              color="secondary"
              size="small"
              onClick={() => navigate(`appGalleryOpen`)}
            >
              <OpenInNewOutlinedIcon
                sx={{ width: 16, height: 16, borderRadius: 4 }}
              />
            </IconButton>
          </Stack>
        ),
        loading,
      },
      {
        subTitle: "Type",
        label: appDetail?.app_type === 1 ? "Image" : "-" || "-",
        loading,
      },
      {
        subTitle: "Kubernetes Cluster",
        label: resource_config?.runtime?.cluster_name || "-",
        loading: versionLoading,
      },
      {
        subTitle: "Last Modified",
        label: appDetail?.updated_at || "-",
        loading,
      },
      { subTitle: "Modified By", label: appDetail?.updated_by || "-", loading },
      { subTitle: "Tags", label: getTagsContent(), tagLoading },
      {
        subTitle: "Description",
        label: appDetail?.description || "-",
        loading,
      },
    ];
  }, [appDetail, selectedVersionInfo]);

  const getDeploymentInfo = useCallback(() => {
    return [
      {
        subTitle: "Image URL",
        label: appDetail?.image_url ? (
          <Stack direction="row" spacing={0} alignItems="center">
            <IconButton
              color="secondary"
              size="small"
              onClick={() => handleImageURLCopy(appDetail?.image_url)}
            >
              <ContentCopyRoundedIcon
                sx={{ width: 16, height: 16, borderRadius: 4 }}
              />
            </IconButton>
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", wordBreak: "break-word" }}
            >
              {appDetail?.image_url || "-"}
            </Typography>
          </Stack>
        ) : (
          "-"
        ),
        loading,
      },
      {
        subTitle: "Image Tag",
        label: selectedVersionInfo?.image_tag || "-",
        loading: versionLoading,
      },
      {
        subTitle: "Version Status",
        label: selectedVersionInfo?.status ? (
          <Label
            color={StatusEnum[selectedVersionInfo?.status] as LabelColor}
            sx={{ width: "100px" }}
          >
            {selectedVersionInfo?.status}
          </Label>
        ) : (
          "-"
        ),
        loading: versionLoading,
      },
      {
        subTitle: "Version Created At",
        label: selectedVersionInfo?.created_at || "-",
        loading: versionLoading,
      },
      {
        subTitle: "Remark",
        label: selectedVersionInfo?.remark || "-",
        loading: versionLoading,
      },
      { subTitle: "", label: "" },
    ];
  }, [appDetail, selectedVersionInfo]);

  const getOtherInfo = useCallback(() => {
    const variables = Object.entries(
      selectedVersionInfo?.env_variables || {}
    ).map(([key, value]) => ({
      key,
      value,
    })) as Array<{ key: string; value: string }>;
    return (
      <>
        <TextField
          id="cmd"
          variant="outlined"
          label="CMD (Optional)"
          multiline
          rows={4}
          value={
            selectedVersionInfo?.cmd ? atob(selectedVersionInfo?.cmd) : "-"
          }
          style={{ width: "80%", marginTop: "10px" }}
        />
        <Stack
          direction="row"
          alignItems="center"
          sx={{ cursor: "pointer", width: 200 }}
          onClick={() => setOpen(!open)}
        >
          <ExpandMoreOutlinedIcon
            sx={{
              transform: open ? "rotate(0)" : "rotate(-90deg)",
              transition: "transform 0.5s",
            }}
          />
          <Typography variant="subtitle1" paddingY={2}>
            Environment Variables
          </Typography>
        </Stack>
        <Collapse in={open}>
          {variables.map((env, index) => (
            <Stack key={index} direction="row" sx={{ mb: 1, px: 2.5 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    name={`variables.${index}.key`}
                    value={env.key}
                    size="small"
                    label="Key"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    name={`variables.${index}.value`}
                    type={!showVariableValue[index] ? "password" : "text"}
                    value={env.value}
                    size="small"
                    label="Value"
                    fullWidth
                    margin="dense"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleClickShowPassword(index)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {!showVariableValue[index] ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Stack>
          ))}
        </Collapse>
      </>
    );
  }, [selectedVersionInfo, showVariableValue, open]);

  const handleOpenVersion = () => {
    setOpenDeployConfigVersion(true);
    // toggleIsCollapseByBol(true);
  };

  const getExtraBtn = () => {
    return (
      <Button
        size="small"
        color="secondary"
        sx={{ height: 24 }}
        endIcon={<KeyboardArrowRightIcon />}
        onClick={handleOpenVersion}
      >
        Version
      </Button>
    );
  };

  return (
    <>
      <MlCard title="Basic Information" isCanFold>
        <MlCardList list={getAppGalleryInfo()} minWidth={600} />
      </MlCard>
      <MlCard title="Deployment Configuration" extraJsx={getExtraBtn()}>
        <MlCardList list={getDeploymentInfo()} minWidth={600} />
        {getOtherInfo()}
      </MlCard>

      {openAppDeployDialog && (
        <AppDeployDialog
          isOpen={openAppDeployDialog}
          onClose={() => {
            setOpenAppDeployDialog(false);
          }}
        />
      )}
    </>
  );
};

export default AppGalleryDetailCards;

import StepDetailTable from "../StepDetailTable";

const InputsTable = ({ title, rows }) => {
  const columns = [
    { id: "name", label: "Name", minWidth: 150 },
    { id: "value", label: "Value" },
    { id: "dataType", label: "Data Type" },
    { id: "description", label: "Description" },
  ];

  return <StepDetailTable title={title} columns={columns} rows={rows} />;
};

export default InputsTable;

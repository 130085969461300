import * as Yup from "yup";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { Stack, Collapse } from "@mui/material";
// types
import {
  TrainingStep,
  TrainingOutputStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-training";
//
import { updateTrainingOutput } from "../../../graph/slices/slice";
import { CollapseButtonStyle } from "../../SettingSidebar";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";

// ----------------------------------------------------------------------

type Props = {
  currentStep: TrainingStep | null;
};

export default function TrainingOutput({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [isCollapse, setCollapse] = useState(true);

  const ParamSchema = Yup.object().shape({
    // s3OutputPath: Yup.string().required("Output path is required"),
  });

  const formik = useFormik<TrainingOutputStruct>({
    initialValues: {
      s3OutputPath: currentStep?.trainingOutput.s3OutputPath || "",
      s3OutputPathUseParam:
        currentStep?.trainingOutput.s3OutputPathUseParam || false,
    },
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values) => {
      try {
        dispatch(updateTrainingOutput(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { handleSubmit } = formik;

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setCollapse((prev) => !prev)}
        endIcon={
          <Icon
            icon={isCollapse ? arrowIosForwardFill : arrowIosDownwardFill}
            width={16}
            height={16}
          />
        }
      >
        Training Output
      </CollapseButtonStyle>

      <Collapse in={!isCollapse} sx={{ mx: 2, mb: !isCollapse ? 0.5 : 0 }}>
        <Stack spacing={1} sx={{ my: 1 }}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onChange={handleSubmit}>
              <PipelineParameterEntry
                label={"S3OutputPath"}
                field={"s3OutputPath"}
                fieldUseParam={"s3OutputPathUseParam"}
                formik={formik}
              />
            </Form>
          </FormikProvider>
        </Stack>
      </Collapse>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik, FormikProvider, Form, Field } from "formik";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  Stack,
  Box,
  Typography,
  FormHelperText,
  FormControlLabel,
  Switch,
} from "@mui/material";
import * as yup from "yup";
import Scrollbar from "@/components/project/Scrollbar";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "@/redux/store";
import {
  addCredential,
  addRelConnCredential,
  getConnectionTableData,
  getCredentialDetail,
  getRelConnCredentialTableData,
  updateCredential,
} from "@/redux/project/connection/thunks";
import { resetConnectionData } from "@/redux/project/connection/slice";
import { LoadingButton } from "@mui/lab";
import MlPasswordField from "@/components/project/mlComponents/MlPasswordField";
import { addSecret, updateSecret } from "@/redux/project/administration/thunks";

interface ICredentialCreateOrEditDialog {
  mode: "create" | "edit";
  isOpen: boolean;
  onClose: VoidFunction;
  row: Record<any, any>;
  handleRefresh: (a: boolean) => void;
}

interface FormValues {
  name: string;
  type: string;
  isContentRequired: boolean;
  accessKey: string;
  secretKey: string;
  username: string;
  password: string;
  connectionList: string[];
  description: string;
}

const initialValues: FormValues = {
  name: "",
  type: "",
  isContentRequired: true,
  accessKey: "",
  secretKey: "",
  username: "",
  password: "",
  connectionList: [],
  description: "",
};

const CredentialCreateOrEditDialog = ({
  mode,
  isOpen,
  onClose,
  row,
  handleRefresh,
}: ICredentialCreateOrEditDialog) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const {
    config: { govern = {} },
    userDetail: { user_id },
  } = useSelector((state: RootState) => state.common);
  const { credential_type: typeList = [] } = govern;

  const {
    connectionTableList: { data: connList },
  } = useSelector((state: RootState) => state.connection);

  const {
    credentialDetail: { data },
  } = useSelector((state: RootState) => state.connection);

  const getValidateRule1 = (type, isContentRequired) => {
    if (mode === "create") {
      return type === "AK/SK";
    } else {
      // edit
      if (type === "AK/SK") {
        return isContentRequired;
      } else {
        return false;
      }
    }
  };

  const getValidateRule2 = (type, isContentRequired) => {
    if (mode === "create") {
      return type === "Basic";
    } else {
      // edit
      if (type === "Basic") {
        return isContentRequired;
      } else {
        return false;
      }
    }
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .required("Key Name is required")
      .max(64, "Key Name cannot be longer than 64 characters"),
    type: yup.string().required("Type is required"),
    accessKey: yup.string().when(["type", "isContentRequired"], {
      is: (type, isContentRequired) =>
        getValidateRule1(type, isContentRequired),
      // is: 'AK/SK',
      then: yup.string().required("Access Key is required"),
    }),
    secretKey: yup.string().when(["type", "isContentRequired"], {
      is: (type, isContentRequired) =>
        getValidateRule1(type, isContentRequired),
      then: yup.string().required("Secret Key is required"),
    }),
    username: yup.string().when(["type", "isContentRequired"], {
      is: (type, isContentRequired) =>
        getValidateRule2(type, isContentRequired),
      then: yup.string().required("Token is required"),
    }),
    password: yup.string().when(["type", "isContentRequired"], {
      is: (type, isContentRequired) =>
        getValidateRule2(type, isContentRequired),
      then: yup.string().required("Value is required"),
    }),
    // connectionList: yup.array().of(yup.string()).min(1, 'Connection must contain at least one connection'),
    description: yup
      .string()
      .max(200, "Description cannot be longer than 200 characters"),
  });

  const handleCreateSubmit = async (values: FormValues) => {
    // create a secret first
    // user+<uid>+<credential_name>
    const tempSecretName = `user+${user_id}+${values.name}`;

    const content =
      values.type === "AK/SK"
        ? {
            aws_default_region: "us-east-1",
            aws_access_key_id: values.accessKey,
            aws_secret_access_key: values.secretKey,
          }
        : {
            username: values.username,
            password: values.password,
          };
    const { arn } = await dispatch(
      addSecret({
        params: {
          name: tempSecretName,
          content,
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();

    // create a credential
    const { id } = await dispatch(
      addCredential({
        params: {
          name: values.name,
          description: values.description,
          credential_type: values.type,
          credential_state: "RELEASED",
          credential_family: "User",
          credential_properties: {},
          resource_location: arn,
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();

    // create rel_connection_credential
    if (!isEmpty(values.connectionList)) {
      await Promise.all(
        values.connectionList.map((item) =>
          dispatch(
            addRelConnCredential({
              params: {
                connection_id: item,
                credential_id: id,
              },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap()
        )
      );
    }
  };

  const handleUpdateSubmit = async (values: FormValues) => {
    // update secret
    if (values.isContentRequired) {
      const tempSecretName = `user+${user_id}+${values.name}`;
      const content =
        values.type === "AK/SK"
          ? {
              aws_default_region: "us-east-1",
              aws_access_key_id: values.accessKey,
              aws_secret_access_key: values.secretKey,
            }
          : {
              username: values.username,
              password: values.password,
            };

      // arn is constant
      await dispatch(
        updateSecret({
          name: tempSecretName,
          params: {
            content,
          },
          pathname,
          alertCallback: enqueueSnackbar,
        })
      ).unwrap();
    }

    // update credential
    await dispatch(
      updateCredential({
        id: row.id,
        params: {
          name: values.name,
          description: values.description, // only this can change
          credential_type: values.type,
          credential_state: "RELEASED",
          credential_family: "User",
          credential_properties: {},
          // resource_location: arn,
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      try {
        if (mode === "edit") {
          await handleUpdateSubmit(values);
          enqueueSnackbar("Edit Success", { variant: "success" });
        } else {
          await handleCreateSubmit(values);
          enqueueSnackbar("Create Success", { variant: "success" });
        }
        actions.setSubmitting(false);

        handleRefresh(true);
        handleClose();
      } catch (e) {
        console.log("error-in-submit", e);
        actions.setSubmitting(false);
      }
    },
  });

  const getCredentialFormPart = (type) => {
    if (type === "AK/SK") {
      return (
        <>
          <MlPasswordField
            name="accessKey"
            label="Access Key"
            formik={formik}
          />
          <MlPasswordField
            name="secretKey"
            label="Secret Key"
            formik={formik}
          />
        </>
      );
    }

    if (type === "Basic") {
      return (
        <>
          <MlPasswordField name="username" label="Username" formik={formik} />
          <MlPasswordField name="password" label="Password" formik={formik} />
        </>
      );
    }

    return <></>;
  };

  const { values, errors, touched, setFieldValue, isSubmitting, resetForm } =
    formik;
  // console.log('values', values);
  // console.log('errors', errors);

  const handleClose = () => {
    onClose();
    resetForm();
    dispatch(resetConnectionData("credentialDetail"));
  };

  const handleInit = async () => {
    try {
      await dispatch(
        getCredentialDetail({
          id: row.id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      dispatch(
        getRelConnCredentialTableData({
          params: {
            credential_id: row.id,
            page_num: 1,
            page_size: 9999,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((res) => {
          setFieldValue(
            "connectionList",
            res?.items?.map((item) => item.connection_id)
          );
        });
    } catch (e) {}
  };

  useEffect(() => {
    dispatch(
      getConnectionTableData({
        params: {
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );

    if (isOpen && mode === "edit") {
      handleInit();
    }
  }, [isOpen]);

  // set default name
  useEffect(() => {
    if (isOpen && mode === "create") {
      setFieldValue("type", typeList[0] || "");
      setFieldValue("name", `omniml-secret-${new Date().getTime()}`);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isEmpty(data) && mode === "edit") {
      setFieldValue("name", data?.name || "");
      setFieldValue("type", data?.credential_type || "");
      setFieldValue("description", data?.description || "");
    }
  }, [data]);

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown
      onClose={handleClose}
      PaperProps={{
        style: { width: "960px", maxWidth: "none", padding: "20px 40px" },
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        {mode === "create" ? "New" : "Edit"} Credential
      </Typography>
      <FormikProvider value={formik}>
        <Form>
          <Scrollbar sx={{ maxHeight: "600px" }}>
            <Box sx={{ px: 2.5 }}>
              <Field
                as={TextField}
                name="name"
                disabled={mode === "edit"}
                size="small"
                label="Key Name"
                fullWidth
                margin="dense"
                helperText={touched.name && errors.name ? errors.name : " "}
                error={touched.name && Boolean(errors.name)}
                FormHelperTextProps={{
                  sx: { minHeight: "18px", marginTop: "2px" },
                }}
              />
              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={touched.type && Boolean(errors.type)}
              >
                <InputLabel>Type</InputLabel>
                <Field
                  as={Select}
                  disabled={mode === "edit"}
                  name="type"
                  label="Type"
                >
                  {typeList.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {touched.type && errors.type ? errors.type : ""}
                </FormHelperText>
              </FormControl>

              {mode === "edit" && (
                <FormControlLabel
                  control={
                    // checked to value
                    <Field name="isContentRequired">
                      {({ field }) => (
                        <Switch
                          {...field}
                          checked={field.value || false} // init undefined to false
                          onChange={(_, checked) => {
                            setFieldValue(field.name, checked);
                            if (!checked) {
                              setFieldValue("accessKey", "");
                              setFieldValue("secretKey", "");
                              setFieldValue("username", "");
                              setFieldValue("password", "");
                            }
                          }}
                          size="small"
                        />
                      )}
                    </Field>
                  }
                  label={`Edit ${
                    values.type === "Basic" ? "username/password" : "AK/SK"
                  }`}
                  sx={{ pl: 1, pb: 1.5 }}
                />
              )}

              {values.isContentRequired && getCredentialFormPart(values.type)}

              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={touched.connectionList && Boolean(errors.connectionList)}
              >
                <InputLabel>Connection</InputLabel>
                <Field
                  as={Select}
                  multiple
                  disabled={mode === "edit"}
                  name="connectionList"
                  label="Connection"
                >
                  {connList?.items?.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {touched.connectionList && errors.connectionList
                    ? errors.connectionList
                    : ""}
                </FormHelperText>
              </FormControl>

              <Field
                as={TextField}
                name="description"
                size="small"
                multiline
                rows={4}
                label="Description (Optional)"
                fullWidth
                margin="dense"
                helperText={
                  touched.description && errors.description
                    ? errors.description
                    : " "
                }
                error={touched.description && Boolean(errors.description)}
                FormHelperTextProps={{
                  sx: { minHeight: "18px", marginTop: "2px" },
                }}
              />
            </Box>
          </Scrollbar>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ my: 4 }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              color="primary"
              sx={{ width: "200px", color: "background.paper" }}
            >
              Save
            </LoadingButton>
            <Button
              type="button"
              color="inherit"
              variant="outlined"
              onClick={handleClose}
              sx={{ width: "200px", color: "text.secondary" }}
            >
              Cancel
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default CredentialCreateOrEditDialog;

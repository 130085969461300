import Page from "@/components/project/Page";
import { Card } from "@mui/material";
import HpTunerTable from "./components/hpTunerList/HpTunerTable";

const HpTuner = () => {
  return (
    <Page>
      <Card>
        <HpTunerTable />
      </Card>
    </Page>
  );
};

export default HpTuner;

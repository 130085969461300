/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TablePagination,
  Stack,
  Button,
  Typography,
  Tooltip,
} from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import Scrollbar from "@/components/project/Scrollbar";
import { extractKeys } from "@/utils/project/utils";
import NotebookVersionTableActionCol from "./NotebookVersionTableActionCol";
import MlCard from "@/components/project/mlComponents/MlCard";
import MlTableBodyContainer from "@/components/project/mlComponents/MlTableBodyContainer";
import { getNotebookVersionTableData } from "@/redux/project/experiment/thunks";
import { RootState, dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import NotebookVersionCreateOrEditDialog from "./NotebookVersionCreateOrEditDialog";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

interface Column {
  id: string;
  label?: string;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
}

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 150 },
  { id: "created_at", label: "Create At" },
  { id: "", width: 150 },
];

const NotebookVersionTable = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { notebookId } = useParams();

  const [rowData, setRowData] = useState({});

  const {
    notebookDetail: { data: detailData },
  } = useSelector((state: RootState) => state.experiment);

  // dialog
  const [
    openNotebookVersionCreateOrEditDialog,
    setOpenNotebookVersionCreateOrEditDialog,
  ] = useState<boolean>(false);

  const [dialogMode, setDialogMode] = useState<"create" | "edit">("create");

  const [page_num, setPageNum] = useState<number>(1);
  const [page_size, setPageSize] = useState<number>(10);

  const {
    config: { common },
  } = useSelector((state: RootState) => state.common);
  const { taichi_docker_url_prefix } = common;

  const {
    userDetail: { project_id, project_list },
  } = useSelector((state: RootState) => state.common);

  const {
    notebookVersionTableList: { data, loading },
  } = useSelector((state: RootState) => state.experiment);

  const fetchData = (params: {
    notebook_id: string;
    project_id: string;
    page_num: number;
    page_size: number;
  }) => {
    return dispatch(
      getNotebookVersionTableData({
        params,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  };

  const handleRefresh = (isUpdate?: boolean) => {
    setPageNum(1);
    setPageSize(10);

    fetchData({
      page_num: 1,
      page_size: 10,
      notebook_id: notebookId!,
      project_id,
    }).then(() => {
      if (!isUpdate) {
        enqueueSnackbar("Refresh Success", { variant: "success" });
      }
    });
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNum(newPage + 1);
    fetchData({
      notebook_id: notebookId!,
      project_id,
      page_num: newPage + 1,
      page_size,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNum(0);
    fetchData({
      notebook_id: notebookId!,
      project_id,
      page_num: 1,
      page_size: parseInt(event.target.value, 10),
    });
  };

  const handleCopy = (row) => {
    // get organization id
    const org_id = project_list.find(
      (item) => item.id === project_id
    ).organization_id;

    navigator.clipboard.writeText(
      `${taichi_docker_url_prefix}/${org_id}/${project_id}/notebook-${detailData.id}:${row.name}`
    );

    enqueueSnackbar("Copy image url success", { variant: "success" });
  };

  const renderCell = (row, column) => {
    switch (column.id) {
      case "name":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Stack alignItems="center" spacing={1} direction="row">
              <Typography variant="subtitle2">{row[column.id]}</Typography>
              <Tooltip title="copy image url">
                <IconButton onClick={() => handleCopy(row)}>
                  <ContentCopyOutlinedIcon
                    sx={{ height: "16px", width: "16px" }}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          </TableCell>
        );
      case "":
        return (
          <TableCell
            align="right"
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <NotebookVersionTableActionCol
              row={row}
              setDialogMode={() => {
                setRowData(row);
                setDialogMode("edit");
                setOpenNotebookVersionCreateOrEditDialog(true);
              }}
              handleRefresh={handleRefresh}
            />
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {row[column.id] || "-"}
            </Typography>
          </TableCell>
        );
    }
  };

  useEffect(() => {
    dispatch(
      getNotebookVersionTableData({
        params: {
          notebook_id: notebookId!,
          project_id,
          page_num,
          page_size,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  }, []);

  return (
    <MlCard
      title="Notebook Version"
      hasPaddingX={false}
      extraJsx={
        <Stack direction="row" alignItems="center" spacing={2}>
          <Button
            variant="contained"
            sx={{ width: "120px", height: "36px" }}
            onClick={() => {
              setDialogMode("create");
              setOpenNotebookVersionCreateOrEditDialog(true);
            }}
          >
            Add Version
          </Button>
          <IconButton onClick={() => handleRefresh()}>
            <RefreshOutlinedIcon sx={{ width: "22px", height: "22px" }} />
          </IconButton>
        </Stack>
      }
    >
      <Scrollbar>
        <TableContainer sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <MlTableBodyContainer loading={loading} data={data?.items}>
                {data?.items?.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((column) => renderCell(row, column))}
                  </TableRow>
                ))}
              </MlTableBodyContainer>
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25]}
        component="div"
        count={data?.total || 0}
        rowsPerPage={page_size}
        page={page_num - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <NotebookVersionCreateOrEditDialog
        isOpen={openNotebookVersionCreateOrEditDialog}
        mode={dialogMode}
        onClose={() => {
          setOpenNotebookVersionCreateOrEditDialog(false);
        }}
        handleRefresh={handleRefresh}
        row={rowData}
      />
    </MlCard>
  );
};

export default NotebookVersionTable;

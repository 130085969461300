import * as Yup from "yup";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
// material
import { Box, Stack, Divider, Collapse } from "@mui/material";
// @types
import { RootState, useDispatch, useSelector } from "@/redux/store";
import { FailStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-fail";
//
import ErrorMessage from "./ErrorMessage";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { StyledTextField } from "../../components/PipelineComponents";
import {
  updateFailData,
  updateEntityName,
  SchemaNodeEntity,
} from "../../../graph/slices/slice";

// ----------------------------------------------------------------------

type Props = {
  entity: SchemaNodeEntity;
};

export default function FailStepEntry({ entity }: Props) {
  const dispatch = useDispatch();
  const [showStepConfig, setShowStepConfig] = useState(true);

  const currentStep = useSelector((state: RootState) => {
    let step: FailStep = state.schema.schema.pipelineSteps.find((step) => {
      return step.id === entity.id;
    }) as FailStep;
    return step;
  });

  const ParamSchema = Yup.object().shape({
    errorMessage: Yup.string().required("Error message is required"),
  });

  const formik = useFormik<FailStep>({
    initialValues: currentStep,
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values) => {
      try {
        dispatch(updateFailData(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <>
      {entity && (
        <Box>
          <CollapseButtonStyle
            fullWidth
            color="inherit"
            onClick={() => setShowStepConfig((prev) => !prev)}
            endIcon={
              <Icon
                icon={
                  showStepConfig ? arrowIosDownwardFill : arrowIosForwardFill
                }
                width={16}
                height={16}
              />
            }
          >
            Configuration
          </CollapseButtonStyle>

          <Collapse in={showStepConfig}>
            <Stack spacing={2} sx={{ mx: 2, mt: 1, mb: 2 }}>
              <StyledTextField
                label="Step name"
                defaultValue={entity.name}
                onChange={(event: any) => {
                  const newValue = event.target.value;
                  dispatch(updateEntityName({ name: newValue }));
                }}
              />
            </Stack>
          </Collapse>

          <Divider />

          <ErrorMessage formik={formik} />

          <Divider sx={{ mb: 1 }} />
        </Box>
      )}
    </>
  );
}

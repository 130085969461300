import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Box, Stack, Collapse, IconButton } from "@mui/material";
//
import EmptyTable from "@/components/project/EmptyTable";
import { CollapseButtonStyle } from "../../../../SettingSidebar";
import { TrainingStep } from "@/@types/project/mlPipeline/Kubeflow/pipeline-training";
import { handleKubeflowStepArray } from "@/pages/project/automation/MlPipeline/graph/slices/slice";
import TrainingCodeArgItem from "./partComponents/TrainingCodeArgItem";

// ----------------------------------------------------------------------

type Props = {
  currentStep: TrainingStep | null;
  isEdit: boolean;
};

export default function TrainingCodeArgs({ currentStep, isEdit }: Props) {
  const dispatch = useDispatch();

  const [showTrainingCodeArg, setShowTrainingCodeArg] = useState(false);

  const trainingCodeArgs = currentStep?.codeJobArgumentsList || [];

  const handleAddTrainingCodeArg = () => {
    if (!showTrainingCodeArg) {
      setShowTrainingCodeArg(true);
    }

    dispatch(
      handleKubeflowStepArray({
        handleType: "create",
        data: {
          name: `TrainingJobArg-${trainingCodeArgs.length + 1}`,
          value: "",
          dataType: "String",
          description: "",
          useParam: false,
          paramType: 0,
          source: "jobArg",
        },
        key: "codeJobArgumentsList",
      })
    );
  };

  return (
    <>
      <Box sx={{ mb: 0, position: "relative" }}>
        <CollapseButtonStyle
          fullWidth
          color="inherit"
          onClick={() => setShowTrainingCodeArg((prev) => !prev)}
          endIcon={
            <Icon
              icon={
                showTrainingCodeArg ? arrowIosDownwardFill : arrowIosForwardFill
              }
              width={16}
              height={16}
            />
          }
        >
          JOB ARGUMENTS{" "}
          {trainingCodeArgs.length > 0 && `(${trainingCodeArgs.length})`}
        </CollapseButtonStyle>

        <IconButton
          sx={{ zIndex: 1999, position: "absolute", top: 7, right: 30 }}
          disabled={isEdit}
          size="small"
          onClick={handleAddTrainingCodeArg}
        >
          <Icon icon={plusFill} width={16} height={16} />
        </IconButton>
      </Box>

      <Collapse in={showTrainingCodeArg}>
        <Stack
          spacing={1.5}
          sx={{ mx: 2, mb: 1.5, mt: showTrainingCodeArg ? 0.5 : 0 }}
        >
          {trainingCodeArgs.length > 0 &&
            trainingCodeArgs.map((trainingCodeArg, index) => (
              <TrainingCodeArgItem
                length={trainingCodeArgs.length}
                isEdit={isEdit}
                key={index}
                id={index}
                data={trainingCodeArg}
              />
            ))}
        </Stack>
      </Collapse>

      {showTrainingCodeArg && trainingCodeArgs.length === 0 && (
        <EmptyTable
          emptyImage={false}
          mainTitle={"No training job args"}
          content={'Create one by clicking "+" button'}
          color={"text.disabled"}
          sx={{ backgroundColor: "transparent", mx: 2, mt: 5, mb: 7 }}
        />
      )}
    </>
  );
}

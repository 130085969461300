import Page from "@/components/project/Page";
import { Card } from "@mui/material";
import PipelineVolumeTable from "./components/pipelineVolumeList/PipelineVolumeTable";

const PipelineVolumeVolume = () => {
  return (
    <Page>
      <Card>
        <PipelineVolumeTable />
      </Card>
    </Page>
  );
};

export default PipelineVolumeVolume;

import {
  Dialog,
  Typography,
  Stack,
  Button,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { RootState, useSelector } from "@/redux/store";
import { useTheme } from "@mui/material";
interface IRequestAccessDialogProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

const RequestAccessDialog = ({
  isOpen,
  onClose,
}: IRequestAccessDialogProps) => {
  const theme = useTheme();
  const {
    modelPackageDescribeDatas: { data: modelPackageDescribe },
  } = useSelector((state: RootState) => state.modelRegistry);
  const {
    userDetail: { user_id },
  } = useSelector((state: RootState) => state.common);

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown
      onClose={() => {
        onClose();
      }}
      PaperProps={{
        sx: {
          width: "960px",
          maxWidth: "none",
          padding: "20px 40px",
        },
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        How To Get Access
      </Typography>
      <DialogContent
        sx={{
          borderRadius: "5px",
          border: `1px solid ${theme.palette.grey[500_32]}`,
          pt: "10px!important",
        }}
      >
        <span color="#919EAB" style={{ fontSize: "16px", color: "#919EAB" }}>
          You can register the user <strong>{user_id}</strong> to the group{" "}
          <strong>{modelPackageDescribe.delivered_to}</strong> following the
          steps:
          <br />
          <br />
          1. Contact the owner{" "}
          <strong>{modelPackageDescribe.created_by}</strong> of this
          ModelPackage <strong>{modelPackageDescribe.name}</strong>.
          <br />
          <br />
          2. Request to join the group{" "}
          <strong>{modelPackageDescribe.delivered_to}</strong> for user{" "}
          <strong>{user_id}</strong>.
          <br />
          <br />
          3. Your registration is successful after confirmation by the owner.
        </span>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        <Stack spacing={2} direction="row" justifyContent="center">
          <Button
            type="submit"
            variant="contained"
            sx={{ width: "200px", color: "background.paper" }}
            onClick={onClose}
          >
            OK
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default RequestAccessDialog;

import { styled } from "@mui/material/styles";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from "@mui/material";
import { Typography } from "@mui/material";
import { extractKeys } from "@/utils/project/utils";
import { Box } from "@mui/material";

const TableHeaderCellStyle = styled(TableCell)(({ theme }) => ({
  minWidth: 120,
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(0.5),
  fontWeight: "bold",
  color: theme.palette.text.secondary,
  textAlign: "left",
}));

const TableRowCellStyle = styled(TableCell)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1.5),
  textAlign: "left",
}));

/* const columns = [
  { id: 'name', label: 'Name', minWidth: 150 },
  { id: 'cluster', label: 'Cluster Name' },
  { id: 'status', label: 'Status', maxWidth: 75 },
  { id: '', width: 150 }
]; */

export default function StepDetailTable({ title, columns, rows }) {
  const renderCell = (row, column) => {
    switch (column.id) {
      /* case 'name':
        return (
          <TableRowCellStyle key={column.id} sx={{ ...extractKeys(column, 'minWidth', 'width') }}>
            <Typography variant="subtitle2">{row[column.id]}</Typography>
          </TableRowCellStyle>
        ); */
      default:
        return (
          <TableRowCellStyle
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {row[column.id] || "-"}
            </Typography>
          </TableRowCellStyle>
        );
    }
  };

  return (
    <Box>
      <Typography
        variant="body2"
        sx={{ color: (theme) => theme.palette.text.secondary, mb: 2, ml: 2.5 }}
      >
        {title}
      </Typography>
      <Card sx={{ bgcolor: "background.neutral", mx: 2 }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                {columns.map((item) => (
                  <TableHeaderCellStyle>{item.label}</TableHeaderCellStyle>
                ))}
              </TableRow>
              {rows.length > 0 ? (
                rows.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((column) => renderCell(row, column))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                    <Typography gutterBottom align="center" variant="body2">
                      There is no content.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Box>
  );
}

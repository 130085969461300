/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import {
  Drawer,
  Stack,
  Typography,
  Divider,
  Grid,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Menu,
  MenuItem,
  Popover,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Scrollbar from "@/components/project/Scrollbar";
import { dispatch, useSelector, RootState } from "@/redux/store";
import { useSnackbar } from "notistack";
import {
  editFeatureSetVersion,
  editFeatureSet,
} from "@/redux/project/dataAsset/featureStore/thunks";
import ConfirmDialog from "@/components/project/ConfirmDialog";

interface IVersionDetailDrawerProps {
  isOpen: boolean;
  onClose: VoidFunction;
  fetchVersionData: VoidFunction;
  featuresetDetail: Record<string, any>;
  versionDetail: Record<string, any>;
}

const VersionDetailDrawer = ({
  isOpen,
  onClose,
  fetchVersionData,
  featuresetDetail,
  versionDetail,
}: IVersionDetailDrawerProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);
  const {
    connectionDatas: { data: connectionData },
    featureSetVersionDatas: {
      data: { items: versionList = [] },
    },
  } = useSelector((state: RootState) => state.featureStore);
  const { userDetail } = useSelector((state: RootState) => state.common);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElPop, setAnchorElPop] = useState<null | HTMLElement>(null);
  const openPopover = Boolean(anchorElPop);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPop(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorElPop(null);
  };

  const handleState = async (type: string) => {
    try {
      if (type === "DELETED") {
        setDelLoading(true);
      }
      await dispatch(
        editFeatureSetVersion({
          id: versionDetail?.id,
          params: { featureset_version_state: type },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      );
      const isAll = versionList.every(
        (item) => item.featureset_version_state === type
      );
      if (isAll) {
        await dispatch(
          editFeatureSet({
            id: featuresetDetail?.id,
            params: { featureset_state: type },
            alertCallback: enqueueSnackbar,
            pathname,
          })
        );
      }
      await fetchVersionData();
      onClose();
      handleClose();
      if (type === "DELETED") {
        setDelLoading(false);
      }
      enqueueSnackbar(
        `${
          type === "RELEASED"
            ? "Released"
            : type === "DELETED"
            ? "Delete"
            : "Deprecate"
        } Success`,
        { variant: "success" }
      );
    } catch (err) {
      setDelLoading(false);
      throw err;
    }
  };

  const getConnectionContent = () => {
    const connectionDetails = [
      { key: "Type", value: connectionData?.connection_type },
      { key: "Scheme", value: connectionData?.connection_scheme },
      { key: "Endpoint", value: connectionData?.connection_endpoint },
      { key: "Port", value: connectionData?.connection_port },
    ];
    return (
      <>
        <Typography
          variant="body2"
          aria-owns={openPopover ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          sx={{
            color: "text.secondary",
            wordBreak: "break-word",
            cursor: "pointer",
          }}
        >
          {connectionData?.name || "-"}
        </Typography>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={openPopover}
          anchorEl={anchorElPop}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Grid
            container
            columnSpacing={1}
            sx={{ width: 200, mr: 0.5, ml: 0.5 }}
            columns={6}
          >
            {connectionDetails.map(({ key, value }) => (
              <>
                <Grid item xs={3} lg={3} key={key}>
                  <Typography variant="subtitle2" paddingY={1}>
                    {`${key}:`}
                  </Typography>
                </Grid>
                <Grid item xs={3} lg={3} key={value}>
                  <Typography
                    variant="body2"
                    paddingY={1}
                    sx={{ wordBreak: "break-word" }}
                  >
                    {value}
                  </Typography>
                </Grid>
              </>
            ))}
          </Grid>
        </Popover>
      </>
    );
  };

  const formatSize = (size: string) => {
    if (size) {
      const value = parseFloat(size);
      if (value < 1024) {
        return `${value} KB`;
      }
      if (value < 1024 * 1024) {
        return `${(value / 1024).toFixed(2)} MB`;
      }
      if (value < 1024 * 1024 * 1024) {
        return `${(value / 1024 / 1024).toFixed(2)} GB`;
      }
    }
  };
  const getVersionDetail = useCallback(() => {
    return [
      {
        name: "Properties",
        datas: {
          "Version Number": versionDetail?.featureset_version_number || "-",
          "Version State": versionDetail?.featureset_version_state || "-",
          "Version UUID": versionDetail?.external_id || "-",
          "Created At": versionDetail?.created_at || "-",
          "Created By": versionDetail?.created_by || "-",
          "Synchronized At": versionDetail?.SynchronizedAt || "-",
          "Released By": versionDetail?.ReleasedBy || "-",
          "Released At": versionDetail?.ReleasedAt || "-",
        },
      },
      {
        name: "Resource",
        datas: {
          "Resource Type": featuresetDetail?.resource_type || "-",
          "Resource Size": formatSize(versionDetail?.SizeOfContents) || "-",
          "Resource Path": featuresetDetail?.resource_path || "-",
          "Resource Connection": getConnectionContent(),
        },
      },
    ];
  }, [connectionData, openPopover]);

  useEffect(() => {
    getVersionDetail();
  }, [getVersionDetail]);

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      anchor="right"
      sx={{
        "& .MuiPaper-root": {
          width: "320px",
          bgcolor: "background.default",
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          py: 2.5,
          px: 4,
          bgcolor: "background.paper",
        }}
      >
        <Typography variant="subtitle1">Info</Typography>
        <CloseOutlinedIcon sx={{ cursor: "pointer" }} onClick={onClose} />
      </Stack>
      <Scrollbar>
        <Stack sx={{ px: 4 }}>
          <Typography variant="h6" align="center" paddingY={2}>
            {versionDetail?.name}
          </Typography>
          {getVersionDetail().map(({ name, datas }) => (
            <>
              <Divider sx={{ mb: 1, borderStyle: "dashed" }} />
              <Accordion
                defaultExpanded
                elevation={0}
                sx={{
                  width: "100% !important",
                  boxShadow: "none !important",
                  "&.Mui-expanded": { margin: 0 },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    padding: 0,
                    minHeight: "64px",
                    "& .MuiAccordionSummary-content": {
                      margin: "0 !important",
                    },
                  }}
                >
                  <Typography variant="subtitle1" paddingY={2}>
                    {name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0 5px" }}>
                  <Grid container columnSpacing={1}>
                    {Object.entries(datas).map(([key, value]) => (
                      <>
                        <Grid item xs={6} lg={6} key={key}>
                          <Typography variant="subtitle2" paddingY={1}>
                            {key}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} lg={6} key={value}>
                          {typeof value === "string" ? (
                            <Typography
                              variant="body2"
                              paddingY={1}
                              sx={{ wordBreak: "break-word" }}
                            >
                              {value}
                            </Typography>
                          ) : (
                            value
                          )}
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          ))}
        </Stack>
        {featuresetDetail?.created_by === userDetail?.user_id && (
          <>
            <Divider sx={{ mb: 2, borderStyle: "dashed" }} />
            <Box sx={{ marginBottom: "160px" }}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigate(`featureSetEdit`)}
                  disabled
                >
                  Edit
                </Button>
                <Button
                  onClick={handleClick}
                  variant="outlined"
                  endIcon={
                    <ExpandMoreOutlinedIcon
                      sx={{
                        transform: open ? "rotate(-180deg)" : "rotate(0deg)",
                        transition: "transform 0.5s",
                      }}
                    />
                  }
                  color="secondary"
                  sx={{ width: "150px" }}
                >
                  Set State
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      width: "150px",
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => handleState("RELEASED")}
                    disabled={
                      versionDetail.featureset_version_state === "RELEASED"
                    }
                  >
                    Release
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOpenConfirm(true);
                      handleClose();
                    }}
                  >
                    Mark As Delete
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleState("DEPRECATED")}
                    disabled={
                      versionDetail.featureset_version_state === "DEPRECATED"
                    }
                  >
                    Deprecate
                  </MenuItem>
                </Menu>
              </Stack>
            </Box>
          </>
        )}
      </Scrollbar>

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete feature set version{" "}
            <strong> {versionDetail?.name} </strong> item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleState("DELETED");
              setOpenConfirm(false);
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </Drawer>
  );
};

export default VersionDetailDrawer;

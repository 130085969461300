/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Drawer,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Scrollbar from "@/components/project/Scrollbar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "@/redux/store";
import { useEffect } from "react";
import { getConnectionDetail } from "@/redux/project/connection/thunks";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router";
import { resetConnectionData } from "@/redux/project/connection/slice";

interface IConnectionDetailDrawer {
  isOpen: boolean;
  onClose: VoidFunction;
  row: Record<string, any>;
}

const ConnectionDetailDrawer = ({
  isOpen,
  onClose,
  row,
}: IConnectionDetailDrawer) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const {
    connectionDetail: { data, loading },
  } = useSelector((state: RootState) => state.connection);

  const getOrderedDetail = (data) => {
    return {
      Type: data?.connection_type || "-",
      Scheme: data?.connection_scheme || "-",
      Endpoint: data?.connection_endpoint || "-",
      Port: data?.connection_port || "-",
      "Created At": data?.created_at || "-",
      "Last Modified": data?.updated_at || "-",
    };
  };

  const getOtherProperties = () => {
    return data?.connection_properties || {};
  };

  const handleInit = async () => {
    dispatch(
      getConnectionDetail({
        id: row.id,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  };

  useEffect(() => {
    if (isOpen) {
      handleInit();
    }
  }, [isOpen]);

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        onClose();
        dispatch(resetConnectionData("connectionDetail"));
      }}
      anchor="right"
      sx={{
        "& .MuiPaper-root": {
          width: "320px",
          bgcolor: "background.default",
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          py: 2.5,
          px: 4,
          bgcolor: "background.paper",
        }}
      >
        <Typography variant="subtitle1">Detail</Typography>
        {/* <Icon></Icon> */}
        <CloseOutlinedIcon sx={{ cursor: "pointer" }} onClick={onClose} />
      </Stack>
      <Scrollbar sx={{ maxHeight: "800px" }}>
        <Stack sx={{ px: 4 }}>
          <Accordion
            defaultExpanded
            elevation={0}
            sx={{
              width: "100% !important",
              boxShadow: "none !important",
              "&.Mui-expanded": { margin: 0 },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                padding: 0,
                minHeight: "64px",
                justifyContent: "end",
                "& .MuiAccordionSummary-content": {
                  margin: "0 !important",
                  transform: "translateX(-50%)",
                  position: "absolute",
                  left: "50%",
                },
              }}
            >
              <Typography variant="h6" align="center" paddingY={2}>
                {data.name}
              </Typography>
              <Divider sx={{ mb: 2.5 }} />
            </AccordionSummary>
            <Divider sx={{ mb: 2.5 }} />
            <AccordionDetails sx={{ padding: "0 5px" }}>
              <Grid container columnSpacing={1}>
                {Object.entries(getOrderedDetail(data)).map(([key, value]) => (
                  <>
                    <Grid item xs={6} lg={6}>
                      <Typography variant="subtitle2" paddingY={1}>
                        {key}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} lg={6}>
                      {loading ? (
                        <Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            py: 0.3,
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height="100%"
                            sx={{ borderRadius: 2, mt: 1, mb: 1 }}
                          />
                        </Box>
                      ) : (
                        <Typography
                          variant="body2"
                          paddingY={1}
                          sx={{ wordBreak: "break-word" }}
                        >
                          {value}
                        </Typography>
                      )}
                    </Grid>
                  </>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Divider sx={{ mt: 1.5 }} />
          <Accordion
            defaultExpanded
            elevation={0}
            sx={{
              width: "100% !important",
              boxShadow: "none !important",
              "&.Mui-expanded": {
                margin: 0,
              },
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                padding: 0,
                minHeight: "64px",
                "& .MuiAccordionSummary-content": {
                  margin: "0 !important",
                },
              }}
            >
              <Typography variant="subtitle1" paddingY={2}>
                Other Properties
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0 5px" }}>
              <Grid container columnSpacing={1}>
                {Object.entries(getOtherProperties()).map(([key, value]) => (
                  <>
                    <Grid item xs={6} lg={6}>
                      {loading ? (
                        <Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            py: 0.3,
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height="40px"
                            sx={{ borderRadius: 2, mt: 1, mb: 1 }}
                          />
                        </Box>
                      ) : (
                        <Typography variant="subtitle2" paddingY={1}>
                          {key}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6} lg={6}>
                      {loading ? (
                        <Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            py: 0.3,
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height="40px"
                            sx={{ borderRadius: 2, mt: 1, mb: 1 }}
                          />
                        </Box>
                      ) : (
                        <Typography variant="body2" paddingY={1}>
                          {value}
                        </Typography>
                      )}
                    </Grid>
                  </>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};

export default ConnectionDetailDrawer;

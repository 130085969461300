import Page from "@/components/project/Page";
import OrgTable from "./components/organizationList/OrgTable";

export default function Organization() {
  return (
    <Page>
      <OrgTable />
    </Page>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import Page from "@/components/project/Page";
import { PATH_DASHBOARD } from "@/routes/paths";
import { useLocation, useParams } from "react-router";
import OrgEditForm from "./components/organizationEdit/OrgEditForm";
import { useEffect } from "react";
import { RootState, dispatch } from "@/redux/store";
import { getOrganizationDetailData } from "@/redux/project/administration/thunks";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

const OrganizationEdit = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const { orgId } = useParams();

  const {
    organizationDetailData: { data },
  } = useSelector((state: RootState) => state.administration);

  const getLinks = () => {
    return [
      {
        name: "Administration",
      },
      {
        name: "Organization",
        href: PATH_DASHBOARD.administration.organization,
      },
      {
        name: data?.name || "",
        href: `${PATH_DASHBOARD.administration.organization}/${orgId}`,
      },
      {
        name: "Edit Organization",
      },
    ];
  };

  useEffect(() => {
    dispatch(
      getOrganizationDetailData({
        id: orgId!,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  }, []);

  return (
    <Page
      title="Edit Organization | OmniML"
      heading="Edit Organization"
      links={getLinks()}
    >
      <OrgEditForm />
    </Page>
  );
};

export default OrganizationEdit;

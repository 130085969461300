import { generateUri } from "./helper-functions";
import { StepInfo } from "@/@types/project/mlPipeline/SageMaker/pipeline";
import { FailStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-fail";

// ----------------------------------------------------------------------

export default function generateFailStep(step: FailStep) {
  return {
    Name: step.name,
    Type: step.type,
    Arguments: {
      ErrorMessage: generateUri(step.errorMessage, step.errorMessageUseParam),
    },
  } as StepInfo;
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  Stack,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Form, Field, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import MlCard from "@/components/project/mlComponents/MlCard";
import {
  editMlService,
  delRelServiceTag,
  addTag,
  addRelMlServiceTag,
  getRelTagServiceList,
  getTagList,
} from "@/redux/project/mlService/mlRuntime/thunks";
import { dispatch, useSelector, RootState } from "@/redux/store";
import { useSnackbar } from "notistack";

interface FormValues {
  name: string;
  model_package: { label: string; id: string };
  scenario: string;
  tags: string[];
  description: string;
}

const MlRuntimeEditForm = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { runtimeId = "" } = useParams();
  const allExistTags = useRef<any[]>([]);
  const {
    tagDatas: { data: tagList },
    modelPackageList: { data },
    mlRuntimeDetail: { data: serviceDetail },
  } = useSelector((state: RootState) => state.mlRuntime);
  const {
    config: { common },
  } = useSelector((state: RootState) => state.common);

  const initialValues: FormValues = {
    name: serviceDetail.name || "",
    model_package: { label: "", id: serviceDetail.mp_external_id },
    scenario: serviceDetail?.scenario || "",
    tags: [],
    description: serviceDetail?.description || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Service Nameis required")
      .max(64, "Description cannot be longer than 64 characters"),
    model_package: Yup.object().required("Model Package is required"),
  });

  useEffect(() => {
    dispatch(
      getRelTagServiceList({
        params: {
          mlservice_id: runtimeId,
          page_num: 1,
          page_size: 9999,
          is_active: 1,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    )
      .unwrap()
      .then((res) => {
        setFieldValue(
          "tags",
          res.items.map((item) => item.tag_id)
        );
        allExistTags.current = res?.items;
      });
  }, []);

  useEffect(() => {
    setFieldValue("name", serviceDetail.name);
    setFieldValue("model_package", {
      label: "",
      id: serviceDetail.mp_external_id,
    });
    setFieldValue("scenario", serviceDetail?.scenario);
    setFieldValue("description", serviceDetail.description);
  }, [serviceDetail, data]);

  const updateAppTags = async (tags) => {
    const deleteIds: string[] = [];
    const addIds: string[] = [];

    tags.forEach((item) => {
      if (allExistTags.current.findIndex((i) => i.tag_id === item) === -1) {
        addIds.push(item);
      }
    });
    allExistTags.current.forEach((item) => {
      if (!tags.includes(item.tag_id)) {
        deleteIds.push(item.tag_id);
      }
    });

    // delete
    await Promise.all(
      deleteIds.map((item) =>
        dispatch(
          delRelServiceTag({
            id: allExistTags.current.find((i) => i.tag_id === item)?.id,
            params: { is_active: 0 },
            alertCallback: enqueueSnackbar,
            pathname,
          })
        ).unwrap()
      )
    );

    // add
    await Promise.all(
      addIds.map((item) =>
        dispatch(
          addRelMlServiceTag({
            params: {
              mlservice_id: runtimeId,
              tag_id: item,
              is_active: 1,
            },
            alertCallback: enqueueSnackbar,
            pathname,
          })
        ).unwrap()
      )
    );
  };

  const handleMlServiceSubmit = (values) => {
    const params = {
      scenario: values.scenario || "",
      mp_external_id: values.model_package.id,
      description: values.description || "",
    };
    dispatch(
      editMlService({
        id: runtimeId,
        params,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      try {
        await handleMlServiceSubmit(values);
        await updateAppTags(values.tags);
        actions.setSubmitting(false);
        navigate(`/ui/mlService/mlRuntime`);
        enqueueSnackbar("Edit Success", { variant: "success" });
      } catch (err) {
        console.log("err", err);
        actions.setSubmitting(false);
      }
    },
  });

  const { errors, touched, values, isSubmitting, setFieldValue, resetForm } =
    formik;

  const handleCancel = () => {
    navigate(`/ui/mlService/mlRuntime`);
    resetForm();
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <MlCard title="Basic Information" sx={{ mt: 1.5 }}>
          <Box sx={{ pb: 2, width: "800px" }}>
            <Field
              as={TextField}
              name="name"
              label="Service Name"
              size="small"
              fullWidth
              margin="dense"
              disabled
              helperText={touched.name && errors.name}
              error={touched.name && Boolean(errors.name)}
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
            <Autocomplete
              size="small"
              fullWidth
              disablePortal
              sx={{ mt: 2 }}
              options={data?.items || []}
              getOptionLabel={(modelPackage: { name: string; id: string }) =>
                modelPackage.name
              }
              value={data?.items?.find(
                (mp) => values.model_package.id === mp.id
              )}
              onChange={(_, newValues) => {
                setFieldValue("model_package", newValues);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name="model_package"
                  label="Model Package"
                  helperText={touched.model_package && errors.model_package}
                  error={touched.model_package && Boolean(errors.model_package)}
                  FormHelperTextProps={{
                    sx: { minHeight: "18px", marginTop: "2px" },
                  }}
                />
              )}
            />
            <FormControl fullWidth margin="dense" size="small">
              <InputLabel>{`Scenario(Optional)`}</InputLabel>
              <Field as={Select} name="scenario" label="Scenario(Optional)">
                {common?.scenario?.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            <Autocomplete
              size="small"
              multiple
              disableCloseOnSelect
              disablePortal
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              limitTags={8}
              getOptionLabel={(option) =>
                typeof option === "object" ? option.name : option
              }
              isOptionEqualToValue={(option, value) => {
                if (typeof option === "object" && typeof value === "object") {
                  return option.id === value.id;
                } else if (
                  typeof option === "string" ||
                  typeof value === "string"
                ) {
                  return option === value;
                }
                return false;
              }}
              options={tagList?.items}
              value={values.tags?.map(
                (tagId) =>
                  tagList?.items?.find((tag) => tag.id === tagId) || tagId
              )}
              onChange={(_, newValues) => {
                const filteredValues = newValues?.filter(
                  (item) => typeof item === "string"
                );

                if (tagList?.items?.find((i) => i.name === filteredValues[0])) {
                  enqueueSnackbar(`Tag ${filteredValues[0]} existed`, {
                    variant: "warning",
                  });
                  return;
                }

                const updatedTagsPromises = newValues.map(async (item) => {
                  if (typeof item === "string") {
                    // new text ,add a new tag
                    const res = await dispatch(
                      addTag({
                        params: {
                          is_active: 1,
                          name: item,
                        },
                        alertCallback: enqueueSnackbar,
                        pathname,
                      })
                    ).unwrap();

                    enqueueSnackbar(`Add Tag ${item} Success`, {
                      variant: "success",
                    });

                    await dispatch(
                      getTagList({
                        params: {
                          is_active: 1,
                          page_num: 1,
                          page_size: 9999,
                        },
                        alertCallback: enqueueSnackbar,
                        pathname,
                      })
                    );

                    return res.id;
                  }
                  return item.id;
                });

                Promise.all(updatedTagsPromises).then((r) => {
                  setFieldValue("tags", r);
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="tags"
                  label="Tags (Optional)"
                  helperText=" "
                  FormHelperTextProps={{
                    style: {
                      minHeight: "18px",
                      marginTop: "2px",
                    },
                  }}
                />
              )}
              sx={{ mt: 1, mb: 0.5 }}
            />
            <Field
              as={TextField}
              multiline
              rows={4}
              name="description"
              label="Description (Optional)"
              size="small"
              fullWidth
              margin="dense"
            />
          </Box>
        </MlCard>

        <Stack
          spacing={2}
          direction="row"
          justifyContent="center"
          sx={{ my: 4 }}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ width: "200px", color: "background.paper" }}
          >
            Save
          </LoadingButton>
          <Button
            type="button"
            color="inherit"
            variant="outlined"
            sx={{ width: "200px", color: "text.secondary" }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default MlRuntimeEditForm;

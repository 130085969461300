import {
  getModelPackageDatas,
  getModelPackageVersionDatas,
  getModelPackageDescribeDatas,
  getModelPackageVersionDescribeDatas,
  getConnectionDatasById,
  getModelPackageTagDatas,
  getTagDatas,
  getTagDataById,
  getConnectionDatas,
  createModelPackage,
  createTag,
  createRelTagModelPackage,
  createSynchronization,
  createSynchronizationJob,
  getProjectDatas,
  createRelProjectModelPackage,
  getSynchronizationDatas,
  updateModelPackage,
  updateSynchronization,
  updateSynchronizationJob,
  deleteRelTagModelPackage,
  updateModelPackageVersion,
  getStatisticDatas,
  getCredentialDatas,
  getRelUserModelPackage,
  getModelPackageVersionAttribute,
  getSynchronizationJobDatas,
  uploadFile,
  createModelPackageVersion,
  getHashToken,
  getSynchronizationJobExecutionVersion,
  getSynchronizationJobExecutionById,
} from "@/services/modelRegistry";
import { checkCode, handleErrorMessage } from "@/utils/project/request";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { MlParams } from "@/@types/project/global";

// model package
interface IGetModelPackageParams extends MlParams {
  jwt_id: string;
  jwt_user_id: string;
  params: {
    page_num: number;
    page_size: number;
    delivered_to?: string;
    created_by?: string;
    modelpackage_state?: string;
    granted?: string;
  };
  isList?: boolean;
}
export const getModelPackageList = createAsyncThunk(
  "modelRegistry/getModelPackageList",
  async (
    {
      jwt_id,
      jwt_user_id,
      params,
      isList,
      alertCallback,
      pathname,
    }: IGetModelPackageParams,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await getModelPackageDatas(params);
      const res = checkCode(data, pathname) as {
        items: Array<Object & { id: string; created_by: string }>;
      };
      if (isList) {
        return res;
      }

      // get jwt.user_id = created_by -> yes
      // jwt.id = rel_userid -> yes and then no
      let modelPackageList = (await Promise.allSettled(
        res.items?.map(async (item) => {
          if (jwt_user_id === item.created_by) {
            return { ...item, granted: "Yes" };
          } else {
            const grantedInfo = await dispatch(
              getRelUserModelPackageList({
                params: {
                  modelpackage_id: item.id,
                  page_num: 1,
                  page_size: 9999,
                  user_id: jwt_id,
                },
                alertCallback,
                pathname,
              })
            ).unwrap();

            return {
              ...item,
              granted: grantedInfo?.items?.length > 0 ? "Yes" : "No",
            };
          }
        })
      )) as Array<{ value: Record<any, any> }>;

      if (params.granted) {
        modelPackageList = modelPackageList.filter(
          (item) => item.value.granted === params.granted
        );
      }

      const lastRes = {
        ...res,
        items: modelPackageList
          .filter((item) => item.value.modelpackage_state !== "DELETED")
          .map((item) => item.value),
      };

      return lastRes;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Model Package failed");
    }
  }
);
interface ICreateModelPackageParams extends MlParams {
  params: Record<string, any>;
}
export const addModelPackage = createAsyncThunk(
  "modelRegistry/addModelPackage",
  async (
    { params, alertCallback, pathname }: ICreateModelPackageParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createModelPackage({ payload: params });
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Creating Model Package failed");
    }
  }
);
interface IGetModelPackageDescribeParams extends MlParams {
  id: string;
}
export const getModelPackageDescribe = createAsyncThunk(
  "modelRegistry/getModelPackageDescribe",
  async (
    { id, alertCallback, pathname }: IGetModelPackageDescribeParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getModelPackageDescribeDatas(id);
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Model Package Detail failed");
    }
  }
);
interface IUpdateModelPackageParams extends MlParams {
  id: string;
  params: Record<string, any>;
}
export const editModelPackage = createAsyncThunk(
  "modelRegistry/editModelPackage",
  async (
    { id, params, alertCallback, pathname }: IUpdateModelPackageParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await updateModelPackage(id, { payload: params });
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Update Model Package failed");
    }
  }
);

// version
interface IGetModelPackageVersionParams extends MlParams {
  isAll?: boolean;
  params: {
    page_num: number;
    page_size: number;
    name?: string;
    external_id?: string;
    modelpackage_id?: string;
  };
  isList?: boolean;
}
export const getModelPackageVersionList = createAsyncThunk(
  "modelRegistry/getModelPackageVersionList",
  async (
    {
      isAll,
      params,
      isList,
      alertCallback,
      pathname,
    }: IGetModelPackageVersionParams,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await getModelPackageVersionDatas(params);
      const res = checkCode(data, pathname);
      if (isList) {
        return res;
      }

      let versionAndAttributeList = (await Promise.allSettled(
        res.items?.map(async (item) => {
          const versionInfo = await dispatch(
            getMPVersionAttributeList({
              params: {
                page_num: 1,
                page_size: 9999,
                modelpackage_version_id: item.id,
                attribute_family: "Default",
              },
              alertCallback,
              pathname,
            })
          ).unwrap();

          const attributeInfo = {};
          // eslint-disable-next-line array-callback-return
          versionInfo.items.map((item) => {
            attributeInfo[item.name] = item.attribute_value;
          });
          return {
            ...item,
            ...attributeInfo,
          };
        })
      )) as Array<{ value: Record<any, any> }>;

      const lastRes = {
        ...res,
        items: isAll
          ? versionAndAttributeList.map((item) => item.value)
          : versionAndAttributeList
              .filter(
                (item) => item.value.modelpackage_version_state !== "DELETED"
              )
              .map((item) => item.value),
      };
      lastRes?.items?.sort(
        (pre, next) =>
          next.modelpackage_version_number - pre.modelpackage_version_number
      );

      return lastRes;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Model Package Version failed");
    }
  }
);
interface IGetModelPackageVersionDescribeParams extends MlParams {
  params: {
    id: string;
  };
}
export const getModelPackageVersionDescribeList = createAsyncThunk(
  "modelRegistry/getModelPackageVersionDescribeList",
  async (
    { params, alertCallback, pathname }: IGetModelPackageVersionDescribeParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getModelPackageVersionDescribeDatas(params);
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Model Package Version Detail failed");
    }
  }
);
interface IUpdateModelPackageVersionParams extends MlParams {
  id: string;
  params: Record<string, any>;
}
export const editModelPackageVersion = createAsyncThunk(
  "modelRegistry/editModelPackageVersion",
  async (
    { id, params, alertCallback, pathname }: IUpdateModelPackageVersionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await updateModelPackageVersion(id, { payload: params });
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Update Model Package Version failed");
    }
  }
);
interface IGetMPVersionAttributeParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
    modelpackage_version_id?: string;
    attribute_family?: string;
  };
}
export const getMPVersionAttributeList = createAsyncThunk(
  "modelRegistry/getMPVersionAttributeList",
  async (
    { params, alertCallback, pathname }: IGetMPVersionAttributeParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getModelPackageVersionAttribute(params);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Model Package Version Attribute failed");
    }
  }
);
interface ICreateModelPackageVersionParams extends MlParams {
  params: Record<string, any>;
}
export const addModelPackageVersion = createAsyncThunk(
  "modelRegistry/addModelPackageVersion",
  async (
    { params, alertCallback, pathname }: ICreateModelPackageVersionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createModelPackageVersion({ payload: params });
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Creating model package version failed");
    }
  }
);

// connection
interface IGetConnectionParams extends MlParams {
  id: string;
}
export const getConnectionById = createAsyncThunk(
  "modelRegistry/getConnectionById",
  async (
    { id, alertCallback, pathname }: IGetConnectionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getConnectionDatasById(id);
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Resource Connection failed");
    }
  }
);
interface IConnectionListParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
    is_active: number;
  };
}
export const getConnectionList = createAsyncThunk(
  "modelRegistry/getConnectionList",
  async (
    { params, alertCallback, pathname }: IConnectionListParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getConnectionDatas(params);
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Connection List failed");
    }
  }
);

// rel_tag_modelPackage
interface IGetModelPackageTagListParams extends MlParams {
  params: {
    modelpackage_id: string | undefined;
    page_num: number;
    page_size: number;
    is_active: number;
  };
}
export const getModelPackageTagList = createAsyncThunk(
  "modelRegistry/getModelPackageTagList",
  async (
    { params, alertCallback, pathname }: IGetModelPackageTagListParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getModelPackageTagDatas(params);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Model Package Tag failed");
    }
  }
);
interface ICreateRelTagModelPackageParams extends MlParams {
  params: Record<string, any>;
}
export const addRelTagModelPackage = createAsyncThunk(
  "modelRegistry/addRelTagModelPackage",
  async (
    { params, alertCallback, pathname }: ICreateRelTagModelPackageParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createRelTagModelPackage({ payload: params });
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Creating Tag and Model Package Relation failed");
    }
  }
);
interface IDelRelTagModelPackageParams extends MlParams {
  id: string;
  params: Record<string, any>;
}
export const delRelTagModelPackage = createAsyncThunk(
  "modelRegistry/delRelTagModelPackage",
  async (
    { id, params, alertCallback, pathname }: IDelRelTagModelPackageParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await deleteRelTagModelPackage(id, { payload: params });
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete tag and modelpackage relation failed");
    }
  }
);

// tag
interface IGetTagListParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
    is_active: number;
  };
}
export const getTagList = createAsyncThunk(
  "modelRegistry/getTagList",
  async (
    { params, alertCallback, pathname }: IGetTagListParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getTagDatas(params);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Tags failed");
    }
  }
);
interface IGetTagParams extends MlParams {
  id: string;
}
export const getTagById = createAsyncThunk(
  "modelRegistry/getTagById",
  async (
    { id, alertCallback, pathname }: IGetTagParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getTagDataById(id);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Tags failed");
    }
  }
);
interface ICreateTagParams extends MlParams {
  params: Record<string, any>;
}
export const addTag = createAsyncThunk(
  "modelRegistry/addTag",
  async (
    { params, alertCallback, pathname }: ICreateTagParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createTag({ payload: params });
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Creating Tag failed");
    }
  }
);

// Synchronization
interface ICreateSynchronizationParams extends MlParams {
  params: Record<string, any>;
}
export const addSynchronization = createAsyncThunk(
  "modelRegistry/addSynchronization",
  async (
    { params, alertCallback, pathname }: ICreateSynchronizationParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createSynchronization({ payload: params });
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Creating Synchronization failed");
    }
  }
);
interface ICreateSynchronizationJobParams extends MlParams {
  params: Record<string, any>;
}
export const addSynchronizationJob = createAsyncThunk(
  "modelRegistry/addSynchronizationJob",
  async (
    { params, alertCallback, pathname }: ICreateSynchronizationJobParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createSynchronizationJob({ payload: params });
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Creating Synchronization job failed");
    }
  }
);
interface ISynchronizationListParams extends MlParams {
  params: {
    page_num?: number;
    page_size?: number;
    modelpackage_id: string;
    synchronization_state: string;
  };
}
export const getSynchronizationList = createAsyncThunk(
  "modelRegistry/getSynchronizationList",
  async (
    { params, alertCallback, pathname }: ISynchronizationListParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getSynchronizationDatas(params);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Synchronization List failed");
    }
  }
);
interface ISynchronizationJobListParams extends MlParams {
  params: {
    page_num?: number;
    page_size?: number;
    synchronization_id: string;
  };
}
export const getSynchronizationJobList = createAsyncThunk(
  "modelRegistry/getSynchronizationJobList",
  async (
    { params, alertCallback, pathname }: ISynchronizationJobListParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getSynchronizationJobDatas(params);
      const res = checkCode(data, pathname);
      return {
        ...res,
        items: res?.items?.filter((item) => item.status !== "deleted"),
      };
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching SynchronizationJob List failed");
    }
  }
);
interface IUpdateSynchronizationParams extends MlParams {
  id: string;
  params: Record<string, any>;
}
export const editSynchronization = createAsyncThunk(
  "modelRegistry/editSynchronization",
  async (
    { id, params, alertCallback, pathname }: IUpdateSynchronizationParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await updateSynchronization(id, { payload: params });
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Update synchronization failed");
    }
  }
);
interface IUpdateSynchronizationJobParams extends MlParams {
  id: string;
  params: Record<string, any>;
}
export const editSynchronizationJob = createAsyncThunk(
  "modelRegistry/editSynchronizationJob",
  async (
    { id, params, alertCallback, pathname }: IUpdateSynchronizationJobParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await updateSynchronizationJob(id, { payload: params });
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Update synchronization job failed");
    }
  }
);

// project
interface IProjectListParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
  };
}
export const getProjectList = createAsyncThunk(
  "modelRegistry/getProjectList",
  async (
    { params, alertCallback, pathname }: IProjectListParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getProjectDatas(params);
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Project List failed");
    }
  }
);
interface IAddRelProjectModelPackageParams extends MlParams {
  params: Record<string, any>;
}
export const addRelProjectModelPackage = createAsyncThunk(
  "modelRegistry/addRelProjectModelPackage",
  async (
    { params, alertCallback, pathname }: IAddRelProjectModelPackageParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createRelProjectModelPackage({ payload: params });
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue(
        "Creating Project and Model Package Relation failed"
      );
    }
  }
);

// statistic
interface IStatisticListParams extends MlParams {
  params: {
    name?: string;
    page_num?: number;
    page_size?: number;
  };
}
export const getStatisticList = createAsyncThunk(
  "modelRegistry/getStatisticList",
  async (
    { params, alertCallback, pathname }: IStatisticListParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getStatisticDatas(params);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetch statistic failed");
    }
  }
);

// credential
interface IGetCredentialParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
    is_active: number;
  };
}
export const getCredentialList = createAsyncThunk(
  "modelRegistry/getCredentialList",
  async (
    { params, pathname, alertCallback }: IGetCredentialParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getCredentialDatas(params);
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching credential data failed");
    }
  }
);

// rel_user_modelpcakge
interface IGetRelUserModelPackageParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
    user_id: string;
    modelpackage_id: string;
  };
}
export const getRelUserModelPackageList = createAsyncThunk(
  "modelRegistry/getRelUserModelPackageList",
  async (
    { params, alertCallback, pathname }: IGetRelUserModelPackageParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getRelUserModelPackage(params);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetch rel_user_modelpackage failed");
    }
  }
);

interface IUploadFileParams extends MlParams {
  formData: FormData;
}
export const uploadFiles = createAsyncThunk(
  "modelRegistry/uploadFiles",
  async (
    { formData, alertCallback, pathname }: IUploadFileParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await uploadFile(formData);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Upload files failed");
    }
  }
);

interface IGetHashTokenParams extends MlParams {
  id: string;
}
export const getToken = createAsyncThunk(
  "modelRegistry/getToken",
  async (
    { id, alertCallback, pathname }: IGetHashTokenParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getHashToken(id);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetch hash token failed");
    }
  }
);

interface IGetSynchronizationJobExecutionVersionParams extends MlParams {
  params: {
    page_num: number;
    page_size: number;
    modelpackage_version_id: string;
  };
}
export const getSynchronizationJobExecutionVersionList = createAsyncThunk(
  "modelRegistry/getSynchronizationJobExecutionVersionList",
  async (
    {
      params,
      alertCallback,
      pathname,
    }: IGetSynchronizationJobExecutionVersionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getSynchronizationJobExecutionVersion(params);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue(
        "Fetch synchronization job execution model package version failed"
      );
    }
  }
);

interface IGetSynchronizationJobExecutionParams extends MlParams {
  id: string;
}
export const getSynchronizationJobExecutionDataById = createAsyncThunk(
  "modelRegistry/getSynchronizationJobExecutionDataById",
  async (
    { id, alertCallback, pathname }: IGetSynchronizationJobExecutionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getSynchronizationJobExecutionById(id);
      const res = checkCode(data, pathname) as Object;
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetch synchronization job execution failed");
    }
  }
);

import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import { TOKEN_FLAG } from "@/config";
import ExternlRedirect from "@/components/project/ExternalRedirect";
import { decodeJWT } from "@/utils/project/utils";
import { dispatch } from "@/redux/store";
import { setCommonData, setUserDetailData } from "@/redux/project/common/slice";
import { getUserInfo } from "@/redux/project/common/thunks";
import {
  filterAdminSiderConfig,
  filterSiderbarConfigByPermissions,
  findMainPage,
} from "@/layouts/project/utils";
import sidebarConfig from "@/layouts/project/sidebar/SidebarConfig";
import { useAuth, hasAuthParams } from "react-oidc-context";

const parseUserData = (userData) => {
  // set the isAdmin flag
  // super admin has 'sys_admin' or 'sys_ops'
  // admin user only has 'organization_id'
  // platform user has 'organization_id' and 'project_id'
  const { sys_admin, sys_ops, organization_id, project_id } = userData;

  const isSuperAdmin: boolean = Boolean(sys_admin || sys_ops);

  const isAdmin: boolean = Boolean(
    !project_id && (organization_id || isSuperAdmin)
  ); // none_user with nothing

  // no organization_id and no project_id
  const isNoneUser: boolean =
    !organization_id && !project_id && !sys_admin && !sys_ops;

  return { isSuperAdmin, isAdmin, isNoneUser };
};

export const initToken = (token: string) => {
  const userData = decodeJWT(token);
  // must first execute the synchronous function
  dispatch(setUserDetailData(userData));

  const { isSuperAdmin, isAdmin, isNoneUser } = parseUserData(userData);

  dispatch(setCommonData({ isAdmin, isSuperAdmin, isNoneUser }));
};

const initUserInfo = async () => {
  // asynchronous function
  const userInfo = await dispatch(getUserInfo()).unwrap();

  dispatch(setUserDetailData(userInfo));
};

const initUser = async (token) => {
  initToken(token);
  await initUserInfo();
};

interface IAuthorityGuardProps {
  globalConfig?: Record<any, any>;
  children: JSX.Element;
  requiredPermissions?: any[];
}

// Here to judge the page permission, show the 403 if no
// token judgment should be in the <InitAppGuard />

// disable re-render, is a interceptor
const CookieAuthorityGuard = ({
  children,
  globalConfig,
}: IAuthorityGuardProps) => {
  const token = localStorage.getItem(TOKEN_FLAG);
  const cookieToken = Cookies.get(TOKEN_FLAG); // needn't set path, can get the cookie correctly

  if (token) {
    // initUser(token);// when dev upload, must mark this row
    // token existed

    // Page Permission must be handled in <Page />
    return children;
  } else {
    if (cookieToken) {
      // first enter to get the token in the cookie
      initUser(cookieToken);

      localStorage.setItem(TOKEN_FLAG, cookieToken);
      Cookies.remove(TOKEN_FLAG, { path: "/ui/" });

      const userData = decodeJWT(cookieToken);
      const { isAdmin, isNoneUser } = parseUserData(userData);

      // homepage
      const targetPath = findMainPage(
        isAdmin,
        filterSiderbarConfigByPermissions(
          filterAdminSiderConfig(isAdmin, isNoneUser, sidebarConfig),
          userData.permissions
        )
      );

      return <Navigate to={targetPath!} replace />;
    } else {
      // no token and no cookie, redirect to the ssoLogin
      return <ExternlRedirect to={globalConfig?.common?.SSO_LOGOUT_URL} />;
      // return <Navigate to='/ui/login' replace />;
    }
  }
};

const OAuthAuthorityGuard = ({
  children,
  globalConfig,
}: IAuthorityGuardProps) => {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  // automatically sign-in
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    } else {
      if (auth.isAuthenticated) {
        const token = auth.user?.access_token;
        initUser(token);

        localStorage.setItem(TOKEN_FLAG, token);
        // Cookies.remove(TOKEN_FLAG, { path: '/ui/' });

        const userData = decodeJWT(token);
        const { isAdmin, isNoneUser } = parseUserData(userData);
      }
    }
  }, [auth, hasTriedSignin]);

  if (auth.isLoading) {
    return <div>Signing you in/out...</div>;
  }

  if (!auth.isAuthenticated) {
    return <div>Unable to log in</div>;
  }
  return children;
};

const AuthorityGuard = ({ children, globalConfig }: IAuthorityGuardProps) => {
  // return <CookieAuthorityGuard children={children} globalConfig={globalConfig} />;
  return (
    <OAuthAuthorityGuard children={children} globalConfig={globalConfig} />
  );
};

export default AuthorityGuard;

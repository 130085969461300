import { useState } from "react";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { Stack, Collapse } from "@mui/material";
// utils
import { updateClarifyBiasConfig } from "../../../graph/slices/slice";
// components
import {
  ClarifyCheckStep,
  ClarifyCheckBiasConfigStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-clarify-check";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { PipelineTextfield } from "../../components/PipelineComponents";

// ----------------------------------------------------------------------

type Props = {
  currentStep: ClarifyCheckStep | null;
};

export default function BiasConfig({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [showProcessor, setShowProcessorr] = useState(false);

  const formik = useFormik<ClarifyCheckBiasConfigStruct>({
    initialValues: {
      labelValuesOrThreshold:
        currentStep?.biasConfig.labelValuesOrThreshold || "",
      facetName: currentStep?.biasConfig.facetName || "",
      facetValuesOrThreshold:
        currentStep?.biasConfig.facetValuesOrThreshold || "",
      groupName: currentStep?.biasConfig.groupName || "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      try {
        dispatch(updateClarifyBiasConfig(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { handleSubmit } = formik;

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setShowProcessorr((prev) => !prev)}
        endIcon={
          <Icon
            icon={showProcessor ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
      >
        Bias Config
      </CollapseButtonStyle>

      <Collapse in={showProcessor} sx={{ mx: 2 }}>
        <Stack spacing={1} sx={{ my: 1.2, mb: 1.5 }}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onChange={handleSubmit}>
              <Stack spacing={2} sx={{ mb: 0.5 }}>
                <PipelineTextfield
                  label={"Label values or threshold"}
                  field={"labelValuesOrThreshold"}
                  placeholder={"string or int"}
                  formik={formik}
                />

                <PipelineTextfield
                  label={"Facet name"}
                  field={"facetName"}
                  placeholder={"string or int"}
                  formik={formik}
                />

                <PipelineTextfield
                  label={"Facet values or threshold"}
                  field={"facetValuesOrThreshold"}
                  placeholder={"float"}
                  formik={formik}
                />

                <PipelineTextfield
                  label={"Group name"}
                  field={"groupName"}
                  placeholder={"string (optional)"}
                  formik={formik}
                />
              </Stack>
            </Form>
          </FormikProvider>
        </Stack>
      </Collapse>
    </>
  );
}

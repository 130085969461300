import { useRef, useState } from "react";
import { Icon } from "@iconify/react";
import bxsTagAlt from "@iconify/icons-bx/bxs-tag-alt";
import plusFill from "@iconify/icons-eva/plus-fill";
// material
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
// redux
import { useDispatch, useSelector } from "react-redux";
// components
import Scrollbar from "@/components/project/Scrollbar";
import MenuPopover from "@/components/project/MenuPopover";
import { IconButton } from "@mui/material";
// graph
import { updateNodeEntity } from "../graph/slices/slice";
import { RootState } from "@/redux/rootReducer";

// ----------------------------------------------------------------------

type StepButtonProps = {
  title: string;
  color: string;
  addStep: Function;
};

// Kubeflow
export const kubeflowStepTypeTitles = [
  "Processing",
  "FeatureSetUpload",
  "RegisterModel",
  "FeatureSetDownload",
  "Training",
];

const kubeflowStepTypeColorList = [
  "#fc880d",
  "#a5f3ff",
  "#aaf27f",
  "#8cb4fc",
  "#44d7b6",
];

let kubeflowStepTypeColorPairs = new Map<string, string>();
[...Array(kubeflowStepTypeTitles.length)].map((_, index) =>
  kubeflowStepTypeColorPairs.set(
    kubeflowStepTypeTitles[index],
    kubeflowStepTypeColorList[index]
  )
);

export const kubeflowStepTypeColorMapper = Object.fromEntries(
  kubeflowStepTypeColorPairs
);

// SageMaker
// just for parseXmlProcessingStep
export const stepTypeTitles = [
  "Processing",
  "Condition",
  // "AutoML",
  "RegisterModel",
  "Training",
  "Transform",
  "Model",
  "Tuning",
  "Lambda",
  "ClarifyCheck",
  "Fail",
];

const stepTypeColorList = [
  "#0094A8",
  "#F393BA",
  // "#F7B500",
  "#F7B500",
  "#FC880D",
  "#9E86FF",
  "#44D7B6",
  "#FFA48D",
  "#AAF27F",
  "#1890FF",
  "#FF4842",
];

let stepTypeColorPairs = new Map<string, string>();
[...Array(stepTypeTitles.length)].map((_, index) =>
  stepTypeColorPairs.set(stepTypeTitles[index], stepTypeColorList[index])
);

export const stepTypeColorMapper = Object.fromEntries(stepTypeColorPairs);

export default function StepPopover({ disabled }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const { pipelineType } = useSelector((state: RootState) => state.schema);

  const btnTitleList =
    pipelineType === "Kubeflow" ? kubeflowStepTypeTitles : stepTypeTitles;
  const btnColorList =
    pipelineType === "Kubeflow" ? kubeflowStepTypeColorList : stepTypeColorList;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getStepButton = ({ title, color, addStep }: StepButtonProps) => {
    return (
      <Button
        fullWidth
        startIcon={<Icon icon={bxsTagAlt} color={color} />}
        sx={{ color: "text.primary", justifyContent: "flex-start", pl: 2 }}
        onClick={() => {
          addStep(title);
        }}
      >
        <Typography variant="body1" sx={{ color: "text.primary" }}>
          {title}
        </Typography>
      </Button>
    );
  };

  const buttonInfos = [...Array(Math.ceil(btnTitleList.length / 2))].map(
    (_, index) => {
      return 2 * index + 1 === btnTitleList.length
        ? [
            {
              title: btnTitleList[2 * index],
              color: btnColorList[2 * index],
            },
          ]
        : [
            {
              title: btnTitleList[2 * index],
              color: btnColorList[2 * index],
            },
            {
              title: btnTitleList[2 * index + 1],
              color: btnColorList[2 * index + 1],
            },
          ];
    }
  );

  const handleAddStep = (title: string) => {
    dispatch(
      updateNodeEntity({
        id: "",
        nodeX: 100,
        nodeY: 100,
        stepType: title,
        isKubeflow: pipelineType === "Kubeflow",
      })
    );
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        size="small"
        disabled={disabled}
        color={open ? "primary" : "default"}
        onClick={handleOpen}
      >
        <Icon icon={plusFill} width={24} height={24} />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          width: pipelineType === "Kubeflow" ? 425 : 350,
          ml: 1,
          mt: 1,
          p: 0,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Pipeline Steps</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {`${btnTitleList.length} types of pipeline steps`}
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: "auto", sm: "auto" }, mx: 1 }}>
          {buttonInfos.map((buttonInfo, index) => (
            <Grid
              container
              direction="row"
              spacing={0.5}
              sx={{ my: 1 }}
              key={index}
            >
              <Grid item xs={6} lg={6}>
                {getStepButton({
                  title: buttonInfo[0]?.title,
                  color: buttonInfo[0]?.color,
                  addStep: handleAddStep,
                })}
              </Grid>
              <Grid item xs={6} lg={6}>
                {buttonInfo[1] &&
                  getStepButton({
                    title: buttonInfo[1]?.title,
                    color: buttonInfo[1]?.color,
                    addStep: handleAddStep,
                  })}
              </Grid>
            </Grid>
          ))}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

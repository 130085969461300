import { Icon } from "@iconify/react";
import moonFill from "@iconify/icons-eva/moon-fill";
import sunFill from "@iconify/icons-eva/sun-fill";
// material
import { MIconButton } from "@/components/project/@material-extend";
// hooks
import useSettings from "@/hooks/project/useSettings";

// ----------------------------------------------------------------------

export default function ThemeToggleBtn() {
  const { themeMode, onToggleMode } = useSettings();
  return (
    <MIconButton
      onClick={onToggleMode}
      title={themeMode ? "Light" : "Dark"}
      sx={{
        padding: 0,
        width: 44,
        height: 44,
        // color: "primary.main",
        color: "default",
      }}
    >
      <Icon icon={themeMode ? sunFill : moonFill} width={22} height={22} />
    </MIconButton>
  );
}

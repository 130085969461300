import MlIframe from "@/components/project/mlComponents/MlIframe";
import { IFRAME_URL } from "@/config";
import { useSearchParams } from "react-router-dom";

export default function Swagger() {
  const [searchParams] = useSearchParams();

  const getIframeSrc = () => {
    const swaggerType = searchParams.get("type");
    return IFRAME_URL.swagger[swaggerType!];
  };

  return <MlIframe src={getIframeSrc()} />;
}

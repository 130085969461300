// material
import { Select } from "@mui/material";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { Field, FieldProps } from "formik";
import { debounce } from "lodash";

// ----------------------------------------------------------------------

type PropsPipelineSelect = TextFieldProps & {
  label: string;
  field: string;
  placeholder?: string;
  options: { code: string; label: string }[];
  getFieldProps?: any;
  isUsedMuiMenuItem?: boolean;
};

export function PipelineSelect({
  label,
  field,
  placeholder,
  options,
  getFieldProps,
  isUsedMuiMenuItem = false,
  ...other
}: PropsPipelineSelect) {
  return (
    <>
      <StyledTextField
        select
        label={label}
        placeholder={placeholder}
        {...getFieldProps(field)}
        SelectProps={{
          native: !isUsedMuiMenuItem,
          MenuProps: {
            // disablePortal: true
            sx: { zIndex: 2999 },
          },
        }}
        {...other}
      >
        {isUsedMuiMenuItem ? (
          options.map((option) => (
            <MenuItem key={option.code} value={option.label}>
              {option.label}
            </MenuItem>
          ))
        ) : (
          <>
            <option aria-label="None" value="" />
            {options.map((option) => (
              <option key={option.code} value={option.label}>
                {option.label}
              </option>
            ))}
          </>
        )}
      </StyledTextField>
    </>
  );
}

type MlPropsPipelineSelect = TextFieldProps & {
  label: string;
  field: string;
  placeholder?: string;
  options: { code: string; label: string }[];
  formik;
};

export function MlPipelineSelect({
  label,
  field,
  placeholder,
  formik,
  // children options in other
  ...other
}: MlPropsPipelineSelect) {
  const { errors, touched, getFieldProps } = formik;
  return (
    <StyledTextField
      select
      placeholder={placeholder}
      label={label}
      SelectProps={{
        MenuProps: {
          // disablePortal: true
          sx: { zIndex: 2999 },
        },
      }}
      error={touched[field] && Boolean(errors[field])}
      helperText={touched[field] && errors[field]}
      {...other}
    />
  );
}

type PipelineTextfieldProps = TextFieldProps & {
  label: string;
  field: string;
  placeholder?: string;
  formik: any;
};

export function PipelineTextfield({
  label,
  field,
  placeholder,
  formik,
  ...other
}: PipelineTextfieldProps) {
  const { errors, touched, getFieldProps } = formik;

  // touched set to all true, caused by the submit triggered
  return (
    <>
      <StyledTextField
        label={label}
        placeholder={placeholder}
        {...getFieldProps(field)}
        error={touched[field] && Boolean(errors[field])}
        helperText={touched[field] && errors[field]}
        {...other}
      />
    </>
  );
}

type MlPipelineTextfieldProps = TextFieldProps & {
  label: string;
  field: string;
  formik: any;
  submitThisValuefunc?: any;
};
export function MlPipelineTextfield({
  label,
  field,
  formik,
  submitThisValuefunc,
  ...other
}: MlPipelineTextfieldProps) {
  const { values, errors, touched, setFieldValue, setFieldTouched } = formik;

  /*   const debounceSubmit = debounce((value) => {
    submitThisValuefunc(value);
  }, 500); */

  return (
    <>
      <StyledTextField
        label={label}
        value={values[field]}
        SelectProps={{
          MenuProps: {
            // disablePortal: true,
            sx: { zIndex: 2999, maxHeight: 220, maxWidth: 200 },
          },
        }}
        onChange={(e) => {
          setFieldValue(field, e.target.value);
          // submit this field value whether errors exist or not
          // debounceSubmit(e.target.value);
          submitThisValuefunc(e.target.value);
        }}
        onBlur={() => {
          setFieldTouched(field, true);
        }}
        error={touched[field] && Boolean(errors[field])}
        helperText={touched[field] && errors[field]}
        {...other}
      />
    </>
  );
}

// no validate error
export function StyledTextField({
  label = "",
  placeholder = "",
  ...other
}: TextFieldProps) {
  return (
    <TextField
      fullWidth
      size="small"
      label={label}
      placeholder={placeholder}
      inputProps={{ style: { fontSize: "14px" } }}
      InputLabelProps={{ style: { fontSize: "15px" } }}
      sx={{
        "& .MuiOutlinedInput-notchedOutline > legend": {
          fontSize: "11px",
        },
      }}
      {...other}
    />
  );
}

// the components in formik
// not used yet
type FormikFieldProps = FieldProps & {
  label: string;
  field: string;
  placeholder?: string;
  formik: any;
  isSelect?: boolean;
};

export function FormikField({
  label,
  field,
  formik,
  isSelect = false,
  ...other
}: FormikFieldProps) {
  const { errors, touched } = formik;
  return isSelect ? (
    <FormControl
      fullWidth
      margin="dense"
      size="small"
      error={touched[field] && Boolean(errors[field])}
    >
      <InputLabel>{label}</InputLabel>
      <Field
        as={Select}
        name={field}
        label={label}
        inputProps={{ style: { fontSize: "14px" } }}
        InputLabelProps={{ style: { fontSize: "15px" } }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline > legend": {
            fontSize: "11px",
          },
        }}
        {...other}
      />
      <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
        {touched[field] && errors[field] ? errors[field] : ""}
      </FormHelperText>
    </FormControl>
  ) : (
    <Field
      as={TextField}
      name={field}
      label={label}
      size="small"
      fullWidth
      margin="dense"
      helperText={(touched[field] && errors[field]) || " "}
      error={touched[field] && Boolean(errors[field])}
      // helperText={(touched[field] && errors[field]) || ' '}
      // FormHelperTextProps={{ sx: { minHeight: '18px', marginTop: '2px' } }}
      inputProps={{ style: { fontSize: "14px" } }}
      InputLabelProps={{ style: { fontSize: "15px" } }}
      sx={{
        "& .MuiOutlinedInput-notchedOutline > legend": {
          fontSize: "11px",
        },
      }}
      {...other}
    />
  );
}

// import Page from "@/components/project/Page";
import MlIframe from "@/components/project/mlComponents/MlIframe";
import { IFRAME_URL } from "@/config";
import { RootState } from "@/redux/store";
import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";
const Monitor = () => {
  const theme = useTheme();

  const {
    userDetail: { project_id },
  } = useSelector((state: RootState) => state.common);

  return (
    <MlIframe
      src={`${IFRAME_URL.observability.grafana}&var-namespace=taichi-prj-${project_id}&theme=${theme.palette.mode}`}
    />
  );
};

export default Monitor;

import { StepInfo } from "@/@types/project/mlPipeline/SageMaker/pipeline";
import { generateUri } from "./helper-functions";
import { TransformStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-transform";

// ----------------------------------------------------------------------

export default function generateTranformStep(step: TransformStep) {
  return {
    Name: step.name,
    Type: step.type,
    Arguments: {
      ModelName: generateUri(step.modelName, step.modelNameUseParam),
      TransformInput: {
        DataSource: {
          S3DataSource: {
            S3DataType: "S3Prefix",
            S3Uri: generateUri(step.dataSource, step.dataSourceUseParam),
          },
        },
      },
      TransformOutput: {
        S3OutputPath: generateUri(step.outputPath, step.outputPathUseParam),
      },
      TransformResources: {
        InstanceCount: generateUri(
          step.instanceCount,
          step.instanceCountUseParam,
          "number"
        ),
        InstanceType: generateUri(step.instanceType, step.instanceTypeUseParam),
      },
    },
  } as StepInfo;
}

import { Icon } from "@iconify/react";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useState } from "react";
// material
import { Box, Stack, Divider, Collapse } from "@mui/material";
// @types
import { RootState, useDispatch, useSelector } from "@/redux/store";
//
import PropertyFiles from "./PropertyFiles";
import ProcessingCode from "./ProcessingCode";
import ProcessingInputs from "./ProcessingInputs";
import ProcessingOutputs from "./ProcessingOutputs";
import ProcessingNetwork from "./ProcessingNetwork";
import ProcessingProcessor from "./ProcessingProcessor";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { StyledTextField } from "../../components/PipelineComponents";
import { ProcessingStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-processing";
import {
  SchemaNodeEntity,
  updateEntityName,
} from "../../../graph/slices/slice";

// ----------------------------------------------------------------------

type ProcessingStepProps = {
  entity: SchemaNodeEntity;
};

export default function ProcessingStepEntry({ entity }: ProcessingStepProps) {
  const dispatch = useDispatch();
  const [showStepConfig, setShowStepConfig] = useState(true);

  const currentStep = useSelector((state: RootState) => {
    let step: ProcessingStep | undefined =
      state.schema.schema.pipelineSteps.find((step) => {
        return step.id === entity.id;
      }) as ProcessingStep;
    return step ? step : null;
  });

  const codeProps = currentStep?.code || {
    source: "amazon_s3",
    path: "",
    includeDir: false,
    jobArgs: [],
  };

  return (
    <>
      {entity && (
        <Box>
          <CollapseButtonStyle
            fullWidth
            color="inherit"
            onClick={() => setShowStepConfig((prev) => !prev)}
            endIcon={
              <Icon
                icon={
                  showStepConfig ? arrowIosDownwardFill : arrowIosForwardFill
                }
                width={16}
                height={16}
              />
            }
          >
            Configuration
          </CollapseButtonStyle>

          <Collapse in={showStepConfig}>
            <Stack spacing={2} sx={{ mx: 2, my: 1, mb: 1.5 }}>
              <StyledTextField
                label="Step name"
                defaultValue={entity.name}
                onChange={(event: any) => {
                  const newValue = event.target.value;
                  dispatch(updateEntityName({ name: newValue }));
                }}
              />

              <ProcessingCode data={codeProps} />
            </Stack>
          </Collapse>

          <Divider />

          <ProcessingProcessor currentStep={currentStep} />

          <Divider />

          <ProcessingInputs currentStep={currentStep} />

          <Divider />

          <ProcessingOutputs currentStep={currentStep} />

          <Divider />

          <PropertyFiles currentStep={currentStep} />

          <Divider />

          <ProcessingNetwork currentStep={currentStep} />

          <Divider sx={{ mb: 1 }} />
        </Box>
      )}
    </>
  );
}

import { isEmpty } from "lodash";
import yaml from "js-yaml";

export const generateAdvancedSettings = (org_name, data) => {
  const tempObj = {};

  return tempObj;
};

export const generateTrailThresholds = (data) => {
  return {
    parallelTrialCount: Number(data.parallelTrial),
    maxTrialCount: Number(data.maxTrails),
    maxFailedTrialCount: Number(data.maxFailedTrails),
    resumePolicy: data.resumePolicy,
  };
};

export const generateObjective = (data) => {
  return {
    type: data.type,
    goal: Number(data.goal),
    objectiveMetricName: data.metric,

    ...(!isEmpty(data.additionalMetrics)
      ? {
          additionalMetricNames: data.additionalMetrics?.map(
            (item) => item.metricName
          ),
        }
      : {}),

    ...(data.isSetMetricStrategies && !isEmpty(data.metricStrategies)
      ? {
          metricStrategies: data.metricStrategies.map((item) => ({
            name: item.metricName,
            value: item.strategy,
          })),
        }
      : {}),
  };
};

export const generateAlgorithm = (data) => {
  let settings: any = [];
  if (data.name === "random") {
    settings = [
      {
        name: "random_state",
        value: data.settings.randomState,
      },
    ];
  }

  if (data.name === "bayesianoptimization") {
    settings = [
      {
        name: "base_estimator",
        value: data.settings.baseEstimator,
      },
      {
        name: "n_initial_points",
        value: data.settings.riInitialPoints,
      },
      {
        name: "acq_func",
        value: data.settings.acqFunc,
      },
      {
        name: "acq_optimizer",
        value: data.settings.acqOptimizer,
      },
      {
        name: "random_state",
        value: data.settings.random_state,
      },
    ];
  }
  return {
    algorithmName: data.name,
    ...(data.name !== "grid" && !isEmpty(data.algorithmSettings)
      ? { algorithmSettings: settings }
      : {}),
  };
};

export const generateEarlyStopping = (data) => {
  return {
    algorithmName: data.name,
    ...(!isEmpty(data.settings)
      ? {
          algorithmSettings: [
            {
              name: "min_trials_required",
              value: data.settings.minTrialsRequired,
            },
            {
              name: "start_step",
              value: data.settings.startStep,
            },
          ],
        }
      : {}),
  };
};

export const generateParameters = (data) => {
  return data.map((item) => {
    if (["integer", "double"].includes(item.parameterType)) {
      return {
        ...item,
        feasibleSpace: {
          max: item.feasibleSpace.max,
          min: item.feasibleSpace.min,
          ...(item.feasibleSpace.step ? { step: item.feasibleSpace.step } : {}),
        },
      };
    }

    return {
      ...item,
      feasibleSpace: {
        list: item.feasibleSpace.map((i) => i.value),
      },
    };
  });
};

export const generateMetricsCollector = (data) => {
  if (data.kind === "Stdout") {
    return {
      kind: data.kind,
      source: {
        filter: {
          metricsFormat: [
            // "{metricName: ([\\w|-]+), metricValue: ((-?\\d+)(\\.\\d+)?)}"
            data.metricsFormat,
          ],
        },
      },
    };
  }

  if (data.kind === "File") {
    return {
      source: {
        filter: {
          metricsFormat: [
            // "{metricName: ([\\w|-]+), metricValue: ((-?\\d+)(\\.\\d+)?)}"
            data.metricsFormat,
          ],
        },
        fileSystemPath: {
          path: data.settings.metricFile,
          kind: data.kind,
        },
      },
      collector: {
        kind: data.kind,
      },
    };
  }

  return {};
};

export const generateTrialTemplate = (data) => {
  return {
    primaryContainerName: data.primaryContainerName,
    trialParameters: data.trialParameters,
    // sourceType: data.settings.sourceType,
    trialSpec: yaml.load(data.settings.content || "") || {},
  };
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Stack,
  Button,
  MenuItem,
  Select,
  Autocomplete,
} from "@mui/material";
import { Form, Field, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import MlCard from "@/components/project/mlComponents/MlCard";
import MlRadio from "@/components/project/mlComponents/MlRadio";
import { LoadingButton } from "@mui/lab";
import { dispatch, useSelector, RootState } from "@/redux/store";
import { useSnackbar } from "notistack";
import {
  getModelPackageList,
  getTagList,
  addMlService,
  addTag,
  addRelMlServiceTag,
  getMlRuntimeList,
} from "@/redux/project/mlService/mlRuntime/thunks";
import moment from "moment";

interface FormValues {
  name: string;
  model_package: { name: string; id: string };
  scenario: string;
  tags: string[];
  description: string;
}
interface IAddBasicInformationProps {
  mlServiceType: string;
  setMlServiceType: Function;
  setActiveStep: Function;
  setMlServiceRes: Function;
  setServiceInfo: Function;
}

const BasicInformationStep = ({
  mlServiceType,
  setMlServiceType,
  setActiveStep,
  setMlServiceRes,
  setServiceInfo,
}: IAddBasicInformationProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const [modelPackageList, setModelPackageList] = useState<any[]>([]);
  const {
    tagDatas: { data: tagList },
    mlRuntimeTableList: {
      data: { items: mlServiceList = [] },
    },
  } = useSelector((state: RootState) => state.mlRuntime);
  const {
    config: { common },
  } = useSelector((state: RootState) => state.common);

  useEffect(() => {
    // get modelpackage
    dispatch(
      getModelPackageList({
        params: { page_num: 1, page_size: 9999 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    )
      .unwrap()
      .then((resp) => {
        const mpList: any[] = [];
        // eslint-disable-next-line array-callback-return
        resp?.items?.map((item) => {
          mpList.push({ name: item.name, id: item.id });
        });
        setModelPackageList(mpList);
      });
    // get tag
    dispatch(
      getTagList({
        params: { page_num: 1, page_size: 9999, is_active: 1 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
    // get all mlservice
    dispatch(
      getMlRuntimeList({
        params: { page_num: 1, page_size: 9999 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  }, []);

  const MLRUNTIME_TYPE = [
    {
      id: 1,
      value: "Kubeflow",
      title: "Kubeflow",
      description: "Created by Kubeflow and running in Kubernetes",
    },
    {
      id: 2,
      value: "SageMaker",
      title: "AWS SageMaker",
      description:
        "AWS ML compute instance running the ML Runtime App on cloud",
    },
  ];

  const initialValues: FormValues = {
    name: `mlservice-${moment().valueOf()}`,
    model_package: { name: "", id: "" },
    scenario: "",
    tags: [],
    description: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Service Name is required")
      .test("name-exist", "Service Name existed", (value) => {
        const isExisted =
          mlServiceList.findIndex((item) => item.name === value) > -1;
        return !isExisted;
      }),
    model_package: Yup.object().required("Model Package is required"),
  });

  const handleMlServiceSubmit = (values) => {
    const params = {
      name: values.name,
      scenario: values.scenario,
      service_type: mlServiceType,
      mp_external_id: values.model_package.id,
      description: values.description,
      is_active: 1,
    };
    return dispatch(
      addMlService({
        params,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      try {
        const mlServiceResult = await handleMlServiceSubmit(values);

        await Promise.all(
          values?.tags?.map((item) =>
            dispatch(
              addRelMlServiceTag({
                params: {
                  mlservice_id: mlServiceResult.id,
                  tag_id: item,
                  is_active: 1,
                },
                alertCallback: enqueueSnackbar,
                pathname,
              })
            ).unwrap()
          )
        );

        actions.setSubmitting(false);
        setServiceInfo(values);
        setMlServiceRes(mlServiceResult);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        enqueueSnackbar("Create Success", { variant: "success" });
      } catch (err) {
        const error = err as Error;
        console.log("error", error.message);
        setServiceInfo({});
        setMlServiceRes({});
        actions.setSubmitting(false);
      }
    },
  });

  const { errors, touched, values, setFieldValue, isSubmitting, resetForm } =
    formik;

  const handleCancel = () => {
    navigate(`/ui/mlService/mlRuntime`);
    resetForm();
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <MlCard title="Basic Information" sx={{ mt: 1.5 }}>
          <Box sx={{ pb: 2, width: "800px" }}>
            <Field
              as={TextField}
              name="name"
              label="Service Name"
              size="small"
              fullWidth
              margin="dense"
              helperText={touched.name && errors.name}
              error={touched.name && Boolean(errors.name)}
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
            <Autocomplete
              size="small"
              fullWidth
              disablePortal
              sx={{ mt: 2 }}
              options={modelPackageList || []}
              getOptionLabel={(modelPackage: { name: string; id: string }) =>
                modelPackage.name
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={
                modelPackageList.filter(
                  (mp) => values.model_package === mp.id
                )[0]
              }
              onChange={(_, newValues) => {
                setFieldValue("model_package", newValues);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name="model_package"
                  label="Model Package"
                  helperText={touched.model_package && errors.model_package}
                  error={touched.model_package && Boolean(errors.model_package)}
                  FormHelperTextProps={{
                    sx: { minHeight: "18px", marginTop: "2px" },
                  }}
                />
              )}
            />
            <FormControl fullWidth margin="dense" size="small">
              <InputLabel>{`Scenario(Optional)`}</InputLabel>
              <Field as={Select} name="scenario" label="Scenario(Optional)">
                {common?.scenario?.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>

            <Autocomplete
              size="small"
              multiple
              disableCloseOnSelect
              disablePortal
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              limitTags={8}
              getOptionLabel={(option) =>
                typeof option === "object" ? option.name : option
              }
              isOptionEqualToValue={(option, value) => {
                if (typeof option === "object" && typeof value === "object") {
                  return option.id === value.id;
                } else if (
                  typeof option === "string" ||
                  typeof value === "string"
                ) {
                  return option === value;
                }
                return false;
              }}
              options={tagList?.items}
              value={values.tags?.map(
                (tagId) =>
                  tagList?.items?.find((tag) => tag.id === tagId) || tagId
              )}
              onChange={(_, newValues) => {
                const filteredValues = newValues?.filter(
                  (item) => typeof item === "string"
                );

                if (tagList?.items?.find((i) => i.name === filteredValues[0])) {
                  enqueueSnackbar(`Tag ${filteredValues[0]} existed`, {
                    variant: "warning",
                  });
                  return;
                }

                const updatedTagsPromises = newValues.map(async (item) => {
                  if (typeof item === "string") {
                    // new text ,add a new tag
                    const res = await dispatch(
                      addTag({
                        params: {
                          is_active: 1,
                          name: item,
                        },
                        alertCallback: enqueueSnackbar,
                        pathname,
                      })
                    ).unwrap();

                    enqueueSnackbar(`Add Tag ${item} Success`, {
                      variant: "success",
                    });

                    await dispatch(
                      getTagList({
                        params: {
                          is_active: 1,
                          page_num: 1,
                          page_size: 9999,
                        },
                        alertCallback: enqueueSnackbar,
                        pathname,
                      })
                    );

                    return res.id;
                  }
                  return item.id;
                });

                Promise.all(updatedTagsPromises).then((r) => {
                  setFieldValue("tags", r);
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="tags"
                  label="Tags (Optional)"
                  helperText=" "
                  FormHelperTextProps={{
                    style: {
                      minHeight: "18px",
                      marginTop: "2px",
                    },
                  }}
                />
              )}
              sx={{ mt: 1, mb: 0.5 }}
            />

            <Field
              as={TextField}
              multiline
              rows={4}
              name="description"
              label="Description (Optional)"
              size="small"
              fullWidth
              margin="dense"
            />
          </Box>
        </MlCard>

        <MlCard title="ML Runtime Type" sx={{ mt: 1.5 }}>
          <Box sx={{ width: "800px" }}>
            <MlRadio
              curValue={mlServiceType}
              setCurValue={setMlServiceType}
              list={MLRUNTIME_TYPE}
            />
          </Box>
        </MlCard>

        <Stack
          spacing={2}
          direction="row"
          justifyContent="center"
          sx={{ my: 4 }}
        >
          <Button
            type="button"
            color="inherit"
            variant="outlined"
            sx={{ width: "200px", color: "text.secondary" }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ width: "200px", color: "background.paper" }}
          >
            Next
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default BasicInformationStep;

import { checkCode, handleErrorMessage } from "@/utils/project/request";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { MlParams } from "@/@types/project/global";
import {
  addHptunerService,
  addNotebookService,
  addNotebookTagsService,
  addNotebookVersionService,
  addNotebookVolumeService,
  addNotebookVolumeTagsService,
  addRelNotebookVolumeService,
  delNotebookService,
  delNotebookTagsService,
  delNotebookVersionService,
  delNotebookVolumeService,
  delNotebookVolumeTagsService,
  delRelNotebookVolumeService,
  deleteHptunerService,
  editNotebookVersionService,
  editNotebookVolumeService,
  getHptunerDetailService,
  getHptunerResultService,
  getHptunerTableList,
  getNotebookDetail,
  getNotebookTableList,
  getNotebookTagsService,
  getNotebookVersionDetailService,
  getNotebookVersionTableList,
  getNotebookVolumeDetail,
  getNotebookVolumeTableList,
  getNotebookVolumeTagsService,
  getRelNotebookVolumeService,
  updateNotebookService,
} from "@/services/experiment";

interface IGetNotebookParams extends MlParams {
  params: {
    name?: string;
    notebook_type?: string;
    page_num: number;
    page_size: number;
  };
  isAll?: boolean;
}

// 1. notebook
export const getNotebookTableData = createAsyncThunk(
  "common/getNotebookTableData",
  async (
    { params, isAll, alertCallback, pathname }: IGetNotebookParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getNotebookTableList(params, isAll);
      const res = checkCode(data, pathname) as Object & {
        items: Array<Record<any, any>>;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching notebook data failed");
    }
  }
);

interface IGetNotebookDetailParams extends MlParams {
  id: string;
}

// notebook detail
export const getNotebookDetailData = createAsyncThunk(
  "common/getNotebookDetailData",
  async (
    { id, alertCallback, pathname }: IGetNotebookDetailParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getNotebookDetail(id);
      const res = checkCode(data, pathname) as Object & {
        resource_config: string;
        notebook_template_version_id: string;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching notebook detail data failed");
    }
  }
);

interface IAddNotebookParams extends MlParams {
  params: {
    name: string;
    resource_config: any;
    notebook_url: string;
    git_repo_url: string;
    artifactory_url: string;
    notebook_template_version_id: number;
    is_active: number;
  };
}

export const addNotebook = createAsyncThunk(
  "common/addNotebook",
  async (
    { params, alertCallback, pathname }: IAddNotebookParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addNotebookService(params);
      const res = checkCode(data, pathname) as { id: string };
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add notebook failed");
    }
  }
);

interface IUpdateNotebookParams extends MlParams {
  id: string;
  params: {
    status: string;
  };
}

export const updateNotebook = createAsyncThunk(
  "common/updateNotebook",
  async (
    { id, params, alertCallback, pathname }: IUpdateNotebookParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await updateNotebookService(id, params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Update notebook failed");
    }
  }
);

interface IDelNotebookParams extends MlParams {
  id: string;
}

export const delNotebook = createAsyncThunk(
  "common/delNotebook",
  async (
    { id, alertCallback, pathname }: IDelNotebookParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delNotebookService(id);
      checkCode(data, pathname) as Object;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete notebook failed");
    }
  }
);

interface IGetNotebookTags extends MlParams {
  params: {
    notebook_id: string;
    page_num: number;
    page_size: number;
  };
}

// notebook tags
export const getNotebookTags = createAsyncThunk(
  "common/getNotebookTags",
  async (
    { params, alertCallback, pathname }: IGetNotebookTags,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getNotebookTagsService(params);
      const res = checkCode(data, pathname) as Object & {
        items: Array<Object & { tag_id: string }>;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching notebook tags failed");
    }
  }
);

interface IAddNotebookTags extends MlParams {
  params: {
    notebook_id: string;
    tag_id: string;
  };
}

export const addNotebookTags = createAsyncThunk(
  "common/addNotebookTags",
  async (
    { params, alertCallback, pathname }: IAddNotebookTags,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addNotebookTagsService(params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Update notebook tags failed");
    }
  }
);

interface IDeleteNotebookTags extends MlParams {
  id: string;
}

export const delNotebookTags = createAsyncThunk(
  "common/delNotebookTags",
  async (
    { id, alertCallback, pathname }: IDeleteNotebookTags,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delNotebookTagsService(id);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete notebook tags failed");
    }
  }
);

interface IGetNotebookVersionParams extends MlParams {
  params: {
    name?: string;
    notebook_id?: string;
    project_id?: string;
    page_num: number;
    page_size: number;
  };
}

// 2. notebook version
export const getNotebookVersionTableData = createAsyncThunk(
  "common/getNotebookVersionTableData",
  async (
    { params, alertCallback, pathname }: IGetNotebookVersionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getNotebookVersionTableList(params);
      const res = checkCode(data, pathname) as Object;

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching notebook version data failed");
    }
  }
);
interface IGetNotebookVersionDetail extends MlParams {
  id: string;
}

export const getNotebookVersionDetail = createAsyncThunk(
  "common/getNotebookVersionDetail",
  async (
    { id, alertCallback, pathname }: IGetNotebookVersionDetail,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getNotebookVersionDetailService(id);
      const res = checkCode(data, pathname) as Object;

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching notebook version detail data failed");
    }
  }
);
interface IAddNotebookVersionParams extends MlParams {
  params: {
    name?: string;
    description?: string;
    notebook_id: string;
    is_active?: number;
  };
}

export const addNotebookVersion = createAsyncThunk(
  "common/addNotebookVersion",
  async (
    { params, alertCallback, pathname }: IAddNotebookVersionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addNotebookVersionService(params);
      const res = checkCode(data, pathname) as Object;

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Edit notebook version data failed");
    }
  }
);
interface IEditNotebookVersionParams extends MlParams {
  id: string;
  params: {
    name?: string;
    description?: string;
    notebook_id: string;
    is_active?: number;
  };
}

export const editNotebookVersion = createAsyncThunk(
  "common/editNotebookVersion",
  async (
    { id, params, alertCallback, pathname }: IEditNotebookVersionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await editNotebookVersionService(id, params);
      const res = checkCode(data, pathname) as Object;

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Edit notebook version data failed");
    }
  }
);

interface IDelNotebookVersionParams extends MlParams {
  id: string;
}

export const delNotebookVersion = createAsyncThunk(
  "common/delNotebookVersion",
  async (
    { id, alertCallback, pathname }: IDelNotebookVersionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delNotebookVersionService(id);
      const res = checkCode(data, pathname) as Object;

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete notebook version data failed");
    }
  }
);

interface IGetNotebookVolumeParams extends MlParams {
  params: {
    name?: string;
    page_num: number;
    page_size: number;
  };
  isAll?: boolean;
}

// 3. notebook volume
export const getNotebookVolumeTableData = createAsyncThunk(
  "common/getNotebookVolumeTableData",
  async (
    { params, isAll, alertCallback, pathname }: IGetNotebookVolumeParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getNotebookVolumeTableList(params, isAll);
      const res = checkCode(data, pathname) as Object & {
        items: Array<Record<any, any>>;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching notebook volume data failed");
    }
  }
);

interface IGetNotebookVolumeDetailParams extends MlParams {
  id: string;
}

export const getNotebookVolumeDetailData = createAsyncThunk(
  "common/getNotebookVolumeDetailData",
  async (
    { id, alertCallback, pathname }: IGetNotebookVolumeDetailParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getNotebookVolumeDetail(id);
      const res = checkCode(data, pathname) as Object & {
        resource_config: string;
        notebook_template_version_id: string;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching notebook volume detail data failed");
    }
  }
);

// rel_notebook_notebook_volume
interface IGetRelNotebookVolumeParams extends MlParams {
  params: {
    notebook_id?: string;
    notebook_volume_id?: string;
    page_num: number;
    page_size: number;
  };
}

export const getRelNotebookVolumeData = createAsyncThunk(
  "common/getRelNotebookVolumeData",
  async (
    { params, alertCallback, pathname }: IGetRelNotebookVolumeParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getRelNotebookVolumeService(params);
      const res = checkCode(data, pathname) as {
        items: Array<{
          id: string;
          notebook_volume_id: string;
          notebook_id: string;
        }>;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching relatetion notebook volume failed");
    }
  }
);

interface IAddRelNotebookVolumeParams extends MlParams {
  params: {
    notebook_id: string;
    notebook_volume_id: string;
  };
}

export const addRelNotebookVolumeData = createAsyncThunk(
  "common/addRelNotebookVolumeData",
  async (
    { params, alertCallback, pathname }: IAddRelNotebookVolumeParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addRelNotebookVolumeService(params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add relatetion notebook volume failed");
    }
  }
);

interface IDelRelNotebookVolumeParams extends MlParams {
  id: string;
}

export const delRelNotebookVolumeData = createAsyncThunk(
  "common/delRelNotebookVolumeData",
  async (
    { id, alertCallback, pathname }: IDelRelNotebookVolumeParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delRelNotebookVolumeService(id);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete relatetion notebook volume failed");
    }
  }
);

interface IAddNotebookVolumeParams extends MlParams {
  params: {
    name: string;
    volume_type: string;
    resource_config: any;
    status?: string;
    is_active: number;
  };
}

export const addNotebookVolume = createAsyncThunk(
  "common/addNotebookVolume",
  async (
    { params, alertCallback, pathname }: IAddNotebookVolumeParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addNotebookVolumeService(params);
      const res = checkCode(data, pathname) as { id: string };
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add notebook Volume failed");
    }
  }
);

interface IEditNotebookVolumeParams extends MlParams {
  id: string;
  params: {
    resource_config?: any;
    status?: string;
  };
}

export const editNotebookVolume = createAsyncThunk(
  "common/editNotebookVolume",
  async (
    { id, params, alertCallback, pathname }: IEditNotebookVolumeParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await editNotebookVolumeService(id, params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Edit notebook failed");
    }
  }
);

interface IDelNotebookParams extends MlParams {
  id: string;
}

export const delNotebookVolume = createAsyncThunk(
  "common/delNotebookVolume",
  async (
    { id, alertCallback, pathname }: IDelNotebookParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delNotebookVolumeService(id);
      checkCode(data, pathname) as Object;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete notebook volume failed");
    }
  }
);

interface IGetNotebookVolumeTags extends MlParams {
  params: {
    notebook_volume_id: string;
    page_num: number;
    page_size: number;
  };
}

export const getNotebookVolumeTags = createAsyncThunk(
  "common/getNotebookVolumeTags",
  async (
    { params, alertCallback, pathname }: IGetNotebookVolumeTags,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getNotebookVolumeTagsService(params);
      const res = checkCode(data, pathname) as Object & {
        items: Array<Object & { tag_id: string }>;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching notebook volume tags failed");
    }
  }
);

interface IAddNotebookVolumeTags extends MlParams {
  params: {
    notebook_volume_id: string;
    tag_id: string;
  };
}

export const addNotebookVolumeTags = createAsyncThunk(
  "common/addNotebookVolumeTags",
  async (
    { params, alertCallback, pathname }: IAddNotebookVolumeTags,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addNotebookVolumeTagsService(params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add notebook volume tags failed");
    }
  }
);

interface IDeleteNotebookVolumeTags extends MlParams {
  id: string;
}

export const delNotebookVolumeTags = createAsyncThunk(
  "common/delNotebookVolumeTags",
  async (
    { id, alertCallback, pathname }: IDeleteNotebookVolumeTags,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delNotebookVolumeTagsService(id);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete notebook volume tags failed");
    }
  }
);

interface IGetHptunerParams extends MlParams {
  params: {
    name?: string;
    page_num: number;
    page_size: number;
  };
  isAll?: boolean;
}

// 4. hp tuner
export const getHptunerTableData = createAsyncThunk(
  "common/getHptunerTableData",
  async (
    { params, isAll, alertCallback, pathname }: IGetHptunerParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getHptunerTableList(params, isAll);
      const res = checkCode(data, pathname) as Object & {
        items: Array<Record<any, any>>;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching HpTuner data failed");
    }
  }
);

interface IGetHptunerDetail extends MlParams {
  id: string;
}

export const getHptunerDetail = createAsyncThunk(
  "common/getHptunerDetail",
  async (
    { id, alertCallback, pathname }: IGetHptunerDetail,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getHptunerDetailService(id);
      const res = checkCode(data, pathname) as Object & {
        resource_config: string;
        name: string;
        hptuner_type: string;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching HpTuner detail data failed");
    }
  }
);

interface IGetHptunerResult extends MlParams {
  params: {
    name: string;
    hptuner_type: string;
  };
}

export const getHptunerResult = createAsyncThunk(
  "common/getHptunerResult",
  async (
    { params, alertCallback, pathname }: IGetHptunerResult,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getHptunerResultService(params);
      const res = checkCode(data, pathname) as Object & {
        resource_config: string;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching HpTuner result data failed");
    }
  }
);

interface IAddHptuner extends MlParams {
  params: {
    name: string;
    hptuner_type: string;
    resource_config: Record<any, any>;
  };
}

export const addHptuner = createAsyncThunk(
  "common/addHptuner",
  async (
    { params, alertCallback, pathname }: IAddHptuner,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addHptunerService(params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add HpTuner failed");
    }
  }
);

interface IDeleteHptuner extends MlParams {
  id: string;
  params?: {
    status: string;
  };
}

export const deleteHptuner = createAsyncThunk(
  "common/deleteHptuner",
  async (
    { id, params, alertCallback, pathname }: IDeleteHptuner,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await deleteHptunerService(id, params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Update HpTuner failed");
    }
  }
);

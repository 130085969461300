// eslint-disable-next-line
import * as Yup from "yup";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { Stack, Collapse } from "@mui/material";
//
import { boolOptions } from "../helper-functions";
import { updateProcessingNetwork } from "../../../graph/slices/slice";
import {
  ProcessingStep,
  NetworkConfigStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-processing";
import { CollapseButtonStyle } from "../../SettingSidebar";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";
import PipelineParameterWithSelector from "../../components/PipelineParameterWithSelector";

// ----------------------------------------------------------------------

type Props = {
  currentStep: ProcessingStep | null;
};

export default function ProcessingNetwork({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [showProcessor, setShowProcessorr] = useState(false);

  const formik = useFormik<NetworkConfigStruct>({
    initialValues: {
      enableNetworkIsolation: currentStep
        ? currentStep.networkConfig.enableNetworkIsolationUseParam === false
          ? currentStep.networkConfig.enableNetworkIsolation === "True" ||
            currentStep.networkConfig.enableNetworkIsolation === "False"
            ? currentStep.networkConfig.enableNetworkIsolation
            : ""
          : currentStep.networkConfig.enableNetworkIsolation
        : "",
      enableNetworkIsolationUseParam:
        currentStep?.networkConfig.enableNetworkIsolationUseParam || false,
      securityGroupIds: currentStep?.networkConfig.securityGroupIds || "",
      securityGroupIdsUseParam:
        currentStep?.networkConfig.securityGroupIdsUseParam || false,
      subnets: currentStep?.networkConfig.subnets || "",
      subnetsUseParam: currentStep?.networkConfig.subnetsUseParam || false,
      encryptInterContainerTraffic: currentStep
        ? currentStep.networkConfig.encryptInterContainerTrafficUseParam ===
          false
          ? currentStep.networkConfig.encryptInterContainerTraffic === "True" ||
            currentStep.networkConfig.encryptInterContainerTraffic === "False"
            ? currentStep.networkConfig.encryptInterContainerTraffic
            : ""
          : currentStep.networkConfig.encryptInterContainerTraffic
        : "",
      encryptInterContainerTrafficUseParam:
        currentStep?.networkConfig.encryptInterContainerTrafficUseParam ||
        false,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      try {
        dispatch(updateProcessingNetwork(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { handleSubmit } = formik;

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setShowProcessorr((prev) => !prev)}
        endIcon={
          <Icon
            icon={showProcessor ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
      >
        Network
      </CollapseButtonStyle>

      <Collapse in={showProcessor} sx={{ mx: 2 }}>
        <Stack spacing={1} sx={{ my: 1.2, mb: 1.5 }}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onChange={handleSubmit}>
              <Stack spacing={2} sx={{ mb: 0.5 }}>
                <PipelineParameterWithSelector
                  label={"Enable network isolation"}
                  field={"enableNetworkIsolation"}
                  placeholder={"None"}
                  fieldUseParam={"enableNetworkIsolationUseParam"}
                  options={boolOptions}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Security group ids"}
                  field={"securityGroupIds"}
                  placeholder={"None"}
                  fieldUseParam={"securityGroupIdsUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Subnets"}
                  field={"subnets"}
                  placeholder={"None"}
                  fieldUseParam={"subnetsUseParam"}
                  formik={formik}
                />

                <PipelineParameterWithSelector
                  label={"Encrypt inter container traffic"}
                  field={"encryptInterContainerTraffic"}
                  placeholder={"None"}
                  fieldUseParam={"encryptInterContainerTrafficUseParam"}
                  options={boolOptions}
                  formik={formik}
                />
              </Stack>
            </Form>
          </FormikProvider>
        </Stack>
      </Collapse>
    </>
  );
}

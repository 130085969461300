//
import { useSelector } from "react-redux";
import { SchemaNodeEntity } from "../graph/slices/slice";
import {
  FailStepEntry,
  ModelStepEntry,
  LambdaStepEntry,
  TuningStepEntry,
  TrainingStepEntry,
  ConditionStepEntry,
  TransformStepEntry,
  ProcessingStepEntry,
  ClarifyCheckStepEntry,
  RegisterModelStepEntry,
} from "./StepConfigComponents";
import {
  ProcessingStepEntry as KubeflowProcessingStepEntry,
  FeatureSetUploadStepEntry,
  RegisterModelStepEntry as KubeflowRegisterModelStepEntry,
  FeatureSetDownloadStepEntry,
  TrainingStepEntry as KubeflowTrainingStepEntry,
} from "./StepConfigComponents/Kubeflow";
import { RootState } from "@/redux/store";

// ----------------------------------------------------------------------

type PipelineStepConfigProps = {
  entity: SchemaNodeEntity | null;
  isEdit: boolean;
};

export default function PipelineStepConfig({
  entity,
  isEdit,
}: PipelineStepConfigProps) {
  const { pipelineType } = useSelector((state: RootState) => state.schema);
  const isKubeflow = pipelineType === "Kubeflow";

  const renderSwitch = (entity: SchemaNodeEntity) => {
    if (isKubeflow) {
      switch (entity.stepType) {
        case "Processing":
          return (
            <KubeflowProcessingStepEntry isEdit={isEdit} entity={entity} />
          );
        case "FeatureSetUpload":
          return <FeatureSetUploadStepEntry isEdit={isEdit} entity={entity} />;
        case "RegisterModel":
          return (
            <KubeflowRegisterModelStepEntry isEdit={isEdit} entity={entity} />
          );
        case "FeatureSetDownload":
          return (
            <FeatureSetDownloadStepEntry isEdit={isEdit} entity={entity} />
          );
        case "Training":
          return <KubeflowTrainingStepEntry isEdit={isEdit} entity={entity} />;
        default:
          // empty to show Processing
          return (
            <KubeflowProcessingStepEntry isEdit={isEdit} entity={entity} />
          );
      }
    } else {
      switch (entity.stepType) {
        case "Processing":
          return <ProcessingStepEntry entity={entity} />;
        case "Training":
          return <TrainingStepEntry entity={entity} />;
        case "RegisterModel":
          return <RegisterModelStepEntry entity={entity} />;
        case "Condition":
          return <ConditionStepEntry entity={entity} />;
        case "Tuning":
          return <TuningStepEntry entity={entity} />;
        case "Model":
          return <ModelStepEntry entity={entity} />;
        case "Transform":
          return <TransformStepEntry entity={entity} />;
        case "Fail":
          return <FailStepEntry entity={entity} />;
        case "Lambda":
          return <LambdaStepEntry entity={entity} />;
        case "ClarifyCheck":
          return <ClarifyCheckStepEntry entity={entity} />;
        default:
          return <ProcessingStepEntry entity={entity} />;
      }
    }
  };

  return (
    <>
      {entity && renderSwitch(entity)}
      {/* <Scrollbar>{entity && renderSwitch(entity)}</Scrollbar> */}
    </>
  );
}

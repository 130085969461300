import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
// components
import { MHidden } from "@/components/project/@material-extend";
import AccountPopover from "./components/AccountPopover";
import ThemeToggleBtn from "./components/ThemeToggleBtn";
import FullscreenBtn from "./components/FullscreenBtn";
import { isMobile, isSafari } from "react-device-detect";
import TokenSwitchSelect from "./components/TokenSwitchSelect";
import HelpBtn from "./components/HelpBtn";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

// ----------------------------------------------------------------------

// const FIRST_DRAWER_WIDTH = 102;

const APPBAR_DESKTOP = 54;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  /* [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${SECOND_DRAWER_WIDTH + 1}px)`,
  }, */
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: "54px !important",
  [theme.breakpoints.up("lg")]: {
    // minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardNavbar({
  onOpenSidebar,
}: DashboardNavbarProps) {
  const { userDetail, isSuperAdmin } = useSelector(
    (state: RootState) => state.common
  );

  const isJustSuperAdmin = () => {
    return isSuperAdmin && !userDetail.hasOwnProperty("organization_id");
  };

  return (
    <RootStyle>
      <ToolbarStyle>
        {/* show when more than lg  */}
        <MHidden width="lgUp">
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 1.5, sm: 1.5 }}
        >
          {/* hide when just sys_admin or sys_ops without organization_id */}
          {!isJustSuperAdmin() && <TokenSwitchSelect />}

          <HelpBtn />
          <ThemeToggleBtn />
          {!(isMobile || isSafari) && <FullscreenBtn />}
          {/* <NotificationsPopover /> */}
          <AccountPopover />
          {/* <ContactsPopover /> */}
          {/* <LanguagePopover /> */}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}

import { FailStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-fail";
import { ModelStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-model";
import { LambdaStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-lambda";
import { TuningStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-tuning";
import { TrainingStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-training";
import { ConditionStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-condition";
import { TransformStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-transform";
import { ProcessingStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-processing";
import { ClarifyCheckStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-clarify-check";
import { RegisterModelStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-register-model";

export const constructProcessingStep = (
  id: number,
  position: number[],
  stepType: string
) => {
  return {
    id: id.toString(),
    type: "Processing",
    name: stepType,
    processor: {
      processorType: "SKLearnProcessor",
      processorTypeUseParam: false,
      frameworkVersion: "0.23-1",
      instanceType: "ml.c4.xlarge",
      instanceTypeUseParam: false,
      instanceCount: "1",
      instanceCountUseParam: false,
      volumeSizeInGb: "30",
      volumeSizeInGbUseParam: false,
      environmentVars: [],
    },
    processingInputs: [],
    processingOutputs: [],
    propertyFiles: [],
    code: {
      source: "amazon_s3",
      path: "",
      includeDir: false,
      jobArgs: [],
    },
    networkConfig: {
      enableNetworkIsolation: "",
      enableNetworkIsolationUseParam: false,
      securityGroupIds: "",
      securityGroupIdsUseParam: false,
      subnets: "",
      subnetsUseParam: false,
      encryptInterContainerTraffic: "",
      encryptInterContainerTrafficUseParam: false,
    },
    nodeX: position[0],
    nodeY: position[1],
    tags: [],
    properties: [],
    stepType: stepType,
  } as ProcessingStep;
};

export const constructTrainingStep = (
  id: number,
  position: number[],
  stepType: string
) => {
  return {
    id: id.toString(),
    type: "Training",
    name: stepType,
    estimator: {
      estimatorType: "PyTorch",
      estimatorTypeUseParam: false,
      frameworkVersion: "1.8.1",
      instanceType: "ml.c4.xlarge",
      instanceTypeUseParam: false,
      instanceCount: "1",
      instanceCountUseParam: false,
      volumeSizeInGb: "30",
      volumeSizeInGbUseParam: false,
      environmentVars: [],
      hyperparameters: [],
    },
    trainingInputs: [],
    trainingOutput: { s3OutputPath: "", s3OutputPathUseParam: false },
    code: {
      source: "amazon_s3",
      sourceDir: "",
      entryPoint: "",
    },
    metricDefinitions: [],
    nodeX: position[0],
    nodeY: position[1],
    tags: [],
    properties: [],
    stepType: stepType,
  } as TrainingStep;
};

export const constructRegisterModelStep = (
  id: number,
  position: number[],
  stepType: string
) => {
  return {
    id: id.toString(),
    type: "RegisterModel",
    name: stepType,
    approvalStatus: "PendingManualApproval",
    approvalStatusUseParam: false,
    model: {
      modelType: "Model",
      modelData: "",
      modelDataUseParam: false,
      contentType: "None",
      responseType: "None",
      container: "PyTorch-1.8.1",
      containerUseParam: false,
      modelPackageGroupName: "model-package-group",
      modelPackageGroupNameUseParam: false,
      inferenceInstanceType: "ml.m4.xlarge",
      inferenceInstanceTypeUseParam: false,
      transformInstanceType: "ml.c5.4xlarge",
      transformInstanceTypeUseParam: false,
    },
    nodeX: position[0],
    nodeY: position[1],
    tags: [],
    properties: [],
    stepType: stepType,
  } as RegisterModelStep;
};

export const constructConditionStep = (
  id: number,
  position: number[],
  stepType: string
) => {
  return {
    id: id.toString(),
    type: "Condition",
    name: stepType,
    conditions: [
      {
        conditionType: "Equals",
        leftValue: {
          source: "value",
          value: "",
          valueUseParam: false,
          jsonKey: "",
          jsonKeyUseParam: false,
        },
        rightValue: {
          source: "value",
          value: "",
          valueUseParam: false,
          jsonKey: "",
          jsonKeyUseParam: false,
        },
      },
    ],
    ifSteps: [],
    elseSteps: [],
    nodeX: position[0],
    nodeY: position[1],
    tags: [],
    properties: [],
    stepType: stepType,
  } as ConditionStep;
};

export const constructTuningStep = (
  id: number,
  position: number[],
  stepType: string
) => {
  return {
    id: id.toString(),
    type: "Tuning",
    name: stepType,
    metricName: "",
    tuningType: "Maximize",
    tuningTypeUseParam: false,
    tuningJob: {
      strategyType: "Random",
      strategyTypeUseParam: false,
      maxTrainingJob: "3",
      maxTrainingJobUseParam: false,
      maxParallelTrainingJob: "3",
      maxParallelTrainingJobUseParam: false,
      trainingJobEarlyStoppingType: "Off",
      trainingJobEarlyStoppingTypeUseParam: false,
      parameterRanges: [],
    },
    trainingJob: {
      estimatorType: "XGBoost",
      estimatorTypeUseParam: false,
      frameworkVersion: "1.0.1",
      instanceType: "ml.m5.xlarge",
      instanceTypeUseParam: false,
      instanceCount: "1",
      instanceCountUseParam: false,
      volumeSizeInGb: "30",
      volumeSizeInGbUseParam: false,
      outputPath: "",
      outputPathUseParam: false,
      hyperparameters: [],
    },
    trainingInputs: [],
    nodeX: position[0],
    nodeY: position[1],
    tags: [],
    properties: [],
    stepType: stepType,
  } as TuningStep;
};

export const constructModelStep = (
  id: number,
  position: number[],
  stepType: string
) => {
  return {
    id: id.toString(),
    type: "Model",
    name: stepType,
    container: {
      containerType: "",
      containerTypeUseParam: false,
      frameworkVersion: "1.0.1",
      environmentVars: [],
    },
    modelData: { modelDataUrl: "", modelDataUrlUseParam: false },
    nodeX: position[0],
    nodeY: position[1],
    tags: [],
    properties: [],
    stepType: stepType,
  } as ModelStep;
};

export const constructTransformStep = (
  id: number,
  position: number[],
  stepType: string
) => {
  return {
    id: id.toString(),
    type: "Transform",
    name: stepType,
    modelName: "",
    modelNameUseParam: false,
    dataSource: "",
    dataSourceUseParam: false,
    outputPath: "",
    outputPathUseParam: false,
    instanceType: "",
    instanceTypeUseParam: false,
    instanceCount: "1",
    instanceCountUseParam: false,
    nodeX: position[0],
    nodeY: position[1],
    tags: [],
    properties: [],
    stepType: stepType,
  } as TransformStep;
};

export const constructLambdaStep = (
  id: number,
  position: number[],
  stepType: string
) => {
  return {
    id: id.toString(),
    type: "Lambda",
    name: stepType,
    function: {
      type: "arn",
      content: "",
    },
    lambdaInputs: [],
    lambdaOutputs: [],
    nodeX: position[0],
    nodeY: position[1],
    tags: [],
    properties: [],
    stepType: stepType,
  } as LambdaStep;
};

export const constructFailStep = (
  id: number,
  position: number[],
  stepType: string
) => {
  return {
    id: id.toString(),
    type: "Fail",
    name: stepType,
    errorMessage: "",
    errorMessageUseParam: false,
    nodeX: position[0],
    nodeY: position[1],
    tags: [],
    properties: [],
    stepType: stepType,
  } as FailStep;
};

export const constructClarifyCheckStep = (
  id: number,
  position: number[],
  stepType: string
) => {
  return {
    id: id.toString(),
    type: "ClarifyCheck",
    name: stepType,
    checkStepInput: {
      checkType: "MODEL_BIAS",
      methods: "all",
      skipCheck: "False",
      skipCheckUseParam: false,
      registerNewBaseline: "False",
      registerNewBaselineUseParam: false,
      suppliedBaselineConstraints: "",
      suppliedBaselineConstraintsUseParam: false,
    },
    checkJobConfig: {
      processorType: "",
      processorTypeUseParam: false,
      configFilePath: "",
      configFilePathUseParam: false,
      frameworkVersion: "",
      instanceType: "ml.m4.xlarge",
      instanceTypeUseParam: false,
      instanceCount: "1",
      instanceCountUseParam: false,
      volumeSizeInGb: "30",
      volumeSizeInGbUseParam: false,
    },
    modelConfig: {
      modelDataUrl: "",
      modelDataUrlUseParam: false,
      instanceType: "ml.m4.xlarge",
      instanceTypeUseParam: false,
      instanceCount: "1",
      instanceCountUseParam: false,
      modelPackageGroupName: "",
      modelPackageGroupNameUseParam: false,
    },
    dataConfig: {
      s3InputDataUrl: "",
      s3InputDataUrlUseParam: false,
      s3OutputUrl: "",
      s3OutputUrlUseParam: false,
      label: "",
      labelUseParam: false,
      contentType: "text/csv",
    },
    predLabelConfig: {
      label: "",
      probability: "",
      probThreshold: "",
      labelHeaders: "",
    },
    biasConfig: {
      labelValuesOrThreshold: "",
      facetName: "",
      facetValuesOrThreshold: "",
      groupName: "",
    },
    shapConfig: {
      baseline: "",
      num_samples: "",
      agg_method: "mean_abs",
      use_logit: "false",
      save_local_shap_values: "true",
      seed: "",
      num_clusters: "",
      text_config: "",
      image_config: "",
    },
    nodeX: position[0],
    nodeY: position[1],
    tags: [],
    properties: [],
    stepType: stepType,
  } as ClarifyCheckStep;
};

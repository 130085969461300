import axios from "@/utils/project/axiosForPython";
import { StepInfo } from "@/@types/project/mlPipeline/SageMaker/pipeline";
import { parseS3BucketAndPrefix } from "../step-parsers/helper-functions";
import { formNumber, generateUri, generateImageUri } from "./helper-functions";
import { ClarifyCheckStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-clarify-check";

// ----------------------------------------------------------------------

export default async function generateClarifyCheckStep(
  step: ClarifyCheckStep,
  roleArn: string
) {
  const modelPackageName = generateUri(
    step.modelConfig.modelPackageGroupName,
    step.modelConfig.modelPackageGroupNameUseParam
  );

  await uploadAnalysisConfig(step);

  return {
    Name: step.name,
    Type: step.type,
    Arguments: {
      ProcessingResources: {
        ClusterConfig: {
          InstanceType: generateUri(
            step.checkJobConfig.instanceType,
            step.checkJobConfig.instanceTypeUseParam
          ),
          InstanceCount: formNumber(
            step.checkJobConfig.instanceCount,
            step.checkJobConfig.instanceCountUseParam
          ),
          VolumeSizeInGB: formNumber(
            step.checkJobConfig.volumeSizeInGb,
            step.checkJobConfig.volumeSizeInGbUseParam
          ),
        },
      },
      AppSpecification: formAppSpecification(step),
      RoleArn: roleArn,
      ProcessingInputs: formProcessingInput(step),
      ProcessingOutputConfig: formProcessingOutput(step),
    },
    CheckType: step.checkStepInput.checkType,
    ModelPackageGroupName: modelPackageName === "" ? null : modelPackageName,
    SkipCheck: generateUri(
      step.checkStepInput.skipCheck,
      step.checkStepInput.skipCheckUseParam
    ),
    RegisterNewBaseline: generateUri(
      step.checkStepInput.registerNewBaseline,
      step.checkStepInput.registerNewBaselineUseParam
    ),
    SuppliedBaselineConstraints: generateUri(
      step.checkStepInput.suppliedBaselineConstraints,
      step.checkStepInput.suppliedBaselineConstraintsUseParam
    ),
    ModelName: generateUri(
      step.modelConfig.modelDataUrl,
      step.modelConfig.modelDataUrlUseParam
    ),
  } as StepInfo;
}

const uploadAnalysisConfig = async (step: ClarifyCheckStep) => {
  const configFilePath = step.checkJobConfig.configFilePath;

  if (configFilePath === "" && configFilePath === undefined) return false;

  const [bucket, prefix] = parseS3BucketAndPrefix(configFilePath);

  let content = {
    dataset_type: step.dataConfig.contentType,
    label: step.dataConfig.label,
    predictor: {
      instance_type: step.modelConfig.instanceType,
      initial_instance_count: step.modelConfig.instanceCount,
    },
  };

  if (step.checkStepInput.checkType === "MODEL_BIAS") {
    content = {
      ...content,
      ...{
        label_values_or_threshold: step.biasConfig.labelValuesOrThreshold
          .split(",")
          .map((item) => Number(item)),
        facet: [
          {
            name_or_index: step.biasConfig.facetName,
            value_or_threshold: step.biasConfig.facetValuesOrThreshold
              .split(",")
              .map((item) => Number(item)),
          },
        ],
        methods: {
          post_training_bias: {
            methods: step.checkStepInput.methods.split(","),
          },
          report: { name: "report", title: "Analysis Report" },
        },
        probability_threshold: step.predLabelConfig.probThreshold,
      },
    };
  } else if (step.checkStepInput.checkType === "MODEL_EXPLAINABILITY") {
    let shapConfig = {};
    if (step.shapConfig.baseline)
      shapConfig = {
        ...shapConfig,
        baseline: step.shapConfig.baseline,
      };
    if (step.shapConfig.num_samples)
      shapConfig = {
        ...shapConfig,
        num_samples: Number(step.shapConfig.num_samples),
      };
    if (step.shapConfig.agg_method)
      shapConfig = {
        ...shapConfig,
        agg_method: step.shapConfig.agg_method,
      };
    if (step.shapConfig.use_logit)
      shapConfig = {
        ...shapConfig,
        use_logit: Boolean(
          String(step.shapConfig.use_logit).toLowerCase() === "true"
        ),
      };
    if (step.shapConfig.save_local_shap_values)
      shapConfig = {
        ...shapConfig,
        save_local_shap_values: Boolean(
          String(step.shapConfig.save_local_shap_values).toLowerCase() ===
            "true"
        ),
      };
    if (step.shapConfig.seed)
      shapConfig = {
        ...shapConfig,
        seed: Number(step.shapConfig.seed),
      };
    if (step.shapConfig.num_clusters)
      shapConfig = {
        ...shapConfig,
        num_clusters: Number(step.shapConfig.num_clusters),
      };
    if (step.shapConfig.text_config)
      shapConfig = {
        ...shapConfig,
        text_config: step.shapConfig.text_config,
      };
    if (step.shapConfig.image_config)
      shapConfig = {
        ...shapConfig,
        image_config: step.shapConfig.image_config,
      };
    content = {
      ...content,
      ...{
        methods: {
          shap: shapConfig,
          report: { name: "report", title: "Analysis Report" },
        },
      },
    };
  }

  // console.log([bucket, prefix]);
  // console.log(content);

  try {
    const response = await axios.post("/omniml/helper/s3/upload/json", {
      s3_bucket: bucket,
      file_path: prefix,
      content: content,
    });
    if (response.status === 200 && response.data.success) {
      return true;
    }
  } catch (error) {
    throw error;
  }

  // const response = await axios.post("/omniml/helper/s3/upload/json", {
  //   s3_bucket: bucket,
  //   file_path: prefix,
  //   content: content,
  // });
  // console.log(response);
  // return true;
};

const formProcessingInput = (step: ClarifyCheckStep) => {
  const processingInputs = [] as any;
  // Add code
  processingInputs.push({
    InputName: "analysis_config",
    AppManaged: false,
    S3Input: {
      S3Uri: step.checkJobConfig.configFilePath,
      LocalPath: "/opt/ml/processing/input/config",
      S3DataType: "S3Prefix",
      S3InputMode: "File",
      S3DataDistributionType: "FullyReplicated",
      S3CompressionType: "None",
    },
  });
  // Add inputs
  processingInputs.push({
    InputName: "dataset",
    AppManaged: false,
    S3Input: {
      S3Uri: generateUri(
        step.dataConfig.s3InputDataUrl,
        step.dataConfig.s3InputDataUrlUseParam
      ),
      LocalPath: "/opt/ml/processing/input/data",
      S3DataType: "S3Prefix",
      S3InputMode: "File",
      S3DataDistributionType: "FullyReplicated",
      S3CompressionType: "None",
    },
  });

  return processingInputs;
};

const formAppSpecification = (step: ClarifyCheckStep) => {
  return {
    ImageUri: generateImageUri(
      step.checkJobConfig.processorType,
      step.checkJobConfig.processorTypeUseParam
    ),
  };
};

const formProcessingOutput = (step: ClarifyCheckStep) => {
  return {
    Outputs: [
      {
        OutputName: "analysis_result",
        AppManaged: false,
        S3Output: {
          S3Uri: generateUri(
            step.dataConfig.s3OutputUrl,
            step.dataConfig.s3OutputUrlUseParam
          ),
          LocalPath: "/opt/ml/processing/output",
          S3UploadMode: "EndOfJob",
        },
      },
    ],
  };
};

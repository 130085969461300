import { useState } from "react";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { Stack, Collapse } from "@mui/material";
// utils
import { updateClarifyPredLabelConfig } from "../../../graph/slices/slice";
// components
import {
  ClarifyCheckStep,
  ClarifyCheckPredLabelConfigStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-clarify-check";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { PipelineTextfield } from "../../components/PipelineComponents";

// ----------------------------------------------------------------------

type Props = {
  currentStep: ClarifyCheckStep | null;
};

export default function PredictedLabelConfig({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [showProcessor, setShowProcessorr] = useState(false);

  const formik = useFormik<ClarifyCheckPredLabelConfigStruct>({
    initialValues: {
      label: currentStep?.predLabelConfig.label || "",
      probability: currentStep?.predLabelConfig.probability || "",
      probThreshold: currentStep?.predLabelConfig.probThreshold || "",
      labelHeaders: currentStep?.predLabelConfig.labelHeaders || "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      try {
        dispatch(updateClarifyPredLabelConfig(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { handleSubmit } = formik;

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setShowProcessorr((prev) => !prev)}
        endIcon={
          <Icon
            icon={showProcessor ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
      >
        Predicted Label Config
      </CollapseButtonStyle>

      <Collapse in={showProcessor} sx={{ mx: 2 }}>
        <Stack spacing={1} sx={{ my: 1.2, mb: 1.5 }}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onChange={handleSubmit}>
              <Stack spacing={2} sx={{ mb: 0.5 }}>
                <PipelineTextfield
                  label={"Label"}
                  field={"label"}
                  placeholder={"string or int"}
                  formik={formik}
                />

                <PipelineTextfield
                  label={"Probability"}
                  field={"probability"}
                  placeholder={"string or int"}
                  formik={formik}
                />

                <PipelineTextfield
                  label={"Probability threshold"}
                  field={"probThreshold"}
                  placeholder={"float"}
                  formik={formik}
                />

                <PipelineTextfield
                  label={"Label headers"}
                  field={"labelHeaders"}
                  placeholder={"string1, string2, ..."}
                  formik={formik}
                />
              </Stack>
            </Form>
          </FormikProvider>
        </Stack>
      </Collapse>
    </>
  );
}

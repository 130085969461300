import {
  Box,
  Card,
  CardProps,
  Collapse,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { ReactNode, useState } from "react";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";

interface MlCardProps extends CardProps {
  title: string;
  children: ReactNode;
  extraJsx?: ReactNode;
  hasBorderBottom?: boolean;
  hasPaddingX?: boolean;
  isCanFold?: boolean;
  defaultExpand?: boolean;
}

const MlCard = ({
  title,
  children,
  extraJsx = <></>,
  hasBorderBottom = false,
  hasPaddingX = true,
  isCanFold = false,
  defaultExpand = true,
  ...other
}: MlCardProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(defaultExpand);

  return (
    <Card sx={{ pb: 2, mb: 2 }} {...other}>
      <Stack
        justifyContent="space-between"
        direction="row"
        sx={{
          pb: 1.5,
          ...(hasBorderBottom
            ? { borderBottom: `1px solid ${theme.palette.grey[500_16]}` }
            : {}),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            cursor: "pointer",
            width: 400,
            pt: 2.5,
            ...(isCanFold ? { pl: 0.5 } : { pl: 2.5 }),
          }}
          spacing={0.5}
          onClick={() => {
            if (isCanFold) {
              setOpen(!open);
            }
          }}
        >
          {isCanFold && (
            <ExpandMoreOutlinedIcon
              sx={{
                transform: open ? "rotate(0)" : "rotate(-90deg)",
                transition: "transform 0.5s",
              }}
            />
          )}
          <Typography variant="h6">{title}</Typography>
        </Stack>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            px: 2.5,
          }}
        >
          {extraJsx}
        </Box>
      </Stack>

      <Collapse in={open}>
        <Box sx={{ ...(hasPaddingX ? { px: 4 } : {}) }}>{children}</Box>
      </Collapse>
    </Card>
  );
};

export default MlCard;

import ReactApexChart from "react-apexcharts";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import { Box, Card, Typography, Stack, Grid } from "@mui/material";
import { BaseOptionChart } from "@/components/project/charts";
import { merge } from "lodash";

const IconWrapperStyle = styled("div")(({ theme }) => ({
  width: 9,
  height: 9,
  display: "flex",
  marginRight: "4px",
  borderRadius: "50%",
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.8),
}));

const CHART_HEIGHT = 72;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
  width: 60,
  height: CHART_HEIGHT,
  marginTop: theme.spacing(1.5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    display: "none",
  },
}));

export default function SummaryPanel({ panelInfo }) {
  const theme = useTheme();

  const labels = panelInfo.data?.map((item) => item.K);
  const seriesData = panelInfo.data?.map((item) => item.V);

  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      alpha(theme.palette.success.main, 0.8),
      alpha(theme.palette.warning.main, 0.8),
      alpha(theme.palette.error.main, 0.8),
      alpha(theme.palette.info.main, 0.8),
      alpha(theme.palette.primary.main, 0.8),
    ],
    labels,
    stroke: { colors: [theme.palette.background.paper], thickness: "1px" },
    legend: { floating: true, horizontalAlign: "center" },
    dataLabels: { enabled: false, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        title: {
          formatter: (seriesName: string) => ``,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });
  return (
    <Card
      sx={{ alignItems: "center", width: "33%", px: 3, py: 2, height: "134px" }}
    >
      <Typography variant="subtitle2">{panelInfo.title}</Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Grid container>
            {panelInfo.data?.map((item, index) => {
              return (
                <Grid key={index} item xs={12}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={0.25}
                    sx={{ mt: panelInfo.data.length > 4 ? 0.15 : 0.5, mr: 1 }}
                  >
                    <IconWrapperStyle />
                    <Typography
                      component="span"
                      variant="subtitle2"
                      fontSize={panelInfo.data.length > 4 ? 10 : 11}
                    >
                      {item.K}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="span"
                      sx={{ color: "text.secondary" }}
                    >
                      {`(${item.V})`}
                    </Typography>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        {seriesData && (
          <ChartWrapperStyle dir="ltr">
            <ReactApexChart
              type="donut"
              series={seriesData}
              options={chartOptions}
              height={90}
            />
          </ChartWrapperStyle>
        )}
      </Stack>
    </Card>
  );
}

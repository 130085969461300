import { useLocation } from "react-router";
import { Dialog, Typography, Stack, Button, Box } from "@mui/material";
import { dispatch, useSelector, RootState } from "@/redux/store";
import { useSnackbar } from "notistack";
import {
  editAppVersion,
  getAppVersionList,
} from "@/redux/project/mlService/appGallery/thunks";

interface IVersionRestoreDialog {
  isOpen: boolean;
  onClose: VoidFunction;
  versionInfo: Record<string, any>;
}

const VersionRestoreDialog = ({
  isOpen,
  onClose,
  versionInfo,
}: IVersionRestoreDialog) => {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    mlAppDescribeData: { data: appDetail },
    mlAppVersionDatas: {
      data: { items: versionData },
    },
  } = useSelector((state: RootState) => state.appGallery);

  const handleRestore = async () => {
    // not started/starting -> wait_start
    // other stared/starting -> wait_close
    if (versionInfo.status !== "stared" && versionInfo.status !== "starting") {
      await dispatch(
        editAppVersion({
          id: versionInfo.id,
          params: { status: "wait_start" },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      );
    }
    // eslint-disable-next-line array-callback-return
    await Promise.all(
      versionData
        .filter((item) => item.id !== versionInfo.id)
        .map((version) => {
          if (version.status === "started" || version.status === "starting") {
            dispatch(
              editAppVersion({
                id: version.id,
                params: { status: "wait_close" },
                alertCallback: enqueueSnackbar,
                pathname,
              })
            ).unwrap();
          }
        })
    );

    // get version
    await dispatch(
      getAppVersionList({
        params: {
          page_num: 1,
          page_size: 9999,
          is_active: 1,
          mlapp_id: appDetail.id,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    onClose();
    enqueueSnackbar("Restore Success", { variant: "success" });
  };

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown
      onClose={() => {
        onClose();
      }}
      PaperProps={{
        sx: {
          width: "500px",
          maxWidth: "none",
          padding: "20px 20px",
        },
      }}
    >
      {/* <Box> */}
      <Typography variant="h6" sx={{ mb: 2 }}>
        Deploy ML App - {appDetail?.name}
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Typography variant="subtitle2" sx={{ mb: 2 }}>
          Are you sure you want to deploy this ML App version?
        </Typography>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="subtitle2">{versionInfo.created_at}</Typography>
          <Typography variant="body2" color="text.secondary">
            {versionInfo.remark}
          </Typography>
        </Box>
      </Box>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="flex-end"
        sx={{ my: 2 }}
      >
        <Button
          type="submit"
          variant="contained"
          sx={{ width: "100px", color: "background.paper" }}
          onClick={handleRestore}
        >
          Yes, Do it
        </Button>
        <Button
          type="button"
          color="inherit"
          variant="outlined"
          onClick={() => {
            onClose();
          }}
          sx={{ width: "100px", color: "text.secondary" }}
        >
          Cancel
        </Button>
      </Stack>
      {/* </Box> */}
    </Dialog>
  );
};

export default VersionRestoreDialog;

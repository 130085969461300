// routes
import Router from "@/routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
// @mui
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";

// components
import ScrollToTop from "@/components/project/ScrollToTop";
import ThemePrimaryColor from "@/components/project/ThemePrimaryColor";
import ThemeLocalization from "@/components/project/ThemeLocalization";
import SnackbarProvider from "@/components/project/snackbar/SnackbarProvider";
import { BaseOptionChartStyle } from "@/components/project/charts/BaseOptionChart";
import { ProgressBarStyle } from "@/components/project/LoadingScreen";

import "./global.css";
import { useEffect, useState } from "react";
import { dispatch } from "./redux/store";
import { setCommonData } from "./redux/project/common/slice";

// ----------------------------------------------------------------------

export default function App() {
  const [globalConfig, setGlobalConfig] = useState<Record<any, any>>({});

  useEffect(() => {
    const config_url =
      process.env.NODE_ENV === "development"
        ? "/static/config.json"
        : "/ui/static/config.json";
    fetch(config_url)
      .then((r) => r.json())
      .then((config) => {
        dispatch(setCommonData({ config }));
        setGlobalConfig(config);
        // console.log('config', config);
      });
  }, []);

  if (globalConfig.common === undefined) {
    return <div></div>;
  }
  if (
    globalConfig.common.OAUTH_AUTHORITY === null ||
    globalConfig.common.OAUTH_CLIENT_ID === null
  ) {
    return <div>Missing OAUTH_AUTHORITY or OAUTH_CLIENT_ID in config.json</div>;
  }

  const oidcConfig = {
    authority: globalConfig?.common?.OAUTH_AUTHORITY,
    client_id: globalConfig?.common?.OAUTH_CLIENT_ID,
    redirect_uri: `${window.location.origin}/ui/`,
    response_type: "code",
    scope: "openid",
    post_logout_redirect_uri: `${window.location.origin}/ui/`,
    userStore: new WebStorageStateStore({ store: localStorage }),
    onSigninCallback: () => {
      window.history.replaceState({}, document.title, window.location.pathname);
    },
  };

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AuthProvider {...oidcConfig}>
              <SnackbarProvider>
                <GlobalStyles />
                <ProgressBarStyle />
                <BaseOptionChartStyle />
                <ScrollToTop />
                <Router globalConfig={globalConfig} />
              </SnackbarProvider>
            </AuthProvider>
          </LocalizationProvider>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}

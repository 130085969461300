import { Box, Divider } from "@mui/material";
import { RootState, useSelector } from "@/redux/store";
//
import { SchemaNodeEntity } from "../../../../graph/slices/slice";
import StepConfig from "./components/StepConfig";
import TrainingProcessor from "./components/TrainingProcessor";
import TrainingInputs from "./components/TrainingInputs";
import TrainingOutputs from "./components/TrainingOutputs";
import { TrainingStep } from "@/@types/project/mlPipeline/Kubeflow/pipeline-training";
import TrainingCodeArgs from "./components/TrainingCodeArgs";

// ----------------------------------------------------------------------

type TrainingStepProps = {
  entity: SchemaNodeEntity;
  isEdit: boolean;
};

export default function TrainingStepEntry({
  entity,
  isEdit,
}: TrainingStepProps) {
  const currentStep = useSelector((state: RootState) => {
    let step: TrainingStep | undefined = state.schema.schema.pipelineSteps.find(
      (step) => {
        return step.id === entity.id;
      }
    ) as TrainingStep;
    return step ? step : null;
  });

  return (
    <>
      {entity && (
        <Box>
          <StepConfig isEdit={isEdit} currentStep={currentStep} />

          <Divider />

          <TrainingCodeArgs isEdit={isEdit} currentStep={currentStep} />

          <Divider />

          <TrainingProcessor isEdit={isEdit} currentStep={currentStep} />

          <Divider />

          <TrainingInputs isEdit={isEdit} currentStep={currentStep} />

          <Divider />

          <TrainingOutputs isEdit={isEdit} currentStep={currentStep} />

          <Divider sx={{ mb: 1 }} />
        </Box>
      )}
    </>
  );
}

import { Schema } from "../../slices/slice";
import {
  ConditionStep,
  ConditionStruct,
  LeftRightValueStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-condition";
import {
  StepInfo,
  PipelineManager,
} from "@/@types/project/mlPipeline/SageMaker/pipeline";
import generateStep, { generateUri } from "./helper-functions";

// ----------------------------------------------------------------------

export default async function generateConditionStep(
  step: ConditionStep,
  schema: Schema,
  pipeline: PipelineManager
) {
  // console.log(step.conditions);
  return {
    Name: step.name,
    Type: step.type,
    Arguments: {
      Conditions: step.conditions.map((condition: ConditionStruct) => ({
        Type: condition.conditionType,
        LeftValue: genLeftRightValue(condition.leftValue),
        RightValue: genLeftRightValue(condition.rightValue),
      })),
      IfSteps: await formIfElseSteps(step.ifSteps, schema, pipeline),
      ElseSteps: await formIfElseSteps(step.elseSteps, schema, pipeline),
    },
  } as StepInfo;
}

const genLeftRightValue = (sideValue: LeftRightValueStruct) => {
  return sideValue.source === "value"
    ? generateUri(sideValue.value, sideValue.valueUseParam, "number")
    : {
        "Std:JsonGet": {
          PropertyFile: generateUri(sideValue.value, sideValue.valueUseParam),
          Path: generateUri(sideValue.jsonKey, sideValue.jsonKeyUseParam),
        },
      };
};

const formIfElseSteps = async (
  stepNames: string[],
  schema: Schema,
  pipeline: PipelineManager
) => {
  const ifElseSteps = [] as any;

  // Add inputs
  for (const stepName of stepNames) {
    const currentStep = schema.pipelineSteps.find(
      (step) => step.name === stepName
    );
    if (currentStep) {
      ifElseSteps.push(await generateStep(currentStep, schema, pipeline));
    }
  }

  // stepNames.forEach((stepName) => {
  //   const currentStep = schema.pipelineSteps.find(
  //     (step) => step.name === stepName
  //   );
  //   if (currentStep) {
  //     ifElseSteps.push(generateStep(currentStep, schema, pipeline));
  //   }
  // });
  return ifElseSteps;
};

/* eslint-disable react-hooks/exhaustive-deps */
import Page from "@/components/project/Page";
import { PATH_DASHBOARD } from "@/routes/paths";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router";
import NotebookVersionTable from "./components/notebookVersion/NotebookVersionTable";
import { RootState, dispatch } from "@/redux/store";
import { getNotebookDetailData } from "@/redux/project/experiment/thunks";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

const NotebookVersion = () => {
  const { notebookId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const {
    notebookDetail: { data },
  } = useSelector((state: RootState) => state.experiment);

  const getLinks = () => {
    return [
      {
        name: "Experiment",
      },
      {
        name: data?.name || "",
        href: PATH_DASHBOARD.experiment.notebook,
      },
      {
        name: "Notebook Version",
      },
    ];
  };

  useEffect(() => {
    dispatch(
      getNotebookDetailData({
        id: notebookId!,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  }, []);

  return (
    <Page
      title="Notebook Version | OmniML"
      heading="Notebook Version"
      links={getLinks()}
    >
      <NotebookVersionTable />
    </Page>
  );
};

export default NotebookVersion;

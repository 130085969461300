/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TablePagination,
  Typography,
  Box,
  Button,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material";
import Scrollbar from "@/components/project/Scrollbar";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { extractKeys } from "@/utils/project/utils";
import MlCard from "@/components/project/mlComponents/MlCard";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "@/redux/store";
import { getMlRuntimeList } from "@/redux/project/mlService/mlRuntime/thunks";
import moment from "moment";
import { isEmpty } from "lodash";
import MlTableBodyContainer from "@/components/project/mlComponents/MlTableBodyContainer";
import MlRuntimeTableActionCol from "./MlRuntimeTableActionCol";
import MlRuntimeScenarioLabel from "../MlRuntimeScenarioLabel";

interface Column {
  id: string;
  label?: string;
  minWidth?: number;
  width?: number;
  maxWidth?: number;
}

const columns: Column[] = [
  { id: "name", label: "Service Name", minWidth: 250 },
  { id: "service_type", label: "Type", minWidth: 150 },
  { id: "scenario", label: "Scenario", minWidth: 150 },
  { id: "mp_external_id", label: "Model Package External Id", minWidth: 150 },
  { id: "", minWidth: 100 },
];

const MlRuntimeTable: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const {
    mlRuntimeTableList: { data, loading },
  } = useSelector((state: RootState) => state.mlRuntime);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage + 1);
    fetchData({ page_num: page, page_size: rowsPerPage, is_active: 1 });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    fetchData({
      page_num: 1,
      page_size: parseInt(event.target.value, 10),
      is_active: 1,
    });
  };

  const fetchData = (params: {
    page_num: number;
    page_size: number;
    is_active: number;
  }) => {
    return dispatch(
      getMlRuntimeList({
        params,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  };

  const handleRefresh = () => {
    fetchData({ page_num: 1, page_size: 10, is_active: 1 });
  };

  useEffect(() => {
    fetchData({ page_num: 1, page_size: 10, is_active: 1 });
  }, []);

  const timeDiff = (time) => {
    if (time) {
      const now = new Date();
      const duration = moment.duration(moment(now).diff(moment(time)));
      return duration.days();
    }
  };

  const renderCell = (row, column) => {
    switch (column.id) {
      case "name":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="subtitle2" sx={{ cursor: "pointer" }}>
              {row[column.id] || "-"}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              {/* <SquareIcon
                color={row.priority === "High" ? "error" : row.priority === "Medium" ? "warning" : "secondary"}
                sx={{ borderRadius: 4, width: 16, height: 16 }}
              />
              <Typography variant="body2" color={theme.palette.text.secondary}>{row.priority || '-'}</Typography>
              <Divider orientation="vertical" flexItem /> */}
              <Typography
                variant="body2"
                color={theme.palette.text.secondary}
              >{`Running for ${
                !isEmpty(row.created_at) ? timeDiff(row.created_at) : "-"
              } days`}</Typography>
            </Stack>
          </TableCell>
        );
      case "scenario":
        const scenario = row[column.id];
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <MlRuntimeScenarioLabel>{scenario || ""}</MlRuntimeScenarioLabel>
          </TableCell>
        );
      case "":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width"), pr: 2 }}
          >
            <MlRuntimeTableActionCol row={row} fetchData={fetchData} />
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {row[column.id] || "-"}
            </Typography>
          </TableCell>
        );
    }
  };

  return (
    <Box>
      <MlCard
        title="ML Runtime"
        extraJsx={
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button
              variant="contained"
              sx={{ width: 150 }}
              onClick={() => navigate("mlRuntimeCreate")}
            >
              Add Runtime
            </Button>
            <IconButton onClick={handleRefresh}>
              <RefreshOutlinedIcon sx={{ width: "22px", height: "22px" }} />
            </IconButton>
          </Stack>
        }
        hasPaddingX={false}
      >
        <Scrollbar>
          <TableContainer sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <MlTableBodyContainer loading={loading} data={data?.items || []}>
                <TableBody>
                  {data?.items?.map((row, index) => (
                    <TableRow key={index}>
                      {columns.map((column) => renderCell(row, column))}
                    </TableRow>
                  ))}
                </TableBody>
              </MlTableBodyContainer>
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          component="div"
          count={data?.total || 0}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </MlCard>
    </Box>
  );
};

export default MlRuntimeTable;

// material
import { Stack } from "@mui/material";
//
import Toolbar from "./Toolbar";
import GraphViewer from "../../../../graph/schema/components/GraphViewer";

// ----------------------------------------------------------------------

export default function PipelineGraphPanel() {
  const handleUpateGraphWidth = (x: number) => {
    const sideBar = document.querySelector(".pipeline-dialog-sidebar");
    if (sideBar) {
      return x - sideBar.clientWidth - 1;
    }
    return x;
  };

  const handleUpateGraphHeight = (x: number) => {
    const templateDetail = document.querySelector(
      ".new-pipeline-template-detail"
    );
    const templateDialogTitle = document.querySelector(
      ".template-dialog-title"
    );
    if (templateDetail && templateDialogTitle) {
      return (
        x - templateDetail.clientHeight - templateDialogTitle.clientHeight - 1
      );
    }
    // return x - 220;
  };

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <Toolbar />
      <GraphViewer
        containerName="new-pipeline-template"
        onChangeWidth={handleUpateGraphWidth}
        onChangeHeight={handleUpateGraphHeight}
      />
    </Stack>
  );
}

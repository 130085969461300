import PermissionDenied from "@/components/project/PermissionDenied";
import MlIframe from "@/components/project/mlComponents/MlIframe";
import { IFRAME_URL } from "@/config";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";

export default function PlatformLogging() {
  const { isAdmin, isSuperAdmin } = useSelector(
    (state: RootState) => state.common
  );

  const isJustGroupAdmin = () => {
    return !isSuperAdmin && isAdmin;
  };

  if (isJustGroupAdmin()) return <PermissionDenied />;

  return <MlIframe src={IFRAME_URL.observability.kibana} />;
}

// material
import { useTheme } from "@mui/material/styles";
import { Box, BoxProps } from "@mui/material";

// ----------------------------------------------------------------------

export default function ComingSoonIllustration({ ...other }: BoxProps) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box {...other}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 480 360"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <linearGradient
            id="BG"
            x1="19.496%"
            x2="77.479%"
            y1="71.822%"
            y2="16.69%"
          >
            <stop offset="0%" stopColor={PRIMARY_MAIN} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} stopOpacity="0" />
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g
            id="coming_soon-copy"
            transform="translate(0.000000, 0.000000)"
            fillRule="nonzero"
          >
            <path
              fill="url(#BG)"
              fillRule="nonzero"
              d="M0,198.780719 C0,240.237877 14.9453803,278.015536 39.539044,306.566479 C67.7534415,339.331025 108.667208,359.93074 154.272689,360.000328 C174.204067,360.029445 193.262054,356.113053 210.767698,348.963528 C219.819007,345.265384 229.949705,345.689815 238.71619,350.071216 C248.999284,355.203871 260.514163,358.081668 272.673313,358.081668 C277.696096,358.081668 282.614828,357.587047 287.372756,356.648253 C300.953924,353.977737 313.312766,347.65843 323.462381,338.708088 C329.840969,333.080811 338.010479,330.252367 346.359713,330.262238 L346.5016,330.262238 C374.090645,330.262238 399.717453,321.530143 420.994125,306.566479 C440.015327,293.206222 455.548223,274.87014 465.897531,253.341502 C474.920463,234.580989 480,213.387949 480,190.957807 C480,114.027201 420.225836,51.6533755 346.5016,51.6533755 C338.985818,51.6533755 331.622433,52.3157951 324.438771,53.5517999 C305.417569,21.4200619 271.355345,0 232.498407,0 C216.093172,0 200.540307,3.81768653 186.618612,10.6612269 C173.379023,17.1483328 161.607697,26.3662746 151.978332,37.5999899 C119.281371,38.1439636 89.0479809,49.2899413 64.3029715,67.8914297 C25.3514428,97.1552417 0,144.882355 0,198.780719 Z"
              id="background"
              opacity="0.2"
            ></path>
            <path
              d="M347.382005,116.476 L347.382005,244.252 C347.382005,245.07 347.223,245.879 346.91,246.634 C346.598,247.39 346.14,248.076 345.562,248.654 C344.984,249.232 344.297,249.69 343.542,250.003 C342.787,250.315 341.977,250.47583 341.16,250.474 L174.467,250.474 C173.637,250.495 172.814,250.335 172.053,250.005 C171.292,249.675 170.612,249.183 170.061,248.563 C169.51,247.943 169.1,247.21 168.862,246.415 C168.623,245.621 168.561,244.784 168.679,243.963 L168.679,116.194 C168.23,113.046 171.008,110.267786 174.467,110.267786 L341.138,110.267786 C341.956,110.264 342.767,110.422 343.524,110.732 C344.281,111.042 344.969,111.499 345.549,112.076 C346.129,112.652 346.59,113.338 346.904,114.093 C347.219,114.848 347.382005,115.658 347.382005,116.476 Z"
              fill={PRIMARY_MAIN}
              fillRule="nonzero"
            ></path>
            <path
              d="M267.906,288.487 C330.436,288.487 381.126,284.094 381.126,278.676 C381.126,273.258 330.436,268.866 267.906,268.866 C205.376,268.866 154.6863,273.258 154.6863,278.676 C154.6863,284.094 205.376,288.487 267.906,288.487 Z"
              fill={PRIMARY_DARKER}
              fillRule="nonzero"
              opacity="0.24"
            ></path>
            <polygon
              fill={PRIMARY_DARK}
              fillRule="nonzero"
              points="115.7766 82.9925 130.3695 76.9658 210.612 84.4902 210.612 92.123 115.7766 92.4052"
            ></polygon>
            <polygon
              fill={PRIMARY_MAIN}
              fillRule="nonzero"
              points="142.3001 74.6 127.9387 74.6 127.9387 274.104 142.3001 274.104"
            ></polygon>
            <path
              d="M239.117,243.326 L233.961,243.326 C233.409,243.326 232.961,243.774 232.961,244.326 L232.961,292.652 C232.961,293.205 233.409,293.652 233.961,293.652 L239.117,293.652 C239.669,293.652 240.117,293.205 240.117,292.652 L240.117,244.326 C240.117,243.774 239.669,243.326 239.117,243.326 Z"
              fill="#FFF"
              fillRule="nonzero"
            ></path>
            <polygon
              fill="#C4CDD5"
              fillRule="nonzero"
              opacity="0.5"
              points="232.828 269.587 240.034 269.587 240.034 244.909 232.676 244.909"
            ></polygon>
            <path
              d="M188.023,243.326 L182.868,243.326 C182.316,243.326 181.868,243.774 181.868,244.326 L181.868,290.967 C181.868,291.519 182.316,291.967 182.868,291.967 L188.023,291.967 C188.576,291.967 189.023,291.519 189.023,290.967 L189.023,244.326 C189.023,243.774 188.576,243.326 188.023,243.326 Z"
              fill="#FFF"
              fillRule="nonzero"
            ></path>
            <polygon
              fill="#C4CDD5"
              fillRule="nonzero"
              opacity="0.5"
              points="181.919 269.587 189.125 269.587 189.125 244.909 181.767 244.909"
            ></polygon>
            <path
              d="M334.788,129.564 L277.873,129.564 C276.216,129.564 274.873,130.907 274.873,132.564 L274.873,216.436 C274.873,218.093 276.216,219.436 277.873,219.436 L334.788,219.436 C336.445,219.436 337.788,218.093 337.788,216.436 L337.788,132.564 C337.788,130.907 336.445,129.564 334.788,129.564 L334.788,129.564 Z"
              fill={PRIMARY_DARKER}
              fillRule="nonzero"
              opacity="0.48"
            ></path>
            <path
              d="M264.689,129.564 C266.346,129.564 267.689,130.907 267.689,132.564 L267.689,216.451 C267.689,218.108 266.346,219.451 264.689,219.451 L207.774,219.451 C206.117,219.451 204.774,218.108 204.774,216.451 L204.774,216.135 C204.774,214.478 206.117,213.135 207.774,213.135 L237.276,213.135 C238.933,213.135 240.276,211.792 240.276,210.135 L240.276,132.564 C240.276,130.907 241.619,129.564 243.276,129.564 L264.689,129.564 Z"
              fill={PRIMARY_DARKER}
              fillRule="nonzero"
              opacity="0.48"
            ></path>
            <path
              d="M239.077,123.349 L182.162,123.349 C180.505,123.349 179.162,124.692 179.162,126.349 L179.162,208.637 C179.162,210.294 180.505,211.637 182.162,211.637 L239.077,211.637 C240.734,211.637 242.077,210.294 242.077,208.637 L242.077,126.349 C242.077,124.692 240.734,123.349 239.077,123.349 Z"
              fill={PRIMARY_DARKER}
              fillRule="nonzero"
            ></path>
            <path
              d="M206.995,92.0145 L208.044,92.0145 L208.044,117.008 C217.122,116.889 228.072,118.09 240.308,122.049 C240.833,122.219 241.121,122.782 240.951,123.308 C240.781,123.833 240.218,124.121 239.692,123.951 C222.47,118.379 207.873,118.383 197.595,119.772 C192.455,120.467 188.395,121.508 185.627,122.373 C184.244,122.805 183.183,123.192 182.473,123.47 C182.118,123.609 181.851,123.72 181.674,123.796 C181.586,123.833 181.521,123.862 181.478,123.881 L181.432,123.902 L181.422,123.906 L181.421,123.907 C180.92,124.139 180.325,123.922 180.093,123.421 C179.86,122.92 180.078,122.326 180.579,122.093 L181,123 C180.579,122.093 180.579,122.093 180.58,122.092 L180.582,122.092 L180.587,122.089 L180.603,122.082 L180.663,122.055 C180.714,122.032 180.789,121.999 180.887,121.957 C181.082,121.873 181.369,121.754 181.745,121.607 C182.496,121.314 183.6,120.91 185.031,120.463 C187.893,119.57 192.064,118.502 197.328,117.79 C200.23,117.398 203.464,117.114 206.995,117.028 L206.995,92.0145 Z"
              id="Path"
              fill="#FFFFFF"
            ></path>
            <path
              d="M222.377,128.963 L185.37,128.963 C184.503,128.963 183.8,129.666 183.8,130.533 L183.8,130.541 C183.8,131.408 184.503,132.111 185.37,132.111 L222.377,132.111 C223.244,132.111 223.947,131.408 223.947,130.541 L223.947,130.533 C223.947,129.666 223.244,128.963 222.377,128.963 L222.377,128.963 Z"
              fill={PRIMARY_MAIN}
              fillRule="nonzero"
            ></path>
            <path
              d="M205.222,134.628 L184.675,134.628 C184.192,134.628 183.8,135.02 183.8,135.504 C183.8,135.987 184.192,136.379 184.675,136.379 L205.222,136.379 C205.706,136.379 206.098,135.987 206.098,135.504 C206.098,135.02 205.706,134.628 205.222,134.628 Z"
              fill={PRIMARY_MAIN}
              fillRule="nonzero"
            ></path>
            <path
              d="M214.512,138.977 L193.965,138.977 C193.481,138.977 193.089,139.369 193.089,139.852 C193.089,140.335 193.481,140.727 193.965,140.727 L214.512,140.727 C214.996,140.727 215.387,140.335 215.387,139.852 C215.387,139.369 214.996,138.977 214.512,138.977 Z"
              fill={PRIMARY_MAIN}
              fillRule="nonzero"
            ></path>
            <path
              d="M210.627,134.628 L209.079,134.628 C208.603,134.628 208.218,135.014 208.218,135.489 L208.218,135.496 C208.218,135.972 208.603,136.357 209.079,136.357 L210.627,136.357 C211.103,136.357 211.488,135.972 211.488,135.496 L211.488,135.489 C211.488,135.014 211.103,134.628 210.627,134.628 Z"
              fill={PRIMARY_MAIN}
              fillRule="nonzero"
            ></path>
            <path
              d="M215.344,134.628 L213.796,134.628 C213.32,134.628 212.935,135.014 212.935,135.489 L212.935,135.496 C212.935,135.972 213.32,136.357 213.796,136.357 L215.344,136.357 C215.82,136.357 216.205,135.972 216.205,135.496 L216.205,135.489 C216.205,135.014 215.82,134.628 215.344,134.628 Z"
              fill={PRIMARY_MAIN}
              fillRule="nonzero"
            ></path>
            <path
              d="M188.604,138.977 L187.056,138.977 C186.58,138.977 186.195,139.362 186.195,139.838 L186.195,139.845 C186.195,140.32 186.58,140.706 187.056,140.706 L188.604,140.706 C189.079,140.706 189.465,140.32 189.465,139.845 L189.465,139.838 C189.465,139.362 189.079,138.977 188.604,138.977 Z"
              fill={PRIMARY_MAIN}
              fillRule="nonzero"
            ></path>
            <path
              d="M220.061,134.628 L218.513,134.628 C218.038,134.628 217.652,135.014 217.652,135.489 L217.652,135.496 C217.652,135.972 218.038,136.357 218.513,136.357 L220.061,136.357 C220.537,136.357 220.922,135.972 220.922,135.496 L220.922,135.489 C220.922,135.014 220.537,134.628 220.061,134.628 Z"
              fill={PRIMARY_MAIN}
              fillRule="nonzero"
            ></path>
            <polygon
              fill={PRIMARY_MAIN}
              fillRule="nonzero"
              points="235.414 225.608 175.943 225.608 175.943 234.637 235.414 234.637"
            ></polygon>
            <polygon
              fill={PRIMARY_MAIN}
              fillRule="nonzero"
              points="245.182 247.636 175.182 247.636 175.182 265.818 245.182 265.818"
            ></polygon>
            <path
              d="M175.49,246.9 C174.386,246.9 173.49,247.796 173.49,248.9 L173.49,264.677 C173.49,265.783 174.388,266.679 175.494,266.677009 L245.165,266.54 C246.286,266.538 247.186,265.615 247.161581,264.494 L246.803,248.862 C246.778,247.775 245.89,246.907 244.804,246.907 L175.49,246.9 Z M175.378,251.418 C175.378,250.313 176.274,249.418 177.378,249.418 L182.596,249.418 C184.246,249.418 185.186,251.304 184.193,252.622 L175.769,263.801 C175.728,263.856 175.664,263.888 175.596,263.888 C175.476,263.888 175.378,263.791 175.378,263.671 L175.378,251.418 Z M192.764,250.22 C193.142,249.715 193.735,249.418 194.366,249.418 L196.57,249.418 C198.216,249.418 199.157,251.294 198.174,252.613 L190.368,263.083 C189.991,263.59 189.397,263.888 188.765,263.888 L186.529,263.888 C184.882,263.888 183.941,262.008 184.928,260.689 L192.764,250.22 Z M206.714,250.23 C207.091,249.72 207.688,249.418 208.323,249.418 L210.525,249.418 C212.171,249.418 213.112,251.295 212.128,252.614 L204.317,263.084 C203.94,263.59 203.346,263.888 202.714,263.888 L200.596,263.888 C198.955,263.888 198.013,262.021 198.987,260.7 L206.714,250.23 Z M220.778,250.222 C221.156,249.716 221.75,249.418 222.381,249.418 L224.56,249.418 C226.21,249.418 227.15,251.303 226.158,252.621 L218.274,263.091 C217.896,263.593 217.304,263.888 216.676,263.888 L214.571,263.888 C212.925,263.888 211.984,262.011 212.968,260.692 L220.778,250.222 Z M234.735,250.23 C235.112,249.719 235.709,249.418 236.344,249.418 L238.539,249.418 C240.184,249.418 241.126,251.295 240.142,252.614 L232.331,263.084 C231.954,263.59 231.359,263.888 230.728,263.888 L228.612,263.888 C226.97,263.888 226.028,262.02 227.003,260.7 L234.735,250.23 Z M244.595,261.884 C244.595,262.988 243.696,263.888 242.591,263.888 C240.947,263.888 240.002,262.008 240.983,260.688 C242.132,259.142 244.595,259.957 244.595,261.884 L244.595,261.884 Z"
              id="Shape"
              fill="#FFFFFF"
            ></path>
            <path
              d="M130.6372,280.275 C130.5036,281.5 130.0828,282.677 129.409,283.709 C128.7351,284.741 127.8273,285.6 126.7593,286.215 C124.4296,285.542 122.1434,284.804 119.8572,283.95 C118.6779,283.516 117.5275,283.06 116.3699,282.561 C109.8804,279.817 103.7228,276.346 98.0149,272.215 C97.6394,271.108 97.3491,269.974 97.1467,268.822 C96.9574,267.676 97.1056,266.499 97.5732,265.435 C98.0408,264.372 98.8076,263.467 99.7802,262.832 C100.7462,262.369 101.6771,261.837 102.5656,261.24 C104.5046,259.648 104.5263,256.624 103.5496,254.338 C102.5729,252.051 100.7786,250.178 99.4763,248.043 C98.174,245.909 97.3058,243.232 98.3404,240.968 C99.0205,239.521 100.3879,238.464 101.2344,237.068 C102.8406,234.456 102.3196,231.106 101.7336,228.097 C100.9522,224.009 100.1636,219.936 99.3606,215.841 C98.8613,213.185 98.4851,210.053 100.4458,208.172 C103.1083,205.647 107.4999,207.672 110.1406,210.197 C113.2932,213.075 115.6167,216.745 116.8691,220.825 C119.3507,229.732 115.017,239.752 118.497,248.318 C120.8266,254.106 126.2963,258.056 129.0166,263.649 C131.4837,268.757 131.4403,274.74 130.6372,280.275 L130.6372,280.275 Z"
              fill={PRIMARY_DARKER}
              fillRule="nonzero"
            ></path>
            <path
              d="M142.2638,254.294 C141.1569,256.559 140.3683,259.359 141.6995,261.529 C142.3782,262.374 143.1028,263.181 143.87,263.946 C146.764,267.476 145.2374,272.787 142.9729,276.73 C142.2771,277.723 141.8237,278.865 141.6489,280.065 C141.5621,281.954 142.9295,283.524 144.0653,285.028 C145.2012,286.533 146.026,288.422 145.2591,289.992 C136.597,288.951 128.0803,286.934 119.8717,283.979 C118.6924,283.545 117.542,283.089 116.3844,282.59 C116.1457,279.957 116.9777,277.207 118.0412,274.726 C119.2205,272.005 121.0293,269.177 121.4851,266.189 C121.8975,263.468 121.42,260.922 122.2664,258.18 C123.0666,255.509 124.4719,253.059 126.3729,251.02 C128.2739,248.981 130.6195,247.408 133.2274,246.423 C134.754,245.895 136.4252,245.526 137.5684,244.419 C138.349,243.581 138.9564,242.598 139.3554,241.525 C141.4005,237.082 143.4311,232.626 145.4472,228.154 C146.3878,226.107 147.6177,223.814 149.8678,223.452 C151.5897,223.17 153.3116,224.305 154.1581,225.825 C154.9363,227.404 155.2043,229.187 154.925,230.925 C153.9555,239.839 146.2503,246.278 142.2638,254.294 L142.2638,254.294 Z"
              fill={PRIMARY_MAIN}
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </svg>
    </Box>
  );
}

import request from "@/utils/project/request";

// 1.connection
export const getConnectionListService = (params) => {
  return request.get(`/govern/connection`, { ...params, is_active: 1 });
};

export const getConnectionDetailService = (id) => {
  return request.get(`/govern/connection/${id}`);
};

export const createConnectionService = (data) => {
  return request.post(`/govern/connection`, { payload: data });
};

export const updateConnectionService = (id, data) => {
  return request.put(`/govern/connection/${id}`, { payload: data });
};

export const delConnectionService = (id) => {
  return request.put(`/govern/connection/${id}`, { payload: { is_active: 0 } });
};

// 2.credential
export const getCredentialListService = (params) => {
  return request.get(`/govern/credential`, { ...params, is_active: 1 });
};

export const getCredentialDetailService = (id) => {
  return request.get(`/govern/credential/${id}`);
};

export const createCredentialService = (data) => {
  return request.post(`/govern/credential`, { payload: data });
};

export const updateCredentialService = (id, data) => {
  return request.put(`/govern/credential/${id}`, { payload: data });
};

export const delCredentialService = (id) => {
  return request.put(`/govern/credential/${id}`, { payload: { is_active: 0 } });
};

// 3. rel_connection_credential
export const getRelConnCredentialListService = (params) => {
  return request.get(`/govern/rel_connection_credential`, {
    ...params,
    is_active: 1,
  });
};

export const createRelConnCredentialService = (data) => {
  return request.post(`/govern/rel_connection_credential`, { payload: data });
};

import { Icon } from "@iconify/react";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { Form, FormikProvider } from "formik";
import { useState } from "react";
// material
import {
  Radio,
  Stack,
  Divider,
  Collapse,
  RadioGroup,
  Typography,
  FormControlLabel,
} from "@mui/material";
//
import PipelineParameterEntry from "../../components/PipelineParameterEntry";
// import PipelineParameterWithSelector from "../../components/PipelineParameterWithSelector";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { PipelineTextfield } from "../../components/PipelineComponents";
// import { estimatorTypes } from "../helper-functions";

// ----------------------------------------------------------------------
const modelTypes = ["Model", "Pipeline Model"];

type Props = {
  formik: any;
};

export default function ModelConfiguration({ formik }: Props) {
  const [isCollapse, setCollapse] = useState(true);
  const { handleSubmit, getFieldProps } = formik;

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setCollapse((prev) => !prev)}
        endIcon={
          <Icon
            icon={isCollapse ? arrowIosForwardFill : arrowIosDownwardFill}
            width={16}
            height={16}
          />
        }
      >
        Model config
      </CollapseButtonStyle>

      <Collapse in={!isCollapse} sx={{ mx: 2, mb: !isCollapse ? 0.5 : 0 }}>
        <Stack spacing={1} sx={{ my: 1 }}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onChange={handleSubmit}>
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                  fontWeight: 600,
                  mt: -0.6,
                  mb: 2,
                }}
              >
                Model type
              </Typography>

              <RadioGroup {...getFieldProps("modelType")} row>
                <Stack
                  spacing={0.2}
                  direction="row"
                  sx={{ ml: 1, mt: -1, mb: -0.5 }}
                >
                  {modelTypes.map((modelType) => (
                    <FormControlLabel
                      key={modelType}
                      value={modelType}
                      control={<Radio size="small" />}
                      label={modelType}
                    />
                  ))}
                </Stack>
              </RadioGroup>

              <Divider sx={{ borderStyle: "dashed", my: 1 }} />

              <Stack spacing={2} sx={{ mt: 2.5, mb: 0.5 }}>
                <PipelineParameterEntry
                  label={"Model data url"}
                  field={"modelData"}
                  fieldUseParam={"modelDataUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Model package group name"}
                  field={"modelPackageGroupName"}
                  fieldUseParam={"modelPackageGroupNameUseParam"}
                  formik={formik}
                />

                {/* <PipelineParameterWithSelector
                  label={"Docker container"}
                  field={"container"}
                  placeholder={"Select a docker image"}
                  fieldUseParam={"containerUseParam"}
                  options={estimatorTypes}
                  formik={formik}
                /> */}

                <PipelineParameterEntry
                  label={"Docker container"}
                  field={"container"}
                  fieldUseParam={"containerUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Inference instance type"}
                  field={"inferenceInstanceType"}
                  fieldUseParam={"inferenceInstanceTypeUseParam"}
                  placeholder={"xx.xx.xxx,xx.xx.xxx,..."}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Transform instance type"}
                  field={"transformInstanceType"}
                  fieldUseParam={"transformInstanceTypeUseParam"}
                  placeholder={"xx.xx.xxx,xx.xx.xxx,..."}
                  formik={formik}
                />

                <PipelineTextfield
                  label={"Input MIME type"}
                  field={"contentType"}
                  placeholder={"application/json,text/csv,..."}
                  formik={formik}
                />

                <PipelineTextfield
                  label={"Output MIME type"}
                  field={"responseType"}
                  placeholder={"application/json,text/csv,..."}
                  formik={formik}
                />

                {/* <PipelineSelect
                  label={"Input MIME type"}
                  field={"contentType"}
                  placeholder={"Select an MIME type"}
                  options={ContentTypes}
                  getFieldProps={getFieldProps}
                />

                <PipelineSelect
                  label={"Output MIME type"}
                  field={"responseType"}
                  placeholder={"Select an MIME type"}
                  options={ContentTypes}
                  getFieldProps={getFieldProps}
                /> */}
              </Stack>
            </Form>
          </FormikProvider>
        </Stack>
      </Collapse>
    </>
  );
}

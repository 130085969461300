import PermissionDenied from "@/components/project/PermissionDenied";
import MlIframe from "@/components/project/mlComponents/MlIframe";
import { IFRAME_URL } from "@/config";
import { RootState } from "@/redux/store";
import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";

export default function PlatformMetrics() {
  const theme = useTheme();
  const { isAdmin, isSuperAdmin } = useSelector(
    (state: RootState) => state.common
  );

  const isJustGroupAdmin = () => {
    return !isSuperAdmin && isAdmin;
  };

  if (isJustGroupAdmin()) return <PermissionDenied />;

  return (
    <MlIframe
      src={`${IFRAME_URL.observability.grafana}&var-namespace=omniml&theme=${theme.palette.mode}`}
    />
  );
}

import { Icon } from "@iconify/react";
import { useState } from "react";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import { RootState, useSelector } from "@/redux/store";
import { Box, Card, Divider, Stack } from "@mui/material";
import EmptyContent from "@/components/project/EmptyContent";
import { CollapseButtonStyle } from "../../../../pipeline-construction/SettingSidebar";
import ExecutionStepSelector from "./components/ExecutionStepSelector";
import InputsTable from "./components/partComponents/InputsTable";
import OutputsTable from "./components/partComponents/OutputsTable";
import ContainerTable from "./components/partComponents/ContainerTable";
import OtherSpecsTable from "./components/partComponents/OtherSpecsTable";

export default function StepDetial() {
  const [showStepDetail, setShowStepDetail] = useState(false);
  const [selectedStepName, setSelectedStepName] = useState("");
  // only one type now (multiple steps not concerned)
  // const [currentStep, setCurrentStep] = useState<Record<any, any>>({});
  // console.log('currentStep', currentStep);
  const { pipelineSteps } = useSelector(
    (state: RootState) => state.schema.schema
  );
  const curStepAllGraphData =
    pipelineSteps?.find((item) => item.name === selectedStepName) || {};

  const { executionSteps } = useSelector(
    (state: RootState) => state.execution.executionGraph
  );

  const stepType = useSelector((state: RootState) => {
    const execSteps = state.execution.executionGraph.executionSteps.filter(
      // may be sone same steps (multip)
      (step) => step.name === selectedStepName
    );
    return execSteps.length > 0 ? execSteps[0].type : null;
  });

  const {
    pipelineExecutionDetail: { data, loading },
  } = useSelector((state: RootState) => state.automation);
  const curStepAllExecutionData =
    data?.steps?.find((item) => item.name === selectedStepName) || {};

  const getInputsRows = () => {
    return curStepAllGraphData?.inputs || [];
  };

  const getOutputsRows = () => {
    return curStepAllGraphData?.outputs || [];
  };

  const getContainerRows = () => {
    const { container = {} } = curStepAllExecutionData;
    return [
      {
        image: container?.image || "",
        codePath: container?.command?.[1] || "",
        arguments: container?.args?.join(", ") || "",
      },
    ];
  };

  const getOtherSpecsRows = () => {
    const { container: { resources = {} } = {} } = curStepAllExecutionData;

    return [
      {
        name: "CPU",
        value: resources?.limits?.cpu || "",
      },
      {
        name: "MEM",
        value: resources?.limits?.memory || "",
      },
      {
        name: "PVC",
        value: curStepAllExecutionData?.volumes
          ?.map((item) => item?.persistentVolumeClaim?.claimName || "")
          .join(", "),
      },
    ];
  };

  return (
    <Box sx={{ justifyContent: "flex-end" }}>
      <CollapseButtonStyle
        disableRipple
        color="inherit"
        onClick={() => setShowStepDetail(!showStepDetail)}
        startIcon={
          <Icon
            icon={showStepDetail ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
        sx={{ mr: 0, "&:hover": { backgroundColor: "transparent" } }}
      >
        Execution Details
      </CollapseButtonStyle>

      {/* {showStepDetail && <ExecutionDetailPanelLog executionStep={currentStep} />} */}

      {showStepDetail && (
        <>
          <ExecutionStepSelector
            selectedStepName={selectedStepName}
            onSetSelectedStepName={(name) => {
              setSelectedStepName(name);
            }}
          />

          {selectedStepName && (
            <Stack spacing={2}>
              <InputsTable
                title={`${stepType} Inputs (${getInputsRows()?.length || 0})`}
                rows={getInputsRows()}
              />

              <OutputsTable
                title={`${stepType} Outputs (${getOutputsRows()?.length || 0})`}
                rows={getOutputsRows()}
              />

              <ContainerTable
                title="Container Specification"
                rows={getContainerRows()}
              />

              <OtherSpecsTable
                title={`Others (${getOtherSpecsRows()?.length || 0})`}
                rows={getOtherSpecsRows()}
              />
            </Stack>
          )}
        </>
      )}

      {showStepDetail && selectedStepName === "" && (
        <Card
          sx={{
            mx: 2,
            mb: 2,
            bgcolor: "background.neutral",
          }}
        >
          <EmptyContent
            title={"No execution details"}
            height={120}
            description={"Select an execution step first."}
            sx={{ backgroundColor: "transparent" }}
          />
        </Card>
      )}

      {!showStepDetail && (
        <Box sx={{ mx: 2 }}>
          <Divider />
        </Box>
      )}
    </Box>
  );
}

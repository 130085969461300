import React, { useEffect, useRef, useState } from "react";
import { Graph, Point } from "@antv/x6";
import "./Graph/shape";

import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import {
  updateFixNextRender,
  SchemaEdgeEntity,
  SchemaNodeEntity,
  setShowPropPanel,
  SchemaEntityRelationship,
  SchemaEntityTag,
  Schema,
} from "../../slices/slice";
import { RootState } from "@/redux/rootReducer";
import useSettings from "@/hooks/project/useSettings";
import {
  stepTypeColorMapper,
  kubeflowStepTypeColorMapper,
} from "../../../pipeline-construction/StepPopover";

let graph: Graph;

type GraphEditorProps = {
  containerName: string;
  onChangeWidth: Function;
  onChangeHeight: Function;
};

const getGraph = () => {
  return graph;
};

const GraphViewer = ({
  containerName,
  onChangeWidth,
  onChangeHeight,
}: GraphEditorProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { themeMode } = useSettings();

  const mousePoint = new Point(0, 0);
  const [showPorts, setShowPorts] = useState(false);
  const [addFirstStep, setAddFirstStep] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const {
    pipelineType,
    fixNextRender,
    showPropPanel,
    schema,
    selectedEntities,
  } = useSelector((state: RootState) => state.schema);
  const commonStepColorMap =
    pipelineType === "Kubeflow"
      ? kubeflowStepTypeColorMapper
      : stepTypeColorMapper;

  const update = (schema: Schema, selected: Array<string>) => {
    graph.getNodes().forEach((node: any) => {
      const nodeEntity = schema.pipelineSteps.find(
        (entity) => entity.id === node.id
      );
      if (!nodeEntity) {
        graph.removeCell(node);
      }
    });
    graph.getEdges().forEach((edge: any) => {
      const edgeEntity = schema.edgeEntities.find(
        (entity) => entity.id === edge.id
      );
      if (!edgeEntity) {
        graph.removeCell(edge);
      }
    });
    schema.pipelineSteps.forEach((entity) => {
      updateNodeEntityLocal(
        entity,
        schema.tags,
        selected.includes(entity.id),
        false
      );
    });

    schema.edgeEntities.forEach((entity) => {
      updateEdgeEntity(entity, schema.relationships, false);
    });
  };

  const updateEdgeEntity = (
    edgeEntity: SchemaEdgeEntity,
    relationships: Array<SchemaEntityRelationship>,
    silent: boolean
  ) => {
    var edge = graph.getEdges().find((edge) => edge.id === edgeEntity.id);
    if (!edge) {
      const allNodes = graph.getNodes();
      const sourceNode = allNodes.find(
        (node) => node.id === edgeEntity.sourceEntityId
      );
      const targetNode = allNodes.find(
        (node) => node.id === edgeEntity.targetEntityId
      );
      if (sourceNode && targetNode) {
        edge = graph.addEdge(
          {
            id: edgeEntity.id,
            shape: "schema-edge",
            router: {
              name: "manhattan",
              args: {
                offset: "center",
              },
            },
            connector: {
              name: "rounded",
              args: { radius: 10 },
            },
            source: {
              cell: sourceNode,
              port: edgeEntity.sourcePortId,
            }, // 源节点和链接桩 ID
            target: {
              cell: targetNode,
              port: edgeEntity.targetPortId,
            }, // 目标节点 ID 和链接桩 ID
          },
          { silent: silent }
        );
      }
    }

    if (edge) {
      const relationship = relationships.find(
        (relationship) => relationship.id === edgeEntity.relationship
      );
      edge.attr("bg/fill", "#000");

      if (relationship?.value) {
        edge.setLabels({
          attrs: {
            text: { text: relationship ? relationship?.value : "true" },
          },
          position: {
            distance: 0.5,
            options: {
              keepGradient: true,
              ensureLegibility: true,
            },
          },
        });
      }
    }
  };

  const updateNodeEntityLocal = (
    entity: SchemaNodeEntity,
    tags: Array<SchemaEntityTag>,
    selected: boolean,
    silent: boolean
  ) => {
    const existNode = graph.getNodes().find((node) => node.id === entity.id);

    const entityTitle = entity.name;
    const entityColor = commonStepColorMap[entity.stepType];
    const node =
      existNode != null
        ? existNode
        : graph.addNode(
            {
              id: entity.id,
              shape: "schema-node",
              x: entity.nodeX,
              y: entity.nodeY,
            },
            { silent: silent }
          );
    node.attr("label/text", entityTitle);
    node.attr("body/fill", entityColor);
    node.position(entity.nodeX, entity.nodeY);
    if (selected) {
      node.attr("body/stroke", "#C9DAFB");
    } else {
      node.attr("body/stroke", entityColor);
    }
  };

  // Init the graph. Just once.
  useEffect(() => {
    const container = containerRef.current as unknown as HTMLDivElement;
    if (graph) {
      graph.dispose();
    }
    graph = new Graph({
      container: container,
      interacting: false,
      autoResize: true,
      panning: true,
      grid: {
        visible: false,
      },
      // scroller: {
      //   enabled: true,
      //   pageVisible: false,
      //   pageBreak: false,
      //   pannable: true,
      // },
      mousewheel: {
        enabled: true,
        factor: 1.05,
        minScale: 0.3,
        maxScale: 3,
        zoomAtMousePosition: true,
        modifiers: ["ctrl", "meta"],
      },
    });
    graph.centerContent();
    window.addEventListener("resize", resize);
    dispatch(updateFixNextRender(true));
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  useEffect(() => {
    dispatch(setShowPropPanel(false));
    resize();
  }, [showPropPanel]);

  useEffect(() => {
    refreshNodesAndEdges();
  }, [themeMode]);

  useEffect(() => {
    update(schema, selectedEntities);
    refreshShowPorts();
    refreshNodesAndEdges();
    graph.getNodes().forEach((node) => updateNodeSize(node.id));
    if (graph.getNodes().length === 1 && !addFirstStep) {
      setAddFirstStep(true);
      graph.zoomToFit({ maxScale: 0.9 });
    }

    graph.centerContent();
  }, [schema]);

  useEffect(() => {
    if (fixNextRender) {
      graph.zoomToFit({ maxScale: 0.8 });
      dispatch(updateFixNextRender(false));
    }
  }, [fixNextRender]);

  const updateNodeSize = (nodeId: string) => {
    const node = graph.getNodes().find((node) => node.id === nodeId);
    if (!node) return;
    const zoom = graph.zoom();
    const nodeElements = document.getElementsByClassName("x6-node");
    const length = nodeElements.length | 0;
    for (let i = 0; i < length; i = (i + 1) | 0) {
      const nodeElement = nodeElements[i] as HTMLElement;
      if (nodeElement.dataset.cellId === nodeId) {
        const rectElement = nodeElements[i].children[1];
        if (rectElement) {
          const rect = rectElement.getBoundingClientRect();
          const width = rect.width / zoom;
          // const height = (rect.height + 16) / zoom;
          const finalWidth = width % 2 === 0 ? width + 26 : width + 25;
          // const finalHeight = height < 36 ? 36 : height;
          node.resize(finalWidth, node.getSize().height);
        }
      }
    }
  };

  const refreshNodesAndEdges = () => {
    // setLineColor(`${theme.palette.text.secondary}`);

    const nodes = graph.getNodes();
    if (nodes) {
      nodes.forEach((node) => {
        node.attr("label/fill", `${theme.palette.text.primary}`);
        node.attr("label/fontFamily", `${theme.typography.fontFamily}`);
      });
    }

    const edges = graph.getEdges();
    if (edges) {
      edges.forEach((edge) => {
        edge.attr("rect/fill", `${theme.palette.background.paper}`);
        edge.attr("text/fill", `${theme.palette.text.primary}`);
        edge.attr("text/fontFamily", `${theme.typography.fontFamily}`);
        edge.attr("line/stroke", `${theme.palette.text.secondary}`);
      });
    }
  };

  const resize = () => {
    // const graph = EditorGraph.graph;
    const chatWindow = document.querySelector(`.${containerName}`);

    let graphHeight, graphWidth;
    if (chatWindow) {
      graphWidth = onChangeWidth(chatWindow.clientWidth);
      graphHeight = onChangeHeight(chatWindow.clientHeight);
    } else {
      graphHeight = document.documentElement.clientHeight * 0.72 - 58;
      graphWidth = document.documentElement.clientWidth - 700;
    }

    graph.resize(graphWidth, graphHeight);

    graph.centerContent();
  };

  useEffect(() => {
    refreshShowPorts();
  }, [mousePoint]);

  const refreshShowPorts = () => {
    // const graph = EditorGraph.graph;
    const hasNode = graph.getNodesFromPoint(mousePoint).length > 0;
    setShowPorts(hasNode);
  };

  useEffect(() => {
    switchShowPorts(showPorts);
  }, [showPorts]);

  const switchShowPorts = (show: boolean) => {
    const container = containerRef.current as unknown as HTMLDivElement;
    if (container) {
      const ports = container.querySelectorAll(
        ".x6-port-body"
      ) as NodeListOf<SVGAElement>;
      for (let i = 0, len = ports.length; i < len; i += 1) {
        // ports[i].style.visibility = show ? "visible" : "hidden";
        ports[i].style.visibility = "hidden";
      }
    }
  };

  return (
    <div>
      <div>
        <div ref={containerRef} />
      </div>
    </div>
  );
};

export default GraphViewer;
export { getGraph };

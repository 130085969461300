import StepDetailTable from "../StepDetailTable";

const OtherSpecsTable = ({ title, rows }) => {
  const columns = [
    { id: "name", label: "Name", minWidth: 150 },
    { id: "value", label: "Value" },
  ];

  return <StepDetailTable title={title} columns={columns} rows={rows} />;
};

export default OtherSpecsTable;

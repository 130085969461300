import { Button, Grid, IconButton } from "@mui/material";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import { DeleteIcon, EditIcon } from "@/assets/project/icons";
import { IKubeflowHpTunerFormValues } from "../..";
import { FormikProps } from "formik";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import { useState } from "react";
import { cloneDeep } from "lodash";

interface IProps {
  row: {
    [key: string]: any;
  };
  setDialogModeAndSetData: (a: Record<string, any>) => void;
  formik: FormikProps<IKubeflowHpTunerFormValues>;
}

const HyperParamsTableActionCol = ({
  row,
  setDialogModeAndSetData,
  formik,
}: IProps) => {
  const { values, setFieldValue } = formik;

  // delete confirm
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const handleDelParam = async () => {
    const tempArr = cloneDeep(values.hyperParameters);
    tempArr.splice(
      tempArr.findIndex((item) => item.name === row.name),
      1
    );

    setFieldValue("hyperParameters", tempArr);
    setOpenConfirm(false);
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={6} lg={6}>
          <IconButton onClick={() => setDialogModeAndSetData(row)}>
            <SvgIconStyle
              src={EditIcon}
              sx={{
                width: 22,
                height: 22,
              }}
            />
          </IconButton>
        </Grid>
        <Grid item xs={6} lg={6}>
          <IconButton
            onClick={() => {
              setOpenConfirm(true);
            }}
          >
            <SvgIconStyle
              src={DeleteIcon}
              sx={{
                width: 22,
                height: 22,
              }}
            />
          </IconButton>
        </Grid>
      </Grid>

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete parameter <strong> {row.name} </strong> item?
          </>
        }
        action={
          <Button
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelParam();
            }}
            variant="contained"
          >
            Delete
          </Button>
        }
      />
    </>
  );
};

export default HyperParamsTableActionCol;

import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Box, Stack, Collapse, IconButton } from "@mui/material";
//
import EmptyTable from "@/components/project/EmptyTable";
import TrainingInput from "./TrainingInput";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { TrainingStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-training";
import { TuningStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-tuning";
import { updateTrainingInput } from "../../../graph/slices/slice";

// ----------------------------------------------------------------------

type Props = {
  currentStep: TrainingStep | TuningStep | null;
};

export default function TrainingInputs({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [isCollapse, setCollapse] = useState(true);

  const trainingInputs = currentStep?.trainingInputs || [];

  const handleAddTrainingInput = () => {
    dispatch(
      updateTrainingInput({
        data: {
          inputName: `TrainingInput-${trainingInputs.length + 1}`,
          s3Data: "",
          s3DataUseParam: false,
          distribution: "FullyReplicated",
          contentType: "None",
          inputMode: "None",
        },
      })
    );
  };

  return (
    <>
      <Box sx={{ mb: 0, position: "relative" }}>
        <CollapseButtonStyle
          fullWidth
          color="inherit"
          onClick={() => setCollapse((prev) => !prev)}
          endIcon={
            <Icon
              icon={!isCollapse ? arrowIosDownwardFill : arrowIosForwardFill}
              width={16}
              height={16}
            />
          }
        >
          Training Inputs{" "}
          {trainingInputs.length > 0 && `(${trainingInputs.length})`}
        </CollapseButtonStyle>

        <IconButton
          sx={{ zIndex: 1999, position: "absolute", top: 7, right: 30 }}
          size="small"
          onClick={handleAddTrainingInput}
        >
          <Icon icon={plusFill} width={16} height={16} />
        </IconButton>
      </Box>

      <Collapse in={!isCollapse}>
        <Stack spacing={1.5} sx={{ mx: 2, mb: 1.5, mt: !isCollapse ? 0.5 : 0 }}>
          {trainingInputs.length > 0 &&
            trainingInputs.map((trainingInput, index) => (
              <TrainingInput key={index} id={index} data={trainingInput} />
            ))}
        </Stack>
      </Collapse>

      {!isCollapse && trainingInputs.length === 0 && (
        <EmptyTable
          mainTitle={"No training inputs"}
          content={'Create one by clicking "+" button'}
          color={"text.disabled"}
          sx={{ backgroundColor: "transparent", mx: 2, mt: 5, mb: 7 }}
        />
      )}
    </>
  );
}

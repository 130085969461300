import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Field, FormikProps } from "formik";
import { IKubeflowHpTunerFormValues } from "..";
import MlNumberInputField from "@/components/project/mlComponents/MlNumberInputField";

interface IProps {
  formik: FormikProps<IKubeflowHpTunerFormValues>;
}

const TrailThresholds = ({ formik }: IProps) => {
  const { errors, touched } = formik;

  return (
    <>
      <Typography variant="subtitle1">Trial Thresholds</Typography>
      <Box sx={{ pl: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <MlNumberInputField
              name="trailThresholds.parallelTrial"
              label="Parallel Trial"
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl
              fullWidth
              margin="dense"
              size="small"
              error={
                touched.trailThresholds?.resumePolicy &&
                Boolean(errors.trailThresholds?.resumePolicy)
              }
            >
              <InputLabel>Resume Policy</InputLabel>
              <Field
                as={Select}
                name="trailThresholds.resumePolicy"
                label="Resume Policy"
                error={
                  touched.trailThresholds?.resumePolicy &&
                  Boolean(errors.trailThresholds?.resumePolicy)
                }
              >
                <MenuItem value="Never">Never</MenuItem>
                <MenuItem value="LongRunning">Long Running</MenuItem>
                <MenuItem value="FromVolume">From Volume</MenuItem>
              </Field>
              <FormHelperText
                margin="dense"
                sx={{ minHeight: "18px", marginTop: "2px" }}
              >
                {touched.trailThresholds?.resumePolicy &&
                errors.trailThresholds?.resumePolicy
                  ? errors.trailThresholds?.resumePolicy
                  : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <MlNumberInputField
          name="trailThresholds.maxTrails"
          label="Max Trial"
          formik={formik}
          helperText={
            (touched.trailThresholds?.maxTrails &&
              errors.trailThresholds?.maxTrails) ||
            "The number of maximum Trials to run if this value is omitted, your Experiment wll be running until the objective goal is reached or the Experiment reaches a maximum number of failed Trials."
          }
        />
        <MlNumberInputField
          name="trailThresholds.maxFailedTrails"
          label="Max Failed Trails"
          formik={formik}
          helperText={
            (touched.trailThresholds?.maxFailedTrails &&
              errors.trailThresholds?.maxFailedTrails) ||
            "The maximum number of failed Trials before Katib should stop the Experiment, in case the failed Trials exceed this number then the Experiment will be marked as Failed."
          }
        />
      </Box>
    </>
  );
};

export default TrailThresholds;

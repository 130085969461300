/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useLocation, useParams, useNavigate } from "react-router";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import { Form, Field, useFormik, FormikProvider, FieldArray } from "formik";
import * as Yup from "yup";
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Stack,
  Button,
  Typography,
  FormHelperText,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import MlCard from "@/components/project/mlComponents/MlCard";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { dispatch, useSelector, RootState } from "@/redux/store";
import { useSnackbar } from "notistack";
import {
  addAppGallery,
  editAppGallery,
  addAppVersion,
  editAppVersion,
  getTagList,
  addTag,
  addRelTagApp,
  getRelTagAppList,
  delRelTagApp,
  getAppGalleryList,
  uploadImage,
  getImage,
  delImage,
} from "@/redux/project/mlService/appGallery/thunks";
import { setAppGalleryData } from "@/redux/project/mlService/appGallery/slice";
import AppDeployDialog from "../appGalleryDetail/AppDeployDialog";
import MlNumberInputField from "@/components/project/mlComponents/MlNumberInputField";
import { isEmpty } from "lodash";
import {
  getInitResourceQuota,
  queryRestResource,
  restResource,
} from "@/utils/project/resourceRequest";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

interface FormValues {
  name: string;
  cover_url: string;
  cluster: string;
  scenario: string;
  tags: [];
  description: string;
  app_type: number;
  image_url: string;
  image_tag: string;
  cpu: string;
  memory_in_gi: string;
  replica: string;
  cmd: string;
  variables: Array<{ key: string; value: string }>;
}

interface IAppGalleryCreateOrEditForm {
  setOpenDeployConfigVersion: Function;
  selectedVersionInfo: Record<string, any>;
  appDetailInfo: Record<string, any>;
  setAppDetailInfo: Function;
}

const DropZoneStyle = styled("div")(({ theme }) => ({
  position: "absolute",
  width: 100,
  height: 100,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
  // border: `1px dashed ${theme.palette.grey[500_32]}`,
  "&:hover": { opacity: 0.72, cursor: "pointer" },
}));

const AppGalleryCreateOrEditForm = ({
  setOpenDeployConfigVersion,
  selectedVersionInfo,
  appDetailInfo,
  setAppDetailInfo,
}: IAppGalleryCreateOrEditForm) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { appId } = useParams();
  const allExistTags = useRef<any[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [imagesOnHold, setImagesOnHold] = useState<string[]>([]);
  const [imageSrc, setImageSrc] = useState<string>("");
  const [showVariableValue, setShowVariableValue] = useState<boolean[]>([]);
  const [openAppDeployDialog, setOpenAppDeployDialog] =
    useState<boolean>(false);
  const [addResult, setResult] = useState<Record<string, any>>({});
  const {
    userDetail: { project_id },
    config: { common = {} },
  } = useSelector((state: RootState) => state.common);
  const {
    tagDatas: {
      data: { items: tagList = [] },
    },
    mlAppVersionDatas: {
      data: { items: versionData = [] },
    },
    // appTagDatas: { data: { items: relAppTagList } },
    mlAppGalleryList: {
      data: { items: appList = [] },
    },
  } = useSelector((state: RootState) => state.appGallery);
  const { scenario = [] } = common;

  // resourceQuota
  const [resourceQuota, setResourceQuota] = useState<Record<string, any>>({});

  const isEdit = pathname.includes("appGalleryEdit");

  const initialValues: FormValues = {
    name: "",
    cover_url: "",
    cluster: "",
    scenario: "",
    tags: [], //
    description: "",
    app_type: 1,
    image_url: "",
    image_tag: "",
    cpu: "",
    memory_in_gi: "",
    replica: "",
    cmd: "",
    variables: [],
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("App Name is required")
      .max(64, "App Name cannot be longer than 64 characters")
      .test("name-exist", "App Name existed", (value) => {
        if (!isEdit) {
          const isExisted =
            appList.findIndex((item) => item.name === value) > -1;
          return !isExisted;
        } else {
          return true;
        }
      }),
    cluster: Yup.string().required("Kubernetes Cluster is required"),
    image_url: Yup.string().required("Image URL is required"),
    image_tag: Yup.string().required("Image Tag is required"),
    cpu: Yup.string().required("CPU is required"),
    memory_in_gi: Yup.string().required("Memory in Gi is required"),
    replica: Yup.string().required("Replica is required"),
  });

  const handleDropMultiFile = useCallback(
    (acceptedFiles) => {
      const objUrls = acceptedFiles.map((file: File) =>
        URL.createObjectURL(file)
      );
      setImagesOnHold(objUrls);
      setFiles(
        acceptedFiles.map((file: File, id: number) =>
          Object.assign(file, {
            preview: objUrls[id],
          })
        )
      );
    },
    [setImagesOnHold]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      maxFiles: 1,
      accept: ["image/jpeg", "image/png", "image/jpg"],
      onDrop: handleDropMultiFile,
    });

  const updateAppTags = async (tags) => {
    const deleteIds: string[] = [];
    const addIds: string[] = [];

    tags.forEach((item) => {
      if (allExistTags.current.findIndex((i) => i.tag_id === item) === -1) {
        addIds.push(item);
      }
    });
    allExistTags.current.forEach((item) => {
      if (!tags.includes(item.tag_id)) {
        deleteIds.push(item.tag_id);
      }
    });

    // delete
    await Promise.all(
      deleteIds.map((item) =>
        dispatch(
          delRelTagApp({
            id: allExistTags.current.find((i) => i.tag_id === item)?.id,
            params: { is_active: 0 },
            alertCallback: enqueueSnackbar,
            pathname,
          })
        ).unwrap()
      )
    );

    // add
    await Promise.all(
      addIds.map((item) =>
        dispatch(
          addRelTagApp({
            params: {
              mlapp_id: appId,
              tag_id: item,
              is_active: 1,
            },
            alertCallback: enqueueSnackbar,
            pathname,
          })
        ).unwrap()
      )
    );
  };

  const handleCreateSubmitApp = async (values) => {
    let result: Record<string, any> = {};
    let appResult;
    let versionResult;
    let imageResult;
    // upload image
    if (files.length > 0) {
      const formData = new FormData();
      formData.append("filename", files[0].name);
      formData.append("file", files[0]);
      imageResult = await dispatch(
        uploadImage({
          formData,
          pathname,
          alertCallback: enqueueSnackbar,
        })
      ).unwrap();
    }
    // app
    const mlappParams = {
      name: values.name,
      app_type: values.app_type,
      project_id: project_id,
      scenario: values.scenario,
      cover_url: imageResult?.s3_key || "",
      image_url: values.image_url,
      description: values.description,
    };
    appResult = await dispatch(
      addAppGallery({
        params: { ...mlappParams, is_active: 1 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    // version
    const variablesObj = {};
    values.variables.forEach(({ key, value }) => {
      variablesObj[key] = value;
    });
    const resource_config = {
      cpu: values.cpu,
      memory: values.memory_in_gi,
      replicas: values.replica,
      image_url: values.image_url,
      runtime: {
        cluster_name: values.cluster,
        namespace: `taichi-prj-${project_id}`,
      },
    };
    const mlappVersionParams = {
      mlapp_id: appResult?.id,
      image_tag: values.image_tag,
      env_variables: variablesObj,
      endpoint: "", // open iframe page
      code_package_s3: "",
      remark: "",
      cmd: btoa(values.cmd || ""),
      resource_config: resource_config,
    };
    versionResult = await dispatch(
      addAppVersion({
        params: { ...mlappVersionParams, status: "draft", is_active: 1 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    // tag
    await Promise.all(
      values.tags?.map((item) =>
        dispatch(
          addRelTagApp({
            params: {
              mlapp_id: appResult?.id,
              tag_id: item,
              is_active: 1,
            },
            alertCallback: enqueueSnackbar,
            pathname,
          })
        ).unwrap()
      )
    );

    result.appId = appResult?.id;
    result.appName = values.name;
    result.versionId = versionResult?.id;

    return result;
  };

  const handleEditSubmitApp = async (values) => {
    let imageResult;
    if (imagesOnHold.length > 0) {
      // upload image
      const formData = new FormData();
      formData.append("filename", files[0].name);
      formData.append("file", files[0]);
      imageResult = await dispatch(
        uploadImage({
          formData,
          pathname,
          alertCallback: enqueueSnackbar,
        })
      ).unwrap();

      // delete image
      if (appDetailInfo.cover_url) {
        const coverUrlTobase64 = encodeURIComponent(appDetailInfo.cover_url);
        await dispatch(
          delImage({
            s3_key: btoa(coverUrlTobase64),
            alertCallback: enqueueSnackbar,
            pathname,
          })
        );
      }
    }

    // app
    const mlappParams = {
      name: values.name,
      app_type: values.app_type,
      project_id: project_id,
      scenario: values.scenario,
      cover_url:
        imagesOnHold.length > 0
          ? imageResult?.s3_key || ""
          : appDetailInfo.cover_url,
      image_url: values.image_url,
      description: values.description,
    };
    await dispatch(
      editAppGallery({
        id: appId,
        params: { ...mlappParams },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    // version
    const variablesObj = {};
    values.variables.forEach(({ key, value }) => {
      variablesObj[key] = value;
    });
    const resource_config = {
      cpu: values.cpu,
      memory: values.memory_in_gi,
      replicas: values.replica,
      image_url: values.image_url,
      runtime: {
        cluster_name: values.cluster,
        namespace: `taichi-prj-${project_id}`,
      },
    };
    const mlappVersionParams = {
      mlapp_id: appId,
      image_tag: values.image_tag,
      env_variables: variablesObj,
      endpoint: "", // open iframe page
      code_package_s3: "",
      remark: "",
      cmd: btoa(values.cmd || ""),
      resource_config: resource_config,
    };
    const initVersion = versionData.find(
      (version) => version.status === "draft"
    );
    if (!isEmpty(initVersion)) {
      await dispatch(
        editAppVersion({
          id: initVersion.id,
          params: { ...mlappVersionParams },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
    }
    // tag
    await updateAppTags(values.tags);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      try {
        if (isEdit) {
          await handleEditSubmitApp(values);
          navigate(`/ui/mlService/appGallery/${appId}`);
        } else {
          const result = await handleCreateSubmitApp(values);
          setOpenAppDeployDialog(true);
          setResult({ ...result });
        }
        enqueueSnackbar(isEdit ? "Update Success" : "Create Success", {
          variant: "success",
        });
        actions.setSubmitting(false);
      } catch (err) {
        actions.setSubmitting(false);
      }
    },
  });

  const resourceHelper = (type) => {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body2">
          {`${resourceQuota[type]?.used || 0}/${
            resourceQuota[type]?.all || 0
          } used/hard`}
        </Typography>
        <Tooltip title="Refresh to query instant resource">
          <IconButton
            size="small"
            sx={{ height: "18px", width: "18px" }}
            onClick={() => getInitResourceQuota(project_id, setResourceQuota)}
          >
            <RefreshOutlinedIcon
              sx={{ color: (theme) => theme.palette.grey[500] }}
            />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  useEffect(() => {
    if (isEdit) {
      dispatch(
        getRelTagAppList({
          params: {
            mlapp_id: appId,
            page_num: 1,
            page_size: 9999,
            is_active: 1,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((res) => {
          setFieldValue(
            "tags",
            res.items.map((item) => item.tag_id)
          );

          allExistTags.current = res?.items;
        });
    }
    // get tag
    dispatch(
      getTagList({
        params: { page_num: 1, page_size: 9999, is_active: 1 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
    // get all app
    dispatch(
      getAppGalleryList({
        params: { page_num: 1, page_size: 9999 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();

    setFieldValue("cpu", "1");
    setFieldValue("memory_in_gi", "1");
    setFieldValue("replica", "1");

    getInitResourceQuota(project_id, setResourceQuota);

    return () => {
      setFiles([]);
      setImageSrc("");
      setImagesOnHold([]);
      setAppDetailInfo({});
      dispatch(setAppGalleryData("mlAppGalleryList"));
    };
  }, []);

  useEffect(() => {
    if (isEdit) {
      if (
        appDetailInfo.cover_url &&
        appDetailInfo.cover_url !== "" &&
        imageSrc === ""
      ) {
        dispatch(
          getImage({
            params: { s3_key: appDetailInfo.cover_url },
            alertCallback: enqueueSnackbar,
            pathname,
          })
        )
          .unwrap()
          .then((resp) => {
            setImageSrc(resp.url);
          });
      }
      setFieldValue("name", appDetailInfo?.name);
      setFieldValue("cover_url", appDetailInfo?.cover_url);
      setFieldValue("app_type", appDetailInfo?.app_type);
      setFieldValue("scenario", appDetailInfo?.scenario || "");
      setFieldValue("image_url", appDetailInfo?.image_url);
      setFieldValue("description", appDetailInfo?.description);
    }
  }, [appDetailInfo]);

  useEffect(() => {
    if (isEdit) {
      if (selectedVersionInfo) {
        const resource_config = selectedVersionInfo.resource_config;

        const variables = Object.entries(
          selectedVersionInfo.env_variables || {}
        ).map(([key, value]) => ({
          key,
          value,
        })) as Array<{ key: string; value: string }>;
        setFieldValue("cluster", resource_config?.runtime?.cluster_name || "");
        setFieldValue("image_tag", selectedVersionInfo.image_tag);
        setFieldValue("cpu", resource_config?.cpu);
        setFieldValue("memory_in_gi", resource_config?.memory);
        setFieldValue("replica", resource_config?.replicas);
        setFieldValue("cmd", atob(selectedVersionInfo.cmd || ""));
        setFieldValue("variables", variables);
      }
    }
  }, [selectedVersionInfo]);

  const { values, errors, touched, isSubmitting, setFieldValue } = formik;

  const handleOpenVersion = () => {
    setOpenDeployConfigVersion(true);
    // toggleIsCollapseByBol(true);
  };

  const handleAddVariable = (push) => {
    push({ key: "", value: "" });
    const variableValue = [...showVariableValue];
    variableValue.push(false);
    setShowVariableValue(variableValue);
  };

  const handleClickShowPassword = (index) => {
    const variableValue = [...showVariableValue];
    variableValue[index] = !showVariableValue[index];
    setShowVariableValue(variableValue);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <MlCard title="Basic Information" sx={{ mb: 2 }}>
            <Box sx={{ pb: 2, width: "95%" }}>
              <Field
                as={TextField}
                name="name"
                label="Title"
                size="small"
                fullWidth
                margin="dense"
                helperText={
                  touched.name && errors.name
                    ? errors.name
                    : "Maximum of 64 alphanumeric characters. Any character is allowed"
                }
                error={touched.name && Boolean(errors.name)}
                FormHelperTextProps={{
                  sx: { minHeight: "18px", marginTop: "2px" },
                }}
              />
              <Typography
                variant="subtitle1"
                color="text.secondary"
              >{`Cover Image (Optional)`}</Typography>
              <Box
                sx={{
                  position: "relative",
                  minWidth: 100,
                  height: 100,
                  mb: 1.5,
                  ml: 1.5,
                  mt: 1.5,
                }}
              >
                {(imagesOnHold.length > 0 || imageSrc) && (
                  <IconButton
                    onClick={() => setImagesOnHold([])}
                    edge="end"
                    sx={{
                      position: "absolute",
                      top: -19,
                      left: 78,
                      zIndex: 9999,
                    }}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                )}

                <DropZoneStyle
                  {...getRootProps()}
                  sx={{
                    ...(isDragActive && { opacity: 0.72 }),
                    ...(isDragReject && {
                      color: "error.main",
                      borderColor: "error.light",
                      bgcolor: "error.lighter",
                    }),
                  }}
                >
                  <input {...getInputProps()} />
                  {isEdit && (imagesOnHold[0] || imageSrc) && (
                    <ImageSrc
                      src={imagesOnHold[0] ? imagesOnHold[0] : imageSrc}
                    />
                  )}
                  {!isEdit && imagesOnHold.length > 0 && (
                    <ImageSrc src={imagesOnHold[0]} />
                  )}
                  <Image>
                    {/* <VisuallyHiddenInput type="file" /> */}
                    <Stack alignItems="center" justifyContent="center">
                      <AddAPhotoIcon sx={{ width: 20, height: 20, mb: 0.5 }} />
                      <Typography variant="caption">Update photo</Typography>
                    </Stack>
                  </Image>
                </DropZoneStyle>
              </Box>

              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={touched.cluster && Boolean(errors.cluster)}
              >
                <InputLabel>Kubernetes Cluster</InputLabel>
                <Field as={Select} name="cluster" label="Kubernetes Cluster">
                  <MenuItem value="k8s">k8s</MenuItem>
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                  {touched.cluster && errors.cluster
                    ? errors.cluster
                    : "Kubernetes Cluster to be deployed in"}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth margin="dense" size="small">
                <InputLabel>{`Scenario (Optional)`}</InputLabel>
                <Field as={Select} name="scenario" label="Scenario (Optional)">
                  {scenario.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Field>
                <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }} />
              </FormControl>
              <Autocomplete
                size="small"
                multiple
                disableCloseOnSelect
                disablePortal
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                limitTags={8}
                getOptionLabel={(option) =>
                  typeof option === "object" ? option.name : option
                }
                isOptionEqualToValue={(option, value) => {
                  if (typeof option === "object" && typeof value === "object") {
                    return option.id === value.id;
                  } else if (
                    typeof option === "string" ||
                    typeof value === "string"
                  ) {
                    return option === value;
                  }
                  return false;
                }}
                options={tagList}
                value={values.tags?.map(
                  (tagId) => tagList?.find((tag) => tag.id === tagId) || tagId
                )}
                onChange={(_, newValues) => {
                  const filteredValues = newValues?.filter(
                    (item) => typeof item === "string"
                  );

                  if (tagList?.find((i) => i.name === filteredValues[0])) {
                    enqueueSnackbar(`Tag ${filteredValues[0]} existed`, {
                      variant: "warning",
                    });
                    return;
                  }

                  const updatedTagsPromises = newValues.map(async (item) => {
                    if (typeof item === "string") {
                      // new text ,add a new tag
                      const res = await dispatch(
                        addTag({
                          params: {
                            is_active: 1,
                            name: item,
                          },
                          alertCallback: enqueueSnackbar,
                          pathname,
                        })
                      ).unwrap();

                      enqueueSnackbar(`Add Tag ${item} Success`, {
                        variant: "success",
                      });

                      await dispatch(
                        getTagList({
                          params: {
                            is_active: 1,
                            page_num: 1,
                            page_size: 9999,
                          },
                          alertCallback: enqueueSnackbar,
                          pathname,
                        })
                      );

                      return res.id;
                    }
                    return item.id;
                  });

                  Promise.all(updatedTagsPromises).then((r) => {
                    setFieldValue("tags", r);
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="tags"
                    label="Tags (Optional)"
                    helperText=" "
                    FormHelperTextProps={{
                      style: {
                        minHeight: "18px",
                        marginTop: "2px",
                      },
                    }}
                  />
                )}
                sx={{ mt: 1, mb: 0.5 }}
              />
              <Field
                as={TextField}
                multiline
                rows={4}
                name="description"
                label="Description (Optional)"
                size="small"
                fullWidth
                margin="dense"
                helperText=" "
                FormHelperTextProps={{
                  sx: { minHeight: "18px", marginTop: "2px" },
                }}
              />
              <Typography variant="subtitle1">Deploy Type</Typography>
              <FormControl
                fullWidth
                margin="dense"
                size="small"
                error={touched.app_type && Boolean(errors.app_type)}
              >
                {/* <FormLabel className={classes.titleMark}>Deploy Type</FormLabel> */}
                <RadioGroup name="app_type" defaultValue={1}>
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="From Image - Pull image and deploy"
                  />
                  <FormControlLabel
                    value="Classification"
                    disabled
                    control={<Radio />}
                    label="From Standard Image - Upload code build tar.Pull image and deploy"
                  />
                </RadioGroup>
              </FormControl>

              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6" sx={{ mt: 1.5, mb: 2, ml: -1.5 }}>
                  Deploy Configuration
                </Typography>
                {isEdit && (
                  <Button
                    size="small"
                    color="secondary"
                    sx={{ height: 24, mt: 1.5 }}
                    endIcon={<KeyboardArrowRightIcon />}
                    onClick={handleOpenVersion}
                  >
                    Version
                  </Button>
                )}
              </Stack>

              <Field
                as={TextField}
                name="image_url"
                label="Image URL"
                size="small"
                fullWidth
                margin="dense"
                disabled={isEdit}
                helperText={
                  touched.image_url && errors.image_url
                    ? errors.image_url
                    : "Input an image address to pull to deploy"
                }
                error={touched.image_url && Boolean(errors.image_url)}
                FormHelperTextProps={{
                  sx: { minHeight: "18px", marginTop: "2px" },
                }}
              />
              <Field
                as={TextField}
                name="image_tag"
                label="Image Tag"
                size="small"
                fullWidth
                margin="dense"
                helperText={
                  touched.image_tag && errors.image_tag ? errors.image_tag : " "
                }
                error={touched.image_tag && Boolean(errors.image_tag)}
                FormHelperTextProps={{
                  sx: { minHeight: "18px", marginTop: "2px" },
                }}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <MlNumberInputField
                    name="cpu"
                    label="CPU"
                    formik={formik}
                    isDouble
                    helperText={resourceHelper("cpu")}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MlNumberInputField
                    name="memory_in_gi"
                    label="Memory in Gi"
                    formik={formik}
                    isDouble
                    helperText={resourceHelper("memory")}
                  />
                </Grid>
              </Grid>
              <MlNumberInputField
                name="replica"
                label="Replica"
                formik={formik}
                isDouble
              />
              <Field
                as={TextField}
                multiline
                rows={4}
                name="cmd"
                label="CMD (Optional)"
                size="small"
                fullWidth
                margin="dense"
                helperText=" "
                FormHelperTextProps={{
                  sx: { minHeight: "18px", marginTop: "2px" },
                }}
              />
              <Typography variant="subtitle1">{`Environment Variables (Optional)`}</Typography>
              <FieldArray
                name="variables"
                render={({ push, remove }) => {
                  return (
                    <>
                      {values.variables.map((line, index) => (
                        <Stack
                          key={index}
                          direction="row"
                          sx={{ mb: 1, px: 2.5 }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} lg={6}>
                              <Field
                                as={TextField}
                                name={`variables.${index}.key`}
                                size="small"
                                label="Key"
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <Field
                                as={TextField}
                                name={`variables.${index}.value`}
                                type={
                                  showVariableValue[index] ? "password" : "text"
                                }
                                size="small"
                                label="Value"
                                fullWidth
                                margin="dense"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() =>
                                          handleClickShowPassword(index)
                                        }
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showVariableValue[index] ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Box sx={{ ml: 1.5, mt: 1 }}>
                            <IconButton onClick={() => remove(index)}>
                              <RemoveOutlinedIcon />
                            </IconButton>
                          </Box>
                        </Stack>
                      ))}
                      <Button
                        size="small"
                        color="secondary"
                        sx={{ height: 24, marginTop: 1.5 }}
                        startIcon={<AddOutlinedIcon />}
                        onClick={() => handleAddVariable(push)}
                      >
                        Environment Variable
                      </Button>
                    </>
                  );
                }}
              />
            </Box>
          </MlCard>
          <Stack direction="row" justifyContent="center" spacing={2}>
            <LoadingButton
              type="submit"
              loading={isSubmitting}
              variant="contained"
              color="primary"
              sx={{ width: "200px", color: "background.paper" }}
            >
              Save
            </LoadingButton>
            <Button
              variant="outlined"
              color="inherit"
              sx={{ width: "200px", color: "text.secondary" }}
              onClick={() => navigate("/ui/mlService/appGallery")}
            >
              Cancel
            </Button>
          </Stack>
        </Form>
      </FormikProvider>

      {openAppDeployDialog && (
        <AppDeployDialog
          isOpen={openAppDeployDialog}
          onClose={() => {
            setOpenAppDeployDialog(false);
          }}
          addInfo={addResult}
        />
      )}
    </>
  );
};

const ImageSrc = styled("img")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

export default AppGalleryCreateOrEditForm;

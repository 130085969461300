import React from "react";
import { useLocation, useNavigate } from "react-router";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Stack,
  Button,
  Autocomplete,
  Chip,
} from "@mui/material";
import { Form, Field, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import MlCard from "@/components/project/mlComponents/MlCard";

import { useSnackbar } from "notistack";
import { RootState, dispatch, useSelector } from "@/redux/store";
import {
  getMetricsTemplateList,
  addRelMlServiceMetrics,
} from "@/redux/project/mlService/mlRuntime/thunks";
import { LoadingButton } from "@mui/lab";

interface IMetricsStepProps {
  mlServiceRes: Record<string, any>;
}

interface FormValue {
  metrics_type: string;
  metrics_name: Array<{ name: string; id: string }>;
  description: string;
}

const MetricsStep = ({ mlServiceRes }: IMetricsStepProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  // const [metricsList, setMetricsList] = useState<Array<{ name: string, id: string }>>([]);
  const {
    metricsTemplateList: {
      data: { items: templateList = [] },
    },
  } = useSelector((state: RootState) => state.mlRuntime);

  const initialValues: FormValue = {
    metrics_type: "",
    metrics_name: [],
    description: "",
  };

  const validationSchema = Yup.object({
    metrics_type: Yup.string().required("Metrics Type is required"),
    metrics_name: Yup.array().required("Metrics List is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      try {
        const promise = values.metrics_name.map((item) => {
          return dispatch(
            addRelMlServiceMetrics({
              params: {
                mlservice_id: mlServiceRes?.id,
                metrics_template_id: item.id,
                is_active: 1,
              },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          );
        });

        Promise.all(promise).then(() => {
          actions.setSubmitting(false);
          navigate("/ui/mlService/mlRuntime");
          resetForm();
          enqueueSnackbar("Save Success", { variant: "success" });
        });
      } catch (err) {
        console.log("err", err);
        actions.setSubmitting(false);
      }
    },
  });

  const handleCancel = () => {
    navigate(`/ui/mlService/mlRuntime`);
    resetForm();
  };

  const handleMetricsTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      getMetricsTemplateList({
        params: {
          page_num: 1,
          page_size: 9999,
          metrics_type: event.target.value,
          is_active: 1,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    setFieldValue("metrics_type", event.target.value);
  };

  const { values, errors, touched, isSubmitting, resetForm, setFieldValue } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form>
        <MlCard title="Metrics - Optional" sx={{ mt: 1.5 }}>
          <Box sx={{ pb: 2, width: "800px" }}>
            <FormControl
              fullWidth
              margin="dense"
              size="small"
              error={touched.metrics_type && Boolean(errors.metrics_type)}
            >
              <InputLabel>Metrics Type</InputLabel>
              <Field
                as={Select}
                name="metrics_type"
                label="Metrics Type"
                onChange={handleMetricsTypeChange}
              >
                <MenuItem value="Regression">Regression</MenuItem>
                <MenuItem value="Classification">Classification</MenuItem>
                <MenuItem value="Clustering">Clustering</MenuItem>
              </Field>
              <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                {touched.metrics_type && errors.metrics_type
                  ? errors.metrics_type
                  : ""}
              </FormHelperText>
            </FormControl>

            <Autocomplete
              multiple
              options={templateList}
              getOptionLabel={(metricsList: { name: string; id: string }) =>
                metricsList.name
              }
              value={templateList.filter((metric) =>
                values.metrics_name.includes(metric)
              )}
              onChange={(_, newValues) => {
                setFieldValue("metrics_name", newValues || []);
                const curValue = newValues[newValues.length - 1];
                const metrics_fun = templateList.find(
                  (item) => item.id === curValue.id
                )?.metrics_func;
                setFieldValue("description", metrics_fun);
              }}
              renderTags={(value: any[], getTagProps) =>
                value.map(
                  (option: { name: string; id: string }, index: number) => (
                    <Chip
                      {...getTagProps({ index })}
                      size="small"
                      variant="soft"
                      label={option.name}
                      key={option.id}
                    />
                  )
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="metrics_name"
                  label="Metrics Name"
                  margin="normal"
                  variant="outlined"
                  size="small"
                />
              )}
            />

            <Field
              as={TextField}
              multiline
              rows={4}
              name="description"
              label="Description (Optional)"
              size="small"
              fullWidth
              margin="dense"
              disabled
            />
          </Box>
        </MlCard>
        <Stack
          spacing={2}
          direction="row"
          justifyContent="center"
          sx={{ my: 4 }}
        >
          <LoadingButton
            type="submit"
            loading={isSubmitting}
            variant="contained"
            sx={{ width: "200px", color: "background.paper" }}
          >
            Save
          </LoadingButton>
          <Button
            color="inherit"
            variant="outlined"
            sx={{ width: "200px", color: "text.secondary" }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default MetricsStep;

/* eslint-disable react-hooks/exhaustive-deps */
import { TableRow } from "@mui/material";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { dispatch } from "@/redux/store";
import { getProjectRolesUsersTableDataInProject } from "@/redux/project/administration/thunks";
import { useLocation } from "react-router";

const ProjectRolesTableRow = ({ row, children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(
      getProjectRolesUsersTableDataInProject({
        params: {
          projectrole_id: row.id,
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  }, []);

  return (
    <>
      <TableRow key={`${row.id} - 1`}>{children}</TableRow>
    </>
  );
};

export default ProjectRolesTableRow;

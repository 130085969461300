import { Schema } from "../../slices/slice";
import {
  ConditionStep,
  ConditionStruct,
  LeftRightValueStruct,
  ConditionArguments,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-condition";
import { parseStep } from "./";
import { addStepPairs } from "./helper-functions";

// ----------------------------------------------------------------------

export default async function parseConditionStep(
  index: number,
  pipelineStep: any,
  stepPairs: [string, string, string][],
  schema: Schema
) {
  const args = pipelineStep.Arguments as ConditionArguments;
  const step = {
    id: String(index),
    type: "Condition",
    name: pipelineStep.Name,
    conditions: parseConditions(pipelineStep.Name, args.Conditions, stepPairs),
    ifSteps: await parseIfElseSteps(
      pipelineStep.Name,
      args.IfSteps,
      schema,
      stepPairs,
      "true"
    ),
    elseSteps: await parseIfElseSteps(
      pipelineStep.Name,
      args.ElseSteps,
      schema,
      stepPairs,
      "false"
    ),
    nodeX: 220 * index,
    nodeY: 100,
    tags: [],
    properties: [],
    stepType: "Condition",
  } as ConditionStep;

  // console.log(step);

  return step;
}

const parseConditions = (
  condStepName: string,
  ConditionConfig: any,
  stepPairs: [string, string, string][]
) => {
  if (ConditionConfig === undefined) return [];

  const conditions: Array<ConditionStruct> = [];
  ConditionConfig.forEach((cond: any) =>
    conditions.push({
      conditionType: cond.Type,
      leftValue: parseLeftRightValue(condStepName, cond.LeftValue, stepPairs),
      rightValue: parseLeftRightValue(condStepName, cond.RightValue, stepPairs),
    })
  );

  // console.log(conditions);

  return conditions;
};

const parseLeftRightValue = (
  condStepName: string,
  leftRightConfig: any,
  stepPairs: [string, string, string][]
) => {
  const leftRightVal: LeftRightValueStruct = {
    source: "value",
    value: "",
    valueUseParam: false,
    jsonKey: "",
    jsonKeyUseParam: false,
  };
  if (typeof leftRightConfig === "object") {
    // JsonGet
    if (Object.keys(leftRightConfig)[0] === "Std:JsonGet") {
      leftRightVal.source = "propertyFile";
      leftRightVal.value = leftRightConfig["Std:JsonGet"]["PropertyFile"][
        "Get"
      ].replace("Steps.", "");
      leftRightVal.valueUseParam = true;

      const s3UriItems =
        leftRightConfig["Std:JsonGet"]["PropertyFile"]["Get"].split(".");
      // Dependent steps
      const relationship = `${s3UriItems[2]}.${s3UriItems[3]}&&Condition`;
      // console.log(relationship);
      addStepPairs(stepPairs, s3UriItems[1], condStepName, relationship);

      if (typeof leftRightConfig["Std:JsonGet"]["Path"] === "object") {
        leftRightVal.jsonKey = leftRightConfig["Std:JsonGet"]["Path"]["Get"];
        leftRightVal.jsonKeyUseParam = true;
      } else {
        leftRightVal.jsonKey = leftRightConfig["Std:JsonGet"]["Path"];
      }
    }
    // Get
    else if (Object.keys(leftRightConfig)[0] === "Get") {
      leftRightVal.value = leftRightConfig["Get"].split(".")[1];
      leftRightVal.valueUseParam = true;
    }
  } else {
    leftRightVal.value = leftRightConfig;
  }

  return leftRightVal;
};

const parseIfElseSteps = async (
  condStepName: string,
  StepConfig: any[],
  schema: Schema,
  stepPairs: [string, string, string][],
  condition: string
) => {
  if (StepConfig === undefined) return [];

  const ifElseSteps: Array<string> = [];

  const startIndex = schema.pipelineSteps.length + 1;
  let index = 0;
  for (const step of StepConfig) {
    ifElseSteps.push(step.Name);
    await parseStep(schema, step, startIndex + index, stepPairs);

    index += 1;
    if (stepPairs.length > 0) {
      const relatedPairs = stepPairs.filter((item) => item[1] === step.Name);
      if (relatedPairs.length === 0) {
        addStepPairs(stepPairs, condStepName, step.Name, `${condition}&&`);
        continue;
      }

      let sumVal = 0;
      relatedPairs.forEach(
        (pair) =>
          (sumVal += StepConfig.filter((item) => item.Name === pair[0]).length)
      );

      // if (step.Name === "ModelBiasCheck") {
      //   console.log(step.Name);
      //   console.log(StepConfig);
      //   console.log(stepPairs.slice(-1));
      //   console.log(stepPairCopy.slice(-1));
      // }

      if (sumVal === 0)
        addStepPairs(stepPairs, condStepName, step.Name, `${condition}&&`);
    }
  }
  // StepConfig.forEach(async (step, index) => {
  //   const stepId = startIndex + index;
  //   ifElseSteps.push(step.Name);
  //   await parseStep(schema, step, stepId, stepPairs);
  //   addStepPairs(stepPairs, condStepName, step.Name, `${condition}&&`);
  // });

  return ifElseSteps;
};

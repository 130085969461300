import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import checkmarkCircle2Fill from "@iconify/icons-eva/checkmark-circle-2-fill";
import {
  Box,
  Radio,
  Stack,
  Portal,
  Switch,
  Dialog,
  Button,
  Divider,
  Tooltip,
  Typography,
  RadioGroup,
  IconButton,
  DialogTitle,
  DialogActions,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider } from "formik";
import { RootState, dispatch, useSelector } from "@/redux/store";
import { RootStyle, OptionStyle, TRIGGER_OPTIONS } from "./helper-functions";
import S3TriggerInput from "./S3TriggerInput";
import ScheduledTriggerInput from "./ScheduledTriggerInput";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import { StyledTextField } from "../components/PipelineComponents";
import Scrollbar from "@/components/project/Scrollbar";
import { MHidden } from "@/components/project/@material-extend";
import { useLocation, useParams } from "react-router";
import {
  addPipelineTrigger,
  delPipelineTrigger,
  editPipelineTrigger,
  getPipelineTriggerDetailData,
} from "@/redux/project/automation/thunks";
import { isEmpty } from "lodash";
import ConfirmDialog from "@/components/project/ConfirmDialog";

// ----------------------------------------------------------------------

type Props = {
  isOpenCompose: boolean;
  onCloseCompose: VoidFunction;
  selectedTriggerId: string | number;
  isEdit: boolean;
  handleRefresh: VoidFunction;
};

interface triggerValusParams {
  type: string;
  triggerName: string;
  address: string;
  triggerDescription: string;
  enableTrigger: false;
  cron: {
    cronSeconds: string;
    cronMinutes: string;
    cronHours: string;
    cronDom: string;
    cronMonth: string;
    cronDow: string;
    // cronYear: string,
  };
  cronExpression: string;
}

export default function TriggerConfigPanel({
  isOpenCompose,
  onCloseCompose,
  selectedTriggerId,
  isEdit,
  handleRefresh,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { versionId } = useParams();

  const [triggerDetail, setTriggerDetail] = useState<Record<any, any>>({});

  const {
    pipelineVersionDetail: { data: versionDetail = {} },
  } = useSelector((state: RootState) => state.automation);

  // delete confirm
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);

  const { pipelineType } = useSelector((state: RootState) => state.schema);
  const isKubeflow = pipelineType === "Kubeflow";

  const initialValues: triggerValusParams = {
    type: "",
    triggerName: "",
    address: "",
    triggerDescription: "",
    enableTrigger: false,
    cron: {
      cronSeconds: "",
      cronMinutes: "",
      cronHours: "",
      cronDom: "",
      cronMonth: "",
      cronDow: "",
      // cronYear: '',
    },
    cronExpression: "",
  };

  const Schema = Yup.object().shape({
    type: Yup.mixed().required("Trigger name is required"),
    triggerName: Yup.mixed().required("Trigger name is required"),
    address: Yup.string().when("type", {
      is: "artifactory",
      then: Yup.string().required("Value is required"),
    }),
    cronExpression: Yup.mixed().test(
      "notAllEmpty",
      "Cron Expression cannot be empty",
      function (_, context) {
        const { cron } = context.parent;
        const allEmpty = Object.values(cron).every((item) => isEmpty(item));

        return !allEmpty;
      }
    ),
  });

  const generateConfig = (values: triggerValusParams) => {
    const {
      resource_config: {
        pipeline: { kf_pl_id = "", kf_pl_ver_id = "", kf_exp_id = "" } = {},
      } = {},
    } = versionDetail || {};

    const trigger_type =
      values.type === "scheduled"
        ? "schedule_trigger_kubeflow"
        : "artifactory_trigger_kubeflow";

    let setting: any = {};
    if (values.type === "scheduled") {
      setting = {
        // pipeline_name: "my-pipeline",
        kf_pl_id,
        kf_pl_ver_id,
        kf_exp_id,
        job_name: "cron-job",
        // start_time: "start-time",
        // end_time: "end-time",
        seconds: values.cron.cronSeconds,
        minuites: values.cron.cronMinutes,
        hours: values.cron.cronHours,
        day_of_month: values.cron.cronDom,
        month: values.cron.cronMonth,
        day_of_week: values.cron.cronDow,
        // year: values.cron.cronYear,
      };
    } else {
      setting = {
        // pipeline_name: "my-pipeline",
        pipeline_id: kf_pl_id,
        experiment_id: kf_exp_id,
        job_name: "cron-job",
        address: values.address,
      };
    }

    const config = {
      env: {
        type: "kubernetes",
        cluster_names: ["default-k8s"],
      },
      node_selector: {},
      pipeline_trigger: {
        pipeline_trigger_type: trigger_type,
        setting,
      },
    };

    return config;
  };

  const handleCreateSubmit = async (values: triggerValusParams) => {
    const trigger_type =
      values.type === "scheduled"
        ? "schedule_trigger_kubeflow"
        : "artifactory_trigger_kubeflow";

    await dispatch(
      addPipelineTrigger({
        params: {
          mlpipeline_version_id: versionId!,
          name: values.triggerName,
          description: values.triggerDescription,
          trigger_type,
          resource_config: generateConfig(values),
          status: values.enableTrigger ? "wait_start" : "wait_close",
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();
  };

  const handleUpdateSubmit = async (values: triggerValusParams) => {
    await dispatch(
      editPipelineTrigger({
        id: selectedTriggerId as string,
        params: {
          description: values.triggerDescription,
          resource_config: generateConfig(values),
          status: values.enableTrigger ? "wait_start" : "wait_close",
        },
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values, actions) => {
      try {
        if (isEdit) {
          await handleUpdateSubmit(values);
          enqueueSnackbar("Edit Success", { variant: "success" });
        } else {
          await handleCreateSubmit(values);
          enqueueSnackbar("Create Success", { variant: "success" });
        }
        actions.setSubmitting(false);

        // get the lastest triggerList
        handleRefresh();
        onCloseCompose();
      } catch (e) {
        console.log("error-in-submit", e);
        actions.setSubmitting(false);
      }
    },
  });

  const handleDelTrigger = async () => {
    setDelLoading(true);
    try {
      await dispatch(
        delPipelineTrigger({
          id: selectedTriggerId as string,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      setDelLoading(false);
      setOpenConfirm(false);
      enqueueSnackbar("Delete Success", { variant: "success" });

      onCloseCompose();

      // get the latest trigger list
      handleRefresh();
    } catch (e) {
      setDelLoading(false);
    }
  };

  const {
    errors,
    values,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (isEdit && isOpenCompose) {
      dispatch(
        getPipelineTriggerDetailData({
          id: selectedTriggerId as string,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((res) => setTriggerDetail(res));
    }
  }, [isEdit, isOpenCompose]);

  useEffect(() => {
    if (!isEdit) {
      setFieldValue("type", "scheduled");
    }
  }, []);

  useEffect(() => {
    if (isEdit && !isEmpty(triggerDetail)) {
      const tType = triggerDetail?.trigger_type.includes("artifactory")
        ? "artifactory"
        : "scheduled";
      setFieldValue("type", tType);
      setFieldValue("triggerName", triggerDetail.name);
      setFieldValue("triggerDescription", triggerDetail.description);
      setFieldValue("enableTrigger", triggerDetail.status === "started");

      const { resource_config: { pipeline_trigger = {} } = {} } = triggerDetail;
      const { setting } = pipeline_trigger || {};

      if (tType === "scheduled") {
        const {
          day_of_month,
          day_of_week,
          hours,
          seconds,
          minuites,
          month,
          // year
        } = setting;

        setFieldValue("cron", {
          cronSeconds: seconds,
          cronMinutes: minuites,
          cronHours: hours,
          cronDom: day_of_month,
          cronMonth: month,
          cronDow: day_of_week,
          // cronYear: year,
        });
      }
    }
  }, [isEdit, triggerDetail]);

  return (
    <Portal>
      <Dialog open={isOpenCompose} onClose={onCloseCompose}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <RootStyle
              sx={{
                ...{
                  top: 0,
                  left: 0,
                  display: "flex",
                  margin: "auto",
                  width: {
                    xs: "90%",
                    md: "80%",
                    lg: "70%",
                  },
                  height: {
                    xs: "80vh",
                    md: "75vh",
                  },
                },
              }}
            >
              <Stack sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    pb: 2,
                    mr: 2.5,
                  }}
                >
                  <DialogTitle sx={{ pt: 2 }}>
                    {isEdit ? "Edit trigger" : "Add trigger"}
                  </DialogTitle>

                  <Stack direction={"row"} spacing={1} sx={{ mt: 2 }}>
                    <Tooltip
                      title="Enable/Disable trigger"
                      enterDelay={500}
                      enterTouchDelay={500}
                      arrow
                      sx={{ zIndex: 3000 }}
                    >
                      <Switch
                        size="small"
                        sx={{ mt: "3px" }}
                        disabled={
                          !(
                            triggerDetail.status === "started" ||
                            triggerDetail.status === "closed" ||
                            triggerDetail.status === "failed"
                          )
                        }
                        checked={values.enableTrigger}
                        {...getFieldProps("enableTrigger")}
                        onChange={(e) => {
                          if (e.target.checked) {
                            // if active_version is 0, forbidden the trigger enable
                            if (versionDetail.active_version === 0) {
                              enqueueSnackbar(
                                "Please activate this version first",
                                { variant: "warning" }
                              );
                            } else {
                              setFieldValue("enableTrigger", e.target.checked);
                            }
                          } else {
                            setFieldValue("enableTrigger", e.target.checked);
                          }
                        }}
                      />
                    </Tooltip>

                    {isEdit && (
                      <Tooltip title="Delete trigger">
                        <IconButton
                          size="small"
                          color={"error"}
                          onClick={() => {
                            setOpenConfirm(true);
                          }}
                        >
                          <Icon icon={trash2Fill} width={20} height={20} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </Box>

                <Scrollbar sx={{ flexDirection: "row" }}>
                  <Box sx={{ p: 3, pt: 1, height: "100%" }}>
                    <RadioGroup
                      row
                      {...getFieldProps("type")}
                      sx={{ display: "block" }}
                    >
                      <Box
                        sx={{
                          display: "grid",
                          gap: 2,
                          gridTemplateColumns: {
                            sm: "repeat(1, 1fr)",
                            md: "repeat(2, 1fr)",
                            // lg: "repeat(2, 1fr)",
                            lg: "repeat(3, 1fr)",
                          },
                        }}
                      >
                        {(isKubeflow
                          ? TRIGGER_OPTIONS.filter(
                              (i) => i.value !== "amazon_s3"
                            )
                          : TRIGGER_OPTIONS
                        ).map((trigger) => {
                          const { value, title, icon, description } = trigger;

                          return (
                            <OptionStyle
                              key={title}
                              sx={{
                                ...(values.type === value && {
                                  boxShadow: (theme) => theme.customShadows.z8,
                                }),
                              }}
                            >
                              <FormControlLabel
                                disabled={isEdit || value === "artifactory"}
                                value={value}
                                control={
                                  <Radio
                                    checkedIcon={
                                      <Icon icon={checkmarkCircle2Fill} />
                                    }
                                  />
                                }
                                label={
                                  <Box sx={{ ml: 1 }}>
                                    <Typography variant="subtitle2">
                                      {title}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{ color: "text.secondary" }}
                                    >
                                      {description}
                                    </Typography>
                                  </Box>
                                }
                                sx={{ flexGrow: 1, py: 1.5 }}
                              />
                              <MHidden width="smDown">
                                <SvgIconStyle
                                  src={icon}
                                  sx={{
                                    ml: 0.5,
                                    width: 34,
                                    height: 34,
                                    flexShrink: 0,
                                    color: "text.secondary",
                                  }}
                                />
                              </MHidden>
                            </OptionStyle>
                          );
                        })}
                      </Box>
                    </RadioGroup>

                    <Divider sx={{ my: 2, borderStyle: "dashed" }}>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "text.disabled" }}
                      >
                        {"Configuration"}
                      </Typography>
                    </Divider>

                    <>
                      <StyledTextField
                        variant="filled"
                        label="Name"
                        disabled={isEdit}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        sx={{
                          mb: 2,
                          "& .MuiFilledInput-root": { borderRadius: 1 },
                        }}
                        {...getFieldProps("triggerName")}
                        error={Boolean(
                          touched.triggerName && errors.triggerName
                        )}
                        helperText={errors.triggerName}
                      />

                      {values.type === "artifactory" && (
                        <StyledTextField
                          variant="filled"
                          label="address"
                          disabled={isEdit}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          sx={{
                            mb: 2,
                            "& .MuiFilledInput-root": { borderRadius: 1 },
                          }}
                          {...getFieldProps("address")}
                          error={Boolean(touched.address && errors.address)}
                          helperText={errors.address}
                        />
                      )}

                      <StyledTextField
                        variant="filled"
                        label="Description (optional)"
                        multiline
                        rows={2}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        sx={{
                          "& .MuiFilledInput-root": { borderRadius: 1 },
                        }}
                        {...getFieldProps("triggerDescription")}
                      />
                    </>

                    {values.type === "scheduled" && (
                      <ScheduledTriggerInput formik={formik} />
                    )}
                    {values.type === "amazon_s3" && (
                      <S3TriggerInput formik={formik} />
                    )}
                  </Box>
                </Scrollbar>

                <Divider />

                <DialogActions sx={{ my: -1 }}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Confirm
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={onCloseCompose}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Stack>
            </RootStyle>
          </Form>
        </FormikProvider>
      </Dialog>

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete trigger{" "}
            <strong> {triggerDetail.name} </strong> item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelTrigger();
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </Portal>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useRef, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  ListItem,
  List,
  Drawer,
  Tooltip,
  IconButton,
  Stack,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CollapseMenuIcon } from "@/assets/project/icons/index";
import useCollapseDrawer from "@/hooks/project/useCollapseDrawer";
import Scrollbar from "@/components/project/Scrollbar";
import sidebarConfig from "../SidebarConfig";
import {
  DashboardSidebarProps,
  FIRST_DRAWER_WIDTH,
  SECOND_DRAWER_WIDTH,
} from "../DashboardSidebar";
import Logo from "@/components/project/Logo";
import NavSection from "./NavSection";
import {
  filterAdminSiderConfig,
  filterSiderbarConfigByPermissions,
  findItemAndParentByPath,
  findMainPage,
  isAuthed,
} from "../../utils";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

type siderbarDomProps = Pick<
  DashboardSidebarProps,
  "isOpenSidebar" | "onCloseSidebar"
>;

// route changed will re-mount not simple re-render, component will re-initialize
export default function SiderbarDom(props: siderbarDomProps) {
  const { isOpenSidebar, onCloseSidebar } = props;
  const theme = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const {
    isAdmin,
    isNoneUser,
    isSuperAdmin,
    userDetail: { permissions = [] } = {},
  } = useSelector((state: RootState) => state.common);

  const authTempSider = useRef<Array<any>>([]);

  const isJustGroupAdmin = () => {
    return !isSuperAdmin && isAdmin;
  };

  const [firstMenuItemName, setFirstMenuItemName] = useState(
    findItemAndParentByPath(sidebarConfig, pathname).firstMenuItem
  );

  const { isCollapse, onToggleCollapse, toggleIsCollapseByBol } =
    useCollapseDrawer();

  const activeFirstRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
  };

  const getFirstAuthSiderbarConfig = () => {
    // admin and non-admin auth
    let authSider = filterAdminSiderConfig(isAdmin, isNoneUser, sidebarConfig);

    // filter by user permissions
    authSider = filterSiderbarConfigByPermissions(authSider, permissions);
    authTempSider.current = authSider;

    return authSider;
  };

  const getSecondAuthSiderbarConfig = (firstMenuItemName: string) => {
    const authSiderbar = authTempSider.current.find(
      (item) => item.firstMenuItem === firstMenuItemName
    )?.secondMenuData;

    // group admin auth
    let filterGroupAdminArr: Array<{
      subheader: string;
      items: Array<{
        title: string;
        path: string;
        icon?: any;
        auth?: number[];
      }>;
    }> = [];
    if (isJustGroupAdmin()) {
      filterGroupAdminArr = authSiderbar?.filter(
        (item) =>
          item.subheader !== "OBSERVABILITY" &&
          item.subheader !== "TEMPLATE" &&
          item.subheader !== "CONNECTION"
      )!;
    } else {
      filterGroupAdminArr = authSiderbar!;
    }
    return filterGroupAdminArr;
  };

  const renderFirstMenuContent = useMemo(() => {
    return (
      <Stack
        sx={{
          width: FIRST_DRAWER_WIDTH,
          borderRight: `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Box
          component={RouterLink}
          to={findMainPage(
            isAdmin,
            filterSiderbarConfigByPermissions(
              filterAdminSiderConfig(isAdmin, isNoneUser, sidebarConfig),
              permissions
            )
          )}
          sx={{
            m: "10px auto",
          }}
        >
          <Logo />
        </Box>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 2, mb: 5 }}
        >
          <Tooltip title={isCollapse ? "Show main menu" : "Hide main menu"}>
            <IconButton onClick={onToggleCollapse}>
              <SvgIconStyle
                src={CollapseMenuIcon}
                sx={{
                  width: 30,
                  height: 30,
                  fontWeight: 500,
                }}
              />
            </IconButton>
          </Tooltip>
        </Stack>
        <Scrollbar
          sx={{
            height: 1,
            "& .simplebar-content": {
              height: 1,
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          <List>
            <Stack spacing={4} direction="column">
              {getFirstAuthSiderbarConfig().map((item) => (
                <ListItem
                  key={item.firstMenuItem}
                  onClick={() => {
                    // to the first subMenuItem
                    if (!isMobile) {
                      const targetRoute = getFirstAuthSiderbarConfig().find(
                        (i) => i.firstMenuItem === item.firstMenuItem
                      )?.secondMenuData[0].items[0].path;
                      navigate(targetRoute!);
                      toggleIsCollapseByBol(false);
                    } else {
                      setFirstMenuItemName(item.firstMenuItem);
                    }
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    sx={{
                      width: "50px",
                      height: "50px",
                      ...(item.firstMenuItem === firstMenuItemName && {
                        ...activeFirstRootStyle,
                      }),
                    }}
                  >
                    {item.icon}
                  </IconButton>
                </ListItem>
              ))}
            </Stack>
          </List>
        </Scrollbar>
      </Stack>
    );
  }, [
    theme.palette.divider,
    isCollapse,
    isMobile,
    firstMenuItemName,
    isAdmin,
    isNoneUser,
    permissions,
  ]);

  const renderSecondMenuContent = useMemo(() => {
    return (
      <Stack
        sx={{
          width: SECOND_DRAWER_WIDTH,
          bgcolor: "background.default",
          pt: 1.5,
        }}
      >
        <Scrollbar
          sx={{
            height: 1,
            "& .simplebar-content": {
              height: 1,
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          {/* isShow is to show the specific memu info, here is to set it always true temporaily */}
          {isAuthed(pathname, isNoneUser, isAdmin) && (
            <NavSection
              navConfig={getSecondAuthSiderbarConfig(firstMenuItemName)!}
              isShow
            />
          )}
        </Scrollbar>
      </Stack>
    );
  }, [firstMenuItemName, pathname, isNoneUser, isAdmin, permissions]);

  return (
    <Drawer
      sx={{ flexShrink: 0, height: "100%" }}
      // open and onClose just for temporary drawer, permanent is invalid
      open={isOpenSidebar}
      onClose={onCloseSidebar} // trigger when click esc or backdrop
      PaperProps={{
        sx: {
          overflow: "hidden",
        },
      }}
      variant={isMobile ? "temporary" : "permanent"}
    >
      <Box
        sx={{
          display: "flex",
          height: "100%",
        }}
      >
        {renderFirstMenuContent}
        {!isCollapse && renderSecondMenuContent}
      </Box>
    </Drawer>
  );
}

// material
import { styled } from "@mui/material/styles";
import { IconButton, Stack } from "@mui/material";
// redux
import { useDispatch } from "@/redux/store";
// components
import SvgIconStyle from "@/components/project/SvgIconStyle";
import { getGraph } from "../../../../graph/schema/components/GraphViewer";
import { setShowPropPanel } from "../../../../graph/slices/slice";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  right: 10,
  bottom: 10,
  zIndex: 3339,
  position: "absolute",
  display: "flex",
  alignItems: "center",
  borderRadius: 8,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  // backgroundColor: `${alpha(theme.palette.divider, 1)}`
  backgroundColor: `${theme.palette.background.neutral}`,
}));

const prefix = process.env.NODE_ENV === "development" ? "" : "/ui";

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`${prefix}/static/icons/pipeline/${name}.svg`}
    sx={{ width: 20, height: 20 }}
  />
);

const ICONS = {
  zoomOut: getIcon("ic_zoomout"),
  zoomIn: getIcon("ic_zoomin"),
  refresh: getIcon("ic_refresh"),
  fullview: getIcon("ic_fullview"),
};

// ----------------------------------------------------------------------

export default function Toolbar() {
  const dispatch = useDispatch();

  const zoomOut = () => {
    getGraph().zoom(-0.05, { minScale: 0.1 });
  };

  const zoomIn = () => {
    getGraph().zoom(0.05);
  };

  const zoomToFit = () => {
    getGraph().zoomToFit({ maxScale: 0.8 });
  };

  const refresh = () => {
    dispatch(setShowPropPanel(true));
    setTimeout(() => {
      getGraph().zoomToFit({ maxScale: 0.9 });
    }, 500);
  };

  return (
    <RootStyle>
      <Stack
        direction="column"
        spacing={0.5}
        sx={{ display: "flex", alignItems: "center", mx: 0.5 }}
      >
        <IconButton size="small" onClick={zoomIn}>
          {ICONS.zoomIn}
        </IconButton>
        <IconButton size="small" onClick={zoomOut}>
          {ICONS.zoomOut}
        </IconButton>
        <IconButton size="small" onClick={zoomToFit}>
          {ICONS.fullview}
        </IconButton>
        <IconButton size="small" onClick={refresh}>
          {ICONS.refresh}
        </IconButton>
      </Stack>
    </RootStyle>
  );
}

import { Collapse, Divider, MenuItem, Stack } from "@mui/material";
import { Icon } from "@iconify/react";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import { Form, FormikProvider, useFormik } from "formik";
import { CollapseButtonStyle } from "./SettingSidebar";
import Scrollbar from "@/components/project/Scrollbar";
import * as Yup from "yup";
import { MlPipelineTextfield } from "./components/PipelineComponents";
import { RootState, dispatch } from "@/redux/store";
import { useSelector } from "react-redux";
import { setCurrentKubeflowPipeline } from "@/redux/project/automation/pipeline";

interface IProps {
  data: Record<any, any>;
  isCollapse: boolean;
  onCollapse: VoidFunction;
  isEdit: boolean;
}

const AdvancedSettings = ({ data, isCollapse, onCollapse, isEdit }: IProps) => {
  const {
    currentKubeflowPipeline: { advancedSettings },
  } = useSelector((state: RootState) => state.pipeline);

  const initialValues = {
    billing: data?.billing || "elastic",
    provider: data?.provider || "aws",
    region: data?.region || "beijing",
    node_type: data?.node_type || "cpu",
    node_linux_arch: data?.node_linux_arch || "x86",
  };

  const initialSchema = Yup.object().shape({
    billing: Yup.string().required("billing is required"),
    provider: Yup.string().required("provider is required"),
    region: Yup.string().required("region is required"),
    node_type: Yup.string().required("node_type is required"),
    node_linux_arch: Yup.string().required("node_linux_arch is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: initialSchema,
    onSubmit: () => {},
  });

  const { values } = formik;

  const submit = (key, value) => {
    dispatch(
      setCurrentKubeflowPipeline({
        advancedSettings: {
          ...advancedSettings,
          [key]: value,
        },
      })
    );
  };

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={onCollapse}
        endIcon={
          <Icon
            icon={isCollapse ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
      >
        Advanced Settings
      </CollapseButtonStyle>

      <Scrollbar>
        <Collapse in={isCollapse}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off">
              <Stack spacing={2} sx={{ mx: 2, mb: 2, mt: 1 }}>
                <MlPipelineTextfield
                  select
                  label={"Billing"}
                  field={"billing"}
                  disabled={isEdit}
                  formik={formik}
                  submitThisValuefunc={(value) => submit("billing", value)}
                >
                  <MenuItem value="elastic">Elastic</MenuItem>
                  <MenuItem value="shared">Shared</MenuItem>
                </MlPipelineTextfield>

                <MlPipelineTextfield
                  select
                  label={"Provider"}
                  field={"provider"}
                  disabled={isEdit}
                  formik={formik}
                  submitThisValuefunc={(value) => submit("provider", value)}
                >
                  <MenuItem value="aws">AWS</MenuItem>
                  <MenuItem value="on-prem">On-prem</MenuItem>
                </MlPipelineTextfield>

                <MlPipelineTextfield
                  select
                  label={"Region"}
                  field={"region"}
                  disabled={isEdit}
                  formik={formik}
                  submitThisValuefunc={(value) => submit("region", value)}
                >
                  {values.provider !== "aws" && (
                    <MenuItem value="shanghai">shanghai</MenuItem>
                  )}
                  <MenuItem value="beijing">beijing</MenuItem>
                </MlPipelineTextfield>

                <MlPipelineTextfield
                  select
                  label={"Node Type"}
                  field={"node_type"}
                  disabled={isEdit}
                  formik={formik}
                  submitThisValuefunc={(value) => submit("node_type", value)}
                >
                  <MenuItem value="gpu">gpu</MenuItem>
                  <MenuItem value="cpu">cpu</MenuItem>
                </MlPipelineTextfield>

                <MlPipelineTextfield
                  select
                  label={"Node Linux Arch"}
                  field={"node_linux_arch"}
                  disabled={isEdit}
                  formik={formik}
                  submitThisValuefunc={(value) =>
                    submit("node_linux_arch", value)
                  }
                >
                  <MenuItem value="x86">x86</MenuItem>
                </MlPipelineTextfield>
              </Stack>
            </Form>
          </FormikProvider>
        </Collapse>
      </Scrollbar>

      {!isCollapse && <Divider />}
    </>
  );
};

export default AdvancedSettings;

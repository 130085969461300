/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Button,
} from "@mui/material";

import Scrollbar from "@/components/project/Scrollbar";
import { extractKeys } from "@/utils/project/utils";
import MlCard from "@/components/project/mlComponents/MlCard";
import ConnectionTableActionCol from "./ConnectionTableActionCol";
import MlTableBodyContainer from "@/components/project/mlComponents/MlTableBodyContainer";
import { getConnectionTableData } from "@/redux/project/connection/thunks";
import { RootState, dispatch } from "@/redux/store";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import ConnectionCreateOrEditDialog from "./ConnectionCreateOrEditDialog";
import ConnectionDetailDrawer from "./ConnectionDetailDrawer";
import { useLocation } from "react-router";
import { IconButton } from "@mui/material";
import { Stack } from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

interface Column {
  id: string;
  label?: string;
  minWidth?: number;
  width?: number;
  maxWidth?: number;
}

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 150 },
  { id: "connection_type", label: "Type" },
  { id: "connection_scheme", label: "Scheme" },
  { id: "connection_endpoint", label: "Endpoint" },
  { id: "connection_port", label: "Port" },
  { id: "created_at", label: "Create At" },
  { id: "", width: 150 },
];

const ConnectionTable = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  // dialog
  const [openConnectionCreateDialog, setOpenConnectionCreateDialog] =
    useState<boolean>(false);
  const [dialogMode, setDialogMode] = useState<"create" | "edit">("create");

  // drawer
  const [openConnectionDetailDrawer, setOpenConnectionDetailDrawer] =
    useState<boolean>(false);

  const [rowData, setRowData] = useState({});

  const [page_num, setPageNum] = useState<number>(1);
  const [page_size, setPageSize] = useState<number>(10);

  const {
    connectionTableList: { data, loading },
  } = useSelector((state: RootState) => state.connection);

  const fetchData = (params: {
    name?: string;
    page_num: number;
    page_size: number;
  }) => {
    return dispatch(
      getConnectionTableData({
        params,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  };

  const handleRefresh = (isUpdate?: boolean) => {
    setPageNum(1);
    setPageSize(10);

    fetchData({ page_num: 1, page_size: 10 }).then(() => {
      if (!isUpdate) {
        enqueueSnackbar("Refresh Success", { variant: "success" });
      }
    });
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNum(newPage + 1);
    fetchData({ page_num: newPage + 1, page_size });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNum(1);
    fetchData({ page_num: 1, page_size: parseInt(event.target.value, 10) });
  };

  const renderCell = (row, column) => {
    switch (column.id) {
      case "name":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width", "maxWidth") }}
          >
            <Typography
              onClick={() => {
                setOpenConnectionDetailDrawer(true);
                setRowData(row);
              }}
              variant="subtitle2"
              color="secondary"
              sx={{ cursor: "pointer" }}
            >
              {row[column.id]}
            </Typography>
          </TableCell>
        );
      case "":
        return (
          <TableCell
            align="right"
            key={column.id}
            sx={{
              ...extractKeys(column, "minWidth", "width", "maxWidth"),
              pr: 2,
            }}
          >
            <ConnectionTableActionCol
              row={row}
              setDialogModeAndSetData={() => {
                setOpenConnectionCreateDialog(true);
                setDialogMode("edit");
                setRowData(row);
              }}
              handleRefresh={handleRefresh}
            />
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {row[column.id] || "-"}
            </Typography>
          </TableCell>
        );
    }
  };

  useEffect(() => {
    dispatch(
      getConnectionTableData({
        params: {
          page_num,
          page_size,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  }, []);

  return (
    <MlCard
      title="Connection"
      extraJsx={
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            sx={{ width: 150 }}
            onClick={() => {
              setDialogMode("create");
              setOpenConnectionCreateDialog(true);
            }}
          >
            Add Connection
          </Button>
          <IconButton onClick={() => handleRefresh()}>
            <RefreshOutlinedIcon />
          </IconButton>
        </Stack>
      }
      hasPaddingX={false}
    >
      <Scrollbar>
        <TableContainer sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <MlTableBodyContainer loading={loading} data={data?.items}>
                {data?.items?.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((column) => renderCell(row, column))}
                  </TableRow>
                ))}
              </MlTableBodyContainer>
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25]}
        component="div"
        count={data?.total || 0}
        rowsPerPage={page_size}
        page={page_num - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ConnectionCreateOrEditDialog
        isOpen={openConnectionCreateDialog}
        mode={dialogMode}
        onClose={() => {
          setOpenConnectionCreateDialog(false);
        }}
        row={rowData}
        handleRefresh={handleRefresh}
      />
      <ConnectionDetailDrawer
        isOpen={openConnectionDetailDrawer}
        onClose={() => {
          setOpenConnectionDetailDrawer(false);
        }}
        row={rowData}
      />
    </MlCard>
  );
};

export default ConnectionTable;

import Page from "@/components/project/Page";
import PipelineTable from "./components/pipelineList/PipelineTable";

const MlPipeline = () => {
  return (
    <Page>
      <PipelineTable />
    </Page>
  );
};

export default MlPipeline;

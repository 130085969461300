import { Box, Divider } from "@mui/material";
import { RootState, useSelector } from "@/redux/store";
import { SchemaNodeEntity } from "../../../../graph/slices/slice";
import StepConfig from "./components/StepConfig";
import { FeatureSetDownloadStep } from "@/@types/project/mlPipeline/Kubeflow/pipeline-featureset-download";
import FeatureSetDownloadProcessor from "./components/FeatureSetDownloadProcessor";
import FeatureSetDownloadOutputs from "./components/FeatureSetDownloadOutputs";
import JobArgument from "./components/JobArgument";

type FeatureSetDownloadStepProps = {
  entity: SchemaNodeEntity;
  isEdit: boolean;
};

export default function FeatureSetDownloadStepEntry({
  entity,
  isEdit,
}: FeatureSetDownloadStepProps) {
  const currentStep = useSelector((state: RootState) => {
    let step: FeatureSetDownloadStep | undefined =
      state.schema.schema.pipelineSteps.find((step) => {
        return step.id === entity.id;
      }) as FeatureSetDownloadStep;
    return step ? step : null;
  });

  return (
    <>
      {entity && (
        <Box>
          <StepConfig isEdit={isEdit} currentStep={currentStep} />

          <Divider />

          <JobArgument isEdit={isEdit} currentStep={currentStep} />

          <Divider />

          <FeatureSetDownloadProcessor
            isEdit={isEdit}
            currentStep={currentStep}
          />

          <Divider />

          <FeatureSetDownloadOutputs
            isEdit={isEdit}
            currentStep={currentStep}
          />

          <Divider sx={{ mb: 1 }} />
        </Box>
      )}
    </>
  );
}

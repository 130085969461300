import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import DashboardNavbar from "./navbar/DashboardNavbar";
import DashboardSidebar from "./sidebar/DashboardSidebar";
import Scrollbar from "@/components/project/Scrollbar";
import Disclaimer from "@/components/project/Disclaimer";

// ----------------------------------------------------------------------

const RootStyle = styled("div")({
  display: "flex",
  height: "100%",
});

const MainStyle = styled("main")(({ theme }) => ({
  flexGrow: 1,
  height: "100%",
  // paddingTop: APP_BAR_MOBILE + 10, // + 24
  paddingBottom: 40,
  /* [theme.breakpoints.up('lg')]: {
    // paddingTop: APP_BAR_DESKTOP - 20, // + 24
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }, */
  /* transition: theme.transitions.create('margin', {
    duration: theme.transitions.duration.complex
  }), */
}));

// ----------------------------------------------------------------------

// path change will trigger re-render here once
export default function DashboardLayout() {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    /* console.log('process.env.REACT_APP_BASE_URL', process.env.REACT_APP_BASE_URL);
    console.log('process.env.REACT_APP_URL_PREFIX', process.env.REACT_APP_URL_PREFIX);

    console.log('process.env.NODE.ENV', process.env.NODE_ENV); */
  }, []);

  return (
    <RootStyle>
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle>
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
        <Scrollbar
          sx={{
            height: 1,
            "& .simplebar-content": {
              // height: 1,
              display: "flex",
              flexDirection: "column",
            },
            paddingTop: `54px`,
          }}
        >
          <Outlet />
        </Scrollbar>
      </MainStyle>

      <Disclaimer />
    </RootStyle>
  );
}

import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  InputAdornment,
  TablePagination,
  Card,
  Stack,
  Button,
  Typography,
  Box,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import SearchIcon from "@mui/icons-material/Search";
import Scrollbar from "@/components/project/Scrollbar";
import { extractKeys } from "@/utils/project/utils";
import PipelineTableActionCol from "./PipelineTableActionCol";
import PipelineScenarioLabel from "../PipelineScenarioLabel";
import { useSelector } from "react-redux";
import MlTableBodyContainer from "@/components/project/mlComponents/MlTableBodyContainer";
import { useSnackbar } from "notistack";
import { RootState, dispatch } from "@/redux/store";
import {
  delPipeline,
  getPipelineTableData,
} from "@/redux/project/automation/thunks";
import { useLocation, useNavigate } from "react-router";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import { PATH_DASHBOARD } from "@/routes/paths";

interface Column {
  id: string;
  label?: string;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
}

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 150 },
  { id: "mlpipeline_type", label: "Type" },
  { id: "scenario", label: "Scenario", maxWidth: 75 },
  { id: "created_at", label: "Last Modified" },
  { id: "", width: 150 },
];

const PipelineTable = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  // delete confirm
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const theme = useTheme();
  const isLight = theme.palette.mode === "light";

  // table
  const [searchText, setSearchText] = useState<string>("");
  const [page_num, setPageNum] = useState<number>(1);
  const [page_size, setPageSize] = useState<number>(10);
  const [filteredTableRows, setFilteredTableRows] = useState<
    Array<Record<string, any>>
  >([]);

  const {
    pipelineTableList: { data, loading },
  } = useSelector((state: RootState) => state.automation);

  const nameExist = (name) => {
    if (name) {
      return {
        name,
      };
    }
    return {};
  };

  const fetchData = (params: {
    name?: string;
    pipeline_status?: string;
    page_num: number;
    page_size: number;
  }) => {
    return dispatch(
      getPipelineTableData({
        params,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  };

  const handleRefresh = (isUpdate?: boolean) => {
    setSearchText("");
    setPageNum(1);
    setPageSize(10);

    fetchData({ page_num: 1, page_size: 10 }).then(() => {
      if (!isUpdate) {
        enqueueSnackbar("Refresh Success", { variant: "success" });
      }
    });
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNum(newPage + 1);
    fetchData({ ...nameExist(searchText), page_num: newPage + 1, page_size });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNum(1);
    fetchData({
      ...nameExist(searchText),
      page_num: 1,
      page_size: parseInt(event.target.value, 10),
    });
  };

  const getAddPipelineBtn = () => {
    return (
      <Box sx={{ width: "150px" }}>
        <Button
          onClick={() => {
            navigate("create");
          }}
          variant="contained"
          fullWidth
        >
          Add Pipeline
        </Button>
      </Box>
    );
  };

  const renderCell = (row, column) => {
    switch (column.id) {
      case "name":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="subtitle2">{row[column.id]}</Typography>
          </TableCell>
        );
      case "scenario":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <PipelineScenarioLabel>{row[column.id]}</PipelineScenarioLabel>
          </TableCell>
        );
      case "":
        return (
          <TableCell
            align="right"
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <PipelineTableActionCol row={row} handleRefresh={handleRefresh} />
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {row[column.id] || "-"}
            </Typography>
          </TableCell>
        );
    }
  };

  const getSelectedRows = () => {
    return filteredTableRows?.filter((i) => i.isSelected) || [];
  };

  const handleBatchDelPipeline = async () => {
    setDelLoading(true);
    try {
      await Promise.all(
        filteredTableRows
          ?.filter((item) => item.isSelected)
          .map((item) =>
            dispatch(
              delPipeline({
                id: item.id,
                alertCallback: enqueueSnackbar,
                pathname,
              })
            ).unwrap()
          )
      );

      setDelLoading(false);
      setOpenConfirm(false);
      enqueueSnackbar("Delete Success", { variant: "success" });

      navigate(PATH_DASHBOARD.automation.mlPipeline);
    } catch (e) {
      setDelLoading(false);
    }
  };

  useEffect(() => {
    dispatch(
      getPipelineTableData({
        params: {
          page_num,
          page_size,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  }, []);

  useEffect(() => {
    setFilteredTableRows(
      data?.items?.map((item) => ({
        ...item,
        isSelected: false,
      }))
    );
  }, [data]);

  return (
    <>
      <Card>
        {getSelectedRows()?.length > 0 ? (
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              height: "80px",
              px: 2.5,
              color: isLight ? "primary.main" : "text.primary",
              bgcolor: isLight ? "primary.lighter" : "primary.dark",
            }}
          >
            <Typography component="div" variant="subtitle1">
              {getSelectedRows()?.length} selected
            </Typography>
            <Tooltip title="Delete">
              <IconButton
                size="medium"
                onClick={() => {
                  setOpenConfirm(true);
                }}
              >
                <Icon icon={trash2Fill} />
              </IconButton>
            </Tooltip>
          </Stack>
        ) : (
          <Stack direction="row" justifyContent="space-between" sx={{ p: 2.5 }}>
            <Box sx={{ width: "360px" }}>
              <TextField
                id="search"
                fullWidth
                variant="outlined"
                size="small"
                label="Search by name"
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() =>
                          fetchData({
                            ...nameExist(searchText),
                            page_num: 1,
                            page_size: 10,
                          }).then(() => {
                            enqueueSnackbar("Search Success", {
                              variant: "success",
                            });
                          })
                        }
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Stack direction="row" alignItems="center" spacing={2}>
              {getAddPipelineBtn()}
              <IconButton onClick={() => handleRefresh()}>
                <RefreshOutlinedIcon sx={{ width: "22px", height: "22px" }} />
              </IconButton>
            </Stack>
          </Stack>
        )}
        <Scrollbar>
          <TableContainer sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {!loading && filteredTableRows?.length > 0 && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={
                          getSelectedRows()?.length !== 0 &&
                          getSelectedRows()?.length < filteredTableRows?.length
                        }
                        checked={filteredTableRows?.every((i) => i.isSelected)}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          setFilteredTableRows(
                            filteredTableRows?.map((i) => ({
                              ...i,
                              isSelected: event.target.checked,
                            }))
                          )
                        }
                      />
                    </TableCell>
                  )}
                  {columns.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <MlTableBodyContainer
                  loading={loading}
                  data={filteredTableRows}
                >
                  {filteredTableRows?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={row?.isSelected || false}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            setFilteredTableRows(
                              filteredTableRows?.map((i) => {
                                if (row.id === i.id) {
                                  return {
                                    ...i,
                                    isSelected: event.target.checked,
                                  };
                                } else {
                                  return i;
                                }
                              })
                            )
                          }
                        />
                      </TableCell>
                      {columns.map((column) => renderCell(row, column))}
                    </TableRow>
                  ))}
                </MlTableBodyContainer>
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          component="div"
          count={filteredTableRows?.length || 0}
          rowsPerPage={page_size}
          page={page_num - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete pipeline{" "}
            <strong>
              {" "}
              {filteredTableRows
                ?.filter((item) => item.isSelected)
                .map((item) => item.name)
                .join(", ")}{" "}
            </strong>{" "}
            item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleBatchDelPipeline();
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
};

export default PipelineTable;

import {
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import {
  DeleteIcon,
  StartIcon,
  StopIcon,
  EditIcon,
} from "@/assets/project/icons";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { dispatch } from "@/redux/store";
import {
  editMlServiceDeployment,
  getServiceDeploymentList,
} from "@/redux/project/mlService/mlRuntime/thunks";

interface IProps {
  row: {
    id: string;
    [key: string]: any;
  };
  mlServiceRes: Record<string, any>;
  handleDeploymentDrawer: Function;
  handleEdit: Function;
}

const DeploymentTableActionCol = ({
  row,
  mlServiceRes,
  handleDeploymentDrawer,
  handleEdit,
}: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  // delete confirm
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);

  // dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateStatus = (record: Record<string, any>, type: string) => {
    let params = {};
    if (type === "delete") {
      setDelLoading(true);
      params = { is_active: 0 };
    } else {
      params = { status: type === "deploy" ? "wait_start" : "wait_close" };
    }
    dispatch(
      editMlServiceDeployment({
        id: record.id,
        params,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    )
      .unwrap()
      .then(() => {
        if (type === "delete") {
          setDelLoading(false);
          setOpenConfirm(false);
        }
        // get deployment
        dispatch(
          getServiceDeploymentList({
            params: {
              page_num: 1,
              page_size: 9999,
              is_active: 1,
              mlservice_id: mlServiceRes?.id,
            },
            alertCallback: enqueueSnackbar,
            pathname,
          })
        ).unwrap();
        enqueueSnackbar(
          type === "deploy"
            ? "Deploy Success"
            : type === "stop"
            ? "Stop Success"
            : "Delete Success",
          { variant: "success" }
        );
      });
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={6} lg={6}>
          <IconButton onClick={() => handleDeploymentDrawer(row)}>
            <KeyboardArrowRightOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6} lg={6}>
          <IconButton onClick={handleClick}>
            <MoreVertOutlinedIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              key="deploy"
              onClick={() => handleUpdateStatus(row, "deploy")}
              disabled={row.status !== "closed"}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <SvgIconStyle
                  src={StartIcon}
                  sx={{
                    width: 22,
                    height: 22,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Deploy"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
            <Divider />
            <MenuItem
              key="stop"
              onClick={() => handleUpdateStatus(row, "stop")}
              disabled={row.status !== "started" && row.status !== "failed"}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <SvgIconStyle
                  src={StopIcon}
                  sx={{
                    width: 22,
                    height: 22,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Stop"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
            <Divider />
            <MenuItem
              key="edit"
              onClick={() => {
                handleEdit(row);
                handleClose();
              }}
              disabled={row.status !== "started" && row.status !== "failed"}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <SvgIconStyle
                  src={EditIcon}
                  sx={{
                    width: 22,
                    height: 22,
                  }}
                />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                Edit
              </ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenConfirm(true);
              }}
              sx={{ color: "error.main" }}
              disabled={row.status !== "closed"}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <SvgIconStyle
                  src={DeleteIcon}
                  sx={{
                    width: 22,
                    height: 22,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Delete"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete deployment <strong> {row.name} </strong>{" "}
            item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleUpdateStatus(row, "delete");
              setOpenConfirm(false);
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
};

export default DeploymentTableActionCol;

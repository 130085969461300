import { ListItemText } from "@mui/material";
import { Grid, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useNavigate } from "react-router";
import { useState } from "react";
import { EditIcon } from "@/assets/project/icons";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";

interface IProps {
  row: {
    [key: string]: any;
  };
}

const OrgTableActionCol = ({ row }: IProps) => {
  const navigate = useNavigate();

  const {
    userDetail: { organization_id },
  } = useSelector((state: RootState) => state.common);

  // dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={6} lg={6}>
          <IconButton onClick={() => navigate(`${row.id}`)}>
            <KeyboardArrowRightOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6} lg={6}>
          <IconButton onClick={handleClick}>
            <MoreVertOutlinedIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              key="edit"
              disabled={
                !(
                  organization_id &&
                  (row.status === "started" ||
                    row.status === "draft" ||
                    row.status === "failed")
                )
              }
              onClick={() => {
                navigate(`${row.id}/edit`);
                handleClose();
              }}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <SvgIconStyle
                  src={EditIcon}
                  sx={{
                    width: 22,
                    height: 22,
                  }}
                />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                Edit
              </ListItemText>
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </>
  );
};

export default OrgTableActionCol;

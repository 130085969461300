import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import { useDispatch } from "react-redux";
// material
import { Paper, Stack, IconButton } from "@mui/material";
// type
import { MetricDefinitionStruct } from "@/@types/project/mlPipeline/SageMaker/pipeline-training";
//
import {
  updateMetricDefinition,
  deleteMetricDefinition,
} from "../../../graph/slices/slice";
import { PipelineTextfield } from "../../components/PipelineComponents";

// ----------------------------------------------------------------------

export default function MetricDefinition({
  id,
  data,
}: {
  id: number;
  data: MetricDefinitionStruct;
}) {
  const dispatch = useDispatch();
  const [isInitialzied, setInitialized] = useState(false);
  const [inputValues, setInputValues] = useState(data);

  useEffect(() => {
    if (isInitialzied) {
      const timer = setTimeout(() => {
        dispatch(
          updateMetricDefinition({
            id: id,
            data: inputValues,
          })
        );
        setInitialized(false);
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [dispatch, id, inputValues, isInitialzied]);

  const ParamSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    regex: Yup.string().required("Value is required"),
  });

  const formik = useFormik<MetricDefinitionStruct>({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values, { setErrors }) => {
      try {
        setInitialized(true);
        setInputValues(values);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { handleSubmit } = formik;

  return (
    <>
      <Paper sx={{ p: 1.5, borderRadius: 1.5, bgcolor: "background.neutral" }}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onChange={handleSubmit}>
            <Stack spacing={2}>
              <PipelineTextfield
                label={"Name"}
                field={"Name"}
                placeholder={"Name"}
                formik={formik}
              />

              <Stack direction="row" spacing={1} alignItems="center">
                <PipelineTextfield
                  label={"Regex"}
                  field={"Regex"}
                  placeholder={"Regex"}
                  formik={formik}
                />

                <IconButton
                  size="small"
                  onClick={() => {
                    dispatch(deleteMetricDefinition(id));
                  }}
                >
                  <Icon icon={trash2Fill} width={18} height={18} />
                </IconButton>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </Paper>
    </>
  );
}

import { useState } from "react";
import Page from "@/components/project/Page";
import { Box, Stack } from "@mui/material";
import DashbordStatistic from "./components/featureSetList/DashbordStatistic";
import FeatureSetTable from "./components/featureSetList/FeatureSetTable";
import FeatureSetDetailDrawer from "./components/featureSetList/FeatureSetDetailDrawer";

export default function FeatureSet() {
  const [openFeatureSetDetailDrawer, setOpenFestureSetDetailDrawer] =
    useState<boolean>(false);
  const [featureSetDetail, setFeatureSetDetail] = useState<Record<string, any>>(
    {}
  );

  const getLinks = () => {
    return [
      {
        name: "Data Asset",
      },
      {
        name: "Feature Set",
      },
    ];
  };

  return (
    <Page heading="Feature Set" links={getLinks()}>
      <Box sx={{ mb: 2 }}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <DashbordStatistic />
        </Stack>
      </Box>
      <FeatureSetTable
        setDrawerOpenAndSetData={(rowData) => {
          setOpenFestureSetDetailDrawer(true);
          setFeatureSetDetail(rowData);
        }}
      />
      {openFeatureSetDetailDrawer && (
        <FeatureSetDetailDrawer
          isOpen={openFeatureSetDetailDrawer}
          onClose={() => {
            setOpenFestureSetDetailDrawer(false);
          }}
          detailData={featureSetDetail}
          clearDetailData={() => {
            setFeatureSetDetail({});
          }}
        />
      )}
    </Page>
  );
}

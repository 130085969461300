import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Box, Stack, Collapse, IconButton } from "@mui/material";
//
import { LambdaStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-lambda";
import EmptyTable from "@/components/project/EmptyTable";
import LambdaOutput from "./LambdaOutput";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { updateLambdaOutput } from "../../../graph/slices/slice";

// ----------------------------------------------------------------------

type Props = {
  currentStep: LambdaStep;
};

export default function LambdaOutputs({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [showLambdaOutput, setShowLambdaOutput] = useState(false);

  const lambdaOutputs = currentStep?.lambdaOutputs || [];

  const handleAddLambdaOutput = () => {
    dispatch(
      updateLambdaOutput({
        data: {
          name: `LambdaOutput-${lambdaOutputs.length + 1}`,
          type: "String",
        },
      })
    );
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <CollapseButtonStyle
          fullWidth
          color="inherit"
          onClick={() => setShowLambdaOutput((prev) => !prev)}
          endIcon={
            <Icon
              icon={
                showLambdaOutput ? arrowIosDownwardFill : arrowIosForwardFill
              }
              width={16}
              height={16}
            />
          }
        >
          Lambda Outputs{" "}
          {lambdaOutputs.length > 0 && `(${lambdaOutputs.length})`}
        </CollapseButtonStyle>

        <IconButton
          sx={{ zIndex: 1999, position: "absolute", top: 7, right: 30 }}
          size="small"
          onClick={handleAddLambdaOutput}
        >
          <Icon icon={plusFill} width={16} height={16} />
        </IconButton>
      </Box>

      <Collapse in={showLambdaOutput}>
        <Stack
          spacing={1.5}
          sx={{ mx: 2, mb: 1.5, mt: showLambdaOutput ? 0.5 : 0 }}
        >
          {lambdaOutputs.length > 0 &&
            lambdaOutputs.map((lambdaOutput, index) => (
              <LambdaOutput key={index} id={index} data={lambdaOutput} />
            ))}
        </Stack>
      </Collapse>

      {showLambdaOutput && lambdaOutputs.length === 0 && (
        <EmptyTable
          mainTitle={"No lambda outputs"}
          content={'Create one by clicking "+" button'}
          color={"text.disabled"}
          sx={{ backgroundColor: "transparent", mx: 2, mt: 5, mb: 7 }}
        />
      )}
    </>
  );
}

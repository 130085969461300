/* eslint-disable react-hooks/exhaustive-deps */

import Page from "@/components/project/Page";
import { PATH_DASHBOARD } from "@/routes/paths";
import { useLocation, useParams } from "react-router";
import MlRuntimeEditForm from "./components/mlRuntimeCreateOrEdit/MlRuntimeEditForm";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { dispatch, useSelector, RootState } from "@/redux/store";
import {
  getMlRuntimeById,
  getModelPackageList,
  getTagList,
} from "@/redux/project/mlService/mlRuntime/thunks";

const MlRuntimeEdit = () => {
  const { pathname } = useLocation();
  const { runtimeId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const {
    mlRuntimeDetail: { data: serviceDetail },
  } = useSelector((state: RootState) => state.mlRuntime);

  useEffect(() => {
    // get service
    dispatch(
      getMlRuntimeById({
        id: runtimeId || "",
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    // get model package
    dispatch(
      getModelPackageList({
        params: { page_num: 1, page_size: 9999 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    // get tag
    dispatch(
      getTagList({
        params: { page_num: 1, page_size: 9999, is_active: 1 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  }, []);

  const getLinks = () => {
    return [
      {
        name: "ML Service",
      },
      {
        name: "ML Runtime",
        href: PATH_DASHBOARD.mlService.mlRuntime,
      },
      {
        name: serviceDetail?.name || "",
        href: `${PATH_DASHBOARD.mlService.mlRuntime}/${runtimeId}`,
      },
      {
        name: `Edit ML Runtime` || "",
      },
    ];
  };

  return (
    <Page
      title={`${`Edit ML Runtime`} | OmniML`}
      heading={`Edit ML Runtime`}
      links={getLinks()}
    >
      <MlRuntimeEditForm />
    </Page>
  );
};

export default MlRuntimeEdit;

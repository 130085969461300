import { createSlice } from "@reduxjs/toolkit";
import { ICommonState } from "@/@types/project/model/common";
import { addThunkReducers } from "../utils";
// utils

// ----------------------------------------------------------------------

const initialState: ICommonState = {
  isNoneUser: false,
  isSuperAdmin: false,
  isAdmin: false,
  config: {},
  userDetail: {}, // user info by decoding token
};

const slice = createSlice({
  name: "common",
  initialState,
  reducers: {
    // this state is current common state not root state
    setCommonData(state, action) {
      Object.assign(state, action.payload);
      /* return {
        ...state,
        ...action.payload,
      } */
    },
    setUserDetailData(state, action) {
      Object.assign(state.userDetail, action.payload);
    },
    // no payload
    // queryUserInfo(state) {
    //   console.log('state', state);
    // },
    /* queryUserInfo(state, { payload }) {
      state.user_id = payload;
    }, */
  },
  extraReducers: (builder) => {
    addThunkReducers(builder, [
      // action: the thunk func by createAsyncThunk, name: the state in initialState
      // options: { updateDirectly: true }, set the option to set the data directly without loading and error
      // { action: getUserInfo, name: 'userInfo' },
      // { action: simpleAction, name: 'user_id', options: { updateDirectly: true } },
    ]);
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setCommonData, setUserDetailData } = slice.actions;

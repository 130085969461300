import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Field, FormikProps } from "formik";
import { IKubeflowHpTunerFormValues } from "..";

interface IProps {
  formik: FormikProps<IKubeflowHpTunerFormValues>;
}

const MetaData = ({ formik }: IProps) => {
  const { errors, touched } = formik;

  return (
    <>
      <Typography variant="subtitle1">Metadata</Typography>
      <Box sx={{ pl: 2 }}>
        <Field
          as={TextField}
          name="metaData.name"
          label="Name"
          size="small"
          fullWidth
          margin="dense"
          helperText={
            (touched.metaData?.name && errors.metaData?.name) ||
            "The name of the Experiment CR that will be submitted in the Kubernetes Cluster, it must be DN$1123 compliant."
          }
          error={touched.metaData?.name && Boolean(errors.metaData?.name)}
          FormHelperTextProps={{ sx: { minHeight: "18px", marginTop: "2px" } }}
        />
        <FormControl
          fullWidth
          margin="dense"
          size="small"
          error={
            touched.metaData?.clusterNames &&
            Boolean(errors.metaData?.clusterNames)
          }
        >
          <InputLabel>Cluster Name</InputLabel>
          <Field
            as={Select}
            multiple
            name="metaData.clusterNames"
            label="Cluster Name"
            error={
              touched.metaData?.clusterNames &&
              Boolean(errors.metaData?.clusterNames)
            }
          >
            <MenuItem value="default-k8s">default-k8s</MenuItem>
          </Field>
          <FormHelperText
            margin="dense"
            sx={{ minHeight: "18px", marginTop: "2px" }}
          >
            {touched.metaData?.clusterNames && errors.metaData?.clusterNames
              ? errors.metaData?.clusterNames
              : ""}
          </FormHelperText>
        </FormControl>
      </Box>
    </>
  );
};

export default MetaData;

import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Box, Stack, Collapse, IconButton } from "@mui/material";
//
import EmptyTable from "@/components/project/EmptyTable";
import ProcessingOutput from "./ProcessingOutput";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { ProcessingStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-processing";
import { updateProcessingOutput } from "../../../graph/slices/slice";

// ----------------------------------------------------------------------

type Props = {
  currentStep: ProcessingStep | null;
};

export default function ProcessingOutputs({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [showProcessingOutput, setShowProcessingOutput] = useState(false);

  const processingOutputs = currentStep?.processingOutputs || [];

  const handleAddProcessingOutput = () => {
    dispatch(
      updateProcessingOutput({
        data: {
          outputName: `ProcessingOutput-${processingOutputs.length + 1}`,
          source: "/opt/ml/processing/ie/data/processed",
          sourceUseParam: false,
          destination: "",
          destinationUseParam: false,
          s3UploadMode: "EndOfJob",
        },
      })
    );
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <CollapseButtonStyle
          fullWidth
          color="inherit"
          onClick={() => setShowProcessingOutput((prev) => !prev)}
          endIcon={
            <Icon
              icon={
                showProcessingOutput
                  ? arrowIosDownwardFill
                  : arrowIosForwardFill
              }
              width={16}
              height={16}
            />
          }
        >
          Processing Outputs{" "}
          {processingOutputs.length > 0 && `(${processingOutputs.length})`}
        </CollapseButtonStyle>

        <IconButton
          sx={{ zIndex: 1999, position: "absolute", top: 7, right: 30 }}
          size="small"
          onClick={handleAddProcessingOutput}
        >
          <Icon icon={plusFill} width={16} height={16} />
        </IconButton>
      </Box>

      <Collapse in={showProcessingOutput}>
        <Stack
          spacing={1.5}
          sx={{ mx: 2, mb: 1.5, mt: showProcessingOutput ? 0.5 : 0 }}
        >
          {processingOutputs.length > 0 &&
            processingOutputs.map((processingOutput, index) => (
              <ProcessingOutput
                key={index}
                id={index}
                data={processingOutput}
              />
            ))}
        </Stack>
      </Collapse>

      {showProcessingOutput && processingOutputs.length === 0 && (
        <EmptyTable
          mainTitle={"No processing outputs"}
          content={'Create one by clicking "+" button'}
          color={"text.disabled"}
          sx={{ backgroundColor: "transparent", mx: 2, mt: 5, mb: 7 }}
        />
      )}
    </>
  );
}

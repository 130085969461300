import { Icon } from "@iconify/react";
import { shallowEqual } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import plusFill from "@iconify/icons-eva/plus-fill";
import arrowIosBackFill from "@iconify/icons-eva/arrow-ios-back-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
// material
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  Drawer,
  Button,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  IconButtonProps,
} from "@mui/material";
// redux
import { RootState, useSelector, dispatch } from "@/redux/store";
// components
import TriggerList from "./TriggerList";
import Scrollbar from "@/components/project/Scrollbar";
import { MHidden } from "@/components/project/@material-extend";
import { setShowPropPanel } from "../graph/slices/slice";
import TriggerConfigPanel from "./pipeline-triggers/TriggerConfigPanel";
import { getPipelineTriggerTableData } from "@/redux/project/automation/thunks";
import { useSnackbar } from "notistack";
import { setCurrentKubeflowPipeline } from "@/redux/project/automation/pipeline";
import { isEmpty } from "lodash";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 109;

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
}));

const ToggleButtonStyle = styled((props) => (
  <IconButton disableRipple {...props} />
))<IconButtonProps>(({ theme }) => ({
  left: 0,
  zIndex: 9,
  width: 20,
  height: 32,
  padding: 2,
  position: "absolute",
  top: theme.spacing(7.5),
  boxShadow: theme.customShadows.z4,
  backgroundColor: theme.palette.background.paper,
  border: `solid 1px ${theme.palette.divider}`,
  borderLeft: 0,
  borderRadius: `0 10px 10px 0`,
  transition: theme.transitions.create("all"),
  "&:hover": {
    backgroundColor: theme.palette.background.neutral,
  },
}));

// ----------------------------------------------------------------------

export default function TriggerSidebar() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { versionId } = useParams();

  const [openSidebar, setOpenSidebar] = useState(true);
  const [selectedTriggerId, setSelectedTriggerId] = useState<string>("");
  const [openTriggerConfigPanel, setOpenTriggerConfigPanel] = useState(false);

  const isCreate = pathname.includes("/createVersion");
  const isDuplicate = pathname.includes("/duplicateVersion");
  const isEdit = !isCreate && !isDuplicate;

  const {
    currentPipeline: { triggerList: sTriggerList },
    currentKubeflowPipeline: { triggerList: kTriggerList },
  } = useSelector((state: RootState) => state.pipeline, shallowEqual);

  const {
    pipelineVersionDetail: { data: versionDetail = {} },
  } = useSelector((state: RootState) => state.automation);

  const { pipelineType } = useSelector((state: RootState) => state.schema);
  const isKubeflow = pipelineType === "Kubeflow";
  const triggerList = isKubeflow ? kTriggerList : sTriggerList;

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (isMobile) {
      return handleCloseSidebar();
    }
    return handleOpenSidebar();
  }, [isMobile, pathname]);

  const handleOpenSidebar = () => {
    setOpenSidebar(true);
  };

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  const handleAddTrigger = () => {
    setSelectedTriggerId("");
    setOpenTriggerConfigPanel(true);
  };

  const handleOpenTriggerConfigPanel = (triggerId: string) => {
    setOpenTriggerConfigPanel(true);
    setSelectedTriggerId(triggerId);
  };

  const handleCloseTriggerConfigPanel = () => {
    setOpenTriggerConfigPanel(false);
  };

  const handleToggleSidebar = () => {
    setOpenSidebar((prev) => !prev);
    setTimeout(() => {
      dispatch(setShowPropPanel(true));
    }, 500);
  };

  const renderContent = (
    <>
      <Box sx={{ pt: 1.35, pb: 1.35, mx: "auto" }}>
        <LabelStyle variant="h6" color={theme.palette.text.secondary}>
          TRIGGER
        </LabelStyle>
      </Box>

      <Divider />

      <Scrollbar sx={{ overflowX: "hidden" }}>
        <TriggerList
          triggerList={triggerList}
          onOpenTriggerConfigPanel={handleOpenTriggerConfigPanel}
        />
      </Scrollbar>

      <Button
        size="medium"
        variant="contained"
        color="primary"
        // one trigger type can only add one
        disabled={
          isEmpty(versionDetail) ||
          triggerList.findIndex(
            (item) => item.trigger_type === "schedule_trigger"
          ) > -1
        }
        startIcon={<Icon icon={plusFill} />}
        onClick={handleAddTrigger}
        sx={{
          position: "absolute",
          boxShadow: "none",
          mx: "8px",
          mb: "5px",
          py: 0.6,
          px: 1.2,
          bottom: 3,
        }}
      >
        Trigger
      </Button>
    </>
  );

  const handleInitList = () => {
    dispatch(
      getPipelineTriggerTableData({
        params: {
          mlpipeline_version_id: versionId!,
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    )
      .unwrap()
      .then((res) => {
        dispatch(setCurrentKubeflowPipeline({ triggerList: res?.items || [] }));
      });
  };

  useEffect(() => {
    if (isEdit && versionId) {
      // may be duplicate
      handleInitList();
    }
  }, []);

  useEffect(() => {
    if (!openTriggerConfigPanel) {
      setSelectedTriggerId("");
    }
  }, [openTriggerConfigPanel]);

  return (
    <>
      <ToggleButtonStyle
        onClick={handleToggleSidebar}
        sx={{
          ...(openSidebar && !isMobile && { left: DRAWER_WIDTH }),
        }}
      >
        <Icon
          width={20}
          height={20}
          icon={openSidebar ? arrowIosBackFill : arrowIosForwardFill}
        />
      </ToggleButtonStyle>

      {/* Mobile */}
      <MHidden width="mdUp">
        <Drawer
          ModalProps={{ keepMounted: true }}
          open={openSidebar}
          onClose={handleCloseSidebar}
          sx={{
            "& .MuiDrawer-paper": { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      {/* Desktop */}
      <MHidden width="mdDown">
        <Drawer
          open={openSidebar}
          variant="persistent"
          sx={{
            height: 1,
            width: DRAWER_WIDTH,
            transition: theme.transitions.create("width"),
            ...(!openSidebar && { width: "0px" }),
            "& .MuiDrawer-paper": {
              position: "static",
              width: DRAWER_WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      {openTriggerConfigPanel && (
        <TriggerConfigPanel
          isOpenCompose={openTriggerConfigPanel}
          onCloseCompose={handleCloseTriggerConfigPanel}
          isEdit={Boolean(selectedTriggerId)}
          selectedTriggerId={selectedTriggerId}
          handleRefresh={handleInitList}
        />
      )}
    </>
  );
}

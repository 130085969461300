import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Collapse, MenuItem, Tooltip, Typography } from "@mui/material";
import { CollapseButtonStyle } from "../../../../SettingSidebar";
import { useEffect, useState } from "react";
import { MlPipelineTextfield } from "../../../../components/PipelineComponents";
import { updateOneKeyofStep } from "@/pages/project/automation/MlPipeline/graph/slices/slice";
import { RegisterModelStep } from "@/@types/project/mlPipeline/Kubeflow/pipeline-register-model";
import { RootState } from "@/redux/store";
import { Switch } from "@mui/material";

const JobArgument = ({
  currentStep,
  isEdit,
}: {
  currentStep: RegisterModelStep | null;
  isEdit: boolean;
}) => {
  const dispatch = useDispatch();
  const [showProcessor, setShowProcessor] = useState(true);

  const {
    connectionTableList: { data: { items: connList = [] } = {} },
    credentialTableList: { data: { items: credentialList = [] } = {} },
  } = useSelector((state: RootState) => state.connection);

  const {
    modelPackageDatas: { data: { items: mpList = [] } = {} },
  } = useSelector((state: RootState) => state.modelRegistry);

  const initialValues = currentStep?.arguments || {
    useParam: false,
    name: "",
    mpId: "",
    mpExternalId: "",
    modelPackageType: "",
    deliverTo: "",
    resourcePath: "",
    resourceType: "",
    resourceConnection: "",
    resourceConnectionCredential: "",
    description: "",
  };

  const ParamSchema = Yup.object().shape({
    mpId: Yup.string().when("useParam", {
      is: true,
      then: Yup.string().required("ModelPackage Name is required"),
    }),
    name: Yup.string().when("useParam", {
      is: false,
      then: Yup.string().required("ModelPackage Name is required"),
    }),
    modelPackageType: Yup.string().when("useParam", {
      is: false,
      then: Yup.string().required("ModelPackage Type is required"),
    }),
    resourceType: Yup.string().when("useParam", {
      is: false,
      then: Yup.string().required("Resource Type is required"),
    }),
    resourceConnection: Yup.string().when("useParam", {
      is: false,
      then: Yup.string().required("Resource Connection is required"),
    }),
    resourceConnectionCredential: Yup.string().when("useParam", {
      is: false,
      then: Yup.string().required("Resource Connection Credential is required"),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ParamSchema,
    onSubmit: () => {},
  });

  const { setFieldValue, values } = formik;

  const submitFunc = (fieldName, value) => {
    try {
      dispatch(
        updateOneKeyofStep({
          key: "arguments",
          data: {
            [fieldName]: value,
          },
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleMultipleValues = (obj) => {
    for (const key in obj) {
      setFieldValue(key, obj[key]);
      submitFunc(key, obj[key]);
    }
  };

  // same node type，but different node data
  useEffect(() => {
    formik.resetForm();
    formik.setValues(initialValues);
  }, [currentStep?.id]);

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setShowProcessor((prev) => !prev)}
        endIcon={
          <Icon
            icon={showProcessor ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
      >
        Job Argument
      </CollapseButtonStyle>

      <Collapse in={showProcessor}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off">
            <Stack spacing={2} sx={{ mx: 2, my: 1, mb: 1.5 }}>
              <Stack direction="row" alignItems="center">
                {values.useParam ? (
                  <MlPipelineTextfield
                    select
                    label={"Model Package Name"}
                    field={"mpId"}
                    disabled={isEdit}
                    formik={formik}
                    // submitThisValuefunc={(value) => submitFunc("mpId", value)}
                    onChange={(e) => {
                      const curMpId = e.target.value;
                      handleMultipleValues({ mpId: curMpId });
                      // setFieldValue("mpId", curMpId);
                      // submitFunc("mpId", curMpId);

                      submitFunc(
                        "mpExternalId",
                        mpList.find((i) => i.id === curMpId)?.external_id || ""
                      );
                    }}
                  >
                    {mpList.map((item) => (
                      <MenuItem key={item.id} value={item.id} title={item.name}>
                        <Typography noWrap textOverflow="ellipsis">
                          {item.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </MlPipelineTextfield>
                ) : (
                  <MlPipelineTextfield
                    label={"Model Package Name"}
                    field={"name"}
                    disabled={isEdit}
                    formik={formik}
                    submitThisValuefunc={(value) => submitFunc("name", value)}
                  />
                )}

                <Tooltip title="Select existing or create new">
                  <Switch
                    size="small"
                    checked={values.useParam}
                    disabled={isEdit}
                    onChange={(e) => {
                      setFieldValue("useParam", e.target.checked);
                      submitFunc("useParam", e.target.checked);

                      if (e.target.checked) {
                        handleMultipleValues({
                          name: "",
                          modelPackageType: "",
                          deliverTo: "",
                          resourcePath: "",
                          resourceType: "",
                          resourceConnection: "",
                          resourceConnectionCredential: "",
                        });
                      } else {
                        handleMultipleValues({
                          mpId: "",
                          mpExternalId: "",
                        });
                      }
                    }}
                  />
                </Tooltip>
              </Stack>

              {!values.useParam && (
                <>
                  <MlPipelineTextfield
                    label={"Deliver To"}
                    field={"deliverTo"}
                    disabled={isEdit}
                    formik={formik}
                    submitThisValuefunc={(value) =>
                      submitFunc("deliverTo", value)
                    }
                  />

                  <MlPipelineTextfield
                    select
                    label="Model Package Type"
                    field={"modelPackageType"}
                    disabled={isEdit}
                    formik={formik}
                    submitThisValuefunc={(value) =>
                      submitFunc("modelPackageType", value)
                    }
                  >
                    <MenuItem key="Internal" value="Internal">
                      Internal
                    </MenuItem>
                    <MenuItem key="External" value="External">
                      External
                    </MenuItem>
                  </MlPipelineTextfield>

                  <MlPipelineTextfield
                    label={"Resource Path"}
                    field={"resourcePath"}
                    disabled={isEdit}
                    formik={formik}
                    submitThisValuefunc={(value) =>
                      submitFunc("resourcePath", value)
                    }
                  />

                  <MlPipelineTextfield
                    select
                    label="Resource Type"
                    field={"resourceType"}
                    disabled={isEdit}
                    formik={formik}
                    submitThisValuefunc={(value) =>
                      submitFunc("resourceType", value)
                    }
                  >
                    <MenuItem value="SQL">SQL</MenuItem>
                    <MenuItem value="ZIP">ZIP</MenuItem>
                    <MenuItem value="TXT">TXT</MenuItem>
                    <MenuItem value="CSV">CSV</MenuItem>
                    <MenuItem value="PARQUET">PARQUET</MenuItem>
                    <MenuItem value="BINARY">BINARY</MenuItem>
                    <MenuItem value="BINARY">DICOM</MenuItem>
                  </MlPipelineTextfield>

                  <MlPipelineTextfield
                    select
                    label="Resource Connnection"
                    field={"resourceConnection"}
                    disabled={isEdit}
                    formik={formik}
                    submitThisValuefunc={(value) =>
                      submitFunc("resourceConnection", value)
                    }
                  >
                    {connList.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </MlPipelineTextfield>

                  <MlPipelineTextfield
                    select
                    label="Resource Connnection Credential"
                    field={"resourceConnectionCredential"}
                    disabled={isEdit}
                    formik={formik}
                    submitThisValuefunc={(value) =>
                      submitFunc("resourceConnectionCredential", value)
                    }
                  >
                    {credentialList.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </MlPipelineTextfield>
                </>
              )}
            </Stack>
          </Form>
        </FormikProvider>
      </Collapse>
    </>
  );
};

export default JobArgument;

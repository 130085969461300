/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Scrollbar from "@/components/project/Scrollbar";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { RootState, dispatch } from "@/redux/store";
import { addTags, getTags } from "@/redux/project/administration/thunks";
import { useLocation, useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import {
  addNotebookVolumeTags,
  delNotebookVolumeTags,
  getNotebookDetailData,
  getNotebookVolumeDetailData,
  getNotebookVolumeTags,
  getRelNotebookVolumeData,
} from "@/redux/project/experiment/thunks";
import { resetNotebookData } from "@/redux/project/experiment/slice";
import { LoadingButton } from "@mui/lab";
import qs from "qs";
import { PATH_DASHBOARD } from "@/routes/paths";
import useCollapseDrawer from "@/hooks/project/useCollapseDrawer";

interface INotebookVolumeUpdateTagsDrawer {
  isOpen: boolean;
  onClose: VoidFunction;
  row: {
    [key: string]: any;
  };
}

const NotebookVolumeUpdateTagsDrawer = ({
  isOpen,
  onClose,
  row,
}: INotebookVolumeUpdateTagsDrawer) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { toggleIsCollapseByBol } = useCollapseDrawer();

  const allExistTags = useRef<any[]>([]);

  const [notebookTagsArr, setNotebookTagsArr] = useState<string[]>([]);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    tags: { items: tagList = [] },
  } = useSelector((state: RootState) => state.administration);

  const {
    notebookVolumeDetail: { data, loading },
  } = useSelector((state: RootState) => state.experiment);

  const [usedBy, setUsedBy] = useState<{
    value: any[];
    loading: boolean;
  }>({
    value: [],
    loading: false,
  });

  const getOrderedDetail = (data) => {
    const { resource_config: { env = {}, notebook_volume = {} } = {} } = data;

    return {
      Size: {
        value: notebook_volume?.size || "-",
        loading,
        fullRow: false,
      },
      "Cluster Name": {
        value: env?.cluster_names || "-",
        loading,
        fullRow: false,
      },
      "Used By": {
        value: !isEmpty(usedBy.value) ? (
          <Stack direction="row">
            {usedBy.value?.map((item, index) => (
              <Stack
                direction="row"
                alignItems="center"
                spacing={0.5}
                sx={{ pt: 0.5 }}
              >
                <Typography variant="body2">{item?.name}</Typography>
                {item.status === "started" && (
                  <IconButton
                    sx={{ color: "primary.main" }}
                    onClick={() => {
                      navigate(
                        `${PATH_DASHBOARD.experiment.notebook}/${
                          item.id
                        }?${qs.stringify({ url: item.notebook_url })}`
                      );
                      toggleIsCollapseByBol(true);
                    }}
                  >
                    <OpenInNewOutlinedIcon sx={{ width: 16, height: 16 }} />
                  </IconButton>
                )}
                {index !== usedBy.value.length - 1 && (
                  <Box component="span" sx={{ mx: 0.5 }}>
                    、
                  </Box>
                )}
              </Stack>
            ))}
          </Stack>
        ) : (
          "-"
        ),
        loading: usedBy.loading,
        fullRow: true,
      },
      "Access Mode": {
        value: notebook_volume?.access_model || "-",
        loading,
        fullRow: false,
      },
      "Storage Class": {
        value: notebook_volume?.storage_class || "-",
        loading,
        fullRow: false,
      },
      Status: {
        value: data?.status || "-",
        loading,
        fullRow: false,
      },
      "Create At": {
        value: data?.created_at || "-",
        loading,
        fullRow: false,
      },
    };
  };

  const handleUpdateTags = async () => {
    try {
      setIsSubmitting(true);

      const deleteIds: string[] = [];
      const addIds: string[] = [];
      notebookTagsArr.forEach((item) => {
        if (allExistTags.current.findIndex((i) => i.tag_id === item) === -1) {
          addIds.push(item);
        }
      });
      allExistTags.current.forEach((item) => {
        if (!notebookTagsArr.includes(item.tag_id)) {
          deleteIds.push(item.tag_id);
        }
      });

      // delete
      await Promise.all(
        deleteIds.map((item) =>
          dispatch(
            delNotebookVolumeTags({
              id: allExistTags.current.find((i) => i.tag_id === item).id,
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap()
        )
      );

      // add
      await Promise.all(
        addIds.map((item) =>
          dispatch(
            addNotebookVolumeTags({
              params: {
                notebook_volume_id: row.id,
                tag_id: item,
              },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap()
        )
      );

      setIsSubmitting(false);
      enqueueSnackbar("Update Success", { variant: "success" });
      onClose();
    } catch (err) {
      setIsSubmitting(false);
    }
  };

  const handleInit = async () => {
    try {
      setUsedBy({
        ...usedBy,
        loading: true,
      });

      await dispatch(
        getNotebookVolumeDetailData({
          id: row.id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      const r = await dispatch(
        getRelNotebookVolumeData({
          params: {
            notebook_volume_id: row.id,
            page_num: 1,
            page_size: 9999,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      const tempPromises = r.items.map((item) =>
        dispatch(
          getNotebookDetailData({
            id: item.notebook_id,
            alertCallback: enqueueSnackbar,
            pathname,
          })
        ).unwrap()
      );

      Promise.all(tempPromises).then((k) => {
        setUsedBy({
          value: k,
          loading: false,
        });
      });
    } catch (e) {
      setUsedBy({
        value: [],
        loading: false,
      });
    }

    // all tags
    dispatch(
      getTags({
        params: {
          is_active: 1,
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );

    // selectedTags
    dispatch(
      getNotebookVolumeTags({
        params: {
          notebook_volume_id: row.id,
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    )
      .unwrap()
      .then((res) => {
        setNotebookTagsArr(res?.items.map((item) => item.tag_id));
        allExistTags.current = res?.items;
      });
  };

  useEffect(() => {
    if (isOpen) {
      handleInit();
    }
  }, [isOpen]);

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        onClose();
        dispatch(resetNotebookData("notebookVolumeDetail"));
      }}
      anchor="right"
      sx={{
        "& .MuiPaper-root": {
          width: "320px",
          bgcolor: "background.default",
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          py: 2.5,
          px: 4,
          bgcolor: "background.paper",
        }}
      >
        <Typography variant="subtitle1">Info</Typography>
        {/* <Icon></Icon> */}
        <CloseOutlinedIcon sx={{ cursor: "pointer" }} onClick={onClose} />
      </Stack>
      <Scrollbar sx={{ maxHeight: "800px" }}>
        {loading ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              height: 60,
              py: "10px",
            }}
          >
            <Skeleton
              variant="rectangular"
              width="150px"
              height="40px"
              sx={{ borderRadius: 2, mt: 1, mb: 1 }}
            />
          </Stack>
        ) : (
          <Typography variant="h6" align="center" paddingY={2}>
            {data.name}
          </Typography>
        )}
        <Autocomplete
          size="small"
          multiple
          disableCloseOnSelect
          disablePortal
          freeSolo
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          limitTags={2}
          getOptionLabel={(option) =>
            typeof option === "object" ? option.name : option
          }
          isOptionEqualToValue={(option, value) => {
            if (typeof option === "object" && typeof value === "object") {
              return option.id === value.id;
            } else if (
              typeof option === "string" ||
              typeof value === "string"
            ) {
              return option === value;
            }
            return false;
          }}
          options={tagList}
          value={notebookTagsArr.map(
            (tagId) => tagList.find((tag) => tag.id === tagId) || tagId
          )}
          onChange={(_, newValues) => {
            const filteredValues = newValues.filter(
              (item) => typeof item === "string"
            );

            if (tagList.find((i) => i.name === filteredValues[0])) {
              enqueueSnackbar(`Tag ${filteredValues[0]} existed`, {
                variant: "warning",
              });
              return;
            }

            const updatedTagsPromises = newValues.map(async (item) => {
              if (typeof item === "string") {
                // new text ,add a new tag
                const res = await dispatch(
                  addTags({
                    params: {
                      is_active: 1,
                      name: item,
                    },
                    alertCallback: enqueueSnackbar,
                    pathname,
                  })
                ).unwrap();

                enqueueSnackbar(`Add Tag ${item} Success`, {
                  variant: "success",
                });

                await dispatch(
                  getTags({
                    params: {
                      is_active: 1,
                      page_num: 1,
                      page_size: 9999,
                    },
                    alertCallback: enqueueSnackbar,
                    pathname,
                  })
                );

                return res.id;
              }
              return item.id;
            });

            Promise.all(updatedTagsPromises).then((r) => {
              setNotebookTagsArr(r);
            });
          }}
          renderInput={(params) => <TextField {...params} label="Tags" />}
          sx={{ mt: 1, mb: 0.5, mx: 2 }}
        />
        <Stack sx={{ px: 4 }}>
          <Divider sx={{ mt: 2 }} />
          <Accordion
            defaultExpanded
            elevation={0}
            sx={{ width: "100% !important", boxShadow: "none !important" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                padding: 0,
                minHeight: "64px",
                "& .MuiAccordionSummary-content": {
                  margin: "0 !important",
                },
              }}
            >
              <Typography variant="subtitle1" paddingY={2}>
                Properties
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0 5px" }}>
              <Grid container columnSpacing={1}>
                {Object.entries(getOrderedDetail(data)).map(
                  ([key, valueObj]) => (
                    <>
                      <Grid
                        key={key}
                        item
                        xs={valueObj.fullRow ? 12 : 6}
                        lg={valueObj.fullRow ? 12 : 6}
                      >
                        <Typography variant="subtitle2" paddingY={1}>
                          {key}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={valueObj.fullRow ? 12 : 6}
                        lg={valueObj.fullRow ? 12 : 6}
                      >
                        {valueObj.loading ? (
                          <Box
                            sx={{
                              width: "100%",
                              height: "100%",
                              py: 0.3,
                            }}
                          >
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height="100%"
                              sx={{ borderRadius: 2, mt: 1, mb: 1 }}
                            />
                          </Box>
                        ) : typeof valueObj.value === "string" ||
                          typeof valueObj.value === "number" ? (
                          <Typography
                            variant="body2"
                            paddingY={1}
                            sx={{ wordBreak: "break-word" }}
                          >
                            {valueObj.value}
                          </Typography>
                        ) : (
                          valueObj.value
                        )}
                      </Grid>
                    </>
                  )
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Stack direction="row" justifyContent="center" spacing={2}>
          <LoadingButton
            onClick={() => {
              handleUpdateTags();
            }}
            variant="contained"
            loading={isSubmitting}
            color="primary"
            sx={{ width: 120, color: "background.paper" }}
          >
            Update
          </LoadingButton>
          <Button
            type="submit"
            variant="outlined"
            color="inherit"
            sx={{ width: 120, color: "text.secondary" }}
          >
            Cancel
          </Button>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};

export default NotebookVolumeUpdateTagsDrawer;

import { RootState, dispatch, store } from "@/redux/store";
import { MenuItem, Stack, Typography, Box, Menu, Tooltip } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { tokenSwitch } from "@/redux/project/common/thunks";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router";
import { isAuthed } from "../../utils";
import { TOKEN_FLAG } from "@/config";
import { initToken } from "@/guards/project/AuthorityGuard";
import { PATH_DASHBOARD } from "@/routes/paths";
import { persistStore } from "redux-persist";
import { useAuth } from "react-oidc-context";

import { isEmpty } from "lodash";

const persistor = persistStore(store);

const TokenSwitchSelect = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const auth = useAuth();

  const navigate = useNavigate();

  const {
    userDetail: data,
    isAdmin,
    isNoneUser,
  } = useSelector((state: RootState) => state.common);

  const curKey = data[isAdmin ? "organization_id" : "project_id"];

  const { user_id } = data;

  // const listData = (!isNoneUser && (data[isAdmin ? 'organization_list' : 'project_list'] || {})) as Record<string, string | string[]>;

  const getListData = () => {
    if (isNoneUser) return {};

    if (isAdmin) return data?.organization_list || {};

    let tempObj: any = {};
    if (!isEmpty(data?.project_list)) {
      data?.project_list.forEach((item) => {
        tempObj[item.id] = [
          item.organization_id,
          item.organization_name,
          item.name,
        ];
      });
    }

    return tempObj;
  };

  // dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item) => {
    setAnchorEl(null);
  };

  const getCurValue = () => {
    const valueData = getListData()?.[curKey];

    return isAdmin ? valueData : valueData?.[2];
  };

  const handleLinkTo = () => {
    for (const section in PATH_DASHBOARD) {
      const parts = PATH_DASHBOARD[section];

      for (const p in parts) {
        if (pathname.startsWith(parts[p])) {
          return parts[p];
        }
      }
    }

    return "";
  };

  const handleTokenSwitch = async (id) => {
    const orgParams = {
      user_id,
      organization_id: id,
    };

    const prjParams = {
      user_id,
      project_id: id,
      refresh_token: auth.user?.refresh_token,
    };
    const res = await dispatch(
      tokenSwitch({
        params: isAdmin ? orgParams : prjParams,
        pathname,
        alertCallback: enqueueSnackbar,
      })
    ).unwrap();
    // update auth user access_token
    await auth.signinSilent();

    // set the new token
    localStorage.setItem(TOKEN_FLAG, res.access_token);

    // initialize token data
    // userinfo is constant
    // asynchoronus function to persist redux
    initToken(res.access_token);

    // wait redux data persisted finished
    await persistor.flush();

    // pathname existed in path.ts, link to the list page
    if (handleLinkTo()) {
      navigate(handleLinkTo());
    }

    window.location.reload(); // must reload crruent page;
  };

  return (
    <>
      {isAuthed(pathname, isNoneUser, isAdmin) && (
        <Stack direction="row" spacing={2} sx={{ mr: 2 }}>
          <Box>
            <Stack
              direction="row"
              onClick={handleClick}
              sx={{ cursor: "pointer" }}
            >
              <Tooltip title={getCurValue()}>
                <Typography
                  sx={{ maxWidth: "200px" }}
                  textOverflow="ellipsis"
                  noWrap
                >
                  {getCurValue()}
                </Typography>
              </Tooltip>
              <ExpandMoreOutlinedIcon
                sx={{
                  transform: open ? "rotate(0)" : "rotate(90deg)",
                  transition: "transform 0.5s",
                }}
              />
            </Stack>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  // width: isAdmin ? '250px' : '150px'
                  maxWidth: "200px",
                },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {Object.entries(getListData()).map(([key, value]) => {
                const fValue = value as string | [string, string, string];
                return (
                  <MenuItem
                    key={key}
                    selected={curKey === key}
                    onClick={() => {
                      setAnchorEl(null);

                      // swtich token
                      handleTokenSwitch(key);
                    }}
                    sx={{
                      "&.Mui-selected": {
                        bgcolor: (theme) =>
                          theme.palette.grey[200] + "!important",
                      },
                    }}
                    title={
                      (isAdmin ? fValue : `${fValue[1]}-${fValue[2]}`) as string
                    }
                  >
                    <Typography textOverflow="ellipsis" noWrap>
                      {isAdmin ? fValue : fValue[2]}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default TokenSwitchSelect;

import Page from "@/components/project/Page";
import PipelineTemplateList from "./components/PipelineTemplateList";

const PipelineTemplate = () => {
  return (
    <Page>
      <PipelineTemplateList />
    </Page>
  );
};

export default PipelineTemplate;

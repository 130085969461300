import { createSlice } from "@reduxjs/toolkit";
import { addThunkReducers } from "../utils";
import {
  getOrgTableData,
  getOrganizationDetailData,
  getProjectDetailData,
  getProjectRolesTableDataInProject,
  getProjectRolesUsersTableDataInProject,
  getProjectTableData,
  getProjectTags,
  getTags,
  getUsers,
} from "./thunks";
import { IAdministrationState } from "@/@types/project/model/administration";

const initialState: IAdministrationState = {
  permissDeniedObj: {},

  users: [],
  tags: [],
  organizationTableList: {
    loading: false,
    data: {},
    error: null,
  },
  organizationDetailData: {
    loading: false,
    data: {},
    error: null,
  },
  projectTableList: {
    loading: false,
    data: {},
    error: null,
  },
  projectDetailData: {
    loading: false,
    data: {},
    error: null,
  },
  projectTags: {
    loading: false,
    data: {},
    error: null,
  },
  projectDetailRolesTableData: {
    loading: false,
    data: {},
    error: null,
  },
  projectDetailRolesUsersTableData: {
    loading: false,
    data: {},
    error: null,
  },
};

const slice = createSlice({
  name: "administration",
  initialState,
  reducers: {
    setAdministrationData(state, action) {
      Object.assign(state, action.payload);
    },
    resetAdministrationData(state, action) {
      Object.assign(state, {
        [action.payload]: {
          loading: false,
          data: {},
          error: null,
        },
      });
    },

    setPermissDeniedObj(state, action) {
      state.permissDeniedObj[action.payload] = true;
    },
    clearPermissDeniedObj(state) {
      Object.assign(state, { permissDeniedObj: {} });
    },
  },
  extraReducers: (builder) => {
    addThunkReducers(builder, [
      { action: getUsers, name: "users", options: { updateDirectly: true } },
      { action: getTags, name: "tags", options: { updateDirectly: true } },

      { action: getOrgTableData, name: "organizationTableList" },
      { action: getOrganizationDetailData, name: "organizationDetailData" },
      { action: getProjectTableData, name: "projectTableList" },
      { action: getProjectDetailData, name: "projectDetailData" },
      { action: getProjectTags, name: "projectTags" },
      {
        action: getProjectRolesTableDataInProject,
        name: "projectDetailRolesTableData",
      },
      {
        action: getProjectRolesUsersTableDataInProject,
        name: "projectDetailRolesUsersTableData",
      },
    ]);
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setAdministrationData,
  resetAdministrationData,
  setPermissDeniedObj,
  clearPermissDeniedObj,
} = slice.actions;

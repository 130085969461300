/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Stack, Box } from "@mui/material";
import Page from "@/components/project/Page";
import { PATH_DASHBOARD } from "@/routes/paths";
import { useLocation, useParams } from "react-router";
import useCollapseDrawer from "@/hooks/project/useCollapseDrawer";
import AppGalleryCreateOrEditForm from "./components/appGalleryCreateOrEdit/AppGalleryCreateOrEditForm";
import DeployConfigVersionPanel from "./components/appGalleryDetail/DeployConfigVersionPanel";
import { dispatch, useSelector, RootState } from "@/redux/store";
import { useSnackbar } from "notistack";
import {
  getAppGalleryDetail,
  getAppVersionList,
} from "@/redux/project/mlService/appGallery/thunks";

const MlAppGalleryCreateOrEdit = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { isCollapse } = useCollapseDrawer();
  const { pathname } = useLocation();
  const { appId } = useParams();
  const [detailWidth, setDetailWidth] = useState<number>(0);
  const [appDetailInfo, setAppDetailInfo] = useState<Record<string, any>>({});
  const [selectedVersionInfo, setSelectedVersionInfo] = useState<
    Record<string, any>
  >({});
  const [openDeployConfigVersion, setOpenDeployConfigVersion] =
    useState<boolean>(false);
  const {
    mlAppDescribeData: { data: appDetail },
    mlAppVersionDatas: {
      data: { items: versionData = [] },
    },
  } = useSelector((state: RootState) => state.appGallery);

  const isEdit = pathname.includes("appGalleryEdit");

  const getLinks = () => {
    return [
      {
        name: "ML Service",
      },
      {
        name: "App Gallery",
        href: PATH_DASHBOARD.mlService.appGallery,
      },
      ...(isEdit
        ? [
            {
              name: appDetail?.name || "",
              href: `${PATH_DASHBOARD.mlService.appGallery}/${appId}`,
            },
          ]
        : []),
      {
        name: `${isEdit ? "Edit" : "Create"} App` || "",
      },
    ];
  };

  useEffect(() => {
    if (isEdit) {
      dispatch(
        getAppGalleryDetail({
          id: appId,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((resp) => {
          setAppDetailInfo(resp);
        });
      dispatch(
        getAppVersionList({
          params: {
            page_num: 1,
            page_size: 9999,
            is_active: 1,
            mlapp_id: appId,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
      // open version sidebar
      setOpenDeployConfigVersion(true);
      // toggleIsCollapseByBol(true);
    }
  }, []);

  useEffect(() => {
    setSelectedVersionInfo(versionData[0] || {});
  }, [versionData]);

  useEffect(() => {
    const width = window.innerWidth - 72 - 24;
    if (!openDeployConfigVersion) {
      setDetailWidth(0);
    } else if (openDeployConfigVersion && !isCollapse) {
      setDetailWidth(width - 240 - 300);
    } else if (openDeployConfigVersion && isCollapse) {
      setDetailWidth(width - 300);
    }
  }, [openDeployConfigVersion, isCollapse]);

  return (
    <Page
      title={`${`${isEdit ? "Edit" : "Create"} App`} | OmniML`}
      heading={`${isEdit ? "Edit" : "Create"} App`}
      links={getLinks()}
    >
      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Box sx={{ width: detailWidth || "100%" }}>
          <AppGalleryCreateOrEditForm
            setOpenDeployConfigVersion={setOpenDeployConfigVersion}
            selectedVersionInfo={selectedVersionInfo}
            appDetailInfo={appDetailInfo}
            setAppDetailInfo={setAppDetailInfo}
          />
        </Box>

        {openDeployConfigVersion && (
          <DeployConfigVersionPanel
            onClose={() => {
              setOpenDeployConfigVersion(false);
              setSelectedVersionInfo({});
            }}
            setSelectedVersionInfo={setSelectedVersionInfo}
            selectedVersionInfo={selectedVersionInfo || versionData[0]}
          />
        )}
      </Stack>
    </Page>
  );
};

export default MlAppGalleryCreateOrEdit;

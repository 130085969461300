import { getUserInfoService, tokenSwitchService } from "@/services/common";
import { checkCode, handleErrorMessage } from "@/utils/project/request";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { MlParams } from "@/@types/project/global";
import { getUsersDataService } from "@/services/administration";

/* export const getUserInfo = (userId: string, alertCallback)  => {
  return async (dispatch, getState) => {
    try {
      // const { data } = await getUserInfoByUserId(userId);
      // const res = checkCode(data);

      // handle the data after check
      dispatch(queryUserInfo(userId));
      console.log('rootState', getState());
      return ['12314'];
    } catch(e) {
      const error = e as Error;
      handleErrorMessage(error?.message || '', alertCallback);
      return [];
    }
  }
}; */
interface IGetUserDetail {
  id: string;
}

export const getUserDetailData = createAsyncThunk(
  "common/getUserDetailData",
  async ({ id }: IGetUserDetail, { rejectWithValue }) => {
    try {
      const { data } = await getUsersDataService({
        user_id: id,
        page_num: 1,
        page_size: 10,
      });
      const res = checkCode(data) as Object & {
        items: Array<{
          legal_surname: string;
          legal_given_name: string;
          privileged: number;
        }>;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      console.log(error);
      return rejectWithValue("Unable to fetch user info");
    }
  }
);

export const getUserInfo = createAsyncThunk(
  "common/getUserInfo",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getUserInfoService();
      const res = checkCode(data) as Object & {
        legal_surname: string;
        legal_given_name: string;
        privileged: number;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      console.log("error", error);
      return rejectWithValue("Unable to fetch user info");
    }
  }
);

interface ITokenSwitch extends MlParams {
  params: {
    user_id: string;
    project_id?: string;
    organization_id?: string;
    refresh_token?: string;
  };
}
export const tokenSwitch = createAsyncThunk(
  "common/tokenSwitch",
  async (
    { params, alertCallback, pathname}: ITokenSwitch,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await tokenSwitchService(params);
      const res = checkCode(data, pathname) as { access_token: string };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Unable to swtich");
    }
  }
);

/* export const simpleAction = createAsyncThunk('simple/action', async (_, { rejectWithValue }) => {
  try {
    return await performSimpleAction();
  } catch (error) {
    return rejectWithValue('Simple action failed');
  }
}); */

/* export const simpleAction = createAsyncThunk('simple/action', async (user_id: number, { rejectWithValue }) => {
  try {
    return await performSimpleAction(user_id);
  } catch (error) {
    return rejectWithValue('Simple action failed');
  }
}); */

import { StepInfo } from "@/@types/project/mlPipeline/SageMaker/pipeline";
import { generateUri } from "./helper-functions";
import {
  LambdaStep,
  LambdaInputStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-lambda";

// ----------------------------------------------------------------------

export default function generateLambdaStep(step: LambdaStep) {
  // console.log(step);
  return {
    Name: step.name,
    Type: step.type,
    Arguments: formArgs(step.lambdaInputs),
    FunctionArn: step.function.content,
    OutputParameters: step.lambdaOutputs.map((output) => ({
      OutputName: output.name,
      OutputType: output.type,
    })),
  } as StepInfo;
}

const formArgs = (args: LambdaInputStruct[] | undefined) => {
  if (args === undefined) return {};

  let argMap = new Map<string, any>();
  args.map((arg) =>
    argMap.set(arg.name, generateUri(arg.value, arg.valueUseParam))
  );

  return Object.fromEntries(argMap);
};

import { useLocation, useNavigate } from "react-router";
import { useFormik, FormikProvider, Form, Field } from "formik";
import {
  Dialog,
  Typography,
  Stack,
  Button,
  TextField,
  Box,
} from "@mui/material";
import * as Yup from "yup";
import { dispatch, useSelector, RootState } from "@/redux/store";
import { useSnackbar } from "notistack";
import {
  editAppVersion,
  getAppVersionList,
} from "@/redux/project/mlService/appGallery/thunks";

interface IAppDeployDialogProps {
  isOpen: boolean;
  onClose: VoidFunction;
  addInfo?: Record<string, any>;
}

const AppDeployDialog = ({
  isOpen,
  onClose,
  addInfo,
}: IAppDeployDialogProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const {
    mlAppDescribeData: { data: appDetail },
    mlAppVersionDatas: {
      data: { items: versionData },
    },
  } = useSelector((state: RootState) => state.appGallery);

  const isCreate = pathname.includes("appGalleryCreate");

  const formik = useFormik({
    initialValues: { remark: "" },
    validationSchema: Yup.object({}),
    onSubmit: async (values, actions) => {
      try {
        if (isCreate && addInfo) {
          await dispatch(
            editAppVersion({
              id: addInfo.versionId,
              params: { status: "wait_start", remark: values.remark },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap();
        } else {
          // draft -> wait_start
          // other started/starting -> wait_close
          // eslint-disable-next-line array-callback-return
          await Promise.all(
            versionData.map((version) => {
              if (version.status === "draft") {
                return dispatch(
                  editAppVersion({
                    id: version.id,
                    params: { status: "wait_start", remark: values.remark },
                    alertCallback: enqueueSnackbar,
                    pathname,
                  })
                ).unwrap();
              } else if (
                version.status === "starting" ||
                version.status === "started"
              ) {
                return dispatch(
                  editAppVersion({
                    id: version.id,
                    params: { status: "wait_close" },
                    alertCallback: enqueueSnackbar,
                    pathname,
                  })
                ).unwrap();
              }
            })
          );
          // get version
          await dispatch(
            getAppVersionList({
              params: {
                page_num: 1,
                page_size: 9999,
                is_active: 1,
                mlapp_id: appDetail.id,
              },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap();
        }

        actions.setSubmitting(false);
        onClose();
        navigate(
          `/ui/mlService/appGallery/${
            isCreate && addInfo ? addInfo.appId : appDetail?.id
          }`
        );
        enqueueSnackbar("Deploy Success", { variant: "success" });
      } catch (err) {
        actions.setSubmitting(false);
        throw err;
      }
    },
  });

  const { resetForm } = formik;

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown
      onClose={() => {
        onClose();
        resetForm();
        if (isCreate) {
          navigate(
            `/ui/mlService/appGallery/${
              isCreate && addInfo ? addInfo.appId : appDetail?.id
            }`
          );
        }
      }}
      PaperProps={{
        sx: {
          width: "500px",
          maxWidth: "none",
          padding: "20px 20px",
        },
      }}
    >
      <FormikProvider value={formik}>
        <Form>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Deploy ML App -{" "}
            {isCreate && addInfo ? addInfo.appName : appDetail?.name}
          </Typography>
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 2 }}>
              Are you sure you want to deploy this ML App version
            </Typography>
            <Field
              as={TextField}
              multiline
              rows={4}
              name="remark"
              label="Version Remark (Optional)"
              size="small"
              fullWidth
              margin="dense"
            />
          </Box>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            sx={{ my: 2 }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{ width: "100px", color: "background.paper" }}
            >
              Yes, Do it
            </Button>
            <Button
              type="button"
              color="inherit"
              variant="outlined"
              onClick={() => {
                onClose();
                resetForm();
                if (isCreate) {
                  navigate(
                    `/ui/mlService/appGallery/${
                      isCreate && addInfo ? addInfo.appId : appDetail?.id
                    }`
                  );
                }
              }}
              sx={{ width: "100px", color: "text.secondary" }}
            >
              Cancel
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default AppDeployDialog;

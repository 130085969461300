import { checkCode, handleErrorMessage } from "@/utils/project/request";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { MlParams } from "@/@types/project/global";
import {
  addNotebookTemplateService,
  addNotebookTemplateVersionService,
  addPipelineStorageService,
  addPipelineTemplateService,
  addPipelineTemplateVersionService,
  delNotebookTemplateService,
  delPipelineTemplateService,
  editNotebookTemplateService,
  editPipelineTemplateService,
  getNotebookTemplateDetailService,
  getNotebookTemplateTableList,
  getNotebookTemplateVersionDataService,
  getNotebookTemplateVersionDetailService,
  getPipelineStorageService,
  getPipelineTemplateDetailService,
  getPipelineTemplateTableList,
  getPipelineTemplateVersionDataService,
  getPipelineTemplateVersionDetailService,
  getProjectRoleDetailService,
  getProjectRoleTableList,
  uploadNotebookFile,
  deleteNotebookFile,
  getNotebookFile,
  uploadPipelineFile,
  deletePipelineFile,
  getPipelineFile,
} from "@/services/template";
import { dispatch } from "@/redux/store";
import { cloneDeep } from "lodash";

interface IGetNotebookTemplateParams extends MlParams {
  params: {
    name?: string;
    page_num: number;
    page_size: number;
  };
}

// 1. notebook template
export const getNotebookTemplateTableData = createAsyncThunk(
  "common/getNotebookTemplateTableData",
  async (
    { params, alertCallback, pathname }: IGetNotebookTemplateParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getNotebookTemplateTableList(params);
      const res = checkCode(data, pathname) as Object;

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Notebook Template failed");
    }
  }
);

interface IGetNotebookTemplateDetail extends MlParams {
  id: string;
}

export const getNotebookTemplateDetail = createAsyncThunk(
  "common/getNotebookTemplateDetail",
  async (
    { id, alertCallback, pathname }: IGetNotebookTemplateDetail,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getNotebookTemplateDetailService(id);
      const res = checkCode(data, pathname) as Object & { name: string };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Notebook Template Detail failed");
    }
  }
);

interface IAddNotebookTemplate extends MlParams {
  params: {
    name: string;
    git_repo_url: string;
    is_active: number;
    icon_url?: string;
    description?: string;
  };
}

export const addNotebookTemplate = createAsyncThunk(
  "common/addNotebookTemplate",
  async (
    { params, alertCallback, pathname }: IAddNotebookTemplate,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addNotebookTemplateService(params);
      const res = checkCode(data, pathname) as { id: string };
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add Notebook Template failed");
    }
  }
);

interface IEditNotebookTemplate extends MlParams {
  id: string;
  params: {
    description: string;
    icon_url?: string;
    is_active: number;
  };
}

export const editNotebookTemplate = createAsyncThunk(
  "common/editNotebookTemplate",
  async (
    { id, params, alertCallback, pathname }: IEditNotebookTemplate,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await editNotebookTemplateService(id, params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Edit Notebook Template failed");
    }
  }
);

interface IDelNotebookTemplate extends MlParams {
  id: string;
}

export const delNotebookTemplate = createAsyncThunk(
  "common/delNotebookTemplate",
  async (
    { id, alertCallback, pathname }: IDelNotebookTemplate,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delNotebookTemplateService(id);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Del Notebook Template failed");
    }
  }
);

interface IGetNotebookTemplateVersionParams extends MlParams {
  params: {
    notebook_template_id?: string;
    page_num: number;
    page_size: number;
  };
}

// template version
export const getNotebookTemplateVersionData = createAsyncThunk(
  "common/getNotebookTemplateVersionData",
  async (
    { params, alertCallback, pathname }: IGetNotebookTemplateVersionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getNotebookTemplateVersionDataService(params);
      const res = checkCode(data, pathname) as Object & {
        items: Array<Record<any, any>>;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Notebook Template Version failed");
    }
  }
);

interface IAddNotebookTemplateVersionParams extends MlParams {
  params: {
    name: string;
    git_commit_id: string;
    notebook_template_id: string;
    is_active: number;
    description?: string;
  };
}

export const addNotebookTemplateVersion = createAsyncThunk(
  "common/addNotebookTemplateVersion",
  async (
    { params, alertCallback, pathname }: IAddNotebookTemplateVersionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addNotebookTemplateVersionService(params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add Notebook Template Version failed");
    }
  }
);

interface IGetNotebookTemplateVersionDetailParams extends MlParams {
  id: string;
}

export const getNotebookTemplateVersionDetail = createAsyncThunk(
  "common/getNotebookTemplateVersionDetail",
  async (
    { id, alertCallback, pathname }: IGetNotebookTemplateVersionDetailParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getNotebookTemplateVersionDetailService(id);
      const res = checkCode(data, pathname) as Object & {
        notebook_template_id: string;
        description: string;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Notebook Template Version failed");
    }
  }
);

interface IGetPipelineTemplateParams extends MlParams {
  params: {
    name?: string;
    page_num: number;
    page_size: number;
  };
  hasDescription?: boolean;
}

// 2. pipeline template
export const getPipelineTemplateTableData = createAsyncThunk(
  "common/getPipelineTemplateTableData",
  async (
    {
      params,
      hasDescription,
      alertCallback,
      pathname,
    }: IGetPipelineTemplateParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getPipelineTemplateTableList(params);
      const res = checkCode(data, pathname) as Object;
      let lastRes: any = res;
      // sort the blank to first item
      const tItems = cloneDeep(lastRes.items);
      tItems.sort((a, b) => {
        if (a.name === "blank") return -1;
        if (b.name === "blank") return -1;
        return 0;
      });

      lastRes = {
        ...lastRes,
        items: tItems,
      };

      if (hasDescription) {
        // get template detail to join
        const lastItems = (await Promise.allSettled(
          lastRes.items?.map(async (item) => {
            const templateDetail = await dispatch(
              getPipelineTemplateDetail({
                id: item.id,
                alertCallback,
                pathname,
              })
            ).unwrap();

            return {
              ...item,
              name: templateDetail.name,
              description: templateDetail.description,
            };
          })
        )) as Array<{ value: Record<any, any> }>;

        lastRes = {
          ...res,
          items: lastItems.map((i) => i.value),
        };
      }

      return lastRes;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Pipeline Template failed");
    }
  }
);

interface IGetPipelineJsonParams extends MlParams {
  params: {
    provider?: string;
    location?: string;
    file_path?: string;
    id?: string;
  };
}

export const getPipelineStorageData = createAsyncThunk(
  "common/getPipelineStorageData",
  async (
    { params, alertCallback, pathname }: IGetPipelineJsonParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getPipelineStorageService(params);
      const res = checkCode(data, pathname) as Object & {
        content: Record<any, any>;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Pipeline Template failed");
    }
  }
);

interface IUploadPipelineJsonParams extends MlParams {
  params: {
    provider?: string;
    location?: string;
    file_path?: string;
    id?: string;
    content?: Record<any, any>;
  };
}

export const addPipelineStorageData = createAsyncThunk(
  "common/addPipelineStorageData",
  async (
    { params, alertCallback, pathname }: IUploadPipelineJsonParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addPipelineStorageService(params);
      const res = checkCode(data, pathname) as Object & {
        content: Record<any, any>;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Upload Pipeline failed");
    }
  }
);

interface IGetPipelineTemplateDetail extends MlParams {
  id: string;
}

export const getPipelineTemplateDetail = createAsyncThunk(
  "common/getPipelineTemplateDetail",
  async (
    { id, alertCallback, pathname }: IGetPipelineTemplateDetail,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getPipelineTemplateDetailService(id);
      const res = checkCode(data, pathname) as Object & {
        name: string;
        description: string;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Pipeline Template Detail failed");
    }
  }
);

interface IAddPipelineTemplate extends MlParams {
  params: {
    name: string;
    git_repo_url: string;
    mlpipeline_type?: string;
    scenario: string;
    icon_url: string;
    description?: string;
  };
}

export const addPipelineTemplate = createAsyncThunk(
  "common/addPipelineTemplate",
  async (
    { params, alertCallback, pathname }: IAddPipelineTemplate,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addPipelineTemplateService(params);
      const res = checkCode(data, pathname) as { id: string };
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add Pipeline Template failed");
    }
  }
);

interface IEditPipelineTemplate extends MlParams {
  id: string;
  params: {
    description: string;
    scenario: string;
    icon_url?: string;
  };
}

export const editPipelineTemplate = createAsyncThunk(
  "common/editPipelineTemplate",
  async (
    { id, params, alertCallback, pathname }: IEditPipelineTemplate,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await editPipelineTemplateService(id, params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Edit Pipeline Template failed");
    }
  }
);

interface IDelPipelineTemplate extends MlParams {
  id: string;
}

export const delPipelineTemplate = createAsyncThunk(
  "common/delPipelineTemplate",
  async (
    { id, alertCallback, pathname }: IDelPipelineTemplate,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await delPipelineTemplateService(id);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Del Pipeline Template failed");
    }
  }
);

interface IGetPipelineTemplateVersionParams extends MlParams {
  params: {
    id?: string;
    mlpipeline_template_id?: string;
    page_num: number;
    page_size: number;
  };
}

// template version
export const getPipelineTemplateVersionData = createAsyncThunk(
  "common/getPipelineTemplateVersionData",
  async (
    { params, alertCallback, pathname }: IGetPipelineTemplateVersionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getPipelineTemplateVersionDataService(params);
      const res = checkCode(data, pathname) as Object & {
        items: Array<Record<any, any>>;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Pipeline Template Version failed");
    }
  }
);

interface IAddPipelineTemplateVersionParams extends MlParams {
  params: {
    name: string;
    git_commit_id: string;
    mlpipeline_template_id: string;
    description?: string;
  };
}

export const addPipelineTemplateVersion = createAsyncThunk(
  "common/addPipelineTemplateVersion",
  async (
    { params, alertCallback, pathname }: IAddPipelineTemplateVersionParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addPipelineTemplateVersionService(params);
      checkCode(data, pathname);
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Add Pipeline Template Version failed");
    }
  }
);

interface IGetPipelineTemplateVersionDetailParams extends MlParams {
  id: string;
}

export const getPipelineTemplateVersionDetail = createAsyncThunk(
  "common/getPipelineTemplateVersionDetail",
  async (
    { id, alertCallback, pathname }: IGetPipelineTemplateVersionDetailParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getPipelineTemplateVersionDetailService(id);
      const res = checkCode(data, pathname) as Object & {
        mlpipeline_template_id: string;
        description: string;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching Pipeline Template Version failed");
    }
  }
);

interface IGetProjectRoleParams extends MlParams {
  params: {
    name?: string;
    page_num: number;
    page_size: number;
  };
}

// 3. project role
export const getProjectRoleTableData = createAsyncThunk(
  "common/getProjectRoleTableData",
  async (
    { params, alertCallback, pathname }: IGetProjectRoleParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getProjectRoleTableList(params);
      const res = checkCode(data, pathname) as Object & {
        items: Array<Record<any, any>>;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching project role failed");
    }
  }
);

interface IGetProjectRoleDetailParams extends MlParams {
  id: string;
}

export const getProjectRoleDetail = createAsyncThunk(
  "common/getProjectRoleDetail",
  async (
    { id, alertCallback, pathname }: IGetProjectRoleDetailParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getProjectRoleDetailService(id);
      const res = checkCode(data, pathname) as {
        name: string;
        description: string;
      };

      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching project role detail failed");
    }
  }
);

interface IUploadNotebookFileParams extends MlParams {
  formData: FormData;
}
export const uploadNotebookImage = createAsyncThunk(
  "common/uploadNotebookImage",
  async (
    { formData, alertCallback, pathname }: IUploadNotebookFileParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await uploadNotebookFile(formData);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Upload notebook image failed");
    }
  }
);
interface IGetNotebookFileParams extends MlParams {
  params: {
    s3_key: string;
  };
}
export const getNotebookImage = createAsyncThunk(
  "common/getNotebookImage",
  async (
    { params, alertCallback, pathname }: IGetNotebookFileParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getNotebookFile({ payload: params });
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching notebook image failed");
    }
  }
);

interface IDeleteNotebookFileParams extends MlParams {
  s3_key: string;
}
export const delNotebookImage = createAsyncThunk(
  "common/delNotebookImage",
  async (
    { s3_key, alertCallback, pathname }: IDeleteNotebookFileParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await deleteNotebookFile(s3_key);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete notebook image failed");
    }
  }
);

interface IUploadPipelineFileParams extends MlParams {
  formData: FormData;
}
export const uploadPipelineImage = createAsyncThunk(
  "common/uploadPipelineImage",
  async (
    { formData, alertCallback, pathname }: IUploadPipelineFileParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await uploadPipelineFile(formData);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Upload pipeline image failed");
    }
  }
);
interface IGetPipelineFileParams extends MlParams {
  params: {
    s3_key: string;
  };
}
export const getPipelineImage = createAsyncThunk(
  "common/getPipelineImage",
  async (
    { params, alertCallback, pathname }: IGetPipelineFileParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getPipelineFile({ payload: params });
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Fetching pipeline image failed");
    }
  }
);

interface IDeletePipelineFileParams extends MlParams {
  s3_key: string;
}
export const delPipelineImage = createAsyncThunk(
  "common/delPipelineImage",
  async (
    { s3_key, alertCallback, pathname }: IDeletePipelineFileParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await deletePipelineFile(s3_key);
      const res = checkCode(data, pathname);
      return res;
    } catch (e) {
      const error = e as Error;
      handleErrorMessage(error?.message || "", alertCallback);
      return rejectWithValue("Delete pipeline image failed");
    }
  }
);

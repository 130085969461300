import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import useLocales from "@/hooks/project/useLocales";
import { useState } from "react";
import TesterRequest from "./TesterRequest";
import TesterResponse from "./TesterResponse";

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1.5),
}));

type TesterResponseStruct = {
  prediction: {
    pred: string;
    time: string;
    size: string;
    statusCode: number;
  };
  body: string;
};

type Props = {
  curDeployment: Record<string, any>;
  setCurDeployment: React.Dispatch<React.SetStateAction<Record<string, any>>>;
};

export default function Tester({ curDeployment, setCurDeployment }: Props) {
  const { translate } = useLocales();
  const [testerResponses, setResponses] = useState(
    [] as TesterResponseStruct[]
  );
  const [loadingResponse, setLoadingResponse] = useState(false);

  const dataType = "json";
  const taskType = "";

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={6} sx={{ height: "64vh", mt: 2 }}>
        <LabelStyle>{translate("test_request")}</LabelStyle>
        <TesterRequest
          dataType={dataType}
          curDeployment={curDeployment}
          loadingResponse={loadingResponse}
          onSetResponses={setResponses}
          onSetLoadingResponse={setLoadingResponse}
          setCurDeployment={setCurDeployment}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={6} sx={{ height: "64vh", mt: 2 }}>
        <LabelStyle>{translate("test_response")}</LabelStyle>
        <TesterResponse
          loadingResponse={loadingResponse}
          responses={testerResponses}
          dataType={dataType}
          taskType={taskType}
        />
      </Grid>
    </Grid>
  );
}

export const createImageURL = (
  rawData: string,
  encodeType: string,
  dataType: string
) => {
  const content = Uint8Array.from(
    Buffer.from(rawData, encodeType as BufferEncoding)
  );
  return URL.createObjectURL(new Blob([content.buffer], { type: dataType }));
};

export const createVideoURL = (rawData: string, encodeType: string) => {
  const content = Uint8Array.from(
    Buffer.from(rawData, encodeType as BufferEncoding)
  );
  return URL.createObjectURL(new Blob([content.buffer]));
};

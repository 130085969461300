import request from "@/utils/project/request";

export const getFeatureSetDatas = (params) => {
  return request.get(`/featurestore/featureset`, params);
};

export const getFeatureSetVersionDatas = (params) => {
  return request.get(`/featurestore/featureset_version`, params);
};

export const getFeatureSetDescribeDatas = (id) => {
  return request.get(`/featurestore/featureset/${id}`);
};

export const getFeatureSetVersionDescribeDatas = (id) => {
  return request.get(`/featurestore/featureset_version/${id}`);
};

export const getConnectionDatasById = (id) => {
  return request.get(`/govern/connection/${id}`);
};

export const getFeatureSetTagDatas = (params) => {
  return request.get(`/featurestore/rel_tag_featureset`, params);
};

export const getTagDatas = (params) => {
  return request.get(`/govern/tag`, params);
};

export const getTagDataById = (params) => {
  return request.get(`/govern/tag/${params.id}`);
};

export const getConnectionDatas = (params) => {
  return request.get(`/govern/connection`, params);
};

export const createFeatureSet = (params) => {
  return request.post(`/featurestore/featureset`, params);
};

export const createTag = (params) => {
  return request.post(`/govern/tag`, params);
};

export const createRelTagFeatureSet = (params) => {
  return request.post(`/featurestore/rel_tag_featureset`, params);
};

export const createSynchronization = (params) => {
  return request.post(`/featurestore/synchronization`, params);
};

export const createSynchronizationJob = (params) => {
  return request.post(`/featurestore/synchronization_job`, params);
};

export const getProjectDatas = (params) => {
  return request.get(`/govern/project`, params);
};

export const createRelProjectFeatureSet = (params) => {
  return request.post(`/featurestore/rel_project_featureset`, params);
};

export const getSynchronizationDatas = (params) => {
  return request.get(`/featurestore/synchronization`, params);
};

export const getSynchronizationJobDatas = (params) => {
  return request.get(`/featurestore/synchronization_job`, params);
};

export const updateFeatureSet = (id, params) => {
  return request.put(`/featurestore/featureset/${id}`, params);
};

export const updateSynchronization = (id, params) => {
  return request.put(`/featurestore/synchronization/${id}`, params);
};

export const updateSynchronizationJob = (id, params) => {
  return request.put(`/featurestore/synchronization_job/${id}`, params);
};

export const deleteRelTagFeatureSet = (id, params) => {
  return request.put(`/featurestore/rel_tag_featureset/${id}`, params);
};

export const updateFeatureSetVersion = (id, params) => {
  return request.put(`/featurestore/featureset_version/${id}`, params);
};

export const getStatisticDatas = (params) => {
  return request.get(`/featurestore/featurestore_statistic`, params);
};

export const getCredentialDatas = (params) => {
  return request.get(`/govern/credential`, params);
};

export const getCredentialById = (id) => {
  return request.get(`/govern/credential/${id}`);
};

export const getRelUserFeatureset = (params) => {
  return request.get(`/featurestore/rel_user_featureset`, params);
};

export const getFeaturesetVersionAttribute = (params) => {
  return request.get(`/featurestore/featureset_version_attribute`, params);
};

export const uploadFile = (data) => {
  return request.post(`/featurestore/s3dataset`, data);
};

export const createFeaturesetVersion = (params) => {
  return request.post(`/featurestore/featureset_version`, params);
};

export const getHashToken = (id) => {
  return request.get(`/govern/hash_token/${id}`);
};

export const getSynchronizationJobExecutionVersion = (params) => {
  return request.get(
    `/featurestore/rel_synchronization_job_execution_featureset_version`,
    params
  );
};

export const getSynchronizationJobExecutionById = (id) => {
  return request.get(`/featurestore/synchronization_job_execution/${id}`);
};

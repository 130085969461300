import { Icon } from "@iconify/react";
import bxsTagAlt from "@iconify/icons-bx/bxs-tag-alt";
import { useDispatch } from "react-redux";
// material
import { styled } from "@mui/material/styles";
import {
  Paper,
  Stack,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
// @types
import { RootState, useSelector } from "@/redux/store";
//
import {
  stepTypeColorMapper,
  kubeflowStepTypeColorMapper,
} from "../StepPopover";
import {
  SchemaNodeEntity,
  SchemaEdgeEntity,
  updateConditionEdges,
} from "../../graph/slices/slice";

// ----------------------------------------------------------------------

export const Branches = ["true", "false"];

const RowStyle = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

const RowIconStyle = styled(Icon)(({ theme }) => ({
  width: 16,
  height: 16,
  marginRight: theme.spacing(1.2),
  color: theme.palette.text.secondary,
}));

const RowTextStyle = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  maxWidth: "calc(100% - 30px)",
  wordBreak: "break-all",
  color: theme.palette.text.primary,
  ...theme.typography.body2,
}));

export default function EdgeSrcNodeCardForCond({
  nodeId,
  property,
  edge,
}: {
  nodeId: string;
  property: string;
  edge: SchemaEdgeEntity;
}) {
  const dispatch = useDispatch();

  const entity = useSelector((state: RootState) => {
    let entity: SchemaNodeEntity | undefined =
      state.schema.schema.pipelineSteps.find((entity) => {
        return entity.id === nodeId;
      });
    return entity ? entity : null;
  });

  const { pipelineType } = useSelector((state: RootState) => state.schema);
  const commonStepColorMap =
    pipelineType === "Kubeflow"
      ? kubeflowStepTypeColorMapper
      : stepTypeColorMapper;

  return (
    <>
      {entity && (
        <Paper
          sx={{
            mx: 2,
            p: 1.5,
            borderRadius: 1.5,
            bgcolor: "background.neutral",
          }}
        >
          <Stack spacing={1.5}>
            <Typography
              variant="subtitle2"
              sx={{ fontSize: "13px", color: "text.secondary" }}
            >
              Step
            </Typography>
            <RowStyle>
              <RowIconStyle
                icon={bxsTagAlt}
                sx={{ color: commonStepColorMap[entity.stepType] }}
              />
              <RowTextStyle>{entity.name}</RowTextStyle>
            </RowStyle>
            <Typography
              variant="subtitle2"
              sx={{ fontSize: "13px", color: "text.secondary" }}
            >
              Branch
            </Typography>
            <RowStyle>
              {/* <StyledTextField
                select
                SelectProps={{ native: true }}
                value={property}
                onChange={(event: any) => {
                  // dispatch(
                  //   updateCondition({
                  //     id: id,
                  //     data: { ...data, conditionType: event.target.value },
                  //   })
                  // );
                }}
              >
                {Branches.map((branch) => (
                  <option key={branch} value={branch}>
                    {branch}
                  </option>
                ))}
              </StyledTextField> */}

              <RadioGroup value={property} row>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ ml: 0.5, mt: -1, mb: -0.5 }}
                >
                  {Branches.map((branch) => (
                    <FormControlLabel
                      key={branch}
                      value={branch}
                      control={<Radio size="small" />}
                      label={branch}
                      onChange={(event: any) => {
                        dispatch(
                          updateConditionEdges({
                            id: edge.id,
                            data: event.target.value + "&&",
                          })
                        );
                        // edge.property = event.target.value + "&&";
                      }}
                    />
                  ))}
                </Stack>
              </RadioGroup>
            </RowStyle>
          </Stack>
        </Paper>
      )}
    </>
  );
}

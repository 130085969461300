import { FormikProps } from "formik";
import { Box, Typography } from "@mui/material";
import { IKubeflowHpTunerFormValues } from "../..";
import HyperParamsTable from "./HyperParamsTable";

interface IProps {
  formik: FormikProps<IKubeflowHpTunerFormValues>;
}

const HyperParameters = ({ formik }: IProps) => {
  return (
    <>
      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        Hyper Parameters
      </Typography>
      <Box sx={{ pl: 2 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", pl: 1, py: 1 }}
        >
          The Hyper Parameters will be used to construct the optimization search
          space. Katib will be generating Trials to test different combinations
          of these parameters in order to find the optimal set
        </Typography>
      </Box>

      <HyperParamsTable formik={formik} />
    </>
  );
};

export default HyperParameters;

import { Field, FormikProps } from "formik";
import { IKubeflowHpTunerFormValues } from "..";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

interface IProps {
  formik: FormikProps<IKubeflowHpTunerFormValues>;
}

const MetricsCollector = ({ formik }: IProps) => {
  const { values, errors, touched } = formik;

  const getMetricsDescription = (kind) => {
    switch (kind) {
      case "Stdout":
        return `Katib collects the metrics from the operating system's default output location (standard output).`;
      case "File":
        return (
          <span>
            Katib collects the metrics from an arbitrary file, where the
            Training Container should log them. You can also check an{" "}
            <a
              href="https://github.com/kubeflow/katib/blob/ea46a7f2b73b2d316b6b7619f99eb440ede1909b/examples/v1beta1/metrics-collector/custom-metrics-collector.yaml#L14-L36"
              target="_blank"
              rel="noreferrer"
            >
              example YAML
            </a>
          </span>
        );
      default:
        return <></>;
    }
  };

  const getMetricsSettings = (kind) => {
    switch (kind) {
      case "File":
        return (
          <Field
            as={TextField}
            name="metricsCollector.settings.metricFile"
            label="Metric File"
            size="small"
            fullWidth
            margin="dense"
            helperText={
              touched.metricsCollector?.settings?.metricFile &&
              errors.metricsCollector?.settings?.metricFile
            }
            error={
              touched.metricsCollector?.settings?.metricFile &&
              Boolean(errors.metricsCollector?.settings?.metricFile)
            }
            FormHelperTextProps={{
              sx: { minHeight: "18px", marginTop: "2px" },
            }}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Typography variant="subtitle1">Metrics Collector</Typography>
      <Box sx={{ pl: 2 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", pl: 1, py: 1 }}
        >
          Define how katib should collect the metrics from each trial, such as
          the accuracy and loss metrics
        </Typography>

        <FormControl
          fullWidth
          margin="dense"
          size="small"
          error={
            touched.metricsCollector?.kind &&
            Boolean(errors.metricsCollector?.kind)
          }
        >
          <InputLabel>Kind</InputLabel>
          <Field
            as={Select}
            name="metricsCollector.kind"
            label="Kind"
            error={
              touched.metricsCollector?.kind &&
              Boolean(errors.metricsCollector?.kind)
            }
          >
            <MenuItem value="Stdout">Stdout</MenuItem>
            <MenuItem value="File">File</MenuItem>
            <MenuItem value="None">None</MenuItem>
          </Field>
          <FormHelperText
            margin="dense"
            sx={{ minHeight: "18px", marginTop: "2px" }}
          >
            {touched.metricsCollector?.kind && errors.metricsCollector?.kind
              ? errors.metricsCollector?.kind
              : getMetricsDescription(values.metricsCollector.kind)}
          </FormHelperText>
        </FormControl>

        {getMetricsSettings(values.metricsCollector.kind)}

        <Field
          as={TextField}
          name="metricsCollector.metricsFormat"
          label="Metrics Format"
          size="small"
          fullWidth
          margin="dense"
          helperText={
            touched.metricsCollector?.metricsFormat &&
            errors.metricsCollector?.metricsFormat
          }
          error={
            touched.metricsCollector?.metricsFormat &&
            Boolean(errors.metricsCollector?.metricsFormat)
          }
          FormHelperTextProps={{ sx: { minHeight: "18px", marginTop: "2px" } }}
        />
      </Box>
    </>
  );
};

export default MetricsCollector;

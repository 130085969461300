import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import SvgIconStyle from "../SvgIconStyle";
import { Icon } from "@iconify/react";
import checkmarkCircle2Fill from "@iconify/icons-eva/checkmark-circle-2-fill"; // radio checked icon （must）
import { MHidden } from "../@material-extend";

const OptionStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 2.5),
  justifyContent: "space-between",
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("all"),
  border: `solid 1px ${theme.palette.grey[500_32]}`,
}));

interface IMlRadioListItem {
  value: any;
  title: string;
  description?: string;
  icon?: string;
  disabled?: boolean;
}

interface IProps {
  curValue: string;
  setCurValue: any;
  list: IMlRadioListItem[];
}

const MlRadio = ({ curValue, setCurValue, list }: IProps) => {
  return (
    <RadioGroup
      // {...getFieldProps("type")} formik
      row
      value={curValue}
      onChange={(e) => setCurValue(e.target.value)}
    >
      <Grid container spacing={2}>
        {list.map((item) => {
          const {
            value,
            title,
            description,
            icon = "",
            disabled = false,
          } = item;

          return (
            <Grid item lg={6} xs={12} sx={{ pb: 1.5 }}>
              <OptionStyle
                key={value}
                sx={{
                  ...(curValue === value && {
                    boxShadow: (theme) => theme.customShadows.z8,
                  }),
                }}
              >
                <FormControlLabel
                  disabled={disabled}
                  value={value}
                  control={
                    <Radio checkedIcon={<Icon icon={checkmarkCircle2Fill} />} />
                  }
                  label={
                    <Box sx={{ ml: 1 }}>
                      <Typography variant="subtitle2">{title}</Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        {description}
                      </Typography>
                    </Box>
                  }
                  sx={{ flexGrow: 1, py: 1.5 }}
                />
                <MHidden width="smDown">
                  <SvgIconStyle
                    src={icon}
                    sx={{
                      ml: 0.5,
                      width: 34,
                      height: 34,
                      flexShrink: 0,
                      color: "text.secondary",
                    }}
                  />
                </MHidden>
              </OptionStyle>
            </Grid>
          );
        })}
      </Grid>
    </RadioGroup>
  );
};

export default MlRadio;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import { getStatisticList } from "@/redux/project/dataAsset/featureStore/thunks";
import StatisticPanel from "./StatisticPanel";
import SummaryPanel from "./SummaryPanel";

const DashbordStatistic = () => {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [featureset, setFeatureset] = useState<Record<string, any>>({
    title: "Feature Sets",
  });
  const [featuresetSummary, setFeaturesetSummary] = useState<
    Record<string, any>
  >({ title: "Feature Set Summary" });
  const [featuresetversion, setFeaturesetversion] = useState<
    Record<string, any>
  >({ title: "Feature Set Versions" });
  const [featuresetversionSummary, setFeaturesetversionSummary] = useState<
    Record<string, any>
  >({ title: "Feature Set Version Summary" });

  useEffect(() => {
    dispatch(
      getStatisticList({
        params: { page_num: 1, page_size: 9999 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    )
      .unwrap()
      .then((resp) => {
        const fs: Record<string, any> = { ...featureset };
        const fsSummary: Record<string, any> = { ...featuresetSummary };
        const fsv: Record<string, any> = { ...featuresetversion };
        const fsvSummary: Record<string, any> = { ...featuresetversionSummary };
        // eslint-disable-next-line array-callback-return
        resp?.items?.map((data) => {
          if (data.name === "FeatureSetTotal") {
            fs.total = data.statistic_value || 0;
          } else if (data.name === "FeatureSetMoM") {
            fs.percent = data.statistic_value
              ? parseFloat(data.statistic_value) * 100
              : null;
            fs.trend = parseFloat(data.statistic_value) < 0 ? "down" : "up";
          } else if (data.name === "FeatureSetDailyNew") {
            const chartData: number[] = [];
            const labels: number[] = [];
            for (let i = 0; i < 7; i += 1) {
              const chart = JSON.parse(data.statistic_value).find(
                (item) => item.K === i
              );
              if (chart) {
                chartData.push(chart.V);
                labels.push(chart.K);
              } else {
                chartData.push(0);
                labels.push(i);
              }
            }
            fs.chartData = chartData;
            fs.labels = labels;
          } else if (data.name === "FeatureSetProportionByState") {
            fsSummary.data = JSON.parse(data.statistic_value) || [];
          } else if (data.name === "FeatureSetVersionTotal") {
            fsv.total = data.statistic_value || 0;
          } else if (data.name === "FeatureSetVersionMoM") {
            fsv.percent = data.statistic_value
              ? parseFloat(data.statistic_value) * 100
              : null;
            fsv.trend = parseFloat(data.statistic_value) < 0 ? "down" : "up";
          } else if (data.name === "FeatureSetVersionDailyNew") {
            const chartData: Array<Record<string, number>> = [];
            const labels: number[] = [];
            for (let i = 0; i < 7; i += 1) {
              const chart = JSON.parse(data.statistic_value).find(
                (item) => item.K === i
              );
              if (chart) {
                chartData.push({ x: chart.K, y: chart.V });
                labels.push(chart.K);
              } else {
                chartData.push({ x: i, y: 0 });
                labels.push(i);
              }
            }
            fsv.chartData = chartData;
            fsv.labels = labels;
          } else if (data.name === "FeatureSetVersionProportionByState") {
            fsvSummary.data = JSON.parse(data.statistic_value) || [];
          }
        });
        setFeatureset(fs);
        setFeaturesetSummary(fsSummary);
        setFeaturesetversion(fsv);
        setFeaturesetversionSummary(fsvSummary);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  return (
    <>
      <StatisticPanel panelInfo={featureset} />
      <SummaryPanel panelInfo={featuresetSummary} />
      <StatisticPanel panelInfo={featuresetversion} />
      <SummaryPanel panelInfo={featuresetversionSummary} />
    </>
  );
};

export default DashbordStatistic;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import useCollapseDrawer from "@/hooks/project/useCollapseDrawer";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { DeleteIcon, EditIcon } from "@/assets/project/icons";
import {
  Box,
  Card,
  Typography,
  Stack,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Avatar,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import { styled, useTheme } from "@mui/material/styles";
import { isEmpty } from "lodash";
import { dispatch, useSelector, RootState } from "@/redux/store";
import { useSnackbar } from "notistack";
import {
  getAppVersionList,
  editAppVersion,
  editAppGallery,
  getAppGalleryList,
  getRelTagAppList,
  delRelTagApp,
  getImage,
  delImage,
} from "@/redux/project/mlService/appGallery/thunks";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import AppScenarioLabel from "../AppScenarioLabel";

const ProductImgStyle = styled("img")(() => ({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
}));

export default function MlAppCard({ mlApp }) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { toggleIsCollapseByBol } = useCollapseDrawer();
  const navigate = useNavigate();
  const [mouseEnter, setMouseEnter] = useState(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string>("");
  const { id, name, cover_url, scenario } = mlApp;
  const {
    userDetail: { permissions = [] },
  } = useSelector((state: RootState) => state.common);

  // delete dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // get imageurl
    if (cover_url) {
      dispatch(
        getImage({
          params: { s3_key: cover_url },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then((resp) => {
          setImageSrc(resp.url);
        });
    }
  }, []);

  const handleOpenDetails = () => {
    navigate(`${id}`);
  };

  const handleOpenApp = () => {
    toggleIsCollapseByBol(true);
    navigate(`${id}/appGalleryOpen`);
  };

  const handleDelete = async () => {
    // version -> is_active = 0, status => wait_close
    // app -> is_active = 0
    // rel_app_tag -> is_active = 0
    // delete image
    try {
      setDelLoading(true);
      const versionList = await dispatch(
        getAppVersionList({
          params: { page_num: 1, page_size: 9999, is_active: 1, mlapp_id: id },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      // eslint-disable-next-line array-callback-return
      await Promise.all(
        versionList?.items.map((version) =>
          dispatch(
            editAppVersion({
              id: version.id,
              params: { is_active: 0, status: "wait_close" },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap()
        )
      );

      const relAppTagResult = await dispatch(
        getRelTagAppList({
          params: { page_num: 1, page_size: 9999, is_active: 1, mlapp_id: id },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      // eslint-disable-next-line array-callback-return
      await Promise.all(
        relAppTagResult?.items?.map((rel) =>
          dispatch(
            delRelTagApp({
              id: rel.id,
              params: { ...rel, is_active: 0 },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          )
        )
      );

      // delete image
      if (cover_url) {
        const coverUrlTobase64 = encodeURIComponent(cover_url);
        await dispatch(
          delImage({
            s3_key: btoa(coverUrlTobase64),
            alertCallback: enqueueSnackbar,
            pathname,
          })
        );
      }

      // const appResult = await dispatch(getAppGalleryDetail({
      //   id,
      //   alertCallback: enqueueSnackbar,
      //   pathname,
      // })).unwrap();

      await dispatch(
        editAppGallery({
          id,
          params: { is_active: 0 },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      );

      await dispatch(
        getAppGalleryList({
          params: { page_num: 1, page_size: 9999, is_active: 1 },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      );
      setDelLoading(false);
      enqueueSnackbar("Delete Success", { variant: "success" });
    } catch (err) {
      setDelLoading(false);
      throw err;
    }
  };

  const handleMouseEnter = () => {
    setMouseEnter(true);
  };

  const handleMouseLeave = () => {
    setMouseEnter(false);
  };

  return (
    <Card
      key={id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        height: 320,
        width: 240,
        mb: 2,
        border: "1px solid rgba(145, 158, 171, 0.23)",
        boxShadow: theme.customShadows.z1,
        ...(mouseEnter && {
          boxShadow: (theme) => theme.customShadows.z24,
        }),
      }}
    >
      <Box onClick={handleOpenApp} sx={{ pt: "95%", position: "relative" }}>
        {isEmpty(imageSrc) ? (
          <Avatar
            variant="square"
            sx={{
              bgcolor: (theme) => theme.palette.grey[5008],
              top: 0,
              height: "100%",
              width: "100%",
              position: "absolute",
            }}
          >
            <Typography variant="body2" fontSize="100px">
              {name ? name[0] : ""}
            </Typography>
          </Avatar>
        ) : (
          <ProductImgStyle alt="" src={imageSrc} />
        )}
      </Box>

      <Stack
        spacing={1.5}
        direction="row"
        justifyContent="space-between"
        sx={{ pt: 1 }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{ pt: 0.5, pr: 1.5, pl: 1.5 }}
        >
          {name}
        </Typography>
        {permissions.includes("21100") && (
          <>
            <IconButton size="small" onClick={handleClick}>
              <MoreVertOutlinedIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  navigate(`${id}/appGalleryEdit`);
                  handleClose();
                }}
                sx={{ color: "text.secondary" }}
              >
                <ListItemIcon sx={{ mr: 1 }}>
                  <SvgIconStyle
                    src={EditIcon}
                    sx={{
                      width: 22,
                      height: 22,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Edit"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  setOpenConfirm(true);
                  handleClose();
                }}
                key="delete"
                sx={{ color: "error.main" }}
              >
                <ListItemIcon sx={{ mr: 1 }}>
                  <SvgIconStyle
                    src={DeleteIcon}
                    sx={{
                      width: 22,
                      height: 22,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Delete"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>
            </Menu>
          </>
        )}
      </Stack>

      <Stack
        spacing={1.5}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pt: 1, pr: 1.5, pb: 1.5, pl: 1.5 }}
      >
        <AppScenarioLabel>{scenario}</AppScenarioLabel>
        <Button
          size="small"
          color="inherit"
          sx={{ height: 24 }}
          endIcon={<KeyboardArrowRightIcon />}
          onClick={handleOpenDetails}
        >
          Detail
        </Button>
      </Stack>
      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete app <strong> {mlApp.name} </strong> item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelete();
              setOpenConfirm(false);
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </Card>
  );
}

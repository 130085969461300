import { Theme } from "@mui/material/styles";
// import { SvgIcon } from '@mui/material';
import { InputSelectIcon } from "./CustomIcons";

// ----------------------------------------------------------------------

// const ICON = (
//   <SvgIcon>
//     <Icon icon={arrowIosDownwardFill} />
//   </SvgIcon>
// );

export default function Select(theme: Theme) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: InputSelectIcon,
        // IconComponent: ICON
      },

      styleOverrides: {
        root: {},
      },
    },
  };
}

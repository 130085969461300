import {
  ProcessingStep,
  PropertyFileStruct,
  NetworkConfigStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-processing";
import { StepInfo } from "@/@types/project/mlPipeline/SageMaker/pipeline";
import { formEnvVars } from "./helper-functions";
import {
  formNumber,
  generateUri,
  generateImageUri,
  genArraysFromString,
} from "./helper-functions";

// ----------------------------------------------------------------------

export default function generateProcessingStep(
  step: ProcessingStep,
  roleArn: string
) {
  const envVars = formEnvVars(step.processor.environmentVars);
  const propFiles = formPropertyFiles(step);
  return {
    Name: step.name,
    Type: step.type,
    Arguments: {
      ProcessingResources: {
        ClusterConfig: {
          InstanceType: generateUri(
            step.processor.instanceType,
            step.processor.instanceTypeUseParam
          ),
          InstanceCount: formNumber(
            step.processor.instanceCount,
            step.processor.instanceCountUseParam
          ),
          VolumeSizeInGB: formNumber(
            step.processor.volumeSizeInGb,
            step.processor.volumeSizeInGbUseParam
          ),
        },
      },
      AppSpecification: formAppSpecification(step),
      RoleArn: roleArn,
      ProcessingInputs: formProcessingInput(step),
      ...(Object.keys(envVars).length > 0 && {
        Environment: envVars,
      }),
      ...formProcessingOutput(step),
      ...formNetworkConfigs(step.networkConfig),
    },
    ...(propFiles.length > 0 && {
      PropertyFiles: propFiles,
    }),
    // CacheConfig: { Enabled: true, ExpireAfter: "30d" },
  } as StepInfo;
}

const formProcessingInput = (step: ProcessingStep) => {
  const processingInputs = [] as any;
  // Add code
  processingInputs.push({
    InputName: "code",
    AppManaged: false,
    S3Input: {
      S3Uri: step.code.path,
      LocalPath: "/opt/ml/processing/input/code",
      S3DataType: "S3Prefix",
      S3InputMode: "File",
      S3DataDistributionType: "FullyReplicated",
      S3CompressionType: "None",
    },
  });
  // Add inputs
  step.processingInputs.map((input) =>
    processingInputs.push({
      InputName: input.inputName,
      AppManaged: false,
      S3Input: {
        S3Uri: generateUri(input.source, input.sourceUseParam),
        LocalPath: generateUri(input.destination, input.destinationUseParam),
        S3DataType: "S3Prefix",
        S3InputMode: "File",
        S3DataDistributionType: input.s3DataDistributionType,
        S3CompressionType: "None",
      },
    })
  );
  return processingInputs;
};

const formAppSpecification = (step: ProcessingStep) => {
  if (step.code.jobArgs.length === 0) {
    const items = step.code.path.split("/");
    return {
      ImageUri: generateImageUri(
        step.processor.processorType,
        step.processor.processorTypeUseParam
      ),
      ContainerEntrypoint: [
        "python3",
        `/opt/ml/processing/input/code/${items[items.length - 1]}`,
      ],
    };
  } else {
    const items = step.code.path.split("/");
    return {
      ImageUri: generateImageUri(
        step.processor.processorType,
        step.processor.processorTypeUseParam
      ),
      ContainerArguments: step.code.jobArgs
        .map((arg: any) => [arg.name, generateUri(arg.value, arg.useParam)])
        .flat()
        .filter((ele) => ele !== ""),
      ContainerEntrypoint: [
        "python3",
        `/opt/ml/processing/input/code/${items[items.length - 1]}`,
      ],
    };
  }
};

const formProcessingOutput = (step: ProcessingStep) => {
  const processingOutputs: any[] = [];
  step.processingOutputs.map((output) =>
    processingOutputs.push({
      OutputName: output.outputName,
      AppManaged: false,
      S3Output: {
        S3Uri: generateUri(output.destination, output.destinationUseParam),
        LocalPath: generateUri(output.source, output.sourceUseParam),
        S3UploadMode: output.s3UploadMode,
      },
    })
  );
  return processingOutputs.length > 0
    ? { ProcessingOutputConfig: { Outputs: processingOutputs } }
    : {};
};

const formPropertyFiles = (step: ProcessingStep) => {
  const propertyFiles = [] as any;
  if (step.propertyFiles) {
    step.propertyFiles.map((output: PropertyFileStruct) =>
      propertyFiles.push({
        PropertyFileName: output.name,
        OutputName: output.outputName,
        FilePath: output.path,
      })
    );
  }
  return propertyFiles;
};

const formNetworkConfigs = (networkConfig: NetworkConfigStruct) => {
  let config = {};
  if (networkConfig) {
    // EnableNetworkIsolation
    if (["True", "False"].includes(networkConfig.enableNetworkIsolation)) {
      config = {
        ...config,
        EnableNetworkIsolation: networkConfig.enableNetworkIsolation === "True",
      };
    } else {
      if (
        networkConfig.enableNetworkIsolation !== "" &&
        networkConfig.enableNetworkIsolationUseParam
      ) {
        config = {
          ...config,
          EnableNetworkIsolation: generateUri(
            networkConfig.enableNetworkIsolation,
            true
          ),
        };
      }
    }
    // Encrypt inter container traffic
    if (
      ["True", "False"].includes(networkConfig.encryptInterContainerTraffic)
    ) {
      config = {
        ...config,
        EncryptInterContainerTraffic:
          networkConfig.encryptInterContainerTraffic === "True",
      };
    } else {
      if (
        networkConfig.encryptInterContainerTraffic !== "" &&
        networkConfig.encryptInterContainerTrafficUseParam
      ) {
        config = {
          ...config,
          EnableNetworkIsolation: generateUri(
            networkConfig.encryptInterContainerTraffic,
            true
          ),
        };
      }
    }
    // VpcConfig
    let vpcConfig = {};
    if (networkConfig.securityGroupIds !== "") {
      vpcConfig = {
        ...vpcConfig,
        SecurityGroupIds: genArraysFromString(
          networkConfig.securityGroupIds,
          networkConfig.securityGroupIdsUseParam
        ),
      };
    }

    if (networkConfig.subnets !== "") {
      vpcConfig = {
        ...vpcConfig,
        Subnets: genArraysFromString(
          networkConfig.subnets,
          networkConfig.subnetsUseParam
        ),
      };
    }

    config = {
      ...config,
      ...(Object.keys(vpcConfig).length > 0 && { VpcConfig: vpcConfig }),
    };
  }
  return Object.keys(config).length > 0 ? { NetworkConfig: config } : {};
};

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MlCard from "@/components/project/mlComponents/MlCard";
import { extractKeys } from "@/utils/project/utils";
import Scrollbar from "@/components/project/Scrollbar";
import MlTableBodyContainer from "@/components/project/mlComponents/MlTableBodyContainer";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import Label from "@/components/project/Label";
import { isEmpty } from "lodash";

enum StatusEnum {
  Succeeded = "success",
  Failed = "error",
}

const TrailTable = () => {
  const {
    hptunerResult: { data, loading },
  } = useSelector((state: RootState) => state.experiment);

  const getColumns = () => {
    const { trials = [] } = data || {};
    const suceededTrials = trials.filter((item) => item.status === "Succeeded");

    const sample = suceededTrials[0] || {};
    const tempData: any = [];
    tempData.push(
      ...(sample?.objective?.map((item) => ({
        id: item.name || undefined,
        label: item.name || "-",
      })) || [])
    );
    tempData.push(
      ...(sample?.parameters?.map((item) => ({
        id: item.name || undefined,
        label: item.name || "-",
      })) || [])
    );

    return [
      { id: "name", label: "Trail Name", minWidth: 150 },
      { id: "status", label: "Status", minWidth: 200 },
      ...tempData,
    ];
  };

  const getRow = () => {
    const { trials = [] } = data || {};
    return trials.map((item) => {
      const { name, status, objective = [], parameters = [] } = item;
      const tempTrial: any = {
        name: name || "-",
        status: status || "-",
      };
      if (!isEmpty(objective)) {
        objective.forEach((item) => {
          tempTrial[item.name] = item?.latest || "-";
        });
      }
      if (!isEmpty(parameters)) {
        parameters.forEach((item) => {
          tempTrial[item.name] = item?.value || "-";
        });
      }

      return tempTrial;
    });
  };

  const renderCell = (row, column) => {
    switch (column.id) {
      case "name":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="subtitle2">{row[column.id]}</Typography>
          </TableCell>
        );
      case "status":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Label color={StatusEnum[row[column.id]] || "default"}>
              {row[column.id]}
            </Label>
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {row[column.id] || "-"}
            </Typography>
          </TableCell>
        );
    }
  };

  return (
    <MlCard title="Experiment Detail" hasPaddingX={false}>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                {getColumns().map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <MlTableBodyContainer loading={loading} data={getRow()}>
                {getRow()?.map((row, index) => (
                  <TableRow key={index}>
                    {getColumns().map((column) => renderCell(row, column))}
                  </TableRow>
                ))}
              </MlTableBodyContainer>
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </MlCard>
  );
};

export default TrailTable;

import { Paper, PaperProps, Typography } from "@mui/material";
import noDataSvg from "@/assets/project/pictures/no-data-robot.svg";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

interface EmptyTableProps extends PaperProps {
  mainTitle: string;
  content?: string;
  emptyImage?: boolean;
}

export default function EmptyTable({
  mainTitle = "",
  content = "",
  color = "text.secondary",
  emptyImage = true,
  ...other
}: EmptyTableProps) {
  return (
    <Paper sx={{ my: 3 }} {...other}>
      {emptyImage && (
        <Box
          component="img"
          src={noDataSvg}
          alt="no data"
          sx={{ margin: "0 auto" }}
        />
      )}
      <Typography
        gutterBottom
        align="center"
        variant="h5"
        sx={{ color: color }}
      >
        {mainTitle}
      </Typography>
      {content && (
        <Typography variant="body1" align="center" sx={{ color: color, mt: 2 }}>
          {content}
        </Typography>
      )}
    </Paper>
  );
}

import * as Yup from "yup";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Stack, Collapse, Typography, IconButton } from "@mui/material";
// types
import {
  TrainingStep,
  TrainingEstimatorStruct,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-training";
//
import {
  updateTrainingEnvVars,
  updateTrainingEstimator,
  updateTrainingHyperparameters,
} from "../../../graph/slices/slice";
import TrainingEstimatorEnvVar from "./TrainingEstimatorEnvVar";
import TrainingHyperparameter from "./TrainingHyperparameter";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";
// import { PipelineSelect } from "../../components/PipelineComponents";
import { CollapseButtonStyle } from "../../SettingSidebar";
// import { estimatorTypes, estimatorVerions } from "../helper-functions";

// ----------------------------------------------------------------------

type Props = {
  currentStep: TrainingStep | null;
};

export default function Estimator({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [isCollapse, setCollapse] = useState(true);

  const ParamSchema = Yup.object().shape({
    instanceType: Yup.string().required("Instance type is required"),
    instanceCount: Yup.string().required("Instance count is required"),
    volumeSizeInGb: Yup.string().required("Volume size is required"),
  });

  const formik = useFormik<TrainingEstimatorStruct>({
    initialValues: {
      estimatorType: currentStep?.estimator.estimatorType || "PyTorch",
      estimatorTypeUseParam:
        currentStep?.estimator.estimatorTypeUseParam || false,
      frameworkVersion: currentStep?.estimator.frameworkVersion || "1.8.1",
      instanceType: currentStep?.estimator.instanceType || "ml.c4.xlarge",
      instanceTypeUseParam:
        currentStep?.estimator.instanceTypeUseParam || false,
      instanceCount: currentStep?.estimator.instanceCount || "1",
      instanceCountUseParam:
        currentStep?.estimator.instanceCountUseParam || false,
      volumeSizeInGb: currentStep?.estimator.volumeSizeInGb || "30",
      volumeSizeInGbUseParam:
        currentStep?.estimator.volumeSizeInGbUseParam || false,
    },
    enableReinitialize: true,
    validationSchema: ParamSchema,
    onSubmit: (values, { setErrors }) => {
      try {
        dispatch(updateTrainingEstimator(values));
      } catch (error) {
        console.error(error);
      }
    },
  });

  const hyperparameters = currentStep?.estimator.hyperparameters;
  const hpNum = hyperparameters !== undefined ? hyperparameters.length : 0;

  const environmentVars = currentStep?.estimator.environmentVars;
  const envVarNum = environmentVars !== undefined ? environmentVars.length : 0;

  const { handleSubmit } = formik;

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setCollapse((prev) => !prev)}
        endIcon={
          <Icon
            icon={isCollapse ? arrowIosForwardFill : arrowIosDownwardFill}
            width={16}
            height={16}
          />
        }
      >
        Estimator
      </CollapseButtonStyle>

      <Collapse in={!isCollapse} sx={{ mx: 2, mb: !isCollapse ? 0.5 : 0 }}>
        <Stack spacing={1} sx={{ my: 1 }}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onChange={handleSubmit}>
              <Stack spacing={2}>
                {/* <PipelineSelect
                  label={"Framework"}
                  field={"estimatorType"}
                  placeholder={"Select a framework"}
                  options={estimatorTypes}
                  getFieldProps={getFieldProps}
                />

                <PipelineSelect
                  label={"Framework version"}
                  field={"frameworkVersion"}
                  placeholder={"Select a framework version"}
                  options={estimatorVerions}
                  getFieldProps={getFieldProps}
                /> */}

                <PipelineParameterEntry
                  label={"Framework"}
                  field={"estimatorType"}
                  fieldUseParam={"estimatorTypeUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Instance type"}
                  field={"instanceType"}
                  fieldUseParam={"instanceTypeUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Instance count"}
                  field={"instanceCount"}
                  fieldUseParam={"instanceCountUseParam"}
                  formik={formik}
                />

                <PipelineParameterEntry
                  label={"Volume size (GB)"}
                  field={"volumeSizeInGb"}
                  fieldUseParam={"volumeSizeInGbUseParam"}
                  formik={formik}
                />
              </Stack>
            </Form>
          </FormikProvider>

          <Stack spacing={1.5} sx={{ mx: 2, my: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", fontWeight: 600, mt: 0.5 }}
              >
                Hyperparameters {hpNum > 0 && `(${hpNum})`}
              </Typography>
              <IconButton
                size="small"
                onClick={() => {
                  dispatch(
                    updateTrainingHyperparameters({
                      data: {
                        name: "",
                        value: "",
                        useParam: false,
                      },
                    })
                  );
                }}
              >
                <Icon icon={plusFill} width={16} height={16} />
              </IconButton>
            </Stack>

            {hyperparameters !== undefined &&
              hyperparameters.map((hp, index) => (
                <TrainingHyperparameter key={index} id={index} data={hp} />
              ))}
          </Stack>

          <Stack spacing={1.5} sx={{ mx: 2, my: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", fontWeight: 600, mt: 0.5 }}
              >
                Environment variables {envVarNum > 0 && `(${envVarNum})`}
              </Typography>
              <IconButton
                size="small"
                onClick={() => {
                  dispatch(
                    updateTrainingEnvVars({
                      name: "",
                      value: "",
                    })
                  );
                }}
              >
                <Icon icon={plusFill} width={16} height={16} />
              </IconButton>
            </Stack>

            {environmentVars !== undefined &&
              environmentVars.map((envVar, index) => (
                <TrainingEstimatorEnvVar
                  key={index}
                  id={index}
                  name={envVar.name}
                  value={envVar.value}
                />
              ))}
          </Stack>
        </Stack>
      </Collapse>
    </>
  );
}

// @mui
import { enUS, zhCN } from "@mui/material/locale";

// API
export const HOST_USER_GUIDE = "https://d2mbrpds81qlae.cloudfront.net";

export const POWER_BI_URL = "https://powerbi-placeholder";

export const AWS_REGION = "us-east-1";

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: "English",
    value: "en",
    systemValue: enUS,
    icon: "/static/icons/flags/ic_flag_en.svg",
  },
  {
    label: "中文",
    value: "cn",
    systemValue: zhCN,
    icon: "/static/icons/flags/ic_flag_cn.svg",
  },
];

export const defaultLang = allLangs[0]; // English

export const OMNIML_URL_PREFIX = "/ui/";

export const TOKEN_FLAG = "access_token";

export const IFRAME_URL = {
  swagger: {
    govern: "/swagger/govern/?layout=BaseLayout",
    experiment: "/swagger/experiment/?layout=BaseLayout",
    featurestore: "/swagger/featurestore/?layout=BaseLayout",
    modelregistry: "/swagger/modelregistry/?layout=BaseLayout",
    pipeline: "/swagger/pipeline/?layout=BaseLayout",
    modelruntime: "/swagger/modelruntime/?layout=BaseLayout",
    appgallery: "/swagger/appgallery/?layout=BaseLayout",
  },
  observability: {
    grafana:
      "/grafana/d/85a562078cdf77779eaa1add43ccec1e/kubernetes-compute-resources-namespace-pods?refresh=10s&kiosk",
    kibana: "/kibana/goto/4f0735cf44bcf58097aae4ec29404b16",
    prometheus: "/prometheus/",
    alertmanager: "/alertmanager/",
  },
};

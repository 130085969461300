import { useState } from "react";
// material
import { Box, Card, Stack } from "@mui/material";
// hooks
import useLocales from "@/hooks/project/useLocales";
// components
import TesterResponseBody from "./TesterResponseBody";
// import TesterResponseTable from "./TesterResponseTable";
import EmptyData from "@/components/project/EmptyData";

// ----------------------------------------------------------------------

type TesterResponseStruct = {
  prediction: {
    pred: string;
    time: string;
    size: string;
    statusCode: number;
  };
  body: string;
};

type Props = {
  loadingResponse: boolean;
  responses: TesterResponseStruct[];
  dataType?: string;
  taskType?: string;
};

export default function TesterResponse({
  loadingResponse,
  responses,
  dataType,
  taskType,
}: Props) {
  const { translate } = useLocales();
  const [selectedResponse, setSelectedResponse] = useState<number>(0);

  return (
    <Card
      sx={{
        alignItems: "top",
        p: 1,
        pt: 2,
        height: "calc(100% - 40px)",
      }}
    >
      {/* {loadingResponse && (
        <Box>
          <UploadingScreen
            sx={{
              zIndex: 9999,
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />
        </Box>
      )} */}
      {!loadingResponse && responses.length === 0 && (
        <Box sx={{ height: "100%" }}>
          <EmptyData title={translate("no_data")} width={"100%"} />
        </Box>
      )}
      {!loadingResponse && responses.length > 0 && (
        <Stack spacing={1}>
          {/* <TesterResponseTable
            prediction={responses[selectedResponse].prediction}
          /> */}
          <TesterResponseBody
            responses={responses}
            dataType={dataType}
            taskType={taskType}
            selectedImage={selectedResponse}
            setSelectedImage={setSelectedResponse}
          />
        </Stack>
      )}
    </Card>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TablePagination,
  Typography,
} from "@mui/material";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { useLocation, useNavigate } from "react-router";
import MlCard from "@/components/project/mlComponents/MlCard";
import Page from "@/components/project/Page";
import { extractKeys } from "@/utils/project/utils";
import Scrollbar from "@/components/project/Scrollbar";
import { RootState, dispatch } from "@/redux/store";
import { getProjectRoleTableData } from "@/redux/project/template/thunks";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import MlTableBodyContainer from "@/components/project/mlComponents/MlTableBodyContainer";

const ProjectRole: React.FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const [page_num, setPageNum] = useState<number>(1);
  const [page_size, setPageSize] = useState<number>(10);

  const {
    projectRoleTableList: { data, loading },
  } = useSelector((state: RootState) => state.template);

  const getColumns = () => {
    return [
      { id: "name", label: "Name", minWidth: 150 },
      { id: "shortName", label: "Short Name", minWidth: 200 },
      { id: "", width: 150 },
    ];
  };

  const fetchData = (params: { page_num: number; page_size: number }) => {
    dispatch(
      getProjectRoleTableData({
        params,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNum(newPage + 1);
    fetchData({ page_num: newPage + 1, page_size });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNum(0);
    fetchData({ page_num: 1, page_size: parseInt(event.target.value, 10) });
  };

  const renderCell = (row, column) => {
    switch (column.id) {
      case "name":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="subtitle2">{row[column.id]}</Typography>
          </TableCell>
        );
      case "shortName":
        const nameArr = row.name.split(" ");
        const shortName = nameArr.map((i) => i.charAt(0)).join("");
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2">{shortName}</Typography>
          </TableCell>
        );
      case "":
        return (
          <TableCell
            align="right"
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <IconButton onClick={() => navigate(`${row.id}`, { state: row })}>
              <KeyboardArrowRightOutlinedIcon />
            </IconButton>
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {row[column.id] || "-"}
            </Typography>
          </TableCell>
        );
    }
  };

  useEffect(() => {
    dispatch(
      getProjectRoleTableData({
        params: {
          page_num,
          page_size,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  }, []);

  return (
    <Page>
      <MlCard title="Roles" hasPaddingX={false}>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {getColumns().map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <MlTableBodyContainer loading={loading} data={data?.items}>
                  {data?.items?.map((row, index) => (
                    <TableRow key={index}>
                      {getColumns().map((column) => renderCell(row, column))}
                    </TableRow>
                  ))}
                </MlTableBodyContainer>
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          component="div"
          count={data?.total || 0}
          rowsPerPage={page_size}
          page={page_num - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </MlCard>
    </Page>
  );
};

export default ProjectRole;

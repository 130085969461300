/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useCallback, useState } from "react";
import {
  Drawer,
  Stack,
  Typography,
  Divider,
  Grid,
  Chip,
  Box,
  Popover,
  Button,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Scrollbar from "@/components/project/Scrollbar";
import { useLocation, useNavigate } from "react-router";
import { dispatch, useSelector, RootState } from "@/redux/store";
import { useSnackbar } from "notistack";
import {
  getModelPackageVersionList,
  getModelPackageTagList,
  getTagList,
  getConnectionById,
  getModelPackageDescribe,
} from "@/redux/project/dataAsset/modelRegistry/thunks";

interface IDetailDrawerProps {
  isOpen: boolean;
  onClose: VoidFunction;
  detailData: Record<string, any>;
  clearDetailData: VoidFunction;
}

const ModelPackageDetailDrawer = ({
  isOpen,
  onClose,
  detailData,
  clearDetailData,
}: IDetailDrawerProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const {
    modelPackageDescribeDatas: { data: modelDescrible },
    connectionDatas: { data: connectionData },
    modelPackageVersionDatas: {
      data: { items: versionList = [] },
    },
    tagDatas: {
      data: { items: tagList = [] },
    },
    modelPackageTagDatas: {
      data: { items: modelPackageTagList = [] },
    },
  } = useSelector((state: RootState) => state.modelRegistry);
  const { userDetail } = useSelector((state: RootState) => state.common);

  const [anchorElPop, setAnchorElPop] = useState<null | HTMLElement>(null);
  const openPopover = Boolean(anchorElPop);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPop(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorElPop(null);
  };

  useEffect(() => {
    // get modelpackage detail
    dispatch(
      getModelPackageDescribe({
        id: detailData.id,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
    // get mp_version
    dispatch(
      getModelPackageVersionList({
        params: {
          page_num: 1,
          page_size: 9999,
          modelpackage_id: detailData.id,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
    // get tag and mp_tag
    dispatch(
      getModelPackageTagList({
        params: {
          page_num: 1,
          page_size: 9999,
          is_active: 1,
          modelpackage_id: detailData.id,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    dispatch(
      getTagList({
        params: { page_num: 1, page_size: 9999, is_active: 1 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    // get connection
    if (detailData?.resource_connection_id) {
      dispatch(
        getConnectionById({
          id: detailData?.resource_connection_id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
    }
  }, [detailData]);

  const getTagsContent = useCallback(() => {
    const tags: string[] = [];
    // eslint-disable-next-line array-callback-return
    modelPackageTagList.map((rel) => {
      const tagName = tagList.find((tag) => tag.id === rel.tag_id)?.name;
      if (tagName) tags.push(tagName);
    });
    return tags.length > 0 ? (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {tags.map((tag) => {
          return <Chip label={tag} size="small" key={tag} />;
        })}
      </Box>
    ) : (
      "-"
    );
  }, [modelPackageTagList]);

  const getConnectionContent = () => {
    const connectionDetails = [
      { key: "Type", value: connectionData?.connection_type },
      { key: "Scheme", value: connectionData?.connection_scheme },
      { key: "Endpoint", value: connectionData?.connection_endpoint },
      { key: "Port", value: connectionData?.connection_port },
    ];
    return (
      <>
        <Typography
          variant="body2"
          aria-owns={openPopover ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          sx={{
            color: "text.secondary",
            wordBreak: "break-word",
            cursor: "pointer",
          }}
        >
          {connectionData?.name || "-"}
        </Typography>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={openPopover}
          anchorEl={anchorElPop}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Grid
            container
            columnSpacing={1}
            sx={{ width: 200, mr: 0.5, ml: 0.5 }}
            columns={6}
          >
            {connectionDetails.map(({ key, value }) => (
              <>
                <Grid item xs={3} lg={3} key={key}>
                  <Typography variant="subtitle2" paddingY={1}>
                    {`${key}:`}
                  </Typography>
                </Grid>
                <Grid item xs={3} lg={3} key={value}>
                  <Typography
                    variant="body2"
                    paddingY={1}
                    sx={{ wordBreak: "break-word" }}
                  >
                    {value}
                  </Typography>
                </Grid>
              </>
            ))}
          </Grid>
        </Popover>
      </>
    );
  };

  const getOrderedDetail = useCallback(() => {
    return {
      "Head Version Number": versionList[0]?.modelpackage_version_number || "-",
      State: modelDescrible?.modelpackage_state || "-",
      Type: modelDescrible?.modelpackage_type || "-",
      Group: modelDescrible?.delivered_to || "-",
      Description: modelDescrible?.description || "-",
      "Created By": modelDescrible?.created_by || "-",
      "Created At": modelDescrible?.created_at || "-",
      "Last Modified": modelDescrible?.updated_at || "-",
      "Resource Path": modelDescrible?.resource_path || "-",
      "Resource Connection": getConnectionContent(),
      "Resource Type": modelDescrible?.resource_type || "-",
      "Released At": versionList[0]?.ReleasedAt || "-",
      "Released By": versionList[0]?.ReleasedBy || "-",
      Tags: getTagsContent(),
    };
  }, [
    modelPackageTagList,
    connectionData,
    versionList,
    openPopover,
    modelDescrible,
  ]);

  useEffect(() => {
    getOrderedDetail();
  }, [getOrderedDetail]);

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        onClose();
        clearDetailData();
      }}
      anchor="right"
      sx={{
        "& .MuiPaper-root": {
          width: "320px",
          bgcolor: "background.default",
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          py: 2.5,
          px: 4,
          bgcolor: "background.paper",
        }}
      >
        <Typography variant="subtitle1">Detail</Typography>
        <CloseOutlinedIcon sx={{ cursor: "pointer" }} onClick={onClose} />
      </Stack>
      <Scrollbar>
        <Stack sx={{ px: 4, mb: 2 }}>
          <Typography variant="h6" align="center" paddingY={2}>
            {modelDescrible.name}
          </Typography>
          <Divider sx={{ mb: 2.5 }} />
          <Grid container columnSpacing={1}>
            {Object.entries(getOrderedDetail()).map(([key, value]) => (
              <>
                <Grid item xs={6} lg={6}>
                  <Typography variant="subtitle2" paddingY={1}>
                    {key}
                  </Typography>
                </Grid>
                <Grid item xs={6} lg={6}>
                  {typeof value === "string" ? (
                    <Typography
                      variant="body2"
                      paddingY={1}
                      sx={{ wordBreak: "break-word" }}
                    >
                      {value}
                    </Typography>
                  ) : (
                    value
                  )}
                </Grid>
              </>
            ))}
          </Grid>
        </Stack>
        <Divider sx={{ mb: 2, borderStyle: "dashed" }} />
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate(`${detailData.id}/modelPackageEdit`)}
            disabled={detailData.created_by !== userDetail.user_id}
            sx={{ mb: 10 }}
          >
            Edit
          </Button>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};

export default ModelPackageDetailDrawer;

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  up: `Up`,
  key: `Key`,
  app: `app`,
  user: `user`,
  list: `list`,
  time: `time`,
  size: `Size`,
  body: `Body`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  name: `Name`,
  send: `Send`,
  enter: `Enter`,
  query: `Query`,
  cards: `cards`,
  posts: `posts`,
  share: `Share`,
  apply: `Apply`,
  value: `Value`,
  param: `Parameter`,
  model: `Model`,
  phase: `Phase`,
  reset: `Reset`,
  cancel: `Cancel`,
  region: `Region`,
  config: `Configure`,
  source: `Source`,
  status: `Status`,
  create: `create`,
  sortby: `Sort By`,
  delete: `Delete`,
  browse: `Browse`,
  filters: `Filters`,
  refresh: `Refresh`,
  kanban: `kanban`,
  rating: `Rating`,
  confirm: `Confirm`,
  no_data: `No Data`,
  up4Star: `Up4 Star`,
  up3Star: `Up3 Star`,
  up2Star: `Up2 Star`,
  up1Star: `Up1 Star`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  waiting: `Waiting`,
  compete: `Compete`,
  disable: `Disable`,
  disabled: `Disabled`,
  optional: `Optional`,
  rejected: `Rejected`,
  approved: `Approved`,
  duration: `Time`,
  submitted: `Submitted`,
  endpoint: `Endpoint`,
  download: `Download`,
  checkout: `checkout`,
  calendar: `calendar`,
  dashboard: `Dashboard`,
  data_type: `Data type`,
  timestamp: `Timestamp`,
  log_body: `Content`,
  log_list: `Log list`,
  test_log: `Test histroy`,
  create_at: `Create at`,
  clear_all: `Clear All`,
  algorithm: `Algorithm`,
  candidate: `candidate`,
  operation: `Operation`,
  deployment: `Deployment`,
  production: `In production`,
  remove_all: `Remove All`,
  prediction: `Prediction`,
  predictions: `Predictions`,
  output_log: `Output Log`,
  search_log: `Search log...`,
  model_name: `Competitor`,
  model_file: `Model file`,
  search_model: `Search model...`,
  search_pipeline: `Search pipeline...`,
  stop_service: `Stop`,
  delete_service: `Delete`,
  apply_changes: `Apply changes`,
  repository: `code repository`,
  management: `management`,
  add_model: `Add model`,
  edit_model: `Edit model`,
  go_to_home: `Go to Main`,
  custom_data: `Custom data`,
  history_data: `History data`,
  test_request: `Request`,
  test_response: `Response`,
  app_scenario: `Scenario`,
  input_params: `Input Params`,
  manual_input: `Manual input`,
  upload_file: `Upload File`,
  api_call: `API call`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  add_candidate: `Add candidate`,
  local_model: `Local model`,
  official_image: `Official`,
  custom_image: `Custom`,
  env_var: `Environment variable`,
  running_env: `Running environment`,
  select_model: `Select model`,
  model_config: `Model configuration`,
  model_registry: `Model registry`,
  third_party_api: `3rd party API`,
  edit_candidate: `Edit candidate`,
  training_data: `Training data`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  service_type: `Service type`,
  operation_time: `Operation time`,
  service_version: `Service version`,
  feature_store: `feature store`,
  item_caption: `item caption`,
  description: `Description`,
  other_cases: `other cases`,
  ml_app_store: `ML app store`,
  set_time_range: `Set Time Range`,
  start_time: `Start time`,
  start_date: `Start date`,
  end_time: `End time`,
  end_date: `End date`,
  config_query: `Configure query`,
  reference_value: `Start`,
  competitor_model: `Competitor`,
  enter_request_url: `Enter request url`,
  model_monitor: `Model monitor`,
  model_lineage: `Model lineage`,
  initial_weight: `Initial weight`,
  instance_type: `Instance type`,
  candidate_model_list: `Candidate model list`,
  setting_history: `Setting history`,
  drop_or_select_file: `Drop or Select file`,
  thorough_your_machine: `thorough your machine`,
  drop_files_here_or_click: `Drop files here or click`,
  sorry_page_not_found: `Sorry, page not found!`,
  deployment_environment: `Deployment environment`,
  only_admin_can_see_this_item: `Only admin can see this item`,

  // Log in
  welcome_back: `Hi, Welcome Back`,
  sign_in: `Sign in to CSAir Recommendation Platform`,
  sign_out: `Sign Out`,
  login: `Login`,
  email: `Email`,
  password: `Password`,
  forget_password: `Forgot password`,
  remember_me: `Remember me`,
  use: `Use`,
  dont_have_account: `Don’t have an account`,
  get_started: `Get Started`,
  enter_details: `Enter your details below.`,

  // Serive Dashboard
  service: {
    ml_service: `Service`,
    ml_service_metric: `ML Service Dashboard`,
    ml_service_list: `ML Services`,
    ml_service_name: `Service Name`,
    ml_service_running: `Running for`,
    ml_service_running_days: `Days`,
    ml_service_maintainer: `Maintainer`,
    ml_service_launch_time: `Launch Time`,
    ml_service_status: `Status`,
    ml_service_status_healthy: `Healthy`,
    ml_service_status_warning: `Warning`,
    ml_service_status_fail: `Fail`,
    ml_service_phase: `Phase`,
    ml_service_activity: `Activity (Past 7 Days)`,
    ml_service_last_prediction: `Last Prediction`,
    ml_service_priority: `Priority`,
    ml_service_priority_high: `High`,
    ml_service_priority_medium: `Medium`,
    ml_service_priority_low: `Low`,
    ml_service_phase_test: `Test`,
    ml_service_phase_prod: `Production`,
    ml_service_phase_dev: `Development`,
    ml_service_metric_inferences: `Total Inferences`,
    ml_service_metric_deployments: `Deployments`,
    ml_service_metric_feedbacks: `Feedbacks`,
    ml_service_metric_statuses: `Status Summary`,

    ml_service_destails: "ML Service Details",

    ml_service_tab_overview: "Overview",
    ml_service_tab_overview_summary: "Summary",
    ml_service_tab_overview_lineage: "Lineage",
    ml_service_tab_overview_lineage_pipelines: "Pipeline",
    ml_service_tab_overview_lineage_models: "Model",
    ml_service_tab_overview_lineage_datasets: "Dataset",
    ml_service_tab_overview_governance: "Governance",
    ml_service_tab_overview_governance_history: "Approval log",

    ml_service_tab_logger: "Logger",
    ml_service_tab_metrics: "Metrics",
    ml_service_tab_tester: "Tester",
    // ml_service_tab_campaign: 'Campaign',
    ml_service_tab_models: "Models",
    ml_service_tab_settings: "Settings",
    ml_service_tab_powerbi: "Power BI",

    log_business_metric: `Business Metrics`,
  },

  // Pipeline
  ml_pipeline: {
    list: `Pipeline List`,
    total: "Total",
    succeeded: "Succeeded",
    executing: "Executing",
    failed: "Failed",
    no_execution: "No execution",
    edit: "Edit",
    delete: "Delete",
    duplicate: "Duplicate",
    action_cannot_be_undone: "Please note this action cannot be undone.",
    confirm_deletion_click: "To confirm deletion, click",
    table: {
      name: "Name",
      scenario: "scenario",
      tags: "Tags",
      creatAt: "Creat at",
      executions: "Executions",
      lastExecution: "Last execution",
    },
    new_pipeline: "New pipeline",
    pipeline_list: "Pipeline list",
    create_pipeline: "Create a new pipeline",
    trigger: "Trigger",
    no_triggers: "No triggers",
    pipeline_settings: "PIPELINE SETTINGS",
    pipeline_config: "Pipeline config",
    pipeline_steps: "Pipeline steps",
    pipeline_params: "Pipeline parames",
    pipeline_name: "Pipline name",
    pipeline_arn: "Pipline arn",
    no_pipeline_steps: "No pipeline steps",
    no_pipeline_params: "No pipeline parameters",
    create_pipeline_params_by: 'Create one by clicking "+" button',
    steps: {
      processing: "Processing",
      registermodel: "RegisterModel",
      training: "Training",
      createmodel: "CreateModel",
      types_of_pipeline_steps: "types of pipeline steps",
    },
  },

  role_arn: "Role arn",

  // ML Store
  ml_store: `ML App Store`,
  ml_store_list: `App list`,
};

export default en;

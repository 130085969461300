import { useState } from "react";
import { Icon } from "@iconify/react";
import editFill from "@iconify/icons-eva/edit-fill";
// material
import { Box, Stack, IconButton } from "@mui/material";
//
import LambdaCodePopover from "./LambdaCodePopover";
import { StepCodeStruct } from "@/@types/project/mlPipeline/SageMaker/pipeline";
import { StyledTextField } from "../../components/PipelineComponents";

// ----------------------------------------------------------------------

export default function LambdaCode({ data }: { data: StepCodeStruct }) {
  const [openCodeSetter, setOpenCodeSetter] = useState(false);

  const handleOpenCodeSetter = () => {
    setOpenCodeSetter(true);
  };

  return (
    <Stack spacing={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <StyledTextField
          multiline
          disabled
          maxRows={3}
          label="Function code path"
          placeholder="s3://xxx/xxx.zip"
          inputProps={{ style: { fontSize: "14px", wordBreak: "break-all" } }}
          InputLabelProps={{
            style: { fontSize: "15px" },
            shrink: data.path !== "",
          }}
          value={data.path}
        />

        <IconButton
          size="small"
          onClick={handleOpenCodeSetter}
          sx={{ ml: 0.5 }}
        >
          <Icon icon={editFill} width={16} height={16} />
        </IconButton>

        <LambdaCodePopover
          isOpenCompose={openCodeSetter}
          onCloseCompose={() => setOpenCodeSetter(false)}
          codeProps={data}
        />
      </Box>
    </Stack>
  );
}

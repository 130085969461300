import { useState } from "react";
import { Icon } from "@iconify/react";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  Radio,
  Paper,
  Button,
  Collapse,
  Typography,
} from "@mui/material";
// components
import { ContentTypes } from "../../SettingSidebar";
import { PipelineSelect } from "../../components/PipelineComponents";
import PipelineParameterEntry from "../../components/PipelineParameterEntry";

// ----------------------------------------------------------------------

const CollapseButtonStyle = styled(Button)(({ theme }) => ({
  height: 2,
  borderRadius: 0,
  paddingTop: theme.spacing(1),
  paddingLeft: 0,
}));

// ----------------------------------------------------------------------

type Props = {
  label: string;
  field: string;
  fieldUseParam: string;
  contentType: string;
  formik: any;
};

export default function ModelMetricCard({
  label,
  field,
  fieldUseParam,
  contentType,
  formik,
}: Props) {
  const [openQuery, setOpenQuery] = useState(false);
  const { values, getFieldProps } = formik;

  return (
    <Paper
      sx={{
        px: 1.5,
        py: 1,
        mx: 0,
        mb: 1,
        mt: 0,
        bgcolor: "background.neutral",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CollapseButtonStyle
          disableRipple
          fullWidth
          onClick={() => setOpenQuery((prev) => !prev)}
          startIcon={
            <Icon
              icon={openQuery ? arrowIosDownwardFill : arrowIosForwardFill}
              width={16}
              height={16}
            />
          }
          sx={{
            justifyContent: "flex-start",
            "&:hover": { backgroundColor: "transparent" },
            color: "text.primary",
          }}
        >
          <Typography
            variant="body2"
            fontWeight={400}
            textOverflow="ellipsis"
            noWrap
          >
            {label}
          </Typography>
        </CollapseButtonStyle>
        <Radio size="small" checked={values[field] !== ""} onClick={() => {}} />
      </Box>

      <Box sx={{ mb: openQuery ? 0.5 : 0 }}>
        <Collapse in={openQuery}>
          <Stack spacing={2} sx={{ mt: 1.5 }}>
            <PipelineParameterEntry
              label={"S3 uri"}
              field={field}
              fieldUseParam={fieldUseParam}
              formik={formik}
            />

            <PipelineSelect
              label={"Content type"}
              field={contentType}
              placeholder={"Select content MIME type"}
              options={ContentTypes}
              getFieldProps={getFieldProps}
            />
          </Stack>
        </Collapse>
      </Box>
    </Paper>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { addThunkReducers } from "../../utils";
import { IFeatureStoreState } from "@/@types/project/model/featureStore";
import {
  getFeatureSetList,
  getFeatureSetVersionList,
  getFeatureSetDescribe,
  getFeatureSetVersionDescribe,
  getConnectionById,
  getFeatureSetTagList,
  getConnectionList,
  getProjectList,
  getSynchronizationList,
  getSynchronizationJobList,
  getStatisticList,
  getCredentialList,
  getTagList,
} from "./thunks";

const initialState: IFeatureStoreState = {
  featureSetDatas: {
    loading: false,
    data: {},
    error: null,
  },
  featureSetVersionDatas: {
    loading: false,
    data: {},
    error: null,
  },
  featureSetDescribeDatas: {
    loading: false,
    data: {},
    error: null,
  },
  featureSetVersionDescribeDatas: {
    loading: false,
    data: {},
    error: null,
  },
  connectionDatas: {
    loading: false,
    data: {},
    error: null,
  },
  tagDatas: {
    loading: false,
    data: {},
    error: null,
  },
  featureSetTagDatas: {
    loading: false,
    data: {},
    error: null,
  },
  connectionListDatas: {
    loading: false,
    data: {},
    error: null,
  },
  projectDatas: {
    loading: false,
    data: {},
    error: null,
  },
  synchronizationDatas: {
    loading: false,
    data: {},
    error: null,
  },
  synchronizationJobDatas: {
    loading: false,
    data: {},
    error: null,
  },
  statisticDatas: {
    loading: false,
    data: {},
    error: null,
  },
  credentialDatas: {
    loading: false,
    data: {},
    error: null,
  },
};

const slice = createSlice({
  name: "featureStore",
  initialState,
  reducers: {
    setFeatureSetData(state, action) {
      Object.assign(state, action.payload);
    },
    setFeatureSetVersionData(state, action) {
      Object.assign(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    addThunkReducers(builder, [
      { action: getFeatureSetList, name: "featureSetDatas" },
      { action: getFeatureSetVersionList, name: "featureSetVersionDatas" },
      { action: getFeatureSetDescribe, name: "featureSetDescribeDatas" },
      {
        action: getFeatureSetVersionDescribe,
        name: "featureSetVersionDescribeDatas",
      },
      { action: getConnectionById, name: "connectionDatas" },
      { action: getFeatureSetTagList, name: "featureSetTagDatas" },
      { action: getConnectionList, name: "connectionListDatas" },
      { action: getTagList, name: "tagDatas" },
      { action: getProjectList, name: "projectDatas" },
      { action: getSynchronizationList, name: "synchronizationDatas" },
      { action: getSynchronizationJobList, name: "synchronizationJobDatas" },
      { action: getStatisticList, name: "statisticDatas" },
      { action: getCredentialList, name: "credentialDatas" },
    ]);
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setFeatureSetData, setFeatureSetVersionData } = slice.actions;

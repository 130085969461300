import { Schema, updateConditionIfElseSteps } from "../slices/slice";
import {
  Step,
  PipelineStruct,
  PipelineManager,
} from "@/@types/project/mlPipeline/SageMaker/pipeline";
import { generateStep } from "./step-generators";
import { dispatch } from "@/redux/store";
import { SchemaEdgeEntity } from "../../graph/slices/slice";
import { ConditionStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-condition";
import {
  // stopUploadingPipeline,
  startUploadingPipeline,
} from "@/redux/project/automation/pipeline";

export const formPipelineInfoForSM = async (
  schema: Schema,
  pipeline: PipelineManager,
  email: string
) => {
  dispatch(startUploadingPipeline());
  const steps = schema.pipelineSteps;

  const pipelineInfo = {
    Version: "2020-12-01",
    Metadata: formMeteData(pipeline, steps, schema.edgeEntities, email),
    PipelineExperimentConfig: {
      ExperimentName: {
        Get: "Execution.PipelineName",
      },
      TrialName: {
        Get: "Execution.PipelineExecutionId",
      },
    },
    Parameters: pipeline.pipelineParamList,
    Steps: [],
  } as PipelineStruct;

  const ifElseSteps: Array<string> = [];
  for (const step of steps) {
    if (step.type === "Condition") {
      udpdateIfElseSteps(steps, step as ConditionStep, schema.edgeEntities);
      ifElseSteps.push(...(step as ConditionStep).ifSteps);
      ifElseSteps.push(...(step as ConditionStep).elseSteps);
    }
  }

  // steps.forEach((step) => {
  //   if (step.type === "Condition") {
  //     ifElseSteps.push(...(step as ConditionStep).ifSteps);
  //     ifElseSteps.push(...(step as ConditionStep).elseSteps);
  //   }
  // });
  // console.log(ifElseSteps);

  for (const step of steps) {
    if (!ifElseSteps.includes(step.name)) {
      pipelineInfo.Steps.push(await generateStep(step, schema, pipeline));
    }
  }

  // dispatch(stopUploadingPipeline());

  // steps.forEach((step) => {
  //   if (!ifElseSteps.includes(step.name)) {
  //     pipelineInfo.Steps.push(generateStep(step, schema, pipeline));
  //   }
  // });

  // console.log(JSON.stringify(pipelineInfo));

  return JSON.stringify(pipelineInfo);
};

const udpdateIfElseSteps = (
  steps: Step[],
  condStep: ConditionStep,
  edgeEntities: SchemaEdgeEntity[]
) => {
  const ifSteps: Array<string> = [];
  const elseSteps: Array<string> = [];

  for (const edge of edgeEntities) {
    if (edge.sourceEntityId === condStep.id) {
      if (edge.property && edge.property.includes("true")) {
        if (!ifSteps.includes(edge.targetEntityId)) {
          ifSteps.push(edge.targetEntityId);
        }
      }
      if (edge.property && edge.property.includes("false")) {
        if (!elseSteps.includes(edge.targetEntityId)) {
          elseSteps.push(edge.targetEntityId);
        }
      }
    }
  }

  findIfElseSteps(steps, edgeEntities, ifSteps);
  findIfElseSteps(steps, edgeEntities, elseSteps);

  const ifStepNames = ifSteps.map((ifStepId) => {
    const foundStep = steps.find((step) => step.id === ifStepId);
    if (foundStep) return foundStep.name;
    return "";
  });
  const elseStepNames = elseSteps.map((elseStepId) => {
    const foundStep = steps.find((step) => step.id === elseStepId);
    if (foundStep) return foundStep.name;
    return "";
  });

  // console.log(ifStepNames);
  // console.log(elseStepNames);

  dispatch(
    updateConditionIfElseSteps({
      id: condStep.id,
      branch: "if",
      data: ifStepNames,
    })
  );

  dispatch(
    updateConditionIfElseSteps({
      id: condStep.id,
      branch: "else",
      data: elseStepNames,
    })
  );

  return;
};

function findIfElseSteps(
  steps: Step[],
  edgeEntities: SchemaEdgeEntity[],
  conditionSteps: Array<string>
) {
  let initialLen = 0;
  const checkedSteps: Array<string> = [];
  while (conditionSteps.length !== initialLen) {
    initialLen = conditionSteps.length;
    for (const condStepId of conditionSteps) {
      if (checkedSteps.includes(condStepId)) continue;
      for (const step of steps) {
        if (conditionSteps.includes(step.id)) continue;
        for (const edge of edgeEntities) {
          if (
            edge.targetEntityId === step.id &&
            edge.sourceEntityId === condStepId
          ) {
            checkedSteps.push(condStepId);
            if (!conditionSteps.includes(edge.targetEntityId)) {
              conditionSteps.push(step.id);
            }
            break;
          }
        }
      }
    }
  }
}

const formMeteData = (
  pipeline: PipelineManager,
  steps: Step[],
  edgeEntities: SchemaEdgeEntity[],
  email: string
) => {
  const results = {} as any;
  if (email && email.length > 0) results.createdby = email;

  if (pipeline.description && pipeline.description !== "")
    results.description = pipeline.description;

  if (pipeline.scenario && pipeline.scenario !== "")
    results.scenario = pipeline.scenario;

  if (pipeline.tags && pipeline.tags.length > 0)
    results.tags = pipeline.tags.join(",");

  // let stepCoordList = [];
  if (steps.length > 0) {
    let stepPositions = new Map<string, (string | number)[]>();
    steps.forEach((step) => {
      stepPositions.set(step.name, [step.id, step.nodeX, step.nodeY]);
    });
    results.nodes = JSON.stringify(Object.fromEntries(stepPositions));
  }

  if (edgeEntities.length > 0) {
    let edgeInfo = new Map<string, string[]>();
    edgeEntities.forEach((edge) => {
      edgeInfo.set(edge.id, [
        edge.sourceEntityId,
        edge.sourcePortId,
        edge.targetEntityId,
        edge.targetPortId,
      ]);
    });
    results.edges = JSON.stringify(Object.fromEntries(edgeInfo));
  }

  return results;
};

/* eslint-disable react-hooks/exhaustive-deps */
import {
  useFormik,
  FormikProvider,
  Form,
  Field,
  FieldArray,
  FormikProps,
} from "formik";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  Stack,
  Box,
  Typography,
  FormHelperText,
  Grid,
  IconButton,
} from "@mui/material";
import * as Yup from "yup";
import Scrollbar from "@/components/project/Scrollbar";
import { cloneDeep, isArray, isEmpty } from "lodash";
import { useEffect } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import MlNumberInputField from "@/components/project/mlComponents/MlNumberInputField";
import { IKubeflowHpTunerFormValues } from "../..";
import { useSnackbar } from "notistack";

enum HyperParametersEnum {
  "Integer" = "integer",
  "Double" = "double",
  "Discrete" = "discrete",
  "Categorical" = "categorical",
}

interface IHyperParametersCreateOrEdit {
  mode: "create" | "edit";
  isOpen: boolean;
  onClose: VoidFunction;
  row?: Record<string, any>;
  clearRowData: VoidFunction;
  totalFormik: FormikProps<IKubeflowHpTunerFormValues>;
}

interface FormValues {
  name: string;
  type: HyperParametersEnum;
  valuesDto:
    | { min: string; max: string; step?: string }
    | Array<{ value: string }>;
}

const initialValues: FormValues = {
  name: "",
  type: HyperParametersEnum.Integer,
  valuesDto: {
    min: "",
    max: "",
    step: "",
  },
};

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .max(64, "Name cannot be longer than 64 characters"),
  type: Yup.string().required("Type is required"),
  valuesDto: Yup.lazy((value, context) => {
    const { type } = context.parent;

    if (
      type === HyperParametersEnum.Integer ||
      type === HyperParametersEnum.Double
    ) {
      return Yup.object().shape({
        min: Yup.string().required("Min is required"),
        max: Yup.string().required("Max is required"),
        // step: Yup.string().required('Step is required'),
      });
    } else if (
      type === HyperParametersEnum.Discrete ||
      type === HyperParametersEnum.Categorical
    ) {
      return Yup.array().of(
        Yup.object().shape({
          value: Yup.string().required("Value is required"),
        })
      );
    } else {
      return Yup.mixed().notRequired();
    }
  }),
});

const HyperParamsDialog = ({
  mode,
  isOpen,
  onClose,
  clearRowData,
  row = {},
  totalFormik,
}: IHyperParametersCreateOrEdit) => {
  const { enqueueSnackbar } = useSnackbar();

  const { values: totalValues, setFieldValue: setTotalFieldValue } =
    totalFormik;

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (mode === "edit") {
        const tempArr = totalValues?.hyperParameters.map((item) => {
          if (item.name === values.name) {
            let tempObj = {};
            if (["integer", "double"].includes(values.type)) {
              tempObj = {
                ...item,
                feasibleSpace: {
                  ...(item?.feasibleSpace || {}),
                  ...(values?.valuesDto || {}),
                },
              };
            } else {
              tempObj = {
                ...item,
                feasibleSpace: values?.valuesDto || [],
              };
            }
            return tempObj;
          }
          return item;
        });

        setTotalFieldValue("hyperParameters", tempArr);
      } else {
        if (
          totalValues?.hyperParameters.findIndex(
            (item) => item.name === values.name
          ) !== -1
        ) {
          enqueueSnackbar("Exist repeat parameter name", {
            variant: "warning",
          });
          return;
        }

        const tempArr = cloneDeep(totalValues?.hyperParameters);
        tempArr.push({
          name: values?.name || "",
          parameterType: values?.type || "",
          feasibleSpace: values?.valuesDto,
        });

        setTotalFieldValue("hyperParameters", tempArr);
      }

      handleClose();
    },
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    setFieldTouched,
    resetForm,
  } = formik;

  const threeColNumInput = () => {
    return (
      <Grid container columnSpacing={2} sx={{ px: 2.5 }}>
        <Grid item xs={12} lg={4}>
          <MlNumberInputField
            name="valuesDto.min"
            label="Min"
            formik={formik}
            isDouble={["double"].includes(values.type)}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <MlNumberInputField
            name="valuesDto.max"
            label="Max"
            formik={formik}
            isDouble={["double"].includes(values.type)}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <MlNumberInputField
            name="valuesDto.step"
            label="Step (Optional)"
            noValidate
            formik={formik}
            isDouble={["double"].includes(values.type)}
          />
        </Grid>
      </Grid>
    );
  };

  const listValues = () => {
    const listErrors = errors.valuesDto as Array<{ value: string }>;
    const listTouched = touched.valuesDto as Array<{ value: boolean }>;

    const listValuesDto = values.valuesDto as Array<{ value: string }>;

    return (
      <>
        <Typography variant="body2" sx={{ mb: 2 }}>
          List of values
        </Typography>
        <Box sx={{ px: 2.5 }}>
          <FieldArray
            name="valuesDto"
            render={({ push, remove }) => (
              <>
                {isArray(listValuesDto) &&
                  listValuesDto.map((_, index) => (
                    <Stack direction="row">
                      <>
                        <Field
                          as={TextField}
                          name={`valuesDto.${index}.value`}
                          label="Value"
                          size="small"
                          fullWidth
                          margin="dense"
                          helperText={
                            (listTouched?.[index]?.value &&
                              listErrors?.[index]?.value) ||
                            " "
                          }
                          error={
                            listTouched?.[index]?.value &&
                            Boolean(listErrors?.[index]?.value)
                          }
                          FormHelperTextProps={{
                            sx: { minHeight: "18px", marginTop: "2px" },
                          }}
                        />
                        <Box sx={{ ml: 1.5, mt: 1 }}>
                          <IconButton
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <RemoveOutlinedIcon />
                          </IconButton>
                        </Box>
                      </>
                    </Stack>
                  ))}
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={<AddOutlinedIcon />}
                  onClick={() => {
                    push({
                      value: "",
                    });
                  }}
                >
                  Addtional Value
                </Button>
              </>
            )}
          />
        </Box>
      </>
    );
  };

  const getRangeForm = (type: HyperParametersEnum) => {
    switch (type) {
      case HyperParametersEnum.Integer:
        return threeColNumInput();
      case HyperParametersEnum.Double:
        return threeColNumInput();
      case HyperParametersEnum.Discrete:
        return listValues();
      case HyperParametersEnum.Categorical:
        return listValues();
      default:
        return <></>;
    }
  };

  const defaultValuesDto = (type: HyperParametersEnum, data?: any) => {
    if (
      type === HyperParametersEnum.Integer ||
      type === HyperParametersEnum.Double
    ) {
      return {
        min: data?.min || "",
        max: data?.max || "",
        step: data?.step || "",
      };
    } else if (
      type === HyperParametersEnum.Discrete ||
      type === HyperParametersEnum.Categorical
    ) {
      return (
        data || [
          {
            value: "",
          },
        ]
      );
    } else {
      return {
        min: data?.min || "",
        max: data?.max || "",
        step: data?.step || "",
      };
    }
  };

  const handleClose = () => {
    onClose();
    resetForm();
    clearRowData();
  };

  useEffect(() => {
    if (isOpen && !isEmpty(row) && mode === "edit") {
      setValues({
        name: row?.name || "",
        type: row?.parameterType || "",
        valuesDto: defaultValuesDto(
          row?.parameterType as HyperParametersEnum,
          row?.feasibleSpace
        ),
      });
    }
  }, [row, isOpen]);

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown
      onClose={handleClose}
      PaperProps={{
        style: { width: "960px", maxWidth: "none", padding: "20px 40px" },
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        {mode === "create" ? "Add New" : "Edit"} Parameter
      </Typography>
      <Box sx={{ px: 1 }}>
        <FormikProvider value={formik}>
          <Form>
            <Scrollbar sx={{ maxHeight: "600px" }}>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Configure the new parameter that will be added to the list
              </Typography>
              <Box sx={{ px: 2.5 }}>
                <Field
                  as={TextField}
                  name="name"
                  disabled={mode === "edit"}
                  size="small"
                  label="Name"
                  fullWidth
                  margin="dense"
                  helperText={touched.name && errors.name ? errors.name : " "}
                  error={touched.name && Boolean(errors.name)}
                  FormHelperTextProps={{
                    sx: { minHeight: "18px", marginTop: "2px" },
                  }}
                />
                <FormControl
                  fullWidth
                  margin="dense"
                  size="small"
                  error={touched.type && Boolean(errors.type)}
                >
                  <InputLabel>Type</InputLabel>
                  <Field
                    as={Select}
                    disabled={mode === "edit"}
                    name="type"
                    label="Type"
                    onChange={async (event) => {
                      await setFieldValue("type", event.target.value);
                      setFieldTouched("type", true);

                      await setFieldValue(
                        "valuesDto",
                        defaultValuesDto(event.target.value)
                      );
                    }}
                  >
                    <MenuItem value="integer">Integer</MenuItem>
                    <MenuItem value="double">Double</MenuItem>
                    <MenuItem value="discrete">Discrete</MenuItem>
                    <MenuItem value="categorical">Categorical</MenuItem>
                  </Field>
                  <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                    {touched.type && errors.type ? errors.type : ""}
                  </FormHelperText>
                </FormControl>
              </Box>

              {getRangeForm(values.type)}
            </Scrollbar>
            <Stack
              spacing={2}
              direction="row"
              justifyContent="center"
              sx={{ my: 4 }}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{ width: "200px", color: "background.paper" }}
              >
                Save
              </Button>
              <Button
                type="button"
                color="inherit"
                variant="outlined"
                onClick={handleClose}
                sx={{ width: "200px", color: "text.secondary" }}
              >
                Cancel
              </Button>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Dialog>
  );
};

export default HyperParamsDialog;

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Page from "@/components/project/Page";
import { PATH_DASHBOARD } from "@/routes/paths";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate, useParams } from "react-router";
import { Stack, Typography, Button, Menu, MenuItem, Box } from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { dispatch, useSelector, RootState } from "@/redux/store";
import { useSnackbar } from "notistack";
import {
  getAppGalleryDetail,
  getAppVersionList,
  editAppVersion,
  getRelTagAppList,
  delRelTagApp,
  editAppGallery,
} from "@/redux/project/mlService/appGallery/thunks";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import MlIframe from "@/components/project/mlComponents/MlIframe";
import EmptyTable from "@/components/project/EmptyTable";

const MlAppGalleryIframe = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { appId } = useParams();
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);
  const [hasStarted, setHasStarted] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {
    mlAppDescribeData: { data: appDetail },
    mlAppVersionDatas: {
      data: { items: versionList = [] },
    },
  } = useSelector((state: RootState) => state.appGallery);
  const { userDetail } = useSelector((state: RootState) => state.common);

  const { project_id, permissions = [] } = userDetail;

  const IFRAME_SRC = `/appgallery/taichi-prj-${project_id}/app-${appId}/`;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLinks = () => {
    return [
      {
        name: "ML Service",
      },
      {
        name: "App Gallery",
        href: PATH_DASHBOARD.mlService.appGallery,
      },
      // {
      //   name: appDetail?.name || '',
      //   href: `${PATH_DASHBOARD.mlService.appGallery}/${appId}`,
      // },
      {
        name: "open",
      },
    ];
  };

  useEffect(() => {
    // get app
    dispatch(
      getAppGalleryDetail({
        id: appId,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    // get app version and message
    dispatch(
      getAppVersionList({
        params: { page_num: 1, page_size: 9999, mlapp_id: appId, is_active: 1 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    )
      .unwrap()
      .then((resp) => {
        const start = resp?.items?.some((item) => item.status === "started");
        setHasStarted(start);
      });
  }, []);

  const handleOpenDetail = () => {
    navigate(`/ui/mlService/appGallery/${appDetail?.id}`, {
      replace: true,
    });
  };

  const handleEdit = () => {
    navigate(`/ui/mlService/appGallery/${appDetail?.id}/appGalleryEdit`);
  };

  const handleDelete = async () => {
    // version -> is_active = 0, status => wait_close
    // app -> is_active = 0
    // rel_app_tag -> is_active = 0
    try {
      setDelLoading(true);
      await Promise.all(
        versionList.map((version) =>
          dispatch(
            editAppVersion({
              id: version.id,
              params: { is_active: 0, status: "wait_close" },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap()
        )
      );

      const relAppTagResult = await dispatch(
        getRelTagAppList({
          params: {
            page_num: 1,
            page_size: 9999,
            is_active: 1,
            mlapp_id: appId,
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      await Promise.all(
        relAppTagResult?.items?.map((rel) =>
          dispatch(
            delRelTagApp({
              id: rel.id,
              params: { is_active: 0 },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          )
        )
      );

      // const appResult = await dispatch(getAppGalleryDetail({
      //   id,
      //   alertCallback: enqueueSnackbar,
      //   pathname,
      // })).unwrap();

      await dispatch(
        editAppGallery({
          id: appId,
          params: { is_active: 0 },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      );

      setDelLoading(false);
      navigate("/ui/mlService/appGallery");
      enqueueSnackbar("Delete Success", { variant: "success" });
    } catch (err) {
      throw err;
    }
  };

  const getHeading = () => {
    return (
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="h4" gutterBottom>
          {appDetail?.name || ""}{" "}
        </Typography>
        {appDetail?.name && (
          <Button
            color="secondary"
            size="small"
            onClick={handleOpenDetail}
            sx={{ marginBottom: "6px" }}
          >
            Detail
          </Button>
        )}
      </Stack>
    );
  };

  const getActionBtn = () => {
    return (
      permissions.includes("21100") && (
        <>
          <Button
            onClick={handleClick}
            variant="outlined"
            endIcon={
              <ExpandMoreOutlinedIcon
                sx={{
                  transform: open ? "rotate(-180deg)" : "rotate(0deg)",
                  transition: "transform 0.5s",
                }}
              />
            }
            color="secondary"
            sx={{ width: "150px" }}
          >
            Actions
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: "150px",
              },
            }}
          >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem
              onClick={() => {
                setOpenConfirm(true);
                handleClose();
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </>
      )
    );
  };

  return (
    <Page
      title={`${appDetail?.name} | OmniML`}
      heading={getHeading()}
      links={getLinks()}
      height="100%"
      action={getActionBtn()}
    >
      {hasStarted ? (
        <MlIframe src={IFRAME_SRC as string} />
      ) : (
        <Box
          sx={{
            mt: 0,
            height: 500,
          }}
        >
          <EmptyTable
            mainTitle="No Active Version"
            content="To start using this app, open the edit page to deploy or restore a version first"
            sx={{ mt: 2, p: 8 }}
          />
        </Box>
      )}

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete app <strong> {appDetail.name} </strong> item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelete();
              setOpenConfirm(false);
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </Page>
  );
};

export default MlAppGalleryIframe;

import { Graph } from "@antv/x6";

Graph.unregisterNode("schema-node");
Graph.unregisterEdge("schema-edge");
Graph.unregisterNode("custom-image");

Graph.registerNode(
  "schema-node",
  {
    inherit: "rect",
    width: 84,
    height: 37,
    attrs: {
      body: {
        rx: 8, // 圆角矩形
        ry: 8,
        fill: "#0094A8",
        fillOpacity: 0.4,
        magnet: false,
        stroke: "#0094A8",
        strokeWidth: 2,
        refWidth: "100%",
        refHeight: "100%",
        zIndex: 1,
      },
      label: {
        textAnchor: "middle",
        fill: "#ffffff",
        fontSize: 16,
      },
    },
    ports: {
      groups: {
        top: {
          position: {
            name: "top",
          },
          attrs: {
            circle: {
              r: 4,
              magnet: true,
              stroke: "#5F95FF",
              strokeWidth: 1,
              fill: "#fff",
              style: {
                visibility: "hidden",
              },
            },
          },
        },
        right: {
          position: {
            name: "right",
          },
          attrs: {
            circle: {
              r: 4,
              magnet: true,
              stroke: "#5F95FF",
              strokeWidth: 1,
              fill: "#fff",
              style: {
                visibility: "hidden",
              },
            },
          },
        },
        bottom: {
          position: {
            name: "bottom",
          },
          attrs: {
            circle: {
              r: 4,
              magnet: true,
              stroke: "#5F95FF",
              strokeWidth: 1,
              fill: "#fff",
              style: {
                visibility: "hidden",
              },
            },
          },
        },
        left: {
          position: {
            name: "left",
          },
          attrs: {
            circle: {
              r: 4,
              magnet: true,
              stroke: "#5F95FF",
              strokeWidth: 1,
              fill: "#fff",
              style: {
                visibility: "hidden",
              },
            },
          },
        },
      },
      items: [
        // {
        //   id: 'top1',
        //   group: 'top',
        // },
        // {
        //   id: 'top2',
        //   group: 'top',
        // },
        {
          id: "top",
          group: "top",
        },
        // {
        //   id: 'right1',
        //   group: 'right',
        // },
        // {
        //   id: 'right2',
        //   group: 'right',
        // },
        {
          id: "right",
          group: "right",
        },
        // {
        //   id: 'bottom1',
        //   group: 'bottom',
        // },
        // {
        //   id: 'bottom2',
        //   group: 'bottom',
        // },
        {
          id: "bottom",
          group: "bottom",
        },
        // {
        //   id: 'left1',
        //   group: 'left',
        // },
        // {
        //   id: 'left2',
        //   group: 'left',
        // },
        {
          id: "left",
          group: "left",
        },
      ],
    },
  },
  true
);
Graph.registerNode(
  "custom-image",
  {
    inherit: "rect",
    width: 84,
    height: 37,
    markup: [
      {
        tagName: "rect",
        selector: "body",
      },
      {
        tagName: "image",
      },
      {
        tagName: "text",
        selector: "label",
      },
    ],
    attrs: {
      body: {
        rx: 8, // 圆角矩形
        ry: 8,
        fill: "#0094A8",
        fillOpacity: 0.4,
        magnet: false,
        stroke: "#0094A8",
        strokeWidth: 2,
        refWidth: "100%",
        refHeight: "100%",
        zIndex: 1,
      },
      image: {
        width: 23,
        height: 23,
        refX: 7,
        refY: 7,
      },
      label: {
        refX: 36,
        textAnchor: "left",
        fill: "#ffffff",
        fontSize: 16,
      },
    },
    ports: {
      groups: {
        top: {
          position: {
            name: "top",
          },
          attrs: {
            circle: {
              r: 4,
              magnet: true,
              stroke: "#5F95FF",
              strokeWidth: 1,
              fill: "#fff",
              style: {
                visibility: "hidden",
              },
            },
          },
        },
        right: {
          position: {
            name: "right",
          },
          attrs: {
            circle: {
              r: 4,
              magnet: true,
              stroke: "#5F95FF",
              strokeWidth: 1,
              fill: "#fff",
              style: {
                visibility: "hidden",
              },
            },
          },
        },
        bottom: {
          position: {
            name: "bottom",
          },
          attrs: {
            circle: {
              r: 4,
              magnet: true,
              stroke: "#5F95FF",
              strokeWidth: 1,
              fill: "#fff",
              style: {
                visibility: "hidden",
              },
            },
          },
        },
        left: {
          position: {
            name: "left",
          },
          attrs: {
            circle: {
              r: 4,
              magnet: true,
              stroke: "#5F95FF",
              strokeWidth: 1,
              fill: "#fff",
              style: {
                visibility: "hidden",
              },
            },
          },
        },
      },
      items: [
        {
          id: "top",
          group: "top",
        },
        {
          id: "right",
          group: "right",
        },
        {
          id: "bottom",
          group: "bottom",
        },
        {
          id: "left",
          group: "left",
        },
      ],
    },
  },
  true
);
Graph.registerEdge(
  "schema-edge",
  {
    inherit: "edge",
    attrs: {
      line: {
        stroke: "#fff",
        lineWidth: 1,
      },
    },
    defaultLabel: {
      markup: [
        {
          tagName: "rect",
          selector: "bg",
        },
        {
          tagName: "text",
          selector: "txt",
        },
      ],
      connector: {
        name: "rounded",
        args: { radius: 10 },
      },
      attrs: {
        txt: {
          fill: "#C9DAFB",
          fontFamily: "Amazon Ember Display",
          fontSize: 14,
          textAnchor: "middle",
          textVerticalAnchor: "middle",
        },
        bg: {
          ref: "txt",
          fill: "#fff",
          rx: 3,
          ry: 3,
          refWidth: 15,
          refHeight: 4,
          refX: -8,
          refY: -2,
        },
      },
      position: {
        distance: 0.5,
        options: {
          keepGradient: true,
          ensureLegibility: true,
        },
      },
    },
  },
  true
);

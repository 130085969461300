import { createAsyncThunk } from "@reduxjs/toolkit";
import { parseFromSmPipeline } from "../schema/ParserForSmPipeline";

// import from local file or select temlate when create
export const importFromSmPipelineDef = createAsyncThunk(
  "schema/importFromSmPipelineAsync",
  async (pipeline: string) => {
    return parseFromSmPipeline(pipeline);
  }
);

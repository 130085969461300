/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router";
import { LoadingButton } from "@mui/lab";
import {
  Divider,
  Paper,
  Stack,
  Typography,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ReplyIcon from "@mui/icons-material/Reply";
import { DeleteIcon } from "@/assets/project/icons";
import Scrollbar from "@/components/project/Scrollbar";
import Label, { LabelColor } from "@/components/project/Label";
import VersionRestoreDialog from "./VersionRestoreDialog";
import AppDeployDialog from "./AppDeployDialog";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import { dispatch, useSelector, RootState } from "@/redux/store";
import { useSnackbar } from "notistack";
import { editAppVersion } from "@/redux/project/mlService/appGallery/thunks";
import ConfirmDialog from "@/components/project/ConfirmDialog";
import { isEmpty } from "lodash";

interface IDeployConfigVersionPanel {
  onClose: VoidFunction;
  setSelectedVersionInfo: Function;
  selectedVersionInfo: Record<string, any>;
}

enum StatusEnum {
  draft = "secondary",
  wait_start = "warning",
  starting = "secondary",
  started = "success",
  wait_close = "warning",
  closing = "secondary",
  closed = "default",
  failed = "error",
}

const useStyles = makeStyles({
  selected: {
    backgroundColor: "#e9e7ef",
    boxShadow: "0 1px 2px 0 alpha(color, 0.16)",
    "&:hover": {
      backgroundColor: "#dcd6d6",
    },
  },
  notSelected: {
    backgroundColor: "background.neutral",
  },
});

const DeployConfigVersionPanel = ({
  onClose,
  setSelectedVersionInfo,
  selectedVersionInfo,
}: IDeployConfigVersionPanel) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const [isSelected, setIsSelected] = useState<boolean[]>([]);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);
  const [openAppDeployDialog, setOpenAppDeployDialog] =
    useState<boolean>(false);
  const [openVersionRestoreDialog, setOpenVersionRestoreDialog] =
    useState<boolean>(false);
  const [versionDetail, setVersionDetail] = useState<Record<string, any>>({});
  const [draftVersion, setDraftVersion] = useState<Record<string, any>>({});
  const [otherVersion, setOtherVersion] = useState<any[]>([]);
  const {
    mlAppVersionDatas: {
      data: { items: versionData = [] },
      loading,
    },
  } = useSelector((state: RootState) => state.appGallery);
  // dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isEdit = pathname.includes("appGalleryEdit");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // get version list
    const otherVersions: any[] = [];
    // eslint-disable-next-line array-callback-return
    versionData.map((version) => {
      if (version.status === "draft") {
        setDraftVersion({ ...version });
      } else {
        otherVersions.push(version);
      }
    });
    const noDraft = versionData.every((item) => item.status !== "draft");
    if (noDraft) setDraftVersion({});
    setOtherVersion(otherVersions);
  }, [versionData]);

  useEffect(() => {
    const selectedValue = [...isSelected];
    selectedValue[selectedVersionInfo.id] = true;
    setIsSelected(selectedValue);
  }, [selectedVersionInfo]);

  const handleRestore = (record) => {
    setOpenVersionRestoreDialog(true);
    setVersionDetail(record);
    handleClose();
  };

  const handleDelete = () => {
    // version is_active->0, status->wait_close
    try {
      setDelLoading(true);
      dispatch(
        editAppVersion({
          id: selectedVersionInfo.id,
          params: { is_active: 0, status: "wait_close" },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      )
        .unwrap()
        .then(() => {
          setDelLoading(false);
          enqueueSnackbar("Delete Success", { variant: "success" });
        });
    } catch (err) {
      setDelLoading(false);
      throw err;
    }
  };

  const handleSelect = (record) => {
    const selectedValue = [...isSelected];
    selectedValue[record.id] = !isSelected[record.id];
    setIsSelected(selectedValue);
    setSelectedVersionInfo(record);
  };

  return (
    <Paper
      sx={{
        mt: 1.5,
        mr: 2,
        height: "100vh",
        width: "290px",
        borderRadius: 1.5,
        boxShadow:
          "0 0 1px 0 rgba(145, 158, 171, 0.42),0 1px 3px -1px rgba(145, 158, 171, 0.24)",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          m: 1.5,
          pt: 0.5,
          px: 1,
          bgcolor: "background.paper",
        }}
      >
        <Typography variant="subtitle2" color="text.secondary">
          Deploy Config Version
        </Typography>
        <CloseOutlinedIcon
          sx={{ cursor: "pointer", height: "18px", width: "18px" }}
          onClick={onClose}
        />
      </Stack>
      <Divider sx={{ mt: 2 }} />
      <Scrollbar>
        <Box sx={{ m: 2 }}>
          {loading &&
            versionData.map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width={250}
                height={68}
                sx={{ borderRadius: 1, padding: 1 }}
              />
            ))}

          {!loading && (
            <>
              {!isEmpty(draftVersion) && (
                <>
                  <Box
                    className={
                      isSelected[draftVersion.id]
                        ? classes.selected
                        : classes.notSelected
                    }
                    onClick={() => handleSelect(draftVersion)}
                    sx={{
                      width: "100%",
                      borderRadius: 1,
                      padding: 1,
                      // mb: 2,
                      // bgcolor: "background.neutral"
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={1.5}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="subtitle2">
                        {draftVersion.updated_at || "-"}
                      </Typography>
                      <Label color="secondary">{draftVersion.status}</Label>
                    </Stack>
                  </Box>

                  {isEdit && (
                    <Box sx={{ mt: 1 }}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => setOpenAppDeployDialog(true)}
                        >
                          Deploy
                        </Button>
                      </Stack>

                      <Divider sx={{ mt: 1, mb: 2, borderStyle: "dashed" }} />
                    </Box>
                  )}
                </>
              )}
              {otherVersion.map((version) => (
                <Box
                  key={version.id}
                  className={
                    isSelected[version.id]
                      ? classes.selected
                      : classes.notSelected
                  }
                  onClick={() => handleSelect(version)}
                  sx={{
                    // mt: 2,
                    width: "100%",
                    borderRadius: 1,
                    padding: 1,
                    // bgcolor: "background.neutral"
                  }}
                >
                  <Stack
                    direction={isEdit ? "row" : "column"}
                    justifyContent="space-between"
                  >
                    <Stack
                      direction="row"
                      spacing={0.5}
                      sx={{ mt: 0.5 }}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="subtitle2">
                        {version.updated_at}
                      </Typography>
                      <Label color={StatusEnum[version.status] as LabelColor}>
                        {version.status}
                      </Label>
                    </Stack>
                    {isEdit && (
                      <>
                        <IconButton size="small" onClick={handleClick}>
                          <MoreVertOutlinedIcon
                            sx={{ width: 20, height: 20 }}
                          />
                        </IconButton>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                        >
                          <MenuItem
                            key="restore"
                            onClick={() => handleRestore(version)}
                          >
                            <ListItemIcon sx={{ mr: 1 }}>
                              <ReplyIcon
                                sx={{ width: "22px", height: "22px" }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primaryTypographyProps={{ variant: "body2" }}
                            >
                              Restore
                            </ListItemText>
                          </MenuItem>
                          <Divider />
                          <MenuItem
                            key="delete"
                            onClick={() => {
                              handleSelect(version);
                              handleClose();
                              setOpenConfirm(true);
                            }}
                            sx={{ color: "error.main" }}
                          >
                            <ListItemIcon sx={{ mr: 1 }}>
                              <SvgIconStyle
                                src={DeleteIcon}
                                sx={{
                                  width: 22,
                                  height: 22,
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Delete"
                              primaryTypographyProps={{ variant: "body2" }}
                            />
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </Stack>
                  <Typography variant="body2" color="text.secondary">
                    {version.remark}
                  </Typography>
                </Box>
              ))}
            </>
          )}
        </Box>
      </Scrollbar>

      {openAppDeployDialog && (
        <AppDeployDialog
          isOpen={openAppDeployDialog}
          onClose={() => {
            setOpenAppDeployDialog(false);
          }}
        />
      )}

      {openVersionRestoreDialog && (
        <VersionRestoreDialog
          isOpen={openVersionRestoreDialog}
          onClose={() => {
            setOpenVersionRestoreDialog(false);
          }}
          versionInfo={versionDetail}
        />
      )}

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content={
          <>
            Are you sure to delete{" "}
            <strong> {selectedVersionInfo.status} </strong> version item?
          </>
        }
        action={
          <LoadingButton
            color="error"
            sx={{ width: "80px" }}
            onClick={() => {
              handleDelete();
              setOpenConfirm(false);
            }}
            loading={delLoading}
            // loadingPosition="start" // must use with startIcon
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </Paper>
  );
};

export default DeployConfigVersionPanel;

import { TOKEN_FLAG } from "@/config";
import axios from "axios";

const { config } = JSON.parse(localStorage.getItem("redux-common") || "{}");
const { common: { BASE_URL = "", SSO_LOGOUT_URL = "" } = {} } = JSON.parse(
  config || "{}"
);

const IframeHttp = axios.create({
  baseURL: BASE_URL,
  // baseURL: "http://localhost:3000",
  timeout: 5000,
});

// request
IframeHttp.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(TOKEN_FLAG);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

IframeHttp.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      localStorage.clear();
      window.location.replace(SSO_LOGOUT_URL);
    }
    return Promise.reject(error);
  }
);

export default IframeHttp;

import {
  TransformStep,
  TransformArguments,
} from "@/@types/project/mlPipeline/SageMaker/pipeline-transform";
import { parseUri, checkUseParam } from "./helper-functions";

// ----------------------------------------------------------------------

export default function parseTransformStep(
  index: number,
  pipelineStep: any,
  stepPairs: [string, string, string][]
) {
  const args = pipelineStep.Arguments as TransformArguments;
  const step = {
    id: String(index),
    type: "Transform",
    name: pipelineStep.Name,
    modelName: parseUri(
      args.ModelName,
      pipelineStep.Name,
      stepPairs,
      "ModelName"
    ),
    modelNameUseParam: checkUseParam(args.ModelName),
    dataSource: parseUri(args.TransformInput.DataSource.S3DataSource.S3Uri),
    dataSourceUseParam: checkUseParam(
      args.TransformInput.DataSource.S3DataSource.S3Uri
    ),
    outputPath: parseUri(args.TransformOutput.S3OutputPath),
    outputPathUseParam: checkUseParam(args.TransformOutput.S3OutputPath),
    instanceType: parseUri(args.TransformResources.InstanceType),
    instanceTypeUseParam: checkUseParam(args.TransformResources.InstanceType),
    instanceCount: parseUri(args.TransformResources.InstanceCount),
    instanceCountUseParam: checkUseParam(args.TransformResources.InstanceCount),
    nodeX: 220 * index,
    nodeY: 0,
    tags: [],
    properties: [],
    stepType: "Transform",
  } as TransformStep;
  return step;
}

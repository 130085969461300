import Page from "@/components/project/Page";
import CredentialTable from "./components/CredentialTable";

const Credential = () => {
  const getLinks = () => {
    return [
      {
        name: "Connection",
      },
      {
        name: "My Credentials",
      },
    ];
  };

  return (
    <Page
      title={`My Credentials | OmniML`}
      heading="My Credentials"
      links={getLinks()}
      isPrivate
    >
      {/* <MlCard title="Basic Information">
        <MlCardList list={CredentialInfoList}/>
      </MlCard> */}
      <CredentialTable />
    </Page>
  );
};

export default Credential;

/* eslint-disable react-hooks/exhaustive-deps */
import Page from "@/components/project/Page";
import { PATH_DASHBOARD } from "@/routes/paths";
import { useLocation, useParams } from "react-router";
import ProjectCreateOrEditForm from "./components/projectCreateOrEdit/ProjectCreateOrEditForm";
import { useEffect } from "react";
import { RootState, dispatch } from "@/redux/store";
import {
  getOrganizationDetailData,
  getProjectDetailData,
} from "@/redux/project/administration/thunks";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { resetAdministrationData } from "@/redux/project/administration/slice";

// two entries
// admin-organizaiton、projectGovernance-manage
const ProjectAddUser = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const isEdit = pathname.includes("projectEdit");

  const { orgId, projectId } = useParams(); // from organizaiton page， create need orgId from url

  const isFromPgManageSiderbar = pathname.includes("/projectGovernance/manage"); // only can edit, can't add project

  const {
    organizationDetailData: { data: orgData },
    projectDetailData: { data },
  } = useSelector((state: RootState) => state.administration);

  const {
    userDetail: {
      project_id: manageProjectId,
      organization_id: curOrgIdOfAdmin,
    },
  } = useSelector((state: RootState) => state.common);

  const curProjectId =
    isFromPgManageSiderbar && manageProjectId ? manageProjectId : projectId;

  const curOrgIdInDetail =
    isFromPgManageSiderbar && manageProjectId ? curOrgIdOfAdmin : orgId;

  const getLinks = () => {
    if (isFromPgManageSiderbar) {
      return [
        {
          name: "Project Governance",
        },
        {
          name: "Manage",
        },
        {
          name: data?.name || "",
          href: PATH_DASHBOARD.projectGovernance.manage,
        },
        {
          name: "Edit Project",
        },
      ];
    }

    return [
      {
        name: "Administration",
      },
      {
        name: "Organization",
        href: PATH_DASHBOARD.administration.organization,
      },
      {
        name: orgData?.name || "",
        href: `${PATH_DASHBOARD.administration.organization}/${orgId}`,
      },
      ...(isEdit
        ? [
            {
              name: data?.name || "",
              href: `${PATH_DASHBOARD.administration.organization}/${orgId}/${projectId}`,
            },
          ]
        : []),
      {
        name: `${isEdit ? "Edit" : "Create"} Project`,
      },
    ];
  };

  useEffect(() => {
    if (isEdit) {
      dispatch(
        getProjectDetailData({
          id: curProjectId,
          organization_id: curOrgIdInDetail,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      );

      if (!isFromPgManageSiderbar) {
        dispatch(
          getOrganizationDetailData({
            id: curOrgIdInDetail,
            alertCallback: enqueueSnackbar,
            pathname,
          })
        );
      }
    } else {
      // from org, create need org_name
      if (!isFromPgManageSiderbar) {
        dispatch(
          getOrganizationDetailData({
            id: curOrgIdInDetail,
            alertCallback: enqueueSnackbar,
            pathname,
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetAdministrationData("projectDetailData"));
    };
  }, []);

  return (
    <Page
      title={`${`${isEdit ? "Edit" : "Create"} Project`} | OmniML`}
      heading={`${isEdit ? "Edit" : "Create"} Project`}
      links={getLinks()}
    >
      <ProjectCreateOrEditForm
        curOrgIdOfProject={curOrgIdInDetail}
        curProjectId={curProjectId}
      />
    </Page>
  );
};

export default ProjectAddUser;

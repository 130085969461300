import request from "@/utils/project/request";

// 1. notebook template
export const getNotebookTemplateTableList = (params) => {
  return request.get(`/experiment/notebook_template`, {
    ...params,
    is_active: 1,
  });
};

export const getNotebookTemplateDetailService = (id) => {
  return request.get(`/experiment/notebook_template/${id}`);
};

export const addNotebookTemplateService = (params) => {
  return request.post(`/experiment/notebook_template`, { payload: params });
};

export const editNotebookTemplateService = (id, params) => {
  return request.put(`/experiment/notebook_template/${id}`, {
    payload: params,
  });
};

export const delNotebookTemplateService = (id) => {
  return request.put(`/experiment/notebook_template/${id}`, {
    payload: { is_active: 0 },
  });
};

// notebook template version
export const getNotebookTemplateVersionDataService = (params) => {
  return request.get(`/experiment/notebook_template_version`, {
    ...params,
    is_active: 1,
  });
};

export const getNotebookTemplateVersionDetailService = (id) => {
  return request.get(`/experiment/notebook_template_version/${id}`);
};

export const addNotebookTemplateVersionService = (params) => {
  return request.post(`/experiment/notebook_template_version`, {
    payload: params,
  });
};

// 2. pipeline template
export const getPipelineTemplateTableList = (params) => {
  return request.get(`/pipeline/mlpipeline_template`, {
    ...params,
    is_active: 1,
  });
};

export const getPipelineStorageService = (params) => {
  return request.get(`/pipeline/mlpipeline_storage`, params);
};

export const addPipelineStorageService = (params) => {
  return request.post(`/pipeline/mlpipeline_storage`, { payload: params });
};

export const getPipelineTemplateDetailService = (id) => {
  return request.get(`/pipeline/mlpipeline_template/${id}`);
};

export const addPipelineTemplateService = (params) => {
  return request.post(`/pipeline/mlpipeline_template`, { payload: params });
};

export const editPipelineTemplateService = (id, params) => {
  return request.put(`/pipeline/mlpipeline_template/${id}`, {
    payload: params,
  });
};

export const delPipelineTemplateService = (id) => {
  return request.put(`/pipeline/mlpipeline_template/${id}`, {
    payload: { is_active: 0 },
  });
};

// pipeline template version
export const getPipelineTemplateVersionDataService = (params) => {
  return request.get(`/pipeline/mlpipeline_template_version`, {
    ...params,
    is_active: 1,
  });
};

export const getPipelineTemplateVersionDetailService = (id) => {
  return request.get(`/pipeline/mlpipeline_template_version/${id}`);
};

export const addPipelineTemplateVersionService = (params) => {
  return request.post(`/pipeline/mlpipeline_template_version`, {
    payload: params,
  });
};

// 3. project role
export const getProjectRoleTableList = (params) => {
  return request.get(`/govern/role`, { ...params, is_active: 1 });
};

export const getProjectRoleDetailService = (id) => {
  return request.get(`/govern/role/${id}`);
};

// notebook image
export const uploadNotebookFile = (params) => {
  return request.post(`/experiment/file_upload`, params);
};

export const deleteNotebookFile = (s3_key) => {
  return request.delete(`/experiment/file_upload/${s3_key}`);
};

export const getNotebookFile = (params) => {
  return request.post(`/govern/s3_presigned_url`, params);
};

// pipeline image
export const uploadPipelineFile = (params) => {
  return request.post(`/pipeline/file_upload`, params);
};

export const deletePipelineFile = (s3_key) => {
  return request.delete(`/pipeline/file_upload/${s3_key}`);
};

export const getPipelineFile = (params) => {
  return request.post(`/govern/s3_presigned_url`, params);
};

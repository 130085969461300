// components
import SvgIconStyle from "@/components/project/SvgIconStyle";

// ----------------------------------------------------------------------
/* 
const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

const ICONS = {
  forecast: getIcon("ic_forecast"),
  detection: getIcon("ic_detection"),
  tracking: getIcon("ic_tracking"),
};

const sidebarConfig = [
  {
    title: "forecast",
    icon: ICONS.forecast,
    children: [
      {
        pipelineName: "Inventory trend prediction using AI",
        coverImgSrc: "/static/mock-images/pipeline-covers/cover_11.jpg",
      },
    ],
  },
  {
    title: "object detection",
    icon: ICONS.detection,
    children: [
      {
        pipelineName: "Bird detection using AI (computer vision)",
        coverImgSrc: "/static/mock-images/pipeline-covers/cover_5.jpg",
      },
    ],
  },
  {
    title: "object tracking",
    icon: ICONS.tracking,
    children: [
      {
        pipelineName: "Pedestrian re-identification using AI",
        coverImgSrc: "/static/mock-images/pipeline-covers/cover_9.gif",
      },
    ],
  },
]; */

const sidebarConfig = [
  {
    id: 1,
    name: "template1",
    type: "AWS SageMaker",
    scenario: "Market",
    executions: 12,
    creator: "zhangsan@test.com",
    createAt: "2022-05-11 04:50PM",
    lastRun: "25 min ago",
    lastStatus: "Succeeded",
    description: "This is a standard pipeline template",
    pipelineGraphName: "re-identification",
  },
  {
    id: 2,
    name: "template2",
    type: "AWS SageMaker",
    scenario: "Ticket",
    executions: 5,
    creator: "liuchunfeng@test.com",
    createAt: "2022-03-11 04:50PM",
    lastRun: "1 hour 10 min ago",
    lastStatus: "Failed",
    description: "This is a standard pipeline template",
    pipelineGraphName: "trend prediction",
  },
  {
    id: 3,
    name: "template3",
    type: "AWS SageMaker",
    scenario: "Flight",
    executions: 23,
    creator: "zhangsan@test.com",
    createAt: "2023-01-11 04:50PM",
    lastRun: "2 day ago",
    lastStatus: "Executing",
    description: "This is a standard pipeline template",
    pipelineGraphName: "bird detection",
  },
];

export const kubeflowSidebarConfig = [
  {
    id: 1,
    name: "template1",
    type: "Kubeflow",
    scenario: "Market",
    // executions: 12,
    // creator: 'zhangsan@test.com',
    // createAt: '2022-05-11 04:50PM',
    // lastRun: '25 min ago',
    // lastStatus: 'Succeeded',
    description: "This is a standard kubeflow pipeline template",
    // pipelineGraphName: "re-identification",
  },
];

export default sidebarConfig;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Drawer,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Scrollbar from "@/components/project/Scrollbar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect } from "react";
import { RootState, dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router";
import { getPipelineTemplateDetail } from "@/redux/project/template/thunks";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

interface IPipelineTemplateDetailDrawer {
  isOpen: boolean;
  onClose: VoidFunction;
  template: Record<string, any>;
  clearRowData: VoidFunction;
}

const PipelineTemplateDetailDrawer = ({
  isOpen,
  onClose,
  template,
  clearRowData,
}: IPipelineTemplateDetailDrawer) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const {
    pipelineTemplateDetail: { data, loading },
  } = useSelector((state: RootState) => state.template);

  const getOrderedDetail = (data) => {
    return {
      Scenario: {
        value: data?.scenario || "-",
        loading,
        fullRow: false,
      },
      Type: {
        value: data?.mlpipeline_type || "-",
        loading,
        fullRow: false,
      },
      Description: {
        value: data?.description || "-",
        loading,
        fullRow: true,
      },
      "Create At": {
        value: data?.created_at || "-",
        loading,
        fullRow: true,
      },
    };
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(
        getPipelineTemplateDetail({
          id: template.id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
    }
  }, [isOpen]);

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        onClose();
        clearRowData();
      }}
      anchor="right"
      sx={{
        "& .MuiPaper-root": {
          width: "320px",
          bgcolor: "background.default",
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          py: 2.5,
          px: 4,
          bgcolor: "background.paper",
        }}
      >
        <Typography variant="subtitle1">Info</Typography>
        {/* <Icon></Icon> */}
        <CloseOutlinedIcon sx={{ cursor: "pointer" }} onClick={onClose} />
      </Stack>
      <Scrollbar sx={{ maxHeight: "800px" }}>
        <Typography variant="h6" align="center" paddingY={2}>
          {template.name}
        </Typography>

        <Stack sx={{ px: 4 }}>
          <Divider />
          <Accordion
            defaultExpanded
            elevation={0}
            sx={{ width: "100% !important", boxShadow: "none !important" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                padding: 0,
                minHeight: "64px",
                "& .MuiAccordionSummary-content": {
                  margin: "0 !important",
                },
              }}
            >
              <Typography variant="subtitle1" paddingY={2}>
                Properties
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0 5px" }}>
              <Grid container columnSpacing={1}>
                {Object.entries(getOrderedDetail(data)).map(
                  ([key, valueObj]) => (
                    <>
                      <Grid
                        item
                        xs={valueObj.fullRow ? 12 : 6}
                        lg={valueObj.fullRow ? 12 : 6}
                      >
                        <Typography variant="subtitle2" paddingY={1}>
                          {key}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={valueObj.fullRow ? 12 : 6}
                        lg={valueObj.fullRow ? 12 : 6}
                      >
                        {valueObj.loading ? (
                          <Box
                            sx={{
                              width: "100%",
                              height: "100%",
                              py: 0.3,
                            }}
                          >
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height="100%"
                              sx={{ borderRadius: 2, mt: 1, mb: 1 }}
                            />
                          </Box>
                        ) : typeof valueObj.value === "string" ||
                          typeof valueObj.value === "number" ? (
                          <Typography
                            variant="body2"
                            paddingY={1}
                            sx={{ wordBreak: "break-word" }}
                          >
                            {valueObj.value}
                          </Typography>
                        ) : (
                          valueObj.value
                        )}
                      </Grid>
                    </>
                  )
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};

export default PipelineTemplateDetailDrawer;

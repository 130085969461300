import { v4 as uuidv4 } from "uuid";
import { Schema } from "../slices/slice";
import { parseStep } from "./step-parsers";
import { dispatch } from "@/redux/store";
import { PipelineStruct } from "@/@types/project/mlPipeline/SageMaker/pipeline";
import { stopLoadingPipeline } from "@/redux/project/automation/pipeline";

export const parseFromSmPipeline = async (pipelineDef: string) => {
  const schema: Schema = {
    name: "",
    tags: [],
    relationships: [],
    edgeEntities: [],
    pipelineRoleArn: "",
    pipelineSteps: [],
  };

  const stepPairs: [string, string, string][] = [];

  return Promise.resolve(JSON.parse(pipelineDef))
    .then(async (def: PipelineStruct) => {
      let index = 0;
      for (const step of def.Steps) {
        await parseStep(schema, step, index, stepPairs);
        index += 1;
      }
      // console.log(JSON.stringify(def));
      // def.Steps.forEach(async (step, index) => {
      //   await parseStep(schema, step, index, stepPairs);
      // });

      dispatch(stopLoadingPipeline());

      const metaKeys = Object.keys(def.Metadata);

      if (metaKeys.includes("nodes") && metaKeys.includes("edges")) {
        const nodes = JSON.parse(def.Metadata.nodes);
        const edges = JSON.parse(def.Metadata.edges);
        addEdgesAndNodePosFromCustomData(schema, nodes, edges, stepPairs);
      } else if (metaKeys.includes("nodes") && !metaKeys.includes("edges")) {
        const nodes = JSON.parse(def.Metadata.nodes);
        addEdgesAndNodePosFromCustomData(schema, nodes, null, stepPairs);
      } else {
        addEdgesAndNodePosFromGenData(schema, stepPairs);
      }
      // console.log(schema);
      return schema;
    })
    .catch((error: Error) => {
      // console.log(error);
      dispatch(stopLoadingPipeline());
      throw error;
    });
};

export const addEdgesAndNodePosFromCustomData = (
  schema: Schema,
  nodes: any,
  edges: any,
  stepPairs: [string, string, string][]
) => {
  const nodeNames = Object.keys(nodes);
  schema.pipelineSteps.forEach((step) => {
    if (nodeNames.includes(step.name)) {
      step.id = nodes[step.name][0];
      step.nodeX = nodes[step.name][1];
      step.nodeY = nodes[step.name][2];
    }
  });

  const tmpNodeMap = new Map<string, string>();
  nodeNames.forEach((nodeName) => tmpNodeMap.set(nodes[nodeName][0], nodeName));

  const tmpNodeObj = Object.fromEntries(tmpNodeMap);

  if (edges) {
    const edgeIds = Object.keys(edges);
    edgeIds.forEach((edgeId, index) => {
      const nodeName1 = tmpNodeObj[edges[edgeId][0]];
      const nodeName2 = tmpNodeObj[edges[edgeId][2]];
      let prop = "";
      for (const stepPair of stepPairs) {
        if (stepPair[0] === nodeName1 && stepPair[1] === nodeName2) {
          prop = stepPair[2];
          break;
        }
      }
      schema.edgeEntities.push({
        id: edgeId,
        relationship: 1,
        sourceEntityId: edges[edgeId][0],
        sourcePortId: edges[edgeId][1],
        targetEntityId: edges[edgeId][2],
        targetPortId: edges[edgeId][3],
        property: prop,
        properties: [],
      });

      schema.relationships.push({
        id: index,
        isNew: false,
        value: "",
      });
    });
  }
};

const addEdgesAndNodePosFromGenData = (
  schema: Schema,
  stepPairs: [string, string, string][]
) => {
  if (stepPairs.length === 0) return;

  // Adjust node positions
  // Step 0: create a grid layout
  // Step 1: find start node, put it on (a, b)
  // Step 2: find all (x) nodes connected to start node, put it on (a +/- floor(0.5 * x), b + 1)
  // Step 3: for each node in found in last step, repeat
  let stepGridPositions = new Map<string, number[]>();
  let startNodes = new Set<string>();
  let removedNodes = new Set<string>();
  stepPairs.forEach(([startNode, endNode, _]) => {
    removedNodes.add(endNode);
    if (!removedNodes.has(startNode)) startNodes.add(startNode);
    if (startNodes.has(endNode)) startNodes.delete(endNode);
  });

  let startNodeArray = Array.from(startNodes);
  startNodeArray.forEach((node, index) => {
    stepGridPositions.set(node, [
      Math.pow(-1, index + 1) *
        Math.ceil(0.5 * (index + ((startNodeArray.length + 1) % 2))),
      0,
    ]);
  });

  let maxColNum = 0;
  // while (startNodeArray.length > 0) {
  //   let startNodes = new Set<string>();

  //   startNodeArray.forEach((node, _) => {
  //     const foundNodes = stepPairs.filter(
  //       ([startNode, __, _]) => startNode === node
  //     );

  //     // Rm nodes which were already assigned grid pos
  //     foundNodes.forEach(([__, endNode, _], index) => {
  //       if (stepGridPositions.has(endNode)) foundNodes.splice(index, 1);
  //     });

  //     foundNodes.forEach(([__, endNode, _], index) => {
  //       startNodes.add(endNode);
  //       let nodeGrid = stepGridPositions.get(node);
  //       const startRowId = nodeGrid !== undefined ? nodeGrid[0] : 0;
  //       const startColId = nodeGrid !== undefined ? nodeGrid[1] : 0;
  //       const foundNodesNum = foundNodes.length;
  //       if (!stepGridPositions.has(endNode)) {
  //         stepGridPositions.set(endNode, [
  //           startRowId +
  //             Math.pow(-1, index + 1) *
  //               Math.ceil(0.5 * (index + ((foundNodesNum + 1) % 2))),
  //           startColId + 1,
  //         ]);
  //       }

  //       if (startColId + 2 > maxColNum) maxColNum = startColId + 2;
  //     });
  //   });
  //   startNodeArray = Array.from(startNodes);
  // }

  while (startNodeArray.length > 0) {
    let startNodes = new Set<string>();

    for (let ii = 0; ii < startNodeArray.length; ii++) {
      let node = startNodeArray[ii];

      // startNodeArray.forEach((node, _) => {
      const foundNodes = stepPairs.filter(
        ([startNode, __, _]) => startNode === node
      );

      // Rm nodes which were already assigned grid pos
      foundNodes.forEach(([__, endNode, _], index) => {
        if (stepGridPositions.has(endNode)) foundNodes.splice(index, 1);
      });

      for (let jj = 0; jj < foundNodes.length; jj++) {
        let endNode = foundNodes[jj][1];

        startNodes.add(endNode);
        let nodeGrid = stepGridPositions.get(node);
        const startRowId = nodeGrid !== undefined ? nodeGrid[0] : 0;
        const startColId = nodeGrid !== undefined ? nodeGrid[1] : 0;
        const foundNodesNum = foundNodes.length;
        if (!stepGridPositions.has(endNode)) {
          stepGridPositions.set(endNode, [
            startRowId +
              Math.pow(-1, jj + 1) *
                Math.ceil(0.5 * (jj + ((foundNodesNum + 1) % 2))),
            startColId + 1,
          ]);
        }

        if (startColId + 2 > maxColNum) maxColNum = startColId + 2;
      }
      // foundNodes.forEach(([__, endNode, _], index) => {
      //   startNodes.add(endNode);
      //   let nodeGrid = stepGridPositions.get(node);
      //   const startRowId = nodeGrid !== undefined ? nodeGrid[0] : 0;
      //   const startColId = nodeGrid !== undefined ? nodeGrid[1] : 0;
      //   const foundNodesNum = foundNodes.length;
      //   if (!stepGridPositions.has(endNode)) {
      //     stepGridPositions.set(endNode, [
      //       startRowId +
      //         Math.pow(-1, index + 1) *
      //           Math.ceil(0.5 * (index + ((foundNodesNum + 1) % 2))),
      //       startColId + 1,
      //     ]);
      //   }

      //   if (startColId + 2 > maxColNum) maxColNum = startColId + 2;
      // });
    }
    // });
    startNodeArray = Array.from(startNodes);
  }

  // Calculate column space
  let xcoords = Array(maxColNum - 1);
  Array.from(stepGridPositions.keys()).forEach((stepName) => {
    const gridCoords = stepGridPositions.get(stepName);
    if (gridCoords && gridCoords[1] < xcoords.length) {
      const newSpace = stepName.length * 12;
      if (
        xcoords[gridCoords[1]] === undefined ||
        xcoords[gridCoords[1]] < newSpace
      )
        xcoords[gridCoords[1]] = newSpace;
    }
  });
  // console.log(xcoords);

  stepPairs.forEach(([startNode, endNode, connectionProp], index) => {
    const src = schema.pipelineSteps.find((step) => step.name === startNode);
    // src && console.log(coords[src.name]);
    const end = schema.pipelineSteps.find((step) => step.name === endNode);
    if (src !== undefined && end !== undefined) {
      // Reassign node positions
      const nodeXY1 = stepGridPositions.get(src.name);
      if (nodeXY1 !== undefined) {
        src.nodeX =
          nodeXY1[1] > 0
            ? xcoords.slice(0, nodeXY1[1]).reduce((a, b) => a + b) +
              nodeXY1[1] * 60
            : 0;
        src.nodeY = nodeXY1[0] * 120;
      }

      const nodeXY2 = stepGridPositions.get(end.name);
      if (nodeXY2 !== undefined) {
        // end.nodeX = nodeXY2[1] * end.name.length * 25;
        end.nodeX =
          nodeXY2[1] > 0
            ? xcoords.slice(0, nodeXY2[1]).reduce((a, b) => a + b) +
              nodeXY2[1] * 60
            : 0;
        end.nodeY = nodeXY2[0] * 120;
      }

      const srcPortId =
        src.nodeX < end.nodeX
          ? "right"
          : src.nodeX > end.nodeX
          ? "left"
          : src.nodeY > end.nodeY
          ? "top"
          : "bottom";
      const endPortId =
        src.nodeX < end.nodeX
          ? "left"
          : src.nodeX > end.nodeX
          ? "right"
          : src.nodeY > end.nodeY
          ? "bottom"
          : "top";

      // Add edges
      schema.edgeEntities.push({
        id: uuidv4().slice(0, 4),
        relationship: 1,
        property: connectionProp,
        properties: [],
        sourceEntityId: src.id,
        sourcePortId: srcPortId,
        targetEntityId: end.id,
        targetPortId: endPortId,
      });
      schema.relationships.push({
        id: index,
        isNew: false,
        value: "",
      });
    }
  });
};

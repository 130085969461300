import { Icon } from "@iconify/react";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useState } from "react";
// material
import { Box, Stack, Collapse, Divider } from "@mui/material";
// @types
import { RootState, useDispatch, useSelector } from "@/redux/store";
import { ConditionStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-condition";
//
import { SchemaNodeEntity } from "../../../graph/slices/slice";
import { updateEntityName } from "../../../graph/slices/slice";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { StyledTextField } from "../../components/PipelineComponents";
import Conditions from "./Conditions";

// ----------------------------------------------------------------------

export const ConditionTypes = [
  { code: "A", label: "=", value: "Equals" },
  { code: "B", label: ">", value: "GreaterThan" },
  { code: "C", label: ">=", value: "GreaterThanOrEqualTo" },
  { code: "D", label: "<", value: "LessThan" },
  { code: "E", label: "<=", value: "LessThanOrEqualTo" },
  { code: "F", label: "In", value: "In" },
  { code: "G", label: "Not", value: "Not" },
  { code: "H", label: "Or", value: "Or" },
];

type Props = {
  entity: SchemaNodeEntity;
};

export default function ConditionStepEntry({ entity }: Props) {
  const dispatch = useDispatch();
  const [showStepConfig, setShowStepConfig] = useState(true);

  const currentStep = useSelector((state: RootState) => {
    let step: ConditionStep | undefined =
      state.schema.schema.pipelineSteps.find((step) => {
        return step.id === entity.id;
      }) as ConditionStep;
    return step ? step : null;
  });

  return (
    <>
      {entity && (
        <Box>
          <CollapseButtonStyle
            fullWidth
            color="inherit"
            onClick={() => setShowStepConfig((prev) => !prev)}
            endIcon={
              <Icon
                icon={
                  showStepConfig ? arrowIosDownwardFill : arrowIosForwardFill
                }
                width={16}
                height={16}
              />
            }
          >
            Configuration
          </CollapseButtonStyle>

          <Collapse in={showStepConfig}>
            <Stack spacing={2} sx={{ mx: 2, mt: 1, mb: 2 }}>
              <StyledTextField
                label="Step name"
                defaultValue={entity.name}
                onChange={(event: any) => {
                  const newValue = event.target.value;
                  dispatch(updateEntityName({ name: newValue }));
                }}
              />
            </Stack>
          </Collapse>

          <Divider />

          <Conditions currentStep={currentStep} />

          <Divider sx={{ mb: 1 }} />
        </Box>
      )}
    </>
  );
}

import request from "@/utils/project/request";

export const getModelPackageDatas = (params) => {
  return request.get(`/modelregistry/modelpackage`, params);
};

export const getModelPackageVersionDatas = (params) => {
  return request.get(`/modelregistry/modelpackage_version`, params);
};

export const getModelPackageDescribeDatas = (id) => {
  return request.get(`/modelregistry/modelpackage/${id}`);
};

export const getModelPackageVersionDescribeDatas = (id) => {
  return request.get(`/modelregistry/modelpackage_version/${id}`);
};

export const getConnectionDatasById = (id) => {
  return request.get(`/govern/connection/${id}`);
};

export const getModelPackageTagDatas = (params) => {
  return request.get(`/modelregistry/rel_tag_modelpackage`, params);
};

export const getTagDatas = (params) => {
  return request.get(`/govern/tag`, params);
};

export const getTagDataById = (id) => {
  return request.get(`/govern/tag/${id}`);
};

export const getConnectionDatas = (params) => {
  return request.get(`/govern/connection`, params);
};

export const createModelPackage = (params) => {
  return request.post(`/modelregistry/modelpackage`, params);
};

export const createTag = (params) => {
  return request.post(`/govern/tag`, params);
};

export const createRelTagModelPackage = (params) => {
  return request.post(`/modelregistry/rel_tag_modelpackage`, params);
};

export const createSynchronization = (params) => {
  return request.post(`/modelregistry/synchronization`, params);
};

export const createSynchronizationJob = (params) => {
  return request.post(`/modelregistry/synchronization_job`, params);
};

export const getProjectDatas = (params) => {
  return request.get(`/govern/project`, params);
};

export const createRelProjectModelPackage = (params) => {
  return request.post(`/modelregistry/rel_project_modelpackage`, params);
};

export const getSynchronizationDatas = (params) => {
  return request.get(`/modelregistry/synchronization`, params);
};

export const getSynchronizationJobDatas = (params) => {
  return request.get(`/modelregistry/synchronization_job`, params);
};

export const updateModelPackage = (id, params) => {
  return request.put(`/modelregistry/modelpackage/${id}`, params);
};

export const updateSynchronization = (id, params) => {
  return request.put(`/modelregistry/synchronization/${id}`, params);
};

export const updateSynchronizationJob = (id, params) => {
  return request.put(`/modelregistry/synchronization_job/${id}`, params);
};

export const deleteRelTagModelPackage = (id, params) => {
  return request.put(`/modelregistry/rel_tag_modelpackage/${id}`, params);
};

export const updateModelPackageVersion = (id, params) => {
  return request.put(`/modelregistry/modelpackage_version/${id}`, params);
};

export const getStatisticDatas = (params) => {
  return request.get(`/modelregistry/modelregistry_statistic`, params);
};

export const getCredentialDatas = (params) => {
  return request.get(`/govern/credential`, params);
};

export const getRelUserModelPackage = (params) => {
  return request.get(`/modelregistry/rel_user_modelpackage`, params);
};

export const getModelPackageVersionAttribute = (params) => {
  return request.get(`/modelregistry/modelpackage_version_attribute`, params);
};

export const uploadFile = (params) => {
  return request.post(`/modelregistry/s3dataset`, params);
};

export const createModelPackageVersion = (params) => {
  return request.post(`/modelregistry/modelpackage_version`, params);
};

export const getHashToken = (id) => {
  return request.get(`/govern/hash_token/${id}`);
};

export const getSynchronizationJobExecutionVersion = (params) => {
  return request.get(
    `/modelregistry/rel_synchronization_job_execution_modelpackage_version`,
    params
  );
};

export const getSynchronizationJobExecutionById = (id) => {
  return request.get(`/modelregistry/synchronization_job_execution/${id}`);
};

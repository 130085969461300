import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useDispatch } from "react-redux";
// material
import { Stack, Collapse, MenuItem } from "@mui/material";
import { CollapseButtonStyle } from "../../../../SettingSidebar";
import { useEffect, useState } from "react";
import { MlPipelineTextfield } from "../../../../components/PipelineComponents";
import { updateOneKeyofStep } from "@/pages/project/automation/MlPipeline/graph/slices/slice";
import { FeatureSetDownloadStep } from "@/@types/project/mlPipeline/Kubeflow/pipeline-featureset-download";

const FeatureSetDownloadProcessor = ({
  currentStep,
  isEdit,
}: {
  currentStep: FeatureSetDownloadStep | null;
  isEdit: boolean;
}) => {
  const dispatch = useDispatch();
  const [showProcessor, setShowProcessor] = useState(true);
  const initialValues = currentStep?.processor || {
    image: "",
    instanceType: "",
    count: "0",
  };

  const ParamSchema = Yup.object().shape({
    image: Yup.string().required("Image is required"),
    instanceType: Yup.string().required("Instance Type is required"),
    count: Yup.number()
      .typeError("Instance Count must be number type")
      .integer("Instance Count must be integer")
      .required("Instance Count is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ParamSchema,
    onSubmit: () => {},
  });

  const submit = (fieldName, value) => {
    try {
      dispatch(
        updateOneKeyofStep({
          key: "processor",
          data: {
            [fieldName]: value,
          },
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  // same node type，but different node data
  useEffect(() => {
    formik.resetForm();
    formik.setValues(initialValues);
  }, [currentStep?.id]);

  return (
    <>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={() => setShowProcessor((prev) => !prev)}
        endIcon={
          <Icon
            icon={showProcessor ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
      >
        PROCESSOR
      </CollapseButtonStyle>

      <Collapse in={showProcessor}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off">
            <Stack spacing={2} sx={{ mx: 2, my: 1, mb: 1.5 }}>
              <MlPipelineTextfield
                label={"Image"}
                field={"image"}
                disabled={isEdit}
                title={formik.values.image}
                formik={formik}
                submitThisValuefunc={(value) => {
                  submit("image", value);
                }}
              />

              <MlPipelineTextfield
                select
                label={"Instance Type"}
                field={"instanceType"}
                disabled={isEdit}
                formik={formik}
                submitThisValuefunc={(value) => submit("instanceType", value)}
              >
                <MenuItem value="CPU">CPU</MenuItem>
                <MenuItem value="GPU">GPU</MenuItem>
              </MlPipelineTextfield>

              <MlPipelineTextfield
                label={"Instance Count"}
                field={"count"}
                disabled={isEdit}
                formik={formik}
                submitThisValuefunc={(value) => {
                  submit("count", value);
                }}
              />
            </Stack>
          </Form>
        </FormikProvider>
      </Collapse>
    </>
  );
};

export default FeatureSetDownloadProcessor;

import { Icon } from "@iconify/react";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useState } from "react";
// material
import { Box, Stack, Divider, Collapse } from "@mui/material";
// @types
import { RootState, useDispatch, useSelector } from "@/redux/store";
import { ModelStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-model";
//
import Container from "./Container";
import ModelData from "./ModelData";
import { SchemaNodeEntity } from "../../../graph/slices/slice";
import { updateEntityName } from "../../../graph/slices/slice";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { StyledTextField } from "../../components/PipelineComponents";

// ----------------------------------------------------------------------

type Props = {
  entity: SchemaNodeEntity;
};

export default function ModelStepEntry({ entity }: Props) {
  const dispatch = useDispatch();
  const [showStepConfig, setShowStepConfig] = useState(true);

  const currentStep = useSelector((state: RootState) => {
    let step: ModelStep = state.schema.schema.pipelineSteps.find((step) => {
      return step.id === entity.id;
    }) as ModelStep;
    return step;
  });

  return (
    <>
      {entity && (
        <Box>
          <CollapseButtonStyle
            fullWidth
            color="inherit"
            onClick={() => setShowStepConfig((prev) => !prev)}
            endIcon={
              <Icon
                icon={
                  showStepConfig ? arrowIosDownwardFill : arrowIosForwardFill
                }
                width={16}
                height={16}
              />
            }
          >
            Configuration
          </CollapseButtonStyle>

          <Collapse in={showStepConfig}>
            <Stack spacing={2} sx={{ mx: 2, mt: 1, mb: 2 }}>
              <StyledTextField
                label="Step name"
                defaultValue={entity.name}
                onChange={(event: any) => {
                  const newValue = event.target.value;
                  dispatch(updateEntityName({ name: newValue }));
                }}
              />
            </Stack>
          </Collapse>

          <Divider />

          <Container currentStep={currentStep} />

          <Divider />

          <ModelData currentStep={currentStep} />

          <Divider sx={{ mb: 1 }} />
        </Box>
      )}
    </>
  );
}

import React from "react";
import { Field } from "formik";
import {
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { get } from "lodash";

interface IProps extends StandardTextFieldProps {
  name: string;
  label: string;
  formik: any;
  isHideEyeBtn?: boolean;
}

const MlPasswordField = ({
  name,
  label,
  formik,
  isHideEyeBtn = false,
  ...other
}: IProps) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { errors, touched } = formik;

  return (
    <Field
      name={name}
      type={showPassword ? "text" : "password"}
      label={label}
      as={TextField}
      size="small"
      fullWidth
      margin="dense"
      {...(!isHideEyeBtn
        ? {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }
        : {})}
      helperText={
        get(touched, name) && get(errors, name) ? get(errors, name) : " "
      }
      error={get(touched, name) && Boolean(get(errors, name))}
      FormHelperTextProps={{ sx: { minHeight: "18px", marginTop: "2px" } }}
      {...other}
    />
  );
};

export default MlPasswordField;

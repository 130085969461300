/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  InputAdornment,
  TablePagination,
  Card,
  MenuItem,
  Stack,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Box,
  Menu,
} from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Scrollbar from "@/components/project/Scrollbar";
import { extractKeys } from "@/utils/project/utils";
import Label from "@/components/project/Label";
import { useLocation, useNavigate } from "react-router";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import HpTunerTableActionCol from "./HpTunerTableActionCol";
import MlTableBodyContainer from "@/components/project/mlComponents/MlTableBodyContainer";
import { getHptunerTableData } from "@/redux/project/experiment/thunks";
import { RootState, dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

interface Column {
  id: string;
  label?: string;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
}

enum StatusEnum {
  started = "success",
  closed = "default",
  updated = "secondary",
  failed = "error",
}

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 150 },
  { id: "hptuner_type", label: "HP Tuner Type" },
  { id: "created_at", label: "Create At" },
  { id: "status", label: "Status", minWidth: 150 },
  { id: "action", label: "Action", minWidth: 150 },
  { id: "", width: 150 },
];

interface IHpTunerTableProps {}

const HpTunerTable: React.FC<IHpTunerTableProps> = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const [searchText, setSearchText] = useState<string>("");
  const [notebookTypeState, setNotebookTypeState] = useState("Kubeflow");
  const [page_num, setPageNum] = useState<number>(1);
  const [page_size, setPageSize] = useState<number>(10);

  const {
    hptunerTableList: { data, loading },
  } = useSelector((state: RootState) => state.experiment);

  // Add hp tuner btn
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const nameExist = (key, value) => {
    if (value) {
      return {
        [key]: value,
      };
    }
    return {};
  };

  const fetchData = (params: {
    name?: string;
    notebook_type?: string;
    page_num: number;
    page_size: number;
  }) => {
    return dispatch(
      getHptunerTableData({
        params,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  };

  const handleRefresh = (isUpdate?: boolean) => {
    setSearchText("");
    setNotebookTypeState("Kubeflow");
    setPageNum(1);
    setPageSize(10);

    fetchData({ page_num: 1, page_size: 10 }).then(() => {
      if (!isUpdate) {
        enqueueSnackbar("Refresh Success", { variant: "success" });
      }
    });
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNum(newPage + 1);
    fetchData({
      ...nameExist("name", searchText),
      ...nameExist("notebook_type", notebookTypeState),
      page_num: newPage + 1,
      page_size,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNum(1);
    fetchData({
      ...nameExist("name", searchText),
      ...nameExist("notebook_type", notebookTypeState),
      page_num: 1,
      page_size: parseInt(event.target.value, 10),
    });
  };

  const renderCell = (row, column) => {
    switch (column.id) {
      case "name":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="subtitle2">{row[column.id]}</Typography>
          </TableCell>
        );

      case "status":
        const status = row[column.id];
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width", "maxWidth") }}
          >
            {status ? (
              <Label color={StatusEnum[status] || "default"}>{status}</Label>
            ) : (
              "-"
            )}
          </TableCell>
        );

      case "action":
        const isShowAction = row.status === "started";
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width", "maxWidth") }}
          >
            <Stack direction="row" alignItems="center" spacing={0.5}>
              {isShowAction ? (
                <>
                  <Typography variant="subtitle2">View Result</Typography>
                  <IconButton
                    sx={{ color: "primary.main" }}
                    onClick={() => {
                      navigate(`${row.id}`);
                    }}
                  >
                    <OpenInNewOutlinedIcon sx={{ width: 16, height: 16 }} />
                  </IconButton>
                </>
              ) : (
                "-"
              )}
            </Stack>
          </TableCell>
        );
      case "":
        return (
          <TableCell
            align="right"
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <HpTunerTableActionCol row={row} handleRefresh={handleRefresh} />
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {row[column.id] || "-"}
            </Typography>
          </TableCell>
        );
    }
  };

  const getAddHpBtn = () => {
    return (
      <Box sx={{ width: "150px" }}>
        <Button
          onClick={handleClick}
          variant="contained"
          endIcon={
            <ExpandMoreOutlinedIcon
              sx={{
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.5s",
              }}
            />
          }
          fullWidth
        >
          Add HP Tuner
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "150px",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              navigate("hpTunerCreate?type=Kubeflow");
            }}
          >
            Kubeflow
          </MenuItem>
          <MenuItem
            disabled
            onClick={() => {
              handleClose();
              navigate("hpTunerCreate?type=AWS SageMaker");
            }}
          >
            AWS SageMaker
          </MenuItem>
        </Menu>
      </Box>
    );
  };

  useEffect(() => {
    dispatch(
      getHptunerTableData({
        params: {
          page_num,
          page_size,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  }, []);

  return (
    <Card>
      <Stack direction="row" justifyContent="space-between" sx={{ p: 2.5 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <TextField
            id="search"
            sx={{ width: "360px" }}
            variant="outlined"
            size="small"
            label="Search HP name"
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() =>
                      fetchData({
                        ...nameExist("name", searchText),
                        ...nameExist("notebook_type", notebookTypeState),
                        page_num: 1,
                        page_size: 10,
                      }).then(() => {
                        enqueueSnackbar("Search Success", {
                          variant: "success",
                        });
                      })
                    }
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
          <FormControl
            fullWidth
            margin="normal"
            size="small"
            sx={{ width: "360px" }}
          >
            <InputLabel>Notebook Type</InputLabel>
            <Select
              value={notebookTypeState}
              onChange={(event) => {
                setNotebookTypeState(event.target.value);
                if (event.target.value === "All") {
                  fetchData({
                    ...nameExist("name", searchText),
                    page_num,
                    page_size,
                  });
                } else {
                  fetchData({
                    ...nameExist("name", searchText),
                    ...nameExist("notebook_type", event.target.value),
                    page_num,
                    page_size,
                  });
                }
              }}
              label="Notebook Type"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Kubeflow">Kubeflow</MenuItem>
              <MenuItem value="AWS SageMaker">AWS SageMaker</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          {getAddHpBtn()}
          <IconButton onClick={() => handleRefresh()}>
            <RefreshOutlinedIcon sx={{ width: "22px", height: "22px" }} />
          </IconButton>
        </Stack>
      </Stack>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <MlTableBodyContainer loading={loading} data={data?.items}>
                {data?.items?.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((column) => renderCell(row, column))}
                  </TableRow>
                ))}
              </MlTableBodyContainer>
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25]}
        component="div"
        count={data?.total || 0}
        rowsPerPage={page_size}
        page={page_num - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

export default HpTunerTable;

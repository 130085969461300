// material
import { Box, FormHelperText, Grid, Typography } from "@mui/material";
// components
import { StyledTextField } from "../components/PipelineComponents";

// ----------------------------------------------------------------------

/* const SCHEDULE_OPTIONS = [
  {
    value: "regular",
    title: "Regular schedule",
    description:
      "Run the pipeline at a regular rate, such as every 10 miniutes.",
  },
  {
    value: "cron",
    title: "Grained schedule",
    description:
      "A fine-grained schedule that runs at a specific time, such as 8:00 a.m. PST on the first Monday of every month.",
  },
]; */

type Props = {
  formik: any;
};

export default function ScheduledTriggerInput({ formik }: Props) {
  const { errors, touched, getFieldProps } = formik;

  return (
    <Box>
      {/* <Typography
        variant="overline"
        sx={{ my: 2.5, display: "block", color: "text.secondary" }}
      >
        Schedule pattern
      </Typography> */}

      {/* <RadioGroup row {...getFieldProps("schedule")} sx={{ mb: 2 }}>
        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: {
              sm: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
            },
          }}
        >
          {SCHEDULE_OPTIONS.map((schedule_option) => {
            const { value, title, description } = schedule_option;

            return (
              <OptionStyle
                key={title}
                // sx={{
                //   ...(values.schedule === value && {
                //     boxShadow: (theme) => theme.customShadows.z1,
                //   }),
                // }}
              >
                <FormControlLabel
                  value={value}
                  control={
                    <Radio checkedIcon={<Icon icon={checkmarkCircle2Fill} />} />
                  }
                  label={
                    <Box sx={{ ml: 1 }}>
                      <Typography variant="subtitle2">{title}</Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        {description}
                      </Typography>
                    </Box>
                  }
                  sx={{ flexGrow: 1, py: 1 }}
                />
              </OptionStyle>
            );
          })}
        </Box>
      </RadioGroup> */}

      {/* {values.schedule === "regular" && (
        <>
          <Typography
            variant="overline"
            sx={{ my: 2.5, display: "block", color: "text.secondary" }}
          >
            Rate expression
          </Typography>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1.5}
          >
            <Grid item xs="auto">
              <Typography align="left" variant="body2">
                {"Run the pipeline every"}
              </Typography>
            </Grid>

            <Grid item xs={2.5} md={1.5}>
              <StyledTextField
                label="Value"
                {...getFieldProps("regularScheduleValue")}
                error={Boolean(
                  touched.regularScheduleValue && errors.regularScheduleValue
                )}
                helperText={errors.regularScheduleValue}
              />
            </Grid>
            <Grid item xs={3.5} md={2}>
              <StyledTextField
                select
                label="Unit"
                SelectProps={{ native: true }}
                {...getFieldProps("regularScheduleUnit")}
              >
                <option key={0} value={"minutes"}>
                  {"Minutes"}
                </option>
                <option key={1} value={"hours"}>
                  {"Hours"}
                </option>
                <option key={2} value={"days"}>
                  {"Days"}
                </option>
              </StyledTextField>
            </Grid>
          </Grid>
        </>
      )} */}

      <>
        <Typography
          variant="subtitle2"
          sx={{ my: 2.5, display: "block", color: "text.secondary" }}
        >
          Schedule Pattern (Cron expression)
        </Typography>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1.5}
        >
          <Grid item xs="auto">
            <Typography align="left" variant="body2">
              {"Synchronized at"}
            </Typography>
          </Grid>
          {/* Cron seconds */}
          <Grid item xs={2.5} md={1.7}>
            <StyledTextField
              label="Seconds"
              {...getFieldProps("cron.cronSeconds")}
            />
          </Grid>
          {/* Cron minutes */}
          <Grid item xs={2.5} md={1.7}>
            <StyledTextField
              label="Minutes"
              {...getFieldProps("cron.cronMinutes")}
            />
          </Grid>
          {/* Cron hours */}
          <Grid item xs={2.5} md={1.7}>
            <StyledTextField
              label="Hours"
              {...getFieldProps("cron.cronHours")}
            />
          </Grid>
          {/* Cron day of month */}
          <Grid item xs={2.5} md={1.7}>
            <StyledTextField
              label="Day of month"
              {...getFieldProps("cron.cronDom")}
            />
          </Grid>
          {/* Cron month */}
          <Grid item xs={2.5} md={1.7}>
            <StyledTextField
              label="Month"
              {...getFieldProps("cron.cronMonth")}
            />
          </Grid>
          {/* Cron day of week */}
          <Grid item xs={2.5} md={1.7}>
            <StyledTextField
              label="Day of week"
              {...getFieldProps("cron.cronDow")}
            />
          </Grid>
          {/* Cron year */}
          {/* <Grid item xs={2.5} md={1.7}>
            <StyledTextField
              label="Year"
              {...getFieldProps("cron.cronYear")}
            />
          </Grid> */}
        </Grid>

        {errors.cronExpression && touched.cronExpression && (
          <FormHelperText error sx={{ px: 2 }}>
            {errors.cronExpression}
          </FormHelperText>
        )}
      </>
    </Box>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import * as Yup from "yup";
import { uuidv7 } from "uuidv7";
import { Form, Field, useFormik, FormikProvider } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Stack,
  Button,
  FormHelperText,
} from "@mui/material";
import MlCard from "@/components/project/mlComponents/MlCard";
import { useSelector, RootState, dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import {
  getConnectionList,
  getCredentialList,
  addModelPackage,
  addRelTagModelPackage,
  editModelPackage,
  delRelTagModelPackage,
} from "@/redux/project/dataAsset/modelRegistry/thunks";
interface StorageProps {
  setActiveStep: Function;
  setModelPacakgeRes: Function;
  setStorageInfo: Function;
  basicInfo: Record<string, any>;
  tags: any[];
}

interface FormValues {
  resource_connection_id: string;
  resource_path: string;
  resource_type: string;
  resource_credential_id: string;
}

const StorageStep = ({
  basicInfo,
  setModelPacakgeRes,
  setStorageInfo,
  setActiveStep,
  tags: currentTags,
}: StorageProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const [curConnection, setCurConnection] = useState<Record<string, any>>({});
  const {
    connectionListDatas: {
      data: { items: connectionList = [] },
    },
    credentialDatas: {
      data: { items: credentialList = [] },
    },
    modelPackageDescribeDatas: { data: modelPackageDescribe },
  } = useSelector((state: RootState) => state.modelRegistry);

  const isEdit = pathname.includes("modelPackageEdit");
  const pathFileRegex = new RegExp(`^(?:[/]?[a-zA-Z0-9_.=-]+)+[/]?$`);
  const pathTableRegex = new RegExp(
    `^(?:["][a-zA-Z0-9_]+["][.]|[a-zA-Z0-9_]+[.])?(?:["][a-zA-Z0-9_]+["]|[a-zA-Z0-9_]+)$`
  );

  const initialValues: FormValues = {
    resource_connection_id: "",
    resource_path: "",
    resource_type: "",
    resource_credential_id: "",
  };

  const validationSchema = Yup.object({
    resource_connection_id: Yup.string().required(
      "Resource Connection is required"
    ),
    resource_path: Yup.string()
      .test("path-valid", "Invalid Resource Path", (value) => {
        if (value) {
          return pathFileRegex.test(value) || pathTableRegex.test(value);
        }
        return false;
      })
      .required("Resource Path is required"),
    resource_type: Yup.string().required("Resource Type is required"),
    resource_credential_id: Yup.string().required("Secrets is required"),
  });

  useEffect(() => {
    dispatch(
      getConnectionList({
        params: { page_num: 1, page_size: 9999, is_active: 1 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
    dispatch(
      getCredentialList({
        params: { page_num: 1, page_size: 9999, is_active: 1 },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    ).unwrap();
  }, []);

  useEffect(() => {
    if (isEdit) {
      setCurConnection(
        connectionList.find(
          (item) => item.id === modelPackageDescribe?.resource_connection_id
        ) || {}
      );
      setFieldValue(
        "resource_connection_id",
        modelPackageDescribe?.resource_connection_id
      );
      setFieldValue("resource_path", modelPackageDescribe?.resource_path);
      setFieldValue("resource_type", modelPackageDescribe?.resource_type);
      setFieldValue(
        "resource_credential_id",
        modelPackageDescribe?.resource_credential_id
      );
    }
  }, [modelPackageDescribe]);

  const submitModelPackage = (values) => {
    const modelPackageParams = {
      name: basicInfo?.name,
      modelpackage_type: basicInfo?.modelpackage_type,
      description: basicInfo?.description,
      resource_type: values.resource_type,
      resource_path: values?.resource_path,
      resource_connection_id: values?.resource_connection_id,
      resource_credential_id: values?.resource_credential_id,
      delivered_to: basicInfo?.deliver_to,
    };
    if (isEdit) {
      return dispatch(
        editModelPackage({
          id: modelPackageDescribe?.id,
          params: { ...modelPackageParams },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
    } else {
      return dispatch(
        addModelPackage({
          params: {
            ...modelPackageParams,
            external_id: uuidv7(),
            modelpackage_state: "DRAFT",
          },
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();
    }
  };

  const updateAppTags = async (tags) => {
    const deleteIds: string[] = [];
    const addIds: string[] = [];

    tags.forEach((item) => {
      if (currentTags?.findIndex((i) => i.tag_id === item) === -1) {
        addIds.push(item);
      }
    });
    currentTags.forEach((item) => {
      if (!tags.includes(item.tag_id)) {
        deleteIds.push(item.tag_id);
      }
    });

    // delete
    await Promise.all(
      deleteIds.map((item) =>
        dispatch(
          delRelTagModelPackage({
            id: currentTags.find((i) => i.tag_id === item)?.id,
            params: { is_active: 0 },
            alertCallback: enqueueSnackbar,
            pathname,
          })
        ).unwrap()
      )
    );

    // add
    await Promise.all(
      addIds.map((item) =>
        dispatch(
          addRelTagModelPackage({
            params: {
              modelpackage_id: modelPackageDescribe?.id,
              tag_id: item,
              is_active: 1,
            },
            alertCallback: enqueueSnackbar,
            pathname,
          })
        ).unwrap()
      )
    );
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      try {
        // add mp and then add rel_tag_mp
        const modelPackageResult = await submitModelPackage(values);

        if (isEdit) {
          await updateAppTags(basicInfo?.tags);
        } else {
          await Promise.all(
            basicInfo?.tags?.map((item) =>
              dispatch(
                addRelTagModelPackage({
                  params: {
                    modelpackage_id: modelPackageResult?.id,
                    tag_id: item,
                    is_active: 1,
                  },
                  alertCallback: enqueueSnackbar,
                  pathname,
                })
              ).unwrap()
            )
          );
        }
        actions.setSubmitting(false);
        setStorageInfo(values);
        setModelPacakgeRes(modelPackageResult);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        enqueueSnackbar(`${isEdit ? "Edit Success" : "Create Success"}`, {
          variant: "success",
        });
      } catch (err) {
        actions.setSubmitting(false);
      }
    },
  });

  const handleCancel = () => {
    navigate(`/ui/dataAsset/modelRegistry`);
  };

  const { values, errors, touched, isSubmitting, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form>
        <MlCard title="Storage Information" sx={{ mt: 1.5 }}>
          <Box sx={{ pb: 2, width: "800px" }}>
            <FormControl
              fullWidth
              margin="dense"
              size="small"
              error={
                touched.resource_connection_id &&
                Boolean(errors.resource_connection_id)
              }
            >
              <InputLabel>Resource Connection</InputLabel>
              <Field
                as={Select}
                name="resource_connection_id"
                label="Resource Connection"
                onChange={(e) => {
                  setFieldValue("resource_connection_id", e.target.value);
                  setCurConnection(
                    connectionList.find((item) => item.id === e.target.value) ||
                      {}
                  );
                }}
              >
                {connectionList.map((item) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </Field>
              <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                {touched.resource_connection_id && errors.resource_connection_id
                  ? errors.resource_connection_id
                  : ""}
              </FormHelperText>
            </FormControl>
            <Field
              as={TextField}
              name="resource_path"
              label="Resource Path"
              size="small"
              fullWidth
              margin="dense"
              helperText={
                touched.resource_path && errors.resource_path
                  ? errors.resource_path
                  : pathTableRegex.test(values.resource_path)
                  ? `Full Address: ${values.resource_path}`
                  : pathFileRegex.test(values.resource_path)
                  ? `Full Address: ${
                      curConnection?.connection_scheme || ""
                    }://${curConnection?.connection_endpoint || ""}${
                      values.resource_path
                    }`
                  : "Full Address: "
              }
              error={touched.resource_path && Boolean(errors.resource_path)}
              FormHelperTextProps={{
                sx: { minHeight: "18px", marginTop: "2px" },
              }}
            />
            <FormControl
              fullWidth
              margin="dense"
              size="small"
              error={touched.resource_type && Boolean(errors.resource_type)}
            >
              <InputLabel>Resource Type</InputLabel>
              <Field as={Select} name="resource_type" label="Resource Type">
                <MenuItem value="ZIP">ZIP</MenuItem>
                <MenuItem value="TAR">TAR</MenuItem>
                <MenuItem value="PICKLE">PICKLE</MenuItem>
              </Field>
              <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                {touched.resource_type && errors.resource_type
                  ? errors.resource_type
                  : ""}
              </FormHelperText>
            </FormControl>
            <Typography variant="subtitle1">Resource Credentials</Typography>
            <FormControl
              fullWidth
              margin="dense"
              size="small"
              error={
                touched.resource_credential_id &&
                Boolean(errors.resource_credential_id)
              }
            >
              <InputLabel>Secrets</InputLabel>
              <Field as={Select} name="resource_credential_id" label="Secrets">
                {credentialList?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Field>
              <FormHelperText sx={{ minHeight: "18px", marginTop: "2px" }}>
                {touched.resource_credential_id && errors.resource_credential_id
                  ? errors.resource_credential_id
                  : ""}
              </FormHelperText>
            </FormControl>
          </Box>
        </MlCard>

        <Stack
          spacing={2}
          direction="row"
          justifyContent="center"
          sx={{ my: 4 }}
        >
          <LoadingButton
            type="submit"
            loading={isSubmitting}
            variant="contained"
            sx={{ width: "200px", color: "background.paper" }}
          >
            Save
          </LoadingButton>
          <Button
            type="button"
            color="inherit"
            variant="outlined"
            sx={{ width: "200px", color: "text.secondary" }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default StorageStep;

import { useState } from "react";
import { Icon } from "@iconify/react";
import roundFullscreen from "@iconify/icons-ic/round-fullscreen";
import roundFullscreenExit from "@iconify/icons-ic/round-fullscreen-exit";
// material
import { MIconButton } from "@/components/project/@material-extend";

// ----------------------------------------------------------------------

export default function FullscreenBtn() {
  const [fullscreen, setFullscreen] = useState(false);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setFullscreen(false);
    }
  };

  return (
    <MIconButton
      onClick={toggleFullScreen}
      // color={fullscreen ? 'primary' : 'inherit'}
      color={"default"}
      sx={{
        padding: 0,
        width: 44,
        height: 44,
      }}
    >
      <Icon
        icon={fullscreen ? roundFullscreenExit : roundFullscreen}
        width={28}
        height={28}
      />
    </MIconButton>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Scrollbar from "@/components/project/Scrollbar";
import { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RootState, dispatch } from "@/redux/store";
import {
  addNotebookTags,
  delNotebookTags,
  getNotebookDetailData,
  getNotebookTags,
} from "@/redux/project/experiment/thunks";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { resetNotebookData } from "@/redux/project/experiment/slice";
import { Skeleton } from "@mui/material";
import {
  getNotebookTemplateDetail,
  getNotebookTemplateVersionDetail,
} from "@/redux/project/template/thunks";
import { addTags, getTags } from "@/redux/project/administration/thunks";
import { LoadingButton } from "@mui/lab";

interface INotebookUpdateTagsDrawer {
  isOpen: boolean;
  onClose: VoidFunction;
  row: {
    [key: string]: any;
  };
}

interface IDetailData {
  value: string;
  loading: boolean;
  fullRow: boolean;
  textStyle: number;
}

const halfRowStyle = {
  noWrap: true,
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const wrapStyle = {
  overflowWrap: "break-word",
};

const NotebookUpdateTagsDrawer = ({
  isOpen,
  onClose,
  row,
}: INotebookUpdateTagsDrawer) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const allExistTags = useRef<any[]>([]);

  const [notebookTagsArr, setNotebookTagsArr] = useState<string[]>([]);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    tags: { items: tagList = [] },
  } = useSelector((state: RootState) => state.administration);

  const {
    notebookDetail: { data, loading },
  } = useSelector((state: RootState) => state.experiment);

  // template name loading
  const [templateName, setTemplateName] = useState<{
    value: string;
    loading: boolean;
  }>({
    value: "",
    loading: false,
  });

  const generateComputeResource = (rowData) => {
    let value = "";
    if (rowData.notebook_type === "Kubeflow") {
      const {
        resource_config: { notebook },
      } = rowData;
      const arr: string[] = [];
      // use gpu when cpu not exist
      if (notebook?.minimum_cpu) {
        arr.push(`${notebook?.minimum_cpu} CPU`);
      }
      if (notebook?.minimum_memory) {
        arr.push(`${notebook?.minimum_memory} MEM`);
      }

      value = arr.join(",");
    } else {
      value = "";
    }

    return value;
  };

  const getOrderedDetail = (data): Record<string, IDetailData> => {
    const { resource_config: { env = {}, notebook = {} } = {} } = data;

    return {
      Type: {
        value: data?.notebook_type || "-",
        loading,
        fullRow: false,
        textStyle: 1,
      },
      "Compute Resource": {
        value: generateComputeResource(data) || "-",
        loading,
        fullRow: false,
        textStyle: 1,
      },
      "Cluster Name": {
        value: env?.cluster_names || "-",
        loading,
        fullRow: false,
        textStyle: 1,
      },
      Volume: {
        value: notebook?.ephemeral_volume_size
          ? `${notebook?.ephemeral_volume_size} GB`
          : "-",
        loading,
        fullRow: false,
        textStyle: 1,
      },
      Image: {
        value: notebook?.image || "-",
        loading,
        fullRow: false,
        textStyle: 0,
      },
      Template: {
        value: templateName?.value || "-",
        loading: templateName.loading,
        fullRow: false,
        textStyle: 1,
      },
      "Artifactory URL": {
        value: data?.artifactory_url || "-",
        loading,
        fullRow: true,
        textStyle: 1,
      },
      "Git URL": {
        value: data?.git_repo_url || "-",
        loading,
        fullRow: true,
        textStyle: 1,
      },
      "Created At": {
        value: data?.created_at || "-",
        loading,
        fullRow: false,
        textStyle: 1,
      },
      "Created By": {
        value: data?.created_by || "-",
        loading,
        fullRow: false,
        textStyle: 1,
      },
    };
  };

  const handleUpdateTags = async () => {
    try {
      setIsSubmitting(true);

      const deleteIds: string[] = [];
      const addIds: string[] = [];
      notebookTagsArr.forEach((item) => {
        if (allExistTags.current.findIndex((i) => i.tag_id === item) === -1) {
          addIds.push(item);
        }
      });
      allExistTags.current.forEach((item) => {
        if (!notebookTagsArr.includes(item.tag_id)) {
          deleteIds.push(item.tag_id);
        }
      });

      // delete
      await Promise.allSettled(
        deleteIds.map((item) =>
          dispatch(
            delNotebookTags({
              id: allExistTags.current.find((i) => i.tag_id === item).id,
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap()
        )
      );

      // add
      await Promise.allSettled(
        addIds.map((item) =>
          dispatch(
            addNotebookTags({
              params: {
                notebook_id: row.id,
                tag_id: item,
              },
              alertCallback: enqueueSnackbar,
              pathname,
            })
          ).unwrap()
        )
      );

      setIsSubmitting(false);
      enqueueSnackbar("Update Success", { variant: "success" });
      onClose();
    } catch (err) {
      setIsSubmitting(false);
    }
  };

  const handleInit = async () => {
    try {
      setTemplateName({
        ...templateName,
        loading: true,
      });

      const res = await dispatch(
        getNotebookDetailData({
          id: row.id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      const r = await dispatch(
        getNotebookTemplateVersionDetail({
          id: res.notebook_template_version_id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      const s = await dispatch(
        getNotebookTemplateDetail({
          id: r.notebook_template_id,
          alertCallback: enqueueSnackbar,
          pathname,
        })
      ).unwrap();

      setTemplateName({
        value: s.name,
        loading: false,
      });
    } catch (e) {
      setTemplateName({
        value: "",
        loading: false,
      });
    }

    // all tags
    dispatch(
      getTags({
        params: {
          is_active: 1,
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );

    // selectedTags
    dispatch(
      getNotebookTags({
        params: {
          notebook_id: row.id,
          page_num: 1,
          page_size: 9999,
        },
        alertCallback: enqueueSnackbar,
        pathname,
      })
    )
      .unwrap()
      .then((res) => {
        setNotebookTagsArr(res?.items.map((item) => item.tag_id));
        allExistTags.current = res?.items;
      });
  };

  useEffect(() => {
    if (isOpen) {
      handleInit();
    }
  }, [isOpen]);

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        onClose();
        dispatch(resetNotebookData("notebookDetail"));
        setNotebookTagsArr([]);

        setTemplateName({
          value: "",
          loading: false,
        });
      }}
      anchor="right"
      sx={{
        "& .MuiPaper-root": {
          width: "320px",
          bgcolor: "background.default",
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          py: 2.5,
          px: 4,
          bgcolor: "background.paper",
        }}
      >
        <Typography variant="subtitle1">Info</Typography>
        {/* <Icon></Icon> */}
        <CloseOutlinedIcon sx={{ cursor: "pointer" }} onClick={onClose} />
      </Stack>
      <Scrollbar sx={{ maxHeight: "800px" }}>
        {loading ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              height: 60,
              py: "10px",
            }}
          >
            <Skeleton
              variant="rectangular"
              width="150px"
              height="40px"
              sx={{ borderRadius: 2, mt: 1, mb: 1 }}
            />
          </Stack>
        ) : (
          <Typography variant="h6" align="center" paddingY={2}>
            {data.name}
          </Typography>
        )}
        <Autocomplete
          size="small"
          multiple
          disableCloseOnSelect
          disablePortal
          freeSolo
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          limitTags={2}
          getOptionLabel={(option) =>
            typeof option === "object" ? option.name : option
          }
          isOptionEqualToValue={(option, value) => {
            if (typeof option === "object" && typeof value === "object") {
              return option.id === value.id;
            } else if (
              typeof option === "string" ||
              typeof value === "string"
            ) {
              return option === value;
            }
            return false;
          }}
          options={tagList}
          value={notebookTagsArr.map(
            (tagId) => tagList.find((tag) => tag.id === tagId) || tagId
          )}
          onChange={(_, newValues) => {
            const filteredValues = newValues.filter(
              (item) => typeof item === "string"
            );

            if (tagList.find((i) => i.name === filteredValues[0])) {
              enqueueSnackbar(`Tag ${filteredValues[0]} existed`, {
                variant: "warning",
              });
              return;
            }

            const updatedTagsPromises = newValues.map(async (item) => {
              if (typeof item === "string") {
                // new text ,add a new tag
                const res = await dispatch(
                  addTags({
                    params: {
                      is_active: 1,
                      name: item,
                    },
                    alertCallback: enqueueSnackbar,
                    pathname,
                  })
                ).unwrap();

                enqueueSnackbar(`Add Tag ${item} Success`, {
                  variant: "success",
                });

                await dispatch(
                  getTags({
                    params: {
                      is_active: 1,
                      page_num: 1,
                      page_size: 9999,
                    },
                    alertCallback: enqueueSnackbar,
                    pathname,
                  })
                );

                return res.id;
              }
              return item.id;
            });

            Promise.all(updatedTagsPromises).then((r) => {
              setNotebookTagsArr(r);
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tags"
              // error={tagError}
              // helperText={tagError && 'Tags is required'}
            />
          )}
          sx={{ mt: 1, mb: 0.5, mx: 2 }}
        />
        <Stack sx={{ px: 4 }}>
          <Divider sx={{ mt: 2 }} />
          <Accordion
            defaultExpanded
            elevation={0}
            sx={{
              width: "100% !important",
              boxShadow: "none !important",
              "&.Mui-expanded": { margin: 0 },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                padding: 0,
                minHeight: "64px",
                "& .MuiAccordionSummary-content": {
                  margin: "0 !important",
                },
              }}
            >
              <Typography variant="subtitle1" paddingY={2}>
                Properties
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0 5px" }}>
              <Grid container columnSpacing={1}>
                {Object.entries(getOrderedDetail(data)).map(
                  ([key, valueObj]) => (
                    <>
                      <Grid
                        item
                        xs={valueObj.fullRow ? 12 : 6}
                        lg={valueObj.fullRow ? 12 : 6}
                      >
                        <Typography variant="subtitle2" paddingY={1}>
                          {key}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={valueObj.fullRow ? 12 : 6}
                        lg={valueObj.fullRow ? 12 : 6}
                      >
                        {valueObj.loading ? (
                          <Box
                            sx={{
                              width: "100%",
                              height: "100%",
                              py: 0.3,
                            }}
                          >
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height="100%"
                              sx={{ borderRadius: 2, mt: 1, mb: 1 }}
                            />
                          </Box>
                        ) : (
                          <Box component="div" title={valueObj.value}>
                            <Typography
                              variant="body2"
                              paddingY={1}
                              sx={{
                                width: "100%",
                                ...(valueObj.textStyle === 1 ? wrapStyle : {}),
                              }}
                              {...(valueObj.textStyle === 0
                                ? halfRowStyle
                                : {})}
                            >
                              {valueObj.value}
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                    </>
                  )
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Stack direction="row" justifyContent="center" spacing={2}>
          <LoadingButton
            onClick={() => {
              handleUpdateTags();
            }}
            variant="contained"
            loading={isSubmitting}
            color="primary"
            sx={{ width: 120, color: "background.paper" }}
          >
            Update
          </LoadingButton>
          <Button
            type="submit"
            variant="outlined"
            color="inherit"
            sx={{ width: 120, color: "text.secondary" }}
          >
            Cancel
          </Button>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};

export default NotebookUpdateTagsDrawer;

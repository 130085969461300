import Page from "@/components/project/Page";
import { Card } from "@mui/material";
import NotebookVolumeTable from "./components/notebookVolumeList/NotebookVolumeTable";

const NotebookVolume = () => {
  return (
    <Page>
      <Card>
        <NotebookVolumeTable />
      </Card>
    </Page>
  );
};

export default NotebookVolume;

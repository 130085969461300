import { styled } from "@mui/material/styles";
import { Typography, Box, BoxProps } from "@mui/material";
// components
import { ComingSoonIllustration } from "@/assets";

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  height: "50%",
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(1, 2, 0.5, 2),
}));

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  title: string;
  titleFont?: "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  img?: string;
  imgHeight?: number;
  description?: string;
}

export default function ComingSoon({
  title,
  titleFont,
  description,
  img,
  imgHeight,
  ...other
}: Props) {
  return (
    <RootStyle {...other}>
      <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
        <Typography variant={titleFont ? titleFont : "h3"} paragraph>
          {title}
        </Typography>

        {description && (
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {description}
          </Typography>
        )}

        <ComingSoonIllustration
          sx={{ mt: 5, mb: 2, height: imgHeight ? imgHeight : 220 }}
        />
      </Box>
    </RootStyle>
  );
}

import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import plusFill from "@iconify/icons-eva/plus-fill";
// @types
import { useDispatch } from "@/redux/store";
// material
import { Box, Stack, Paper, Collapse, IconButton } from "@mui/material";
//
import Scrollbar from "@/components/project/Scrollbar";
import { CollapseButtonStyle } from "./SettingSidebar";
import EmptyTable from "@/components/project/EmptyTable";
import { PipelineParameter } from "@/@types/project/mlPipeline/SageMaker/pipeline";
import { MlPipelineTextfield } from "./components/PipelineComponents";
import { dataTypes } from "./constants";
import { MenuItem } from "@mui/material";
import { handleKubeflowParameters } from "@/redux/project/automation/pipeline";
import { useEffect } from "react";

function ParameterItem({
  id,
  data,
  isEdit,
  length,
}: {
  id: number;
  data: PipelineParameter;
  isEdit: boolean;
  length: number;
}) {
  const dispatch = useDispatch();
  const ParamSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const formik = useFormik({
    initialValues: data,
    validationSchema: ParamSchema,
    onSubmit: () => {},
  });

  const submitFunc = (fieldName, value) => {
    try {
      dispatch(
        handleKubeflowParameters({
          handleType: "edit",
          id,
          data: {
            [fieldName]: value,
          },
          key: "pipelineParamList",
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const { setValues } = formik;

  useEffect(() => {
    setValues(data);
  }, [length]);

  return (
    <>
      <Paper sx={{ p: 1.5, borderRadius: 1.5, bgcolor: "background.neutral" }}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off">
            <Stack spacing={2}>
              <MlPipelineTextfield
                label={"Parameter name"}
                field={"Name"}
                disabled={isEdit}
                formik={formik}
                submitThisValuefunc={(value) => submitFunc("Name", value)}
              />

              <MlPipelineTextfield
                label={"Default value"}
                field={"DefaultValue"}
                disabled={isEdit}
                formik={formik}
                submitThisValuefunc={(value) =>
                  submitFunc("DefaultValue", value)
                }
              />

              <Stack direction="row" spacing={0.5} alignItems="center">
                <MlPipelineTextfield
                  select
                  label={"Data type"}
                  field={"Type"}
                  disabled={isEdit}
                  formik={formik}
                  submitThisValuefunc={(value) => submitFunc("Type", value)}
                >
                  {dataTypes.map((item) => (
                    <MenuItem key={item.label} value={item.label}>
                      {item.label}
                    </MenuItem>
                  ))}
                </MlPipelineTextfield>

                <IconButton
                  size="small"
                  disabled={isEdit}
                  onClick={() => {
                    dispatch(
                      handleKubeflowParameters({
                        id,
                        handleType: "delete",
                        key: "pipelineParamList",
                      })
                    );
                  }}
                >
                  <Icon icon={trash2Fill} width={18} height={18} />
                </IconButton>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </Paper>
    </>
  );
}

type PipelineParametersProps = {
  parameters: Array<PipelineParameter>;
  isCollapse: boolean;
  onCollapse: VoidFunction;
  openCollapse: VoidFunction;
  isEdit: boolean;
};

export default function PipelineParameters({
  parameters,
  isCollapse,
  onCollapse,
  openCollapse,
  isEdit,
}: PipelineParametersProps) {
  const dispatch = useDispatch();

  const handleAddParameter = () => {
    if (!isCollapse) {
      openCollapse();
    }

    dispatch(
      handleKubeflowParameters({
        handleType: "create",
        data: {
          Name: `Paramater-${parameters.length + 1}`,
          Type: "String",
          DefaultValue: "",
        },
        key: "pipelineParamList",
      })
    );
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <CollapseButtonStyle
          fullWidth
          color="inherit"
          onClick={onCollapse}
          endIcon={
            <Icon
              icon={isCollapse ? arrowIosDownwardFill : arrowIosForwardFill}
              width={16}
              height={16}
            />
          }
        >
          Pipeline Params {parameters.length > 0 && `(${parameters.length})`}
        </CollapseButtonStyle>

        <IconButton
          sx={{ position: "absolute", top: 7, right: 30 }}
          disabled={isEdit}
          size="small"
          onClick={handleAddParameter}
        >
          <Icon icon={plusFill} width={16} height={16} />
        </IconButton>
      </Box>

      {/* <Box sx={{ height: isCollapse ? `calc(70%)` : 0 }}> */}
      <Scrollbar>
        <Collapse in={isCollapse}>
          <Stack
            spacing={1.5}
            sx={{
              mx: 2,
              mt: isCollapse ? 0.5 : 0,
              mb: isCollapse ? 1.5 : 1,
            }}
          >
            {parameters.map((param, index) => (
              <ParameterItem
                length={parameters.length}
                key={index}
                id={index}
                data={param}
                isEdit={isEdit}
              />
            ))}
          </Stack>
        </Collapse>
      </Scrollbar>
      {/* </Box> */}

      {isCollapse && parameters.length === 0 && (
        <EmptyTable
          emptyImage={false}
          mainTitle={"No pipeline parameters"}
          content={'Create one by clicking "+" button'}
          color={"text.disabled"}
          sx={{ backgroundColor: "transparent", mx: 2, mt: 5, mb: 7 }}
        />
      )}
    </>
  );
}

import React, { useState } from "react";
import { useLocation } from "react-router";
import MlCard from "@/components/project/mlComponents/MlCard";
import Scrollbar from "@/components/project/Scrollbar";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { extractKeys } from "@/utils/project/utils";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import Label from "@/components/project/Label";
import VersionDetailDrawer from "./VersionDetailDrawer";
import { dispatch, RootState, useSelector } from "@/redux/store";
import MlTableBodyContainer from "@/components/project/mlComponents/MlTableBodyContainer";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { getModelPackageVersionList } from "@/redux/project/dataAsset/modelRegistry/thunks";

enum versionStateEnum {
  DRAFT = "secondary",
  RELEASED = "success",
  DEPRECATED = "warning",
  SYNCHRONIZED = "info",
  DELETE = "default",
}

const ModelPackageDetailTable: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const [openVersionDetailDrawer, setOpenVersionDetailDrawer] =
    useState<boolean>(false);
  const [versionDetail, setVersionDetail] = useState<Record<string, any>>({});
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const {
    modelPackageDescribeDatas: { data: modelPackageDescribe },
    modelPackageVersionDatas: { data, loading },
  } = useSelector((state: RootState) => state.modelRegistry);

  const fetchData = (params: {
    page_num: number;
    page_size: number;
    name?: string;
    external_id?: string;
    modelpackage_id?: string;
  }) => {
    return dispatch(
      getModelPackageVersionList({
        params,
        alertCallback: enqueueSnackbar,
        pathname,
      })
    );
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage + 1);
    fetchData({ page_num: newPage, page_size: rowsPerPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    fetchData({ page_num: 1, page_size: parseInt(event.target.value, 10) });
  };

  const handleShowVersionDetail = (record) => {
    setOpenVersionDetailDrawer(true);
    setVersionDetail(record);
  };

  const handleRefresh = () => {
    fetchData({
      page_num: 1,
      page_size: 10,
      modelpackage_id: modelPackageDescribe?.id,
    })
      .unwrap()
      .then(() => {
        enqueueSnackbar("Refresh Success", { variant: "success" });
      });
  };

  const columns = [
    { id: "name", label: "Version Name", minWidth: 150 },
    { id: "modelpackage_version_number", label: "Version Number" },
    { id: "modelpackage_version_state", label: "Version State" },
    { id: "created_at", label: "Create At" },
    { id: "SynchronizedAt", label: "Synchronized At" },
    { id: "ReleasedAt", label: "Released At" },
    { id: "", width: 150 },
  ];

  const renderCell = (row, column) => {
    switch (column.id) {
      case "name":
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography
              variant="subtitle2"
              color="secondary"
              sx={{ cursor: "pointer" }}
              onClick={() => handleShowVersionDetail(row)}
            >
              {row[column.id] || "-"}
            </Typography>
          </TableCell>
        );
      case "modelpackage_version_state":
        const state = row[column.id];
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            {!isEmpty(state) ? (
              <Label color={versionStateEnum[state]}>{state}</Label>
            ) : (
              "-"
            )}
          </TableCell>
        );
      case "":
        return (
          <TableCell
            align="right"
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Grid container rowSpacing={1} columnSpacing={1}>
              <Grid item xs={6} lg={6}>
                <IconButton onClick={() => handleShowVersionDetail(row)}>
                  <KeyboardArrowRightOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={column.id}
            sx={{ ...extractKeys(column, "minWidth", "width") }}
          >
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {row[column.id] || "-"}
            </Typography>
          </TableCell>
        );
    }
  };

  return (
    <>
      <MlCard
        title="Version History"
        extraJsx={
          <Stack direction="column" justifyContent="center" sx={{ pr: "20px" }}>
            <IconButton onClick={handleRefresh}>
              <RefreshOutlinedIcon />
            </IconButton>
          </Stack>
        }
        hasPaddingX={false}
      >
        <Scrollbar>
          <TableContainer sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <MlTableBodyContainer loading={loading} data={data?.items || []}>
                <TableBody>
                  {data?.items?.map((row, index) => (
                    <TableRow key={index}>
                      {columns.map((column) => renderCell(row, column))}
                    </TableRow>
                  ))}
                </TableBody>
              </MlTableBodyContainer>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20, 25]}
            component="div"
            count={data?.items?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Scrollbar>
      </MlCard>
      {openVersionDetailDrawer && (
        <VersionDetailDrawer
          isOpen={openVersionDetailDrawer}
          onClose={() => setOpenVersionDetailDrawer(false)}
          fetchVersionData={handleRefresh}
          versionDetail={versionDetail}
          modelPackageDetail={modelPackageDescribe}
        />
      )}
    </>
  );
};

export default ModelPackageDetailTable;

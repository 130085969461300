import Page from "@/components/project/Page";
import ConnectionTable from "./components/connectionList/ConnectionTable";

const Connection = () => {
  return (
    <Page>
      <ConnectionTable />
    </Page>
  );
};

export default Connection;

// material
import { alpha, styled } from "@mui/material/styles";
import { IconButton, Stack } from "@mui/material";
import SvgIconStyle from "@/components/project/SvgIconStyle";
import { getGraph } from "../components/GraphViewer";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  right: 6,
  bottom: 6,
  zIndex: 3339,
  position: "absolute",
  display: "flex",
  alignItems: "center",
  borderRadius: 8,
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  backgroundColor: `${alpha(theme.palette.divider, 0.1)}`,
  // backgroundColor: `${theme.palette.background.default}`
}));

const prefix = process.env.NODE_ENV === "development" ? "" : "/ui";

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`${prefix}/static/icons/pipeline/${name}.svg`}
    sx={{ width: 18, height: 18 }}
  />
);

const ICONS = {
  zoomOut: getIcon("ic_zoomout"),
  zoomIn: getIcon("ic_zoomin"),
  refresh: getIcon("ic_refresh"),
  fullview: getIcon("ic_fullview"),
};

// ----------------------------------------------------------------------

export default function GraphToolbar() {
  const zoomOut = () => {
    getGraph().zoom(-0.05, { minScale: 0.1 });
  };

  const zoomIn = () => {
    getGraph().zoom(0.05);
  };

  const zoomToFit = () => {
    getGraph().zoomToFit({ maxScale: 0.8 });
  };

  const refresh = () => {
    getGraph().zoomToFit({ maxScale: 0.9 });
  };

  return (
    <RootStyle>
      <Stack
        direction="column"
        spacing={0.2}
        sx={{ display: "flex", alignItems: "center", mx: 0.2 }}
      >
        <IconButton
          size="small"
          onClick={zoomIn}
          sx={{ width: 26, height: 26 }}
        >
          {ICONS.zoomIn}
        </IconButton>
        <IconButton
          size="small"
          onClick={zoomOut}
          sx={{ width: 26, height: 26 }}
        >
          {ICONS.zoomOut}
        </IconButton>
        <IconButton
          size="small"
          onClick={zoomToFit}
          sx={{ width: 26, height: 26 }}
        >
          {ICONS.fullview}
        </IconButton>
        <IconButton
          size="small"
          onClick={refresh}
          sx={{ width: 26, height: 26 }}
        >
          {ICONS.refresh}
        </IconButton>
      </Stack>
    </RootStyle>
  );
}

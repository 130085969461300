/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import { getStatisticList } from "@/redux/project/dataAsset/modelRegistry/thunks";
import StatisticPanel from "./StatisticPanel";
import SummaryPanel from "./SummaryPanel";

const DashbordStatistic = () => {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [modelpackage, setModelpackage] = useState<Record<string, any>>({
    title: "Model Packages",
  });
  const [modelpackageSummary, setModelpackageSummary] = useState<
    Record<string, any>
  >({ title: "Model Package Summary" });
  const [modelpackageversion, setModelpackageversion] = useState<
    Record<string, any>
  >({ title: "Model Package Versions" });
  const [modelpackageversionSummary, setModelpackageversionSummary] = useState<
    Record<string, any>
  >({ title: "Model Package Version Summary" });

  useEffect(() => {
    dispatch(
      getStatisticList({
        params: {},
        alertCallback: enqueueSnackbar,
        pathname,
      })
    )
      .unwrap()
      .then((resp) => {
        const mp: Record<string, any> = { ...modelpackage };
        const mpSummary: Record<string, any> = { ...modelpackageSummary };
        const mpv: Record<string, any> = { ...modelpackageversion };
        const mpvSummary: Record<string, any> = {
          ...modelpackageversionSummary,
        };
        // eslint-disable-next-line array-callback-return
        resp?.items?.map((data) => {
          if (data.name === "ModelPackageTotal") {
            mp.total = data.statistic_value || 0;
          } else if (data.name === "ModelPackageMoM") {
            mp.percent = data.statistic_value
              ? parseFloat(data.statistic_value) * 100
              : null;
            mp.trend = parseFloat(data.statistic_value) < 0 ? "down" : "up";
          } else if (data.name === "ModelPackageDailyNew") {
            const chartData: number[] = [];
            const labels: number[] = [];
            for (let i = 0; i < 7; i += 1) {
              const chart = JSON.parse(data.statistic_value).find(
                (item) => item.K === i
              );
              if (chart) {
                chartData.push(chart.V);
                labels.push(chart.K);
              } else {
                chartData.push(0);
                labels.push(i);
              }
            }
            mp.chartData = chartData;
            mp.labels = labels;
          } else if (data.name === "ModelPackageProportionByState") {
            mpSummary.data = JSON.parse(data.statistic_value) || [];
          } else if (data.name === "ModelPackageVersionTotal") {
            mpv.total = data.statistic_value || 0;
          } else if (data.name === "ModelPackageVersionMoM") {
            mpv.percent = data.statistic_value
              ? parseFloat(data.statistic_value) * 100
              : null;
            mpv.trend = parseFloat(data.statistic_value) < 0 ? "down" : "up";
          } else if (data.name === "ModelPackageVersionDailyNew") {
            const chartData: Array<Record<string, number>> = [];
            const labels: number[] = [];
            for (let i = 0; i < 7; i += 1) {
              const chart = JSON.parse(data.statistic_value).find(
                (item) => item.K === i
              );
              if (chart) {
                chartData.push({ x: chart.K, y: chart.V });
                labels.push(chart.K);
              } else {
                chartData.push({ x: i, y: 0 });
                labels.push(i);
              }
            }
            mpv.chartData = chartData;
            mpv.labels = labels;
          } else if (data.name === "ModelPackageVersionProportionByState") {
            mpvSummary.data = JSON.parse(data.statistic_value) || [];
          }
        });
        setModelpackage(mp);
        setModelpackageSummary(mpSummary);
        setModelpackageversion(mpv);
        setModelpackageversionSummary(mpvSummary);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  return (
    <>
      <StatisticPanel panelInfo={modelpackage} />
      <SummaryPanel panelInfo={modelpackageSummary} />
      <StatisticPanel panelInfo={modelpackageversion} />
      <SummaryPanel panelInfo={modelpackageversionSummary} />
    </>
  );
};

export default DashbordStatistic;

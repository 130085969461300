import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { useDispatch } from "react-redux";
import { useState } from "react";
// material
import { Box, Stack, Collapse, IconButton } from "@mui/material";
//
import EmptyTable from "@/components/project/EmptyTable";
import PropertyFile from "./PropertyFile";
import { CollapseButtonStyle } from "../../SettingSidebar";
import { ProcessingStep } from "@/@types/project/mlPipeline/SageMaker/pipeline-processing";
import { updatePropertyFile } from "../../../graph/slices/slice";

// ----------------------------------------------------------------------

type Props = {
  currentStep: ProcessingStep | null;
};

export default function PropertyFiles({ currentStep }: Props) {
  const dispatch = useDispatch();

  const [isCollapse, setCollapse] = useState(false);

  const propertyFiles = currentStep?.propertyFiles || [];
  const processingOutputs = currentStep?.processingOutputs || [];

  const handleAddPropertyFile = () => {
    dispatch(
      updatePropertyFile({
        data: {
          name: `PropertyFile-${propertyFiles.length + 1}`,
          outputName: "",
          path: "",
        },
      })
    );
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <CollapseButtonStyle
          fullWidth
          color="inherit"
          onClick={() => setCollapse((prev) => !prev)}
          endIcon={
            <Icon
              icon={isCollapse ? arrowIosDownwardFill : arrowIosForwardFill}
              width={16}
              height={16}
            />
          }
        >
          Property files{" "}
          {propertyFiles.length > 0 && `(${propertyFiles.length})`}
        </CollapseButtonStyle>

        <IconButton
          sx={{ zIndex: 1999, position: "absolute", top: 7, right: 30 }}
          size="small"
          onClick={handleAddPropertyFile}
        >
          <Icon icon={plusFill} width={16} height={16} />
        </IconButton>
      </Box>

      <Collapse in={isCollapse}>
        <Stack spacing={1.5} sx={{ mx: 2, mb: 1.5, mt: isCollapse ? 0.5 : 0 }}>
          {propertyFiles.length > 0 &&
            propertyFiles.map((propertyFile, index) => (
              <PropertyFile
                key={index}
                id={index}
                data={propertyFile}
                processingOutputs={processingOutputs}
              />
            ))}
        </Stack>
      </Collapse>

      {/* {!isCollapse && <Divider sx={{ mb: 1 }} />} */}

      {isCollapse && propertyFiles.length === 0 && (
        <EmptyTable
          mainTitle={"No property files"}
          content={'Create one by clicking "+" button'}
          color={"text.disabled"}
          sx={{ backgroundColor: "transparent", mx: 2, mt: 5, mb: 7 }}
        />
      )}
    </>
  );
}
